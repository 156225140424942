<template>
  <v-modal
    id="admin-agency-templates-edit"
    class-name="modal-fullscreen-sm-down"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('admin.agency.template.addTemplates') }}
      </modal-title>
    </modal-header>

    <modal-body>
      <v-select-agency-template-search
        id="admin-agency-template-search"
        :selected-options="agency.templates"
        @selected="assignTemplate"
      />

      <div class="search-separator-container pb-32">
        <div class="separator-text-container">
          <span class="separator-text text-sm">{{ $t('general.shared.orCreateNew') }}</span>
        </div>
      </div>

      <div class="agency-create-container">
        <form
          id="agency-template-create-form"
          class="d-flex flex-column justify-content-between h-100"
        >
          <div>
            <fieldset>
              <legend class="visually-hidden">{{ $t('clientTour.basicInformation') }}</legend>

              <v-form-input
                id="template-create-name"
                v-model="name"
                :label="this.$t('general.shared.name')"
                :yup-errors-variable="errors.name"
                autocomplete="off"
                form-type="outline"
              />

              <v-form-input
                id="template-create-pax"
                v-model="pax"
                :label="this.$t('general.shared.pax')"
                :yup-errors-variable="errors.pax"
                autocomplete="off"
                form-type="outline"
                onwheel="return false;"
                type="number"
              />

              <v-form-input
                id="template-create-total-days"
                v-model="totalDays"
                :label="this.$t('general.shared.totalDays')"
                :yup-errors-variable="errors.totalDays"
                autocomplete="off"
                form-type="outline"
                type="number"
              />
            </fieldset>

            <div class="search-separator-container pt-24">
              <div class="separator-text-container">
                <span class="separator-text text-sm">{{ $t('clientTour.howWillYourPath') }}</span>
              </div>
            </div>

            <fieldset>
              <legend class="visually-hidden">{{ $t('clientTour.howWillYourPath') }}</legend>

              <v-form
                id="template-create-travel-type-select"
                :label="this.$t('clientTour.howToDoIt')"
                form-type="outline"
              >
                <v-select
                  v-model="travelType"
                  :clearable="false"
                  :options="travelTypes"
                  :reduce="returnValue => returnValue.key"
                  class="mb-8"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </v-form>
            </fieldset>
          </div>

          <v-button
            :disabled="!meta.valid || disableButton"
            :is-loading="accepted"
            class="btn-icon w-fit align-self-end mt-24"
            size="sm"
            variant="primary"
            @click="createTemplate"
          >
            {{ $t('general.button.create') }}
            <v-icon icon="arrow-right" size="sm" space="ms-12"/>
          </v-button>
        </form>
      </div>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VForm, VFormInput } from '@uniqoders/form';
import vSelect from 'vue-select';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import api from '@/api';
import Agency from '@/api/objects/Agency';
import { findConceptByKey, mapConcepts } from '@/helpers/ConceptHelper';
import VSelectAgencyTemplateSearch from '@/components/vendor/VSelectAgencyTemplateSearch.vue';
import Template from '@/api/objects/Template';
import useFormValidation from '@/helpers/form';
import useConcepts from '@/helpers/Concepts';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'TheAdminAgencyAddTemplatesModal',
  components: {
    VSelectAgencyTemplateSearch,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VFormInput,
    vSelect,
    VForm,
    VButton,
  },
  emits: [
    'closed',
    'emitReloadAgency',
  ],
  props: {
    agency: {
      type: Agency,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      totalDays: yup.number()
        .required()
        .min(1)
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.daysNumber')),
      pax: yup.number()
        .required()
        .min(1)
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.pax')),
      travelType: yup.string()
        .required()
        .label(t('general.shared.client')),
    });

    const initialValues = {
      pax: 1,
      totalDays: 1,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: name } = useField<string>('name');
    const { value: pax } = useField<number>('pax');
    const { value: totalDays } = useField<number>('totalDays');
    const { value: travelType } = useField<string>('travelType');

    return {
      findConceptByKey,
      ...useConcepts(),
      ...form,
      name,
      pax,
      totalDays,
      travelType,
    };
  },
  data() {
    return {
      disableButton: false as boolean,
    };
  },
  computed: {
    travelTypes(): any {
      if (!!this.concepts && this.concepts['tour.travel_type']) {
        return mapConcepts(this.concepts['tour.travel_type']);
      }

      return [];
    },
  },
  methods: {
    async assignTemplate(template: Template) {
      await this.$modal.confirm({
        title: this.$t('admin.agency.template.addTemplateToAgencyTitle'),
        text: this.$t('admin.agency.template.addTemplateToAgencyTitleText', {
          template: template.name,
          agency: this.agency.name,
        }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.add'),
        confirmButtonCallback: () => this.doAssignTemplate(template.id),
      });
    },
    async doAssignTemplate(templateId: number) {
      try {
        const data: any = {
          templates: [templateId],
        };

        await api.admin.agency.attachTemplates(this.agency.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.reloadAgency();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async createTemplate() {
      try {
        this.toggleAccepted();
        this.disableButton = true;

        const data: any = {
          name: this.name,
          pax_number: this.pax,
          total_days: this.totalDays,
          travel_type: this.travelType,
        };

        await api.template.createEmpty(this.agency.slug, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.reloadAgency();
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableButton = false;

        this.toggleAccepted();
      }
    },
    async reloadAgency() {
      this.$emit('emitReloadAgency');
    },
  },
});
</script>
