<template>
  <div class="form-outline">
    <label class="label" :class="{ disabled: isDisabled }">
      <span v-if="!!label">{{ label }}</span>
      <span v-else>{{ $t('tourClient.selectClient') }}*</span>
    </label>

    <v-select
      v-if="status === 'loaded'"
      v-model="localValue"
      :disabled="isDisabled"
      :clear-search-on-select="true"
      :clearable="clearable"
      :filterable="false"
      :options="clients"
      :reduce="returnValue => returnValue.id"
      class="select-search"
      label="name"
      @search="onSearch"
      @update:model-value="$emit('update:modelValue', localValue)"
    >
      <template v-slot:no-options>
        <div class="my-4">{{ $t('tourClient.writeSearchClients') }}</div>
      </template>

      <template v-slot:option="option">
        <div class="d-center">
          <span>{{ option?.name }} {{ option?.surname }}</span>
        </div>
      </template>

      <template v-slot:selected-option="option">
        <div class="selected d-center py-8">
          <span>{{ option?.name }} {{ option?.surname }}</span>
        </div>
      </template>

      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <v-icon icon="search" size="sm"/>
        </span>
      </template>

      <template v-if="canCreate" #list-footer>
        <li ref="load" class="create-client">
          <v-button
            class="btn-icon h-fit fw-normal shadow-none py-0 px-16"
            size="sm"
            variant="link"
            @click="openModal('v-client-create-modal')"
          >
            <v-icon icon="plus" size="xs" space="me-8"/>
            {{ $t('clientTour.createClient') }}
          </v-button>
        </li>
      </template>
    </v-select>

    <v-client-create-modal
      v-if="modalToShow === 'v-client-create-modal'"
      :agency="agency"
      @client="setNewClient"
      @closed="closeModal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import Client from '@/api/objects/Client';
import useModalUtils from '@/helpers/ModalUtils';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VClientCreateModal from '@/components/shared/VClientCreateModal.vue';

export default defineComponent({
  name: 'VSelectClientSearch',
  components: {
    VClientCreateModal,
    VIcon,
    vSelect,
    VButton,
  },
  emits: [
    'userAttached',
    'clear',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    clientName: {
      type: String,
      required: false,
    },
    clientId: {
      type: Number,
      required: false,
    },
    canCreate: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    agency: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      ...useModalUtils(),
    };
  },
  data() {
    return {
      localValue: this.modelValue,
      clients: [] as Client[],
      status: 'loading' as string,
    };
  },
  async created() {
    this.status = 'loading';

    if (!!this.clientName) {
      await this.loadClients(null, this.clientName);
    }

    if (!!this.clientId) {
      await this.loadClients(null, '', true);
    }

    this.status = 'loaded';
  },
  methods: {
    onSearch(search: string, loading: any) {
      if (search.length) {
        loading(true);
        this.loadClients(loading, search);
      }
    },
    async loadClients(loading?: any, search?: string, withDefault?: boolean) {
      if (!!loading) {
        loading(true);
      }

      try {
        if (!search) {
          search = '';
        }

        let id: string | number = '';

        if (withDefault && !!this.clientId) {
          id = this.clientId;
        }

        const query = { filters: { name: search, id } };

        this.clients = await api.client.all(this.agency, query);

        if (!!this.clientId && !!this.clients && this.clients.length > 0) {
          const clientFound = this.clients.find((client: Client) => client.id === this.clientId);

          if (!!clientFound) {
            this.localValue = clientFound.id;
          }
        }
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        if (!!loading) {
          loading(false);
        }
      }
    },
    async setNewClient(client: Client) {
      this.localValue = client.id;

      await this.loadClients(null, client.name);

      this.$emit('update:modelValue', client.id);
    },
  },
});
</script>
