import ApiClient from '@/api/ApiClient';

export default new ApiClient({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    'X-RefererSearch': window.location.search,
    'Accept-Language': localStorage.getItem('language') || 'en',
  },
});

