<template>
  <div class="detail">
    <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
      <div class="fw-medium">{{ $t('supplier.basicInformation') }}</div>

      <v-button
        v-if="!supplier.deletedAt"
        class="btn-edit p-4"
        variant="icon"
        @click="editBasicInformation = !editBasicInformation">
        <v-icon icon="pencil"/>
      </v-button>
    </div>

    <div class="detail-content">
      <Transition mode="out-in" name="fade-in">
        <div v-if="!editBasicInformation">
          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.name') }}: </span>
            <span>{{ supplier.name }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.type') }}: </span>
            <span v-if="!!supplier.type">{{ findConceptByKey('supplier.type', supplier.type).name }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.description') }}: </span>
            <span v-if="!!supplier.description" v-html="supplier.description"/>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.priceRange') }}: </span>

            <div v-if="!!supplier.priceRange" class="star-group justify-content-between">
              <v-star-rating
                :disabled="true"
                :model-value="supplier.priceRange"
                icon="euro"
              />
            </div>

            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.location') }}: </span>
            <span v-if="!!supplier.location && !!supplier.location.name">{{ supplier.location.name }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.amenities') }}: </span>
            <span v-if="!!allAmenities">{{ allAmenities }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.phone') }}: </span>
            <span v-if="!!supplier.phone">{{ supplier.phone }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.email') }}: </span>
            <span v-if="!!supplier.email">{{ supplier.email }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span v-if="supplier.typeEmail === 'normal'" class="info-title fw-medium">
              {{ $t('general.shared.notificationsSentMail') }}
            </span>

            <span v-else class="info-title fw-medium">{{ $t('general.shared.notNotificationsSentMail') }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.typesOfBooking') }}: </span>
            <span v-if="!!supplier.bookingType">
              {{ supplier.bookingType }}
            </span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.bookingOptions') }}: </span>
            <span v-if="!!supplier.bookingTypeMeta">
              {{ supplier.bookingTypeMeta }}
            </span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.preferredLanguage') }}: </span>
            <span v-if="!!supplier.preferredLanguage">
              {{ findConceptByKey('app.languages', supplier.preferredLanguage).name }}
            </span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.paymentMethod') }}: </span>

            <span v-if="!!supplier.paymentMethod">
              {{ findConceptByKey('supplier.payment_method_type', supplier.paymentMethod).name }}
            </span>

            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.paymentBy') }}: </span>

            <span v-if="!!supplier.paymentDate">
              {{ supplier.paymentDate }}
            </span>

            <span v-else>-</span>
          </div>

          <div v-if="paymentMethod === 'bank_transfer'" class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.accountNumber') }}: </span>
            <span v-if="!!supplier.account">{{ supplier.account }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.notes') }}: </span>
            <span v-if="!!supplier.notes" v-html="supplier.notes"/>
            <span v-else>-</span>
          </div>

          <div v-if="!!supplier.mapUrl" class="text-sm d-flex py-4">
            <a
              :aria-label="$t('general.shared.viewGoogleMaps')"
              :href="supplier.mapUrl"
              class="resource-link btn-link"
              rel="noopener noreferrer"
              target="_blank"
            >
              {{ $t('general.shared.viewGoogleMaps') }}
            </a>
          </div>

          <div v-if="!!supplier.siteUrl" class="text-sm d-flex py-4">
            <a
              :aria-label="$t('general.shared.viewWebsite')"
              :href="supplier.siteUrl"
              class="resource-link btn-link"
              rel="noopener noreferrer"
              target="_blank"
            >
              {{ $t('general.shared.viewWebsite') }}
            </a>
          </div>

          <p class="mb-4 mt-16 fw-medium">{{ $t('general.address.address') }}</p>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.streetAddress') }}: </span>
            <span v-if="!!supplier.contactAddress1">{{ supplier.contactAddress1 }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.aptSuit') }}: </span>
            <span v-if="!!supplier.contactAddress2">{{ supplier.contactAddress2 }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.postalCode') }}: </span>
            <span v-if="!!supplier.contactPostalCode">{{ supplier.contactPostalCode }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.country') }}: </span>
            <span v-if="!!supplier.contactCountry">{{ supplier.contactCountry }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.city') }}: </span>
            <span v-if="!!supplier.contactCity">{{ supplier.contactCity }}</span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.provinceStateRegion') }}: </span>
            <span v-if="!!supplier.contactState">{{ supplier.contactState }}</span>
            <span v-else>-</span>
          </div>
        </div>

        <form
          v-else
          id="supplier-update"
          class="d-flex flex-column justify-content-between h-100"
        >

          <div class="fieldset-translatable mb-16">
            <nav class="translations-tab">
              <div id="nav-tab" class="nav nav-tabs" role="tablist">
                <v-button
                  v-for="(language, index) in languages"
                  :id="`nav-supplier-create-basic-information-${language.key}`"
                  :key="index"
                  :aria-controls="`supplier-create-basic-information-${language.key}`"
                  :class="{ active: language.key === currentLanguage }"
                  :data-bs-target="`#supplier-create-basic-information-${language.key}`"
                  aria-selected="true"
                  class="nav-tab-link py-8 px-16 me-8"
                  data-bs-toggle="tab"
                  role="tab"
                  size="sm"
                  variant="outline-primary"
                  @click="currentLanguage = language.key"
                >
                  {{ language.label }}
                </v-button>
              </div>
            </nav>

            <transition
              mode="out-in"
              name="fade"
              appear
            >
              <div
                id="supplier-update-basic-information-tabs"
                class="tab-content"
              >
                <div
                  v-for="(language, index) in languages"
                  :id="`supplier-update-basic-information-${language.key}`"
                  :key="index"
                  :aria-labelledby="`nav-supplier-update-basic-information-${language.key}`"
                  :class="{ 'active show': language.key === currentLanguage }"
                  class="supplier-update-basic-information-tab tab-pane fade"
                  role="tabpanel">
                  <the-supplier-create-basic-information-fieldset
                    v-model:description="translations[language.key].description"
                    v-model:name="name"
                    v-model:type="type"
                    :clear="clear"
                    :current-language="currentLanguage"
                    :selected-resources-data="selectedResourcesData"
                    :selected-default-resources="selectedDefaultResources"
                    :language="language.key"
                    update-module="update"
                    @check-button-update="checkButtonUpdate($event)"
                    @is-valid="isTranslationsValid.find((translation) => translation.locale === language.key).valid = $event"
                  />
                </div>
              </div>
            </transition>
          </div>

          <fieldset class="mb-16">
            <legend class="visually-hidden">{{ $t('general.shared.moreData') }}</legend>

            <div class="row gx-12">
              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.priceRange') }}</span>
                </label>

                <div class="form-control">
                  <div class="star-group justify-content-between">
                    <v-star-rating
                      v-model="rating"
                      icon="euro"
                      icon-size="md"
                    />

                    <div class="text-sm">{{ getPriceRangeLabel(rating) }}</div>
                  </div>
                </div>
              </div>

              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.amenities') }}</span>
                </label>

                <v-select
                  id="supplier-update-amenities-select"
                  v-model="amenities"
                  :options="supplierAmenities"
                  :reduce="(returnValue: any) => returnValue.key"
                  multiple
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>

              <v-form-input
                id="supplier-update-phone"
                v-model="phone"
                :label="$t('general.shared.phone')"
                :type="'text'"
                :yup-errors-variable="errors.phone"
                autocomplete="off"
                form-type="outline"
              />

              <v-form-input
                id="supplier-update-email"
                v-model="email"
                :label="$t('general.shared.email')"
                :type="'email'"
                :yup-errors-variable="errors.email"
                autocomplete="off"
                class="mb-20"
                form-type="outline"
              />

              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.bookingType') }}</span>
                </label>
                <v-select
                  id="supplier-create-preferred-language"
                  v-model="preferredTypesBooking"
                  :options="getTypesBooking"
                  :reduce="returnValue => returnValue.label"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>
              <v-form-input
                v-if="'Portal' === preferredTypesBooking"
                :id="`supplier-booking-types-supplier`"
                v-model="preferredTypesBookingMeta"
                :label="$t('general.shared.supplierPortal')"
                :type="'text'"
                autocomplete="off"
                form-type="outline"
              />

              <v-form-input
                v-if="'Promo code' === preferredTypesBooking"
                :id="`supplier-booking-types-promocode`"
                v-model="preferredTypesBookingMeta"
                :label="$t('general.shared.promoCode')"
                :type="'text'"
                autocomplete="off"
                form-type="outline"
              />

              <!-- <v-form-input
                v-if="'Email' === preferredTypesBooking"
                :id="`supplier-booking-types-email`"
                v-model="preferredTypesBookingMeta"
                :label="$t('general.shared.byEmail')"
                :type="'text'"
                autocomplete="off"
                form-type="outline"
              /> -->

              <v-form-input
                v-if="'Other' === preferredTypesBooking"
                :id="`supplier-booking-types-other`"
                v-model="preferredTypesBookingMeta"
                :label="$t('general.shared.other')"
                :type="'text'"
                autocomplete="off"
                form-type="outline"
              />

              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.preferredLanguage') }}</span>
                </label>

                <v-select
                  id="supplier-update-preferred-language"
                  v-model="preferredLanguage"
                  :options="languages"
                  :reduce="(returnValue: any) => returnValue.key"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>

              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.paymentMethod') }}</span>
                </label>

                <v-select
                  id="supplier-update-payment-method-type-select"
                  v-model="paymentMethod"
                  :options="supplierPaymentMethodTypes"
                  :reduce="(returnValue: any) => returnValue.key"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>

              <v-form-input
                v-if="paymentMethod === 'bank_transfer'"
                id="supplier-update-account-number"
                v-model="account"
                :label="$t('general.shared.accountNumber')"
                :yup-errors-variable="errors.account"
                autocomplete="off"
                class="col-12"
                form-type="outline"
              />              
              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.paymentBy') }}</span>
                </label>
           
                <v-select 
                  id="supplier-update-payment-by-select" 
                  v-model="paymentDate" 
                  :options="getPaymentBy"
                  :reduce="returnValue => returnValue.label"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>

              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.notes') }}</span>
                </label>

                <v-html-editor
                  id="supplier-update-notes"
                  :value="notes"
                  name="supplier-update-notes"
                  @input="notes = $event"
                  @media-uploaded="editorMedia.push($event)"
                />
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend class="visually-hidden">{{ $t('general.shared.links') }}</legend>

            <v-form-input
              id="supplier-update-map"
              v-model="mapUrl"
              :label="$t('general.shared.map')"
              :yup-errors-variable="errors.mapUrl"
              autocomplete="off"
              form-type="outline"
            />

            <v-form-input
              id="supplier-update-website"
              v-model="siteUrl"
              :label="$t('general.shared.website')"
              :yup-errors-variable="errors.website"
              autocomplete="off"
              form-type="outline"
            />
          </fieldset>

          <div class="search-separator-container pb-32">
            <div class="separator-text-container">
              <span class="separator-text text-sm">{{ $t('general.address.address') }}</span>
            </div>
          </div>

          <v-contact-billing-form
            id="supplier-update-address-form"
            ref="supplier-update-address-form-ref"
            :data="addressData"
            :hidden-legend="true"
            :without-billing-address="true"
            id-prefix="supplier-update"
            @updated-data="setAddressData"
            @is-valid="addressValid = $event"
          />

          <v-button
            :disabled="!meta.valid || !addressValid || accepted
              || !isTranslationsValid.every(translation => translation.valid)"
            :is-loading="accepted"
            class="btn-submit-form btn-icon w-fit align-self-end"
            size="sm"
            variant="primary"
            @click="saveSupplier"
          >
            {{ $t('general.button.save') }}
            <v-icon icon="arrow-right" size="sm" space="ms-12"/>
          </v-button>
          <div class="d-flex save-exit-btn">
            <v-button
              :disabled="!buttonDisable"
              :is-loading="accepted"
              class="btn-icon w-fit align-self-end p-8"
              variant="primary"
              @click="saveSupplier"
          >
            <span class="text-sm fw-bolder">{{ $t('general.button.save') }}</span>
            <v-icon icon="arrow-right" size="xs" space="ms-12"/>
          </v-button>
          </div>
        </form>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import vSelect from 'vue-select';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import useModalUtils from '@/helpers/ModalUtils';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { findConceptByKey, mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';
import Supplier from '@/api/objects/Supplier';
import VHtmlEditor from '@/components/vendor/htmlEditor/VHtmlEditor.vue';
import VStarRating from '@/components/vendor/VStarRating.vue';
import TheSupplierCreateBasicInformationFieldset
  from '@/components/supplier/parts/TheSupplierCreateBasicInformationFieldset.vue';

export default defineComponent({
  name: 'TheSupplierDetailsBasicInformation',
  components: {
    TheSupplierCreateBasicInformationFieldset,
    VStarRating,
    VHtmlEditor,
    VContactBillingForm,
    VIcon,
    VButton,
    VFormInput,
    vSelect,
  },
  emits: ['closed', 'reloadSupplier', 'emitReloadSuppliers'],
  props: {
    supplier: {
      type: Supplier,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      amenities: yup.array()
        .nullable()
        .label(t('general.shared.amenities')),
      phone: yup.string()
        .nullable()
        .label(t('general.shared.phone')),
      email: yup.string()
        .email()
        .nullable()
        .label(t('general.shared.email')),
      typeEmail: yup.bool()
        .oneOf([true, false], t('general.shared.sendEmailNotification')),
      paymentMethod: yup.string()
        .nullable()
        .label(t('general.shared.paymentMethod')),
      account: yup.string()
        .nullable()
        .label(t('general.shared.accountNumber')),
      notes: yup.string()
        .nullable()
        .label(t('general.shared.notes')),
      rating: yup.number()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.priceRange')),
      mapUrl: yup.string()
        .nullable()
        .label(t('general.shared.map')),
      siteUrl: yup.string()
        .nullable()
        .label(t('general.shared.website')),
      preferredLanguage: yup.string()
        .nullable()
        .label(t('general.shared.preferredLanguage')),
      preferredTypesBooking: yup.string()
        .nullable()
        .label(t('general.shared.bookingType')),
      preferredTypesBookingMeta: yup.string()
        .nullable()
        .label(t('general.shared.bookingType')),
      paymentDate: yup.string()
        .nullable()
        .label(t('general.shared.paymentBy')),
    });

    const initialValues: Record<string, any> = {
      rating: 0,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: amenities } = useField('amenities');
    const { value: phone } = useField('phone');
    const { value: email } = useField('email');
    const { value: typeEmail } = useField('typeEmail');
    const { value: paymentMethod } = useField('paymentMethod');
    const { value: account } = useField('account');
    const { value: notes } = useField('notes');
    const { value: rating } = useField('rating');
    const { value: mapUrl } = useField('mapUrl');
    const { value: siteUrl } = useField('siteUrl');
    const { value: preferredLanguage } = useField('preferredLanguage');
    const { value: preferredTypesBooking } = useField('preferredTypesBooking');
    const { value: preferredTypesBookingMeta } = useField('preferredTypesBookingMeta');
    const { value: paymentDate } = useField('paymentDate');

    const initData = {
      ratingLocal: props.supplier.priceRange,
      phoneLocal: props.supplier.phone,
      emailLocal: props.supplier.email,
      amenities: props.supplier.amenities,
      account: props.supplier.account,
      notes: props.supplier.notes,
      mapUrl: props.supplier.mapUrl,
      siteUrl: props.supplier.siteUrl,
      preferredLanguage: props.supplier.preferredLanguage,
      preferredTypesBooking: props.supplier.bookingType,
      paymentMethod: props.supplier.paymentMethod,
      paymentDate: props.supplier.paymentDate,
      contactAddress1: props.supplier.contactAddress1,
      contactAddress2: props.supplier.contactAddress2,
      contactPostalCode: props.supplier.contactPostalCode,
      contactCountry: props.supplier.contactCountry,
      contactCity: props.supplier.contactCity,
      contactState: props.supplier.contactState,
    }

    return {
      ...useConcepts(),
      ...useModalUtils(),
      findConceptByKey,
      ...form,
      amenities,
      phone,
      email,
      typeEmail,
      paymentMethod,
      account,
      notes,
      rating,
      mapUrl,
      siteUrl,
      preferredLanguage,
      preferredTypesBooking,
      preferredTypesBookingMeta,
      paymentDate,
      initData,
    };
  },
  data() {
    return {
      editBasicInformation: false as boolean,
      addressData: {} as any,
      addressValid: false as boolean,
      lat: '' as string | number,
      lng: '' as string | number,
      locationId: null as null | number,
      allAmenities: '' as string | any,
      timers: {
        updateSupplier: '',
      },
      name: '' as string,
      type: '' as string,
      translations: {} as Record<string, any>,
      currentLanguage: '',
      clear: false,
      isTranslationsValid: [] as Record<string, any>[],
      editorMedia: [] as any[],
      selectedDefaultResources: [] as any[],
      selectedResourcesData: [] as any[],
      buttonDisable: false as boolean,
    };
  },
  computed: {
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    languages(): any {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
    supplierTypes(): any {
      if (!!this.concepts && this.concepts['supplier.type']) {
        return mapConcepts(this.concepts['supplier.type']);
      }

      return [];
    },
    supplierPaymentMethodTypes(): any {
      if (!!this.concepts && this.concepts['supplier.payment_method_type']) {
        return mapConcepts(this.concepts['supplier.payment_method_type']);
      }

      return [];
    },
    supplierAmenities(): any {
      if (!!this.concepts && this.concepts['supplier.amenities']) {
        return mapConcepts(this.concepts['supplier.amenities']);
      }

      return [];
    },
    getTypesBooking() {
      const bokingData = [
        {
          name: 'website',
          label: 'Website'
        },
        {
          name: 'supplier_portal',
          label: 'Portal'
        },
        {
          name: 'promo_code',
          label: 'Promo code'
        },
        {
          name: 'email',
          label: 'Email'
        },
        {
          name: 'other',
          label: 'Other'
        }
      ];
      const sortedBookingData = bokingData.sort((a, b) => a.label.localeCompare(b.label));

      return sortedBookingData;
    },
    getPaymentBy() {
      const PaymentData = [
        {
          name: '10_days_before',
          label: '10 days before'
        },
        {
          name: 'after_the_trip',
          label: 'After the trip'
        },
      ];
      return PaymentData;
    },
  },
  watch: {
    supplier(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setData();
      }
    },
    addressData(newVal){
      const contadd1 = newVal.contactAddress1 === '' ? null : newVal.contactAddress1;
      const contadd2 = newVal.contactAddress2 === '' ? null : newVal.contactAddress2;
      const contpostal = newVal.contactPostalCode === '' ? null : newVal.contactPostalCode;
      const contcountry = newVal.contactCountry === '' ? null : newVal.contactCountry;
      const contcity = newVal.contactCity === '' ? null : newVal.contactCity;
      const contstate = newVal.contactState === '' ? null : newVal.contactState;

      if (contadd1 !== this.initData.contactAddress1
        || contadd2 !== this.initData.contactAddress2
        || contpostal !== this.initData.contactPostalCode
        || contcountry !== this.initData.contactCountry
        || contcity !== this.initData.contactCity
        || contstate !== this.initData.contactState
      ) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    phone(newVal) {
      const data = newVal === '' ? null : newVal;      
      if (data !== this.initData.phoneLocal) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    rating(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.ratingLocal) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    email(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.emailLocal) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    amenities(newVal) {
      const data = newVal?.length === 0 ?  null : newVal;      
      if (data !== this.initData.amenities) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    account(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.account) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    notes(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.notes) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    mapUrl(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.mapUrl) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    siteUrl(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.siteUrl) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    preferredLanguage(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.preferredLanguage) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    preferredTypesBooking(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.preferredTypesBooking) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    paymentMethod(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.paymentMethod) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
    paymentDate(newVal) {
      const data = newVal === '' ? null : newVal;
      if (data !== this.initData.paymentDate) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
    },
  },
  async created() {
    this.currentLanguage = this.defaultLocale;

    await this.mapTranslations();
    await this.setData();
  },
  methods: {
    checkButtonUpdate(value: boolean) {
      this.buttonDisable = value;
    },
    mapTranslations() {
      this.isTranslationsValid = [];

      this.languages.forEach((language: Record<string, any>) => {
        this.isTranslationsValid.push({
          locale: language.key,
          valid: false,
        });

        this.translations[language.key] = {
          description: '',
        };
      });
    },
    setData() {
      if (!!this.supplier) {        
        this.name = this.supplier.name;
        this.type = this.supplier.type;
        this.amenities = JSON.parse(this.supplier.amenities);
        this.phone = this.supplier.phone;
        this.email = this.supplier.email;
        this.typeEmail = this.supplier.typeEmail === 'normal';
        this.paymentMethod = this.supplier.paymentMethod;
        this.account = this.supplier.account;
        this.locationId = this.supplier.locationId;
        this.lat = this.supplier.lat;
        this.lng = this.supplier.lng;
        this.notes = this.supplier.notes;
        this.rating = this.supplier.priceRange;
        this.mapUrl = this.supplier.mapUrl;
        this.siteUrl = this.supplier.siteUrl;
        this.preferredLanguage = this.supplier.preferredLanguage;
        this.preferredTypesBooking = this.supplier.bookingType;
        this.preferredTypesBookingMeta = this.supplier.bookingTypeMeta;
        this.selectedResourcesData = this.supplier.resources.map((e) => ({ name: e.name, ...e.prices }));
        this.paymentDate = this.supplier.paymentDate;


        this.addressData.contactAddress1 = this.supplier.contactAddress1;
        this.addressData.contactAddress2 = this.supplier.contactAddress2;
        this.addressData.contactPostalCode = this.supplier.contactPostalCode;
        this.addressData.contactCountry = this.supplier.contactCountry;
        this.addressData.contactCity = this.supplier.contactCity;
        this.addressData.contactState = this.supplier.contactState;
        this.addressData.lat = this.supplier.lat;
        this.addressData.lng = this.supplier.lng;
        this.addressData.locationId = this.supplier.locationId;

        this.supplier.translations.forEach((translation: any) => {
          this.translations[translation.locale].description = translation.description;

          const translationValid = this.isTranslationsValid.find(
            (translations) => translations.locale === translation.locale);

          if (!!translationValid) {
            translationValid.valid = true;
          }
        });

        if (Array.isArray(this.amenities)) {
          let isFirstType = true;

          this.amenities.forEach((type: string) => {
            const typeObject = this.supplierAmenities.find((amenity: any) => amenity.key === type);

            if (!!typeObject) {
              const typeLabel = typeObject.label;

              if (isFirstType) {
                this.allAmenities = typeLabel;

                isFirstType = false;
              } else {
                this.allAmenities = `${this.allAmenities}, ${typeLabel}`;
              }
            }
          });
        } else {
          this.allAmenities = this.amenities;
        }
      }
    },
    async setAddressData(address: Record<string, any>) {
      this.addressData = address;
      this.lat = address.lat;
      this.lng = address.lng;
    },
    async saveSupplier() {
      if (!!this.supplier) {
        try {
          this.toggleAccepted();

          const data: any = {
            name: this.name,
            type: this.type,
            amenities: this.amenities,
            phone: this.phone,
            email: this.email,
            type_email: this.typeEmail ? 'normal' : 'never',
            payment_method: this.paymentMethod,
            account: this.account,
            notes: this.notes,
            location_id: !!this.addressData.location_id ? this.addressData.location_id : this.locationId,
            contact_address_1: this.addressData.contactAddress1,
            contact_address_2: this.addressData.contactAddress2,
            contact_postal_code: this.addressData.contactPostalCode,
            contact_country: this.addressData.contactCountry,
            contact_city: this.addressData.contactCity,
            contact_state: this.addressData.contactState,
            lat: this.addressData.lat,
            lng: this.addressData.lng,
            price_range: this.rating,
            translations: this.translations,
            map_url: this.mapUrl,
            site_url: this.siteUrl,
            preferred_language: this.preferredLanguage,
            booking_type: this.preferredTypesBooking,
            booking_type_meta: this.preferredTypesBookingMeta,
            selectedDefaultResources: this.selectedDefaultResources,
            payment_date: this.paymentDate,
          };

          const supplier: Supplier = await api.supplier.update(this.supplier.id, data);

          if (!!this.editorMedia && this.editorMedia.length > 0) {
            const editorMedia = {
              media: this.editorMedia,
              model_type: 'supplier',
              collection: 'tinymce',
              model_id: this.supplier.id,
            };

            await api.media.confirm('s3', editorMedia);
          }

          this.$toast.success(supplier.message);

          this.toggleAccepted();

          this.$emit('reloadSupplier');
          this.$emit('emitReloadSuppliers');
        } catch (e: any) {
          this.toggleAccepted();

          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    getPriceRangeLabel(rating: number) {
      let ratingLabel = '';

      switch (rating) {
        case 1:
          ratingLabel = this.$t('general.shared.veryCheap');
          break;
        case 2:
          ratingLabel = this.$t('general.shared.cheap');
          break;
        case 3:
          ratingLabel = this.$t('general.shared.normal');
          break;
        case 4:
          ratingLabel = this.$t('general.shared.expensive');
          break;
        default:
          ratingLabel = '';
          break;
      }

      return ratingLabel;
    },
  },
});
</script>

<style lang="scss">
.save-exit-btn {
  position: fixed;
  top: 155px;
  z-index: 11;
  right: 5rem;

  @media screen and (max-width: 992px) {
    top: 147px;
  }
}
</style>