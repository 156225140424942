import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface MediaContract extends IBaseObject {
  collectionName: string;
  conversionsDisk: string;
  disk: string;
  fileName: string;
  mimeType: string;
  modelId: string;
  name: string;
  originalUrl: string;
  previewUrl: string;
  type: string;
  uuid: string;
  humanReadableSize: string;
  storageUrl: string;
  consultationId: number;
  createdAt: string;
  updatedAt: string;
}

export default class Media extends BaseObject implements MediaContract {
  public declare collectionName: string;
  public declare conversionsDisk: string;
  public declare disk: string;
  public declare fileName: string;
  public declare mimeType: string;
  public declare modelId: string;
  public declare name: string;
  public declare originalUrl: string;
  public declare previewUrl: string;
  public declare type: string;
  public declare uuid: string;
  public declare humanReadableSize: string;
  public declare storageUrl: string;
  public declare consultationId: number;
  public declare createdAt: string;
  public declare updatedAt: string;

  constructor(data: MediaContract) {
    super(data);
  }
}
