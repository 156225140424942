<template>
  <draggable v-if="!!modelValue" :list="modelValue" class="tour" draggable=".tour-day-container:not(.not-draggable)"
    item-key="id" v-bind="dragOptions" @change="change">
    <template #item="{ element, index }">
      <div :key="index" :class="{
    'not-draggable ghost-item': updatingTour,
    'not-draggable blocked-item': element.isBlocked,
  }" class="tour-day-container">
        <div key="tour-day">
          <v-button v-if="index !== 0" :disabled="updatingTour || element.isBlocked" class="btn-icon btn-day p-4 mb-12"
            size="sm" variant="outline-secondary" @click="addDay(element.date)">
            <v-icon icon="plus" />
          </v-button>

          <div :class="{ 'mt-36': index === 0 }" class="tour-day d-flex flex-row row gx-0">
            <div class="col-4">
              <div class="tour-day-info p-20 pe-16">
                <div class="date text-sm mb-8">
                  {{ getDate(element.date) }}
                </div>

                <div class="d-flex align-items-center">
                  <div class="tour-day-info-left me-16 me-lg-32">
                    <div class="day-circle d-flex align-items-center justify-content-center mb-8">
                      <div>{{ index + 1 }}</div>
                    </div>
                  </div>

                  <div class="tour-day-info-right w-100">
                    <div class="d-flex flex-column flex-md-row align-items-md-center mb-12">
                      <v-checkbox :id="`tour-day-${element.id}-stage`" :is-disabled="isTourDayUpdating"
                        :label="$t('tourBuilder.stage')" :left="false" :modelValue="setIsStage(element.type)"
                        :right="true" class="form-check-inline me-32" for-key="isAllDayActivated"
                        @update:modelValue="updateTourDayType($event, element)" />

                      <v-form-input :id="`tour-day-${element.id}-pax`" v-model="element.pax"
                        :disabled="isTourDayUpdating" :label="$t('general.shared.pax')" :yup-errors-variable="paxError"
                        autocomplete="off" class="w-100 mb-0" form-type="outline" type="number"
                        @blur="updateTourDayPax(element)" />
                    </div>

                    <template v-if="element.type === 'stage'">
                      <v-input-address id="tour-day-origin-search" ref="tour-day-origin-search-ref"
                        :disabled-input="isTourDayUpdating" :get-address="true" :get-radius-bounds="true"
                        :label="$t('general.shared.origin')" :radius-bounds="20000"
                        :value="searchLocation(element.originId)" class="col-12 mb-12"
                        @address="createLocation(element, $event)" @input="updateTourDayOrigin($event, element)" />

                      <v-input-address id="tour-day-destination-search" ref="tour-day-destination-search-ref"
                        :disabled-input="isTourDayUpdating" :get-address="true" :get-radius-bounds="true"
                        :label="$t('general.shared.destination')" :radius-bounds="20000"
                        :value="searchLocation(element.destinationId)" class="col-12 mb-12"
                        @address="createLocation(element, $event, false)"
                        @input="updateTourDayDestination($event, element)" />

                      <div class="text-sm mb-20">
                        {{ $t('general.shared.distanceKm') }}: {{ element.stageLength }}
                      </div>
                    </template>

                    <template v-else>
                      <v-input-address id="tour-day-location-search" ref="tour-day-location-search-ref"
                        :disabled-input="isTourDayUpdating" :get-address="true" :get-radius-bounds="true"
                        :label="$t('general.shared.location')" :radius-bounds="20000"
                        :value="searchLocation(element.originId)" class="col-12 mb-20"
                        @address="createLocation(element, $event)" @input="updateTourDayOrigin($event, element)" />
                    </template>

                    <v-button :class="{ active: currentTourDay === element.id }" class="btn-icon" size="xs"
                      variant="outline-default" @click="$emit('openDayInformationOffCanvas', element.id);">
                      <v-icon icon="pencil" space="me-12" />
                      {{ $t('tourBuilder.editStageInformation') }}
                    </v-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-8">
              <div class="d-flex justify-content-between h-100 align-items-center p-20 ps-16">
                <the-tour-day-item-draggable  :agency="agency"
                  :can-add-item="(element.type !== 'stage' && !!element.originId) ||
                  (element.type === 'stage' && !!element.originId && !!element.destinationId)"
                  :client-tour-id="clientTour.id" :list="element.items" :tour-day-id="element.id" :tour-id="tourId"
                  :tour-mapped="tourMapped" :updating-tour="updatingTour" type="tour-day-item"
                  @emit-reload-tour="$emit('reloadTour')"
                  @open-item-details-off-canvas="$emit('openItemDetailsOffCanvas', $event)"
                  @close-item-details-off-canvas="$emit('closeItemDetailsOffCanvas', $event)"
                  @move-item-to-another-day="$emit('moveItemToAnotherDay', $event)" />

                <div class="d-flex flex-column">
                  <v-button :disabled="updatingTour" class="btn-day p-4 mb-12" size="sm" variant="icon"
                    @click="$emit('reloadTour')">
                    <span class="icon icon-refresh text-lg text-primary" />
                  </v-button>

                  <v-button :disabled="updatingTour" class="btn-day p-4 mb-12" size="sm" variant="icon"
                    @click="removeDay(element, index)">
                    <v-icon icon="trash" size="lg" />
                  </v-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </draggable>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Vuedraggable from 'vuedraggable';
import { VCheckbox, VFormInput } from '@uniqoders/form';
import { DateTime } from 'luxon';
import { TYPE } from 'vue-toastification';
import TheTourDayItemDraggable from '@/components/tour/TheTourDayItemDraggable.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ClientTour from '@/api/objects/ClientTour';
import api from '@/api';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import Location from '@/api/objects/Location';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';

export default defineComponent({
  name: 'TheTourDayDraggable',
  components: {
    VInputAddress,
    TheTourDayItemDraggable,
    draggable: Vuedraggable,
    VCheckbox,
    VFormInput,
    VButton,
    VIcon,
  },
  emits: [
    'dayMoved',
    'emitAddDay',
    'reloadTour',
    'reloadClientTour',
    'openItemDetailsOffCanvas',
    'closeItemDetailsOffCanvas',
    'openDayInformationOffCanvas',
    'moveItemToAnotherDay',
  ],
  props: {
    modelValue: {
      type: Array as PropType<Record<string, unknown>[]>,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    clientTour: {
      type: ClientTour,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    agency: {
      type: String,
      required: true,
    },
    updatingTour: {
      type: Boolean,
      required: false,
      default: false,
    },
    initDate: {
      type: String,
      required: true,
      default: '',
    },
    endDate: {
      type: String,
      required: true,
      default: '',
    },
    currentTourDay: {
      type: [String, Number],
      required: false,
      default: '',
    },
    tourMapped: {
      type: Object,
      required: true,
    },
    
  },
  setup() {
    return {
      findConceptByKey,
      ...useOffCanvasUtils(),
    };
  },
  data() {
    return {
      isStage: false as boolean,
      locations: [] as Location[],
      isTourDayUpdating: false as boolean,
      paxError: '' as string,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'tour-day',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  async created() {
    await this.loadLocations();
  },
  methods: {
    async loadLocations() {
      this.locations = await api.location.all();
    },
    searchLocation(locationId: number): string {
      const locationName = this.locations.find((location) => location.id === locationId);

      if (!!locationName) {
        return locationName.name;
      }

      return '';
    },
    getDate(date: string) {
      const dateISO = DateTime.fromISO(date);

      return dateISO.toFormat('ccc, dd MMMM yyyy');
    },
    getDateFormatted(index: number) {
      const initDate = DateTime.fromISO(this.initDate);
      const endDate = DateTime.fromISO(this.endDate);

      let date = initDate;

      const dates: string[] = [];

      while (date <= endDate) {
        dates.push(date.toFormat('yyyy-MM-dd'));
        date = date.plus({ days: 1 });
      }

      return dates[index];
    },
    change(event: any) {
      const tourDay = event.moved.element;
      const newDate = this.getDateFormatted(event.moved.newIndex);

      this.$emit('dayMoved', tourDay, newDate);
    },
    async addDay(tourDayDate: string) {
      this.$emit('emitAddDay', tourDayDate);
    },
    async removeAllItems(tourDay: Record<string, any>) {
      tourDay.items.forEach((item: Record<string, any>) => {
        api.tourDayItem.delete(
          this.agency,
          this.clientTour.id,
          this.tourId,
          tourDay.id,
          item.id,
        );
      });
    },
    async removeDay(tourDay: Record<string, any>, dayNumber: number) {
      await this.$modal.delete({
        title: this.$t('tourBuilder.deleteTourDayTitle'),
        text: this.$t('tourBuilder.deleteTourDayText', { dayNumber: dayNumber + 1, date: this.getDate(dayNumber) }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doRemoveDay(tourDay),
      });
    },
    async doRemoveDay(tourDay: Record<string, any>) {
      const loadingToast = this.$toast.loading(this.$t('general.button.deleting'), 'toast-remove-tour-day');

      try {
        await this.removeAllItems(tourDay);

        await api.tourDay.delete(
          this.agency,
          this.clientTour.id,
          this.tourId,
          tourDay.id,
        );

        this.$emit('reloadTour');
        this.$emit('reloadClientTour');

        loadingToast.update('toast-remove-tour-day', {
          content: this.$t('tourBuilder.dayDeleted'),
          options: {
            type: TYPE.SUCCESS,
            icon: 'icon icon-check-circle-fill',
            timeout: 5000,
          },
        });
      } catch (e: any) {
        loadingToast.update('toast-remove-tour-day', {
          content: this.$t('tourBuilder.dayDeleteError'),
          options: {
            type: TYPE.ERROR,
            icon: 'icon icon-exclamation-triangle-fill',
            timeout: 5000,
          },
        });

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async updateTourDayLength(tourDay: Record<string, any>) {
      if ((!tourDay.stageLength && tourDay.stageLength === 0)
        || (!!tourDay.stageLength && tourDay.stageLength >= 0)) {
        await this.updateTourDay(tourDay);
      }
    },
    async createLocation(tourDay: Record<string, any>, address: Record<string, any>, isOrigin = true) {
      const location = await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });

      if (isOrigin) {
        tourDay.originId = location.id;
      } else {
        tourDay.destinationId = location.id;
      }

      await this.updateTourDay(tourDay);
    },
    setIsStage(tourDayType: string) {
      return tourDayType === 'stage';
    },
    async updateTourDayType(checkboxValue: boolean, tourDay: Record<string, any>) {
      if (!this.isTourDayUpdating) {
        tourDay.type = checkboxValue ? 'stage' : 'rest';

        await this.updateTourDay(tourDay);
      }
    },
    async updateTourDayOrigin(value: string, tourDay: Record<string, any>) {
      if (!value) {
        tourDay.originId = null;

        await this.updateTourDay(tourDay);
      }
    },
    async updateTourDayDestination(value: string, tourDay: Record<string, any>) {
      if (!value) {
        tourDay.destinationId = null;

        await this.updateTourDay(tourDay);
      }
    },
    async updateTourDayPax(tourDay: Record<string, any>) {
      if (!tourDay.pax) {
        this.paxError = this.$t('tourBuilder.paxErrorMessage');
      } else {
        this.paxError = '';
        await this.updateTourDay(tourDay);
      }
    },
    async updateTourDay(tourDay: Record<string, any>) {
      if (!this.isTourDayUpdating) {
        try {
          this.isTourDayUpdating = true;

          await api.tourDay.update(
            this.agency,
            this.clientTour.id,
            this.tourId,
            tourDay.id,
            {
              pax: tourDay.pax,
              date: tourDay.date,
              type: tourDay.type,
              origin: tourDay.originId,
              destination: tourDay.destinationId,
              stage_length: tourDay.stageLength,
            },
          );

          this.isTourDayUpdating = false;
        } catch (e: any) {
          this.isTourDayUpdating = false;

          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
  },
});
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.list-group {
  min-height: 20px;
}

.list-enter-from {
  opacity: 0;
  transform: scaleY(0.25);
}

.list-enter-active,
.list-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.list-move {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-active {
  position: relative;
}

.list-group-item {
  display: block !important;
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  width: 100% !important;
}
</style>
