import { AbstractService } from '@uniqoders/sdk';
import AgencyUser from '@/api/objects/AgencyUser';

export default class AgencyUserService extends AbstractService {
  /**
   * Retrieves the all users of the agency
   * @param agency
   * @protected
   */
  public all(agency: string): Promise<AgencyUser[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/users', { agency }),
    });
  }
}
