<template>
  <v-modal
    id="supplier-resource-details-modal"
    modal-classes="item-details"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('supplier.resource.title') }}
      </modal-title>
    </modal-header>

    <modal-body class="justify-content-between d-flex flex-column item-details">
      <div class="detail mb-20">
        <div class="detail-title d-flex justify-content-between mb-16">
          <div class="fw-medium">{{ $t('resource.basicInformation') }}</div>

          <v-button
            v-if="!resource.deletedAt"
            :disabled="status === 'loading'"
            class="btn-edit p-4"
            variant="icon"
            @click="editBasicInformation = !editBasicInformation">
            <v-icon icon="pencil"/>
          </v-button>
        </div>

        <div class="detail-content">
          <Transition mode="out-in" name="fade-in">
            <div v-if="status === 'loading'" class="position-relative py-64">
              <v-loader/>
            </div>

            <div v-else-if="!editBasicInformation" class="row">
              <div class="text-sm d-flex flex-column flex-sm-row py-4 col-12">
                <span class="info-title fw-medium">{{ $t('general.shared.name') }}:</span>
                <span v-if="!!resource.name">{{ resource.name }}</span>
                <span v-else>-</span>
              </div>

              <div class="text-sm d-flex flex-column flex-sm-row py-4 col-12">
                <span class="info-title fw-medium">{{ $t('general.shared.type') }}:</span>

                <span v-if="!!resource.type">
                  {{ findConceptByKey('supplier.type', resource.type).name }}
                </span>

                <span v-else>-</span>
              </div>

              <div class="text-sm d-flex py-4">
                <span class="info-title fw-medium">{{ $t('general.shared.amenities') }}: </span>
                <span v-if="!!allAmenities">{{ allAmenities }}</span>
                <span v-else>-</span>
              </div>

              <div class="resource-description text-sm d-flex flex-column flex-sm-row py-4 col-12">
                <span class="info-title fw-medium">{{ $t('general.shared.description') }}:</span>
                <span v-if="!!resource.description" v-html="resource.description"/>
                <span v-else>-</span>
              </div>

              <div class="text-sm d-flex flex-column flex-sm-row py-4 col-12">
                <span class="info-title fw-medium">{{ $t('general.shared.cancellationPolicy') }}:</span>

                <span v-if="!!resource.cancellationPolicy">
                  {{
                    findConceptByKey('supplier_resource.cancellation_policy_type', resource.cancellationPolicy).name
                  }}
                </span>

                <span v-else>-</span>
              </div>
            </div>

            <form
              v-else
              id="supplier-resource-update-form"
              class="d-flex flex-column justify-content-between h-100"
            >
              <div class="fieldset-translatable mb-16">
                <nav class="translations-tab">
                  <div id="nav-tab" class="nav nav-tabs" role="tablist">
                    <v-button
                      v-for="(language, index) in languages"
                      :id="`nav-supplier-create-basic-information-${language.key}`"
                      :key="index"
                      :aria-controls="`supplier-create-basic-information-${language.key}`"
                      :class="{ active: language.key === currentLanguage }"
                      :data-bs-target="`#supplier-create-basic-information-${language.key}`"
                      aria-selected="true"
                      class="nav-tab-link py-8 px-16 me-8"
                      data-bs-toggle="tab"
                      role="tab"
                      size="sm"
                      variant="outline-primary"
                      @click="currentLanguage = language.key"
                    >
                      {{ language.label }}
                    </v-button>
                  </div>
                </nav>

                <transition
                  mode="out-in"
                  name="fade"
                  appear
                >
                  <div
                    id="supplier-resource-update-basic-information-tabs"
                    class="tab-content"
                  >
                    <div
                      v-for="(language, index) in languages"
                      :id="`supplier-resource-update-basic-information-${language.key}`"
                      :key="index"
                      :aria-labelledby="`nav-supplier-resource-update-basic-information-${language.key}`"
                      :class="{ 'active show': language.key === currentLanguage }"
                      class="supplier-resource-update-basic-information-tab tab-pane fade"
                      role="tabpanel">
                      <the-supplier-resource-create-basic-information-fieldset
                        v-model:description="translations[language.key].description"
                        v-model:name="translations[language.key].name"
                        :clear="clear"
                        :current-language="currentLanguage"
                        :language="language.key"
                        @is-valid="isTranslationsValid.find((translation) => translation.locale === language.key).valid = $event"
                      />
                    </div>
                  </div>
                </transition>
              </div>

              <fieldset class="mb-16">
                <legend class="visually-hidden">{{ $t('resource.basicInformation') }}</legend>

                <v-form
                  id="supplier-resource-update-type-select"
                  :label="`${$t('general.shared.type')}*`"
                  :yup-errors-variable="errors.type"
                  form-type="outline"
                >
                  <v-select
                    v-model="type"
                    :class="{ 'is-invalid': !!errors.type }"
                    :options="supplierResourceTypes"
                    :reduce="returnValue => returnValue.key"
                  >
                    <template v-slot:no-options>
                      <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                    </template>
                  </v-select>
                </v-form>

                <v-form
                  id="supplier-resource-create-amenities-select"
                  :label="$t('general.shared.amenities')"
                  :yup-errors-variable="errors.amenities"
                  form-type="outline"
                >
                  <v-select
                    v-model="amenities"
                    :class="{ 'is-invalid': !!errors.amenities }"
                    :options="supplierAmenities"
                    :reduce="returnValue => returnValue.key"
                    multiple
                  >
                    <template v-slot:no-options>
                      <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                    </template>
                  </v-select>
                </v-form>

                <v-form
                  id="supplier-resource-create-cancellation-policy-select"
                  :label="`${$t('general.shared.cancellationPolicy')}*`"
                  :yup-errors-variable="errors.cancellationPolicy"
                  form-type="outline"
                >
                  <v-select
                    v-model="cancellationPolicy"
                    :class="{ 'is-invalid': !!errors.cancellationPolicy }"
                    :options="cancellationPolicyTypes"
                    :reduce="returnValue => returnValue.key"
                  >
                    <template v-slot:no-options>
                      <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                    </template>
                  </v-select>
                </v-form>
              </fieldset>

              <v-button
                :disabled="!isTranslationsValid.every(translation => translation.valid)"
                :is-loading="isButtonLoading"
                class="btn-submit-form btn-icon w-fit align-self-end"
                size="sm"
                variant="primary"
                @click="updateSupplierResource"
              >
                {{ $t('general.button.save') }}
                <v-icon icon="arrow-right" size="sm" space="ms-12"/>
              </v-button>
            </form>
          </Transition>
        </div>
      </div>

      <the-supplier-resource-details-media
        v-if="!resource.deletedAt && status === 'loaded'"
        :resource="resource"
        @reload-resource="loadSupplierResource"
      />
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import vSelect from 'vue-select';
import { VForm } from '@uniqoders/form';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useConcepts from '@/helpers/Concepts';
import SupplierResource from '@/api/objects/SupplierResource';
import api from '@/api';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import { findConceptByKey, mapConcepts } from '@/helpers/ConceptHelper';
import TheSupplierResourceDetailsMedia from '@/components/supplier/resource/TheSupplierResourceDetailsMedia.vue';
import TheSupplierResourceCreateBasicInformationFieldset
  from '@/components/supplier/resource/TheSupplierResourceCreateBasicInformationFieldset.vue';
import useFormValidation from '@/helpers/form';

export default defineComponent({
  name: 'TheSupplierResourceDetailsModal',
  components: {
    TheSupplierResourceCreateBasicInformationFieldset,
    TheSupplierResourceDetailsMedia,
    VLoader,
    VIcon,
    VButton,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VForm,
    vSelect,
  },
  emits: ['closed', 'reloadSupplier'],
  props: {
    resourceId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      cancellationPolicy: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.cancellationPolicy')),
      type: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.type')),
      amenities: yup.array()
        .nullable()
        .label(t('general.shared.amenities')),
    });

    const form = useFormValidation(rules);

    const { value: cancellationPolicy } = useField('cancellationPolicy');
    const { value: type } = useField('type');
    const { value: amenities } = useField('amenities');

    return {
      ...useConcepts(),
      findConceptByKey,
      ...form,
      cancellationPolicy,
      type,
      amenities,
    };
  },
  data() {
    return {
      status: 'loading' as string,
      resource: {} as SupplierResource,
      editBasicInformation: false as boolean,
      allAmenities: '' as string | any,
      translations: {} as Record<string, any>,
      currentLanguage: '',
      clear: false,
      isTranslationsValid: [] as Record<string, any>[],
      isButtonLoading: false as boolean,
    };
  },
  computed: {
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    languages(): any {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
    supplierResourceTypes(): any {
      if (!!this.concepts && this.concepts['supplier.type']) {
        return mapConcepts(this.concepts['supplier.type']);
      }

      return [];
    },
    supplierAmenities(): any {
      if (!!this.concepts && this.concepts['supplier.amenities']) {
        return mapConcepts(this.concepts['supplier.amenities']);
      }

      return [];
    },
    cancellationPolicyTypes(): any {
      if (!!this.concepts && this.concepts['supplier_resource.cancellation_policy_type']) {
        return mapConcepts(this.concepts['supplier_resource.cancellation_policy_type']);
      }

      return [];
    },
  },
  async created() {
    await this.mapTranslations();
    await this.loadSupplierResource();
    await this.setSupplierResourceData();

    this.currentLanguage = this.defaultLocale;

    this.status = 'loaded';
  },
  methods: {
    mapTranslations() {
      this.isTranslationsValid = [];

      this.languages.forEach((language: Record<string, any>) => {
        this.isTranslationsValid.push({
          locale: language.key,
          valid: false,
        });

        this.translations[language.key] = {
          name: '',
          description: '',
        };
      });
    },
    async loadSupplierResource() {
      this.resource = await api.supplierResource.retrieve(this.resourceId);
    },
    async setSupplierResourceData() {
      this.type = this.resource.type;
      this.amenities = JSON.parse(this.resource.amenities);
      this.cancellationPolicy = this.resource.cancellationPolicy;

      this.resource.translations.forEach((translation: any) => {
        this.translations[translation.locale].name = translation.name;
        this.translations[translation.locale].description = translation.description;

        const translationValid = this.isTranslationsValid.find(
          (translations) => translations.locale === translation.locale);

        if (!!translationValid) {
          translationValid.valid = true;
        }
      });

      if (Array.isArray(this.amenities)) {
        let isFirstType = true;

        this.amenities.forEach((type: string) => {
          const typeObject = this.supplierAmenities.find((amenity: any) => amenity.key === type);

          if (!!typeObject) {
            const typeLabel = typeObject.label;

            if (isFirstType) {
              this.allAmenities = typeLabel;

              isFirstType = false;
            } else {
              this.allAmenities = `${ this.allAmenities }, ${ typeLabel }`;
            }
          }
        });
      } else {
        this.allAmenities = this.amenities;
      }
    },
    async updateSupplierResource() {
      try {
        this.isButtonLoading = true;

        const data: any = {
          type: this.type,
          cancellation_policy: this.cancellationPolicy,
          amenities: this.amenities,
          translations: this.translations,
        };

        await api.supplierResource.update(this.resourceId, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.clear = !this.clear;
        this.isButtonLoading = false;

        this.$emit('reloadSupplier');
        this.$emit('closed');
      } catch (e: any) {
        this.isButtonLoading = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.detail-content {
  .info-title {
    margin-right: .5rem;
  }
}
</style>
