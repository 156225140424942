export default {
  resources: 'Informatie',
  noResources: 'Er zijn geen middelen',
  createNewResource: 'Nieuw middel aanmaken',
  createNewInformation: 'Nieuwe informatie aanmaken',
  archivedResources: 'Gearchiveerde middelen',
  activeResources: 'Actieve middelen',
  basicInformation: 'Basisinformatie',
  resourceDetails: 'Middelgegevens',
  archiveText: 'U kunt een middel archiveren. Als u dit doet, zal het middel niet verschijnen in de applicatie-zoekopdrachten. U kunt het zien in de lijst van middelen als u filtert op "gearchiveerd". Wanneer u de details van een gearchiveerd middel bekijkt, kunt u het definitief verwijderen of het uit het archief halen.',
  media: 'Media',
  noMedia: 'Er zijn geen bijbehorende media',
  archivedText: 'Dit middel is gearchiveerd, als u wilt kunt u het uit het archief halen door op de \'Herstellen\' knop te klikken.',
  archiveResourceTitle: 'Middel archiveren',
  archiveResourceText: 'Weet u zeker dat u het middel \'{resource}\' wilt archiveren? Als u dit doet, zal het niet verschijnen in de zoekopdrachten.',
  deleteText: 'Dit middel is gearchiveerd, als u het permanent wilt verwijderen, klikt u op de \'Verwijderen\' knop. Let op dat als u dit doet, dit middel niet langer bestaat en niet kan worden hersteld.',
  deleteResourceTitle: 'Middel verwijderen',
  deleteResourceText: 'Weet u zeker dat u het middel \'{resource}\' wilt verwijderen? Als u dit doet, kunt u het niet meer herstellen.',
  restoreResourceText: 'Weet u zeker dat u het middel \'{resource}\' wilt herstellen? Als u dit doet, wordt het niet langer gearchiveerd en zal het verschijnen in de applicatie-zoekopdrachten.',
  resourcesStatus: 'Middelen status',
  resourceSearch: 'Middel zoeken',
  canSearchResource: 'U kunt zoeken naar het middel',
  orUpdateItManually: 'of handmatig bijwerken',
  removeMediaTitle: 'Media verwijderen',
  removeMediaText: 'Weet u zeker dat u het archief wilt verwijderen? Als u dit doet, kunt u het niet meer herstellen.',
  informationDetails: 'Details van de informatie',
};
