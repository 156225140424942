import { AbstractService } from '@uniqoders/sdk';
import Template from '@/api/objects/Template';
import Location from '@/api/objects/Location';

export default class TemplateService extends AbstractService {
  /**
   * Retrieves all agency Templates
   * @protected
   * @param agency
   * @param params
   */
  public all(agency: string, params?: any): Promise<Template[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/templates', { agency }),
      params,
    });
  }

  /**
   * Retrieves all agency Templates paginated
   * @protected
   * @param agency
   * @param params
   * @param paginate
   */
  public allPaginated(agency: string, params: any, paginate = true): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/templates', { agency }),
      params,
      options: {
        paginate,
      },
    });
  }

  /**
   * Retrieves all Template Locations
   *
   * @param agency
   * @param template
   */
  public locations(agency: string, template: number): Promise<Location[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/template/{template}/locations', { agency, template }),
    });
  }

  /**
   * Retrieves a specific Template
   * @protected
   */
  public retrieve(agency: string, template: number): Promise<Template> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/template/{template}', { agency, template }),
    });
  }

  /**
   * Creates a single Template
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   */
  public create(agency: string, clientTour: number, tour: number): Promise<Template> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/template', { agency, clientTour, tour }),
    });
  }

  /**
   * Creates a empty Template
   * @protected
   * @param agency
   * @param data
   */
  public createEmpty(agency: string, data: any): Promise<Template> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/template/empty', { agency }),
      data,
    });
  }

  /**
   * Updates the specified Template
   * @param agency
   * @param template
   * @param data
   * @protected
   */
  public update(agency: string, template: string | number, data: Record<string, any>): Promise<Template> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/template/{template}', { agency, template }),
      data,
    });
  }

  /**
   * Archives the specified Template
   * @protected
   * @param agency
   * @param template
   */
  public archive(agency: string, template: number | string): Promise<Template> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/template/{template}', { agency, template }),
    });
  }

  /**
   * Restore the specified Template
   * @protected
   * @param agency
   * @param template
   */
  public restore(agency: string, template: string | number): Promise<Template> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/template/{template}/restore', { agency, template }),
    });
  }

  /**
   * Deletes the specified Template
   * @protected
   * @param agency
   * @param template
   */
  public delete(agency: string, template: string | number): Promise<Template> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/template/{template}/force', { agency, template }),
    });
  }

  /**
   * Attach agencies
   * @protected
   * @param template
   * @param data
   */
  public attachAgencies(template: string | number, data: Record<string, any>): Promise<Template> {
    return this.request({
      method: 'post',
      url: this.buildPath('/template/{template}/attach/agencies', { template }),
      data,
    });
  }

  /**
   * Detach agencies
   * @protected
   * @param template
   * @param data
   */
  public detachAgencies(template: string | number, data: Record<string, any>): Promise<Template> {
    return this.request({
      method: 'post',
      url: this.buildPath('/template/{template}/detach/agencies', { template }),
      data,
    });
  }

  /**
   * Duplicates a Template
   * @protected
   * @param agency
   * @param template
   */
  public duplicate(agency: string, template: string | number): Promise<Template> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/template/{template}/duplicate', { agency, template }),
    });
  }
}
