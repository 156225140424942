import { AbstractService } from '@uniqoders/sdk';

export default class MandrillService extends AbstractService {
  /**
   * Confirm reservation
   * @protected
   * @param params
   */
  public accept(params: any): Promise<any> {
    return this.request({
      method: "get",
      url: this.buildPath("/response/accepted"),
      params,
    });
  }

  /**
   * Confirm reservation
   * @protected
   * @param params
   * @param data
   */
  public modified(params: any, data: any): Promise<any> {
    return this.request({
      method: "post",
      url: this.buildPath("/response/modified"),
      params,
      data
    });
  }
}
