<template>
  <div
    v-if="!!user && !!user.impersonatedBy"
    id="impersonate-container"
    class="impersonate-container d-flex justify-content-between align-items-center border-bottom bg-tertiary text-light
    px-16"
  >
    <div class="text-xs py-4">
      {{
        this.$t('general.menu.impersonateText', {
          user: user.name,
          email: user.email,
        })
      }}
    </div>

    <v-button
      id="btn-stop-impersonate"
      class="btn-icon h-100 shadow-none p-0 ms-8"
      size="sm"
      variant="link-light"
      @click="impersonateStop"
    >
      <v-icon icon="arrow-left" size="sm" space="me-12"/>
      {{ $t('general.menu.impersonateStopText') }}
    </v-button>
  </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { useAuthStore } from '@/stores/auth';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';

export default defineComponent({
  name: 'TheImpersonateStop',
  components: {
    VIcon,
    VButton,
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
  },
  created() {
    if (!!this.user && !!this.user.impersonatedBy) {
      const body: any = document.getElementById('body');

      body.classList.add('impersonate-active');
    }
  },
  mounted() {
    if (!!this.user && !!this.user.impersonatedBy) {
      const header: any = document.getElementById('top');
      const main: any = document.getElementById('main');

      if (!!header && !!main) {
        const height = header.offsetHeight;

        main.style.paddingTop = `${height}px`;
      }
    }
  },
  unmounted() {
    const body: any = document.getElementById('body');

    body.classList.remove('impersonate-active');

    const header: any = document.getElementById('top');
    const main: any = document.getElementById('main');

    if (!!header && !!main) {
      const height = header.offsetHeight;

      main.style.paddingTop = `${height}px`;
    }
  },
  methods: {
    async impersonateStop() {
      await this.authStore.impersonateStop();
    },
  },
});
</script>
