<template>
  <form
    id="case-tour-update-form"
    class="d-flex flex-column justify-content-between h-100"
  >
    <fieldset>
      <legend class="visually-hidden">{{ $t('clientTour.tourBasicInformation') }}</legend>

      <v-form-input
        id="tour-update-name"
        v-model="name"
        :label="$t('clientTour.tourName')"
        :yup-errors-variable="errors.name"
        autocomplete="off"
        class="col-12"
        form-type="outline"
      />

      <v-form
        id="case-create-travel-type-select"
        :label="`${$t('clientTour.travelType')}*`"
        :yup-errors-variable="errors.travelType"
        form-type="outline"
      >
        <v-select
          id="case-create-travel-type-select"
          v-model="travelType"
          :clearable="false"
          :options="travelTypes"
          :reduce="returnValue => returnValue.key"
          class="mb-8"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-input-address
        id="case-tour-origin-search"
        ref="case-tour-origin-search"
        :get-address="true"
        :get-radius-bounds="true"
        :label="`${$t('general.shared.origin')}*`"
        :radius-bounds="20000"
        :value="searchLocation(origin)"
        :yup-errors-variable="errors.originSelected"
        class="col-12"
        @address="createLocation($event, true)"
        @input="checkLocationInput($event, true)"
      />

      <v-input-address
        id="case-tour-destination-search"
        ref="case-tour-destination-search-ref"
        :get-address="true"
        :get-radius-bounds="true"
        :label="`${$t('general.shared.destination')}*`"
        :radius-bounds="20000"
        :value="searchLocation(destination)"
        :yup-errors-variable="errors.destinationSelected"
        class="col-12"
        @address="createLocation($event, false)"
        @input="checkLocationInput($event, false)"
      />
    </fieldset>

    <v-button
      :disabled="!meta.valid || disableButton || !meta.dirty"
      :is-loading="accepted"
      class="btn-icon w-fit align-self-end mt-32 mt-lg-48"
      size="sm"
      variant="primary"
      @click="updateTour"
    >
      {{ $t('general.button.save') }}
      <v-icon icon="arrow-right" size="sm" space="ms-12"/>
    </v-button>
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { VForm, VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import vSelect from 'vue-select';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useFormValidation from '@/helpers/form';
import api from '@/api';
import useModalUtils from '@/helpers/ModalUtils';
import Tour from '@/api/objects/Tour';
import { mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import Location from '@/api/objects/Location';

export default defineComponent({
  name: 'TheCaseSetupTourDetails',
  components: {
    VIcon,
    VButton,
    VFormInput,
    vSelect,
    VForm,
    VInputAddress,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
    clientTourId: {
      type: Number,
      required: true,
    },
  },
  emits: ['reloadClientTour'],
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .nullable()
        .label(t('clientTour.tourName')),
      travelType: yup.string()
        .required()
        .label(t('clientTour.travelType')),
      originSelected: yup.bool()
        .oneOf([true], t('general.shared.selectOriginMessage')),
      origin: yup.mixed()
        .required()
        .nullable()
        .label(t('general.shared.origin')),
      destination: yup.mixed()
        .required()
        .nullable()
        .label(t('general.shared.destination')),
      destinationSelected: yup.bool()
        .oneOf([true], t('general.shared.selectDestinationMessage')),
    });

    const initialValues: Record<string, any> = {
      name: props.tour.name,
      travelType: props.tour.travelType,
      origin: props.tour.originId,
      destination: props.tour.destinationId,
    };

    if (!!props.tour.originId) {
      initialValues.originSelected = true;
    }

    if (!!props.tour.destinationId) {
      initialValues.destinationSelected = true;
    }

    const form = useFormValidation(rules, initialValues);

    const { value: name } = useField('name');
    const { value: travelType } = useField('travelType');
    const { value: origin } = useField('origin');
    const { value: destination } = useField('destination');
    const { value: originSelected } = useField('originSelected');
    const { value: destinationSelected } = useField('destinationSelected');

    return {
      ...useConcepts(),
      ...useModalUtils(),
      ...form,
      name,
      travelType,
      origin,
      destination,
      originSelected,
      destinationSelected,
    };
  },
  data() {
    return {
      locations: [] as Location[],
      disableButton: false as boolean,
    };
  },
  computed: {
    travelTypes(): any {
      if (!!this.concepts && this.concepts['tour.travel_type']) {
        return mapConcepts(this.concepts['tour.travel_type']);
      }

      return [];
    },
  },
  async created() {
    await this.loadLocations();
  },
  methods: {
    checkLocationInput(value: string, isOrigin = false) {
      if (isOrigin) {
        this.originSelected = false;
      } else {
        this.destinationSelected = false;
      }
    },
    async loadLocations() {
      this.locations = await api.location.all();
    },
    searchLocation(locationId: number): string {
      const locationName = this.locations.find((location) => location.id === locationId);

      if (!!locationName) {
        return locationName.name;
      }

      return '';
    },
    async createLocation(address: Record<string, any>, isOrigin = true) {
      const location: Location = await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });

      await this.loadLocations();

      if (isOrigin) {
        this.origin = location.id;
        this.originSelected = true;
      } else {
        this.destination = location.id;
        this.destinationSelected = true;
      }
    },
    async updateTour() {
      try {
        this.toggleAccepted();
        this.disableButton = true;

        const data = {
          name: this.name,
          travel_type: this.travelType,
          origin: this.origin,
          destination: this.destination,
          contact_text: this.tour.contactText,
        };

        await api.tour.update(this.agency, this.clientTourId, this.tour.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadClientTour');

        this.disableButton = false;
        this.toggleAccepted();
      } catch (e: any) {
        this.disableButton = false;
        this.toggleAccepted();

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
