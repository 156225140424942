<template>
  <span :class="objectClasses" class="icon"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VIcon',
  props: {
    icon: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    space: {
      type: String,
      required: false,
    },
  },
  computed: {
    objectClasses() {
      let oClass = '';
      oClass += !!this.icon ? `icon-${this.icon}` : '';
      oClass += !!this.size ? ` text-${this.size}` : '';
      oClass += !!this.space ? ` ${this.space}` : '';
      return oClass;
    },
  },
});
</script>
