import { AbstractService } from '@uniqoders/sdk';
import { useAuthStore } from '@/stores/auth';
import User from '@/api/objects/User';

export default class AuthService extends AbstractService {
  public async authCheck() {
    return this.request({
      method: 'get',
      url: this.buildPath('/auth/check'),
    });
  }

  public async login(data: any) {
    return this.request({
      method: 'post',
      url: this.buildPath('/login'),
      data,
    });
  }

  public async socialLogin(provider: string): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/auth/social/{provider}/redirect', { provider }),
    });
  }

  /**
   * Checks with Google if user can be logged in by social login
   * @param provider
   * @param params
   */
  public async confirmSocialAuth(provider: string, params: any): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/auth/social/{provider}/confirm', { provider }),
      params,
    });
  }

  public logout() {
    return this.request({
      method: 'post',
      url: this.buildPath('/logout'),
    });
  }

  public async forgotPassword(data: any) {
    return this.request({
      method: 'post',
      url: this.buildPath('/forgot-password'),
      data,
    });
  }

  public async check(): Promise<boolean> {
    const authStore = useAuthStore();

    if (!authStore.logged) {
      try {
        const logged = await this.authCheck();

        authStore.setLogged(logged);

        if (logged) {
          await authStore.setAuthUser();
        }
      } catch (e) {
        console.error(e);
      }
    }

    return authStore.logged;
  }

  public user(): Promise<User> {
    return this.request({
      method: 'get',
      url: this.buildPath('/user/me'),
    });
  }
}
