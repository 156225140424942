<template>
  <v-modal
    id="admin-agency-user-details-modal"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('admin.agency.user') }}
      </modal-title>
    </modal-header>

    <modal-body class="justify-content-between d-flex flex-column item-details">
      <the-admin-user-details-basic-information
        :user-details="user"
        @reload-user="$emit('reloadItem')"
      />
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import User from '@/api/objects/User';
import TheAdminUserDetailsBasicInformation from '@/components/admin/user/parts/TheAdminUserDetailsBasicInformation.vue';

export default defineComponent({
  name: 'TheAdminAgencyUserDetailsModal',
  components: {
    TheAdminUserDetailsBasicInformation,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  emits: [
    'closed',
    'reloadItem',
  ],
  props: {
    user: {
      type: User,
      required: true,
    },
  },
});
</script>
