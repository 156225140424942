import { AbstractService } from '@uniqoders/sdk';
import Pax from '@/api/objects/Pax';
import Tour from '@/api/objects/Tour';
import Template from '@/api/objects/Template';

export default class PaxService extends AbstractService {
  /**
   * Retrieves all Paxes of a specific case
   * @protected
   * @param agency
   * @param clientTour
   */
  public caseAll(agency: string, clientTour: string | number): Promise<Pax[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/paxes', { agency, clientTour }),
    });
  }

  /**
   * Retrieves all Paxes of a specific tour
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   */
  public all(agency: string, clientTour: number, tour: number): Promise<Pax[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/paxes', { agency, clientTour, tour }),
    });
  }

  /**
   * Retrieves a specific tour with paxes
   * @protected
   * @param tour
   * @param token
   */
  public retrieveTour(tour: number, token: any): Promise<Tour> {
    return this.request({
      method: 'get',
      url: this.buildPath('/tour/{tour}', { tour }),
      params: token,
    });
  }

  /**
   * Store all pax for a specific tour
   * @protected
   * @param tour
   * @param token
   * @param data
   */
  public create(tour: number, token: any, data: any): Promise<Pax[]> {
    return this.request({
      method: 'post',
      url: this.buildPath('/tour/{tour}/pax', { tour }),
      params: token,
      data,
      options: {
        cancellable: true,
        cancelKey: 'createPax',
      },
    });
  }

  /**
   * Deletes the specified pax
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param pax
   */
  public delete(agency: string, clientTour: string | number, tour: number, pax: number): Promise<Template> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/pax/{pax}', { agency, clientTour, tour, pax }),
    });
  }

  /**
   * Update a specific pax
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param pax
   * @param data
   */
  public update(agency: string, clientTour: number, tour: number, pax: string | number, data: any): Promise<Pax> {
    return this.request({
      method: 'put',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/pax/{pax}', { agency, clientTour, tour, pax }),
      data,
    });
  }

  /**
   * Send request email to client
   * @protected
   * @param agency
   * @param clientTour
   * @param data
   */
  public sendEmail(
    agency: string,
    clientTour: string | number,
    data: Record<string, any>): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/pax/notification', { agency, clientTour }),
      data,
    });
  }
}
