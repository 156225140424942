<template>
  <div :id="id">
    <fieldset>
      <legend class="visually-hidden">{{ $t('general.address.address') }}</legend>

      <div class="search-separator-container mb-8" v-if="!hiddenLegend">
        <div class="separator-text-container">
          <span class="separator-text text-sm fw-medium">{{ $t('general.address.address') }}</span>
        </div>
      </div>

      <div class="row gx-12">
        <div class="col-12">
          <v-input-address
            :id="`${idPrefix}-address`"
            :ref="`${idPrefix}-address-ref`"
            :disabled-input="allDisabled"
            :form-floating="false"
            :get-address="true"
            :label="`${this.$t('general.address.streetAddress')}${addressRequired ? '*' : ''}`"
            :value="address"
            class="mb-8"
            @address="setContactAddress"
          />
        </div>

        <div class="col-12">
          <v-form-input
            :id="`${idPrefix}-second-address`"
            v-model="secondAddress"
            :disabled="allDisabled"
            :label="this.$t('general.address.aptSuit')"
            :yup-errors-variable="errors.secondAddress"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            @update:modelValue="setFormattedAddress"
          />
        </div>

        <div class="col-12">
          <v-form-input
            :id="`${idPrefix}-country`"
            v-model="country"
            :disabled="allDisabled"
            :label="`${this.$t('general.address.country')}${addressRequired ? '*' : ''}`"
            :yup-errors-variable="errors.country"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            @update:modelValue="setFormattedAddress"
          />
        </div>

        <div class="col-12">
          <v-form-input
            :id="`${idPrefix}-postal-code`"
            v-model="postalCode"
            :disabled="allDisabled"
            :label="`${this.$t('general.address.postalCode')}${addressRequired ? '*' : ''}`"
            :yup-errors-variable="errors.postalCode"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            @update:modelValue="setFormattedAddress"
          />
        </div>

        <div class="col-12">
          <v-input-address
            :id="`${idPrefix}-city-search`"
            :ref="`${idPrefix}-city-search-ref`"
            :disabled-input="allDisabled"
            :get-address="true"
            :label="`${this.$t('general.address.city')}${addressRequired ? '*' : ''}`"
            :options="{
              types: ['(cities)'],
              fields: ['place_id', 'name', 'vicinity', 'formatted_address', 'address_component'],
            }"
            :value="city"
            class="mb-8"
            @address="setCity"
            @input="checkCityValue"
          />
        </div>

        <div class="col-12">
          <v-form-input
            :id="`${idPrefix}-region`"
            v-model="state"
            :disabled="allDisabled"
            :label="`${this.$t('general.address.provinceStateRegion')}${addressRequired ? '*' : ''}`"
            :yup-errors-variable="errors.state"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            @update:modelValue="setFormattedAddress"
          />
        </div>
        <slot name="vatInput"></slot>
      </div>
    </fieldset>

    <fieldset v-if="!withoutBillingAddress">
      <legend class="visually-hidden">{{ $t('general.address.billingAddress') }}</legend>

      <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
        <div class="fw-medium">{{ $t('general.address.billingAddress') }}</div>

        <v-button
          v-if="!billingAlwaysVisible && !showBillingAddress"
          :disabled="!address"
          class="btn-edit p-4"
          variant="icon"
          @click="showBillingAddress = !showBillingAddress"
        >
          <v-icon icon="pencil"/>
        </v-button>
      </div>

      <p v-if="!billingAlwaysVisible && !showBillingAddress">
        {{ $t('general.address.matchesContactAddress') }}
      </p>

      <v-form-input-radio
        v-if="billingAlwaysVisible || showBillingAddress"
        ref="contact-and-billing-ref"
        v-model="contactAndBilling"
        :formValueOptions="[
          { key: 'matchesAddress', label: formattedAddress },
          { key: 'notMatchesAddress', label: this.$t('general.address.addAddress') },
        ]"
        form-class="mb-8"
      />

      <div v-if="contactAndBilling === 'notMatchesAddress'" class="row gx-12">
        <div class="col-12">
          <v-input-address
            :id="`${idPrefix}-billing-address`"
            :ref="`${idPrefix}-billing-address-ref`"
            :form-floating="false"
            :get-address="true"
            :label="`${this.$t('general.address.streetAddress')}${addressRequired ? '*' : ''}`"
            :value="billingAddress"
            class="mb-8"
            @address="setBillingAddress"
          />
        </div>

        <div class="col-12">
          <v-form-input
            :id="`${idPrefix}-billing-second-address`"
            v-model="billingAddress2"
            :label="this.$t('general.address.aptSuit')"
            :yup-errors-variable="errors.billingAddress2"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            @update:modelValue="setFormattedAddress"
          />
        </div>

        <div class="col-12">
          <v-form-input
            :id="`${idPrefix}-billing-country`"
            v-model="billingCountry"
            :label="`${this.$t('general.address.country')}${addressRequired ? '*' : ''}`"
            :yup-errors-variable="errors.billingCountry"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            @update:modelValue="setFormattedAddress"
          />
        </div>

        <div class="col-12">
          <v-form-input
            :id="`${idPrefix}-billing-postal-code`"
            v-model="billingPostalCode"
            :label="`${this.$t('general.address.postalCode')}${addressRequired ? '*' : ''}`"
            :yup-errors-variable="errors.billingPostalCode"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            @update:modelValue="setFormattedAddress"
          />
        </div>

        <div class="col-12">
          <v-form-input
            :id="`${idPrefix}-billing-city`"
            v-model="billingCity"
            :label="`${this.$t('general.address.city')}${addressRequired ? '*' : ''}`"
            :yup-errors-variable="errors.billingCity"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            @update:modelValue="setFormattedAddress"
          />
        </div>

        <div class="col-12">
          <v-form-input
            :id="`${idPrefix}-billing-region`"
            v-model="billingState"
            :label="`${this.$t('general.address.provinceStateRegion')}${addressRequired ? '*' : ''}`"
            :yup-errors-variable="errors.billingState"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            @update:modelValue="setFormattedAddress"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput, VFormInputRadio } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useModalUtils from '@/helpers/ModalUtils';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import api from '@/api';
import Location from '@/api/objects/Location';

export default defineComponent({
  name: 'VContactBillingForm',
  components: {
    VInputAddress,
    VIcon,
    VButton,
    VFormInput,
    VFormInputRadio,
  },
  emits: ['updatedData', 'isValid'],
  props: {
    id: {
      type: String,
      required: false,
      default: 'contact-billing-form',
    },
    idPrefix: {
      type: String,
      required: false,
      default: 'item',
    },
    addressRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    contactAndBillingMatches: {
      type: Boolean,
      required: false,
      default: true,
    },
    billingAlwaysVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    data: {
      type: Object,
      required: false,
    },
    hiddenLegend: {
      type: Boolean,
      required: false,
      default: false,
    },
    withoutBillingAddress: {
      type: Boolean,
      required: false,
      default: false,
    },
    allDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    createStreetLocation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      contactAndBilling: yup.string()
        .required()
        .oneOf(['matchesAddress', 'notMatchesAddress']),
      address: yup.lazy(() => {
        const rule = yup.string()
          .label(t('general.address.streetAddress'));

        if (props.addressRequired) {
          return rule.required();
        }

        return rule.nullable();
      }),
      secondAddress: yup.string()
        .nullable()
        .label(t('general.address.aptSuit')),
      postalCode: yup.lazy(() => {
        const rule = yup.string()
          .label(t('general.address.postalCode'));

        if (props.addressRequired) {
          return rule.required();
        }

        return rule.nullable();
      }),
      country: yup.lazy(() => {
        const rule = yup.string()
          .label(t('general.address.country'));

        if (props.addressRequired) {
          return rule.required();
        }

        return rule.nullable();
      }),
      city: yup.lazy(() => {
        const rule = yup.string()
          .label(t('general.address.city'));

        if (props.addressRequired) {
          return rule.required();
        }

        return rule.nullable();
      }),
      state: yup.lazy(() => {
        const rule = yup.string()
          .label(t('general.address.provinceStateRegion'));

        if (props.addressRequired) {
          return rule.required();
        }

        return rule.nullable();
      }),
      billingAddress: yup.lazy((value, options) => {
        const rule = yup.string()
          .label(t('general.address.streetAddress'));

        if (props.addressRequired) {
          const { contactAndBilling } = options.parent;

          if (contactAndBilling === 'notMatchesAddress') {
            return rule.required();
          }
        }

        return rule.nullable();
      }),
      billingAddress2: yup.string()
        .nullable()
        .label(t('general.address.aptSuit')),
      billingCity: yup.lazy((value, options) => {
        const rule = yup.string()
          .label(t('general.address.city'));

        if (props.addressRequired) {
          const { contactAndBilling } = options.parent;

          if (contactAndBilling === 'notMatchesAddress') {
            return rule.required();
          }
        }

        return rule.nullable();
      }),
      billingCountry: yup.lazy((value, options) => {
        const rule = yup.string()
          .label(t('general.address.country'));

        if (props.addressRequired) {
          const { contactAndBilling } = options.parent;

          if (contactAndBilling === 'notMatchesAddress') {
            return rule.required();
          }
        }

        return rule.nullable();
      }),
      billingState: yup.lazy((value, options) => {
        const rule = yup.string()
          .label(t('general.address.provinceStateRegion'));

        if (props.addressRequired) {
          const { contactAndBilling } = options.parent;

          if (contactAndBilling === 'notMatchesAddress') {
            return rule.required();
          }
        }

        return rule.nullable();
      }),
      billingPostalCode: yup.lazy((value, options) => {
        const rule = yup.string()
          .label(t('general.address.postalCode'));

        if (props.addressRequired) {
          const { contactAndBilling } = options.parent;

          if (contactAndBilling === 'notMatchesAddress') {
            return rule.required();
          }
        }

        return rule.nullable();
      }),
    });

    const initialValues = {
      contactAndBilling: 'matchesAddress',
    };

    if (!props.contactAndBillingMatches) {
      initialValues.contactAndBilling = 'notMatchesAddress';
    }

    const form = useFormValidation(rules, initialValues);

    const { value: contactAndBilling } = useField('contactAndBilling');
    const { value: address } = useField('address');
    const { value: secondAddress } = useField('secondAddress');
    const { value: postalCode } = useField('postalCode');
    const { value: country } = useField('country');
    const { value: city } = useField('city');
    const { value: state } = useField('state');

    const { value: billingAddress } = useField('billingAddress');
    const { value: billingAddress2 } = useField('billingAddress2');
    const { value: billingCity } = useField('billingCity');
    const { value: billingCountry } = useField('billingCountry');
    const { value: billingState } = useField('billingState');
    const { value: billingPostalCode } = useField('billingPostalCode');

    return {
      ...useModalUtils(),
      ...form,
      contactAndBilling,
      address,
      secondAddress,
      postalCode,
      country,
      city,
      state,
      billingAddress,
      billingAddress2,
      billingCity,
      billingCountry,
      billingState,
      billingPostalCode,
    };
  },
  data() {
    return {
      localValue: this.data as Record<string, any>,
      showBillingAddress: false as boolean,
      editBasicInformation: false as boolean,
      formattedAddress: '' as string,
      location: {} as Location,
      lat: null as null | number,
      lng: null as null | number,
    };
  },
  watch: {
    contactAndBilling() {
      this.emitData();
    },
    data: {
      async handler(newValue) {
        if (!!newValue) {
          this.localValue = newValue;
          await this.setData();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.setData();
    await this.setFormattedAddress();
  },
  methods: {
    setData() {
      if (!!this.localValue) {
        this.address = this.localValue.contactAddress1;
        this.secondAddress = this.localValue.contactAddress2;
        this.postalCode = this.localValue.contactPostalCode;
        this.country = this.localValue.contactCountry;
        this.city = this.localValue.contactCity;
        this.state = this.localValue.contactState;
        this.billingAddress = this.localValue.billingAddress1;
        this.billingAddress2 = this.localValue.billingAddress2;
        this.billingPostalCode = this.localValue.billingPostalCode;
        this.billingCountry = this.localValue.billingCountry;
        this.billingCity = this.localValue.billingCity;
        this.billingState = this.localValue.billingState;
        this.lat = this.localValue.lat;
        this.lng = this.localValue.lng;
      }
    },
    async setContactAddress(address: any) {
      this.address = address.name;
      this.country = address.country.longName;
      this.city = address.locality.longName;
      this.state = address.regionProvince.longName;
      this.postalCode = address.postalCode.longName;
      this.lat = address.lat;
      this.lng = address.lng;

      await this.setFormattedAddress();

      if (this.createStreetLocation) {
        await this.createLocation(address);
      }
    },
    setBillingAddress(address: any) {
      this.billingAddress = address.name;
      this.billingCity = address.locality.longName;
      this.billingCountry = address.country.longName;
      this.billingState = address.regionProvince.longName;
      this.billingPostalCode = address.postalCode.longName;

      this.setFormattedAddress();
    },
    setFormattedAddress() {
      let formattedAddress = '';

  if (this.address) {
    formattedAddress += `${this.address}, `;
  }

  if (this.secondAddress) {
    formattedAddress += `${this.secondAddress}, `;
  }

  if (this.postalCode) {
    formattedAddress += `${this.postalCode} `;
  }

  if (this.city) {
    formattedAddress += `${this.city}, `;
  }

  if (this.state) {
    formattedAddress += `${this.state}, `;
  }

  if (this.country) {
    formattedAddress += this.country;
  }

  if (!formattedAddress.trim()) {
    this.contactAndBilling = 'matchesAddress';
    this.formattedAddress = this.$t('general.address.matchesContactAddress');
  } else {
    this.formattedAddress = formattedAddress.replace(/,\s*$/, '');
  }

      this.emitData();
    },
    async emitData() {
      const data: any = {
        location: this.location.id,
        locationId: this.location.id,
        contactAddress1: this.address,
        contactAddress2: this.secondAddress,
        contactPostalCode: this.postalCode,
        contactCountry: this.country,
        contactCity: this.city,
        contactState: this.state,
        lat: this.lat,
        lng: this.lng,
      };

      if (!this.withoutBillingAddress) {
        if (this.contactAndBilling === 'matchesAddress') {
          data.billingAddress1 = this.address;
          data.billingAddress2 = this.secondAddress;
          data.billingPostalCode = this.postalCode;
          data.billingCountry = this.country;
          data.billingCity = this.city;
          data.billingState = this.state;
        } else {
          data.billingAddress1 = this.billingAddress;
          data.billingAddress2 = this.billingAddress2;
          data.billingPostalCode = this.billingPostalCode;
          data.billingCountry = this.billingCountry;
          data.billingCity = this.billingCity;
          data.billingState = this.billingState;
        }
      }

      this.$emit('updatedData', data);
      this.$emit('isValid', this.meta.valid);
    },
    clearForm() {
      this.resetForm();
      if (!!this.$refs[`${this.idPrefix}-address-ref`]) {
        (this.$refs[`${this.idPrefix}-address-ref`] as any).valueLocal = '';
      }

      if (!!this.$refs[`${this.idPrefix}-billing-address-ref`]) {
        (this.$refs[`${this.idPrefix}-billing-address-ref`] as any).valueLocal = '';
      }

      this.contactAndBilling = 'matchesAddress';
      this.showBillingAddress = false;
      this.formattedAddress = '';
    },
    async setCity(address: Record<string, any>) {
      this.city = address.locality.longName;

      await this.createLocation(address);
    },
    async createLocation(address: Record<string, any>) {
      this.location = await api.location.create({
        name: address.locality.longName,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });
    },
    checkCityValue(city: string) {
      if (!city) {
        this.city = '';
      }
    },
  },
});
</script>
