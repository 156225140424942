export default {
  greeting: "Hoi",
  hope_you_are_fine: "Hoop dat het goed met je gaat!",
  email_subject: "Onderwerp:",
  client: "Klant:",
  case: "Geval:",
  room: "Kamer:",
  quantity: "Aantal:",
  check_in_date: "Incheckdatum:",
  check_out_date: "Uitcheckdatum:",
  nights: "Nachten:",
  number_of_people: "Aantal personen:",
  observations: "Opmerkingen:",
  understanding: "Bedankt voor uw hulp.",
  request: "U heeft een *Request* voor deze reservering gemaakt in uw verblijf:",
  mofication: "U heeft een *Modify* voor deze reservering gemaakt in uw verblijf:",
  reminder: "U heeft een *Cancel* voor deze reservering gemaakt in uw verblijf:",
  invoice_request: "U heeft een *Request* voor een factuur voor deze reservering gemaakt in uw verblijf:",
  payment_information: "U heeft een *Request* voor betalingsinformatie voor deze reservering gemaakt in uw verblijf:",
  email_warning: "Waarschuwing",
  price_warning: "De prijswaarde kan niet op 0 of negatief worden ingesteld.",
  free_text_warning: "De vrije tekst mag niet leeg zijn.",
};
