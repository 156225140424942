<template>
  <div id="page-resume-days">
    <div class="page-inner container">
      <div v-if="loaded" class="tour-data-container">
        <div class="tour-data">
          <h3 class="h5 mb-12">{{ $t('travelBook.tourResume') }}</h3>
          <div class="tour-resume-container">
            <div class="tour-resume-day">
              <div class="day-data" v-if="tourTemplate">
                <component :is="isSummary ? 'div' : 'a'"
                  :href="isSummary ? '#' : `#accordion-tour-day-before-${tourTemplate?.beforeInformation?.id}`"
                  class="text-decoration-none p-16 p-lg-24 d-flex justify-content-between m-16">
                  <div class="d-flex flex-column flex-md-row align-items-md-center pe-16">
                    {{ $t('clientTour.beforeTour') }}
                  </div>

                  <v-button v-if="!isSummary" class="w-fit text-nowrap px-16 py-8" size="xs" variant="primary">
                    {{ $t('clientTour.beforeTour') }}
                  </v-button>
                </component>
              </div>
            </div>
          </div>

          <div class="tour-resume-container">
            <div v-for="tourDay in tourDays" :key="tourDay.id" class="tour-resume-day">
              <div class="day-data">
                <component :is="isSummary ? 'div' : 'a'" :href="isSummary ? '#' : `#accordion-tour-day-${tourDay.id}`"
                  class="text-decoration-none p-16 p-lg-24 d-flex justify-content-between m-16">
                  <div class="d-flex flex-column flex-md-row align-items-md-center pe-16">
                    <div v-if="tourDay.type === 'stage'"
                      class="locations d-flex align-items-center text-sm mb-4 mb-lg-0">
                      <v-icon icon="run" size="xl" space="me-12" />

                      <div v-if="!!tourDay.originId && !!tourDay.origin">{{ tourDay.origin.name }}</div>
                      <div v-else>{{ $t('traveler.withoutOrigin') }}</div>

                      <v-icon icon="arrow-right" size="sm" space="px-8" />

                      <div v-if="!!tourDay.destinationId && !!tourDay.destination">{{ tourDay.destination.name }}</div>
                      <div v-else>{{ $t('traveler.withoutDestination') }}</div>
                    </div>

                    <div v-else class="locations flex-shrink-1 d-flex align-items-center text-sm mb-4 mb-lg-0">
                      <v-icon icon="run" size="xl" space="me-12" />

                      <div v-if="!!tourDay.originId && !!tourDay.origin">{{ tourDay.origin.name }}</div>
                      <div v-else>{{ $t('traveler.withoutLocation') }}</div>
                    </div>

                    <span class="dot-divider mx-20 d-none d-sm-flex " />

                    <div class="d-flex align-items-center flex-nowrap text-nowrap">
                      <div class="date text-gray-500 mb-0">
                        {{ $str.formatDateTime(tourDay.date, 'dd MMMM yyyy') }}
                      </div>

                      <template v-if="tourDay.type === 'stage' && !!tourDay.stageLength">
                        <span class="dot-divider mx-20" />

                        <div class="stage-length text-gray-500">
                          {{ tourDay.stageLength }} {{ $t('general.shared.kilometersShorted') }}
                        </div>
                      </template>
                    </div>
                  </div>

                  <v-button v-if="!isSummary" class="w-fit text-nowrap px-16 py-8" size="xs" variant="primary">
                    {{ $t('general.button.seeDay') }}
                  </v-button>
                </component>
                <template v-if="!!tourDay.items && tourDay.items.length > 0 && isSummary">
                  <div class="row pt-4 pb-24 ps-16 ps-md-24 pe-16 pe-md-48">
                    <div v-for="dayItem in mapTourDayItems(tourDay.items, tourDay.date, tourDay.disabledItemResources)"
                      :key="`tour-resume-${dayItem.type}`" class="information-container-summary col-12 col-md-6 
                      col-lg-4 col-xl-3 px-12 py-8 px-xxl-16 py-lg-12 mb-8 mb-md-0">
                      <div class="d-flex align-items-center">
                        <v-icon :icon="findConceptByKey('supplier.type', dayItem.type).icon" size="xl" space="me-12" />
                        <h4 class="text-normal mb-0">{{ getDayItemType(dayItem.type) }}</h4>
                      </div>

                      <div 
                        v-for="(count, resourceName) in mapResources(dayItem.items)" 
                        :key="resourceName"
                        class="fw-semi text-sm ps-16 ps-md-36 py-4"
                      >
                       <template v-if="resourceName !== 'undefined'">
                          <p 
                          :title="`${resourceName} x${count}`" 
                          class="resource-name mb-0" 
                        >
                          {{ `${resourceName} x${count}` }}
                          </p>
                       </template>
                      </div>

                      <template v-if="!!tourDay.optionalItemResources && tourDay.optionalItemResources.length > 0">
                        <div
                          v-for="(count, resourceName) in mapOptResources(tourDay.optionalItemResources, dayItem.type)"
                          :key="resourceName" class="information-container-summary d-flex align-items-center fw-semi 
                          text-sm text-gray-500 ps-16 ps-md-36 py-4">
                          <p :title="`${resourceName} x${count}`" class="resource-name mb-0">
                            {{ `${resourceName} x${count}` }}
                          </p>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>

                <template v-if="(!tourDay.items || tourDay.items.length === 0)
                  && !!tourDay.disabledItemResources && tourDay.disabledItemResources.length > 0 && isSummary">
                  <div class="d-flex flex-column flex-wrap py-4 ps-24 pe-16">
                    <div class="px-12 py-8 px-xxl-16 py-lg-12">
                      <div class="d-flex align-items-center">
                        <v-icon :icon="findConceptByKey('supplier.type', 'hotel').icon" size="xl" space="me-12" />
                        <h4 class="text-normal mb-0">{{ getDayItemType('hotel') }}</h4>
                      </div>

                      <div v-for="(count, resourceName) in mapOptResources(tourDay.disabledItemResources)"
                        :key="resourceName" class="information-container-summary d-flex align-items-center fw-semi 
                        text-sm ps-16 ps-md-36 py-4">
                        <p :title="`${resourceName} x${count}`" class="resource-name mb-0">
                          {{ `${resourceName} x${count}` }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="tour-resume-container">
            <div class="tour-resume-day">
              <div class="day-data" v-if="tourTemplate">
                <component :is="isSummary ? 'div' : 'a'"
                  :href="isSummary ? '#' : `#accordion-tour-day-after-${tourTemplate?.afterInformation?.id}`"
                  class="text-decoration-none p-16 p-lg-24 d-flex justify-content-between m-16">
                  <div class="d-flex flex-column flex-md-row align-items-md-center pe-16">
                    {{ $t('clientTour.afterTour') }}
                  </div>

                  <v-button v-if="!isSummary" class="w-fit text-nowrap px-16 py-8" size="xs" variant="primary">
                    {{ $t('clientTour.afterTour') }}
                  </v-button>
                </component>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isSummary"
          class="map-container break-avoid d-flex align-items-center justify-content-center hide-print">
          <v-map ref="travelBookHotelsMapContainer" :items="locations" :locations="locations" :zoom="5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {findConceptByKey} from '@/helpers/ConceptHelper';
import VMap from '@/components/vendor/VMap.vue';
import Supplier from '@/api/objects/Supplier';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import TourDay from '@/api/objects/TourDay';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ClientTour from '@/api/objects/ClientTour';
import {DateTime} from "luxon";
import TourDayItem from "@/api/objects/TourDayItem";

export type ResourceType = {
  id: string | number;
  name: string;
  currentDay: string;
  type: string;
  disabled: boolean;
}

export type MappedItemType = {
  type: string;
  items: ResourceType[];
};

export default defineComponent({
  name: 'TheTravelBookResumeDays',
  components: {
    VMap,
    VIcon,
    VButton,
  },
  props: {
    tourDays: {
      type: Array as PropType<TourDay[]>,
      required: false,
    },
    locations: {
      type: Array as PropType<Location[]>,
      required: false,
    },
    isSummary: {
      type: Boolean,
      required: false,
      default: false,
    },
    clientTour: {
      type: Object as PropType<ClientTour>,
      required: false,
    },
    tourTemplate: {
      type: [] as any,
      required: true,
    },
  },
  setup() {
    return {
      findConceptByKey,
    };
  },
  data() {
    return {
      suppliers: [] as Supplier[],
      tourDaysLocation: [] as Location[],
      loaded: false,
    };
  },
  async created() {
    this.loaded = true;
  },
  methods: {
    getDayItemType(type: string): string {
      if (type === 'hotel') {
        return this.$t('travelBook.accommodation');
      }

      return findConceptByKey('supplier.type', type).name;
    },
    /**
     * Counts the occurrences of each resource name within an array of resources.
     * This is for determining how many resources of each type are present.
     *
     * @param {TourDayItemResource[]} resources - The array of resources to count.
     * @returns {Record<string, number>} An object mapping each resource name to its count of occurrences.
     */
    mapResources(resources: any[]): Record<string, number> {
      const counts: Record<string, number> = {};

      resources.forEach(resource => {
        const name = resource.name;

        if (!counts[name]) {
          counts[name] = 0;
        }

        counts[name]++;
      });

      return counts;
    },
    mapOptResources(resources: any[], type?: string): Record<string, number> {
      let resourcesByType = resources;

      if (!!type) {
        resourcesByType = resources.filter(resource => resource.type === type);
      }

      return resourcesByType.reduce((acc: Record<string, number>, resource) => {
        const name = resource.name;

        if (!acc[name]) {
          acc[name] = 0;
        }

        acc[name]++;

        return acc;
      }, {});
    },
    mapTourDayItems(
      tourDayItems: TourDayItem[],
      tourDayDate: string,
      tourDayItemsDisabled?: ResourceType[],
    ): MappedItemType[] {
      const itemsGroupByType = tourDayItems.group((item: TourDayItem) => item.type);

      return Object.keys(itemsGroupByType)
        .map((type: string) => {
          const tourDayItems = itemsGroupByType[type];

          let items: ResourceType[] = [];

          tourDayItems.forEach((tourDayItem: TourDayItem) => {
            const resourcesMapped: ResourceType[] = tourDayItem.resources.map(resource => ({
              id: resource.id,
              name: resource.resource.name,
              currentDay: DateTime.fromISO(tourDayDate).toFormat('yyyy-MM-dd'),
              type: resource.resource.type,
              disabled: false,
            }));

            const resourcesMappedAux = tourDayItemsDisabled && tourDayItemsDisabled.length > 0
              ? resourcesMapped.concat(tourDayItemsDisabled)
              : resourcesMapped;

            items = items.concat(resourcesMappedAux);
          })

          return {
            type,
            items,
          }
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}

.break-avoid {
  break-inside: avoid;
}

.information-container-summary {
  position: relative;

  &:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1px;
    width: 100%;
    background: var(--uq-gray-300);
  }

  @media (min-width: 768px) {
    &:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background: var(--uq-gray-300);
    }
  }
}

.tour-data-container {
  .tour-data {
    .tour-resume-day {
      break-inside: avoid;
      margin-bottom: 1.75rem;

      .day-data {
        position: relative;
        background-color: var(--uq-white);
        border-radius: .5rem;
        box-shadow: 0 1px .25rem var(--uq-gray-300);

        .locations {
          @media print {
            font-size: .875rem;
          }
        }

        .date,
        .stage-length {
          font-size: .875rem;

          @media print {
            font-size: .75rem;
          }
        }
      }
    }
  }

  .resource-name {
    @media only screen {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .map-container {
    @media only screen {
      height: 31rem;
    }

    :deep(.map) {
      border-radius: .5rem;
    }

    @media print {
      width: 0 !important;
      height: 25rem;

      :deep(.map) {
        width: 0 !important;
      }
    }
  }
}
</style>
