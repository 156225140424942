import { defineStore } from 'pinia';
import api from '@/api';
import Concept from '@/api/objects/Concept';
import Permission from '@/api/objects/Permission';

export interface AppState {
  concepts: Record<string, Concept[]> | null,
  permissions: string[],
  settings: {
    formatDate: string,
    formatDateHyphen: string,
    formatDatetime: string,
  }
  userBrowserTimezone: any,
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    concepts: {},
    permissions: [],
    settings: {
      formatDate: 'dd/MM/yyyy',
      formatDateHyphen: 'dd-MM-yyyy',
      formatDatetime: 'dd/MM/yyyy HH:mm',
    },
    userBrowserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
  }),
  actions: {
    async init() {
      await this.loadConcepts();
    },
    async loadConcepts() {
      const data = await api.concept.allConcepts();

      // eslint-disable-next-line arrow-body-style,no-shadow
      const groupBy = <Concept>(group: Concept[], key = 'type'): { [key: string]: Concept[] } => {
        return group.reduce((result: any, item: any) => {
          if (item.status === 'active') {
            (result[item[key]] = result[item[key]] || []).push(item);
          }
          return result;
        }, {});
      };

      this.concepts = groupBy(data) || null;
    },
    async setPermissions(permissions: Permission[]) {
      this.permissions = permissions.map((permission: any) => permission.name);
    },
  },
});
