import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import TourDay from '@/api/objects/TourDay';
import Agency from '@/api/objects/Agency';
import Media from '@/api/objects/Media';

export interface TemplateContract extends IBaseObject {
  clientId: null | number;
  clientTourId: null | number;
  contactText: string;
  destinationId: number;
  endDate: string;
  grossPrice: string;
  initDate: string;
  invoicesStatus: string;
  markup: string;
  markupType: string;
  name: string;
  netPrice: string;
  originId: number;
  paxNumber: number;
  paymentsStatus: string;
  reservationStatus: string;
  status: string;
  tourId: number | null;
  travelType: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  agencies: Agency[];
  destination: Location | null;
  origin: Location | null;
  tourDays: TourDay[];
  termsConditionsText: string;
  complementaryText: string;
  featuredImage: Media;
  tourDaysCount: number | null;
  isCollaborator: boolean;
}

export default class Template extends BaseObject implements TemplateContract {
  public declare clientId: null | number;
  public declare clientTourId: null | number;
  public declare contactText: string;
  public declare destinationId: number;
  public declare endDate: string;
  public declare grossPrice: string;
  public declare initDate: string;
  public declare invoicesStatus: string;
  public declare markup: string;
  public declare markupType: string;
  public declare name: string;
  public declare netPrice: string;
  public declare originId: number;
  public declare paxNumber: number;
  public declare paymentsStatus: string;
  public declare reservationStatus: string;
  public declare status: string;
  public declare tourId: number | null;
  public declare travelType: string;
  public declare type: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare agencies: Agency[];
  public declare destination: Location | null;
  public declare origin: Location | null;
  public declare tourDays: TourDay[];
  public declare termsConditionsText: string;
  public declare complementaryText: string;
  public declare featuredImage: Media;
  public declare tourDaysCount: number | null;
  public declare isCollaborator: boolean;

  constructor(data: TemplateContract) {
    super(data);
  }
}
