import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface PaxContract extends IBaseObject {
  guardName: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export default class Pax extends BaseObject implements PaxContract {
  public declare guardName: string;
  public declare name: string;
  public declare createdAt: string;
  public declare updatedAt: string;

  constructor(data: PaxContract) {
    super(data);
  }
}
