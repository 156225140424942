import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface SupplierTranslationContract extends IBaseObject {
  description: string;
  locale: string;
  supplierId: number;
}

export default class SupplierTranslation extends BaseObject implements SupplierTranslationContract {
  public declare description: string;
  public declare locale: string;
  public declare supplierId: number;

  constructor(data: SupplierTranslationContract) {
    super(data);
  }
}
