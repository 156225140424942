<template>
  <ul class="p-0 m-0">
    <template
      v-for="(filter, index) in modelValue"
      :key="index">
      <li
        v-if="!!filter.label && !!filter.value"
        class="d-inline-block me-8 mb-8"
      >
        <v-button
          class="btn-icon rounded-pill d-flex shadow-none status-btn" 
          size="sm"
          variant="outline-primary"
          @click="emitRemoveFilter(filter)">
          <span class="me-8">{{ filter.label }}</span>
          <v-icon class="text-xs rounded-pill" icon="close"/>
        </v-button>
      </li>
    </template>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';

export default defineComponent({
  name: 'VFilters',
  components: { VIcon, VButton },
  emits: ['removeFilter', 'update:modelValue'],
  props: {
    modelValue: {
      type: Array as PropType<Record<string, any>[]>,
      required: true,
    },
  },
  methods: {
    emitRemoveFilter(value: Record<string, string>) {
      this.$emit('removeFilter', value);
    },
  },
  
});
</script>
<style scoped>
  .status-btn{
    padding: 10px;
    padding-left: 15px;
  }
</style>
