import { AbstractService } from '@uniqoders/sdk';
import Invoice from '@/api/objects/Invoice';

export default class InvoiceService extends AbstractService {
  /**
   * Retrieves all Invoices
   * @param agency
   * @param clientTour
   * @param tour
   * @protected
   */
  public all(
    agency: string,
    clientTour: number,
    tour: string | number): Promise<Invoice[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/invoices', { agency, clientTour, tour }),
    });
  }

  /**
   * Retrieves a specific Invoice
   * @param agency
   * @param clientTour
   * @param tour
   * @param invoice
   * @protected
   */
  public retrieve(
    agency: string,
    clientTour: number,
    tour: string | number,
    invoice: number): Promise<Invoice> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/invoice/{invoice}', {
        agency,
        clientTour,
        tour,
        invoice
      }),
    });
  }

  /**
   * Creates a single Invoice
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   */
  public create(
    agency: string,
    clientTour: number,
    tour: string | number,
    data: Record<string, any>): Promise<Invoice> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/invoice', { agency, clientTour, tour }),
      data,
    });
  }

  /**
   * Updates the specified Invoice
   * @param agency
   * @param clientTour
   * @param tour
   * @param invoice
   * @param data
   * @protected
   */
  public update(
    agency: string,
    clientTour: number,
    tour: string | number,
    invoice: number,
    data: Record<string, any>): Promise<Invoice> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/invoice/{invoice}', {
        agency,
        clientTour,
        tour,
        invoice
      }),
      data,
    });
  }

  /**
   * Archives the specified Invoice
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param invoice
   */
  public archive(
    agency: string,
    clientTour: number,
    tour: string | number,
    invoice: string | number): Promise<Invoice> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/invoice/{invoice}', {
        agency,
        clientTour,
        tour,
        invoice
      }),
    });
  }

  /**
   * Deletes the specified Invoice
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param invoice
   */
  public delete(agency: string, clientTour: number, tour: string | number, invoice: number): Promise<Invoice> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/invoice/{invoice}/force', {
        agency,
        clientTour,
        tour,
        invoice
      }),
    });
  }

  /**
   * Restores the specified Invoice
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param invoice
   */
  public restore(agency: string, clientTour: number, tour: string | number, invoice: number): Promise<Invoice> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/invoice/{invoice}/force', {
        agency,
        clientTour,
        tour,
        invoice
      }),
    });
  }

  /**
   * Download the specified Invoice
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param invoice
   */
  public download(agency: string, clientTour: number, tour: string | number, invoice: number): Promise<Invoice> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/invoice/{invoice}/download', {
        agency,
        clientTour,
        tour,
        invoice
      }),
    });
  }
}
