import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import AgencyUser from '@/api/objects/AgencyUser';
import Contact from '@/api/objects/Contact';
import Template from '@/api/objects/Template';

export interface AgencyContract extends IBaseObject {
  billingAddress1: string;
  billingAddress2: string;
  billingPostalCode: string;
  billingCountry: string;
  billingCity: string;
  billingState: string;
  contactAddress1: string;
  contactAddress2: string;
  contactPostalCode: string;
  contactCountry: string;
  contactCity: string;
  contactState: string;
  location: string;
  name: string;
  nif: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  agencyUsers: AgencyUser[] | null;
  contacts: Contact[] | null;
  templates: Template[] | null;
  isCollaborator?: boolean;
}

export default class Agency extends BaseObject implements AgencyContract {
  public declare billingAddress1: string;
  public declare billingAddress2: string;
  public declare billingPostalCode: string;
  public declare billingCountry: string;
  public declare billingCity: string;
  public declare billingState: string;
  public declare contactAddress1: string;
  public declare contactAddress2: string;
  public declare contactPostalCode: string;
  public declare contactCountry: string;
  public declare contactCity: string;
  public declare contactState: string;
  public declare location: string;
  public declare name: string;
  public declare nif: string;
  public declare slug: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare agencyUsers: AgencyUser[] | null;
  public declare contacts: Contact[] | null;
  public declare templates: Template[] | null;

  constructor(data: AgencyContract, public isCollaborator?: boolean) {
    super(data);
  }
}
