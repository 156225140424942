<template>
  <form
    id="agency-user-create-form"
    class="d-flex flex-column justify-content-between h-100"
  >
    <fieldset class="mb-24">
      <legend class="visually-hidden">{{ $t('admin.user.basicInformation') }}</legend>

      <v-form-input
        id="user-create-email"
        v-model="email"
        :label="`${this.$t('general.shared.email')}*`"
        :type="'email'"
        :yup-errors-variable="errors.email"
        autocomplete="off"
        form-type="outline"
      />
    </fieldset>

    <v-button
      :disabled="!meta.valid || accepted"
      :is-loading="accepted"
      class="btn-submit-form btn-icon w-fit align-self-end"
      variant="primary"
      @click="createUser"
    >
      {{ $t('general.button.create') }}
      <v-icon icon="arrow-right" size="sm" space="ms-12"/>
    </v-button>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { VFormInput } from '@uniqoders/form';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import User from '@/api/objects/User';

export default defineComponent({
  name: 'TheAdminAgencyCreateUserForm',
  components: {
    VIcon,
    VButton,
    VFormInput,
  },
  emits: ['userCreated'],
  props: {
    agencyId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      email: yup.string()
        .email()
        .required()
        .label(t('general.shared.email')),
    });

    const form = useFormValidation(rules);

    const { value: email } = useField('email');

    return {
      ...form,
      email,
    };
  },
  data() {
    return {
      disableCreateButton: false as boolean,
    };
  },
  methods: {
    async createUser() {
      try {
        this.toggleAccepted();

        const data = {
          email: this.email,
        };

        const user: User = await api.admin.user.create(data);

        await api.admin.agency.attachUser(this.agencyId, user.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.resetForm();

        this.$emit('userCreated');

        this.toggleAccepted();
      } catch (e: any) {
        this.toggleAccepted();

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
