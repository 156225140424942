<template>
  <v-off-canvas
    id="resource-information-create-offcanvas"
    :backdrop-static="true"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <h5 class="offcanvas-title">{{ $t('resource.createNewInformation') }}</h5>
      </off-canvas-header>

      <off-canvas-body>
      <form
        id="resource-information-create-form"
        class="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <fieldset>
            <legend class="visually-hidden">{{ $t('resource.resourceSearch') }}</legend>

            <div class="row gx-12">
              <v-input-address
                id="resource-information-city-search"
                ref="resource-information-city-search-ref"
                :get-address="true"
                :get-radius-bounds="true"
                :label="`${$t('general.address.city')}*`"
                :options="{
                  types: ['(cities)'],
                  fields: ['place_id', 'name', 'vicinity', 'formatted_address', 'geometry', 'address_component'],
                }"
                :radius-bounds="20000"
                class="col-12 mb-20"
                @address="setCity($event)"
                @input="checkCityValue"
              />
            </div>
          </fieldset>

          <div class="fieldset-translatable">
            <nav class="translations-tab">
              <div id="resource-information-nav-tab" class="nav nav-tabs" role="tablist">
                <v-button
                  v-for="(language, index) in languages"
                  :id="`nav-supplier-create-basic-information-${language.key}`"
                  :key="index"
                  :aria-controls="`supplier-create-basic-information-${language.key}`"
                  :class="{ active: language.key === currentLanguage }"
                  :data-bs-target="`#supplier-create-basic-information-${language.key}`"
                  aria-selected="true"
                  class="nav-tab-link py-8 px-16 me-8"
                  data-bs-toggle="tab"
                  role="tab"
                  size="sm"
                  variant="outline-primary"
                  @click="currentLanguage = language.key"
                >
                  {{ language.label }}
                </v-button>
              </div>
            </nav>

            <transition
              mode="out-in"
              name="fade"
              appear
            >
              <div
                id="resource-information-create-basic-information-tabs"
                class="tab-content"
              >
                <div
                  v-for="(language, index) in languages"
                  :id="`resource-information-create-basic-information-${language.key}`"
                  :key="index"
                  :aria-labelledby="`nav-resource-information-create-basic-information-${language.key}`"
                  :class="{ 'active show': language.key === currentLanguage }"
                  class="resource-information-create-basic-information-tab tab-pane fade"
                  role="tabpanel">
                  <the-resource-information-create-basic-information-fieldset
                    v-model:description="translations[language.key].description"
                    v-model:title="translations[language.key].title"
                    :clear="clear"
                    :current-language="currentLanguage"
                    :language="language.key"
                    @media-uploaded="editorMedia.push($event)"
                    @is-valid="isTranslationsValid.find((translation) => translation.locale === language.key).valid = $event"
                  />
                </div>
              </div>
            </transition>
          </div>

          <div class="search-separator-container mb-8 pb-24">
            <div class="separator-text-container">
              <span class="separator-text text-sm">{{ $t('general.shared.links') }}</span>
            </div>
          </div>

          <fieldset>
            <legend class="visually-hidden">{{ $t('general.shared.links') }}</legend>

            <v-form-input
              id="resource-information-create-map"
              v-model="mapUrl"
              :label="$t('general.shared.map')"
              :yup-errors-variable="errors.mapUrl"
              autocomplete="off"
              form-type="outline"
            />

            <v-form-input
              id="resource-information-create-website"
              v-model="website"
              :label="$t('general.shared.website')"
              :yup-errors-variable="errors.website"
              autocomplete="off"
              form-type="outline"
            />
          </fieldset>

          <div class="search-separator-container">
            <div class="separator-text-container mb-16"/>
          </div>

          <fieldset>
            <legend class="visually-hidden">{{ $t('general.shared.media') }}</legend>

            <div class="resource-media-container mb-32">
              <div class="media-title text-lg fw-semi mb-16">{{ $t('general.shared.media') }}</div>

              <v-uploader
                ref="uploader"
                :after-upload="onChange"
                :initial-value="photosUuid"
                route-prefix="api/s3"
                @removed="onChange"
              />
            </div>
          </fieldset>
        </div>

        <v-button
          :disabled="!meta.valid || accepted || !isTranslationsValid.every(translation => translation.valid)"
          :is-loading="accepted"
          class="btn-submit-form btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="createResource"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </form>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import useConcepts from '@/helpers/Concepts';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import VUploader from '@/components/vendor/upload/VUploader.vue';
import Location from '@/api/objects/Location';
import TheResourceInformationCreateBasicInformationFieldset
  from '@/components/resource/parts/TheResourceInformationCreateBasicInformationFieldset.vue';
import { mapConcepts } from '@/helpers/ConceptHelper';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheResourceInformationCreateOffcanvas',
  components: {
    TheResourceInformationCreateBasicInformationFieldset,
    VUploader,
    VInputAddress,
    VIcon,
    VButton,
    VFormInput,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
  },
  emits: [
    'closed',
    'reloadResources',
    'openDetails',
  ],
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      mapUrl: yup.string()
        .nullable()
        .label(t('general.shared.map')),
      website: yup.string()
        .nullable()
        .label(t('general.shared.website')),
    });

    const form = useFormValidation(rules);

    const { value: mapUrl } = useField('mapUrl');
    const { value: website } = useField('website');

    return {
      ...useConcepts(),
      ...form,
      mapUrl,
      website,
    };
  },
  data() {
    return {
      lat: null as null | number,
      lng: null as null | number,
      media: [] as any,
      location: {} as Location,
      placeId: '' as string,
      translations: {} as Record<string, any>,
      currentLanguage: '',
      clear: false,
      isTranslationsValid: [] as Record<string, any>[],
      photosUuid: {} as Record<string, any>,
      disableRemoveButton: false as boolean,
      editorMedia: [] as any[],
    };
  },
  computed: {
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    languages(): any {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
    mediaUuids() {
      return this.media.map((media: any) => (media.attributes.uuid));
    },
  },
  created() {
    this.currentLanguage = this.defaultLocale;

    this.mapTranslations();
  },
  methods: {
    mapTranslations() {
      this.isTranslationsValid = [];

      this.languages.forEach((language: Record<string, any>) => {
        this.isTranslationsValid.push({
          locale: language.key,
          valid: false,
        });

        this.translations[language.key] = {
          title: '',
          description: '',
        };
      });
    },
    onChange() {
      const { uploader }: any = this.$refs;

      if (!!uploader) {
        this.media = uploader.mediaLibrary.state.media;
      }
    },
    async setCity(place: any) {
      if (!!this.placeId && this.placeId !== place.place_id) {
        await this.clearForm(true);
      }

      await this.createLocation(place);

      this.lat = place.lat;
      this.lng = place.lng;
      this.placeId = place.place_id;
      this.mapUrl = place.url;
      this.website = place.website;

      Object.keys(this.translations)
        .forEach((language: string) => {
          this.translations[language].title = place.locality.longName;
        });
    },
    checkCityValue(value: string) {
      if (!value) {
        this.clear = !this.clear;
        this.clearForm(true);
      }
    },
    async createLocation(address: Record<string, any>) {
      this.location = await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });
    },
    clearForm(clearAll = false) {
      this.resetForm();

      this.mapTranslations();

      if (clearAll) {
        if (!!this.$refs['resource-information-city-search-ref']) {
          (this.$refs['resource-information-city-search-ref'] as any).valueLocal = '';
        }

        this.media = [];
        this.photosUuid = [];

        const { uploader }: any = this.$refs;

        if (!!uploader) {
          uploader.mediaLibrary.state.media = [];
        }
      }
    },
    async createResource() {
      try {
        this.toggleAccepted();

        const media = this.mediaUuids;

        const data = {
          map_url: this.mapUrl,
          images: media,
          lat: this.lat,
          lng: this.lng,
          location_id: this.location.id,
          provider_id: this.placeId,
          translations: this.translations,
          site_url: this.website,
          type: 'information',
        };

        const response = await api.resource.create(data);

        if (!!this.editorMedia && this.editorMedia.length > 0) {
          const editorMedia = {
            media: this.editorMedia,
            model_type: 'resource',
            collection: 'tinymce',
            model_id: response.id,
          };

          await api.media.confirm('s3', editorMedia);
        }

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.clear = !this.clear;

        this.clearForm(true);

        this.$emit('openDetails', response);
        this.$emit('reloadResources');
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
      }
    },
  },
});
</script>
