<template>
  <v-auth-layout>
    <div id="login-container">
      <div v-if="socialLoginBtnLoading" class="position-relative py-140">
        <v-loader/>
      </div>

      <template v-else>
        <!-- TODO: Change for loginWithGoogle('google') -->
        <v-button
          class="social-login-btn btn-icon justify-content-center w-100 lh-xl p-20 mb-32"
          variant="outline-gray"
          @click="socialLogin(googleProvider)"
        >
          <Transition mode="out-in" name="fade">
            <div v-if="statusSocialLogin === 'loading'">
              {{ $t('auth.login.redirect') }}
            </div>

            <div v-else class="d-flex align-items-center">
              <div id="login-google-logo" class="me-12">
                <v-picture class="d-block">
                  <img
                    :alt="this.$t('auth.login.logInGoogle')"
                    src="@/../resources/img/google-logo.svg"
                    srcset="@/../resources/img/google-logo.svg 2x,
                 @/../resources/img/google-logo.svg 1x"/>
                </v-picture>
              </div>

              {{ $t('auth.login.logInGoogle') }}
            </div>
          </Transition>
        </v-button>

        <!-- login with apple -->
        <v-button
          class="social-login-btn btn-icon justify-content-center w-100 lh-xl p-20 mb-32"
          variant="outline-gray"
          @click="socialLogin(appleProvider)"
        >
          <Transition mode="out-in" name="fade">
            <div v-if="statusSocialAppleLogin === 'loading'">
              {{ $t("auth.login.redirect") }}
            </div>
            <div v-else class="d-flex align-items-center">
              <div id="login-apple-logo" class="me-12">
                <v-picture class="d-block">
                  <img
                    :alt="this.$t('auth.login.logInApple')"
                    src="@/../resources/img/apple-logo.svg"
                    srcset="@/../resources/img/apple-logo.svg 2x, @/../resources/img/apple-logo.svg 1x"
                  />
                </v-picture>
              </div>
              {{ $t("auth.login.logInApple") }}
            </div>
          </Transition>
        </v-button>

        <template v-if="environment === 'development'">
          <h1 class="text-sm fw-medium text-center mb-16">{{ $t('auth.login.logInWithEmail') }}</h1>

          <form id="login" class="mb-24" @submit.prevent="login">
            <fieldset>
              <legend class="visually-hidden">{{ $t('auth.login.loginData') }}</legend>

              <v-form-input
                id="login-email"
                v-model="email"
                :label="`${this.$t('auth.shared.email')}*`"
                :type="'email'"
                :yup-errors-variable="errors.email"
                autocomplete="off"
                form-type="outline"
              />

              <v-input-password-outline
                id="login-password"
                v-model="password"
                :label="`${this.$t('auth.shared.password')}*`"
                :yup-errors-variable="errors.password"
                autocomplete="off"
                class="mb-32"
              >
                <template #btn-icon>
                  <v-icon icon="eye" />
                </template>
              </v-input-password-outline>
            </fieldset>

            <div class="d-flex justify-content-center">
              <v-button
                :disabled="!meta.valid || accepted"
                :is-loading="accepted"
                class="w-100"
                type="submit"
                variant="primary"
              >
                {{ $t("auth.login.logIn") }}
              </v-button>
            </div>
          </form>
        </template>
      </template>
    </div>
  </v-auth-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as yup from "yup";
import { useField } from "vee-validate";
import { VFormInput, VInputPasswordOutline } from "@uniqoders/form";
import { useI18n } from "vue-i18n";
import VPicture from "@/components/vendor/basic/picture/VPicture.vue";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import api from "@/api";
import useFormValidation from "@/helpers/form";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import VAuthLayout from "@/components/layout/VAuthLayout.vue";
import VLoader from "@/components/vendor/loader/VLoader.vue";

export default defineComponent({
  name: "TheLogin",
  components: {
    VLoader,
    VAuthLayout,
    VIcon,
    VButton,
    VPicture,
    VFormInput,
    VInputPasswordOutline,
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      email: yup.string().email().required().label(t("general.shared.email")),
      password: yup.string().required().label(t("general.shared.password")),
    });

    const form = useFormValidation(rules);

    const { value: email } = useField("email");
    const { value: password } = useField("password");

    return {
      ...form,
      email,
      password,
    };
  },
  data() {
    return {
      statusSocialLogin: "loaded" as string,
      statusSocialAppleLogin: "loaded" as string,
      socialLoginBtnLoading: false as boolean,
      googleProvider: 'google' as const,
      appleProvider: 'apple' as const
    };
  },
  computed: {
    environment(): string {
      return import.meta.env.VITE_NODE_ENV;
    },
  },
  created() {
    this.confirmAuthSocial();
  },
  methods: {
    async login() {
      try {
        this.toggleAccepted();

        await api.auth.login({ email: this.email, password: this.password });

        await this.redirectToHome();

        this.toggleAccepted();
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
      }
    },
    async socialLogin(provider: string) {
  try {
    localStorage.setItem('socialLoginProvider', provider);
    if (provider === this.googleProvider) {
      this.statusSocialLogin = this.LOADING;
    } else if (provider === this.appleProvider) {
      this.statusSocialAppleLogin = this.LOADING;
    }

    const response = await api.auth.socialLogin(provider);

    if (response.status === 'success') {
      window.location.href = response.redirect_uri;
    }
  } catch (e: any) {
    if (provider === this.googleProvider) {
      this.statusSocialLogin = this.LOADED;
    } else if (provider === this.appleProvider) {
      this.statusSocialAppleLogin = this.LOADED;
    }

    console.error(e.response.data);

    this.$toast.error(e.response.data.message);

    await this.$router.push({ name: 'login' });
  } finally {
    // Reset the status back to 'loaded' regardless of success or failure
    if (provider === this.googleProvider) {
      this.statusSocialLogin = this.LOADED;
    } else if (provider === this.appleProvider) {
      this.statusSocialAppleLogin = this.LOADED;
    }
  }
},

async confirmAuthSocial() {
  const socialLoginProvider = localStorage.getItem('socialLoginProvider');

  if (socialLoginProvider === this.googleProvider) {
    const {
      state, code, scope, authuser, hd, prompt,
    } = this.$router.currentRoute.value.query;

    if (!!state && !!code && !!scope && !!authuser && !!prompt) {
      this.socialLoginBtnLoading = true;

      const params = {
        state, code, scope, authuser, hd, prompt,
      };

      try {
        await api.auth.confirmSocialAuth(this.googleProvider, params);
        await this.redirectToHome();
      } catch (e) {
        console.error(e);
        this.socialLoginBtnLoading = false;
        await this.$router.push({ name: 'login' });
        this.$toast.error(this.$t('auth.login.userNotAllowed'));
      }
    }
  } else if (socialLoginProvider === this.appleProvider) {
    const { state, code, id_token } = this.$router.currentRoute.value.query;

    if (!!state && !!code && !!id_token) {
      this.socialLoginBtnLoading = true;

      const params = {
        state,
        code,
        id_token
      };

      try {
        await api.auth.confirmSocialAuth(this.appleProvider, params);
        await this.redirectToHome();
      } catch (e) {
        console.error(e);
        this.socialLoginBtnLoading = false;
        await this.$router.push({ name: 'login' });
        this.$toast.error(this.$t('auth.login.userNotAllowed'));
      }
    }
  }
},
    async redirectToHome() {
      const isCollaborator = localStorage.getItem("isCollaborator");
      if(isCollaborator === "true"){
        await this.$router.push({ name: "collaboratorDashboard" });
        return;
      }
      if (this.$route.query.redirect) {
        await this.$router.push(String(this.$route.query.redirect));
      } else {
        await this.$router.push({ name: "case.index" });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.social-login-btn {
  border: 1px solid #d1d1d1;
  border-radius: 0.5rem;

  #login-google-logo,
  #login-apple-logo {
    width: 1.75rem;
  }
}
</style>
