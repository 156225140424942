<template>
  <v-off-canvas
    id="user-create-offcanvas"
    :backdrop-static="false"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
   <resizable-container>
    <off-canvas-header>
      <h5 class="offcanvas-title">{{ $t('admin.user.createNewUser') }}</h5>
    </off-canvas-header>

    <off-canvas-body>
      <form id="user-create" class="d-flex flex-column justify-content-between h-100" @submit.prevent="createUser">
        <fieldset>
          <legend class="visually-hidden">{{ $t('admin.user.basicInformation') }}</legend>

          <v-form-input
            id="user-create-email"
            v-model="email"
            :label="`${this.$t('general.shared.email')}*`"
            :type="'email'"
            :yup-errors-variable="errors.email"
            autocomplete="off"
            form-type="outline"
          />
        </fieldset>

        <v-button
          :disabled="!meta.valid || accepted"
          :is-loading="accepted"
          class="btn-icon w-fit align-self-end"
          size="sm"
          type="submit"
          variant="primary"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </form>
    </off-canvas-body>
   </resizable-container> 
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheAdminUserCreateOffcanvas',
  components: {
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VFormInput,
    ResizableContainer,
  },
  emits: [
    'closed',
    'reloadUsers',
    'openDetails',
  ],
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      email: yup.string()
        .email()
        .required()
        .label(t('general.shared.email')),
    });

    const form = useFormValidation(rules);

    const { value: email } = useField('email');

    return {
      ...form,
      email,
    };
  },
  methods: {
    async createUser() {
      try {
        this.toggleAccepted();

        const data = {
          email: this.email,
        };

        const response = await api.admin.user.create(data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.resetForm();

        this.$emit('openDetails', response);
        this.$emit('reloadUsers');
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
      }
    },
  },
});
</script>
