export default {
  shared: {
    password: 'Password',
    email: 'Email',
  },
  login: {
    logInGoogle: 'Login with Google',
    logInApple: 'Login with Apple',
    logInWithEmail: 'o Login with your email',
    logIn: 'Login',
    forgot: 'I have forgotten my password',
    redirect: 'Redirecting...',
    loginData: 'Login data',
    userNotAllowed: 'There is no user with that email in the application',
  },
  forgot: {
    recover: 'Reset password',
    backLogin: 'Back to Login',
    receiveEmail: 'You will receive an email to recover your password at this address.',
    receiveEmailSpam: 'If you don\'t receive it, it may be in your spam folder.',
    emailSent: 'We have just resent you the email, please check your spam folder..',
    resend: 'Resend',
    email: 'Enter your email address',
  },
  reset: {
    addNewPassword: 'Add your new password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    congratulations: 'Congratulations!',
    successfulRecover: 'Your password has been successfully reset. You can now log in again login and enter your new credentials.',
    passwords: 'Passwords',
  },
};
