<template>
  <div class="detail">
    <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
      <div class="fw-medium">{{ $t('client.basicInformation') }}</div>

      <v-button
        v-if="!client.deletedAt"
        class="btn-edit p-4"
        variant="icon"
        @click="editBasicInformation = !editBasicInformation">
        <v-icon icon="pencil"/>
      </v-button>
    </div>

    <div class="detail-content">
      <Transition mode="out-in" name="fade-in">

        <div v-if="!editBasicInformation">
          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.code') }}: </span>
            <span>{{ client.code }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.name') }}: </span>
            <span>{{ client.name }}</span>
          </div>

          <div class="text-sm d-flex mb-8">
            <span class="info-title fw-medium">{{ $t('general.shared.surname') }}: </span>
            <span>{{ client.surname }}</span>
          </div>

          <div class="text-sm d-flex mb-8">
            <span class="info-title fw-medium">{{ $t('general.shared.email') }}: </span>
            <span>{{ client.email }}</span>
          </div>

          <div class="text-sm d-flex mb-8">
            <span class="info-title fw-medium">{{ $t('general.shared.phone') }}: </span>
            <span>{{ client.phone }}</span>
          </div>

          <div class="text-sm d-flex mb-8">
            <span class="info-title fw-medium">{{ $t('general.shared.preferredLanguage') }}: </span>
            <span v-if="!!client.preferredLanguage">
              {{ findConceptByKey('app.languages', client.preferredLanguage).name }}
            </span>
            <span v-else>-</span>
          </div>

          <div class="text-sm d-flex mb-8">
            <span class="info-title fw-medium">{{ $t('general.shared.source') }}: </span>
            <span>{{ client.source }}</span>
          </div>

          <p class="mb-4 mt-16 text-sm fw-bold">{{ $t('general.address.address') }}</p>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.streetAddress') }}: </span>
            <span>{{ client.contactAddress1 }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.aptSuit') }}: </span>
            <span>{{ client.contactAddress2 }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.postalCode') }}: </span>
            <span>{{ client.contactPostalCode }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.country') }}: </span>
            <span>{{ client.contactCountry }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.city') }}: </span>
            <span>{{ client.contactCity }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.provinceStateRegion') }}: </span>
            <span>{{ client.contactState }}</span>
          </div>

          <p class="mb-4 mt-16 text-sm fw-bold">{{ $t('general.address.billingAddress') }}</p>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.streetAddress') }}: </span>
            <span>{{ client.billingAddress1 }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.aptSuit') }}: </span>
            <span>{{ client.billingAddress2 }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.postalCode') }}: </span>
            <span>{{ client.billingPostalCode }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.country') }}: </span>
            <span>{{ client.billingCountry }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.city') }}: </span>
            <span>{{ client.billingCity }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.provinceStateRegion') }}: </span>
            <span>{{ client.billingState }}</span>
          </div>
        </div>

        <form
          v-else
          id="client-update-form"
          class="d-flex flex-column justify-content-between h-100"
        >
          <fieldset>
            <legend class="visually-hidden">{{ $t('client.basicInformation') }}</legend>
            <div class="row gx-12">
              <v-form-input
                id="client-update-name"
                v-model="name"
                :label="`${$t('general.shared.name')}*`"
                :yup-errors-variable="errors.name"
                autocomplete="off"
                class="col-12 mb-20"
                form-type="outline"
              />

              <v-form-input
                id="client-update-surname"
                v-model="surname"
                :label="$t('general.shared.surname')"
                :yup-errors-variable="errors.surname"
                autocomplete="off"
                class="col-12 mb-20"
                form-type="outline"
              />

              <v-form-input
                id="client-update-email"
                v-model="email"
                :label="`${$t('general.shared.email')}*`"
                :yup-errors-variable="errors.email"
                autocomplete="off"
                class="col-12 mb-20"
                form-type="outline"
                type="email"
              />

              <v-form
                id="client-update-country-code"
                :label="$t('general.shared.countryCode')"
                :yup-errors-variable="errors.countryCode"
                class="mb-8"
                form-type="outline"
              >
                <v-select
                  key="value"
                  v-model="countryCode"
                  :class="{ 'is-invalid': !!errors.countryCode }"
                  :clearable="true"
                  :options="countryCodes"
                  :placeholder="$t('general.shared.countryCodePlaceholder')"
                  :reduce="(prefix: any) => prefix.value"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </v-form>

              <v-form-input
                id="client-update-contact-phone"
                v-model="phone"
                :label="$t('general.shared.phone')"
                :yup-errors-variable="errors.phone"
                autocomplete="off"
                class="mb-8"
                form-type="outline"
              />

              <v-form
                id="client-update-preferred-language"
                :label="$t('general.shared.preferredLanguage')"
                :yup-errors-variable="errors.preferredLanguage"
                class="mb-8"
                form-type="outline"
              >
                <v-select
                  v-model="preferredLanguage"
                  :class="{ 'is-invalid': !!errors.preferredLanguage }"
                  :options="languages"
                  :reduce="(returnValue: any) => returnValue.key"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </v-form>

              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.type') }}</span>
                </label>

                <v-select
                  id="client-update-source-type-select"
                  v-model="source"
                  :clearable="false"
                  :options="sourceTypes"
                  :reduce="(returnValue: any) => returnValue.key"
                  class="mb-16"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>
            </div>
          </fieldset>

          <v-contact-billing-form
            id="client-update-address-form"
            ref="client-update-address-form-ref"
            :billing-always-visible="true"
            :contact-and-billing-matches="false"
            :data="addressData"
            id-prefix="client-update"
            @updated-data="addressData = $event"
            @is-valid="addressValid = $event"
          />

          <v-button
            :disabled="!meta.valid || !addressValid || accepted"
            :is-loading="accepted"
            class="btn-submit-form btn-icon w-fit align-self-end"
            size="sm"
            variant="primary"
            @click="saveClient"
          >
            {{ $t('general.button.save') }}
            <v-icon icon="arrow-right" size="sm" space="ms-12"/>
          </v-button>
        </form>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VForm, VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import vSelect from 'vue-select';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useConcepts from '@/helpers/Concepts';
import { findConceptByKey, mapConcepts, sortConcepts } from '@/helpers/ConceptHelper';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';
import Client from '@/api/objects/Client';

export default defineComponent({
  name: 'TheClientDetailsBasicInformation',
  components: {
    VContactBillingForm,
    VIcon,
    VButton,
    VFormInput,
    vSelect,
    VForm,
  },
  emits: [
    'closed',
    'reloadClient',
    'emitReloadClients',
  ],
  props: {
    agency: {
      type: String,
      required: true,
    },
    client: {
      type: Client,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      surname: yup.string()
        .nullable()
        .label(t('general.shared.surname')),
      email: yup.string()
        .email()
        .required()
        .label(t('general.shared.email')),
      countryCode: yup.string()
        .nullable()
        .label(t('general.shared.countryCode')),
      phone: yup.string()
        .nullable()
        .label(t('general.shared.phone')),
      preferredLanguage: yup.string()
        .nullable()
        .label(t('general.shared.preferredLanguage')),
      source: yup.string()
        .nullable()
        .label(t('general.shared.source')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: surname } = useField('surname');
    const { value: email } = useField('email');
    const { value: countryCode } = useField('countryCode');
    const { value: phone } = useField('phone');
    const { value: preferredLanguage } = useField('preferredLanguage');
    const { value: source } = useField('source');

    return {
      findConceptByKey,
      ...useConcepts(),
      ...form,
      name,
      surname,
      email,
      countryCode,
      phone,
      preferredLanguage,
      source,
    };
  },
  data() {
    return {
      editBasicInformation: false as boolean,
      addressData: {} as any,
      addressValid: false as boolean,
    };
  },
  computed: {
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    languages(): any {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
    sourceTypes(): any {
      if (!!this.concepts && this.concepts['client.source_type']) {
        return mapConcepts(this.concepts['client.source_type']);
      }

      return [];
    },
    countryCodes(): any {
      if (!!this.concepts && this.concepts['app.countryPhones']) {
        return mapConcepts(sortConcepts(this.concepts['app.countryPhones']));
      }

      return [];
    },
  },
  watch: {
    client(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setData();
      }
    },
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      if (!!this.client) {
        this.name = this.client.name;
        this.surname = this.client.surname;
        this.email = this.client.email;

        const phone = !!this.client.phone ? this.client.phone.split(' ') : '';

        this.countryCode = !!this.client.phone ? phone[0] : phone;
        this.phone = !!this.client.phone ? phone[1] : phone;
        this.preferredLanguage = this.client.preferredLanguage;
        this.source = this.client.source;

        this.addressData.contactAddress1 = this.client.contactAddress1;
        this.addressData.contactAddress2 = this.client.contactAddress2;
        this.addressData.contactPostalCode = this.client.contactPostalCode;
        this.addressData.contactCountry = this.client.contactCountry;
        this.addressData.contactCity = this.client.contactCity;
        this.addressData.contactState = this.client.contactState;
        this.addressData.billingAddress1 = this.client.billingAddress1;
        this.addressData.billingAddress2 = this.client.billingAddress2;
        this.addressData.billingPostalCode = this.client.billingPostalCode;
        this.addressData.billingCountry = this.client.billingCountry;
        this.addressData.billingCity = this.client.billingCity;
        this.addressData.billingState = this.client.billingState;
      }
    },
    async saveClient() {
      try {
        this.toggleAccepted();

        let phoneTmp = '';

        if (!!this.countryCode && !!this.phone) {
          phoneTmp = `${this.countryCode} ${this.phone.toString().replace(/\s/g, '')}`;
        }

        const data: any = {
          name: this.name,
          surname: this.surname,
          email: this.email,
          source: this.source,
          phone: phoneTmp,
          location_id: this.addressData.locationId,
          contact_address_1: this.addressData.contactAddress1,
          contact_address_2: this.addressData.contactAddress2,
          contact_postal_code: this.addressData.contactPostalCode,
          contact_country: this.addressData.contactCountry,
          contact_city: this.addressData.contactCity,
          contact_state: this.addressData.contactState,
          billing_address_1: this.addressData.billingAddress1,
          billing_address_2: this.addressData.billingAddress2,
          billing_postal_code: this.addressData.billingPostalCode,
          billing_country: this.addressData.billingCountry,
          billing_city: this.addressData.billingCity,
          billing_state: this.addressData.billingState,
          preferred_language: !!this.preferredLanguage ? this.preferredLanguage : this.defaultLocale,
        };

        await api.client.update(this.agency, this.client?.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.toggleAccepted();

        this.$emit('reloadClient');
        this.$emit('emitReloadClients');
      } catch (e: any) {
        this.toggleAccepted();

        console.error({ e });
      }
    },
  },
});
</script>
