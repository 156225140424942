import { AbstractService } from '@uniqoders/sdk';
import Concept from '@/api/objects/Concept';

export default class ConceptService extends AbstractService {
  public async retrieve(type: string): Promise<Concept> {
    return this.request({
      method: 'get',
      url: this.buildPath('/concept/{type}', { type }),
    });
  }

  public async allConcepts(): Promise<Concept[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/concepts'),
    });
  }
}
