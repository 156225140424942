import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import TourDayItem from '@/api/objects/TourDayItem';
import SupplierResource from '@/api/objects/SupplierResource';
import Media from '@/api/objects/Media';

export interface TourDayItemResourceContract extends IBaseObject {
  amountPaid: string | number;
  createdAt: string;
  date: string;
  deletedAt: string;
  destination: string;
  endDate: string;
  endHour: string;
  grossPrice: string;
  initHour: string;
  invoiceChecked: boolean;
  invoiceStatus: string;
  isOptional: boolean;
  markup: string;
  media: Media[];
  modelId: number;
  modelType: string;
  nReserve: string;
  netPrice: string;
  netValidated: boolean;
  observations: string;
  client_observations: string;
  origin: string;
  pax: number;
  paymentStatus: string;
  reservationStatus: string;
  resource: SupplierResource;
  tourDayItem: TourDayItem;
  tourDayItemId: number;
  type: string;
  updatedAt: string;
  account: string;
  paymentMethod: string;
}

export default class TourDayItemResource extends BaseObject implements TourDayItemResourceContract {
  public declare amountPaid: string | number;
  public declare createdAt: string;
  public declare date: string;
  public declare deletedAt: string;
  public declare destination: string;
  public declare endDate: string;
  public declare endHour: string;
  public declare grossPrice: string;
  public declare initHour: string;
  public declare invoiceChecked: boolean;
  public declare invoiceStatus: string;
  public declare isOptional: boolean;
  public declare markup: string;
  public declare media: Media[];
  public declare modelId: number;
  public declare modelType: string;
  public declare nReserve: string;
  public declare netPrice: string;
  public declare netValidated: boolean;
  public declare observations: string;
  public declare client_observations: string;
  public declare origin: string;
  public declare pax: number;
  public declare paymentStatus: string;
  public declare reservationStatus: string;
  public declare resource: SupplierResource;
  public declare tourDayItem: TourDayItem;
  public declare tourDayItemId: number;
  public declare type: string;
  public declare updatedAt: string;
  public declare account: string;
  public declare paymentMethod: string;

  constructor(data: TourDayItemResourceContract) {
    super(data);
  }
}
