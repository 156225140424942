<template>
  <v-modal
    id="contact-details-modal"
    :is-double-modal="true"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('contact.contact') }}
      </modal-title>
    </modal-header>

    <modal-body class="justify-content-between d-flex flex-column item-details">
      <div class="detail mb-32">
        <div class="detail-title d-flex justify-content-between mb-16">
          <div class="fw-medium">{{ $t('contact.basicInformation') }}</div>

          <v-button
            v-if="!contact.deletedAt"
            class="btn-edit p-4"
            variant="icon"
            @click="editBasicInformation = !editBasicInformation">
            <v-icon icon="pencil"/>
          </v-button>
        </div>

        <div class="contact-detail-content">
          <Transition mode="out-in" name="fade-in">
            <div v-if="!editBasicInformation" class="row">
              <div class="text-sm py-4 me-4">
                <span class="info-title fw-medium">{{ $t('general.shared.name') }}:</span>
                <span v-if="!!contact.name">{{ contact.name }}</span>
                <span v-else>-</span>
              </div>

              <div class="text-sm py-4 me-4">
                <span class="info-title fw-medium">{{ $t('general.shared.surname') }}:</span>
                <span v-if="!!contact.surname">{{ contact.surname }}</span>
                <span v-else>-</span>
              </div>

              <div class="text-sm py-4 me-4">
                <span class="info-title fw-medium">{{ $t('general.shared.dateBirth') }}:</span>
                <span v-if="!!contact.birthday">{{ contact.birthday }}</span>
                <span v-else>-</span>
              </div>

              <div class="text-sm py-4 me-4">
                <span class="info-title fw-medium">{{ $t('general.shared.genre') }}:</span>
                <span v-if="!!contact.genre">{{ findConceptByKey('app.genres', contact.genre).name }}</span>
                <span v-else>-</span>
              </div>

              <div class="text-sm py-4 me-4">
                <span class="info-title fw-medium">{{ $t('general.shared.language') }}:</span>
                <span v-if="!!contact.language">{{ contact.language }}</span>
                <span v-else>-</span>
              </div>

              <div class="text-sm py-4 me-4">
                <span class="info-title fw-medium">{{ $t('general.shared.note') }}:</span>
                <span v-if="!!contact.note">{{ contact.note }}</span>
                <span v-else>-</span>
              </div>

              <div class="fw-medium mt-32 mb-12">{{ $t('general.shared.contactInformation') }}</div>

              <div class="text-sm py-4 me-4">
                <span class="info-title fw-medium">{{ $t('general.shared.email') }}:</span>
                <span v-if="!!contact.email">{{ contact.email }}</span>
                <span v-else>-</span>
              </div>

              <div class="text-sm py-4 me-4">
                <span class="info-title fw-medium">{{ $t('general.shared.phone') }}:</span>
                <span v-if="!!contact.phone" class="text-nowrap">+{{ contact.phone }}</span>
                <span v-else>-</span>
              </div>

              <div class="text-sm py-4 me-4">
                <span class="info-title fw-medium">{{ $t('general.shared.web') }}: </span>
                <a
                  v-if="!!contact.web"
                  :aria-label="contact.web"
                  :href="contact.web"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ contact.web }}
                </a>
              </div>

              <div class="row">
                <div class="contact-address col-12">
                  <div class="fw-medium mt-32 mb-12">{{ $t('general.address.address') }}</div>

                  <div class="text-sm py-4 me-4">
                    <span class="info-title fw-medium">{{ $t('general.address.streetAddress') }}:</span>
                    <span v-if="!!contact.contactAddress1">{{ contact.contactAddress1 }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="text-sm py-4 me-4">
                    <span class="info-title fw-medium">{{ $t('general.address.aptSuit') }}:</span>
                    <span v-if="!!contact.contactAddress2">{{ contact.contactAddress2 }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="text-sm py-4 me-4">
                    <span class="info-title fw-medium">{{ $t('general.address.country') }}:</span>
                    <span v-if="!!contact.contactCountry">{{ contact.contactCountry }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="text-sm py-4 me-4">
                    <span class="info-title fw-medium">{{ $t('general.address.postalCode') }}:</span>
                    <span v-if="!!contact.contactPostalCode">{{ contact.contactPostalCode }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="text-sm py-4 me-4">
                    <span class="info-title fw-medium">{{ $t('general.address.city') }}:</span>
                    <span v-if="!!contact.contactCity">{{ contact.contactCity }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="text-sm py-4 me-4">
                    <span class="info-title fw-medium">{{ $t('general.address.provinceStateRegion') }}:</span>
                    <span v-if="!!contact.contactState">{{ contact.contactState }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
            </div>

            <v-contact-form
              v-else
              :id="`update-${contactableType}-contact-form`"
              :ref="`update-${contactableType}-contact-form-ref`"
              :button-text="this.$t('general.button.save')"
              :contact="contact"
              :contactable-type="contactableType"
              :hidden-legend="true"
              :id-prefix="`update-${contactableType}`"
              :is-create-contact="false"
              @closed="$emit('closed')"
              @reload-item="$emit('reloadItem')"
            />
          </Transition>
        </div>
      </div>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import VContactForm from '@/components/shared/VContactForm.vue';
import Contact from '@/api/objects/Contact';

export default defineComponent({
  name: 'TheContactDetailsModal',
  components: {
    VContactForm,
    VIcon,
    VButton,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  emits: ['closed', 'reloadItem'],
  props: {
    contact: {
      type: Contact,
      required: true,
    },
    contactableType: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');

    return {
      findConceptByKey,
      ...form,
      name,
    };
  },
  data() {
    return {
      editBasicInformation: false as boolean,
    };
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      this.name = this.contact.name;
    },
  },
});
</script>

<style lang="scss" scoped>
.info-title {
  margin-right: .25rem;
}
</style>
