export default {
  pricing: 'Pricing',
  profit: 'Profit',
  netPrice: 'Cost Price',
  percentageProfit: '% Profit',
  grossPrice: 'Sales Price',
  dates: 'Dates',
  supplierTypeTotal: '{supplierType} total',
  updatePriceTitle: 'Update total price',
  updatePriceText: 'The total \'{ newTotal }\' does not match its old value \'{ initialValue }\'. If you change this total, keep in mind that the resource prices will be changed to match. Are you sure you want to do this?',
  updateTotalPriceText: 'The total \'{ newTotal }\' does not match its old value \'{ initialValue }\'. If you change this total, please note that the price of the tour will change but the prices of the products will remain the same. If you modify the price of any product, the total will be recalculated according to the prices of the products. Are you sure you want to do this?',
  total: 'Total',
  newInvoice: 'New invoice',
  invoice: {
    noResourcesSelectedMessage: 'Select a product to create an invoice',
    createNew: 'Create new invoice emitted',
    basicInformation: 'Basic information',
    number: 'Invoice number',
    amount: 'Amount',
    address: 'Address',
    resource: 'Resource',
    resources: 'Resources',
    status: 'Status',
    idIdentity: 'NIF',
    vat: 'VAT number',
    type: 'Type',
    currency: 'Currency',
  },
  nights: 'Nights',
  dayNumber: 'Number day',
  dayNumberShort: 'Nº day',
  quantity: 'Quantity',
  quantityShort: 'Qty.',
  splitPayment: 'Split Payment',
};
