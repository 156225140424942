<template>
  <div v-if="showResponsiveModal" class="p-16 border rounded-sm">
    <div>{{ $t('general.screenVerify.title') }}</div>

    <div>
      <p class="fw-bold text-gray-600">
        {{ $t('general.screenVerify.message') }}
      </p>

      <p class="fw-medium text-sm text-gray-500">
        {{ $t('general.screenVerify.openOnDesktop') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'TheScreenSizeVerify',
  emits: ['canShow'],
  data() {
    return {
      width: null as any,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['logged']),
    showResponsiveModal(): boolean {
      const canShow = (this.width < 1200 && this.logged) ?? false;

      this.$emit('canShow', !canShow);

      return canShow;
    },
  },
  created() {
    this.width = window.innerWidth;

    window.addEventListener('resize', this.onResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    },
  },
});
</script>
