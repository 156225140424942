import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import AgencyUser from '@/api/objects/AgencyUser';
import Agency from '@/api/objects/Agency';
import Role from './Role';

export interface UserContract extends IBaseObject {
  name: string;
  email: string;
  emailVerifiedAt: string;
  canAccess: boolean;
  impersonatedBy: null | number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  agencyUsers: AgencyUser[] | null;
  agencies: Agency[] | null;
  roles: Role[];
}

export default class User extends BaseObject implements UserContract {
  public declare name: string;
  public declare email: string;
  public declare emailVerifiedAt: string;
  public declare canAccess: boolean;
  public declare impersonatedBy: null | number;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare agencyUsers: AgencyUser[] | null;
  public declare agencies: Agency[] | null;
  public declare roles: Role[];

  constructor(data: UserContract) {
    super(data);
  }
}
