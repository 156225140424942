<template>
  <v-off-canvas
    id="client-tour-quote-offcanvas"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <div class="d-flex align-items-center">
        <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

        <h5 id="client-details-offcanvas-label" class="offcanvas-title">
          {{ $t('tourClient.quote') }}
        </h5>
      </div>
      </off-canvas-header>

      <off-canvas-body>
      <div
        class="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <v-checkbox
            id="case-quote-optional"
            v-model="optional"
            :label="$t('clientTour.quoteOptionalText')"
            :left="true"
            :right="false"
            class="mb-16"
            for-key="case-quote-optional"
          />

          <div>
            <p class="mb-8">{{ $t('clientTour.quotePriceText') }}</p>

            <v-form-input-radio
              v-model="priceType"
              :formValueOptions="[
                { key: 'tp', label: $t('clientTour.quoteTotalPrice') },
                { key: 'ppp', label: $t('clientTour.quotePricePerPerson') },
              ]"
              form-class="mb-8"
            />
          </div>
        </div>

        <v-button
          :disabled="disableBtn"
          :is-loading="disableBtn"
          class="btn-submit-form btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="openQuote"
        >
          {{ $t('general.button.see') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </div>
      </off-canvas-body>
    </resizable-container> 
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VCheckbox, VFormInputRadio } from '@uniqoders/form';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheCaseSetupQuoteOffCanvas',
  components: {
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VCheckbox,
    VFormInputRadio,
    ResizableContainer,
  },
  emits: ['closed', 'setOpenedWindow'],
  props: {
    quoteRoute: {
      type: Object,
      required: true,
    },
    openedWindow: {
      required: false,
    },
  },
  data() {
    return {
      disableBtn: false,
      optional: true,
      priceType: 'ppp',
    };
  },
  methods: {
    openQuote() {
      this.$emit('closed');

      const query = {
        ...this.quoteRoute.query,
        is_optional: this.optional ? 1 : 0,
      };

      if (this.optional) {
        query.is_optional = this.optional ? 1 : 0;
      }

      if (this.priceType === 'ppp') {
        query.price_type = 'ppp';
      }

      if (!!this.openedWindow && !this.openedWindow.closed) {
        this.openedWindow.close();
      }

      const routeData = this.$router.resolve({ name: this.quoteRoute.url, query });
      const width = 1000;
      const height = 800;
      const top = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
      const left = window.top.outerWidth / 2 + window.top.screenX - (width / 2);

      const openedWindowLocal = window.open(routeData.href, '_blank', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,left=${left},width=${width},height=${height},top=${top}`);

      this.$emit('setOpenedWindow', openedWindowLocal);
    },
  },
});
</script>
