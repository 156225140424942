<template>
  <v-modal
    id="supplier-resource-create"
    class-name="modal-fullscreen-sm-down"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('supplier.resource.createNew') }}
      </modal-title>
    </modal-header>

    <modal-body>
      <form
        id="supplier-resource-create-form"
        class="d-flex flex-column justify-content-between h-100"
      >
        <div class="fieldset-translatable mb-16">
          <nav class="translations-tab">
            <div id="nav-tab" class="nav nav-tabs" role="tablist">
              <v-button
                v-for="(language, index) in languages"
                :id="`nav-supplier-create-basic-information-${language.key}`"
                :key="index"
                :aria-controls="`supplier-create-basic-information-${language.key}`"
                :class="{ active: language.key === currentLanguage }"
                :data-bs-target="`#supplier-create-basic-information-${language.key}`"
                aria-selected="true"
                class="nav-tab-link py-8 px-16 me-8"
                data-bs-toggle="tab"
                role="tab"
                size="sm"
                variant="outline-primary"
                @click="currentLanguage = language.key"
              >
                {{ language.label }}
              </v-button>
            </div>
          </nav>

          <transition
            mode="out-in"
            name="fade"
            appear
          >
            <div
              id="supplier-resource-create-basic-information-tabs"
              class="tab-content"
            >
              <div
                v-for="(language, index) in languages"
                :id="`supplier-resource-create-basic-information-${language.key}`"
                :key="index"
                :aria-labelledby="`nav-supplier-resource-create-basic-information-${language.key}`"
                :class="{ 'active show': language.key === currentLanguage }"
                class="supplier-resource-create-basic-information-tab tab-pane fade"
                role="tabpanel">
                <the-supplier-resource-create-basic-information-fieldset
                  v-model:description="translations[language.key].description"
                  v-model:name="translations[language.key].name"
                  :clear="clear"
                  :current-language="currentLanguage"
                  :language="language.key"
                  @media-uploaded="editorMedia.push($event)"
                  @is-valid="isTranslationsValid.find((translation) => translation.locale === language.key).valid = $event"
                />
              </div>
            </div>
          </transition>
        </div>

        <fieldset>
          <legend class="visually-hidden">{{ $t('resource.basicInformation') }}</legend>

          <v-form
            id="supplier-resource-create-type-select"
            :label="`${$t('general.shared.type')}*`"
            :yup-errors-variable="errors.type"
            form-type="outline"
          >
            <v-select
              v-model="type"
              :class="{ 'is-invalid': !!errors.type }"
              :options="supplierResourceTypes"
              :reduce="(returnValue: any) => returnValue.key"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>

          <v-form
            id="supplier-resource-create-amenities-select"
            :label="$t('general.shared.amenities')"
            :yup-errors-variable="errors.amenities"
            form-type="outline"
          >
            <v-select
              v-model="amenities"
              :class="{ 'is-invalid': !!errors.amenities }"
              :options="supplierAmenities"
              :reduce="(returnValue: any) => returnValue.key"
              multiple
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>

          <v-form
            id="supplier-resource-create-cancellation-policy-select"
            :label="`${$t('general.shared.cancellationPolicy')}*`"
            :yup-errors-variable="errors.cancellationPolicy"
            form-type="outline"
          >
            <v-select
              v-model="cancellationPolicy"
              :class="{ 'is-invalid': !!errors.cancellationPolicy }"
              :options="cancellationPolicyTypes"
              :reduce="(returnValue: any) => returnValue.key"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>
        </fieldset>

        <div class="search-separator-container mb-24 pb-24">
          <div class="separator-text-container"/>
        </div>

        <fieldset>
          <legend class="visually-hidden">{{ $t('general.shared.media') }}</legend>

          <div class="resource-media-container mb-32">
            <div class="media-title text-lg fw-semi mb-16">{{ $t('general.shared.media') }}</div>

            <v-uploader
              ref="uploader"
              :after-upload="onChange"
              route-prefix="api/s3"
              @removed="onChange"
            />
          </div>
        </fieldset>

        <v-button
          :disabled="!isTranslationsValid.every(translation => translation.valid) || !meta.valid"
          :is-loading="isButtonLoading"
          class="btn-submit-form btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="createSupplierResource"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </form>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VForm } from '@uniqoders/form';
import Supplier from '@/api/objects/Supplier';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import useConcepts from '@/helpers/Concepts';
import api from '@/api';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { mapConcepts } from '@/helpers/ConceptHelper';
import VUploader from '@/components/vendor/upload/VUploader.vue';
import TheSupplierResourceCreateBasicInformationFieldset
  from '@/components/supplier/resource/TheSupplierResourceCreateBasicInformationFieldset.vue';
import useFormValidation from '@/helpers/form';

export default defineComponent({
  name: 'TheSupplierResourcesCreateModal',
  components: {
    TheSupplierResourceCreateBasicInformationFieldset,
    VUploader,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    VIcon,
    VForm,
    vSelect,
  },
  emits: ['closed', 'reloadSupplier'],
  props: {
    supplier: {
      type: Supplier,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      cancellationPolicy: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.cancellationPolicy')),
      type: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.type')),
      amenities: yup.array()
        .nullable()
        .label(t('general.shared.amenities')),
    });

    const form = useFormValidation(rules);

    const { value: cancellationPolicy } = useField('cancellationPolicy');
    const { value: type } = useField('type');
    const { value: amenities } = useField('amenities');

    return {
      ...useConcepts(),
      ...form,
      cancellationPolicy,
      type,
      amenities,
    };
  },
  data() {
    return {
      media: [] as any,
      translations: {} as Record<string, any>,
      currentLanguage: '',
      clear: false,
      isTranslationsValid: [] as Record<string, any>[],
      isButtonLoading: false as boolean,
      editorMedia: [] as any[],
    };
  },
  computed: {
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    languages(): any {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
    supplierResourceTypes(): any {
      if (!!this.concepts && this.concepts['supplier.type']) {
        return mapConcepts(this.concepts['supplier.type']);
      }

      return [];
    },
    supplierAmenities(): any {
      if (!!this.concepts && this.concepts['supplier.amenities']) {
        return mapConcepts(this.concepts['supplier.amenities']);
      }

      return [];
    },
    cancellationPolicyTypes(): any {
      if (!!this.concepts && this.concepts['supplier_resource.cancellation_policy_type']) {
        return mapConcepts(this.concepts['supplier_resource.cancellation_policy_type']);
      }

      return [];
    },
    mediaUuids() {
      return this.media.map((media: any) => (media.attributes.uuid));
    },
  },
  created() {
    this.currentLanguage = this.defaultLocale;

    this.mapTranslations();
  },
  methods: {
    mapTranslations() {
      this.isTranslationsValid = [];

      this.languages.forEach((language: Record<string, any>) => {
        this.isTranslationsValid.push({
          locale: language.key,
          valid: false,
        });

        this.translations[language.key] = {
          name: '',
          description: '',
        };
      });
    },
    onChange() {
      const { uploader }: any = this.$refs;

      if (!!uploader) {
        this.media = uploader.mediaLibrary.state.media;
      }
    },
    async createSupplierResource() {
      try {
        this.isButtonLoading = true;

        const data: any = {
          type: this.type,
          cancellation_policy: this.cancellationPolicy,
          images: this.mediaUuids,
          amenities: this.amenities,
          translations: this.translations,
        };

        const response = await api.supplierResource.create(this.supplier.id, data);

        if (!!this.editorMedia && this.editorMedia.length > 0) {
          const editorMedia = {
            media: this.editorMedia,
            model_type: 'supplier_resource',
            collection: 'tinymce',
            model_id: response.id,
          };

          await api.media.confirm('s3', editorMedia);
        }

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.clear = !this.clear;

        this.isButtonLoading = false;

        this.$emit('reloadSupplier');
      } catch (e: any) {
        this.isButtonLoading = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
