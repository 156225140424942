<template>
  <div id="accordionTourBeforeTour" class="accordion">
    <div class="case-details accordion-item">
      <h2 id="headingTourBeforeTour" class="accordion-header">
        <button
          aria-controls="collapseTourBeforeTour"
          aria-expanded="true"
          class="accordion-button collapsed"
          data-bs-target="#collapseTourBeforeTour"
          data-bs-toggle="collapse"
          type="button"
        >
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="contact" size="xxl" space="p-12" />
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t("clientTour.beforeTour") }}</div>
              <div class="fw-light accordion-subtitle">{{ $t("clientTour.beforeTourSubtitle") }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseTourBeforeTour"
        aria-labelledby="headingTourBeforeTour"
        class="accordion-collapse collapse show"
        data-bs-parent="#accordionTourBrforeTour"
      >
        <div class="accordion-body pb-32 pb-xl-48">
          <nav class="translations-tab">
            <div id="nav-tab" class="nav nav-tabs" role="tablist">
              <v-button
                v-for="(language, index) in languages"
                :id="`nav-template-day-update-basic-information-${language.key}`"
                :key="index"
                :aria-controls="`template-day-update-basic-information-${language.key}`"
                :class="{ active: language.shortName === currentLanguage }"
                :data-bs-target="`#template-day-update-basic-information-${language.key}`"
                aria-selected="true"
                class="nav-tab-link py-8 px-16 me-8"
                data-bs-toggle="tab"
                role="tab"
                size="sm"
                variant="outline-primary"
                @click="currentLanguage = language.shortName"
              >
                {{ language.label }}
              </v-button>
            </div>
          </nav>
          <form id="case-tour-update-before-update-form" class="d-flex flex-column justify-content-between h-100">
            <fieldset v-for="language in languages" :key="language.shortName">
              <div class="form-outline" v-if="currentLanguage === language.shortName">
                <label class="label">
                  <span>{{ $t("clientTour.beforeTour") }}</span>
                </label>
                <v-html-editor
                  :id="'case-tour-update-before-update-' + language.shortName"
                  ref="case-tour-update-before-update-ref"
                  :value="beforeTrip[language.shortName].description"
                  @input="(beforeTrip[language.shortName].description = $event), isSaveButtonDisabled()"
                  @media-uploaded="editorMedia.push($event)"
                />
              </div>
            </fieldset>

            <v-button
              :is-loading="accepted"
              :disabled="isSaveButtonDisabled()"
              class="btn-icon w-fit align-self-end mt-24"
              size="sm"
              variant="primary"
              @click="updateTour"
            >
              {{ $t("general.button.save") }}
              <v-icon icon="arrow-right" size="sm" space="ms-12" />
            </v-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import VHtmlEditor from "@/components/vendor/htmlEditor/VHtmlEditor.vue";
import api from "@/api";
import useFormValidation from "@/helpers/form";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import useConcepts from "@/helpers/Concepts";
import { mapConcepts } from "@/helpers/ConceptHelper";
import ClientTour from "@/api/objects/ClientTour";

export default defineComponent({
  name: "TheCaseSetupBeforeTour",
  components: {
    VHtmlEditor,
    VIcon,
    VButton,
  },
  emits: ["reloadClientTour"],
  props: {
    agency: {
      type: String,
      required: true,
    },
    clientTour: {
      type: ClientTour,
      required: true,
    },
  },
  setup(props) {
    const beforeTripData = props.clientTour?.beforeInformation?.translations || [];

    const beforeTrip = {
      en: { description: "" },
      nl: { description: "" },
      es: { description: "" },
    };

    beforeTripData.forEach((translation: { locale: any; description: any }) => {
      const { locale, description } = translation;
      if (beforeTrip.hasOwnProperty(locale)) {
        beforeTrip[locale].description = description;
      }
    });

    const form = useFormValidation("", beforeTrip);

    return {
      ...form,
      ...useConcepts(),
      beforeTrip,
    };
  },
  data() {
    return {
      disableButton: false as boolean,
      editorMedia: [] as any[],
      currentLanguage: localStorage.getItem("language"),
    };
  },
  computed: {
    languages(): any {
      if (!!this.concepts && this.concepts["app.languages"]) {
        return mapConcepts(this.concepts["app.languages"]);
      }

      return [];
    },
  },
  methods: {
    isSaveButtonDisabled() {
      // Do not use this pls, triggers rerender recursively
      // this.$forceUpdate();
      return !Object.keys(this.beforeTrip).some((locale) => {
        return (
          this.beforeTrip[locale]?.description?.trim() !== ""
        );
      });
    },
    async updateTour() {
      try {
        this.toggleAccepted();
        this.disableButton = true;

        const data = {
          client_id: this.clientTour.clientId,
          init_date: this.clientTour.initDate,
          end_date: this.clientTour.endDate,
          pax_number: this.clientTour.paxNumber,
          type: this.clientTour.type,
          before_trip: this.beforeTrip,
        };

        await api.case.update(this.agency, this.clientTour.id, data);

        if (!!this.editorMedia && this.editorMedia.length > 0) {
          const editorMedia = {
            media: this.editorMedia,
            model_type: "client_tour",
            collection: "tinymce",
            model_id: this.clientTour.id,
          };

          await api.media.confirm("s3", editorMedia);
        }

        this.$toast.success(this.$t("general.shared.savedChanges"));

        this.$emit("reloadClientTour");

        this.disableButton = false;
        this.toggleAccepted();
      } catch (e: any) {
        this.disableButton = false;
        this.toggleAccepted();

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>

<style>
.btn-outline-primary.active {
  background-color: #d82770;
  border-color: #d82770;
  color: #fcfcfc;
  border-width: 1px;
  border-radius: 0.5rem 0.5rem 0 0;
  margin-top: 0.25rem;
  box-shadow: none;
  border-bottom: 0px;
}

.nav-tabs .nav-tab-link {
  border-width: 1px;
  border-radius: 0.5rem 0.5rem 0 0;
  margin-top: 0.25rem;
  box-shadow: none;
}

.nav-tabs {
  border-bottom: 1px solid #d82770 !important;
}
</style>
