<template>
  <div class="detail">
    <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
      <div class="fw-medium">{{ $t("supplier.products") }}</div>

      <div>
        <v-button class="btn-rates p-4" variant="icon" @click="openOffCanvas('the-supplier-details-rates-off-canvas')">
          <v-icon icon="euro" />
        </v-button>

        <v-button class="btn-edit p-4" variant="icon" @click="openModal('the-supplier-resources-create-modal')">
          <v-icon icon="circle-plus" />
        </v-button>
      </div>
    </div>
    <div class="detail-content">
      <div v-if="!supplierLocal.resources || supplierLocal.resources.length === 0">
        {{ $t("supplier.noProducts") }}
      </div>
      <draggable
        :element="'div'"
        :list="supplierLocal.resources"
        class="detail-content resources-container"
        draggable=".item-container:not(.not-draggable)"
        item-key="id"
        @change="onChanged"
      >
        <template #item="{ element }">
          <div class="item-container supplier border rounded-xs p-12 mb-8">
            <div class="align-items-center">
              <div class="d-flex justify-content-between gap-16">
                <div class="fw-medium">{{ element.name }}</div>
                <div class="d-flex">
                  <v-button class="shadow-none p-4" variant="icon" @click="showSupplierResourceDetail(element.id)">
                    <v-icon icon="eye" />
                  </v-button>
                  <v-button class="shadow-none p-4" variant="icon" @click="archiveSupplierResource(element)">
                    <v-icon icon="trash" />
                  </v-button>
                </div>
              </div>
              <div class="resource-description text-sm fw-light text-gray-500">
                <span v-html="element.description" />
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <the-supplier-resources-create-modal
      v-if="modalToShow === 'the-supplier-resources-create-modal'"
      :supplier="supplier"
      @closed="closeModal"
      @reload-supplier="reloadSupplier"
    />

    <the-supplier-resource-details-modal
      v-if="modalToShow === 'the-supplier-resource-details-modal'"
      :resource-id="currentResource"
      @closed="
        closeModal();
        currentResource = null;
      "
      @reload-supplier="reloadSupplier"
    />

    <the-supplier-details-rates-off-canvas
      v-if="offCanvasToShow === 'the-supplier-details-rates-off-canvas'"
      :supplier-id="supplierLocal.id"
      @closed="closeOffCanvas"
      @reload-supplier="reloadSupplier"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Vuedraggable from 'vuedraggable';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useModalUtils from '@/helpers/ModalUtils';
import Supplier from '@/api/objects/Supplier';
import TheSupplierResourcesCreateModal from '@/components/supplier/resource/TheSupplierResourcesCreateModal.vue';
import SupplierResource from '@/api/objects/SupplierResource';
import TheSupplierResourceDetailsModal from '@/components/supplier/resource/TheSupplierResourceDetailsModal.vue';
import api from '@/api';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import TheSupplierDetailsRatesOffCanvas from '@/components/supplier/parts/TheSupplierDetailsRatesOffCanvas.vue';

export default defineComponent({
  name: 'TheSupplierDetailsResources',
  components: {
    TheSupplierDetailsRatesOffCanvas,
    TheSupplierResourceDetailsModal,
    TheSupplierResourcesCreateModal,
    VIcon,
    VButton,
    draggable: Vuedraggable,
  },
  emits: ['closed', 'reloadSupplier'],
  props: {
    supplier: {
      type: Supplier,
      required: true,
    },
  },
  setup() {
    return {
      ...useModalUtils(),
      ...useOffCanvasUtils(),
    };
  },
  data() {
    return {
      currentResource: null as null | number,
      supplierLocal: {} as Supplier,
      disableArchiveButton: false as boolean,
    };
  },
  watch: {
    supplier(value) {
      if (!!value && !!value.id) {
        this.supplierLocal = value;
      }
    },
  },
  created() {
    this.supplierLocal = this.supplier;
  },
  methods: {
    async onChanged(event: any) {
      // Handle the change event when the order changesmessage
      const sortableId = event.moved.element.id;
      const newPosition = { position: event.moved.newIndex + 1 };
      const response = await api.supplierResourcePrice.sortable(sortableId, newPosition);

      this.$toast.success(response.message);
    },
    showSupplierResourceDetail(supplierResourceId: number) {
      this.currentResource = supplierResourceId;

      if (this.modalToShow !== 'the-supplier-resource-details-modal') {
        this.openModal('the-supplier-resource-details-modal');
      }
    },
    async archiveSupplierResource(supplierResource: SupplierResource) {
      await this.$modal.delete({
        title: this.$t('supplier.resource.archiveTitle'),
        text: this.$t('supplier.resource.archiveText', {
          resource: supplierResource.name,
          supplier: this.supplierLocal.name,
        }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doArchiveSupplierResource(supplierResource.id),
      });
    },
    async doArchiveSupplierResource(supplierResourceId: string | number) {
      try {
        this.disableArchiveButton = true;

        await api.supplierResource.archive(supplierResourceId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.reloadSupplier();

        this.disableArchiveButton = false;
      } catch (e: any) {
        this.disableArchiveButton = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    reloadSupplier() {
      this.$emit('reloadSupplier');
    },
  },
});
</script>

<style lang="scss">
.resource-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    margin-bottom: 0;
  }
}
</style>
