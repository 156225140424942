import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import Contact from '@/api/objects/Contact';

export interface ClientContract extends IBaseObject {
  email: string;
  holdedId: string;
  name: string;
  surname: string;
  source: string;
  location: string;
  code: string;
  contactAddress1: string;
  contactAddress2: string;
  contactPostalCode: string;
  contactCountry: string;
  contactCity: string;
  contactState: string;
  billingAddress1: string;
  billingAddress2: string;
  billingPostalCode: string;
  billingCountry: string;
  billingCity: string;
  billingState: string;
  phone: string;
  preferredLanguage: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  contacts: Contact[] | null;
}

export default class Client extends BaseObject implements ClientContract {
  public declare email: string;
  public declare holdedId: string;
  public declare name: string;
  public declare surname: string;
  public declare source: string;
  public declare location: string;
  public declare code: string;
  public declare contactAddress1: string;
  public declare contactAddress2: string;
  public declare contactPostalCode: string;
  public declare contactCountry: string;
  public declare contactCity: string;
  public declare contactState: string;
  public declare billingAddress1: string;
  public declare billingAddress2: string;
  public declare billingPostalCode: string;
  public declare billingCountry: string;
  public declare billingCity: string;
  public declare billingState: string;
  public declare phone: string;
  public declare preferredLanguage: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare contacts: Contact[] | null;

  constructor(data: ClientContract) {
    super(data);
  }
}
