<template>
  <v-modal
    id="case-invoice-emitted-details-modal"
    class-name="modal-fullscreen-sm-down"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('clientTour.invoice.invoiceDetails') }}
      </modal-title>
    </modal-header>

    <modal-body>
      <div class="detail-title d-flex justify-content-between mb-20 align-items-center align-items-center">
        <div class="fw-medium">{{ $t('clientTour.invoice.basicInformation') }}</div>

        <v-button
          class="btn-edit p-4"
          variant="icon"
          @click="editInvoice = !editInvoice">
          <v-icon icon="pencil"/>
        </v-button>
      </div>

      <Transition mode="out-in" name="fade-in">
        <div v-if="!loaded" class="position-relative py-64">
          <v-loader/>
        </div>

        <div v-else>
          <Transition mode="out-in" name="fade-in">
            <div v-if="!editInvoice" class="invoice-details text-sm">
              <div class="item-data">
                <span class="item-data-title">{{ $t('clientTour.invoice.number') }}:</span>
                <span class="item-data-value">{{ invoice.number }}</span>
              </div>

              <div class="item-data">
                <span class="item-data-title">{{ $t('clientTour.invoice.amount') }}:</span>
                <span class="item-data-value">
                  <span class="me-2">{{ $t('general.shared.euroShort') }}</span>
                  {{ invoice.amount }}
                </span>
              </div>

              <div class="item-data">
                <span class="item-data-title">{{ $t('clientTour.invoice.nif') }}:</span>
                <span class="item-data-value">{{ !!invoice.idIdentity ? invoice.idIdentity : '-' }}</span>
              </div>

              <div class="item-data">
                <span class="item-data-title">{{ $t('clientTour.invoice.vat') }}:</span>
                <span class="item-data-value">{{ !!invoice.vat ? invoice.vat : '-' }}</span>
              </div>

              <div class="detail-title d-flex mb-8 mt-20 text-normal">
                <div class="fw-medium">{{ $t('general.address.address') }}</div>
              </div>

              <div class="item-data">
                <span class="item-data-title">{{ $t('general.address.streetAddress') }}:</span>
                <span class="item-data-value">{{ !!invoice.line1 ? invoice.line1 : '-' }}</span>
              </div>

              <div class="item-data">
                <span class="item-data-title">{{ $t('general.address.aptSuit') }}:</span>
                <span class="item-data-value">{{ !!invoice.line2 ? invoice.line2 : '-' }}</span>
              </div>

              <div class="item-data">
                <span class="item-data-title">{{ $t('general.address.postalCode') }}:</span>
                <span class="item-data-value">{{ !!invoice.postalCode ? invoice.postalCode : '-' }}</span>
              </div>

              <div class="item-data">
                <span class="item-data-title">{{ $t('general.address.country') }}:</span>
                <span class="item-data-value">{{ !!invoice.country ? invoice.country : '-' }}</span>
              </div>

              <div class="item-data">
                <span class="item-data-title">{{ $t('general.address.city') }}:</span>
                <span class="item-data-value">{{ !!invoice.city ? invoice.city : '-' }}</span>
              </div>

              <div class="item-data">
                <span class="item-data-title">{{ $t('general.address.provinceStateRegion') }}:</span>
                <span class="item-data-value">{{ !!invoice.state ? invoice.state : '-' }}</span>
              </div>
            </div>

            <form
              v-else
              id="tour-invoice-update-form"
              class="d-flex flex-column justify-content-between h-100"
            >
              <div>
                <fieldset>
                  <legend class="visually-hidden">{{ $t('tourPricing.invoice.amount') }}</legend>
                  <div class="row gx-12">
                    <div class="col-12">
                      <div class="form-outline">
                        <label class="label">
                          <span>{{ $t('tourPricing.invoice.amount') }}*</span>
                        </label>

                        <v-form-input
                          id="tour-invoice-update-amount-due"
                          v-model="amount"
                          :yup-errors-variable="errors.amount"
                          autocomplete="off"
                          form-type="input-group"
                          input-class="px-32"
                          onwheel="return false;"
                          right-addon="€"
                          step=".01"
                          type="number"
                        />
                      </div>
                    </div>

                    <v-form-input
                      id="tour-invoice-update-id-identity"
                      v-model="idIdentity"
                      :label="this.$t('tourPricing.invoice.idIdentity')"
                      :yup-errors-variable="errors.idIdentity"
                      autocomplete="off"
                      class="col-12"
                      form-type="outline"
                    />

                    <v-form-input
                      id="tour-invoice-update-vat"
                      v-model="vat"
                      :label="this.$t('tourPricing.invoice.vat')"
                      :yup-errors-variable="errors.vat"
                      autocomplete="off"
                      class="col-12"
                      form-type="outline"
                    />
                  </div>
                </fieldset>

                <div class="search-separator-container pb-32">
                  <div class="separator-text-container">
                    <span class="separator-text text-sm">{{ $t('tourPricing.invoice.address') }}</span>
                  </div>
                </div>

                <v-contact-billing-form
                  :id="`invoice-contact-address-form`"
                  :ref="`invoice-contact-address-form-ref`"
                  :data="addressData"
                  :hidden-legend="true"
                  :id-prefix="`invoice-contact-create`"
                  :without-billing-address="true"
                  @updated-data="addressData = $event"
                  @is-valid="addressValid = $event"
                />
              </div>

              <v-button
                :disabled="!meta.valid || accepted"
                :is-loading="accepted"
                class="btn-submit-form btn-icon w-fit align-self-end"
                size="sm"
                variant="primary"
                @click="updateInvoice"
              >
                {{ $t('general.button.save') }}
                <v-icon icon="arrow-right" size="sm" space="ms-12"/>
              </v-button>
            </form>
          </Transition>
        </div>
      </Transition>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import api from '@/api';
import Invoice from '@/api/objects/Invoice';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useFormValidation from '@/helpers/form';
import useConcepts from '@/helpers/Concepts';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';

export default defineComponent({
  name: 'TheCaseInvoiceEmittedDetailsModal',
  components: {
    VContactBillingForm,
    VLoader,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VIcon,
    VButton,
    VFormInput,
  },
  emits: ['closed', 'reloadInvoices'],
  props: {
    agency: {
      type: String,
      required: true,
    },
    tour: {
      type: Number,
      required: true,
    },
    clientTour: {
      type: Number,
      required: true,
    },
    invoiceId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      amount: yup.number()
        .required()
        .nullable()
        .min(0)
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('tourPricing.invoice.number')),
      idIdentity: yup.string()
        .nullable()
        .label(t('tourPricing.invoice.idIdentity')),
      vat: yup.string()
        .nullable()
        .label(t('tourPricing.invoice.vat')),
    });

    const form = useFormValidation(rules);

    const { value: amount } = useField('amount');
    const { value: idIdentity } = useField('idIdentity');
    const { value: vat } = useField('vat');

    return {
      ...useConcepts(),
      ...form,
      findConceptByKey,
      amount,
      idIdentity,
      vat,
    };
  },
  data() {
    return {
      invoice: null as null | Invoice,
      editInvoice: false as boolean,
      loaded: false as boolean,
      disableUpdateBtn: false as boolean,
      addressData: {} as any,
      addressValid: false as boolean,
    };
  },
  async created() {
    await this.loadInvoice();
    await this.setDefaultValues();
  },
  methods: {
    async loadInvoice() {
      this.loaded = false;

      this.invoice = await api.invoice.retrieve(this.agency, this.clientTour, this.tour, this.invoiceId);

      this.loaded = true;
    },
    setDefaultValues() {
      if (!!this.invoice) {
        this.setValues({
          amount: Number(this.invoice.amount),
          address: this.invoice.line1,
          addressTwo: this.invoice.line2,
          city: this.invoice.city,
          country: this.invoice.country,
          region: this.invoice.state,
          postalCode: this.invoice.postalCode,
          idIdentity: this.invoice.idIdentity,
          vat: this.invoice.vat,
        });

        this.addressData.contactAddress1 = this.invoice.line1;
        this.addressData.contactAddress2 = this.invoice.line2;
        this.addressData.contactPostalCode = this.invoice.postalCode;
        this.addressData.contactCountry = this.invoice.country;
        this.addressData.contactCity = this.invoice.city;
        this.addressData.contactState = this.invoice.state;
      }
    },
    async updateInvoice() {
      if (!!this.invoice) {
        try {
          this.disableUpdateBtn = true;

          const data = {
            amount: this.amount,
            line1: this.addressData.contactAddress1,
            line2: this.addressData.contactAddress2,
            postal_code: this.addressData.contactPostalCode,
            country: this.addressData.contactCountry,
            city: this.addressData.contactCity,
            state: this.addressData.contactState,
            id_identity: this.idIdentity,
            vat: this.vat,
            type: 'emitted',
          };

          await api.invoice.update(this.agency, this.clientTour, this.tour, this.invoiceId, data);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          await this.loadInvoice();
          this.setDefaultValues();

          this.$emit('reloadInvoices');
        } catch (e: any) {
          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        } finally {
          this.disableUpdateBtn = false;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.input-group) {
  .form-control {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

.item-data {
  padding-block: .25rem;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 1rem;

  @media (min-width: 992px) {
    grid-template-columns: 20% 80%;
  }

  .item-data-title {
    font-weight: 500;
  }
}
</style>
