<template>
  <v-modal
    class-name="modal-fullscreen-sm-down"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        <template v-if="!!title">{{ title }}</template>
        <template v-else>{{ $t('contact.createNewContact') }}</template>
      </modal-title>
    </modal-header>

    <modal-body>
      <v-contact-form
        :id="`${idPrefix}-contact-form`"
        :ref="`${idPrefix}-contact-form-ref`"
        :button-text="this.$t('general.button.create')"
        :contactable-id="associateModelId"
        :contactable-type="contactableType"
        :id-prefix="idPrefix"
        :is-create-contact="true"
        @closed="$emit('closed')"
        @reload-item="$emit('reloadItem')"
      />
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import VContactForm from '@/components/shared/VContactForm.vue';

export default defineComponent({
  name: 'VContactCreateModal',
  components: {
    VContactForm,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  emits: ['closed', 'reloadItem'],
  props: {
    idPrefix: {
      type: String,
      required: false,
      default: 'create-contact',
    },
    title: {
      type: String,
      required: false,
    },
    associateModelId: {
      type: Number,
      required: true,
    },
    contactableType: {
      type: String,
      required: true,
    },
  },
});
</script>
