<template>
    <div class="page-header">
      <div
        class="container-xl header-inner d-flex flex-row align-items-sm-center justify-content-between 
        text-light py-48 py-md-80 px-16"
      >
        <div class="tour-data text-sm">
          <h2 class="h4 header-title">{{ $t("general.shared.headerTile") }}</h2>
        </div>

        <div class="header-logo">
          <v-picture class="logo">
            <img
              :alt="$t('general.shared.appLogoAlt')"
              src="@/../resources/img/logo-waw-travel-white.png"
              srcset="@/../resources/img/logo-waw-travel-white.png 2x, @/../resources/img/logo-waw-travel-white.png 1x"
            />
          </v-picture>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "TheEmailHeader",
};
</script>

<style lang="scss" scoped>
.basic-information {
  @media only screen {
    font-weight: 500;

    @media (min-width: 1200px) {
      color: var(--uq-white);
      font-weight: 300;
    }
  }

  @media print {
    color: var(--uq-white);
    font-weight: 500;
  }
}

.page-header {
  background: linear-gradient(122.71deg, rgba(var(--uq-primary-rgb), 1) 31%, rgba(var(--uq-secondary-rgb), 1) 150%);

  @media print {
    height: 6.375rem;
  }

  .header-inner {
    position: relative;

    .tour-data {
      z-index: 20;

      @media print {
        font-weight: 500;
      }

      .header-title {
        @media print {
          display: none;
        }
      }
    }

    .header-logo {
      position: absolute;
      right: 50%;
      top: 100%;
      transform: translate(50%, -50%);
      opacity: 0.1;

      :deep(.logo img) {
        width: 70vw;
        max-width: 992px;
      }
    }
  }

  @media print {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .header-inner {
    @media print {
      padding: 1.75rem 1rem;
      max-width: 58.125rem;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.traveler {
  border-radius: 0.25rem;
  position: relative;
  box-shadow: 0 0.5rem 1.75rem rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.dot-divider {
  background-color: var(--uq-white, #f1c933);
  border-radius: 50%;
  display: inline-flex;
  height: 0.25rem;
  margin-inline: 1rem;
  width: 0.25rem;
}
</style>
