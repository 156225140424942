<template>
  <template v-if="status === 'loading'">
    <v-loader/>
  </template>

  <div v-else class="traveler-form-container">
    <header id="top" class="fixed-top">
      <div class="header-container">
        <div class="container-xl px-0">
          <nav
            class="navbar navbar-expand-lg flex-column flex-sm-row align-items-start align-items-sm-center px-16
            justify-content-sm-start">
            <div class="uq-header-logo me-16 me-lg-32 mb-8 mb-sm-0 d-none d-sm-block">
              <div
                id="header-logo"
              >
                <v-picture class="d-none d-lg-block">
                  <img
                    :alt="$t('general.shared.appLogoAlt')"
                    src="@/../resources/img/waw-logo.png"
                    srcset="@/../resources/img/waw-logo.png 2x,
                       @/../resources/img/waw-logo.png 1x"/>
                </v-picture>

                <v-picture class="d-block d-lg-none">
                  <img
                    :alt="$t('general.shared.appLogoAlt')"
                    src="@/../resources/img/waw-logo-mobile.png"
                    srcset="@/../resources/img/waw-logo-mobile.png 2x,
                       @/../resources/img/waw-logo-mobile.png 1x"/>
                </v-picture>
              </div>
            </div>

            <div class="tour-data text-sm">
              <div v-if="!!tour.name" class="mb-4">{{ tour.name }}</div>

              <div class="locations d-flex align-items-center fw-medium mb-4">
                <div v-if="!!tour.origin && !!tour.origin.name">{{ tour.origin.name }}</div>
                <div v-else>{{ $t('traveler.withoutOrigin') }}</div>

                <v-icon icon="arrow-right" size="sm" space="px-8"/>

                <div v-if="!!tour.destination && !!tour.destination.name">{{ tour.destination.name }}</div>
                <div v-else>{{ $t('traveler.withoutDestination') }}</div>
              </div>

              <div class="d-flex align-items-center fw-light">
                <div class="dates">
                  <template v-if="!!tour.initDate">
                    {{ $str.formatDateTime(tour.initDate, settings.formatDate) }}
                  </template>

                  <template v-else>{{ $t('traveler.noStartDate') }}</template>
                  -
                  <template v-if="!!tour.endDate">
                    {{ $str.formatDateTime(tour.endDate, settings.formatDate) }}
                  </template>

                  <template v-else>{{ $t('traveler.noEndDate') }}</template>
                </div>

                <span class="dot-divider"/>

                <div class="travelers">
                  {{ paxNumber }}
                  <v-icon icon="group" size="sm"/>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>

    <main class="bg-tertiary-faded">
      <div class="container">
        <div class="min-vh-100 pb-48 pt-140 pt-sm-120 pt-lg-96 d-flex align-items-center">
          <div class="border-0 py-32 px-24 p-lg-72 w-100">
            <div class="traveler-container mb-48">
              <div
                v-for="(traveler, index) in pax"
                :id="`accordion-pax-${index}`"
                :key="index"
                class="traveler bg-light p-16 p-lg-24 accordion"
              >

                <div :id="`head-pax-${index}`" class="cursor-pointer">
                  <div
                    :aria-controls="`collapse-pax-${index}`"
                    :data-bs-target="`#collapse-pax-${index}`"
                    aria-expanded="true"
                    class="accordion-button fw-semi d-flex align-items-center bg-transparent p-0 h-100 shadow-none"
                    data-bs-toggle="collapse"
                  >
                    {{ $t('traveler.traveler') }}
                    <span class="text-sm me-4">(#{{ index + 1 }})</span>
                    <span class="fw-normal">- {{ traveler.name }} {{ traveler.surname_1 }} {{ traveler.surname_2 }}
                    </span>
                  </div>
                </div>

                <div
                  :id="`collapse-pax-${index}`"
                  :aria-labelledby="`head-pax-${index}`"
                  :class="{ show: index === 0 }"
                  :data-bs-parent="`#accordion-pax-${index}`"
                  class="collapse"
                >
                  <the-traveler-form
                    v-model:address-city="traveler.address_city"
                    v-model:address-country="traveler.address_country"
                    v-model:address-cp="traveler.address_cp"
                    v-model:address-state="traveler.address_state"
                    v-model:address-street="traveler.address_street"
                    v-model:allergies="traveler.allergies"
                    v-model:arrival-date="traveler.arrival_date"
                    v-model:arrival-hour="traveler.arrival_hour"
                    v-model:arrival-location="traveler.arrival_location"
                    v-model:arrival-number="traveler.arrival_number"
                    v-model:arrival-type="traveler.arrival_type"
                    v-model:bike-type="traveler.bike_type"
                    v-model:birth-date="traveler.birth_date"
                    v-model:departure-date="traveler.departure_date"
                    v-model:departure-hour="traveler.departure_hour"
                    v-model:departure-location="traveler.departure_location"
                    v-model:departure-number="traveler.departure_number"
                    v-model:departure-type="traveler.departure_type"
                    v-model:diet="traveler.diet"
                    v-model:document-number="traveler.document_number"
                    v-model:document-type="traveler.document_type"
                    v-model:email="traveler.email"
                    v-model:height="traveler.height"
                    v-model:id="traveler.id"
                    v-model:language="traveler.language"
                    v-model:name="traveler.name"
                    v-model:observations="traveler.observations"
                    v-model:phone="traveler.phone"
                    v-model:surname1="traveler.surname_1"
                    v-model:surname2="traveler.surname_2"
                    v-model:weight="traveler.weight"
                    @is-valid="isValid = $event"
                    @update-paxs="sendForm()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import TheTravelerForm from '@/components/traveler/TheTravelerForm.vue';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import Tour from '@/api/objects/Tour';
import { useAppStore } from '@/stores/app';

export default defineComponent({
  name: 'TheTourTravelersForm',
  components: {
    VIcon,
    VPicture,
    TheTravelerForm,
    VLoader,
  },
  data() {
    return {
      status: 'loading' as string,
      pax: [] as Record<string, string | undefined>[],
      disableSendBtn: false,
      tour: {} as Tour,
      isValid: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
    paxNumber(): number {
      return Number(this.$route.query.pax);
    },
    tourId(): number {
      return Number(this.$route.query.tour);
    },
    token(): any {
      return this.$route.query.token;
    },
  },
  async created() {
    await this.loadTour();

    this.status = 'loaded';
  },
  methods: {
    async loadTour() {
      try {
        this.tour = await api.pax.retrieveTour(this.tourId, { token: this.token });

        this.pax = this.tour.paxs.map((pax: any) => ({
          address_city: pax.addressCity,
          address_country: pax.addressCountry,
          address_cp: pax.addressCp,
          address_state: pax.addressState,
          address_street: pax.addressStreet,
          allergies: pax.allergies,
          arrival_date: pax.arrivalDate,
          arrival_hour: pax.arrivalHour,
          arrival_location: pax.arrivalLocation,
          arrival_number: pax.arrivalNumber,
          arrival_type: pax.arrivalType,
          birth_date: pax.birthDate,
          bike_type: pax.bikeType,
          departure_date: pax.departureDate,
          departure_hour: pax.departureHour,
          departure_location: pax.departureLocation,
          departure_number: pax.departureNumber,
          departure_type: pax.departureType,
          diet: pax.diet,
          document_number: pax.documentNumber,
          document_type: pax.documentType,
          email: pax.email,
          height: pax.height,
          id: pax.id,
          language: pax.language,
          name: pax.name,
          observations: pax.observations,
          phone: pax.phone,
          surname_1: pax.surname1,
          surname_2: pax.surname2,
          weight: pax.weight,
        }));

        await this.setPax();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    setPax() {
      while (this.pax.length < this.paxNumber) {
        this.pax.push({
          address_city: '',
          address_country: '',
          address_cp: '',
          address_state: '',
          address_street: '',
          allergies: '',
          arrival_date: '',
          arrival_hour: '',
          arrival_location: '',
          arrival_number: '',
          arrival_type: '',
          bike_type: '',
          birth_date: '',
          departure_date: '',
          departure_hour: '',
          departure_location: '',
          departure_number: '',
          departure_type: '',
          diet: '',
          document_number: '',
          document_type: '',
          email: '',
          height: '',
          id: '',
          language: '',
          name: '',
          observations: '',
          phone: '',
          surname_1: '',
          surname_2: '',
          weight: '',
        });
      }
    },
    async sendForm() {
      try {
        this.disableSendBtn = true;

        const paxFilled = this.pax.filter((traveler: Record<string, any>) => !!traveler.name);

        await api.pax.create(this.tourId, { token: this.token }, { pax: paxFilled });

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.disableSendBtn = false;
      } catch (e: any) {
        this.disableSendBtn = false;

        if (e?.code !== 'ERR_CANCELED') {
          console.error(e);

          this.$toast.error(this.$t('general.toast.errorMessage'));
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.traveler-form-container {
  .traveler {
    border-radius: .25rem;
    position: relative;
    box-shadow: 0 .5rem 1.75rem rgba(0, 0, 0, .1);
    margin-bottom: 1rem;
  }

  .dot-divider {
    background-color: var(--uq-primary, #f1c933);
    border-radius: 50%;
    display: inline-flex;
    height: .25rem;
    margin-inline: 1rem;
    width: .25rem;
  }
}
</style>
