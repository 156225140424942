import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface SupplierResourceTranslationContract extends IBaseObject {
  description: string;
  locale: string;
  name: string;
  supplierResourceId: number;
}

export default class SupplierResourceTranslation extends BaseObject implements SupplierResourceTranslationContract {
  public declare description: string;
  public declare locale: string;
  public declare name: string;
  public declare supplierResourceId: number;

  constructor(data: SupplierResourceTranslationContract) {
    super(data);
  }
}
