export default {
  tourBuilder: 'Tour Builder',
  stage: 'Stage',
  item: 'Item',
  itemDetails: 'Detalles del artículo',
  paxErrorMessage: 'Pax es un campo obligatorio',
  deleteTourDayTitle: 'Borrar día',
  deleteTourDayText: '¿Está seguro de que desea eliminar el día {dayNumber} ({date})? Si lo borras, todo el progreso que hayas hecho en este día se perderá',
  deleteTourDayItemTitle: 'Borrar elemento del día',
  deleteTourDayItemText: '¿Estás seguro de que quieres borrar este \'{dayItem}\'? Si lo borras, perderás todo el progreso que hayas hecho',
  dayUpdated: 'Día actualizado con éxito',
  dayUpdateError: 'Se ha producido un error al actualizar el día',
  dayCreated: 'Día creado con éxito',
  dayCreateError: 'Se ha producido un error al crear el día',
  dayDeleted: 'Día borrado con éxito',
  dayDeleteError: 'Se ha producido un error al borrar el día',
  dayItemCreated: 'Artículo creado con éxito',
  dayITemCreateError: 'Se ha producido un error al crear el elemento',
  dayItemDeleted: 'Elemento eliminado correctamente',
  dayItemDeleteError: 'Se ha producido un error al eliminar el elemento',
  resources: 'Recursos',
  reservation: 'Reserva',
  payment: 'Pago',
  invoice: 'Factura',
  exportAsProduct: 'Exportar como actividad',
  searchTourDayItemResource: 'Buscar recursos',
  tourDayItemResourceDates: 'Fechas de recursos',
  supplierNoOptions: 'No hay proveedores',
  writeSearchSuppliers: 'Escribe para buscar proveedores',
  writeSearchResources: "Escribe para buscar recursos",
  price: 'Precios',
  payments: 'Pagos',
  deleteTourDayItemResourceTitle: 'Eliminar producto',
  deleteTourDayItemResourceText: '¿Está seguro de que desea eliminar todos los productos de \'{resource}\' en {date}? Si los eliminas se perderán todos los datos que hayas añadido',
  booking: 'Reserva',
  bookingStatus: 'Estado de la reserva',
  lengthErrorMessage: 'Escribe una distancia en Kms o escribe cero',
  KilometersShorted: 'Km',
  exportTourAsTemplateTitle: 'Exportar recorrido como actividad',
  exportTourAsTemplateText: '¿Está seguro de que desea crear una actividad basado en este caso?',
  editStageInformation: 'Editar información de la etapa',
  stageInformation: 'Información de la etapa',
  removeMediaTitle: 'Eliminar medios',
  removeMediaText: '¿Estás seguro de que quieres borrar el archivo? Si lo borras, no podrás recuperarlo',
  advancedOptions: 'Opciones avanzadas',
  addDayTitle: 'Añadir día al Tour',
  addDayText: '¿Estás seguro de que quieres añadir un nuevo día a este Tour? Ten en cuenta que al añadir un nuevo día todos los hoteles, transfers, etc que se hayan añadido y les afecte este nuevo día, cambiarán de fecha, tanto si está confirmada como si no.',
  hotelRoomTypeUnavailable: 'Hotel tipo de habitación no disponible',
  roomTypeMissingWarning: 'Elimina este campo e introduce un tipo de habitación',
  transferNotSelected: 'Transfer no seleccionado',
  transportNotSelected: 'Transporte no seleccionado',
};
