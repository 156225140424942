<template>
  <div id="page-resume-contact" class="w-lg-60 mb-24 mb-lg-0">
    <h3 class="h4 mb-12">
      {{ tour.name }}
    </h3>

    <div class="client-container bg-white w-100 text-sm p-28">
      <div class="mb-4">
        <span class="fw-semi me-4">{{ $t('general.shared.name') }}: </span>
        {{ tour.clientTour.client.name }} {{ tour.clientTour.client.surname }}
      </div>

      <div class="mb-4">
        <span class="fw-semi me-4">{{ $t('general.shared.destination') }}: </span>
        <template v-if="!!tour.destination && !!tour.destination.name">{{ tour.destination.name }}</template>
        <template v-else>{{ $t('traveler.withoutDestination') }}</template>
      </div>

      <div class="mb-4">
        <span class="fw-semi me-4">{{ $t('general.shared.persons') }}: </span>
        {{ tour.clientTour.paxNumber }}
      </div>

      <div class="mb-4">
        <span class="fw-semi me-4">{{ $t('general.shared.dates') }}: </span>
        <template v-if="!!tour.initDate">{{ $str.formatDateTime(tour.initDate, settings.formatDate) }}</template>
        <template v-else>{{ $t('traveler.noStartDate') }}</template>
        -
        <template v-if="!!tour.endDate">{{ $str.formatDateTime(tour.endDate, settings.formatDate) }}</template>
        <template v-else>{{ $t('traveler.noEndDate') }}</template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapState } from 'pinia';
import Tour from '@/api/objects/Tour';
import { useAppStore } from '@/stores/app';

export default defineComponent({
  name: 'TheTravelBookClient',
  props: {
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}

.client-container {
  border-radius: .5rem;
  box-shadow: 0 1px .25rem var(--uq-gray-300);
}
</style>
