export default {
  fileTypeNotAllowed: 'You must upload a file of type',
  tooLarge: 'File too large, max',
  tooSmall: 'File too small, min',
  tryAgain: 'please try uploading this file again',
  somethingWentWrong: 'Something went wrong while uploading this file',
  selectOrDragMax: 'Select or drag max {maxItems} {file}',
  selectOrDrag: 'Select or drag files',
  file: { singular: 'file', plural: 'files' },
  anyImage: 'any image',
  anyVideo: 'any video',
  goBack: 'Go back',
  dropFile: 'Drop file to upload',
  dragHere: 'Drag file here',
  remove: 'Remove',
  name: 'Name',
  download: 'Download',
};
