<template>
  <main class="bg-tertiary-faded">
    <div class="container">
      <div class="auth-container min-vh-100 py-48">
        <div class="auth-content bg-light border-0 py-64 px-72">
          <div
            id="login-logo"
            class="logo mx-auto mb-56">
            <router-link :to="{ name: 'login' }">
              <v-picture class="d-block">
                <img
                  :alt="$t('general.shared.appLogoAlt')"
                  src="../../../resources/img/waw-logo.png"
                  srcset="@/../resources/img/waw-logo.png 2x,
                 @/../resources/img/waw-logo.png 1x"/>
              </v-picture>
            </router-link>
          </div>

          <slot/>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';

export default defineComponent({
  name: 'VAuthLayout',
  components: {
    VPicture,
  },
});
</script>

<style lang="scss" scoped>
.bg-tertiary-faded {
  background-color: rgba(var(--uq-tertiary-rgb), .1);

  .auth-container {
    display: grid;
    place-content: center;

    .auth-content {
      width: 30rem;
      border-radius: 1rem;

      @media (min-width: 992px) {
        min-width: 28.125rem;
      }

      #login-logo {
        max-width: 8.25rem;
      }
    }
  }
}
</style>
