<template>
  <div id="generated-travel-book" class="h-100">
    <v-loader v-if="loadingTravelBook" />

    <div v-else-if="show404Error" class="error-container d-flex align-items-center justify-content-center h-100">
      <div class="p-16 py-lg-24 px-lg-32">
        <div class="title fw-bold">{{ $t('general.errors.error404') }}</div>
        <div class="text-xl mb-16">{{ $t('general.errors.pageNotFound') }}</div>
        <div>{{ $t('general.errors.pageNotFoundText') }}</div>
      </div>
    </div>

    <template v-else>
      <v-travel-book-page-header v-if="!!tour" :tour="tour" />

      <table>
        <thead>
          <tr>
            <td>
              <div class="header-space" />
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div class="page">
                <div class="page-inner container">
                  <div class="d-flex justify-content-between align-items-center mb-32">
                    <h3 class="h4 mb-0">
                      {{ tour.name }}
                    </h3>

                    <v-button :disabled="downloadingPdf" class="fw-medium text-sm hide-print" size="xs"
                      variant="primary" @click="downloadPDF">
                      <v-icon icon="download" space="me-8" />

                      <template v-if="downloadingPdf">{{ $t('general.button.generatingPdf') }}</template>
                      <template v-else>{{ $t('general.button.download') }}</template>
                    </v-button>
                  </div>

                  <the-travel-book-resume-contact v-if="!!tour" :tour="tour" />

                  <div v-if="!!featuredImage" class="featured-image mt-32">
                    <v-picture class="d-block">
                      <img :alt="$t('clientTour.featuredImage')" :src="featuredImage.storageUrl" />
                    </v-picture>
                  </div>
                </div>
              </div>

              <div class="page">
                <the-travel-book-resume-days v-if="!!tour" :is-summary="false" :locations="locations"
                  :tour-days="tourDays" :client-tour="tour?.clientTour" :tour-template="tourTemplate" />
              </div>

              <div class="page">
                <the-travel-book-days :locations="locations" :tour="tour" :tour-days="tourDays"
                  :tour-template="tourTemplate" />
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div class="footer-space" />
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import api from '@/api';
import Tour from '@/api/objects/Tour';
import TheTravelBookResumeContact from '@/components/tour/book/parts/TheTravelBookResumeContact.vue';
import VTravelBookPageHeader from '@/components/tour/book/parts/VTravelBookPageHeader.vue';
import SupplierResource from '@/api/objects/SupplierResource';
import TheTravelBookResumeDays from '@/components/tour/book/parts/TheTravelBookResumeDays.vue';
import TourDay from '@/api/objects/TourDay';
import TheTravelBookDays from '@/components/tour/book/parts/TheTravelBookDays.vue';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import Media from '@/api/objects/Media';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';

export default defineComponent({
  name: 'ThePreviewPdf',
  components: {
    VPicture,
    TheTravelBookDays,
    TheTravelBookResumeDays,
    VLoader,
    TheTravelBookResumeContact,
    VTravelBookPageHeader,
    VButton,
    VIcon,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    tourId: {
      type: String,
      required: true,
    },
  },
  computed: {
    token(): any {
      return this.$route.query.token;
    },
    locale(): any {
      return this.$route.query.locale;
    },
    summary(): any {
      return !!this.$route.query.summary && this.$route.query.summary === '1' ? 1 : 0;
    },
    domain() {
      return import.meta.env.VITE_APP_API_URL;
    },
  },
  setup() {
    return {
      findConceptByKey,
      ...useOffCanvasUtils(),
    };
  },
  data() {
    return {
      loadingTravelBook: true,
      downloadingPdf: false,
      show404Error: false,
      tour: {} as Tour,
      supplierResources: [] as SupplierResource[],
      tourDays: [] as TourDay[],
      locations: [] as Location[],
      featuredImage: null as Media | null,
      tourTemplate: [] as any,
    };
  },
  async created() {
    if (!!this.token) {
      let tour: Tour | any = {};

      const params = {
        token: this.token,
        locale: this.locale,
        summary: this.summary,
      };

      try {
        this.tour = await api.travelBook.simple(Number(this.caseId), Number(this.tourId), params);

        this.locations = await api.travelBook.locations(Number(this.caseId), Number(this.tourId), params);
        tour = await api.travelBook.dataForPreview(Number(this.caseId), Number(this.tourId), params);

        this.tourTemplate = tour.clientTour;

        this.featuredImage = tour.featuredImage;

        this.tourDays = tour.tourDays;

        this.loadingTravelBook = false;
      } catch (e: any) {
        this.loadingTravelBook = false;
        this.show404Error = true;
      }
    } else {
      this.show404Error = true;
    }
  },
  methods: {
    async downloadPDF() {
      try {
        this.downloadingPdf = true;

        const params = {
          token: this.token,
          locale: this.locale,
          summary: this.summary,
        };

        const response = await axios({
          url: `/api/case/${this.caseId}/tour/${this.tourId}/preview/download`,
          baseURL: this.domain,
          params,
          responseType: 'blob',
          withCredentials: true,
        });

        const contentDisposition = response.headers['content-disposition'];
        let filename = this.tour.name;

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);

          if (filenameMatch.length > 1) {
            filename = filenameMatch[1];
          }
        }

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `travel-book-${filename}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e);

        this.$toast.error(this.$t('travelBook.downloadErrorText'));
      } finally {
        this.downloadingPdf = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}

#generated-travel-book {
  .error-container {
    .title {
      font-size: 5rem;
    }
  }

  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  table {
    width: 100%;
  }

  .header-space {
    position: relative;
    z-index: 1;

    @media print {
      height: 7.375rem;
    }
  }

  .footer-space {
    position: relative;
    z-index: 1;
  }

  @page {
    size: A4;
    margin: 1.5cm;
  }

  @media print {
    thead {
      display: table-header-group;
    }

    tfoot {
      display: table-footer-group;
    }
  }

  .page {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100vw;

    @media print {
      page-break-before: always;
    }

    :deep(.page-inner) {
      padding-inline: 2rem;
      margin: 0 auto;
      width: 100%;

      @media only screen {
        padding-block: 2rem;
      }

      @media print {
        max-width: 58.125rem !important;
        padding-block: 1rem;
      }
    }
  }

  .featured-image {
    :deep(img) {
      height: 100%;
      width: 100%;
      max-height: 18rem;
      object-fit: contain;
    }
  }
}
</style>
