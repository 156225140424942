<template>
  <div class="modal-body">
    <slot/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalBody',
});
</script>
