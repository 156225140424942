export default {
  shared: {
    password: 'Contraseña',
    email: 'Correo electrónico',
  },
  login: {
    logInGoogle: 'Iniciar sesión con Google',
    logInApple: 'Iniciar sesión con Apple',
    logInWithEmail: 'o Iniciar sesión con tu correo electrónico',
    logIn: 'Iniciar sesión',
    forgot: 'He olvidado mi contraseña',
    redirect: 'Redirigiendo...',
    loginData: 'Datos de inicio de sesión',
    userNotAllowed: 'No existe un usuario con ese correo electrónico en la aplicación',
  },
  forgot: {
    recover: 'Restablecer contraseña',
    backLogin: 'Volver a inicio de sesión',
    receiveEmail: 'Recibirás un correo electrónico en esta dirección para restablecer tu contraseña.',
    receiveEmailSpam: 'Si no lo recibes, puede estar en tu carpeta de spam.',
    emailSent: 'Acabamos de reenviarte el correo electrónico, por favor verifica tu carpeta de spam.',
    resend: 'Reenviar',
    email: 'Ingresa tu dirección de correo electrónico',
  },
  reset: {
    addNewPassword: 'Agrega tu nueva contraseña',
    newPassword: 'Nueva contraseña',
    confirmNewPassword: 'Confirma tu nueva contraseña',
    congratulations: '¡Felicidades!',
    successfulRecover: 'Tu contraseña se ha restablecido correctamente. Ahora puedes iniciar sesión de nuevo y usar tus nuevas credenciales.',
    passwords: 'Contraseñas',
  },
};
