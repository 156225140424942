export default {
  tourBuilder: 'Tourbouwer',
  stage: 'Etappe',
  item: 'Item',
  itemDetails: 'Itemdetails',
  paxErrorMessage: 'Pax is een verplicht veld',
  deleteTourDayTitle: 'Dag verwijderen',
  deleteTourDayText: 'Weet u zeker dat u dag {dayNumber} ({date}) wilt verwijderen? Als u deze dag verwijdert, gaat alle voortgang die u op deze dag hebt gemaakt verloren.',
  deleteTourDayItemTitle: 'Dagitem verwijderen',
  deleteTourDayItemText: 'Weet u zeker dat u \'{dayItem}\' wilt verwijderen? Als u dit item verwijdert, gaat alle voortgang die u hebt gemaakt verloren.',
  dayUpdated: 'Dag succesvol bijgewerkt',
  dayUpdateError: 'Er is een fout opgetreden tijdens het bijwerken van de dag',
  dayCreated: 'Dag succesvol aangemaakt',
  dayCreateError: 'Er is een fout opgetreden bij het maken van de dag',
  dayDeleted: 'Dag succesvol verwijderd',
  dayDeleteError: 'Er is een fout opgetreden bij het verwijderen van de dag',
  dayItemCreated: 'Item succesvol aangemaakt',
  dayITemCreateError: 'Er is een fout opgetreden bij het maken van het item',
  dayItemDeleted: 'Item succesvol verwijderd',
  dayItemDeleteError: 'Er is een fout opgetreden bij het verwijderen van het item',
  resources: 'Bronnen',
  reservation: 'Reservering',
  payment: 'Betaling',
  invoice: 'Factuur',
  exportAsProduct: 'Exporteren als activiteit',
  searchTourDayItemResource: 'Zoeken naar bronnen',
  tourDayItemResourceDates: 'Data van bronnen',
  supplierNoOptions: 'Er zijn geen leveranciers',
  writeSearchSuppliers: 'Schrijf om leveranciers te zoeken',
  writeSearchResources: "Schrijf om resources te zoeken",
  price: 'Prijzen',
  payments: 'Betalingen',
  deleteTourDayItemResourceTitle: 'Product verwijderen',
  deleteTourDayItemResourceText: 'Weet u zeker dat u alle producten \'{resource}\' op {date} wilt verwijderen? Als u ze allemaal verwijdert, gaan alle toegevoegde gegevens verloren.',
  booking: 'Boeken',
  bookingStatus: 'Boekingsstatus',
  lengthErrorMessage: 'Schrijf een afstand in kilometers of schrijf nul',
  KilometersShorted: 'Km',
  exportTourAsTemplateTitle: 'Route exporteren als activiteit',
  exportTourAsTemplateText: 'Weet je zeker dat je een activiteit wilt maken op basis van deze casus?',
  editStageInformation: 'Bewerk etappe-informatie',
  stageInformation: 'Etappe-informatie',
  removeMediaTitle: 'Media verwijderen',
  removeMediaText: 'Weet u zeker dat u het archief wilt verwijderen? Als u dit verwijdert, kunt u het niet meer herstellen.',
  advancedOptions: 'Geavanceerde opties',
  addDayTitle: 'Dag toevoegen aan de Tour',
  addDayText: 'Weet je zeker dat je een nieuwe dag wilt toevoegen aan deze Tour? Houd er rekening mee dat bij het toevoegen van een nieuwe dag alle hotels, transfers, etc. die zijn toegevoegd en beïnvloed worden door deze nieuwe dag van datum veranderen, of ze nu bevestigd zijn of niet.',
  hotelRoomTypeUnavailable: 'Hotelroomtype onbeschikbaar',
  roomTypeMissingWarning: 'Verwijder dit en voeg een approprieerd hotelroomtype toe',
  transferNotSelected: 'Vervoer niet geselecteerd',
  transportNotSelected: 'Transport niet geselecteerd',
};
