export default {
  shared: {
    appLogoAlt: "WAW Travel Logo",
    agency: "Agency",
    user: "User",
    client: "Client",
    collaborator: "Collaborator",
    admin: "Admin",
    location: "Location",
    resource: "Resource",
    contact: "Contact",
    supplier: "Supplier",
    case: "Case",
    search: "Search",
    name: "Name",
    surname: "Surname",
    nameAndSurname: "Name and Surname",
    email: "Email",
    password: "Password",
    passwordConfirmation: "Password confirmation",
    filters: "Filters",
    cannotUndo: "This operation cannot be undone.",
    noLocation: "No location",
    writeSearchAgencies: "Write to search for agencies",
    writeSearchUsers: "Write to search for users",
    pagination: "Pagination",
    tableShowNumber: "Show: {number}",
    archived: "Archived",
    active: "Active",
    actions: "Actions",
    orCreateNew: "or create new",
    archive: "Archive",
    restore: "Restore",
    delete: "Delete",
    type: "Type",
    types: "Types",
    created: "Created",
    title: "Title",
    description: "Description",
    writeItManually: "or you can type it manually",
    links: "Links",
    map: "Map",
    uploadMedia: "Upload media",
    source: "Source",
    media: "Media",
    documentType: "Document type",
    documentNumber: "Document number",
    nationality: "Nationality",
    language: "Language",
    genre: "Genre",
    dateBirth: "Date of birth",
    phone: "Phone",
    web: "Web",
    website: "Website",
    note: "Note",
    tag: "Tag",
    countryCode: "Country code",
    filterSearch: "Search: '{value}'",
    filterSource: "Source: '{value}'",
    filterType: "Type: '{value}'",
    filterTypes: "Types: '{value}'",
    filterAmenity: "Amenity: '{value}'",
    filterLocation: "Location: '{value}'",
    filterSurname: "Surname: '{value}'",
    all: "All",
    dateBirthCannotFuture: "Date of birth cannot be in the future",
    basicInformation: "Basic information",
    contactInformation: "Contact information",
    countryCodePlaceholder: "Country (+000)",
    pax: "Pax",
    age: "Age",
    room: "Room",
    dateRange: "Date range",
    initDate: "Init date",
    endDate: "End date",
    writeSearchLocations: "Write to search for locations",
    origin: "Origin",
    destination: "Destination",
    isStage: "Mark Stage Information",
    isGeneral: "Mark General Information",
    dates: "Dates",
    cancellationPolicy: "Cancellation policy",
    notes: "Notes",
    paymentMethod: "Payment method",
    paymentBy: "When to Pay",
    accountNumber: "Account number",
    amenities: "Amenities",
    distanceKm: "Distance (Km)",
    products: "Products",
    checkIn: "Check-in",
    checkOut: "Check-out",
    priceRange: "Price range",
    veryCheap: "Very cheap",
    cheap: "Cheap",
    normal: "Normal",
    expensive: "Expensive",
    category: "Category",
    price: "Price",
    searchByNameOrLocation: "Search by name or location",
    selected: "Selected",
    amount: "Amount",
    invoiceStatus: "Invoice status",
    paymentStatus: "Payment status",
    noEndDate: "No end date",
    moreData: "More data",
    secondSurname: "Second surname",
    selectOriginMessage: "You must search and select an origin city",
    selectDestinationMessage: "You must search and select an destination city",
    accommodation: "Accommodation",
    kilometersShorted: "Km",
    date: "Date",
    size: "Size",
    status: "Status",
    filterStatus: "Status: '{value}'",
    filterInitDate: "Init date since: '{value}'",
    filterEndDate: "End date until: '{value}'",
    filterTravelType: "Travel type: '{value}'",
    filterPaxNumber: "Pax: '{value}'",
    filterClient: "Client: '{value}'",
    filterReservationStatus: "Reservation status: '{value}'",
    filterPaymentsStatus: "Payment status: '{value}'",
    filterInvoicesStatus: "Invoices status: '{value}'",
    filterDestination: "Destination: '{value}'",
    filterOrigin: "Origin: '{value}'",
    filterName: "Name: '{value}'",
    reservationStatus: "Reservation status",
    reservations: "Reservations",
    paymentsStatus: "Payments status",
    payments: "Payments",
    invoicesStatus: "Invoices status",
    invoices: "Invoices",
    travelType: "Travel type",
    information: "Location",
    paxNumber: "Pax: {value}",
    originAndDestination: "Origin and destination",
    general_information: "General",
    stage_information: "Stage",
    point_of_interest: "POI",
    caseManager: "Case manager",
    url: "Url",
    writeSearchProducts: "Write to search for activities",
    number: "Number",
    euroShort: "€",
    product: "Activity",
    totalDays: "Total days",
    sendEmailNotification: "Send email notifications",
    notificationsSentMail: "Notifications are sent to the mail",
    notNotificationsSentMail: "No notifications are sent to the mail",
    googleMaps: "Google Maps",
    viewWebsite: "View website",
    viewGoogleMaps: "View in Google Maps",
    filterManager: "Responsible: '{value}'",
    seenOnlyCasesOnWay: "See only current cases",
    filterOnlyOnWay: "View current cases only",
    filterStartingSoon: "Start of cases up to '{value}' days",
    startingSoon: "Start of cases from today until",
    oneWeek: "One week",
    twoWeeks: "Two weeks",
    oneMonth: "One month",
    daysNumber: "Number of days",
    code: "Code",
    supplier_observations: "Supplier Observations",
    client_observations: "Client Observations",
    payment_link: "Payment link",
    savedChanges: "Saved changes",
    nif: "NIF",
    vat: "VAT",
    preferredLanguage: "Preferred language",
    bookingType: "Type of booking",
    supplierPortal: "Portal",
    promoCode: "Promo code",
    other: "Booking By",
    byEmail: "Booking by Email",
    bookingBy: "Booking by",
    bookingTypeNotFound: "No type of Booking",
    typesOfBooking: "Types of booking",
    bookingOptions: "Booking options",
    bookings: "Bookings",
    persons: "Persons",
    tripManager: "Tripmanager",
    offers: "Offers",
    initHour: "Init hour",
    endHour: "End hour",
    optional: "optional",
    nReserve: "Reservation number",
    optionalQuoteItem: "Optional quote item",
    netValidated: "Net validated",
    paid: "Paid",
    pending: "Pending",
    errorMessage: "An error has occurred",
    files: "Files",
    writeCity: "Write the city",
    canSearchSupplier: "You can search for the supplier (optional)",
    canSearchResource: "You can search for the resource (optional)",
    day: "Day",
    notification: "Notification",
    supplierNotFound: "Deleted supplier",
    markedPay: "Mark To Pay",
    payAmount: "Payable",
    pendingAmount: "Pending",
    paidAmount: "Paid",
    hotelName: "Hotel Name",
    total: "Total",
    generateXml: "Generate XML",
    priceSummary: "Pricing Summary",
    preview: "Payment XML",
    noDataAvailable: "No data available.",
    withoutSupplier: "Without supplier",
    quantityShort: "Qty",
    paymentMethodOpt: "Supplier has opted for",
    supplierAccountNotFound: "No bank account",
    noPaymentMethod: "No Payment Method Selected",
    confirmationOfPricing: "Confirmation of Pricing",
    confirmPriceInfo: "Are you sure that every price is a Net price cost to be paid by WAW.travel?",
    headerTile: "How much is the price per unit? Do you want to offer something different?",
    unit: "unit",
    editPrice: "Edit Price",
    editPriceWarning:
      "To edit the price, please click on the 'Edit Price' button.",
    editFreeTextWarning: "To edit the free text, please click on the 'Modification' button.",
    companyName:"Company name",
    totalCases: "Total cases",
    completedCases: "Completed cases",
    pendingCases: "Pending cases",
    modification: "Modification",
    yearsOld:"years old",
    startDate:"Start date",
    alternativeStartDate:" Alternative start date",
    clientName:"Client Name",
    clientEmail:"Client Email",
    languagePreferedByClient:"Language Prefered By Client",
    clientPhoneNumber:"Client Phone Number",
    supplierList: "Supplier List",
    transfer: "Transfer",
    transport: "Transport",
  },
  button: {
    new: "New",
    close: "Close",
    loading: "Loading...",
    create: "Create",
    save: "Save",
    book: "Book",
    bookNow: "Book Now",
    getAQuote: "Get a Quote",
    saving: "Saving...",
    archive: "Archive",
    archiving: "Archiving...",
    delete: "Delete",
    deleting: "Deleting...",
    remove: "Remove",
    removing: "Removing...",
    confirm: "Confirm",
    modify: "Modify",
    cancel: "Cancel",
    processing: "Processing...",
    add: "Add",
    adding: "Adding...",
    forgiveAccess: "Forgive access",
    forgivingAccess: "Forgiving access...",
    login: "Login",
    loggingIn: "Logging in...",
    restore: "Restore",
    restoring: "Restoring...",
    seeAll: "See all",
    next: "Next",
    update: "Update",
    sendEmail: "Send email",
    send: "Send",
    sending: "Sending...",
    see: "See",
    selectAll: "Select all",
    unselectAll: "Unselect all",
    download: "Download",
    seeFiles: "See files",
    generatingPdf: "Generating PDF...",
    seeDay: "See day",
    view: "View",
    viewMore: "View more",
    viewLess: "View less",
    yes: "Yes",
    no: "No",
    ok: "Ok",
    exportBookings: "Export Bookings",
  },
  toast: {
    successMessage: "Success message",
    informationMessage: "Information message",
    adventMessage: "Advent message",
    errorMessage: "Error message",
    otherTypeTooltipMessage: "Please detail any special requirements or room preferences you have",
    exportTooltip: "Click here to export your bookings to Google sheet",
    showSupplierBookings: "Click here to show supplier bookings",
  },
  menu: {
    impersonateStopText: "Back to Admin",
    impersonateText: "You are logged in to the user { user } ({ email }).",
    cases: "Cases",
    clients: "Clients",
    suppliers: "Suppliers",
    resources: "Resources",
    contacts: "Contacts",
    agencies: "Agencies",
    statistics: "Statistics",
    logout: "Logout",
    myProfile: "My Profile",
    products: "Activities",
  },
  adminMenu: {
    users: "Users",
    agencies: "Agencies",
    collaborators: "Collaborators",
  },
  address: {
    address: "Address",
    addAddress: "Add address",
    addressLine2: "Address line 2",
    billingAddress: "Billing address",
    aptSuit: "Apt, Suite, Unit, etc",
    secondAddress: "Second address",
    city: "City",
    state: "State",
    country: "Country",
    postalCode: "Postal Code",
    provinceStateRegion: "Province / State / Region",
    streetAddress: "Street address",
    number: "Number",
    matchesContactAddress: "Matches contact address",
  },
  screenVerify: {
    title: "We are sorry!",
    message: "The Tour builder is not available in the mobile version.",
    openOnDesktop: "For the best experience, we recommend accessing from a device with a larger screen.",
  },
  vueSelect: {
    noOptions: "Sorry, no matching options.",
  },
  errors: {
    error404: "404",
    pageNotFound: "Opps! Page not found",
    pageNotFoundText: "We can't seem to find the page you're looking for.",
  },
  pages: {
    confirmingReservation: "The reservation is being confirmed...",
    modifingReservation: "The reservation is being modified...",
    reservationConfirmed: "Reservation confirmed!",
    reservationModified: "Reservation modified!",
    confirmEmailStatus: "You will receive the email ASAP.",
    modifiedStatus: "Thank you for the suggestion. We will contact you.",
    emailConfirmationSucess: "Thank you...",
    emailAlreadyUpdated: "This email already updated by the supplier. if you have any query, please contact us.",
  },
};
