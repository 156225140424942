<template>
  <v-modal
    ref="modal"
    :backdrop-static="!!modal.backdrop ? true : null"
    :is-double-modal="modal.isDoubleModal"
    @closed="dispatchHideModal">
    <modal-header :disable-close="modal.showCloseX">
      <modal-title class="h5">
        {{ modal.title }}
      </modal-title>
    </modal-header>

    <modal-body>
      <component :is="modal.textLayout" v-if="!!modal.textLayout">
        <!-- If layout has a slot, it is filled with the text; if not,
        component just appears and text is ignored -->
        {{ modal.text }}
        <p class="fw-semi text-black mb-0 mt-24" v-if="!!modal.subtitle">{{ modal.subtitle }}</p>
      </component>

      <template v-else>
        {{ modal.text }}
      </template>
    </modal-body>

    <modal-footer v-if="!!modal.leftButtonText || !!modal.rightButtonText">
      <template v-if="modal.switchButtons">
        <v-button
          v-if="!!modal.rightButtonText"
          :class="modal.rightButtonClasses"
          :disabled="modal.rightButtonDisabled === true ? true : null"
          size="xs"
          @click="rightButtonClicked">
          <slot name="accept-button">
            <template v-if="rightButtonIsLoading">
              {{ modal.rightButtonLoadingText }}
            </template>

            <template v-else>
              {{ modal.rightButtonText }}
            </template>
          </slot>
        </v-button>

        <v-button
          v-if="!!modal.leftButtonText"
          :class="modal.leftButtonClasses"
          :disabled="modal.leftButtonDisabled === true ? true : null"
          size="xs"
          @click="leftButtonClicked">
          <slot name="close-button">
            <template v-if="leftButtonIsLoading">
              {{ modal.leftButtonLoadingText }}
            </template>

            <template v-else>
              {{ modal.leftButtonText }}
            </template>
          </slot>
        </v-button>
      </template>

      <template v-else>
        <v-button
          v-if="!!modal.leftButtonText"
          :class="modal.leftButtonClasses"
          :disabled="modal.leftButtonDisabled === true ? true : null"
          size="xs"
          @click="leftButtonClicked">
          <slot name="close-button">
            <template v-if="leftButtonIsLoading">
              {{ modal.leftButtonLoadingText }}
            </template>

            <template v-else>
              {{ modal.leftButtonText }}
            </template>
          </slot>
        </v-button>

        <v-button
          v-if="!!modal.rightButtonText"
          :class="modal.rightButtonClasses"
          :disabled="modal.rightButtonDisabled === true ? true : null"
          size="xs"
          @click="rightButtonClicked">
          <slot name="accept-button">
            <template v-if="rightButtonIsLoading">
              {{ modal.rightButtonLoadingText }}
            </template>

            <template v-else>
              {{ modal.rightButtonText }}
            </template>
          </slot>
        </v-button>
      </template>
    </modal-footer>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useGeneralModalsStore } from '@/stores/genenalModals';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/basic/modal/ModalFooter.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

// TODO: I should control 'show_x' close event and click outside event to manage the close_callback

export default defineComponent({
  name: 'GeneralModal',
  components: {
    VButton,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  setup() {
    const generalModalsStore = useGeneralModalsStore();

    return {
      generalModalsStore,
    };
  },
  computed: {
    ...mapState(useGeneralModalsStore, ['modal']),
  },
  data() {
    return {
      leftButtonIsLoading: false,
      rightButtonIsLoading: false,
    };
  },
  methods: {
    async dispatchHideModal() {
      this.generalModalsStore.hideModal();
    },
    async rightButtonClicked() {
      await this.manageCallback(false);
    },
    async leftButtonClicked() {
      await this.manageCallback(true);
    },
    async manageCallback(isLeft: any) {
      let close = true;

      // We disable button
      if (isLeft === true) {
        this.leftButtonIsLoading = true;
        this.generalModalsStore.disableModalLeftButton();
      } else {
        this.rightButtonIsLoading = true;
        this.generalModalsStore.disableModalRightButton();
      }

      // Manage each callback if existing
      if ((!!this.modal.leftButtonCallback && isLeft === true)
        || (!!this.modal.rightButtonCallback && isLeft === false)) {
        let response = null;

        // TODO: Maybe force all buttons to be disabled and backdropStatic to be true

        // Here we execute the callback
        if (isLeft === true) {
          response = await this.modal.leftButtonCallback();
        } else {
          response = await this.modal.rightButtonCallback();
        }

        /**
         * If it returns an .error item, it won't be closed and,
         * an error will be toasted and button will be re-enabled
         */
        if (!!response && !!response.error) {
          close = false;

          if (response.error !== true) {
            this.$toast.error(response.error);
          }

          // TODO Resolve left & right button loading text not working
          if (isLeft === true) {
            this.leftButtonIsLoading = false;
            this.generalModalsStore.enableModalLeftButton();
          } else {
            this.rightButtonIsLoading = false;
            this.generalModalsStore.enableModalRightButton();
          }
        }
      }

      // If everything ok => close
      if (close) {
        (this.$refs.modal as any).hide();
      }
    },
  },
});
</script>
