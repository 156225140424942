<template>
  <v-modal
    id="travel-book-location-resources"
    class-name="modal-fullscreen"
    size="xl"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('travelBook.guidebookOfLocation', { location: location }) }}
      </modal-title>
    </modal-header>

    <modal-body>
      <div class="location-resources-filters mb-24">
        <v-form
          id="resource-filter-types-select"
          :label="$t('general.shared.types')"
          form-type="outline"
        >
          <v-select
            v-model="filterTypes"
            :clearable="false"
            :options="resourceTypesOptions"
            :reduce="returnValue => returnValue.key"
            multiple
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
            </template>
          </v-select>
        </v-form>
      </div>

      <div class="location-resources-container">
        <div v-if="!loaded" class="position-relative py-96">
          <v-loader/>
        </div>

        <template v-else>
          <template v-if="!!resources && resources.length > 0">
            <div v-for="(resource, index) in resources" :key="resource.id" class="location-resource mb-16">
              <div class="information-container row gx-0 break-avoid">
                <div
                  :class="[{
                             'col-lg-6': !!resource.media && resource.media.length > 0,
                           },
                           index % 2 === 0 ? 'left order-1' : 'right order-2']"
                  class="col-12">
                  <div class="data-container p-24 p-lg-32">
                    <div
                      class="fw-semi d-flex align-items-center mb-16"
                    >
                      <v-icon icon="send-love" size="xl" space="me-12"/>

                      <div v-if="!!resource.title">
                        {{ resource.title }}
                      </div>
                    </div>

                    <div class="d-flex flex-column justify-content-between h-100">
                      <div v-if="resource.description" class="message">
                        <div v-html="resource.description"/>
                      </div>

                      <div v-if="!!resource.phone" class="contact-data d-flex text-sm mb-4">
                        <div class="contact-data-title fw-medium me-4">{{ $t('general.shared.phone') }}:</div>
                        <div class="contact-data-value">
                          {{ resource.phone }}
                        </div>
                      </div>

                      <div class="links-container d-flex flex-column flex-sm-row align-items-sm-center">
                        <div v-if="!!resource.mapUrl" class="text-sm d-flex mb-4">
                          <a
                            :aria-label="$t('travelBook.linkGoogleMapsResource', { resource: resource.title })"
                            :href="resource.mapUrl"
                            class="resource-link fw-medium btn-link p-0"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {{ $t('travelBook.viewGoogleMaps') }}
                          </a>
                        </div>

                        <span v-if="!!resource.mapUrl && !!resource.siteUrl" class="dot-divider d-none d-sm-block"/>

                        <div v-if="!!resource.siteUrl" class="text-sm d-flex mb-4">
                          <a
                            :aria-label="$t('travelBook.linkResourceWebsite', { resource: resource.title })"
                            :href="resource.siteUrl"
                            class="resource-link fw-medium btn-link p-0"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {{ $t('travelBook.viewWebsite') }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  :class="[{
                             'col-lg-6': !!resource.media && resource.media.length > 0,
                             'd-none': !resource.media || resource.media.length === 0,
                           },
                           index % 2 === 0 ? 'right order-2' : 'left order-1']"
                  class="col-12"
                >
                  <div class="w-100 h-100">
                    <swiper
                      :loop="true"
                      :modules="modules"
                      :navigation="true"
                      :pagination="{
                        type: 'fraction',
                      }"
                      class="mySwiper hide-print"
                    >
                      <swiper-slide v-for="image in resource.media" :key="image.id">
                        <v-picture class="image-container">
                          <img
                            :alt="image.name"
                            :src="image.storageUrl"
                          />
                        </v-picture>
                      </swiper-slide>
                    </swiper>

                    <div v-if="!!resource.media && resource.media.length > 0" class="only-print">
                      <v-picture class="image-container">
                        <img
                          :alt="resource.media[0].name"
                          :src="resource.media[0].storageUrl"
                        />
                      </v-picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div v-else>
            {{ $t('travelBook.noResourcesLocation') }}
          </div>
        </template>
      </div>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { defineComponent } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
import vSelect from 'vue-select';
import { VForm } from '@uniqoders/form';
import useConcepts from '@/helpers/Concepts';
import Resource from '@/api/objects/Resource';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import { mapConcepts } from '@/helpers/ConceptHelper';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import api from '@/api';

export default defineComponent({
  name: 'TheTravelBookLocationResourcesModal',
  components: {
    VLoader,
    vSelect,
    Swiper,
    VPicture,
    SwiperSlide,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VForm,
  },
  emits: [
    'closed',
  ],
  props: {
    locationId: {
      type: Number,
      required: false,
    },
    location: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    return {
      ...useConcepts(),
      modules: [Pagination, Navigation],
    };
  },
  data() {
    return {
      resources: [] as Resource[],
      filterTypes: [] as string[],
      loaded: false as boolean,
    };
  },
  computed: {
    resourceTypesOptions(): any {
      if (!!this.concepts && this.concepts['resource.types']) {
        return mapConcepts(this.concepts['resource.types']);
      }

      return [];
    },
  },
  watch: {
    filterTypes() {
      this.loadLocationResources();
    },
  },
  created() {
    this.loadLocationResources();
  },
  methods: {
    async loadLocationResources() {
      try {
        this.loaded = false;

        const params = {
          filters: {
            location_id: this.locationId,
            type: 'point_of_interest',
            types: this.filterTypes,
          },
        };

        this.resources = await api.travelBook.resources(params);

        this.loaded = true;
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.break-avoid {
  break-inside: avoid;
}

.hide-print {
  @media print {
    display: none;
  }
}

.only-print {
  @media only screen {
    display: none;
  }
}

.information-container {
  border-radius: .5rem;
  box-shadow: 0 1px .25rem var(--uq-gray-300);
  background-color: var(--uq-white);

  .message {
    @media print {
      font-size: .875rem;
    }
  }

  .left {
    .image-container {
      :deep(img) {
        border-radius: .5rem .5rem 0 0;

        @media (min-width: 992px) {
          border-top-right-radius: 0;
          border-bottom-left-radius: .5rem;
        }
      }
    }
  }

  .right {
    .image-container {
      :deep(img) {
        border-radius: 0 0 .5rem .5rem;

        @media (min-width: 992px) {
          border-top-right-radius: .5rem;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    :deep(img) {
      height: 20rem;

      @media (min-width: 992px) {
        height: 30rem;
      }
    }
  }

  .data-container-info {
    .contact-container {
      position: relative;
      padding-top: 1rem;
      margin-top: 1rem;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: var(--uq-gray-400);
      }
    }

    .contact-data {
      display: grid;
      grid-template-columns: 10% 80%;

      .contact-data-title {
        font-size: .875rem;
      }

      .contact-data-value {
        font-size: .875rem;
      }
    }
  }
}
</style>
