export default {
  contact: 'Contacto',
  contacts: 'Contactos',
  noContacts: 'No hay contactos',
  createNewContact: 'Crear nuevo contacto',
  archivedContacts: 'Contactos archivados',
  activeContacts: 'Contactos activos',
  basicInformation: 'Información básica',
  contactDetails: 'Detalles del contacto',
  archiveText: 'Puedes archivar un contacto, si lo haces no aparecerá en las búsquedas de la aplicación. Podrás verlo en la lista de contactos si filtras por \'archivado\'. Una vez que veas los detalles de un contacto archivado, puedes eliminarlo definitivamente o desarchivarlo.',
  media: 'Multimedia',
  noMedia: 'No hay multimedia asociada',
  archivedText: 'Este contacto está archivado, si lo deseas puedes desarchivarlo haciendo clic en el botón \'Restaurar\'.',
  archiveContactTitle: 'Archivar contacto',
  archiveContactText: '¿Estás seguro de que quieres archivar el contacto \'{contact}\'? Si lo archivas, no aparecerá en las búsquedas.',
  deleteText: 'Este contacto está archivado, si quieres eliminarlo permanentemente haz clic en el botón \'Eliminar\'. Ten en cuenta que si lo eliminas, este contacto dejará de existir y no podrá ser recuperado.',
  deleteContactTitle: 'Eliminar contacto',
  deleteContactText: '¿Estás seguro de que quieres eliminar el contacto \'{contact}\'? Si lo eliminas, no podrás recuperarlo.',
  restoreContactTitle: 'Restaurar contacto',
  restoreContactText: '¿Estás seguro de que quieres recuperar el contacto \'{contact}\'? Si lo recuperas, ya no estará archivado y aparecerá en las búsquedas de la aplicación.',
  contactsStatus: 'Estado de contactos',
};
