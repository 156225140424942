import { AbstractService } from '@uniqoders/sdk';
import TourDay from '@/api/objects/TourDay';

export default class TemplateDayService extends AbstractService {
  /**
   * Retrieves a specific Tour Day
   * @param agency
   * @param template
   * @param tourDay
   * @protected
   */
  public retrieve(
    agency: string,
    template: number,
    tourDay: number): Promise<TourDay> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/template/{template}/day/{tourDay}',
        {
          agency,
          template,
          tourDay,
        }),
    });
  }

  /**
   * Creates a single Tour Day
   * @protected
   * @param agency
   * @param template
   * @param data
   */
  public create(
    agency: string,
    template: number,
    data: Record<string, any>): Promise<TourDay> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/template/{template}/day', {
        agency,
        template,
      }),
      data,
    });
  }

  /**
   * Updates the specified Tour Day
   * @param agency
   * @param template
   * @param tourDay
   * @param data
   * @protected
   */
  public update(
    agency: string,
    template: string | number,
    tourDay: string | number,
    data: Record<string, any>): Promise<TourDay> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/template/{template}/day/{tourDay}', {
        agency,
        template,
        tourDay,
      }),
      data,
    });
  }

  /**
   * Deletes the specified Tour Day
   * @protected
   * @param agency
   * @param template
   * @param tourDay
   */
  public delete(agency: string, template: number, tourDay: number): Promise<TourDay> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/template/{template}/day/{tourDay}', {
        agency,
        template,
        tourDay,
      }),
    });
  }
}
