import { Duration, Settings, DateTime } from 'luxon';
import { App, Plugin } from 'vue';
import DateHelper from './date/DateHelper';

const userBrowserTimezone = 'Europe/Madrid';

/**
 * Our awesome class to provide texts formatting.
 *
 * Just call to the different format methods to make it beauty and more readable and international.
 *
 * Could be interesting to implement formatjs if it makes us easier to beautify texts.
 * @link https://formatjs.io/docs/vue-intl/
 * @link https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 * @link https://moment.github.io/luxon/demo/global.html
 * @link https://moment.github.io/luxon/#/parsing
 */
class Str {
  numberFormats = {
    PERCENTAGE: 'percentage',
    NUMBER: 'number',
    CURRENCY: 'currency',
  };

  protected locale: string;

  constructor(options: { locale: string }) {
    this.locale = options.locale || 'en';

    Settings.defaultLocale = options.locale || 'en';
  }

  /**
   * Receives a date as input, parses it with the provided date_format
   * and returns it formatted as the output_format says.
   *
   * @param date
   * @param format
   * @link https://moment.github.io/luxon/#/parsing?id=iso-8601
   * @link https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   * @returns {string}
   */
  formatDate = (date: any, format = 'dd/MM/yyyy') => DateHelper.parse(date)
    .toFormat(format);

  /**
   * Receives a date as input, parses it with the provided date_format and returns
   * it formatted as the output_format says.
   *
   * @param date
   * @param format
   * @param fromUTC
   * @link https://moment.github.io/luxon/#/parsing?id=iso-8601
   * @link https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   * @returns {string}
   */
    // formatDateTime = (date: any, format = 'dd/MM/yyyy HH:mm') => DateHelper.parse(date)
    //   .toFormat(format);

  formatDateTime = (date: any, format = 'dd/MM/yyyy HH:mm', fromUTC = true) => {
    if(!date) return;
    const dateToParse = DateHelper.parse(date);

    if (fromUTC) {
      return dateToParse.setZone(userBrowserTimezone)
        .toFormat(format);
    }
    return dateToParse.toFormat(format);
  };

  formatDateTimeRelativeToNow = (date: any) => {

    return DateTime.fromISO(date).toRelative();
  };

  /**
   * Receives a number and formats it to the available formats.
   *
   * @param num
   * @param format
   * @param locale
   * @param currency
   * @param decimals
   * @param prefix
   * @param suffix
   * @returns {string|*}
   */
  formatNumber(num: any, format: any, {
    locale,
    currency,
    decimals,
    prefix,
    suffix,
  }: any = {}) {
    const tmpLocale: string = locale || this.locale;

    if (format === this.numberFormats.PERCENTAGE) {
      return this.numberToPercent(parseFloat(num), tmpLocale, prefix, suffix);
    }
    if (format === this.numberFormats.NUMBER) {
      return this.numberToNumber(parseFloat(num), tmpLocale, decimals, prefix, suffix);
    }
    if (format === this.numberFormats.CURRENCY) {
      return this.numberToCurrency(parseFloat(num), tmpLocale, currency, prefix, suffix);
    }

    return num;
  }

  /**
   * Returns the provided amount of seconds as a minutes and seconds counter.
   * <ul><li>2m 30sec</li></ul>
   *
   * @param seconds
   * @param minutesUnit
   * @param secondsUnit
   * @returns {string}
   */
  minutesAndSeconds = (seconds: any, minutesUnit: any, secondsUnit: any) => {
    // TODO: Move this functionality to UQDate.js
    const time: Duration = Duration.fromObject({ seconds: parseInt(seconds, 10) });

    return time.toFormat(`mm'${minutesUnit || 'm'}' ss'${secondsUnit || 's'}`);
  };

  /**
   * Returns the provided amount of hours, minutes and seconds individually.
   * <ul><li>1h 2m 30sec</li></ul>
   *
   * @param seconds
   * @returns {string}
   */
  parseSeconds = (seconds: string) => {
    // TODO: Move this functionality to UQDate.js
    const time = Duration.fromObject({ seconds: parseInt(seconds, 10) });

    const x = time.toFormat('h@m@s')
      .split('@');

    return {
      hours: parseInt(x[0], 10),
      minutes: parseInt(x[1], 10),
      seconds: parseInt(x[2], 10),
    };
  };

  /**
   * Formats the received number as a percent. Number must be between 0 and 100.
   * <ul><li>20.45%</li></ul>
   *
   * @param num
   * @param locale
   * @param prefix
   * @param suffix
   * @returns {string|*}
   */
  numberToPercent = (num: any, locale: any, prefix = '', suffix = '') => {
    if (num < 0 || num > 100) {
      // TODO Mejorar procesos de errores
      return num;
    }

    return (prefix || '') + (num / 100).toLocaleString(locale, {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + (suffix || '');
  };

  /**
   * Formats the received number as thousand number with a dot as separator.
   * <ul><li>20.45%</li></ul>
   *
   * @param num
   * @returns {string|*}
   */
  formatNumberToThousand = (num: any) => {
    if (typeof num === 'number') {
      num = num.toString();
    }

    return num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  /**
   * Formats the received number with the received locale.
   *
   * @param tmpNum
   * @param locale
   * @param decimals
   * @param prefix
   * @param suffix
   * @returns {string}
   */
  numberToNumber = (num: any, locale: any, decimals = 2, prefix = '', suffix = '') => {
    let tmpNum = num.toLocaleString();

    if (decimals) {
      tmpNum = parseFloat(tmpNum)
        .toFixed(decimals);
    }

    return (prefix || '') + tmpNum + (suffix || '');
  };

  /**
   * Formats the received number with the received locale and currency.
   *
   * @param num
   * @param locale
   * @param currency
   * @param prefix
   * @param suffix
   * @returns {string}
   */
  public numberToCurrency(num: any, locale: any, currency: any, prefix = '', suffix = '') {
    return (prefix || '')
      + num.toLocaleString(locale, {
        style: 'currency',
        currency,
      })
      + (suffix || '');
  }

  public milesToKilometers = (miles: number): number => miles * 1.6;

  public roundedToFixed(float: number, digits: number) {
    const rounder = 10 ** digits;
    return (Math.round(float * rounder) / rounder).toFixed(digits);
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $str: Str;
  }
}

const createFormatter: Plugin = {
  install(app: App, options: { locale: string }) {
    app.config.globalProperties.$str = new Str(options);
  },
};

export default createFormatter;
