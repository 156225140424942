<template>
  <form
    id="user-agency-create-form"
    class="d-flex flex-column justify-content-between h-100"
  >
    <fieldset>
      <legend class="visually-hidden">{{ $t('admin.agency.basicInformation') }}</legend>

      <v-form-input
        id="admin-user-agency-create-name"
        v-model="name"
        :label="`${$t('general.shared.name')}*`"
        :yup-errors-variable="errors.name"
        autocomplete="off"
        class="mb-8"
        form-type="outline"
      />

      <v-form-input
        id="admin-user-agency-create-nif"
        v-model="nif"
        :label="`${$t('general.shared.nif')}`"
        :yup-errors-variable="errors.nif"
        autocomplete="off"
        class="mb-8"
        form-type="outline"
      />

      <v-input-address
        id="admin-user-agency-create-location"
        ref="admin-user-agency-create-location-ref"
        :get-address="true"
        :get-radius-bounds="true"
        :label="$t('general.shared.location')"
        :options="{
          types: ['(cities)'],
          fields: ['place_id', 'name', 'vicinity', 'formatted_address', 'geometry', 'address_component'],
        }"
        :radius-bounds="20000"
        class="mb-16"
        @address="setLocation($event)"
      />
    </fieldset>

    <v-contact-billing-form
      id="admin-user-create-agency"
      ref="admin-user-agency-create-address-form-ref"
      :data="addressData"
      id-prefix="admin-user-create-agency"
      @updated-data="addressData = $event"
      @is-valid="addressValid = $event"
    />

    <v-button
      :disabled="!meta.valid || accepted"
      :is-loading="accepted"
      class="btn-submit-form btn-icon w-fit align-self-end"
      size="sm"
      variant="primary"
      @click="createAgency"
    >
      {{ $t('general.button.create') }}
      <v-icon icon="arrow-right" size="sm" space="ms-12"/>
    </v-button>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { VFormInput } from '@uniqoders/form';
import { mapState } from 'pinia';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';
import Agency from '@/api/objects/Agency';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'TheAdminUserCreateAgencyAddress',
  components: {
    VContactBillingForm,
    VInputAddress,
    VIcon,
    VButton,
    VFormInput,
  },
  emits: ['agencyCreated'],
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const authStore = useAuthStore();
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      nif: yup.string()
        .nullable()
        .label(t('general.shared.nif')),
      location: yup.string()
        .nullable()
        .label(t('general.shared.location')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: nif } = useField('nif');
    const { value: location } = useField('location');

    return {
      authStore,
      ...form,
      name,
      nif,
      location,
    };
  },
  data() {
    return {
      addressData: {} as any,
      addressValid: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
  },
  methods: {
    async createAgency() {
      try {
        this.toggleAccepted();

        const data: any = {
          name: this.name,
          nif: this.nif,
          location_id: this.addressData.locationId,
          contact_address_1: this.addressData.contactAddress1,
          contact_address_2: this.addressData.contactAddress2,
          contact_postal_code: this.addressData.contactPostalCode,
          contact_country: this.addressData.contactCountry,
          contact_city: this.addressData.contactCity,
          contact_state: this.addressData.contactState,
          billing_address_1: this.addressData.billingAddress1,
          billing_address_2: this.addressData.billingAddress2,
          billing_postal_code: this.addressData.billingPostalCode,
          billing_country: this.addressData.billingCountry,
          billing_city: this.addressData.billingCity,
          billing_state: this.addressData.billingState,
          location: this.location,
        };

        const agency: Agency = await api.admin.agency.create(data);

        await api.admin.agency.attachUser(agency.id, this.userId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        if (this.userId === this.user?.id) {
          await this.authStore.setAuthUser();
        }

        this.resetForm();

        (this.$refs['admin-user-agency-create-address-form-ref'] as any).clearForm();

        this.$emit('agencyCreated');

        this.toggleAccepted();
      } catch (e: any) {
        this.toggleAccepted();

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
    async setLocation(address: Record<string, any>) {
      this.location = address.locality.longName;
    },
  },
});
</script>
