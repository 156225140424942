<template>
  <div id="accordion-tour-day">
    <div id="page-terms">
      <div class="page-inner container">
        <h3 class="h5 mb-12">{{ $t('clientTour.termsConditions') }}</h3>
        <div class="terms-container bg-white w-100 p-28">
          <template v-for="(language, index) in tour.clientTour.translations" :key="language.shortName">
          <div
            v-if="currentLanguage === language.locale"
            class="message d-flex justify-content-center flex-column pb-32"
          >
            <div
              :id="`collapseText-${index}`"
              class="collapse collapsable-text"
              :class="{ show: language?.description?.length <= 500 }"
            >
              <div v-html="language?.description?.trim().replace(/\s+/g, ' ')" />
            </div>

            <v-button
              v-if="language?.description?.length > 500"
              ref="collapseButton"
              :aria-controls="`collapseText-${index}`"
              :data-bs-target="`#collapseText-${index}`"
              aria-expanded="false"
              class="btn-sm mx-auto"
              data-bs-toggle="collapse"
              type="button"
              variant="primary"
              @click="toggleCollapsedText"
            >
              {{ isLargeTextCollapsed ? $t("general.button.viewMore") : $t("general.button.viewLess") }}
            </v-button>
          </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Tour from '@/api/objects/Tour';
import { mapConcepts } from "@/helpers/ConceptHelper";
import VButton from "@/components/vendor/basic/button/VButton.vue";

export default defineComponent({
  name: 'TheTravelBookTerms',
  props: {
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
  },
  components: {
    VButton,
  },
  data() {
    return {
      currentLanguage: localStorage.getItem("language"),
      isLargeTextCollapsed: true,
    }
  },
  computed: {
    languages(): any {
      if (!!this.concepts && this.concepts["app.languages"]) {
        return mapConcepts(this.concepts["app.languages"]);
      }

      return [];
    },
  },
  methods: {
    toggleCollapsedText() {
      this.isLargeTextCollapsed = !this.isLargeTextCollapsed;
    },
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}
.message {
  @media print {
    font-size: 0.875rem;
  }

  .collapsable-text.collapsing {
    height: 300px;
  }

  .collapsable-text {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4rem;
      opacity: 0;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 100%);
      transition: opacity 0.1s ease-out;
    }

    &.collapsing {
      &:after {
        opacity: 1;
      }
    }

    &.collapse:not(.show) {
      display: block !important;
      height: 300px;
      max-height: 300px;
      overflow: hidden;
      position: relative;

      &:after {
        opacity: 1;
      }
    }
  }

  .btn {
    width: fit-content;
  }
}
</style>

