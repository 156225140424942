<template>
  <aside v-if="!!currentLanguage && !!languages" id="left-sidebar">
    <div class="nav-container">
      <nav class="nav-actions vertical">
        <ul>
          <li class="language-selector position-relative">
            <a
              class="btn btn-primary rounded-pill text-decoration-none p-4 my-4 my-md-8 me-8 me-md-12"
              href="#">
              <span class="text text-uppercase">
                {{ currentLanguage }}
              </span>
            </a>

            <ul class="dropdown">
              <template v-for="(language, index) in languages" :key="index">
                <li v-if="language.key !== currentLanguage">
                  <a
                    class="btn btn-primary rounded-pill text-decoration-none p-4 m-4 m-md-8"
                    href="#"
                    @click="currentLanguage = language.key">
                    <span class="text">{{ language.key }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';

export default defineComponent({
  name: 'TheLanguageSwitcher',
  setup() {
    return {
      ...useConcepts(),
    };
  },
  data() {
    return {
      locale: import.meta.env.VITE_APP_I18N_LOCALE,
      currentLanguage: localStorage.getItem('language'),
    };
  },
  watch: {
    currentLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!!this.$root) {
          localStorage.setItem('language', newValue);

          this.$root.$i18n.locale = newValue;

          const params = new URLSearchParams(window.location.search);
          const localeParam = params.get('locale') || '';

          if (!!localeParam) {
            params.set('locale', newValue);

            window.location.href = `${window.location.pathname}?${params.toString()}`;
          } else {
            window.location.reload();
          }
        }
      }
    },
  },
  computed: {
    languages(): Record<string, any>[] {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
  },
  mounted() {
    const language = localStorage.getItem('language');

    if (!!this.concepts && this.concepts['app.languages']) {
      let languageAux = '';

      const languages: any = mapConcepts(this.concepts['app.languages']);

      const foundLocalLanguage = languages.find((languageAux: any) => languageAux.key === language);

      const foundDefaultLanguage = languages.find((languageAux: any) => languageAux.key === this.locale);

      const params = new URLSearchParams(window.location.search);
      const localeParam = params.get('locale') || '';
      const foundParamLanguage = languages.find((languageAux: any) => languageAux.key === localeParam);

      if (!!foundParamLanguage) {
        languageAux = localeParam;
      } else if (!!language) {
        languageAux = language;
      } else if (!!foundLocalLanguage) {
        languageAux = foundLocalLanguage.key;
      } else if (!!foundDefaultLanguage) {
        languageAux = foundDefaultLanguage.key;
      } else {
        languageAux = import.meta.env.VITE_APP_DEFAULT_LOCALE;
      }

      localStorage.setItem('language', languageAux);

      this.currentLanguage = languageAux;

      this.$i18n.locale = languageAux;
    }
  },
});
</script>

<style lang="scss" scoped>
#left-sidebar {
  left: .5rem;
  bottom: .5rem;
  display: flex;
  flex-direction: column;
  height: max-content;
  justify-content: center;
  position: fixed;
  transition: transform 1s;
  width: 8.5rem;
  z-index: 1025;
  top: unset;

  @media print {
    display: none;
  }

  .nav-actions ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .language-selector {
    &:hover {
      .dropdown {
        opacity: 1;
        width: auto;
      }
    }

    .text {
      height: 2rem;
      width: 2rem;
      align-items: center;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      text-align: center;
      transition: all .25s;
      visibility: visible;
      font-size: .75rem;
      font-weight: 800;
      letter-spacing: .0625rem;
      text-transform: uppercase;
    }

    .dropdown {
      display: flex;
      flex-direction: row;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(100%);
      transition: all .25s;
      width: 0;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
