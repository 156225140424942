<template>
  <v-off-canvas id="template-create-offcanvas" :backdrop-static="false"
    :offcanvas-class="['offcanvas-end', { 'double-offcanvas': isDoubleOffCanvas }]" @closed="$emit('closed')">
    <resizable-container>
      <off-canvas-header>
        <h5 class="offcanvas-title">{{ $t('clientTour.createNewProduct') }}</h5>
      </off-canvas-header>

      <off-canvas-body>
        <form id="template-create-form" class="d-flex flex-column justify-content-between h-100">
          <div>
            <fieldset>
              <legend class="visually-hidden">{{ $t('clientTour.basicInformation') }}</legend>

              <div class="search-separator-container">
                <div class="separator-text-container">
                  <span class="separator-text text-sm">{{ $t('clientTour.basicInformation') }}</span>
                </div>
              </div>

              <v-form-input id="template-create-name" v-model="name" :label="$t('general.shared.name')"
                :yup-errors-variable="errors.name" autocomplete="off" form-type="outline" />

              <v-form-input id="template-create-total-days" v-model="totalDays" :label="$t('general.shared.totalDays')"
                :yup-errors-variable="errors.totalDays" autocomplete="off" form-type="outline" type="number" />

            </fieldset>
            <div class="search-separator-container pt-24">
              <div class="separator-text-container">
                <span class="separator-text text-sm">{{ $t('clientTour.howWillYourPath') }}</span>
              </div>
            </div>

            <fieldset>
              <legend class="visually-hidden">{{ $t('clientTour.howWillYourPath') }}</legend>

              <v-form id="template-create-travel-type-select" :label="$t('clientTour.howToDoIt')" form-type="outline">
                <v-select v-model="travelType" :clearable="false" :options="travelTypes"
                  :reduce="returnValue => returnValue.key" class="mb-8">
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </v-form>

              <v-input-address id="template-create-origin-search" ref="template-create-origin-search-ref"
                :get-address="true" :get-radius-bounds="true" :label="$t('general.shared.origin')"
                :radius-bounds="20000" class="col-12" @address="createLocation($event, true)" />

              <v-input-address id="template-create-destination-search" ref="template-create-destination-search-ref"
                :get-address="true" :get-radius-bounds="true" :label="$t('general.shared.destination')"
                :radius-bounds="20000" class="col-12" @address="createLocation($event, false)" />
            </fieldset>
          </div>

          <v-button :disabled="!meta.valid || disableButton" :is-loading="accepted"
            class="btn-icon w-fit align-self-end mt-24" size="sm" variant="primary" @click="createTemplate">
            {{ $t('general.button.create') }}
            <v-icon icon="arrow-right" size="sm" space="ms-12" />
          </v-button>
        </form>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VForm, VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import { mapState } from 'pinia';
import vSelect from 'vue-select';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { useAuthStore } from '@/stores/auth';
import api from '@/api';
import ClientTour from '@/api/objects/ClientTour';
import Template from '@/api/objects/Template';
import { mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import Location from '@/api/objects/Location';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue'

export default defineComponent({
  name: 'TheTemplateCreateOffcanvas',
  components: {
    VInputAddress,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VFormInput,
    vSelect,
    VForm,
    ResizableContainer
  },
  emits: [
    'closed',
    'reloadTemplates',
  ],
  props: {
    templateData: {
      type: Template,
      required: false,
      default: null,
    },
    clientTourData: {
      type: ClientTour,
      required: false,
      default: null,
    },
    isDoubleOffCanvas: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      totalDays: yup.number()
        .required()
        .min(1)
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.daysNumber')),
      travelType: yup.string()
        .required()
        .label(t('general.shared.client')),
    });

    const initialValues = {
      totalDays: 1,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: name } = useField<string>('name');
    const { value: totalDays } = useField<number>('totalDays');
    const { value: travelType } = useField<string>('travelType');

    return {
      ...useConcepts(),
      ...form,
      name,
      totalDays,
      travelType,
    };
  },
  data() {
    return {
      disableButton: false as boolean,
      origin: null as null | number,
      destination: null as null | number,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    travelTypes(): any {
      if (!!this.concepts && this.concepts['tour.travel_type']) {
        return mapConcepts(this.concepts['tour.travel_type']);
      }

      return [];
    },
  },
  methods: {
    async createLocation(address: Record<string, any>, isOrigin = true) {
      const location: Location = await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });

      if (isOrigin) {
        this.origin = location.id;
      } else {
        this.destination = location.id;
      }
    },
    async createTemplate() {
      try {
        this.toggleAccepted();
        this.disableButton = true;

        const data: any = {
          name: this.name,
          total_days: this.totalDays,
          travel_type: this.travelType,
          origin: this.origin,
          destination: this.destination,
        };

        if (!!this.agency) {
          const response = await api.template.createEmpty(this.agency.slug, data);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.$emit('reloadTemplates');

          await this.$router.replace({
            name: 'template.show',
            params: { templateId: response.id },
          });
        }
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableButton = false;

        this.toggleAccepted();
      }
    },
  },
});
</script>
