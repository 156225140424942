<template>
  <v-off-canvas
    id="resource-details-offcanvas"
    :backdrop-static="false"
    offcanvas-class="item-details offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
        <div class="d-flex align-items-center">
          <v-icon class="text-primary" icon="info" size="xxl" space="me-8" />

          <h5 id="resource-details-offcanvas-label" class="offcanvas-title">
            <template v-if="!isInformation">{{ $t("resource.resourceDetails") }}</template>
            <template v-else>{{ $t("resource.informationDetails") }}</template>
          </h5>
        </div>
      </off-canvas-header>

      <off-canvas-body class="justify-content-between d-flex flex-column">
        <v-loader v-if="status === 'loading'" />

        <div v-else class="item-details">
          <div class="detail">
            <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
              <div class="fw-medium">{{ $t("resource.basicInformation") }}</div>

              <v-button
                v-if="!resource.deletedAt"
                class="btn-edit p-4"
                variant="icon"
                @click="editBasicInformation = !editBasicInformation"
              >
                <v-icon icon="pencil" />
              </v-button>
            </div>

            <div class="detail-content">
              <div class="d-flex justify-content-end text-xs mb-8">
                <span class="fw-medium me-4">{{ $t("general.shared.created") }}:</span>
                {{ $str.formatDateTime(resource.createdAt, "dd MMM yyyy") }}
              </div>

              <Transition mode="out-in" name="fade-in">
                <div v-if="!editBasicInformation">
                  <div class="text-sm d-flex py-4">
                    <span class="info-title fw-medium">{{ $t("general.shared.title") }}: </span>
                    <span v-if="!!resource.title">{{ resource.title }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="text-sm d-flex py-4">
                    <span class="info-title fw-medium">{{ $t("general.shared.location") }}: </span>
                    <span v-if="!!resource.location && !!resource.location.name">
                      <template v-if="isStage">
                        {{ resource.location.name }}
                        <v-icon v-if="!!resource.destination" icon="arrow-right" size="xs" space="px-8" />
                        {{ resource.destination }}
                      </template>
                      <template v-else>
                        {{ resource.location.name }}
                      </template>
                    </span>
                    <span v-else>-</span>
                  </div>
                  <template v-if="!isGeneral">
                    <div class="text-sm d-flex py-4">
                      <span class="info-title fw-medium">{{ $t("general.shared.types") }}: </span>
                      <span v-if="!!allTypes">{{ allTypes }}</span>
                      <span v-else>-</span>
                    </div>
                  </template>

                  <div class="text-sm d-flex py-4">
                    <span class="info-title fw-medium">{{ $t("general.shared.distanceKm") }}: </span>
                    <span v-if="resource?.stageLength">{{ resource?.stageLength }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="text-sm d-flex py-4">
                    <span class="info-title fw-medium">{{ $t("general.shared.description") }}: </span>
                    <span v-if="!!resource.description" v-html="resource.description" />
                    <span v-else>-</span>
                  </div>

                  <template v-if="!isStage && !isGeneral">
                    <div v-if="!isInformation" class="text-sm d-flex py-4">
                      <span class="info-title fw-medium">{{ $t("general.shared.phone") }}: </span>
                      <span v-if="!!resource.phone">{{ resource.phone }}</span>
                      <span v-else>-</span>
                    </div>

                    <div v-if="!!resource.mapUrl" class="text-sm d-flex py-4">
                      <a
                        :aria-label="$t('general.shared.viewGoogleMaps')"
                        :href="resource.mapUrl"
                        class="resource-link btn-link"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {{ $t("general.shared.viewGoogleMaps") }}
                      </a>
                    </div>

                    <div v-else class="text-sm d-flex py-4">
                      <span class="info-title fw-medium">{{ $t("general.shared.googleMaps") }}: </span>
                      <span>-</span>
                    </div>

                    <div v-if="!!resource.siteUrl" class="text-sm d-flex py-4">
                      <a
                        :aria-label="$t('general.shared.viewWebsite')"
                        :href="resource.siteUrl"
                        class="resource-link btn-link"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {{ $t("general.shared.viewWebsite") }}
                      </a>
                    </div>

                    <div v-else class="text-sm d-flex py-4">
                      <span class="info-title fw-medium">{{ $t("general.shared.website") }}: </span>
                      <span>-</span>
                    </div>

                    <template v-if="!isInformation">
                      <p class="mb-4 mt-16 text-sm fw-bold">{{ $t("general.address.address") }}</p>

                      <div class="text-sm d-flex py-4">
                        <span class="info-title fw-medium">{{ $t("general.address.streetAddress") }}: </span>
                        <span>{{ resource.address1 }}</span>
                      </div>

                      <div class="text-sm d-flex py-4">
                        <span class="info-title fw-medium">{{ $t("general.address.aptSuit") }}: </span>
                        <span>{{ resource.address2 }}</span>
                      </div>

                      <div class="text-sm d-flex py-4">
                        <span class="info-title fw-medium">{{ $t("general.address.postalCode") }}: </span>
                        <span>{{ resource.postalCode }}</span>
                      </div>

                      <div class="text-sm d-flex py-4">
                        <span class="info-title fw-medium">{{ $t("general.address.country") }}: </span>
                        <span>{{ resource.country }}</span>
                      </div>

                      <div class="text-sm d-flex py-4">
                        <span class="info-title fw-medium">{{ $t("general.address.city") }}: </span>
                        <span>{{ resource.city }}</span>
                      </div>

                      <div class="text-sm d-flex py-4">
                        <span class="info-title fw-medium">{{ $t("general.address.provinceStateRegion") }}: </span>
                        <span>{{ resource.state }}</span>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div v-if="resource.links" class="text-sm d-flex flex-column py-4">
                      <span class="info-title fw-medium">Links: </span>
                      <template v-for="url in inputFields" :key="url">
                        <a :href="url.link" class="resource-link btn-link" rel="noopener noreferrer" target="_blank">
                          {{ url.link }}
                        </a>
                      </template>
                    </div>
                  </template>
                </div>

                <form v-else id="resource-update" class="d-flex flex-column justify-content-between h-100">
                  <div class="fieldset-translatable">
                    <nav class="translations-tab">
                      <div id="nav-tab" class="nav nav-tabs" role="tablist">
                        <v-button
                          v-for="(language, index) in languages"
                          :id="`nav-supplier-create-basic-information-${language.key}`"
                          :key="index"
                          :aria-controls="`supplier-create-basic-information-${language.key}`"
                          :class="{ active: language.key === currentLanguage }"
                          :data-bs-target="`#supplier-create-basic-information-${language.key}`"
                          aria-selected="true"
                          class="nav-tab-link py-8 px-16 me-8"
                          data-bs-toggle="tab"
                          role="tab"
                          size="sm"
                          variant="outline-primary"
                          @click="currentLanguage = language.key"
                        >
                          {{ language.label }}
                        </v-button>
                      </div>
                    </nav>

                    <transition mode="out-in" name="fade" appear>
                      <div id="resource-update-basic-information-tabs" class="tab-content">
                        <v-checkbox
                          v-if="isStage"
                          id="is-stage"
                          v-model="isStage"
                          :label="`${$t('general.shared.isStage')}`"
                          class="text-nowrap mt-8"
                          isDisabled
                        />
                        <v-checkbox
                          v-if="isGeneral"
                          id="is-general"
                          v-model="isGeneral"
                          :label="`${$t('general.shared.isGeneral')}`"
                          class="text-nowrap mt-8"
                          isDisabled
                        />
                        <div
                          v-for="(language, index) in languages"
                          :id="`resource-update-basic-information-${language.key}`"
                          :key="index"
                          :aria-labelledby="`nav-resource-update-basic-information-${language.key}`"
                          :class="{ 'active show': language.key === currentLanguage }"
                          class="tab-pane fade"
                          role="tabpanel"
                        >
                          <the-resource-create-basic-information-fieldset
                            v-model:description="translations[language.key].description"
                            v-model:title="translations[language.key].title"
                            v-model:types="types"
                            v-model:is-stage="isStage"
                            v-model:is-general="isGeneral"
                            v-model:distance="stageLength"
                            :destination="destination"
                            :city="city"
                            update-section="updateResource"
                            :clear="clear"
                            :current-language="currentLanguage"
                            :is-information="isInformation"
                            :language="language.key"
                            @is-valid="
                              isTranslationsValid.find((translation) => translation.locale === language.key).valid =
                                $event
                            "
                            @media-uploaded="editorMedia.push($event)"
                          />
                        </div>
                      </div>
                    </transition>
                  </div>
                  <template v-if="!isStage && !isGeneral">
                    <fieldset v-if="!isInformation">
                      <legend class="visually-hidden">{{ $t("general.shared.basicInformation") }}</legend>
                      <v-form-input
                        id="resource-update-phone"
                        v-model="phone"
                        :label="$t('general.shared.phone')"
                        :yup-errors-variable="errors.phone"
                        autocomplete="off"
                        form-type="outline"
                      />
                    </fieldset>
                  </template>

                  <div class="search-separator-container mb-8 pb-24">
                    <div class="separator-text-container">
                      <span class="separator-text text-sm">{{ $t("general.shared.links") }}</span>
                    </div>
                  </div>

                  <fieldset v-if="!isStage && !isGeneral">
                    <legend class="visually-hidden">{{ $t("general.shared.links") }}</legend>

                    <v-form-input
                      id="resource-create-map"
                      v-model="mapUrl"
                      :label="$t('general.shared.map')"
                      :yup-errors-variable="errors.mapUrl"
                      autocomplete="off"
                      form-type="outline"
                    />

                    <v-form-input
                      id="resource-create-website"
                      v-model="website"
                      :label="$t('general.shared.website')"
                      :yup-errors-variable="errors.website"
                      autocomplete="off"
                      form-type="outline"
                    />
                  </fieldset>
                  <fieldset v-else>
                    <legend class="visually-hidden">{{ $t("general.shared.links") }}</legend>

                    <div v-for="(input, index) in inputFields" :key="index">
                      <v-form-input
                        :id="'resource-create-new-url-' + index"
                        v-model="input.link"
                        label="Link"
                        autocomplete="off"
                        form-type="outline"
                        @input="updateLink(index)"
                      />
                      <div class="w-100 d-flex justify-content-start" v-if="index === inputFields.length - 1">
                        <v-button
                          class="btn-icon p-1 rounded-circle mt-8"
                          size="sm"
                          variant="gradient"
                          @click="addInputField"
                        >
                          <v-icon icon="plus" />
                        </v-button>
                      </div>
                    </div>
                  </fieldset>
                  <template v-if="!isStage && !isGeneral">
                    <div v-if="!isInformation" class="search-separator-container mb-8 pb-24">
                      <div class="separator-text-container">
                        <span class="separator-text text-sm">{{ $t("general.address.address") }}</span>
                      </div>
                    </div>

                    <fieldset v-if="!isInformation">
                      <legend class="visually-hidden">{{ $t("general.address.address") }}</legend>

                      <div class="row gx-12">
                        <v-input-address
                          id="resource-update-address-search"
                          ref="resource-update-address-search-ref"
                          :form-floating="false"
                          :get-address="true"
                          :label="$t('general.address.streetAddress')"
                          :value="address"
                          class="col-12"
                          @address="setContactAddress"
                        />

                        <v-form-input
                          id="update-resource-second-address"
                          v-model="addressTwo"
                          :label="$t('general.address.aptSuit')"
                          :yup-errors-variable="errors.addressTwo"
                          autocomplete="off"
                          class="col-12"
                          form-type="outline"
                        />

                        <v-form-input
                          id="resource-update-country"
                          v-model="countryLabel"
                          :label="$t('general.address.country')"
                          :yup-errors-variable="errors.countryLabel"
                          autocomplete="off"
                          class="col-12"
                          form-type="outline"
                        />

                        <v-form-input
                          id="resource-update-postal-code"
                          v-model="postalCode"
                          :label="$t('general.address.postalCode')"
                          :yup-errors-variable="errors.postalCode"
                          autocomplete="off"
                          class="col-12"
                          form-type="outline"
                        />

                        <v-form-input
                          id="resource-update-city"
                          v-model="city"
                          :label="$t('general.address.city')"
                          :yup-errors-variable="errors.city"
                          autocomplete="off"
                          class="col-12"
                          form-type="outline"
                        />

                        <v-form-input
                          id="resource-update-region"
                          v-model="region"
                          :label="$t('general.address.provinceStateRegion')"
                          :yup-errors-variable="errors.region"
                          autocomplete="off"
                          class="col-12"
                          form-type="outline"
                        />
                      </div>
                    </fieldset>
                  </template>

                  <v-button
                    :disabled="
                      !meta.valid || accepted || !isTranslationsValid.every((translation) => translation.valid)
                    "
                    :is-loading="accepted"
                    class="btn-submit-form btn-icon w-fit align-self-end mt-16"
                    size="sm"
                    variant="primary"
                    @click="saveResource"
                  >
                    {{ $t("general.button.save") }}
                    <v-icon icon="arrow-right" size="sm" space="ms-12" />
                  </v-button>
                </form>
              </Transition>
            </div>
          </div>

          <the-resource-details-media v-if="!resource.deletedAt" :resource="resource" @reload-resource="loadResource" />

          <div class="detail pb-0 mb-0">
            <div class="detail-title fw-medium mb-20">
              <template v-if="!resource.deletedAt">{{ $t("general.shared.archive") }}</template>
              <template v-else>{{ $t("general.shared.restore") }}</template>
            </div>

            <div class="detail-content">
              <p v-if="!resource.deletedAt" class="text-sm">{{ $t("resource.archiveText") }}</p>
              <p v-else class="text-sm">{{ $t("resource.archivedText") }}</p>

              <v-button
                v-if="!resource.deletedAt"
                id="btn-archive-resource"
                :disabled="disableArchiveBtn"
                class="btn-icon mb-8"
                size="sm"
                variant="danger"
                @click="archiveResource"
              >
                <v-icon icon="import" space="me-12" />
                <template v-if="disableArchiveBtn">{{ $t("general.button.archiving") }}</template>
                <template v-else>{{ $t("general.button.archive") }}</template>
              </v-button>

              <v-button
                v-else
                id="btn-restore-resource"
                :disabled="disableRestoreBtn"
                class="btn-icon mb-8"
                size="sm"
                variant="primary"
                @click="restoreResource"
              >
                <v-icon icon="unarchive" space="me-12" />
                <template v-if="disableRestoreBtn">{{ $t("general.button.restoring") }}</template>
                <template v-else>{{ $t("general.button.restore") }}</template>
              </v-button>
            </div>
          </div>

          <div v-if="!!resource.deletedAt" class="detail">
            <div class="detail-title fw-medium mb-20">
              {{ $t("general.shared.delete") }}
            </div>

            <div class="detail-content">
              <p class="text-sm">{{ $t("resource.deleteText") }}</p>

              <v-button
                id="btn-delete-resource"
                :disabled="disableDeleteBtn"
                class="btn-icon mb-8"
                size="sm"
                variant="danger"
                @click="deleteResource"
              >
                <v-icon icon="trash" space="me-12" />
                <template v-if="disableDeleteBtn">{{ $t("general.button.deleting") }}</template>
                <template v-else>{{ $t("general.button.delete") }}</template>
              </v-button>
            </div>
          </div>
        </div>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as yup from "yup";
import { useField } from "vee-validate";
import { VFormInput, VCheckbox } from "@uniqoders/form";
import { useI18n } from "vue-i18n";
import VOffCanvas from "@/components/vendor/basic/offcanvas/VOffCanvas.vue";
import OffCanvasHeader from "@/components/vendor/basic/offcanvas/OffCanvasHeader.vue";
import OffCanvasBody from "@/components/vendor/basic/offcanvas/OffCanvasBody.vue";
import useFormValidation from "@/helpers/form";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import api from "@/api";
import useModalUtils from "@/helpers/ModalUtils";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import useConcepts from "@/helpers/Concepts";
import { findConceptByKey, mapConcepts } from "@/helpers/ConceptHelper";
import TheResourceDetailsMedia from "@/components/resource/parts/TheResourceDetailsMedia.vue";
import Resource from "@/api/objects/Resource";
import VLoader from "@/components/vendor/loader/VLoader.vue";
import VInputAddress from "@/components/vendor/basic/form/VInputAddress.vue";
import TheResourceCreateBasicInformationFieldset from "@/components/resource/parts/TheResourceCreateBasicInformationFieldset.vue";
import ResizableContainer from "@/components/resizablecontainer/ResizableContainer.vue";

export default defineComponent({
  name: "TheResourceDetailsOffcanvas",
  components: {
    TheResourceCreateBasicInformationFieldset,
    VInputAddress,
    VLoader,
    TheResourceDetailsMedia,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VFormInput,
    ResizableContainer,
    VCheckbox,
  },
  emits: ["closed", "reloadResources"],
  props: {
    resourceData: {
      type: Resource,
      required: true,
    },
    tourBuilder: {
      type: String,
      defaulr: "",
    },
    isArchived: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      address: yup.string().nullable().label(t("general.address.streetAddress")),
      addressTwo: yup.string().nullable().label(t("general.address.aptSuit")),
      city: yup.string().nullable().label(t("general.address.city")),
      countryLabel: yup.string().nullable().label(t("general.address.country")),
      mapUrl: yup.string().nullable().label(t("general.shared.map")),
      postalCode: yup.string().nullable().label(t("general.address.postalCode")),
      region: yup.string().nullable().label(t("general.address.provinceStateRegion")),
      website: yup.string().nullable().label(t("general.shared.website")),
      phone: yup.string().nullable().label(t("general.shared.phone")),
    });

    const form = useFormValidation(rules);

    const { value: address } = useField("address");
    const { value: addressTwo } = useField("addressTwo");
    const { value: city } = useField("city");
    const { value: countryLabel } = useField("countryLabel");
    const { value: mapUrl } = useField("mapUrl");
    const { value: postalCode } = useField("postalCode");
    const { value: region } = useField("region");
    const { value: website } = useField("website");
    const { value: phone } = useField("phone");

    return {
      findConceptByKey,
      ...useConcepts(),
      ...useModalUtils(),
      ...form,
      address,
      addressTwo,
      city,
      countryLabel,
      mapUrl,
      postalCode,
      region,
      website,
      phone,
    };
  },
  data() {
    return {
      allTypes: "" as string,
      clear: false,
      currentLanguage: "",
      disableArchiveBtn: false as boolean,
      disableDeleteBtn: false as boolean,
      disableDenyForgiveBtn: false as boolean,
      disableRestoreBtn: false as boolean,
      editBasicInformation: false as boolean,
      isTranslationsValid: [] as Record<string, any>[],
      lat: "" as string | number,
      lng: "" as string | number,
      locationId: null as null | number,
      resource: {} as Resource,
      status: "loading" as string,
      translations: {} as Record<string, any>,
      types: [] as [],
      isInformation: false as boolean,
      isStage: false as boolean,
      isGeneral: false as boolean,
      destination: "" as string,
      dLat: null as null | number,
      dLng: null as null | number,
      destinationId: 0 as number,
      inputFields: [{ link: "" }] as { link: string }[],
      stageLength: null as null | number,
      editorMedia: [] as any[],
    };
  },
  computed: {
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : "en";
    },
    languages(): any {
      if (!!this.concepts && this.concepts["app.languages"]) {
        return mapConcepts(this.concepts["app.languages"]);
      }

      return [];
    },
    resourceTypes(): any {
      if (!!this.concepts && this.concepts["resource.types"]) {
        return mapConcepts(this.concepts["resource.types"]);
      }

      return [];
    },
  },
  watch: {
    async resourceData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.isArchived) {
          await this.loadResource();
        } else {
          this.resource = this.resourceData;

          this.isInformation = !(this.resource.type === "information");

          this.status = "loaded";
        }
      }
    },
  },
  async created() {
    if (!this.isArchived) {
      await this.loadResource();

      this.currentLanguage = this.defaultLocale;
    } else {
      this.resource = this.resourceData;

      this.status = "loaded";
    }
  },
  methods: {
    addInputField() {
      this.inputFields.push({ link: "" });
    },
    getLinksArray() {
      return this.inputFields?.map((input) => input.link);
    },
    updateLink(index: number) {
      const currentLink = this.inputFields[index].link;

      if (currentLink !== "") {
        return;
      }
    },
    async archiveResource() {
      await this.$modal.delete({
        title: this.$t("resource.archiveResourceTitle"),
        text: this.$t("resource.archiveResourceText", { resource: this.resource?.title }),
        deleteButtonText: this.$t("general.button.archive"),
        deleteButtonLoadingText: this.$t("general.button.archiving"),
        rightButtonClasses: "btn-tertiary",
        deleteButtonCallback: this.doArchiveResource,
      });
    },
    async deleteResource() {
      await this.$modal.delete({
        title: this.$t("resource.deleteResourceTitle"),
        text: this.$t("resource.deleteResourceText", { resource: this.resource?.title }),
        deleteButtonText: this.$t("general.button.delete"),
        rightButtonClasses: "btn-tertiary",
        deleteButtonCallback: this.doDeleteResource,
      });
    },
    async doArchiveResource() {
      try {
        this.disableArchiveBtn = true;

        await api.resource.archive(this.resource.id);

        this.$toast.success(this.$t("general.shared.savedChanges"));

        this.$emit("reloadResources");
        this.$emit("closed");

        this.disableArchiveBtn = false;
      } catch (e: any) {
        this.disableArchiveBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async doDeleteResource() {
      try {
        this.disableDeleteBtn = true;

        await api.resource.delete(this.resource.id);

        this.$toast.success(this.$t("general.shared.savedChanges"));

        this.$emit("reloadResources");
        this.$emit("closed");

        this.disableDeleteBtn = false;
      } catch (e: any) {
        this.disableDeleteBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async doRestoreResource() {
      try {
        this.disableRestoreBtn = true;

        await api.resource.restore(this.resource.id);

        this.$toast.success(this.$t("general.shared.savedChanges"));

        this.$emit("reloadResources");
        this.$emit("closed");

        this.disableRestoreBtn = false;
      } catch (e: any) {
        this.disableRestoreBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async loadResource() {
      this.status = "loading";

      this.resource = await api.resource.retrieve(this.resourceData.id);      

      this.stageLength = this.resource.stageLength;

      this.isStage = this.resource.type === "stage_information";

      this.isGeneral = this.resource.type === "general_information";

      this.isInformation = !(this.resource.type === "information");

      await this.mapTranslations();

      await this.setData();
      if (this.tourBuilder === "tourBuilder" && this.tourBuilder !== undefined) {
        this.editBasicInformation = true;
      } else {
        this.editBasicInformation = false;
      }
      this.status = "loaded";
    },
    mapTranslations() {
      this.isTranslationsValid = [];

      this.languages.forEach((language: Record<string, any>) => {
        this.isTranslationsValid.push({
          locale: language.key,
          valid: false,
        });

        this.translations[language.key] = {
          title: "",
          description: "",
        };
      });
    },
    async restoreResource() {
      await this.$modal.confirm({
        title: this.$t("resource.restoreResourceTitle"),
        text: this.$t("resource.restoreResourceText", { resource: this.resource?.title }),
        leftButtonClasses: "btn-tertiary",
        confirmButtonText: this.$t("general.button.confirm"),
        confirmButtonCallback: this.doRestoreResource,
      });
    },
    async saveResource() {
      if (!!this.resource) {
        try {
          this.toggleAccepted();

          const data: any = {
            types: this.types,
            type: this.resource.type,
            map_url: this.mapUrl,
            address_1: this.address,
            address_2: this.addressTwo,
            postal_code: this.postalCode,
            country: this.countryLabel,
            city: this.city,
            state: this.region,
            lat: this.lat,
            lng: this.lng,
            location_id: this.locationId,
            translations: this.translations,
            site_url: this.website,
            phone: this.phone,
            destination: this.destination,
            d_lat: this.dLat?.toString(),
            d_lng: this.dLng?.toString(),
            destination_id: this.destinationId,
            links: this.isStage || this.isGeneral ? this.getLinksArray() : null,
            stage_length: this.stageLength,
          };

          const response = await api.resource.update(this.resource?.id, data);

          if (!!this.editorMedia && this.editorMedia.length > 0) {
          const editorMedia = {
            media: this.editorMedia,
            model_type: 'resource',
            collection: 'tinymce',
            model_id: response.id,
          };

          await api.media.confirm('s3', editorMedia);
        }

          this.$toast.success(this.$t("general.shared.savedChanges"));

          this.toggleAccepted();

          await this.loadResource();

          this.$emit("reloadResources");
        } catch (e: any) {
          this.toggleAccepted();

          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    async setContactAddress(address: any) {
      this.address = address.name;
      this.countryLabel = address.country.longName;
      this.city = address.locality.longName;
      this.region = address.regionProvince.longName;
      this.postalCode = address.postalCode.longName;
      this.lat = address.lat;
      this.lng = address.lng;
    },
    setData() {
      this.isStage = this.resource.type === "stage_information" ? true : false;
      this.isGeneral = this.resource.type === "general_information" ? true : false;
      // this.destinationId= this.resource.destination_id;
      this.destination = this.resource.destination;
      this.types = JSON.parse(this.resource.types);
      const inputLinks = JSON.parse(this.resource?.links);
      this.inputFields = !!inputLinks ? inputLinks?.map((link: string) => ({ link })) : [{ link: "" }];      
      this.stageLength = this.resource.stageLength;
      this.mapUrl = this.resource.mapUrl;
      this.address = this.resource.address1;
      this.addressTwo = this.resource.address2;
      this.city = this.resource.city;
      this.countryLabel = this.resource.country;
      this.region = this.resource.state;
      this.postalCode = this.resource.postalCode;
      this.website = this.resource.siteUrl;
      this.phone = this.resource.phone;

      this.locationId = this.resource.locationId;
      this.lat = this.resource.lat;
      this.lng = this.resource.lng;

      this.resource.translations.forEach((translation: any) => {
        this.translations[translation.locale].title = translation.title;
        this.translations[translation.locale].description = translation.description;

        const translationValid = this.isTranslationsValid.find(
          (translations) => translations.locale === translation.locale
        );

        if (!!translationValid) {
          translationValid.valid = !!translation.title;
        }
      });

      if (!!this.types && this.types.length > 0) {
        let isFirstType = true;

        this.types.forEach((type: string) => {
          const typeObject = this.resourceTypes.find((resourceType: any) => resourceType.key === type);

          if (!!typeObject) {
            const typeLabel = typeObject.label;

            if (isFirstType) {
              this.allTypes = typeLabel;

              isFirstType = false;
            } else {
              this.allTypes = `${this.allTypes}, ${typeLabel}`;
            }
          }
        });
      }
    },
  },
});
</script>

<style lang="scss">
.detail-content {
  .type:not(:last-child) {
    &:after {
      content: ",\00a0";
    }
  }

  .resource-link {
    overflow-wrap: anywhere;
  }
}
</style>
