import { ref } from 'vue';

const useModalUtils = () => {
  const modalToShow = ref('');

  const closeModal = () => {
    modalToShow.value = '';
  };

  const openModal = (modal: string) => {
    modalToShow.value = modal;
  };

  return {
    modalToShow,
    closeModal,
    openModal,
  };
};

export default useModalUtils;
