<template>
  <div id="templates-list" class="container item-list pb-64">
    <v-list-header
      :title="this.$t('template.title')"
      icon="compass"
      @open-filters-offcanvas="openOffCanvas('the-template-filters-offcanvas')"
      @emit-close-off-canvas="closeOffCanvas"
      @reload-client-tours="loadTemplates"
    >
      <template #input-search>
        <v-input-text-search
          id="templates-search"
          v-model="name"
          :placeholder="this.$t('general.shared.search')"
          autocomplete="off"
          class="w-100 me-24"
          input-class="h-auto py-8"
          input-group-text-class="py-8 px-16"
          type="text"
          @update:modelValue="name = $event; search()"
        />
      </template>
    </v-list-header>

    <div class="items-content">
      <div class="filters-container mb-24">
        <v-filters
          v-if="!!filterOptions"
          v-model="filterOptions"
          @remove-filter="removeFilter"
        />
      </div>

      <div class="table-responsive d-none d-md-flex">
        <v-table>
          <v-table-header>
            <tr>
              <th>
                <v-button
                  :class="{ 'sort-active': order.key === 'name' }"
                  class="btn-order text-md fw-light shadow-none p-0 pb-4"
                  variant="icon"
                  @click="toggleOrder('name')"
                >
                  {{ $t('general.shared.name') }}
                  <v-icon
                    :class="{ 'sort-icon-transition': order.key === 'name' && order.order === 'DESC' }"
                    class="ms-8"
                    icon="chevron-down"
                    size="xs"/>
                </v-button>
              </th>

              <th>
                <v-button
                  :class="{ 'sort-active': order.key === 'travel_type' }"
                  class="btn-order text-md fw-light shadow-none p-0 pb-4 text-nowrap"
                  variant="icon"
                  @click="toggleOrder('travel_type')"
                >
                  {{ $t('template.travelType') }}
                  <v-icon
                    :class="{ 'sort-icon-transition': order.key === 'travel_type' && order.order === 'DESC' }"
                    class="ms-8"
                    icon="chevron-down"
                    size="xs"/>
                </v-button>
              </th>

              <th>{{ $t('general.shared.origin') }}</th>
              <th>{{ $t('general.shared.destination') }}</th>
              <th class="fit"/>
            </tr>
          </v-table-header>

          <v-table-body class="fw-medium">
            <tr v-if="!loaded">
              <td class="position-relative border-0 py-96" colspan="2222">
                <v-loader/>
              </td>
            </tr>

            <template v-else-if="!!templates && templates.length > 0">
              <tr v-for="template in templates" :key="template.id">
                <td class="d-flex">
                  <div class="image-container rounded-sm bg-tertiary me-12">
                    {{ template.name?.charAt(0) }}
                  </div>

                  <router-link
                    v-if="!agency?.isCollaborator && !template.deletedAt"
                    :aria-label="template.name"
                    :to="{
                      name: 'template.show',
                      params: {
                        templateId: template.id,
                      },
                    }"
                    class="py-12"
                  >
                    {{ template.name }} <span class="d-none d-md-inline" v-if="template.isCollaborator"> ({{ $t('general.shared.collaborator') }})</span>
                  </router-link>

                  <a
                    v-else
                    :aria-label="template.name"
                    class="py-12"
                    href="#"
                    @click.prevent="setCurrentTemplate(template)"
                  >
                    {{ template.name }}
                  </a>
                </td>

                <td>{{ findConceptByKey('tour.travel_type', template.travelType).name }}</td>
                <td>{{ template.origin?.name }}</td>
                <td>{{ template.destination?.name }}</td>

                <td class="fit text-end">
                  <router-link
                    v-if="!agency?.isCollaborator && !template.deletedAt"
                    :aria-label="this.$t('template.show')"
                    :to="{
                      name: 'template.show',
                      params: {
                        templateId: template.id,
                      },
                    }"
                    class="btn btn-icon btn-view rounded-pill bg-secondary"
                  >
                    <v-icon icon="arrow-right"/>
                  </router-link>

                  <v-button
                    v-else
                    class="btn-view rounded-pill bg-secondary"
                    size="xs"
                    variant="icon"
                    @click="setCurrentTemplate(template)"
                  >
                    <v-icon icon="arrow-right"/>
                  </v-button>
                </td>
              </tr>
            </template>

            <template v-else>
              <tr>
                <td class="rounded-xs py-16" colspan="2222">
                  {{ $t('template.noProducts') }}
                </td>
              </tr>
            </template>
          </v-table-body>
        </v-table>
      </div>

      <div class="item-cards d-block d-md-none">
        <div v-if="!loaded" class="position-relative py-96">
          <v-loader/>
        </div>

        <template v-else-if="!!templates && templates.length > 0">
          <div v-for="template in templates" :key="template.id" class="item-card border rounded-xs p-8 mb-12 text-sm">
            <div class="d-flex">
              <div class="image-container rounded-sm bg-tertiary me-12">
                {{ template.name?.charAt(0) }}
              </div>

              <div class="w-100">
                <router-link
                  :disabled="agency?.isCollaborator"
                  v-if="!template.deletedAt"
                  :aria-label="template.name"
                  :to="{
                    name: 'template.show',
                    params: {
                      templateId: template.id,
                    },
                  }"
                  class="fw-medium"
                >
                  {{ template.name }}
                </router-link>

                <div class="text-xs fw-light mt-4">
                  <template v-if="!!template.origin || !template.destination">
                    {{ !!template.origin ? template.origin.name : '-' }}

                    <v-icon icon="arrow-right" size="xs" space="px-8"/>

                    {{ !!template.destination ? template.destination.name : '-' }}
                  </template>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between mt-12 align-items-center">
              <div class="fw-light text-xs">
                {{ findConceptByKey('tour.travel_type', template.travelType).name }}
              </div>

              <router-link
                v-if="!template.deletedAt"
                :aria-label="this.$t('template.show')"
                :to="{
                  name: 'template.show',
                  params: {
                    templateId: template.id,
                  },
                }"
                class="btn btn-icon btn-view rounded-pill bg-secondary"
              >
                <v-icon icon="arrow-right" size="xs"/>
              </router-link>

              <v-button
                v-else
                class="btn-view rounded-pill bg-secondary"
                size="xs"
                variant="icon"
                @click="setCurrentTemplate(template)"
              >
                <v-icon icon="arrow-right"/>
              </v-button>
            </div>
          </div>
        </template>

        <div v-else>
          {{ $t('template.noProducts') }}
        </div>
      </div>

      <v-pagination
        v-if="!!templates && templates.length > 0"
        :meta="pagination.meta"
        :pagination-links="pagination.links"
        class="mt-32"
        @page-changed="changePage($event); handlePageChangedEvent($event)"
        @per-page-changed="perPage = $event; doSearch()"
      />
    </div>

    <the-template-filters-offcanvas
      v-if="offCanvasToShow === 'the-template-filters-offcanvas'"
      v-model:destination="destination"
      v-model:location="location"
      v-model:origin="origin"
      v-model:status="status"
      v-model:travelType="travelType"
      @closed="closeOffCanvas"
      @selected="doSearch"
    />

    <the-template-archived-offcanvas
      v-if="offCanvasToShow === 'the-template-archived-offcanvas'"
      :template="currentTemplate"
      @closed="closeOffCanvas(); cleanCurrentTemplate()"
      @reload-templates="loadTemplates"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VInputTextSearch } from '@uniqoders/form';
import qs from 'qs';
import querystring from 'query-string';
import { mapState } from 'pinia';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import api from '@/api';
import withTimeout from '@/helpers/timeOut';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import VListHeader from '@/components/shared/VListHeader.vue';
import VPagination from '@/components/vendor/basic/table/VPagination.vue';
import usePagination from '@/helpers/pagination';
import VFilters from '@/components/vendor/filters/VFilters.vue';
import { useAuthStore } from '@/stores/auth';
import Template from '@/api/objects/Template';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import TheTemplateArchivedOffcanvas from '@/components/template/TheTemplateArchivedOffcanvas.vue';
import TheTemplateFiltersOffcanvas from '@/components/template/TheTemplateFiltersOffcanvas.vue';

export default defineComponent({
  name: 'TheTemplates',
  components: {
    TheTemplateFiltersOffcanvas,
    TheTemplateArchivedOffcanvas,
    VFilters,
    VPagination,
    VListHeader,
    VLoader,
    VTableBody,
    VTableHeader,
    VTable,
    VButton,
    VInputTextSearch,
    VIcon,
  },
  setup() {
    return {
      findConceptByKey,
      ...useOffCanvasUtils(),
      ...usePagination(),
    };
  },
  data() {
    return {
      templates: [] as Template[],
      status: 'active' as string,
      name: '' as string,
      destination: '' as string,
      location: '' as string,
      origin: '' as string,
      travelType: '' as string,
      filters: [
        {
          variable: 'status',
          key: 'status',
          labelAux: 'general.shared.filterStatus',
          conceptName: 'app.states',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'name',
          key: 'name',
          labelAux: 'general.shared.filterSearch',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'destination',
          key: 'destination',
          labelAux: 'general.shared.filterDestination',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'location',
          key: 'location',
          labelAux: 'general.shared.filterLocation',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'origin',
          key: 'origin',
          labelAux: 'general.shared.filterOrigin',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'travelType',
          key: 'travel_type',
          labelAux: 'general.shared.filterTravelType',
          conceptName: 'tour.travel_type',
          multiple: false,
          isConcept: true,
        },
      ] as Record<string, string | boolean>[],
      order: {
        key: '' as string,
        order: '' as string,
      } as Record<string, string>,
      perPage: 15 as number,
      page: 1 as number,
      timers: {
        name: null as any,
      },
      loaded: false as boolean,
      currentTemplate: null as null | Template,
      filterOptions: [] as Record<string, any>[],
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
  },
  async created() {
    await this.setQueryParametersAsFilters();
    await this.doSearch();
  },
  methods: {
    search() {
      clearTimeout(this.timers.name);

      this.timers.name = withTimeout(async () => {
        await this.doSearch();
      }, this.timers.name, 1000);
    },
    /**
     * Detects any query parameter in the url and loads it in the filters (if available)
     *
     * @returns {Promise<void>}
     */
    async setQueryParametersAsFilters() {
      const { query }: any = this.$route;

      const {
        filters,
        order,
        perPage,
        status,
        page,
      }: any = qs.parse(query);

      if (!!filters) {
        this.filters.forEach((filter: Record<string, any>) => {
          if (!!filters[filter.key]) {
            this[filter.variable] = filters[filter.key];
          }
        });
      }

      if (!!order) {
        this.order = order;
      }

      const pageNumber = parseInt(page, 10);

      if (!Number.isNaN(pageNumber)) {
        this.page = pageNumber;
      }

      const perPageNumber = parseInt(perPage, 10);

      if (!Number.isNaN(perPageNumber)) {
        this.perPage = perPageNumber;
      }

      if (!!status) {
        this.status = status;
      }
    },
    /**
     * Sets the local filters as url query parameters.
     */
    async setFiltersAsQueryParameters() {
      const filters: any = {};

      this.filters.forEach((filter: Record<string, any>) => {
        if (!!this[filter.variable]) {
          filters[filter.key] = this[filter.variable];
        }
      });

      const queryObject: any = {
        filters,
        perPage: this.perPage,
        status: this.status,
        page: this.page,
      };

      if (!!this.order && !!this.order.key) {
        queryObject.order = this.order;
      }

      await this.setFilters();

      let query: any = qs.stringify(queryObject, { encode: false });
      query = querystring.parse(query);

      this.$router.push({
        name: 'template.index',
        query,
      });
    },
    async doSearch() {
      await this.setFiltersAsQueryParameters();
      await this.loadTemplates();
    },
    async loadTemplates(page?: number) {
      try {
        this.loaded = false;

        const filters: any = {};

        this.filters.forEach((filter: Record<string, any>) => {
          if (!!this[filter.variable]) {
            filters[filter.key] = this[filter.variable];
          }
        });

        const query: any = {
          filters,
          order: this.order,
          perPage: this.perPage,
          status: this.status,
          page: !!page ? page : this.page,
        };

        if (!!this.agency) {
          const response = await api.template.allPaginated(this.agency.slug, query);

          this.setResponse(response);

          this.templates = response.data;
        }
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.loaded = true;
      }
    },
    cleanCurrentTemplate() {
      this.currentTemplate = null;
    },
    async handlePageChangedEvent(page: number) {
      await this.loadTemplates(page);
    },
    toggleOrder(field: string) {
      const { order } = this.order;

      if (!order) {
        this.order.order = 'ASC';
      } else {
        this.order.order = order === 'ASC' ? 'DESC' : 'ASC';
      }

      this.order.key = field;

      this.search();
    },
    setFilters() {
      this.filterOptions = this.filters.flatMap((filter: Record<string, any>) => {
        const {
          key,
          multiple,
          isConcept,
          labelAux,
          conceptName,
          variable,
        } = filter;

        const options = multiple ? this[variable] : [this[variable]];

        return options.map((option: any) => {
          const value = isConcept ? findConceptByKey(`${conceptName}`, option) : option;
          const label = this.$t(`${labelAux}`, { value: !!conceptName ? value.name : value });

          return { key, value: !!conceptName ? value.key : value, label };
        });
      });
    },
    removeFilter(filter: Record<string, string>) {
      this.filters.forEach((filterAux: Record<string, string | boolean>) => {
        if (filterAux.key === filter.key) {
          if (!filterAux.multiple) {
            this[filterAux.variable] = '';
          } else {
            this[filterAux.variable] = this[filterAux.variable].filter((option: string) => option !== filter.value);
          }
        }
      });

      if (filter.key === 'status') {
        this.status = 'active';
      }

      this.doSearch();
    },
    setCurrentTemplate(template: Template) {
      this.currentTemplate = template;

      if (this.offCanvasToShow !== 'the-template-archived-offcanvas') {
        this.openOffCanvas('the-template-archived-offcanvas');
      }
    },
  },
  unmounted() {
    this.cleanCurrentTemplate();
  },
});
</script>
