<template>
  <v-off-canvas
    id="collaborator-create-offcanvas"
    :backdrop-static="false"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <h5 id="collaborator-create-offcanvas-label" class="offcanvas-title">
        {{ $t('admin.collaborator.createNewCollaborator') }}
      </h5>
      </off-canvas-header>

      <off-canvas-body>
      <form
        id="collaborator-create-form"
        class="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <fieldset>
            <legend class="visually-hidden">{{ $t('admin.user.basicInformation') }}</legend>

            <v-form-input
              id="collaborator-create-name"
              v-model="name"
              :label="`${$t('general.shared.companyName')}*`"
              :yup-errors-variable="errors.name"
              autocomplete="off"
              class="mb-8"
              form-type="outline"
            />


          
          </fieldset>

          <v-contact-billing-form
            id="collaborator-create-address-form"
            ref="collaborator-create-address-form-ref"
            :data="addressData"
            id-prefix="collaborator-create"
            @updated-data="addressData = $event"
            @is-valid="addressValid = $event"
          >
          <!-- Slot to create vat in contact form -->
            <template #vatInput>  
              <v-form-input
                id="collaborator-create-vat"
                v-model="vat"
                :label="`${$t('general.shared.vat')}`"
                :yup-errors-variable="errors.vat"
                autocomplete="off"
                class="mb-8"
                form-type="outline"
              />
            </template>
          </v-contact-billing-form>

        </div>

        <v-button
          :disabled="!meta.valid || !addressValid || accepted"
          :is-loading="accepted"
          class="btn-submit-form btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="createcollaborator"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </form>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { VFormInput } from '@uniqoders/form';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useFormValidation from '@/helpers/form';
import api from '@/api';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheAdmincollaboratorCreateOffcanvas',
  components: {
    VIcon,
    VButton,
    VContactBillingForm,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VFormInput,
    ResizableContainer,
  },
  emits: [
    'closed',
    'reloadAgencies',
    'openDetails',
  ],
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      nif: yup.string()
        .nullable()
        .label(t('general.shared.nif')),
      location: yup.string()
        .nullable()
        .label(t('general.shared.location')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: vat } = useField('vat');
    const { value: location } = useField('location');

    return {
      ...form,
      name,
      vat,
      location,
    };
  },
  data() {
    return {
      addressData: {} as any,
      addressValid: false as boolean,
    };
  },
  methods: {
    async createcollaborator() {
      
      try {
        this.toggleAccepted();

        const data: any = {
          name: this.name,
          vat: this.vat,
          location_id: this.addressData.locationId,
          contact_address_1: this.addressData.contactAddress1,
          contact_address_2: this.addressData.contactAddress2,
          contact_postal_code: this.addressData.contactPostalCode,
          contact_country: this.addressData.contactCountry,
          contact_city: this.addressData.contactCity,
          contact_state: this.addressData.contactState,
          billing_address_1: this.addressData.billingAddress1,
          billing_address_2: this.addressData.billingAddress2,
          billing_postal_code: this.addressData.billingPostalCode,
          billing_country: this.addressData.billingCountry,
          billing_city: this.addressData.billingCity,
          billing_state: this.addressData.billingState,
          location: this.location,
          is_collaborator: true,
          created_by_agency_slug: localStorage.getItem('agency') || null,
        };

        const response = await api.admin.agency.create(data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.resetForm();

        (this.$refs['collaborator-create-address-form-ref'] as any).clearForm();

        this.$emit('openDetails', response);
        this.$emit('reloadAgencies');
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
      }
    },
    async setLocation(address: Record<string, any>) {
      this.location = address.locality.longName;

      await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });
    },
  },
});
</script>
