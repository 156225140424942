export default {
  clients: 'Clientes',
  noClients: 'No hay clientes',
  createNewClient: 'Crear nuevo cliente',
  archivedClients: 'Clientes archivados',
  activeClients: 'Clientes activos',
  basicInformation: 'Información básica',
  clientDetails: 'Detalles del cliente',
  archiveText: 'Puede archivar un cliente, si lo hace, no aparecerá en las búsquedas de la aplicación. Podrá verlo en la lista de clientes si filtra por \'archivado\'. Una vez que vea los detalles de un cliente archivado, puede eliminarlo definitivamente o desarchivarlo.',
  media: 'Medios',
  noMedia: 'No hay medios asociados',
  archivedText: 'Este cliente está archivado, si lo desea, puede desarchivarlo haciendo clic en el botón \'Restaurar\'.',
  archiveClientTitle: 'Archivar cliente',
  archiveClientText: '¿Está seguro de que desea archivar el cliente \'{client}\'? Si lo archiva, no aparecerá en las búsquedas.',
  deleteText: 'Este cliente está archivado, si desea eliminarlo permanentemente, haga clic en el botón \'Eliminar\'. Tenga en cuenta que si lo elimina, este cliente dejará de existir y no se podrá recuperar.',
  deleteClientTitle: 'Eliminar cliente',
  deleteClientText: '¿Está seguro de que desea eliminar el cliente \'{client}\'? Si lo elimina, no podrá recuperarlo.',
  restoreClientTitle: 'Restaurar cliente',
  restoreClientText: '¿Está seguro de que desea recuperar el cliente \'{client}\'? Si lo recupera, dejará de estar archivado y aparecerá en las búsquedas de la aplicación.',
  clientStatus: 'Estado del cliente',
  contacts: 'Contactos',
  noContacts: 'No hay contactos asociados',
  editContactsBelongClientText: 'Editar contactos pertenecientes al cliente',
  addContacts: 'Agregar contactos',
  removeContactTitle: 'Eliminar contacto del cliente',
  removeContactText: '¿Está seguro de que desea eliminar a {contact} como contacto del cliente {client}?',
  cases: 'Casos',
  casesText: 'Lista de casos que pertenecen al cliente',
  seeCases: 'Ver casos',
};
