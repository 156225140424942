export default {
  contact: 'Contact',
  contacts: 'Contacten',
  noContacts: 'Er zijn geen contacten',
  createNewContact: 'Nieuw contact aanmaken',
  archivedContacts: 'Gearchiveerde contacten',
  activeContacts: 'Actieve contacten',
  basicInformation: 'Basisinformatie',
  contactDetails: 'Contactgegevens',
  archiveText: 'Je kunt een contact archiveren. Als je dit doet, zal het contact niet verschijnen in de applicatie-zoekopdrachten. Je kunt het zien in de lijst van contacten als je filtert op \'gearchiveerd\'. Zodra je de details van een gearchiveerd contact bekijkt, kun je het definitief verwijderen of opnieuw activeren.',
  media: 'Media',
  noMedia: 'Er zijn geen gerelateerde media',
  archivedText: 'Dit contact is gearchiveerd. Als je wilt, kun je het terugzetten door op de knop \'Herstellen\' te klikken.',
  archiveContactTitle: 'Contact archiveren',
  archiveContactText: 'Weet je zeker dat je het contact \'{contact}\' wilt archiveren? Als je het archiveert, zal het niet meer verschijnen in de zoekopdrachten.',
  deleteText: 'Dit contact is gearchiveerd. Als je het permanent wilt verwijderen, klik dan op de knop \'Verwijderen\'. Let op: als je het verwijdert, bestaat dit contact niet meer en kan het niet worden hersteld.',
  deleteContactTitle: 'Contact verwijderen',
  deleteContactText: 'Weet je zeker dat je het contact \'{contact}\' wilt verwijderen? Als je het verwijdert, kun je het niet meer herstellen.',
  restoreContactTitle: 'Contact herstellen',
  restoreContactText: 'Weet je zeker dat je het contact \'{contact}\' wilt herstellen? Als je het herstelt, is het niet langer gearchiveerd en zal het verschijnen in de zoekopdrachten van de applicatie.',
  contactsStatus: 'Contactstatus',
};
