import { AbstractService } from '@uniqoders/sdk';
import Tour from "@/api/objects/Tour";

export default class EmailAuthLessService extends AbstractService {
  /**
   * Retrieves a specific tour with paxes
   * @protected
   * @param tour
   * @param resources_id
   * @param token
   */
  public retrieveTourPricing(tour: number,resources_id: any, token: any): Promise<Tour> {
    return this.request({
      method: "get",
      url: this.buildPath("/fetch-pricing-data/{tour}", { tour }),
      params: {
        resources_id: resources_id,
        token: token,
      },
    });
  }

  /**
   * Updates the specified Tour Item Resource Group Pricing
   * @protected
   * @param data
   */
  public updatePricing(data: Record<string, any>): Promise<any> {
    return this.request({
      method: "post",
      url: this.buildPath("/update-pricing-data"),
      data,
    });
  }

  /**
   * Updates the specified Tour Item Resource Group Pricing
   * @protected
   * @param tourDayItemResource
   * @param data
   */
  public updateTourDayItemResource(tourDayItemResource: number, data: Record<string, any>): Promise<any> {
    return this.request({
      method: "post",
      url: this.buildPath("/update-tour-item-data/{tourDayItemResource}", { tourDayItemResource }),
      data,
    });
  }

  /**
   * Updates the specified Tour Item Resource Group Pricing
   * @protected
   * @param data
   */
  public netValidate(data: Record<string, any>): Promise<any> {
    return this.request({
      method: "post",
      url: this.buildPath("/update-net-pricing-data"),
      data,
    });
  }

  /**
   * Confirm reservation
   * @protected
   * @param data
   */
  public confirmedemail(data: any): Promise<any> {
    return this.request({
      method: "post",
      url: this.buildPath("/confirm-changes"),
      data,
    });
  }
}


 
 
 
 
 
 
 
 
 
 
 
