<template>
  <div class="detail">
    <div class="detail-title d-flex justify-content-between mb-20">
      <div class="fw-medium">{{ $t('client.contacts') }}</div>

      <v-button
        class="btn-add p-4"
        variant="icon"
        @click="openModal('v-contact-create-modal')">
        <v-icon icon="circle-plus"/>
      </v-button>
    </div>

    <div class="detail-content">
      <div class="contacts-container card-item-list">
        <div v-if="!client.contacts || client.contacts.length === 0">
          {{ $t('client.noContacts') }}
        </div>

        <div
          v-for="contact in client.contacts"
          :key="contact.id"
          class="contact item-card border rounded-xs d-flex justify-content-between align-items-center
          p-8 p-lg-12 mb-8"
        >
          <div class="d-flex align-items-center w-100 me-lg-24">
            <div class="image-container rounded-sm bg-tertiary me-8 me-lg-12">
              {{ contact.name?.charAt(0) }}
            </div>

            <div
              class="w-100 d-flex flex-column text-sm">
              <div class="fw-medium">{{ contact.name }} {{ contact.surname }}</div>
              <div class="text-sm fw-light text-gray-500 wrap-anywhere">{{ contact.email }}</div>
              <div class="text-sm fw-light text-gray-500">{{ contact.phone }}</div>
            </div>
          </div>

          <div class="d-flex">
            <v-button
              class="btn-show-details shadow-none p-2"
              variant="icon"
              @click="showContactDetail(contact)"
            >
              <v-icon icon="eye"/>
            </v-button>

            <v-button
              :disabled="disableArchiveButton"
              class="shadow-none p-2"
              variant="icon"
              @click="archiveContact(contact)"
            >
              <v-icon icon="trash"/>
            </v-button>
          </div>
        </div>
      </div>
    </div>

    <the-contact-details-modal
      v-if="modalToShow === 'the-contact-details-modal'"
      :contact="currentContact"
      :contactable-type="'client'"
      @closed="closeModal(); currentContact = null"
      @reload-item="reloadClient"
    />

    <v-contact-create-modal
      v-if="modalToShow === 'v-contact-create-modal'"
      id="client-contact-create-modal"
      :associate-model-id="client.id"
      contactable-type="client"
      id-prefix="create-client"
      @closed="closeModal"
      @reload-item="reloadClient"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useModalUtils from '@/helpers/ModalUtils';
import Contact from '@/api/objects/Contact';
import api from '@/api';
import TheContactDetailsModal from '@/components/contact/TheContactDetailsModal.vue';
import VContactCreateModal from '@/components/contact/VContactCreateModal.vue';
import Client from '@/api/objects/Client';

export default defineComponent({
  name: 'TheClientDetailsContacts',
  components: {
    VContactCreateModal,
    TheContactDetailsModal,
    VIcon,
    VButton,
  },
  emits: ['closed', 'reloadClient'],
  props: {
    client: {
      type: Client,
      required: true,
    },
  },
  setup() {
    return {
      ...useModalUtils(),
    };
  },
  data() {
    return {
      currentContact: null as null | Contact,
      disableArchiveButton: false as boolean,
    };
  },
  methods: {
    async showContactDetail(contact: Contact) {
      this.currentContact = contact;

      if (this.modalToShow !== 'the-contact-details-modal') {
        this.openModal('the-contact-details-modal');
      }
    },
    async archiveContact(contact: Contact) {
      await this.$modal.delete({
        title: this.$t('client.removeContactTitle'),
        text: this.$t('client.removeContactText', {
          client: `'${this.client?.name}  ${!!this.client?.surname ? this.client.surname : ''}'`,
          contact: `'${contact.name} ${!!contact.surname ? contact.surname : ''}'`,
        }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doArchiveContact(contact.id),
      });
    },
    async doArchiveContact(contactId: string | number) {
      try {
        this.disableArchiveButton = true;

        await api.contact.archive(contactId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.reloadClient();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableArchiveButton = false;
      }
    },
    reloadClient() {
      this.$emit('reloadClient');
    },
  },
});
</script>
