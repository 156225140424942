export default {
  title: 'Actividades',
  noProducts: 'No hay actividades',
  createNew: 'Crear nueva actividad',
  setup: 'Configuración',
  builder: 'Constructor',
  productBuilder: 'Constructor de la actividad',
  product: 'Actividad',
  travelType: 'Tipo de viaje',
  show: 'Mostrar actividad',
  archived: 'Actividades archivadas',
  active: 'Actividades activas',
  basicInformation: 'Información básica',
  details: 'Detalles de la actividad',
  detailsSubtitle: 'La información general de la actividad vive aquí',
  media: 'Medios',
  noMedia: 'No hay medios asociados',
  archiveSubtitle: 'Puede archivar la actividad aquí',
  archivedText: 'Esta actividad está archivada, si lo desea puede desarchivarla haciendo clic en el botón \'Restaurar\'.',
  archiveTitle: 'Archivar actividad',
  archiveAlertText: '¿Seguro que quiere archivar la actividad \'{product}\'? Si la archiva, no aparecerá en las búsquedas',
  archiveText: 'Puedes archivar una actividad, si lo haces la actividad no aparecerá en las búsquedas de la aplicación. Podrá verla en la lista de actividades si filtra por \'archivado\'. Una vez que vea los detalles de una actividad archivada, puede eliminarla definitivamente o puedes desarchivarla',
  archiveTextAlert: '¿Seguro que quiere archivar la actividad \'{product}\'? Si lo archiva, no aparecerá en las búsquedas',
  deleteText: 'Esta actividad está archivada, si quieres eliminarla permanentemente pulsa el botón \'Eliminar\'. Tenga en cuenta que si la elimina, esta actividad dejará de existir y no podrá recuperarse.',
  deleteTitle: 'Eliminar actividad',
  deleteTextAlert: '¿Seguro que quieres eliminar la actividad \'{product}\'? Si la eliminas, no podrás recuperarla',
  restoreTitle: 'Restaurar actividad',
  restoreText: '¿Seguro que quieres recuperar la actividad \'{product}\'? Si la recupera, dejará de estar archivada y aparecerá en las búsquedas de la aplicación.',
  status: 'Estado de la actividad',
  search: 'Búsqueda de actividad',
  canSearch: 'Puedes buscar la actividad',
  orUpdateItManually: 'O actualizarlo manualmente',
  removeMediaTitle: 'Eliminar medios',
  removeMediaText: '¿Estás seguro de que quieres borrar el archivo? Si lo borras, no podrás recuperarlo',
  tourType: 'Tipo de recorrido',
  noArchived: 'No hay actividades archivadas',
  useProduct: 'Actividad usada',
  productSaved: 'Actividad guardada',
  createCaseWithTemplate: 'Crear caso con esta actividad',
  deleteDayTitle: 'Borrar día',
  deleteDayText: '¿Está seguro de que quiere borrar el día {dayNumber}? Si lo borras, todo el progreso que hayas hecho en este día se perderá',
  dayDeleted: 'Día borrado con éxito',
  dayDeleteError: 'Se ha producido un error al borrar el día',
  lengthErrorMessage: 'Escriba una distancia en Kms o escriba cero',
  dayUpdated: 'Día actualizado con éxito',
  dayUpdateError: 'Se ha producido un error al actualizar el día',
  stage: 'Etapa',
  duplicateActivity: 'Duplicar Actividad',
  duplicatedActivitySuccess: 'Actividad duplicada con éxito',
  redirectingNewActivity: 'Redirigiendo a la nueva actividad...',
  templateBelongsToCollaborator: 'Esta actividad es para colaboradores',
  agency: {
    title: 'Agencias',
    subtitle: 'Agencias asociadas a esta actividad',
    noAgencies: 'Esta actividad no está asociada a ninguna agencia',
    editAgenciesBelongsTo: 'Edita las agencias a las que pertenece esta actividad',
    addAgency: 'Añadir agencia',
    addAgencyToTemplateTitle: 'Añadir agencia a la actividad',
    addAgencyToTemplateTitleText: '¿Está seguro de que desea asociar la agencia \'{ agency }\' a la actividad \'{ template }\'?',
    detachAgencyToTemplateTitle: 'Desasignar agencia de la actividad',
    detachAgencyToTemplateTitleText: '¿Está seguro de que desea desasignar la agencia \'{ agency }\' de la actividad \'{ template }\'?',
  },
  contact: {
    contacts: 'Contactos',
    contactsSubtitle: 'Aquí podrás añadir la información de contacto por defecto para la vista previa y presupuesto de los casos que usen este producto.',
    contactInformation: 'Información de contacto',
  },
  pax: {
    title: 'Pasajeros',
    subtitle: 'Pasajeros de la actividad',
  },
  addDayTitle: 'Añadir día a la actividad',
  addDayText: '¿Estás seguro de que quieres añadir un nuevo día a esta actividad?',
};
