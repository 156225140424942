<template>
  <form :id="`traveler-create-${paxId}-form`">
    <fieldset class="mb-24">
      <legend class="separator-container">
        <span class="separator">
          <span class="separator-text text-sm">{{ $t('traveler.basicInformation') }}</span>
        </span>
      </legend>

      <div class="row">
        <div class="col-12 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-name`"
            v-model="nameLocal"
            :label="`${$t('general.shared.name')}*`"
            :yup-errors-variable="errors.nameLocal"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:name', nameLocal)"
          />
        </div>

        <div class="col-12 col-lg-6 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-surname`"
            v-model="surnameLocal"
            :label="`${$t('general.shared.surname')}*`"
            :yup-errors-variable="errors.surnameLocal"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:surname1', surnameLocal)"
          />
        </div>

        <div class="col-12 col-lg-6 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-surname-second`"
            v-model="surnameSecondLocal"
            :label="$t('general.shared.secondSurname')"
            :yup-errors-variable="errors.surnameSecondLocal"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:surname2', surnameSecondLocal)"
          />
        </div>

        <div class="col-12 col-md-5 col-xl-4">
          <v-form
            :id="`traveler-create-${paxId}-document-type`"
            :label="`${$t('general.shared.documentType')}*`"
            :yup-errors-variable="errors.documentTypeLocal"
            form-type="outline"
          >
            <v-select
              v-model="documentTypeLocal"
              :class="{ 'is-invalid': !!errors.documentTypeLocal }"
              :options="documentTypeOptions"
              :reduce="returnValue => returnValue.key"
              @option:selected="$emit('update:documentType', documentTypeLocal) ; updatePax()"
            >
              <template v-slot:option="option">
                <span class="text-uppercase">{{ option.label }}</span>
              </template>

              <template v-slot:selected-option="option">
                <span class="text-uppercase">{{ option.label }}</span>
              </template>
            </v-select>
          </v-form>
        </div>

        <div class="col-12 col-md-7 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-document-number`"
            v-model="documentNumberLocal"
            :label="`${$t('general.shared.documentNumber')}*`"
            :yup-errors-variable="errors.documentNumberLocal"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:documentNumber', documentNumberLocal)"
          />
        </div>

        <div class="col-12 col-md-5 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-birth-date`"
            v-model="birthDateLocal"
            :label="$t('general.shared.dateBirth')"
            :yup-errors-variable="errors.birthDateLocal"
            autocomplete="off"
            form-type="outline"
            type="date"
            @blur="updatePax"
            @input="$emit('update:birthDate', birthDateLocal)"
          />
        </div>

        <div class="col-12 col-md-7 col-xl-12">
          <v-form
            :id="`traveler-create-${paxId}-language`"
            :label="$t('traveler.spokenLanguage')"
            form-type="outline"
          >
            <v-select
              v-model="languageLocal"
              :options="languageOptions"
              :reduce="returnValue => returnValue.key"
              @update:model-value="$emit('update:language', languageLocal); updatePax()"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-8">
      <legend class="separator-container">
        <span class="separator">
          <span class="separator-text text-sm">{{ $t('traveler.contactInformation') }}</span>
        </span>
      </legend>

      <div class="row">
        <div class="col-12 col-xl-5">
          <v-form-input
            :id="`traveler-create-${paxId}-email`"
            v-model="emailLocal"
            :label="$t('general.shared.email')"
            :yup-errors-variable="errors.emailLocal"
            autocomplete="off"
            form-type="outline"
            type="email"
            @blur="updatePax"
            @input="$emit('update:email', emailLocal)"
          />
        </div>

        <div class="col-12 col-md-5 col-xl-3">
          <v-form
            :id="`traveler-create-${paxId}-country-code`"
            :label="$t('general.shared.countryCode')"
            :yup-errors-variable="errors.countryCode"
            class="mb-8"
            form-type="outline"
          >
            <v-select
              key="value"
              v-model="countryCodeLocal"
              :class="{ 'is-invalid': !!errors.countryCodeLocal }"
              :clearable="true"
              :options="countryCodeOptions"
              :placeholder="$t('general.shared.countryCodePlaceholder')"
              :reduce="prefix => prefix.value"
              @update:model-value="setPhoneFormat"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>
        </div>

        <div class="col-12 col-md-7 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-phone`"
            v-model="phoneLocal"
            :label="$t('general.shared.phone')"
            :yup-errors-variable="errors.phoneLocal"
            autocomplete="off"
            class="mb-8"
            @blur="setPhoneFormat"
          />
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-16">
      <legend class="separator-container">
        <span class="separator">
          <span class="separator-text text-sm">{{ $t('traveler.addressInformation') }}</span>
        </span>
      </legend>

      <div class="row">
        <div class="col-12 col-xl-6">
          <v-input-address
            :id="`traveler-create-${paxId}-street`"
            :ref="`traveler-create-${paxId}-street-ref`"
            :form-floating="false"
            :get-address="true"
            :label="$t('general.address.streetAddress')"
            :value="addressStreetLocal"
            @address="setAddress"
          />
        </div>

        <div class="col-12 col-xl-6">
          <v-form-input
            :id="`traveler-create-${paxId}-city`"
            v-model="addressCityLocal"
            :label="$t('general.address.city')"
            :yup-errors-variable="errors.addressCityLocal"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:addressCity', addressCityLocal)"
          />
        </div>

        <div class="col-12 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-postal-code`"
            v-model="addressCpLocal"
            :label="$t('general.address.postalCode')"
            :yup-errors-variable="errors.addressCpLocal"
            autocomplete="off"
            class="col-12"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:addressCp', addressCpLocal)"
          />
        </div>

        <div class="col-12 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-state`"
            v-model="addressStateLocal"
            :label="$t('general.address.provinceStateRegion')"
            :yup-errors-variable="errors.addressStateLocal"
            autocomplete="off"
            class="col-12"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:addressState', addressStateLocal)"
          />
        </div>

        <div class="col-12 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-country`"
            v-model="addressCountryLocal"
            :label="$t('general.address.country')"
            :yup-errors-variable="errors.addressCountryLocal"
            autocomplete="off"
            class="col-12"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:addressCountry', addressCountryLocal)"
          />
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-12">
      <legend class="separator-container mb-24">
        <span class="separator">
          <span class="separator-text text-sm">{{ $t('traveler.moreInformation') }}</span>
        </span>
      </legend>

      <div class="row">
        <div class="col-12">
          <p class="mb-4">{{ $t('traveler.haveAllergies') }}*</p>

          <div class="form-check">
            <label class="form-check-label" for="allergies-true">
              <input
                id="allergies-true"
                v-model="allergiesCheckLocal"
                :value="true"
                class="form-check-input"
                type="radio"
              />
              {{ $t('traveler.yes') }}
            </label>
          </div>

          <div class="form-check">
            <label class="form-check-label" for="allergies-false">
              <input
                id="allergies-false"
                v-model="allergiesCheckLocal"
                :value="false"
                class="form-check-input"
                type="radio"
                @update:model-value="$emit('update:allergies', ''); updatePax()"
              />
              {{ $t('traveler.no') }}
            </label>
          </div>
        </div>

        <div v-if="allergiesCheckLocal" class="col-12">
          <v-form-input
            :id="`traveler-create-${paxId}-allergies`"
            v-model="allergiesLocal"
            :label="`${$t('traveler.haveAllergiesText')}*`"
            :yup-errors-variable="errors.allergiesLocal"
            autocomplete="off"
            class="mb-16"
            form-type="outline"
            input-type="textarea"
            rows="5"
            @blur="updatePax"
            @input="$emit('update:allergies', allergiesLocal)"
          />
        </div>

        <div class="col-12 mt-16">
          <p class="mb-4">{{ $t('traveler.followingDiet') }}*</p>

          <div class="form-check">
            <label class="form-check-label" for="diet-no">
              <input
                id="diet-no"
                v-model="dietLocal"
                class="form-check-input"
                type="radio"
                value="diet-no"
                @update:model-value="$emit('update:diet', dietLocal); updatePax()"
              />
              {{ $t('traveler.no') }}
            </label>
          </div>

          <div v-for="diet in dietOptions" :key="diet.key" class="form-check">
            <label :for="`diet-${diet.key}`" class="form-check-label">
              <input
                :id="`diet-${diet.key}`"
                v-model="dietLocal"
                :value="diet.key"
                class="form-check-input"
                type="radio"
                @update:model-value="$emit('update:diet', dietLocal); updatePax()"
              />
              {{ diet.label }}
            </label>
          </div>
        </div>

        <div v-if="dietLocal === 'other_diet'" class="col-12 mt-8">
          <v-form-input
            :id="`traveler-create-${paxId}-diet-other`"
            v-model="dietOther"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:diet', dietOther)"
          />
        </div>

        <div class="col-12 mt-16">
          <p class="mb-4">{{ $t('traveler.bikeText') }}</p>

          <div class="form-check">
            <label class="form-check-label" for="bike-true">
              <input
                id="bike-true"
                v-model="bikeLocal"
                :value="true"
                class="form-check-input"
                type="radio"
              />
              {{ $t('traveler.yes') }}
            </label>
          </div>

          <div class="form-check">
            <label class="form-check-label" for="bike-false">
              <input
                id="bike-false"
                v-model="bikeLocal"
                :value="false"
                class="form-check-input"
                type="radio"
                @update:model-value="$emit('update:height', ''); $emit('update:weight', '');
                                     $emit('update:bikeType', ''); updatePax()"
              />
              {{ $t('traveler.no') }}
            </label>
          </div>
        </div>

        <div v-if="bikeLocal" class="mt-16">
          <p class="mb-0">{{ $t('traveler.bikeYesText') }}</p>

          <div class="row">
            <div class="col-4">
              <v-form-input
                :id="`traveler-create-${paxId}-height`"
                v-model="heightLocal"
                :label="$t('traveler.height')"
                :yup-errors-variable="errors.heightLocal"
                autocomplete="off"
                form-type="outline"
                @blur="updatePax"
                @input="$emit('update:height', heightLocal)"
              />
            </div>

            <div class="col-4">
              <v-form-input
                :id="`traveler-create-${paxId}-weight`"
                v-model="weightLocal"
                :label="$t('traveler.weight')"
                :yup-errors-variable="errors.weightLocal"
                autocomplete="off"
                form-type="outline"
                @blur="updatePax"
                @input="$emit('update:weight', weightLocal)"
              />
            </div>

            <div class="col-4">
              <v-form
                :id="`traveler-create-${paxId}-bike-type`"
                :label="$t('traveler.bikeType')"
                form-type="outline"
              >
                <v-select
                  v-model="bikeTypeLocal"
                  :options="bikeTypeOptions"
                  :reduce="returnValue => returnValue.key"
                  @option:selected="$emit('update:bikeType', bikeTypeLocal); updatePax()"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </v-form>
            </div>
          </div>
        </div>

        <div class="col-12">
          <v-form-input
            :id="`traveler-create-${paxId}-observations`"
            v-model="observationsLocal"
            :label="$t('general.shared.supplier_observations')"
            :yup-errors-variable="errors.observationsLocal"
            autocomplete="off"
            class="mb-16"
            form-type="outline"
            input-type="textarea"
            rows="5"
            @blur="updatePax"
            @input="$emit('update:observations', observationsLocal)"
          />
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-24">
      <legend class="separator-container">
        <span class="separator">
          <span class="separator-text text-sm">{{ $t('traveler.arrival') }}</span>
        </span>
      </legend>

      <div class="row">
        <div :class="arrivalTypeLocal === 'other_arrival_type' ? 'col-6 col-md-3' : 'col-6' ">
          <v-form
            :id="`traveler-create-${paxId}-arrival-type`"
            :label="$t('traveler.arrivalType')"
            form-type="outline"
          >
            <v-select
              v-model="arrivalTypeLocal"
              :options="arrivalTypeOptions"
              :reduce="returnValue => returnValue.key"
              @option:selected="$emit('update:arrivalType', arrivalTypeLocal); updatePax()"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>
        </div>

        <div v-if="arrivalTypeLocal === 'other_arrival_type'" class="col-3">
          <v-form-input
            :id="`traveler-create-${paxId}-arrival-other-type`"
            v-model="arrivalTypeOther"
            :label="$t('traveler.arrivalType')"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:arrivalType', arrivalTypeOther)"
          />
        </div>

        <div class="col-12 col-md-6">
          <v-form-input
            :id="`traveler-create-${paxId}-arrival-number`"
            v-model="arrivalNumberLocal"
            :label="$t('traveler.arrivalNumber')"
            :yup-errors-variable="errors.arrivalNumberLocal"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:arrivalNumber', arrivalNumberLocal)"
          />
        </div>

        <div class="col-12 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-arrival-location`"
            v-model="arrivalLocationLocal"
            :label="$t('traveler.arrivalLocation')"
            :yup-errors-variable="errors.arrivalLocationLocal"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:arrivalLocation', arrivalLocationLocal)"
          />
        </div>

        <div class="col-12 col-md-6 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-arrival-date`"
            v-model="arrivalDateLocal"
            :label="$t('traveler.arrivalDate')"
            :yup-errors-variable="errors.arrivalDateLocal"
            autocomplete="off"
            form-type="outline"
            type="date"
            @blur="updatePax"
            @input="$emit('update:arrivalDate', arrivalDateLocal)"
          />
        </div>

        <div class="col-12 col-md-6 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-arrival-hour`"
            v-model="arrivalHourLocal"
            :label="$t('traveler.arrivalHour')"
            :yup-errors-variable="errors.arrivalHourLocal"
            autocomplete="off"
            form-type="outline"
            type="time"
            @blur="updatePax"
            @input="$emit('update:arrivalHour', arrivalHourLocal)"
          />
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-24">
      <legend class="separator-container">
        <span class="separator">
          <span class="separator-text text-sm">{{ $t('traveler.departure') }}</span>
        </span>
      </legend>

      <div class="row">
        <div :class="departureTypeLocal === 'other_arrival_type' ? 'col-6 col-md-3' : 'col-6'">
          <v-form
            :id="`traveler-create-${paxId}-departure-type`"
            :label="$t('traveler.departureType')"
            form-type="outline"
          >
            <v-select
              v-model="departureTypeLocal"
              :options="arrivalTypeOptions"
              :reduce="returnValue => returnValue.key"
              @option:selected="$emit('update:departureType', departureTypeLocal); updatePax()"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>
        </div>

        <div v-if="departureTypeLocal === 'other_arrival_type'" class="col-3">
          <v-form-input
            :id="`traveler-create-${paxId}-departure-other-type`"
            v-model="departureTypeOther"
            :label="$t('traveler.departureType')"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:departureType', departureTypeOther)"
          />
        </div>

        <div class="col-6">
          <v-form-input
            :id="`traveler-create-${paxId}-departure-number`"
            v-model="departureNumberLocal"
            :label="$t('traveler.departureNumber')"
            :yup-errors-variable="errors.departureNumberLocal"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:departureNumber', departureNumberLocal)"
          />
        </div>

        <div class="col-12 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-departure-location`"
            v-model="departureLocationLocal"
            :label="$t('traveler.departureLocation')"
            :yup-errors-variable="errors.departureLocationLocal"
            autocomplete="off"
            form-type="outline"
            @blur="updatePax"
            @input="$emit('update:departureLocation', departureLocationLocal)"
          />
        </div>

        <div class="col-12 col-md-6 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-departure-date`"
            v-model="departureDateLocal"
            :label="$t('traveler.departureDate')"
            :yup-errors-variable="errors.departureDateLocal"
            autocomplete="off"
            form-type="outline"
            type="date"
            @blur="updatePax"
            @input="$emit('update:departureDate', departureDateLocal)"
          />
        </div>

        <div class="col-12 col-md-6 col-xl-4">
          <v-form-input
            :id="`traveler-create-${paxId}-departure-hour`"
            v-model="departureHourLocal"
            :label="$t('traveler.departureHour')"
            :yup-errors-variable="errors.departureHourLocal"
            autocomplete="off"
            form-type="outline"
            type="time"
            @blur="updatePax"
            @input="$emit('update:departureHour', departureHourLocal)"
          />
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VForm, VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import vSelect from 'vue-select';
import useFormValidation from '@/helpers/form';
import { mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import { useAppStore } from '@/stores/app';

export default defineComponent({
  name: 'TheTravelerForm',
  components: {
    VInputAddress,
    VFormInput,
    VForm,
    vSelect,
  },
  props: {
    addressCity: {
      type: String,
      required: false,
    },
    addressCountry: {
      type: String,
      required: false,
    },
    addressCp: {
      type: String,
      required: false,
    },
    addressState: {
      type: String,
      required: false,
    },
    addressStreet: {
      type: String,
      required: false,
    },
    allergies: {
      type: String,
      required: false,
    },
    arrivalDate: {
      type: String,
      required: false,
    },
    arrivalHour: {
      type: String,
      required: false,
    },
    arrivalLocation: {
      type: String,
      required: false,
    },
    arrivalNumber: {
      type: String,
      required: false,
    },
    arrivalType: {
      type: String,
      required: false,
    },
    birthDate: {
      type: String,
      required: false,
    },
    bikeType: {
      type: String,
      required: false,
    },
    departureDate: {
      type: String,
      required: false,
    },
    departureHour: {
      type: String,
      required: false,
    },
    departureLocation: {
      type: String,
      required: false,
    },
    departureNumber: {
      type: String,
      required: false,
    },
    departureType: {
      type: String,
      required: false,
    },
    diet: {
      type: String,
      required: false,
    },
    documentNumber: {
      type: String,
      required: false,
    },
    documentType: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    language: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    observations: {
      type: String,
      required: false,
    },
    paxId: {
      type: Number,
      required: false,
      default: 0,
    },
    phone: {
      type: String,
      required: false,
    },
    surname1: {
      type: String,
      required: false,
    },
    surname2: {
      type: String,
      required: false,
    },
    weight: {
      type: String,
      required: false,
    },
  },
  emits: [
    'isValid',
    'update:addressCity',
    'update:addressCountry',
    'update:addressCp',
    'update:addressState',
    'update:addressStreet',
    'update:allergies',
    'update:arrivalDate',
    'update:arrivalHour',
    'update:arrivalLocation',
    'update:arrivalNumber',
    'update:arrivalType',
    'update:birthDate',
    'update:bikeType',
    'update:departureDate',
    'update:departureHour',
    'update:departureLocation',
    'update:departureNumber',
    'update:departureType',
    'update:diet',
    'update:documentNumber',
    'update:documentType',
    'update:email',
    'update:height',
    'update:language',
    'update:name',
    'update:observations',
    'update:paxId',
    'update:phone',
    'update:surname1',
    'update:surname2',
    'update:weight',
    'updatePaxs',
  ],
  setup(props) {
    const { t } = useI18n();
    const appStore = useAppStore();

    const dietTypes = ref(computed(() => (!!appStore.concepts && !!appStore.concepts['pax.diet_type']
      ? mapConcepts(appStore.concepts['pax.diet_type']) : [])));

    const arrivalTypes = ref(computed(() => (!!appStore.concepts && !!appStore.concepts['pax.arrival_type']
      ? mapConcepts(appStore.concepts['pax.arrival_type']) : [])));

    const dietOther = ref('');
    const arrivalTypeOther = ref('');
    const departureTypeOther = ref('');

    const rules = yup.object({
      nameLocal: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.name')),
      surnameLocal: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.surname')),
      surnameSecondLocal: yup.string()
        .nullable()
        .label(t('general.shared.secondSurname')),
      emailLocal: yup.string()
        .email()
        .nullable()
        .label(t('general.shared.email')),
      countryCodeLocal: yup.string()
        .nullable()
        .label(t('general.shared.countryCode')),
      phoneLocal: yup.string()
        .nullable()
        .label(t('general.shared.phone')),
      documentTypeLocal: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.documentType')),
      documentNumberLocal: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.documentNumber')),
      addressStreetLocal: yup.string()
        .nullable()
        .label(t('general.address.streetAddress')),
      addressCityLocal: yup.string()
        .nullable()
        .label(t('general.address.city')),
      addressCpLocal: yup.string()
        .nullable()
        .label(t('general.address.postalCode')),
      addressStateLocal: yup.string()
        .nullable()
        .label(t('general.address.provinceStateRegion')),
      addressCountryLocal: yup.string()
        .nullable()
        .label(t('general.address.country')),
      birthDateLocal: yup.string()
        .nullable()
        .label(t('general.shared.dateBirth')),
      languageLocal: yup.string()
        .nullable()
        .label(t('general.shared.language')),
      observationsLocal: yup.string()
        .nullable()
        .label(t('general.shared.supplier_observations')),
      allergiesCheckLocal: yup.bool()
        .oneOf([true, false], t('traveler.allergies')),
      allergiesLocal: yup.lazy((value, options) => {
        const rule = yup.string()
          .label(t('traveler.allergies'));

        if (options.parent.allergiesCheckLocal) {
          return rule.required();
        }

        return rule.nullable();
      }),
      dietLocal: yup.string()
        .nullable()
        .label(t('traveler.diet')),
      bikeLocal: yup.bool()
        .oneOf([true, false], t('traveler.bike')),
      heightLocal: yup.string()
        .nullable()
        .label(t('traveler.height')),
      weightLocal: yup.string()
        .nullable()
        .label(t('traveler.weight')),
      bikeTypeLocal: yup.string()
        .nullable()
        .label(t('traveler.bikeType')),
      arrivalTypeLocal: yup.string()
        .nullable()
        .label(t('traveler.arrivalType')),
      arrivalNumberLocal: yup.string()
        .nullable()
        .label(t('traveler.arrivalNumber')),
      arrivalDateLocal: yup.string()
        .nullable()
        .label(t('traveler.arrivalDate')),
      arrivalHourLocal: yup.string()
        .nullable()
        .label(t('traveler.arrivalHour')),
      arrivalLocationLocal: yup.string()
        .nullable()
        .label(t('traveler.arrivalLocation')),
      departureTypeLocal: yup.string()
        .nullable()
        .label(t('traveler.departureType')),
      departureNumberLocal: yup.string()
        .nullable()
        .label(t('traveler.departureNumber')),
      departureDateLocal: yup.string()
        .nullable()
        .label(t('traveler.departureDate')),
      departureHourLocal: yup.string()
        .nullable()
        .label(t('traveler.departureHour')),
      departureLocationLocal: yup.string()
        .nullable()
        .label(t('traveler.departureLocation')),
    });

    const initialValues = {
      nameLocal: props.name,
      surnameLocal: props.surname1,
      surnameSecondLocal: props.surname2,
      emailLocal: props.email,
      countryCodeLocal: '',
      phoneLocal: '',
      documentTypeLocal: props.documentType,
      documentNumberLocal: props.documentNumber,
      addressStreetLocal: props.addressStreet,
      addressCityLocal: props.addressCity,
      addressCpLocal: props.addressCp,
      addressStateLocal: props.addressState,
      addressCountryLocal: props.addressCountry,
      birthDateLocal: props.birthDate,
      languageLocal: props.language,
      observationsLocal: props.observations,
      allergiesCheckLocal: !!props.allergies,
      allergiesLocal: props.allergies,
      dietLocal: !!props.diet ? props.diet : 'diet-no',
      bikeLocal: !!props.height || !!props.weight || !!props.bikeType,
      heightLocal: props.height,
      weightLocal: props.weight,
      bikeTypeLocal: props.bikeType,
      arrivalTypeLocal: props.arrivalType,
      arrivalNumberLocal: props.arrivalNumber,
      arrivalDateLocal: props.arrivalDate,
      arrivalHourLocal: props.arrivalHour,
      arrivalLocationLocal: props.arrivalLocation,
      departureTypeLocal: props.departureType,
      departureNumberLocal: props.departureNumber,
      departureDateLocal: props.departureDate,
      departureHourLocal: props.departureHour,
      departureLocationLocal: props.departureLocation,
    };

    if (!!props.diet) {
      const dietTypesKeys = dietTypes.value.map((type: any) => type.key);
      if (!dietTypesKeys.includes(props.diet) || props.diet === 'diet-no') {
        initialValues.dietLocal = 'other_diet';
        // eslint-disable-next-line vue/no-setup-props-destructure
        dietOther.value = props.diet;
      }
    }

    if (!!props.phone) {
      const tmpWholePhone: string[] = (props.phone as string).split(/\s+/);
      if (!!tmpWholePhone) {
        const [tmpCode, tmpNumber] = tmpWholePhone;

        initialValues.countryCodeLocal = tmpCode;
        initialValues.phoneLocal = tmpNumber;
      }
    }

    const arrivalTypesKeys = arrivalTypes.value.map((type: any) => type.key);

    if (!!props.arrivalType) {
      if (!arrivalTypesKeys.includes(props.arrivalType)) {
        initialValues.arrivalTypeLocal = 'other_arrival_type';
        // eslint-disable-next-line vue/no-setup-props-destructure
        arrivalTypeOther.value = props.arrivalType;
      }
    }

    if (!!props.departureType) {
      if (!arrivalTypesKeys.includes(props.departureType)) {
        initialValues.departureTypeLocal = 'other_arrival_type';
        // eslint-disable-next-line vue/no-setup-props-destructure
        departureTypeOther.value = props.departureType;
      }
    }

    const form = useFormValidation(rules, initialValues);

    const { value: nameLocal } = useField('nameLocal');
    const { value: surnameLocal } = useField('surnameLocal');
    const { value: surnameSecondLocal } = useField('surnameSecondLocal');
    const { value: emailLocal } = useField('emailLocal');
    const { value: countryCodeLocal } = useField('countryCodeLocal');
    const { value: phoneLocal } = useField('phoneLocal');
    const { value: documentTypeLocal } = useField('documentTypeLocal');
    const { value: documentNumberLocal } = useField('documentNumberLocal');
    const { value: addressStreetLocal } = useField('addressStreetLocal');
    const { value: addressCityLocal } = useField('addressCityLocal');
    const { value: addressCpLocal } = useField('addressCpLocal');
    const { value: addressStateLocal } = useField('addressStateLocal');
    const { value: addressCountryLocal } = useField('addressCountryLocal');
    const { value: birthDateLocal } = useField('birthDateLocal');
    const { value: languageLocal } = useField('languageLocal');
    const { value: observationsLocal } = useField('observationsLocal');
    const { value: allergiesCheckLocal } = useField('allergiesCheckLocal');
    const { value: allergiesLocal } = useField('allergiesLocal');
    const { value: dietLocal } = useField('dietLocal');
    const { value: bikeLocal } = useField('bikeLocal');
    const { value: heightLocal } = useField('heightLocal');
    const { value: weightLocal } = useField('weightLocal');
    const { value: bikeTypeLocal } = useField('bikeTypeLocal');
    const { value: arrivalTypeLocal } = useField('arrivalTypeLocal');
    const { value: arrivalNumberLocal } = useField('arrivalNumberLocal');
    const { value: arrivalDateLocal } = useField('arrivalDateLocal');
    const { value: arrivalHourLocal } = useField('arrivalHourLocal');
    const { value: arrivalLocationLocal } = useField('arrivalLocationLocal');
    const { value: departureTypeLocal } = useField('departureTypeLocal');
    const { value: departureNumberLocal } = useField('departureNumberLocal');
    const { value: departureDateLocal } = useField('departureDateLocal');
    const { value: departureHourLocal } = useField('departureHourLocal');
    const { value: departureLocationLocal } = useField('departureLocationLocal');

    return {
      ...useConcepts(),
      ...form,
      addressCityLocal,
      addressCountryLocal,
      addressCpLocal,
      addressStateLocal,
      addressStreetLocal,
      allergiesCheckLocal,
      allergiesLocal,
      arrivalDateLocal,
      arrivalHourLocal,
      arrivalLocationLocal,
      arrivalNumberLocal,
      arrivalTypeLocal,
      birthDateLocal,
      bikeLocal,
      bikeTypeLocal,
      countryCodeLocal,
      departureDateLocal,
      departureHourLocal,
      departureLocationLocal,
      departureNumberLocal,
      departureTypeLocal,
      dietLocal,
      documentNumberLocal,
      documentTypeLocal,
      emailLocal,
      heightLocal,
      languageLocal,
      nameLocal,
      observationsLocal,
      phoneLocal,
      surnameLocal,
      surnameSecondLocal,
      weightLocal,
      dietOther,
      arrivalTypeOther,
      departureTypeOther,
    };
  },
  computed: {
    isValid() {
      return this.meta.valid && this.meta.dirty;
    },
    documentTypeOptions(): any {
      return !!this.concepts && this.concepts['pax.document_type'] ? mapConcepts(this.concepts['pax.document_type']) : [];
    },
    languageOptions(): any {
      return !!this.concepts && this.concepts['pax.language_type'] ? mapConcepts(this.concepts['pax.language_type']) : [];
    },
    dietOptions(): any {
      return !!this.concepts && this.concepts['pax.diet_type'] ? mapConcepts(this.concepts['pax.diet_type']) : [];
    },
    bikeTypeOptions(): any {
      return !!this.concepts && this.concepts['pax.bike_type'] ? mapConcepts(this.concepts['pax.bike_type']) : [];
    },
    countryCodeOptions(): any {
      return !!this.concepts && this.concepts['app.countryPhones'] ? mapConcepts(this.concepts['app.countryPhones']) : [];
    },
    arrivalTypeOptions(): any {
      return !!this.concepts && this.concepts['pax.arrival_type'] ? mapConcepts(this.concepts['pax.arrival_type']) : [];
    },
  },
  watch: {
    isValid(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('isValid', newValue);
      }
    },
  },
  methods: {
    setAddress(address: any) {
      this.addressStreetLocal = address.name;
      this.addressCountryLocal = address.country.longName;
      this.addressCityLocal = address.locality.longName;
      this.addressStateLocal = address.regionProvince.longName;
      this.addressCpLocal = address.postalCode.longName;

      this.$emit('update:addressStreet', this.addressStreetLocal);
      this.$emit('update:addressCountry', this.addressCountryLocal);
      this.$emit('update:addressCity', this.addressCityLocal);
      this.$emit('update:addressState', this.addressStateLocal);
      this.$emit('update:addressCp', this.addressCpLocal);

      this.updatePax();
    },
    setPhoneFormat() {
      let phoneTmp = '';

      if (!!this.countryCodeLocal && !!this.phoneLocal) {
        phoneTmp = `${this.countryCodeLocal} ${this.phoneLocal.toString()
          .replace(/\s/g, '')}`;
      }

      this.$emit('update:phone', phoneTmp);

      this.updatePax();
    },
    async updatePax() {
      const { valid } = await this.validate();

      if (valid) {
        this.$emit('updatePaxs');
      }
    },
  },
});
</script>
