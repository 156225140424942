<template>
  <div v-if="!agency.deletedAt" class="detail">
    <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
      <div class="fw-medium">{{ $t('admin.collaborator.template.title') }}</div>

      <v-button
        class="btn-edit p-4"
        variant="icon"
        @click="openModal('the-admin-collaborator-add-templates-modal')">
        <v-icon icon="circle-plus"/>
      </v-button>
    </div>

    <div v-if="!!agency" class="detail-content">
      <div class="products-container card-item-list">
        <div v-if="!agency.templates || agency.templates.length === 0" class="text-sm">
          {{ $t('admin.collaborator.template.noTemplates') }}
        </div>

        <div
          v-for="template in agency.templates"
          :key="template.id"
          class="contact item-card border rounded-xs d-flex justify-content-between align-items-center
          p-8 p-lg-12 mb-8"
        >
          <div class="d-flex align-items-center w-100 me-8">
            <div class="image-container rounded-sm bg-tertiary me-12">
              {{ template.name?.charAt(0) }}
            </div>

            <div
              class="w-100 d-flex flex-column text-sm">
              <div class="fw-medium">{{ template.name }}</div>

              <div class="text-sm fw-light">
                {{ findConceptByKey('tour.travel_type', template.travelType).name }}
              </div>

              <div class="locations d-flex align-items-center text-xs fw-light mb-4">
                <div v-if="!!template.origin && !!template.origin.name">{{ template.origin.name }}</div>
                <div v-else>{{ $t('traveler.withoutOrigin') }}</div>

                <v-icon icon="arrow-right" size="xs" space="px-8"/>

                <div v-if="!!template.destination && !!template.destination.name">{{ template.destination.name }}</div>
                <div v-else>{{ $t('traveler.withoutDestination') }}</div>
              </div>
            </div>
          </div>

          <div class="d-flex">
            <router-link
              :aria-label="template.name"
              :to="{
                name: 'template.show',
                params: {
                  templateId: template.id,
                },
              }"
              class="btn btn-icon shadow-none p-2"
            >
              <v-icon icon="eye"/>
            </router-link>

            <v-button
              :disabled="disableUnassignTemplateBtn"
              class="shadow-none p-2"
              variant="icon"
              @click="unassignTemplate(template)"
            >
              <v-icon icon="trash"/>
            </v-button>
          </div>
        </div>
      </div>
    </div>

    <the-admin-collaborator-add-templates-modal
      v-if="modalToShow === 'the-admin-collaborator-add-templates-modal'"
      :agency="agency"
      @closed="closeModal"
      @emit-reload-agency="reloadAgency"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useModalUtils from '@/helpers/ModalUtils';
import Agency from '@/api/objects/Agency';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import Template from '@/api/objects/Template';
import api from '@/api';
import TheAdminCollaboratorAddTemplatesModal from '@/components/admin/collaborator/TheAdminCollaboratorAddTemplatesModal.vue';

export default defineComponent({
  name: 'TheAdminAgencyDetailsTemplates',
  components: {
    TheAdminCollaboratorAddTemplatesModal,
    VIcon,
    VButton,
  },
  emits: [
    'closed',
    'reloadAgency',
  ],
  props: {
    agency: {
      type: [Agency, Object],
      required: false,
    },
  },
  setup() {
    return {
      ...useModalUtils(),
      findConceptByKey,
    };
  },
  data() {
    return {
      disableUnassignTemplateBtn: false as boolean,
    };
  },
  methods: {
    async unassignTemplate(template: Template) {
      await this.$modal.delete({
        title: this.$t('admin.collaborator.template.detachTemplateFromCollaboratorTitle'),
        text: this.$t('admin.collaborator.template.detachTemplateFromCollaboratorTitleText', {
          template: template.name,
          agency: this.agency?.name,
        }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doUnassignTemplate(template.id),
      });
    },
    async doUnassignTemplate(templateId: string | number) {
      try {
        this.disableUnassignTemplateBtn = true;

        const data: any = {
          templates: [templateId],
        };

        if (!!this.agency) {
          await api.admin.agency.detachTemplates(this.agency.id, data);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          await this.reloadAgency();
        }
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableUnassignTemplateBtn = false;
      }
    },
    async reloadAgency() {
      this.$emit('reloadAgency');
    },
  },
});
</script>
