import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface SupplierResourcePriceContract extends IBaseObject {
  category: string;
  type: string;
  initDate: string;
  endDate: string;
  price: string;
  supplierResourceId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export default class SupplierResourcePrice extends BaseObject implements SupplierResourcePriceContract {
  public declare category: string;
  public declare type: string;
  public declare initDate: string;
  public declare endDate: string;
  public declare price: string;
  public declare supplierResourceId: number;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;

  constructor(data: SupplierResourcePriceContract) {
    super(data);
  }
}
