import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import AgencyUser from '@/api/objects/AgencyUser';
import Client from '@/api/objects/Client';
import Tour from '@/api/objects/Tour';

export interface ClientTourContract extends IBaseObject {
  aaa: any;
  assignedToId: number;
  assignedTo: AgencyUser;
  name: string;
  initDate: string;
  endDate: string;
  alternateStartDate: string;
  paxNumber: number;
  type: string;
  clientId: number;
  agencyUserId: number;
  termsConditionsText: string;
  complementaryText: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  client: Client;
  agencyUser: AgencyUser;
  tours: Tour[];
  translations: any;
  additionalRequirements: string;
  otherRoomRequirements: string;
}

export default class ClientTour extends BaseObject implements ClientTourContract {
  public declare aaa: any;
  public declare assignedToId: number;
  public declare assignedTo: AgencyUser;
  public declare name: string;
  public declare initDate: string;
  public declare endDate: string;
  public declare alternateStartDate: string;
  public declare paxNumber: number;
  public declare type: string;
  public declare clientId: number;
  public declare agencyUserId: number;
  public declare termsConditionsText: string;
  public declare complementaryText: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare client: Client;
  public declare agencyUser: AgencyUser;
  public declare tours: Tour[];
  public declare translations: any;
  public declare additionalRequirements: string;
  public declare otherRoomRequirements: string;

  constructor(data: ClientTourContract) {
    super(data);
    this.aaa = 'hoa';
  }
}
