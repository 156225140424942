<template>
  <div class="star-rating">
    <label
      v-for="(rating, index) in ratings"
      :key="index"
      class="star"
      v-bind:class="{ 'is-selected': ((localValue >= rating) && localValue != null), 'is-disabled': disabled }"
      @click="set(rating)"
    >
      <input
        v-bind:id="index + 1"
        v-model="localValue"
        class="star-rating star-rating__checkbox"
        type="radio"
        v-bind:disabled="disabled"
        v-bind:name="name"
        v-bind:required="required"
        v-bind:value="rating">
      <v-icon :class="iconClass" :icon="icon" :size="iconSize" :space="iconSpace"/>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';

export default defineComponent({
  name: 'VStarRating',
  components: {
    VIcon,
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    value: {
      type: Number,
      required: false,
    },
    modelValue: {
      type: Number,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    iconClass: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: 'close',
    },
    iconSize: {
      type: String,
      required: false,
    },
    iconSpace: {
      type: String,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      localValue: this.modelValue,
      ratings: [1, 2, 3, 4],
    };
  },
  watch: {
    modelValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localValue = newVal;
      }
    },
  },
  methods: {
    set(value: number) {
      if (this.disabled) {
        return;
      }

      this.localValue = value;

      this.$emit('update:modelValue', value);
    },
  },
});
</script>
