<template>
  <div class="form-outline">
    <label class="label">
      <span>{{ $t('admin.collaborator.addExistingUser') }}</span>
    </label>

    <v-select
      v-model="userSelected"
      :clearable="false"
      :filterable="false"
      :loading="assigningAgencyUser"
      :options="users"
      :selectable="(option) => !!selectedOptions
        ? !selectedOptions.some((selectedOption) => selectedOption.id === option.id) : null"
      class="select-search"
      label="name"
      @search="onSearch"
      @option:selecting="assignAgencyUser"
    >
      <template v-slot:no-options>
        {{ $t('general.shared.writeSearchUsers') }}
      </template>

      <template v-slot:option="option">
        <div class="d-center">
          <span>{{ option?.name }}</span>

          <span v-if="!!option?.email" class="text-sm fw-light ms-4">
            ({{ option.email }})
          </span>
        </div>
      </template>

      <template v-slot:selected-option="option">
        <div class="selected d-center py-8">
          <span>{{ option?.name }}</span>

          <span v-if="!!option?.email" class="text-sm fw-light ms-4">
            ({{ option.email }})
          </span>
        </div>
      </template>

      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"><v-icon icon="search" size="sm"/></span>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import vSelect from 'vue-select';
import { mapState } from 'pinia';
import Agency from '@/api/objects/Agency';
import api from '@/api';
import User from '@/api/objects/User';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'VSelectUserSearch',
  components: {
    VIcon,
    vSelect,
  },
  props: {
    agency: {
      type: Agency,
      required: true,
    },
    selectedOptions: {
      type: Array as PropType<User[]>,
      required: false,
    },
  },
  emits: ['userAttached'],
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
    };
  },
  data() {
    return {
      userSelected: null as User | null,
      users: [] as User[],
      assigningAgencyUser: false as boolean,
      location: '' as string,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
  },
  methods: {
    onSearch(search: string, loading: any) {
      if (search.length) {
        loading(true);
        this.loadUsers(loading, search);
      }
    },
    async loadUsers(loading: any, search: string) {
      loading(true);

      try {
        const query = { filters: { mix: search } };

        this.users = await api.admin.user.all(query);
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        loading(false);
      }
    },
    async assignAgencyUser(user: User) {
      await this.$modal.confirm({
        title: this.$t('admin.collaborator.addCollaboratorUserTitle'),
        text: this.$t('admin.collaborator.addCollaboratorUserText', { user: user.name, agency: this.agency.name }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.add'),
        confirmButtonCallback: this.doAssignAgencyUser,
      });

      return { error: true };
    },
    async doAssignAgencyUser() {
      try {
        this.assigningAgencyUser = true;

        if (!!this.userSelected) {
          await api.admin.agency.attachUser(this.agency.id, this.userSelected.id);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.user = null;

          this.assigningAgencyUser = false;

          this.$emit('userAttached');

          if (this.userSelected.id === this.user?.id) {
            await this.authStore.setAuthUser();
          }
        }
      } catch (e: any) {
        this.assigningAgencyUser = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
