import { AbstractService } from '@uniqoders/sdk';
import Contact from '@/api/objects/Contact';

export default class ContactService extends AbstractService {
  /**
   * Retrieves all Contacts
   * @protected
   * @param params
   */
  public all(params: any): Promise<Contact[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/contacts'),
      params,
    });
  }

  /**
   * Retrieves all Contacts paginated
   * @protected
   * @param params
   * @param paginate
   */
  public allPaginated(params: any, paginate = true): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/contacts'),
      params,
      options: {
        paginate,
      },
    });
  }

  /**
   * Retrieves a specific Contact
   * @protected
   */
  public retrieve(contact: string | number): Promise<Contact> {
    return this.request({
      method: 'get',
      url: this.buildPath('/contact/{contact}', { contact }),
    });
  }

  /**
   * Creates a single Contact
   * @protected
   * @param data
   */
  public create(data: Record<string, any>): Promise<Contact> {
    return this.request({
      method: 'post',
      url: this.buildPath('/contact'),
      data,
    });
  }

  /**
   * Updates the specified Contact
   * @param contact
   * @param data
   * @protected
   */
  public update(contact: string | number, data: Record<string, any>): Promise<Contact> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/contact/{contact}', { contact }),
      data,
    });
  }

  /**
   * Archives the specified Contact
   * @protected
   * @param contact
   */
  public archive(contact: string | number): Promise<Contact> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/contact/{contact}', { contact }),
    });
  }

  /**
   * Restore the specified Contact
   * @protected
   * @param contact
   */
  public restore(contact: string | number): Promise<Contact> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/contact/{contact}/restore', { contact }),
    });
  }

  /**
   * Deletes the specified Contact
   * @protected
   * @param contact
   */
  public delete(contact: string | number): Promise<Contact> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/contact/{contact}/force', { contact }),
    });
  }
}
