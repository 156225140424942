/**
 * To call a function after the provided milliseconds in timeout. The timer is reset when it is
 * called again and it didn't fire. (!) The 'timer' variable must be assigned to the local 'timer'
 * in where this 'withTimeout' function is used. If I use this in a .vue component, I declare a
 * 'let timer = null' in that .vue component, then I use:
 * timer = withTimeout(function() { console.log('hello'); }, timer, 250).
 * If that timer is used again in another 'withTimeout' call, it will restart (so if no timeout
 * passed, it won't fire the first call).
 * @param theFunction
 * @param timer
 * @param timeout
 * @returns {number}
 */

let withTimeout: (theFunction: any, timer: any, timeout?: number) => any;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default withTimeout = (theFunction: any, timer: any, timeout = 1000) => {
  window.clearTimeout(timer);
  // eslint-disable-next-line no-param-reassign
  timer = window.setTimeout(() => {
    theFunction();
  }, timeout);

  return timer;
};
