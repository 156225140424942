<template>
  <div class="reservation-confirm-container">
    <main class="bg-tertiary-faded">
      <div class="container">
        <div class="min-vh-100 pb-48 pt-140 pt-sm-120 pt-lg-96 d-flex align-items-center">
          <div class="w-100 bg-light rounded-xs border-0 py-32 px-24 p-lg-56">
            <Transition mode="out-in" name="fade-in">
              <div v-if="status === 'loading'" class="position-relative">
                <h1 class="h4 text-center mb-32 mb-lg-56">{{ $t('general.pages.confirmingReservation') }}</h1>

                <div class="position-relative py-48">
                  <v-loader/>
                </div>
              </div>

              <h1 v-else class="h4 text-center">{{ $t('general.pages.reservationConfirmed') }}</h1>
            </Transition>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import api from '@/api';

export default defineComponent({
  name: 'TheReservationConfirm',
  components: { VLoader },
  data() {
    return {
      status: 'loading' as string,
    };
  },
  created() {
    this.acceptReservation();
  },
  methods: {
    async acceptReservation() {
      try {
        await api.mandrill.accept(this.$router.currentRoute.value.query);
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        setTimeout(async () => {
          this.status = 'loaded';
        }, 1000);
      }
    },
  },
});
</script>
