/* eslint-disable */
import { DateTime } from 'luxon';

class DateHelper {
  /**
   * Recognized date formats.
   *
   * @type {{ISO: string}}
   */
  protected dateFormats: {
    [key: string]: string;
  } = {
    ISO: 'iso',
    RFC2822: 'rfc2822',
    HTTP: 'http',
    SQL: 'sql',
    MILLISECONDS: 'milliseconds',
    SECONDS: 'seconds',
  };

  /**
   * Recognized and accepted RegExps for each date format.
   *
   * @type {{ISO}}
   */
  protected regExps: {
    [key: string]: RegExp[];
  } = {
    ISO: [
      /^\d{4}$/, // 2016
      /^\d{4}-\d{2}$/, // 2016-05
      /^\d{6}$/, // 201605
      /^\d{4}-\d{2}-\d{2}$/, // 2016-05-25
      /^\d{8}$/, // 20160525
      /^\d{4}-\d{2}-\d{2}T\d{2}$/, // 2016-05-25T09
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/, // 2016-05-25T09:24
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}\.\d{3}$/, // 2016-05-25T09:24.123
      /^\d{4}-\d{2}-\d{2}T\d{4}$/, // 2016-05-25T0924
      /^\d{4}-\d{2}-\d{2}T\d{4}$/, // 2016-05-25T092415
      /^\d{4}-\d{2}-\d{2}T\d{4}\.\d{3}$/, // 2016-05-25T092415.123
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2},\d{3}$/, // 2016-05-25T09:24:15,123
      /^\d{4}-w\d{2}-\d$/, // 2016-W21-3
      /^\d{4}w\d{3}$/, // 2016W213
      /^\d{4}-w\d{2}-\dT\d{2}:\d{2}:\d{2}\.\d{3}$/, // 2016-W21-3T09:24:15.123
      /^\d{4}w\d{3}T\d{2}:\d{2}:\d{2}\.\d{3}$/, // 2016W213T09:24:15.123
      /^\d{4}-\d{3}$/, // 2016-200
      /^\d{7}$/, // 2016200
      /^\d{4}-\d{3}T\d{2}:\d{2}:\d{2}\.\d{3}$/, // 2016-200T09:24:15.123
      /^\d{2}:\d{2}$/, // 09:24
      /^\d{2}:\d{2}:\d{2}$/, // 09:24:15
      /^\d{2}:\d{2}:\d{2}\.\d{3}$/, // 09:24:15.123
      /^\d{2}:\d{2}:\d{2},\d{3}$/, // 09:24:15,123

      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.*$/, // 2021-11-09T12:36:00.000000Z
    ],
    RFC2822: [
      /^\w,\s\d{2}\s\w\s\d{4}\s\d{2}:\d{2}:\d{2}\s+\d{4}$/, // Tue, 01 Nov 2016 13:23:12 +0630
    ],
    HTTP: [
      /^\w,\s\d{2}-\w-\d{2}\s\d{2}:\d{2}:\d{2}\s\w$/, // Sunday, 06-Nov-94 08:49:37 GMT
      /^\w,\s\d{2}\s\w\s\d{4}\s\d{2}:\d{2}:\d{2}\s\w$/, // Sun, 06 Nov 1994 08:49:37 GMT
    ],
    SQL: [
      /^\d{4}-\d{2}-\d{2}$/, // 2017-05-15
      /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, // 2017-05-15 09:24:15
      /^\d{2}:\d{2}:\d{2}$/, // 09:24:15
    ],
    MILLISECONDS: [
      /^\d+$/, // 1542674993410
    ],
    SECONDS: [
      /^\d+$/, // 1542674993
    ],
  };

  /**
   * Iterates over the provided date and recognizes a valid format if existing.
   *
   * @param date
   * @returns {null|*}
   * @private
   */
  protected getDateFormat(date: any) {
    const {
      dateFormats,
      regExps,
    } = this;

    return Object.keys(regExps)
      .map((key: string) => {
        if (regExps[key].some((regx) => regx.test(date))) {
          return dateFormats[key];
        }

        return null;
      })
      .find((item) => !!item);
  }

  /**
   * Turns any provided date into a DateTime from luxon.
   *
   * @param originalDate
   */
  public parse(originalDate: any): DateTime {
    let ret = null;

    let date = originalDate;

    // TODO: Add checkings to fix
    if (date instanceof Date) {
      // Handle js dates that are not in seconds
      date = date.getSeconds();
    }

    const format = this.getDateFormat(date);

    switch (format) {
      case this.dateFormats.ISO:
        ret = DateTime.fromISO(date);
        break;
      case this.dateFormats.RFC2822:
        ret = DateTime.fromRFC2822(date);
        break;
      case this.dateFormats.HTTP:
        ret = DateTime.fromHTTP(date);
        break;
      case this.dateFormats.SQL:
        ret = DateTime.fromSQL(date);
        break;
      case this.dateFormats.MILLISECONDS:
        ret = DateTime.fromMillis(date);
        break;
      case this.dateFormats.SECONDS:
        ret = DateTime.fromSeconds(date);
        break;
      // TODO: Add more types
      default:
        throw Error(`Unknown format ${JSON.stringify(format)}`);
    }

    return ret;
  }
}

export default new DateHelper();
