import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import Media from '@/api/objects/Media';
import Tour from '@/api/objects/Tour';
import InvoiceLine from '@/api/objects/InvoiceLine';

export interface InvoiceContract extends IBaseObject {
  amount: string;
  city: string;
  country: string;
  holdedId: string;
  idIdentity: string;
  invoiceStatus: string;
  line1: string;
  line2: string;
  number: string;
  paymentStatus: string;
  postalCode: string;
  state: string;
  tourId: string;
  vat: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  media: Media[];
  tour: Tour;
  invoiceLines: InvoiceLine[];
}

export default class Invoice extends BaseObject implements InvoiceContract {
  public declare amount: string;
  public declare city: string;
  public declare country: string;
  public declare holdedId: string;
  public declare idIdentity: string;
  public declare invoiceStatus: string;
  public declare line1: string;
  public declare line2: string;
  public declare number: string;
  public declare paymentStatus: string;
  public declare postalCode: string;
  public declare state: string;
  public declare tourId: string;
  public declare vat: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare media: Media[];
  public declare tour: Tour;
  public declare invoiceLines: InvoiceLine[];

  constructor(data: InvoiceContract) {
    super(data);
  }
}
