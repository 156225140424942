import { ref } from 'vue';
import { useForm } from 'vee-validate';

const useFormValidation = (schema: any, initialValues?: any) => {
  const {
    meta,
    values,
    errors,
    setErrors,
    setFieldError,
    resetForm,
    resetField,
    setValues,
    setFieldValue,
    validate,
  } = useForm({
    validationSchema: schema,
    initialValues,
  });

  const accepted = ref(false);

  const toggleAccepted = () => {
    accepted.value = !accepted.value;
  };

  return {
    meta,
    values,
    errors,
    accepted,
    setErrors,
    resetField,
    setFieldError,
    setFieldValue,
    resetForm,
    setValues,
    validate,
    toggleAccepted,
  };
};

export default useFormValidation;
