import { AbstractService } from '@uniqoders/sdk';
import Supplier from '@/api/objects/Supplier';

export default class SupplierService extends AbstractService {
  /**
   * Retrieves all Suppliers
   * @protected
   * @param params
   */
  public all(params: any): Promise<Supplier[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/suppliers'),
      params,
    });
  }

    /**
   * Retrieves all suppliers.
   *
   * @return {Promise<Supplier[]>} Promise that resolves with an array of Supplier objects
   */
  public getAllSuppliers(): Promise<Supplier[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/get-all-suppliers'),
    });
  }

  /**
   * Retrieves all Suppliers paginated
   * @protected
   * @param params
   * @param paginate
   */
  public allPaginated(params: any, paginate = true): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/suppliers'),
      params,
      options: {
        paginate,
      },
    });
  }

  /**
   * Retrieves a specific Supplier
   * @protected
   */
  public retrieve(supplier: string | number): Promise<Supplier> {
    return this.request({
      method: 'get',
      url: this.buildPath('/supplier/{supplier}', { supplier }),
    });
  }

  /**
   * Creates a single Supplier
   * @protected
   * @param data
   */
  public create(data: Record<string, any>): Promise<Supplier> {
    return this.request({
      method: 'post',
      url: this.buildPath('/supplier'),
      data,
    });
  }

  /**
   * Updates the specified Supplier
   * @param supplier
   * @param data
   * @protected
   */
  public update(supplier: string | number, data: Record<string, any>): Promise<Supplier> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/supplier/{supplier}', { supplier }),
      data,
    });
  }

  /**
   * Archives the specified Supplier
   * @protected
   * @param supplier
   */
  public archive(supplier: string | number): Promise<Supplier> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/supplier/{supplier}', { supplier }),
    });
  }

  /**
   * Restore the specified Supplier
   * @protected
   * @param supplier
   */
  public restore(supplier: string | number): Promise<Supplier> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/supplier/{supplier}/restore', { supplier }),
    });
  }

  /**
   * Deletes the specified Supplier
   * @protected
   * @param supplier
   */
  public delete(supplier: string | number): Promise<Supplier> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/supplier/{supplier}/force', { supplier }),
    });
  }

  /**
   * Retrieves Hotel Booking Data
   * @protected
   * @param supplierId
   * @param params
   */
  public getHotelBookingData(supplierId: number | string, params: any): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/supplier-booking-dates/{supplierId}', { supplierId }),
      params,
    });
  }

  /**
   * Exports suppliers booking to google sheet
   * @protected
   * @param params
   */
  public exportToGoogleSheet(params: any): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/export-suppliers-booking-info'),
      params,
    });
  }
}
