import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast from 'vue-toastification';
import * as Popper from '@popperjs/core';
import {
  Button, Collapse, Dropdown, Modal, Offcanvas,
} from 'bootstrap';
import createToast from '@/helpers/Toast';
import createModal from '@/helpers/Modal';
import createFormatter from '@/helpers/Str';
import router from '@/router';
import i18n from '@/lang';
import initSentry from '@/helpers/Sentry';
import App from '@/App.vue';
import 'core-js/actual';

import '../resources/scss/app.scss';

window.Popper = Popper;
window.Collapse = Collapse;
window.Offcanvas = Offcanvas;
window.Dropdown = Dropdown;
window.Button = Button;
window.Modal = Modal;

const pinia = createPinia();
const app = createApp(App);
const locale = !!localStorage.getItem('language') ? localStorage.getItem('language') : import.meta.env.VITE_APP_DEFAULT_LOCALE;

app.provide('tinymce_key', import.meta.env.VITE_APP_TINYMCE_KEY);

app.use(Toast);
app.use(i18n);
app.use(pinia);
app.use(router);
app.use(createModal);
app.use(createToast);

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  initSentry(app);
}

app.use(createFormatter, {
  locale: !!locale ? locale : 'en',
});

app.mount('#app');
