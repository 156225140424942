export default {
  fileTypeNotAllowed: 'Debe cargar un archivo del tipo',
  tooLarge: 'Archivo demasiado grande, max',
  tooSmall: 'Archivo demasiado pequeño, min',
  tryAgain: 'Por favor, intente subir este archivo de nuevo',
  somethingWentWrong: 'Algo ha ido mal al subir este archivo',
  selectOrDragMax: 'Selecciona o arrastra max {maxItems} {file}',
  selectOrDrag: 'Selecciona o arrastra archivos',
  file: { singular: 'archivo', plural: 'archivos' },
  anyImage: 'cualquier imagen',
  anyVideo: 'cualquier vídeo',
  goBack: 'Volver atrás',
  dropFile: 'Soltar archivo para subir',
  dragHere: 'Arrastrar archivo aquí',
  remove: 'Eliminar',
  name: 'Nombre',
  download: 'Descargar',
};
