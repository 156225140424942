<template>
  <div v-if="language === currentLanguage" class="form-outline">
    <label :class="{ disabled: disabledFields }" class="label">
      <span>{{ $t('general.shared.description') }}</span>
    </label>

    <v-html-editor
      id="tour-day-update-description"
      ref="tour-day-update-description-ref"
      :disabled="disabledFields"
      :value="descriptionLocal"
      @input="descriptionLocal = $event; $emit('update:description', descriptionLocal)"
      @media-uploaded="$emit('mediaUploaded', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import useFormValidation from '@/helpers/form';
import useConcepts from '@/helpers/Concepts';
import VHtmlEditor from '@/components/vendor/htmlEditor/VHtmlEditor.vue';

export default defineComponent({
  name: 'TheTourDayUpdateBasicInformationFieldset',
  components: {
    VHtmlEditor,
  },
  emits: [
    'closed',
    'reloadResources',
    'update:description',
    'isValid',
    'mediaUploaded',
  ],
  props: {
    description: {
      type: String,
      required: false,
      default: '',
    },
    disabledFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    language: {
      type: String,
      required: true,
      default: '',
    },
    currentLanguage: {
      type: String,
      required: true,
      default: '',
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      descriptionLocal: yup.string()
        .nullable()
        .label(t('general.shared.description')),
    });

    const initialValues = {
      descriptionLocal: props.description,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: descriptionLocal } = useField('descriptionLocal');

    return {
      ...useConcepts(),
      ...form,
      descriptionLocal,
    };
  },
  computed: {
    isValid() {
      return this.meta.valid && this.meta.dirty;
    },
  },
  watch: {
    description(value) {
      this.descriptionLocal = value;
    },
    clear() {
      this.resetForm();
    },
    isValid(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('isValid', newValue);
      }
    },
  },
});
</script>
