<template>
  <v-modal
    id="admin-collaborator-templates-edit"
    class-name="modal-fullscreen-sm-down"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('admin.collaborator.template.addTemplates') }}
      </modal-title>
    </modal-header>

    <modal-body>
      <v-select-collaborator-template-search
        id="admin-collaborator-template-search"
        :selected-options="agency.templates"
        @selected="assignTemplate"
      />
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import api from '@/api';
import Agency from '@/api/objects/Agency';
import { findConceptByKey, mapConcepts } from '@/helpers/ConceptHelper';
import VSelectCollaboratorTemplateSearch from '@/components/vendor/VSelectCollaboratorTemplateSearch.vue';
import Template from '@/api/objects/Template';
import useFormValidation from '@/helpers/form';
import useConcepts from '@/helpers/Concepts';

export default defineComponent({
  name: 'TheAdminAgencyAddTemplatesModal',
  components: {
    VSelectCollaboratorTemplateSearch,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal
  },
  emits: [
    'closed',
    'emitReloadAgency',
  ],
  props: {
    agency: {
      type: Agency,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      totalDays: yup.number()
        .required()
        .min(1)
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.daysNumber')),
      pax: yup.number()
        .required()
        .min(1)
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.pax')),
      travelType: yup.string()
        .required()
        .label(t('general.shared.client')),
    });

    const initialValues = {
      pax: 1,
      totalDays: 1,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: name } = useField<string>('name');
    const { value: pax } = useField<number>('pax');
    const { value: totalDays } = useField<number>('totalDays');
    const { value: travelType } = useField<string>('travelType');

    return {
      findConceptByKey,
      ...useConcepts(),
      ...form,
      name,
      pax,
      totalDays,
      travelType,
    };
  },
  data() {
    return {
      disableButton: false as boolean,
    };
  },
  computed: {
    travelTypes(): any {
      if (!!this.concepts && this.concepts['tour.travel_type']) {
        return mapConcepts(this.concepts['tour.travel_type']);
      }

      return [];
    },
  },
  methods: {
    async assignTemplate(template: Template) {
      await this.$modal.confirm({
        title: this.$t('admin.collaborator.template.addTemplateToCollaboratorTitle'),
        text: this.$t('admin.collaborator.template.addTemplateToCollaboratorTitleText', {
          template: template.name,
          agency: this.agency.name,
        }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.add'),
        confirmButtonCallback: () => this.doAssignTemplate(template.id),
      });
    },
    async doAssignTemplate(templateId: number) {
      try {
        const data: any = {
          templates: [templateId],
        };

        await api.admin.agency.attachTemplates(this.agency.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.reloadAgency();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async createTemplate() {
      try {
        this.toggleAccepted();
        this.disableButton = true;

        const data: any = {
          name: this.name,
          pax_number: this.pax,
          total_days: this.totalDays,
          travel_type: this.travelType,
        };

        await api.template.createEmpty(this.agency.slug, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.reloadAgency();
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableButton = false;

        this.toggleAccepted();
      }
    },
    async reloadAgency() {
      this.$emit('emitReloadAgency');
    },
  },
});
</script>
