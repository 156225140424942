<template>
  <div class="notification-section ms-24 ms-md-36">
    <div v-if="hasNotifications" class="notification-mark"></div>
    <v-dropdown id="user-header-dropdown" :disable-icon="true" :data-bs-toggle="'dropdown'" :data-bs-auto-close="true" width="350" @click="loadNotifications"
      button-classes="text-sm fw-medium bg-transparent shadow-none p-0 pe-0"
      :dropdown-classes="'dropdown-notification dropdown-menu-end dropdown-menu-notifications w-auto overflow-auto'">
      <template #button>
        <v-icon icon="bell" size="xxl" />
      </template>

      <template #content v-if="notifications.length">
          <li 
            class="dropdown-list text-sm d-flex justify-space-between align-items-center"
            v-for="(notification, index) in notifications" 
            :key="notification?.id" 
            :class="{ 
              'read-noti': notification.isRead, 
              'unread-noti': !notification.isRead 
            }" 
            style="padding: 10px 0px;">

          <div class="char-image-container rounded-sm bg-tertiary mx-12" v-if="notification?.notificationTypeSlug !== 'warning' || notification?.notificationTypeSlug === null">
            <template v-if="!!notification?.appNotificationMessage">
              {{ index + 1 }}
            </template>

            <template v-else>{{ notification.id }}</template>
          </div>
          <div class="char-image-container rounded-sm bg-warning mx-12" v-else>
            <template v-if="!!notification?.appNotificationMessage">
              &#9888;
            </template>
          </div>
          
          <div style="flex:1;">
            <a :aria-label="$t('general.menu.myProfile')"
              class="dropdown-item d-flex align-items-center py-0 px-0 text-wrap text-xs"
              href="#" @click="routeTo(notification, index)"  style="margin-bottom: 8px;">
              {{ notification.tourId }}- {{ notification?.appNotificationMessage }}
            </a>
            <span class="px-0 text-wrap text-xs">
              {{ $str.formatDateTimeRelativeToNow(notification?.createdAt) }}
            </span>
          </div>

          <v-button v-if="!notification.isRead" class="notification-eye text-sm align-slef-start" size="sm"
            variant="outline" data-bs-toggle="tooltip" data-bs-placement="top" :title="'Mark as read'"
            @click="updateNotificationStatus(index, true)">
            <v-icon icon="eye-blocked" size="sm" />
          </v-button>
          <v-button v-else class="notification-eye text-sm" size="sm" variant="outline" data-bs-toggle="tooltip"
            data-bs-placement="top" :title="'Mark as unread'" @click="updateNotificationStatus(index, false)">
            <v-icon icon="eye" />
          </v-button>

          <div v-if="index === notifications?.length - 1" class="dropdown-divider" />
        </li>
      </template>
      <template #content v-else>
        <div class="text-center py-16">
          <v-icon icon="bell" size="xxl" />
          <p class="mb-0">No Notifications</p>
        </div>
      </template>
    </v-dropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useAuthStore } from "@/stores/auth";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import VDropdown from "@/components/vendor/basic/dropdown/VDropdown.vue";
import api from "@/api";
import caseNotifications from "../../api/objects/CaseNotifications";
import VButton from "../vendor/basic/button/VButton.vue";

export default defineComponent({
  name: "TheHeaderNotificationDropdown",
  components: {
    VDropdown,
    VIcon,
    VButton,
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
    };
  },
  data() {
    return {
      hasNotifications: false as boolean,
      loaded: true as boolean,
      notifications: [] as caseNotifications[],
    };
  },
  async created() {
    await this.loadNotifications();
  },
  computed: {
    ...mapState(useAuthStore, ["user"]),
    ...mapState(useAuthStore, ["agency"]),
  },
  methods: {
    async routeTo(notification: any, index: number) {
      await this.updateNotificationStatus(index, true);
      const path = notification.redirectPath === 'builder' ? 'tour.builder.show' : 'case.setup.show';
      await this.$router.push({
        name: path,
        params: { caseId: notification.tour.clientTourId, tourId: notification.tourId },
      });
    },

    async updateNotificationStatus(index: number, isRead) {
      this.notifications[index].isRead = !this.notifications[index].isRead;
      const response = await api.CaseNotifications.updateCaseNotifications(
        this.agency?.slug,
        this.notifications[index].id,
        isRead
      );
      this.loadNotifications();
      return response;
    },

    async loadNotifications() {
      this.loaded = false;
      try {
        const response = await api.CaseNotifications.getCaseNotifications(this.agency?.slug);
        this.notifications = response;

        this.hasNotifications = this.notifications.some((notification) => !notification.isRead);
      } catch (error) {
        console.error("Error fetching cases notifications:", error);
      } finally {
        this.loaded = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.notification-section {
  position: relative;
}

.notification-mark {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ee2a7b;
  z-index: 2;
  top: -2px;
  right: 0px;
}

.isUnRead {
  background-color: #ee2a7c6b;

  a {
    color: #fff;
  }
}

.form-check-input {
  border: 2px solid #ee2a7b !important;
  outline: none !important;
}

.form-check-input:checked {
  background-color: #ee2a7b !important;
  border: 2px solid #ee2a7b !important;
  outline: none !important;
}

/* Change the color of the switch handle */
.form-check-input:checked+.form-check-label::before {
  background-color: #ee2a7b !important;
  outline: none !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.notification-eye {
  width: 3em;
  height: 3.5em;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 .5em;
}

.notification-eye:hover {
  background-color: #D5D5D5 !important;
}

.dropdown-list:hover {
  background-color: #E8E8E8;
  transition: .05s ease-in;
}

.char-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  font-size: 1.5rem;
  color: var(--uq-light);
}
.read-noti{
  opacity: .4;
}
</style>
