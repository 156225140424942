<template>
  <div class="pt-40 pb-32 pb-lg-48">
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <div class="d-flex justify-content-between align-items-center w-100 mb-24 mb-md-0">
        <div class="h4 ff-secondary mb-0 d-flex align-items-center">
          <div class="bg-secondary rounded-xs me-8">
            <v-icon :icon="icon" size="xxl" space="p-12"/>
          </div>

          {{ title }}
        </div>

        <v-create-item-dropdown
        v-if="!agency?.isCollaborator"
          @open-filters-offcanvas="$emit('openFiltersOffcanvas')"
          @emit-close-off-canvas="$emit('emitCloseOffCanvas')"
          @reload-users="$emit('reloadUsers')"
          @reload-agencies="$emit('reloadAgencies')"
          @reload-clients="$emit('reloadClients')"
          @reload-resources="$emit('reloadResources')"
          @reload-suppliers="$emit('reloadSuppliers')"
          @reload-client-tours="$emit('reloadClientTours')"
        />
        <the-case-create-offcanvas
          v-if="offCanvasToShow === 'the-case-create-offcanvas'"
          :template-data="currentTemplate"
          @closed="closeOffCanvas();"
        />
      </div>

      <div class="d-flex align-items-center">
        <slot name="input-search"/>

        <v-button
          id="btn-filters"
          class="p-0 shadow-none btn-filters"
          variant="icon"
          @click="closeOffCanvas(); $emit('openFiltersOffcanvas')"
        >
          <v-icon class="fw-normal text-primary mb-0" icon="filters" size="xxl"/>
        </v-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import VCreateItemDropdown from '@/components/shared/VCreateItemDropdown.vue';
import { useAuthStore } from '../../stores/auth';
import TheCaseCreateOffcanvas from '../case/TheCaseCreateOffcanvas.vue';
import Template from '../../api/objects/Template';

export default defineComponent({
  name: 'VListHeader',
  components: {
    VCreateItemDropdown,
    VButton,
    VIcon,
    TheCaseCreateOffcanvas
  },
  props: {
    title: {
      type: String,
      required: true,
      default: 'Title',
    },
    icon: {
      type: String,
      required: true,
      default: 'user',
    },
  },
  emits: [
    'openFiltersOffcanvas',
    'emitCloseOffCanvas',
    'reloadUsers',
    'reloadAgencies',
    'reloadClients',
    'reloadResources',
    'reloadSuppliers',
    'reloadClientTours',
  ],
  setup() {
    return {
      ...useOffCanvasUtils(),
    };
  },
  data() {
    return {
      currentTemplate: null as null | Template,
    }
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
  },
  methods: {
    setCurrentTemplate(template: Template) {
        this.currentTemplate = template;
        this.openOffCanvas('the-case-create-offcanvas');
    },
  }
});
</script>
