import { AbstractService } from '@uniqoders/sdk';
import ClientTour from '@/api/objects/ClientTour';

export default class CaseService extends AbstractService {
  /**
   * Retrieves all Cases paginated
   * @protected
   * @param agency
   * @param params
   * @param paginate
   */
  public allPaginated(agency: string, params: any, paginate = true): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/cases', { agency }),
      params,
      options: {
        paginate,
      },
    });
  }

  /**
   * Retrieves a specific Case
   * @param agency
   * @param clientTour
   * @protected
   */
  public retrieve(agency: string, clientTour: number): Promise<ClientTour> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}', { agency, clientTour }),
    });
  }

  /**
   * Creates a single Case
   * @protected
   * @param agency
   * @param data
   */
  public create(agency: string, data: Record<string, any>): Promise<ClientTour> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case', { agency }),
      data,
    });
  }

  public createCollaborator(agency: string, data: Record<string, any>): Promise<ClientTour> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/collaborator-case', { agency }),
      data,
    });
  }
  /**
   * Updates the specified Case
   * @param agency
   * @param clientTour
   * @param data
   * @protected
   */
  public update(agency: string, clientTour: string | number, data: Record<string, any>): Promise<ClientTour> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/case/{clientTour}', { agency, clientTour }),
      data,
    });
  }

  /**
   * Updates the specified Case
   * @param agency
   * @param clientTour
   * @param data
   * @protected
   */
  public updateInitialDate(
    agency: string,
    clientTour: string | number,
    data: Record<string, any>): Promise<ClientTour> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/case/{clientTour}/init-date', { agency, clientTour }),
      data,
    });
  }

  /**
   * Archives the specified Case
   * @protected
   * @param agency
   * @param clientTour
   */
  public archive(agency: string, clientTour: string | number): Promise<ClientTour> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}', { agency, clientTour }),
    });
  }

  /**
   * Restore the specified Case
   * @protected
   * @param agency
   * @param clientTour
   */
  public restore(agency: string, clientTour: string | number): Promise<ClientTour> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/case/{clientTour}/restore', { agency, clientTour }),
    });
  }

  /**
   * Deletes the specified Case
   * @protected
   * @param agency
   * @param clientTour
   */
  public delete(agency: string, clientTour: string | number): Promise<ClientTour> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}/force', { agency, clientTour }),
    });
  }

  /**
   * Assign user to the specified Case
   * @protected
   * @param agency
   * @param clientTour
   * @param data
   */
  public assignUser(agency: string, clientTour: string | number, data: any): Promise<ClientTour> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/assign', { agency, clientTour }),
      data,
    });
  }

  public unassignUser(agency: string, clientTour: string | number): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/unassign', { agency, clientTour }),
    });
  }
}
