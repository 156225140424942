import { defineStore } from 'pinia';

export interface GoogleState {
  loader: any,
}

export const useGoogleStore = defineStore('google', {
  state: (): GoogleState => ({
    loader: null,
  }),
  actions: {
    setLoader(loader: any) {
      this.loader = loader;
    },
  },
});
