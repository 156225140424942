<template>
  <Teleport to="body">
    <div
      :id="id"
      ref="offcanvas"
      :data-bs-backdrop="false"
      :data-bs-scroll="true"
      aria-hidden="true"
      class="offcanvas"
      :class="offcanvasClass"
      tabindex="-1"
    >
      <slot/>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Offcanvas } from 'bootstrap';

export default defineComponent({
  name: 'VOffCanvas',
  emits: ['closed', 'hide', 'hide-prevented', 'show', 'shown'],
  props: {
    id: {
      type: String,
      required: false,
      default: 'offcanvas',
    },
    backdropStatic: {
      type: Boolean,
      required: false,
      default: false,
    },
    backdrop: {
      type: String,
      required: false,
      default: 'false',
    },
    offcanvasClass: {
      type: [String, Object],
      required: false,
      default: '',
    },
    scrollOnlyMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.setup();
    this.open();
  },
  data() {
    return {
      doEmitOnClose: true as boolean,
      offCanvas: null as any | null,
    };
  },
  methods: {
    setup() {
      const OFFCANVAS: any = this.$refs.offcanvas;

      const config: Record<string, any> = {};

      if (this.backdropStatic) {
        config.backdrop = 'static';
      }

      if (this.backdrop === 'false') {
        config.backdrop = false;
      }

      // config.backdrop = this.backdrop !== 'false';
      this.offCanvas = Offcanvas.getOrCreateInstance(OFFCANVAS, config);

      // const main: any = document.getElementById('main');
      // if (!!main) {
      //   main.classList.add('offcanvas-open');
      // }

      OFFCANVAS.addEventListener('hidden.bs.offcanvas', () => {
        if (this.doEmitOnClose) {
          // if (!!main) {
          //   main.classList.remove('offcanvas-open');
          // }

          // TODO Replace to same BT event name: hidden
          this.$emit('closed');
        }
      });

      OFFCANVAS.addEventListener('hide.bs.offcanvas', () => {
        // if (!!main) {
        //   main.classList.remove('offcanvas-open');
        // }

        if (this.doEmitOnClose) {
          this.$emit('hide');
        }
      });

      OFFCANVAS.addEventListener('hidePrevented.bs.offcanvas', () => {
        if (this.doEmitOnClose) {
          this.$emit('hide-prevented');
        }
      });

      OFFCANVAS.addEventListener('show.bs.offcanvas', () => {
        if (this.doEmitOnClose) {
          this.$emit('show');
        }
      });

      OFFCANVAS.addEventListener('shown.bs.offcanvas', () => {
        if (this.doEmitOnClose) {
          this.$emit('shown');
        }
      });
    },
    open() {
      this.offCanvas.show();
    },
    hide() {
      this.offCanvas.hide();
    },
    dispose() {
      this.offCanvas.dispose();
    },
  },
  beforeUnmount() {
    this.offCanvas.hide();
  },
});
</script>
