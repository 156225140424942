import { AbstractService } from '@uniqoders/sdk';
import SupplierResource from '@/api/objects/SupplierResource';

export default class SupplierResourceService extends AbstractService {
  /**
   * Retrieves a specific Supplier Resource
   * @protected
   */
  public retrieve(resource: string | number): Promise<SupplierResource> {
    return this.request({
      method: 'get',
      url: this.buildPath('/supplier/resource/{resource}', { resource }),
    });
  }

  /**
   * Creates a single Supplier Resource
   * @protected
   * @param supplier
   * @param data
   */
  public create(supplier: string | number, data: Record<string, any>): Promise<SupplierResource> {
    return this.request({
      method: 'post',
      url: this.buildPath('/supplier/{supplier}/resource', { supplier }),
      data,
    });
  }

  /**
   * Updates the specified Supplier Resource
   * @param resource
   * @param data
   * @protected
   */
  public update(resource: number | string, data: Record<string, any>): Promise<SupplierResource> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/supplier/resource/{resource}', { resource }),
      data,
    });
  }

  /**
   * Archives the specified Supplier Resource
   * @protected
   * @param resource
   */
  public archive(resource: string | number): Promise<SupplierResource> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/supplier/resource/{resource}', { resource }),
    });
  }

  /**
   * Restore the specified Supplier Resource
   * @protected
   * @param resource
   */
  public restore(resource: string | number): Promise<SupplierResource> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/supplier/resource/{resource}/restore', { resource }),
    });
  }

  /**
   * Deletes the specified Supplier Resource
   * @protected
   * @param resource
   */
  public delete(resource: string | number): Promise<SupplierResource> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/supplier/resource/{resource}/force', { resource }),
    });
  }
}
