import { AbstractService } from '@uniqoders/sdk';
import TourDayItemResource from '@/api/objects/TourDayItemResource';

export default class TemplateDayItemResourceService extends AbstractService {
  /**
   * Creates a single Template Day Item Resource
   * @protected
   * @param agency
   * @param template
   * @param tourDay
   * @param tourDayItem
   * @param data
   */
  public create(
    agency: string,
    template: number,
    tourDay: string | number,
    tourDayItem: string | number,
    data: Record<string, any>): Promise<TourDayItemResource> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/template/{template}/day/{tourDay}/item/{tourDayItem}/resource',
        {
          agency,
          template,
          tourDay,
          tourDayItem,
        }),
      data,
    });
  }

  /**
   * Update the specified Template Day Item Resource
   * @param agency
   * @param template
   * @param tourDay
   * @param tourDayItem
   * @param tourDayItemResource
   * @param data
   * @protected
   */
  public update(
    agency: string,
    template: number,
    tourDay: number,
    tourDayItem: number,
    tourDayItemResource: number,
    data: Record<string, any>): Promise<TourDayItemResource> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/template/{template}/day/{tourDay}/item/{tourDayItem}/resource/{tourDayItemResource}', {
        agency,
        template,
        tourDay,
        tourDayItem,
        tourDayItemResource,
      }),
      data,
    });
  }

  /**
   * Delete the specified Template Day Item Resource
   * @protected
   * @param agency
   * @param template
   * @param tourDay
   * @param tourDayItem
   * @param tourDayItemResource
   */
  public delete(
    agency: string,
    template: number,
    tourDay: string | number,
    tourDayItem: string | number,
    tourDayItemResource: string | number): Promise<TourDayItemResource> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/template/{template}/day/{tourDay}/item/{tourDayItem}/resource/{tourDayItemResource}', {
        agency,
        template,
        tourDay,
        tourDayItem,
        tourDayItemResource,
      }),
    });
  }

  /**
   * Update the specified Template Day Item Resource
   * @param agency
   * @param template
   * @param tourDay
   * @param tourDayItem
   * @param tourDayItemResource
   * @param data
   * @protected
   */
  public updateStatus(
    agency: string,
    template: number,
    tourDay: number,
    tourDayItem: number,
    tourDayItemResource: number,
    data: Record<string, any>): Promise<TourDayItemResource> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/template/{template}/day/{tourDay}/item/{tourDayItem}/resource/{tourDayItemResource}/reservation', {
        agency,
        template,
        tourDay,
        tourDayItem,
        tourDayItemResource,
      }),
      data,
    });
  }
}
