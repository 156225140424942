import { POSITION, TYPE, useToast } from 'vue-toastification';
import { App, Plugin } from 'vue';
import 'vue-toastification/src/scss/index.scss';
import i18n from '@/lang';

class Toast {
  protected $t;

  public constructor() {
    this.$t = i18n.global.t;
  }

  success(message = this.$t('general.shared.savedChanges'), options?: any) {
    const toast = useToast();

    toast(message, {
      type: TYPE.SUCCESS,
      position: POSITION.BOTTOM_RIGHT,
      ...options,
    });
  }

  info(message = this.$t('general.toast.informationMessage')) {
    const toast = useToast();

    toast(message, {
      type: TYPE.INFO,
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  warning(message = this.$t('general.toast.adventMessage')) {
    const toast = useToast();

    toast(message, {
      type: TYPE.WARNING,
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  error(message = this.$t('general.shared.errorMessage')) {
    const toast = useToast();

    toast(message, {
      type: TYPE.ERROR,
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  loading(message = this.$t('Loading...'), id = 'toast-promise') {
    const toast = useToast();

    toast(message, {
      id,
      type: TYPE.DEFAULT,
      position: POSITION.BOTTOM_RIGHT,
      timeout: false,
      toastClassName: 'toast-loading',
      icon: {
        iconClass: 'spinner-border spinner-border-sm text-light',
        iconTag: 'div',
      },
    });

    return toast;
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $toast: Toast;
  }
}

const createToast: Plugin = {
  install(app: App) {
    app.config.globalProperties.$toast = new Toast();
  },
};

export default createToast;
