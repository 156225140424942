<template>
  <div class="accordion">
    <div class="case-details accordion-item">
      <h2 id="headingAgencies" class="accordion-header">
        <button
          aria-controls="collapseAgencies"
          aria-expanded="true"
          class="accordion-button collapsed"
          data-bs-target="#collapseAgencies"
          data-bs-toggle="collapse"
          type="button">
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="import" size="xxl" space="p-12"/>
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t('template.agency.title') }}</div>
              <div class="fw-light accordion-subtitle">{{ $t('template.agency.subtitle') }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseAgencies"
        aria-labelledby="headingAgencies"
        class="accordion-collapse collapse show"
        data-bs-parent="#accordionExample">
        <div class="accordion-body pb-32 pb-xl-48">
          <p class="text-sm fw-medium">{{ $t('template.agency.editAgenciesBelongsTo') }}</p>

          <div class="agencies-container">
            <template v-if="!!template.agencies && template.agencies.length > 0">
              <div
                v-for="agency in template.agencies"
                :key="agency.id"
                class="agency d-flex align-items-center"
              >
                <div class="image-container bg-tertiary">
                  {{ agency.name.charAt(0) }}
                </div>

                <div class="agency-data h-100 w-100 d-flex justify-content-between gap-8 align-items-center">
                  <div class="fw-medium">{{ agency.name }}</div>

                  <div class="actions-container text-nowrap" v-if="can('template.detach')">
                    <v-button
                      :disabled="disassociatingAgencyToTemplate"
                      class="p-4"
                      variant="icon"
                      @click="detachAgency(agency)"
                    >
                      <v-icon icon="trash"/>
                    </v-button>
                  </div>
                </div>
              </div>
            </template>

            <div v-else class="text-sm">{{ $t('template.agency.noAgencies') }}</div>
          </div>

          <template v-if="can('template.attach')">
            <div class="search-separator-container pb-24 mb-4">
              <div class="separator-text-container">
                <span class="separator-text text-sm">{{ $t('template.agency.addAgency') }}</span>
              </div>
            </div>

            <form
              id="template-agencies-search-form"
              class="d-flex flex-column justify-content-between h-100"
            >
              <fieldset>
                <legend class="visually-hidden">{{ $t('template.agency.title') }}</legend>

                <v-select-template-agency-search
                  id="template-agencies-search"
                  :selected-options="template.agencies"
                  @selected="assignAgency"
                />
              </fieldset>
            </form>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import Template from '@/api/objects/Template';
import VSelectTemplateAgencySearch from '@/components/vendor/VSelectTemplateAgencySearch.vue';
import Agency from '@/api/objects/Agency';
import api from '@/api';
import useCapabilities from '@/helpers/capabilities';

export default defineComponent({
  name: 'TheTemplateSetupAgencies',
  components: {
    VSelectTemplateAgencySearch,
    VIcon,
    VButton,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    template: {
      type: Template,
      required: true,
    },
  },
  emits: ['reloadTemplate'],
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      disableButton: false as boolean,
      disassociatingAgencyToTemplate: false,
    };
  },
  computed: {
    selectedAgencies() {
      return this.template.agencies.map((agency: any) => agency);
    },
  },
  methods: {
    async assignAgency(agency: Agency) {
      await this.$modal.confirm({
        title: this.$t('template.agency.addAgencyToTemplateTitle'),
        text: this.$t('template.agency.addAgencyToTemplateTitleText', {
          agency: agency.name,
          template: this.template.name,
        }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.add'),
        confirmButtonCallback: () => this.doAssignAgency(agency.id),
      });
    },
    async doAssignAgency(agencyId: number) {
      try {
        const data: any = {
          agencies: [agencyId],
        };

        await api.template.attachAgencies(this.template.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadTemplate');
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async detachAgency(agency: Agency) {
      await this.$modal.delete({
        title: this.$t('template.agency.detachAgencyToTemplateTitle'),
        text: this.$t('template.agency.detachAgencyToTemplateTitleText', {
          agency: agency.name,
          template: this.template.name,
        }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doDetachAgency(agency.id),
      });
    },
    async doDetachAgency(agencyId: number) {
      try {
        this.disassociatingAgencyToTemplate = true;

        const data: any = {
          agencies: [agencyId],
        };

        await api.template.detachAgencies(this.template.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadTemplate');
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disassociatingAgencyToTemplate = false;
      }
    },
  },
});
</script>

<style lang="scss">
.agencies-container .agency {
  margin-bottom: .5rem;

  .image-container {
    border-bottom-left-radius: .25rem;
    border-top-left-radius: .25rem;
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.5rem;
    color: var(--uq-light);
  }

  .agency-data {
    padding: .563rem 1.25rem .563rem 2rem;
    border: 1px solid var(--uq-gray-400);
    border-left: none;
    border-top-right-radius-radius: .25rem;
    border-top-right-radius-radius: .25rem;
  }
}
</style>
