<template>
  <div class="detail">
    <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
      <div class="fw-medium">{{ $t('admin.agency.basicInformation') }}</div>

      <v-button
        v-if="!agency.deletedAt"
        class="btn-edit p-4"
        variant="icon"
        @click="editBasicInformation = !editBasicInformation">
        <v-icon icon="pencil"/>
      </v-button>
    </div>

    <div class="detail-content">
      <Transition mode="out-in" name="fade-in">
        <div v-if="!editBasicInformation">
          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.companyName') }}: </span>
            <span>{{ agency.name }}</span>
          </div>

          <p class="mb-4 mt-16 text-sm fw-bold">{{ $t('general.address.address') }}</p>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.streetAddress') }}: </span>
            <span>{{ agency.contactAddress1 }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.aptSuit') }}: </span>
            <span>{{ agency.contactAddress2 }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.postalCode') }}: </span>
            <span>{{ agency.contactPostalCode }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.country') }}: </span>
            <span>{{ agency.contactCountry }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.city') }}: </span>
            <span>{{ agency.contactCity }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.provinceStateRegion') }}: </span>
            <span>{{ agency.contactState }}</span>
          </div>

          <p class="mb-4 mt-16 text-sm fw-bold">{{ $t('general.address.billingAddress') }}</p>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.streetAddress') }}: </span>
            <span>{{ agency.billingAddress1 }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.aptSuit') }}: </span>
            <span>{{ agency.billingAddress2 }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.postalCode') }}: </span>
            <span>{{ agency.billingPostalCode }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.country') }}: </span>
            <span>{{ agency.billingCountry }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.city') }}: </span>
            <span>{{ agency.billingCity }}</span>
          </div>

          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.address.provinceStateRegion') }}: </span>
            <span>{{ agency.billingState }}</span>
          </div>
          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.vat') }}: </span>
            <span>{{ agency.vat }}</span>
          </div>
        </div>

        <form
          v-else
          id="agency-update"
          class="d-flex flex-column justify-content-between h-100"
        >
          <fieldset>
            <legend class="visually-hidden">{{ $t('admin.user.basicInformation') }}</legend>
            <v-form-input
              id="agency-update-name"
              v-model="name"
              :label="`${$t('general.shared.companyName')}*`"
              :yup-errors-variable="errors.name"
              autocomplete="off"
              class="mb-8"
              form-type="outline"
            />
            
          </fieldset>

          <v-contact-billing-form
            id="agency-update-address-form"
            ref="agency-update-address-form-ref"
            :billing-always-visible="true"
            :is-vat-visible="true"
            :contact-and-billing-matches="false"
            :data="addressData"
            id-prefix="agency-update"
            @updated-data="addressData = $event"
            @is-valid="addressValid = $event"
          >
          <!-- Slot to update vat in contact form -->
          <template #vatInput>  
            <v-form-input
              id="agency-update-create-vat"
              v-model="vat"
              :label="`${$t('general.shared.vat')}`"
              :yup-errors-variable="errors.vat"
              autocomplete="off"
              class="mb-8"
              form-type="outline"
            />
          </template>
        
          </v-contact-billing-form>
          

          <v-button
            :disabled="!meta.valid || !addressValid || accepted"
            :is-loading="accepted"
            class="btn-submit-form btn-icon w-fit align-self-end"
            size="sm"
            variant="primary"
            @click="saveAgency"
          >
            {{ $t('general.button.save') }}
            <v-icon icon="arrow-right" size="sm" space="ms-12"/>
          </v-button>
        </form>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import { mapState } from 'pinia';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import useModalUtils from '@/helpers/ModalUtils';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';
import Agency from '@/api/objects/Agency';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'TheAdminCollaboratorDetailsBasicInformation',
  components: {
    VContactBillingForm,
    VIcon,
    VButton,
    VFormInput,
  },
  emits: [
    'closed',
    'emitReloadAgencies',
    'reloadAgency',
  ],
  props: {
    agency: {
      type: Agency,
      required: true,
    },
  },
  setup() {
    const authStore = useAuthStore();
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      nif: yup.string()
        .nullable()
        .label(t('general.shared.nif')),
      vat: yup.string()
        .nullable()
        .label(t('general.shared.vat')),
      location: yup.string()
        .nullable()
        .label(t('general.shared.location')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: nif } = useField('nif');
    const { value: vat } = useField('vat');
    const { value: location } = useField('location');

    return {
      authStore,
      ...useModalUtils(),
      ...form,
      name,
      nif,
      vat,
      location,
    };
  },
  data() {
    return {
      editBasicInformation: false as boolean,
      addressData: {} as any,
      addressValid: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      if (!!this.agency) {
        this.name = this.agency.name;
        this.location = this.agency.location;

        this.addressData.contactAddress1 = this.agency.contactAddress1;
        this.addressData.contactAddress2 = this.agency.contactAddress2;
        this.addressData.contactPostalCode = this.agency.contactPostalCode;
        this.addressData.contactCountry = this.agency.contactCountry;
        this.addressData.contactCity = this.agency.contactCity;
        this.addressData.contactState = this.agency.contactState;
        this.addressData.billingAddress1 = this.agency.billingAddress1;
        this.addressData.billingAddress2 = this.agency.billingAddress2;
        this.addressData.billingPostalCode = this.agency.billingPostalCode;
        this.addressData.billingCountry = this.agency.billingCountry;
        this.addressData.billingCity = this.agency.billingCity;
        this.addressData.billingState = this.agency.billingState;
        this.addressData.location = this.agency.location;
        this.vat = this.agency.vat;
      }
    },
    async setLocation(address: Record<string, any>) {
      this.location = address.locality.longName;

      await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });
    },
    async saveAgency() {
      try {
        this.toggleAccepted();

        const data: any = {
          name: this.name,
          nif: this.nif,
          vat: this.vat,
          location: this.location,
          contact_address_1: this.addressData.contactAddress1,
          contact_address_2: this.addressData.contactAddress2,
          contact_postal_code: this.addressData.contactPostalCode,
          contact_country: this.addressData.contactCountry,
          contact_city: this.addressData.contactCity,
          contact_state: this.addressData.contactState,
          billing_address_1: this.addressData.billingAddress1,
          billing_address_2: this.addressData.billingAddress2,
          billing_postal_code: this.addressData.billingPostalCode,
          billing_country: this.addressData.billingCountry,
          billing_city: this.addressData.billingCity,
          billing_state: this.addressData.billingState,
        };

        await api.admin.agency.update(this.agency?.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        if (!!this.user?.agencies?.length && this.user.agencies.some((agency: any) => agency.id === this.agency?.id)) {
          await this.authStore.setAuthUser();
        }

        this.$emit('reloadAgency');
        this.$emit('emitReloadAgencies');

        this.toggleAccepted();

        this.editBasicInformation = !this.editBasicInformation;
      } catch (e: any) {
        this.toggleAccepted();

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
