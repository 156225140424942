export default {
  suppliers: 'Suppliers',
  noSuppliers: 'There are no suppliers',
  createNewSupplier: 'Create new supplier',
  archivedSuppliers: 'Archived suppliers',
  activeSuppliers: 'Active suppliers',
  basicInformation: 'Basic information',
  supplierDetails: 'Supplier details',
  archiveText:
    "You can archive a supplier, if you do this the supplier will not appear in the application searches. You will be able to see it in the list of suppliers if you filter by 'archived'. Once you view the details of an archived supplier, you can delete it definitively or you can unarchive it.",
  media: 'Media',
  noMedia: 'There are no associated media',
  archivedText: "This supplier is archived, if you want you can unarchive it by clicking on the 'Restore' button.",
  archiveSupplierTitle: 'Archive supplier',
  archiveSupplierText:
    "Are you sure you want to archive the supplier '{supplier}'? If you archive it, it will not appear in searches.",
  deleteText:
    "This supplier is archived, if you want to delete it permanently click the 'Delete' button. Note that if you delete it, this supplier will no longer exist and cannot be recovered.",
  deleteSupplierTitle: 'Delete supplier',
  deleteSupplierText:
    "Are you sure you want to delete the supplier '{supplier}'? If you delete it, you will not be able to recover it.",
  restoreSupplierTitle: 'Recover supplier',
  restoreSupplierText:
    "Are you sure you want to recover the supplier '{supplier}'? If you recover it, it will no longer be archived and will appear in the application searches.",
  supplierStatus: 'Supplier Status',
  products: 'Products',
  noProducts: 'There are no associated products',
  editProductsBelongSupplierText: 'Edit products belonging to the supplier',
  contacts: 'Contacts',
  noContacts: 'There are no associated contacts',
  editContactsBelongSupplierText: 'Edit contacts belonging to the supplier',
  addContacts: 'Add contacts',
  removeContactTitle: 'Remove supplier contact',
  removeContactText: "Are you sure you want to remove '{contact}' as a contact for the supplier '{supplier}'?",
  supplierSearch: 'Supplier search',
  removeMediaTitle: 'Delete media',
  removeMediaText: 'Are you sure you want to delete the archive? If you delete it, you will not be able to recover it.',
  singleRoom: 'Single B&B',
  doubleRoom: 'Double B&B',
  doubleTwinRoom: 'Double Twin B&B',
  tripleRoom: 'Triple B&B',
  bunkbedHBRoom: 'Bunkbed HB',
  bunkbedFBRoom: 'Bunkbed FB',
  singleRoomFullName: 'Single room B&B',
  doubleRoomFullName: 'Double room 1 bed B&B',
  doubleTwinRoomFullName: 'Double room Twin beds B&B',
  tripleRoomFullName: 'Triple room B&B',
  bunkbedHBRoomFullName: 'Bunk bed in shared room Half Board',
  bunkbedFBRoomFullName: 'Bunk bed in shared room Full board',
  luggageTransport: 'Luggage Transport',
  busTicket: 'Bus Ticket',
  boatTicket: 'Boat Ticket',
  trainTicket: 'Train Ticket',
  transfer: 'Transfer',
  exportErrorToast: "You don't have permission to export bookings. Please contact your administrator",
  resource: {
    createNew: 'Create new product',
    basicInformation: 'Basic information',
    title: 'Product details',
    archiveTitle: 'Archive supplier product',
    archiveText:
      "Are you sure you want to archive the product '{resource}' of '{supplier}'? If you archive it, it will not appear in searches.",
    media: 'Media',
    noMedia: 'There are no associated media',
    removeMediaTitle: 'Delete media',
    removeMediaText:
      'Are you sure you want to delete the archive? If you delete it, you will not be able to recover it.',
  },
  rates: 'Rates',
  rate: {
    description: 'Add the ways this product can be sold here.',
    descriptionOnlyView: 'The ways this product can be sold',
    create: 'Create rate',
    update: 'Update rate',
    delete: 'Delete rate',
    duplicate: 'Duplicate rate',
    duplicateBlock: 'Duplicate',
    basicInformation: 'Basic information',
    typeNoOptions: 'No types available',
    categoryNoOptions: 'No category available',
    product: 'Product',
    type: 'Type',
    category: 'Category',
    initDate: 'Init date',
    endDate: 'End date',
    price: 'Price',
    removeTitle: 'Delete rate',
    singleRate: 'You will duplicate the following rate',
    blockRate: 'You will duplicate the following block',
    removeText:
      "Are you sure you want to delete the rate of product '{product}'? If you delete it, you will not be able to recover it.",
  },
};
