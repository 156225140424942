<template>
  <button
    :type="type"
    :id="id"
    ref="button"
    :aria-controls="ariaControls"
    :aria-expanded="ariaExpanded"
    :aria-haspopup="ariaHaspopup"
    :aria-label="ariaLabel"
    :aria-labelledby="ariaLabelledby"
    :class="[cSize, cVariant]"
    :data-bs-dismiss="dataBsDismiss"
    :data-bs-target="dataBsTarget"
    :data-bs-toggle="dataBsToggle"
    :disabled="disabled"
    class="btn">
    <slot v-if="!isLoading"/>
    <template v-else>{{ $t('general.button.loading') }}</template>
  </button>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VButton',
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    dataBsDismiss: {
      type: String,
      required: false,
      default: null,
    },
    dataBsToggle: {
      type: String,
      required: false,
      default: null,
    },
    dataBsTarget: {
      type: String,
      required: false,
      default: null,
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },
    ariaLabelledby: {
      type: String,
      required: false,
      default: null,
    },
    ariaHaspopup: {
      type: String,
      required: false,
      default: null,
    },
    ariaExpanded: {
      type: [Boolean, String],
      required: false,
      default: null,
    },
    ariaControls: {
      type: String,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  computed: {
    // TODO debe ser compatible con purgecss
    cSize() {
      let ret = null;

      if (!!this.size) {
        ret = `btn-${this.size}`;
      }

      return ret;
    },
    cVariant() {
      let ret = null;

      if (!!this.variant) {
        ret = `btn-${this.variant}`;
      }

      return ret;
    },
  },
});
</script>
