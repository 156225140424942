export default {
  fileTypeNotAllowed: 'U moet een bestand van het type uploaden',
  tooLarge: 'Bestand te groot, max',
  tooSmall: 'Bestand te klein, min',
  tryAgain: 'probeer dit bestand opnieuw te uploaden',
  somethingWentWrong: 'Er is iets misgegaan bij het uploaden van dit bestand',
  selectOrDragMax: 'Selecteer of sleep maximaal {maxItems} {file}',
  selectOrDrag: 'Selecteer of sleep bestanden',
  file: { singular: 'bestand', plural: 'bestanden' },
  anyImage: 'elke afbeelding',
  anyVideo: 'elke video',
  goBack: 'Ga terug',
  dropFile: 'Sleep bestand om te uploaden',
  dragHere: 'Sleep bestand hier',
  remove: 'Verwijderen',
  name: 'Naam',
  download: 'Downloaden',
};
