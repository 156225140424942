export default {
  title: 'Activiteiten',
  noProducts: 'Geen activiteiten',
  createNew: 'Nieuwe activiteit maken',
  setup: 'Instellingen',
  builder: 'Builder',
  productBuilder: 'Activiteiten bouwer',
  product: 'Activiteit',
  travelType: 'Reistype',
  show: 'Toon activiteit',
  archived: 'Gearchiveerde activiteiten',
  active: 'Actieve activiteiten',
  basicInformation: 'Basisinformatie',
  details: 'Activiteit details',
  detailsSubtitle: 'Algemene activiteitsinformatie leeft hier',
  media: 'Media',
  noMedia: 'Er zijn geen bijbehorende media',
  archiveSubtitle: 'U kunt de activiteit hier archiveren',
  archivedText: 'Deze activiteit is gearchiveerd, als je wilt kun je deze unarchiveren door op de knop \'Herstellen\' te klikken.',
  archiveTitle: 'Archief activiteit',
  archiveAlertText: 'Weet je zeker dat je de activiteit \'{product}\' wilt archiveren? Als je het archiveert, zal het niet verschijnen in zoekopdrachten.',
  archiveText: 'Je kunt een activiteit archiveren. Als je dit doet, verschijnt de activiteit niet in de zoekresultaten van de applicatie. Je kunt de activiteit wel zien in de lijst met activiteiten als je filtert op \'gearchiveerd\'. Als je de details van een gearchiveerde activiteit bekijkt, kun je deze definitief verwijderen of de archivering ongedaan maken.',
  archiveTextAlert: 'Weet je zeker dat je de activiteit \'{product}\' wilt archiveren? Als je het archiveert, zal het niet verschijnen in zoekopdrachten.',
  deleteText: 'Deze activiteit is gearchiveerd, als je deze permanent wilt verwijderen klik je op de knop \'Verwijderen\'. Houd er rekening mee dat deze activiteit niet langer bestaat en niet kan worden hersteld als u deze verwijdert.',
  deleteTitle: 'Activiteit verwijderen',
  deleteTextAlert: 'Weet je zeker dat je de activiteit \'{product}\' wilt verwijderen? Als u deze verwijdert, kunt u deze niet meer herstellen.',
  restoreTitle: 'Product herstellen',
  restoreText: 'Weet u zeker dat u het product \'{product}\' wilt herstellen? Als u het herstelt, is het niet langer gearchiveerd en verschijnt het weer in zoekopdrachten.',
  status: 'Activiteitsstatus',
  search: 'Activiteit zoeken',
  canSearch: 'U kunt zoeken naar de activiteit',
  orUpdateItManually: 'of het handmatig bijwerken',
  removeMediaTitle: 'Media verwijderen',
  removeMediaText: 'Weet u zeker dat u het archief wilt verwijderen? Als u het verwijdert, kunt u het niet meer herstellen.',
  tourType: 'Type tour',
  noArchived: 'Geen gearchiveerde activiteiten',
  useProduct: 'Gebruik activiteit',
  productSaved: 'Opgeslagen activiteit',
  createCaseWithTemplate: 'Maak een zaak aan met dit sjabloon',
  deleteDayTitle: 'Dag verwijderen',
  deleteDayText: 'Weet u zeker dat u dag {dayNumber} wilt verwijderen? Als u dit doet, gaat alle voortgang die u die dag heeft gemaakt verloren.',
  dayDeleted: 'Dag succesvol verwijderd',
  dayDeleteError: 'Er is een fout opgetreden bij het verwijderen van de dag',
  lengthErrorMessage: 'Schrijf een afstand in kilometers of schrijf nul',
  dayUpdated: 'Dag succesvol bijgewerkt',
  dayUpdateError: 'Er is een fout opgetreden bij het bijwerken van de dag',
  stage: 'Etappe',
  duplicateActivity: 'Dubbele activiteit',
  duplicatedActivitySuccess: 'Succesvol gedupliceerde activiteit',
  redirectingNewActivity: 'Doorverwijzen naar de nieuwe activiteit...',
  templateBelongsToCollaborator: 'Deze activiteit is voor agentschappen',
  agency: {
    title: 'Agentschappen',
    subtitle: 'Bij deze activiteit betrokken agentschappen',
    noAgencies: 'Deze activiteit is niet verbonden aan een agentschap',
    editAgenciesBelongsTo: 'Bewerk de agentschappen waartoe deze activiteit behoort',
    addAgency: 'Agentschap toevoegen',
    addAgencyToTemplateTitle: 'Agentschap toevoegen aan activiteit',
    addAgencyToTemplateTitleText: 'Weet u zeker dat u het bureau \'{ agency }\' wilt koppelen aan de activiteit \'{ template }\'?',
    detachAgencyToTemplateTitle: 'Wijs agentschap los van activiteit',
    detachAgencyToTemplateTitleText: 'Weet u zeker dat u het agentschap \'{ agency }\' wilt loskoppelen van de activiteit \'{ template }\'?',
  },
  contact: {
    contacts: 'Contacten',
    contactsSubtitle: 'Hier kun je de standaard contactgegevens toevoegen voor de preview en offerte van cases die deze activiteit gebruiken',
    contactInformation: 'Contactinformatie',
  },
  pax: {
    title: 'Passagiers',
    subtitle: 'Passagiers van de activiteit',
  },
  addDayTitle: 'Dag aan activiteit toevoegen',
  addDayText: 'Weet je zeker dat je een nieuwe dag aan deze activiteit wilt toevoegen?',
};
