import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface PaxContract extends IBaseObject {
  addressCity: string;
  addressCountry: string;
  addressCp: string;
  addressState: string;
  addressStreet: string;
  allergies: string;
  arrivalDate: string;
  arrivalHour: string;
  arrivalLocation: string;
  arrivalNumber: string;
  arrivalType: string;
  bikeType: string;
  birthDate: string;
  createdAt: string;
  departureDate: string;
  departureHour: string;
  departureLocation: string;
  departureNumber: string;
  departureType: string;
  diet: string;
  documentNumber: string;
  documentType: string;
  email: string;
  height: string;
  language: string;
  name: string;
  observations: string;
  phone: string;
  surname1: string;
  surname2: string;
  tourId: number;
  updatedAt: string;
  weight: string;
  age: number;
}

export default class Pax extends BaseObject implements PaxContract {
  public declare addressCity: string;
  public declare addressCountry: string;
  public declare addressCp: string;
  public declare addressState: string;
  public declare addressStreet: string;
  public declare allergies: string;
  public declare arrivalDate: string;
  public declare arrivalHour: string;
  public declare arrivalLocation: string;
  public declare arrivalNumber: string;
  public declare arrivalType: string;
  public declare bikeType: string;
  public declare birthDate: string;
  public declare createdAt: string;
  public declare departureDate: string;
  public declare departureHour: string;
  public declare departureLocation: string;
  public declare departureNumber: string;
  public declare departureType: string;
  public declare diet: string;
  public declare documentNumber: string;
  public declare documentType: string;
  public declare email: string;
  public declare height: string;
  public declare language: string;
  public declare name: string;
  public declare observations: string;
  public declare phone: string;
  public declare surname1: string;
  public declare surname2: string;
  public declare tourId: number;
  public declare updatedAt: string;
  public declare weight: string;
  public declare age: number;

  constructor(data: PaxContract) {
    super(data);
  }
}
