<template>
  <div class="loader" :class="type === 'rounded' ? 'balls' : 'bar'" :style="{ opacity: options.opacity }">
    <div class="loader-balls" v-if="type === 'rounded'">
      <div class="load loader-1"/>
      <div class="load loader-2"/>
      <div class="load loader-3"/>
      <div class="load loader-4"/>
      <div class="load loader-5"/>
      <div class="load loader-6"/>
      <div class="load loader-7"/>
      <div class="load loader-8"/>
    </div>

    <div class="progress-loader" v-else-if="type === 'progress'">
      <div class="progress-bar" :style="{ width: percent + '%' }"/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  nextTick,
} from 'vue';

export default defineComponent({
  name: 'VLoader',
  data() {
    return {
      percent: 0,
      time: 250,
      timer: null as any,
      cut: 0,
      options: {
        show: true,
        opacity: 1,
      },
    };
  },
  props: {
    type: {
      required: false,
      default: 'rounded',
    },
  },
  methods: {
    start() {
      if (this.type === 'progress') {
        this.percent = 0;
        this.cut = 10000 / Math.floor(this.time);
        clearInterval(this.timer);

        this.timer = setInterval(() => {
          this.increase(this.cut * Math.random());
          if (this.percent > 95) {
            this.finish();
          }
        }, 200);
      }
    },
    increase(num: number) {
      this.percent = Math.min(99, this.percent + Math.floor(num));
    },
    hide() {
      clearInterval(this.timer);
      this.timer = null;

      setTimeout(() => {
        this.options.show = false;
        this.options.opacity = 0;
        nextTick(() => {
          setTimeout(() => {
            this.percent = 0;
          }, 500);
        });
      }, 1000);
    },
    finish() {
      this.percent = 100;
      this.hide();
    },
  },
  mounted() {
    this.start();
  },
});
</script>

<style lang="scss" scoped>
.loader {
  transition: opacity .1s ease-out;

  &.balls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.bar {
    position: fixed;
    z-index: 1050;
    width: 100%;
  }

  .loader-balls {
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;

    .load {
      animation: roller-loader 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 2.5rem 2.5rem;

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        background: var(--uq-primary);
        margin: -.25rem 0 0 -.25rem;
      }
    }

    .loader-1 {
      animation-delay: -0.036s;

      &:after {
        top: 63px;
        left: 63px;
      }
    }

    .loader-2 {
      animation-delay: -0.072s;

      &:after {
        top: 68px;
        left: 56px;
      }
    }

    .loader-3 {
      animation-delay: -0.108s;

      &:after {
        top: 71px;
        left: 48px;
      }
    }

    .loader-4 {
      animation-delay: -0.144s;

      &:after {
        top: 72px;
        left: 40px;
      }
    }

    .loader-5 {
      animation-delay: -0.18s;

      &:after {
        top: 71px;
        left: 32px;
      }
    }

    .loader-6 {
      animation-delay: -0.216s;

      &:after {
        top: 68px;
        left: 24px;
      }
    }

    .loader-7 {
      animation-delay: -0.252s;

      &:after {
        top: 63px;
        left: 17px;
      }
    }

    .loader-8 {
      animation-delay: -0.288s;

      &:after {
        top: 56px;
        left: 12px;
      }
    }
  }

  .progress-bar {
    background-color: var(--uq-primary);
    height: .25rem;
    border-radius: 0;
    transition: width 1s ease-out;
  }
}
</style>
