<template>
  <div id="accordionInvoices" class="accordion">
    <div class="case-details accordion-item">
      <h2 id="headingInvoices" class="accordion-header">
        <button
          aria-controls="collapseInvoices"
          aria-expanded="true"
          class="accordion-button collapsed"
          data-bs-target="#collapseInvoices"
          data-bs-toggle="collapse"
          type="button">
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="description" size="xxl" space="p-12"/>
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t('clientTour.invoicesReceived') }}</div>
              <div class="fw-light accordion-subtitle">{{ $t('clientTour.invoicesReceivedSubtitle') }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseInvoices"
        aria-labelledby="headingInvoices"
        class="accordion-collapse collapse show"
        data-bs-parent="#accordionInvoices">
        <div class="accordion-body pb-32 pb-xl-48">
          <div class="invoices-media-container d-flex flex-column">
            <v-uploader
              ref="uploader"
              :after-upload="onChange"
              :headers="{ 'X-case': tour.clientTourId }"
              route-prefix="api/drive"
              @removed="onChange"
            />

            <v-button
              :disabled="disableButton || mediaUuids.length === 0"
              class="mt-16 align-self-end"
              size="sm"
              variant="primary"
              @click="confirmMedia">
              {{ $t('general.button.save') }}
              <v-icon icon="arrow-right" size="sm" space="ms-12"/>
            </v-button>

            <div class="media-container item-list mt-16 text-sm">
              <div class="table-responsive d-none d-md-flex d-lg-none d-xl-block">
                <v-table class="mb-0">
                  <v-table-header>
                    <tr>
                      <th>
                        {{ $t('general.shared.name') }}
                      </th>

                      <th>
                        {{ $t('general.shared.date') }}
                      </th>

                      <th>
                        {{ $t('general.shared.size') }}
                      </th>
                      <th class="fit"/>
                    </tr>
                  </v-table-header>

                  <v-table-body class="fw-medium">
                    <tr v-if="!invoicesLoaded">
                      <td class="position-relative border-0 py-96" colspan="2222">
                        <v-loader/>
                      </td>
                    </tr>

                    <template v-else-if="!!invoices && invoices.length > 0">
                      <tr v-for="file in invoices" :key="file.id">
                        <td class="d-flex align-items-center">
                          <v-icon v-if="file.type === 'pdf'" class="invoice-icon" icon="pdf" space="me-8"/>

                          <v-icon
                            v-else-if="file.type !== 'pdf' && file.type !== 'image'"
                            class="invoice-icon"
                            icon="doc"
                            space="me-8"
                          />

                          <v-picture v-else class="image-container">
                            <img
                              :alt="file.storageUrl"
                              :src="file.storageUrl"
                            />
                          </v-picture>

                          <the-case-setup-invoices-update-name
                            :agency="agency"
                            :client-tour="clientTour"
                            :file="file"
                            :tour="tour.id"
                            @reload-tour="reloadTour"
                          />
                        </td>

                        <td class="text-nowrap">
                          {{ file.createdAt }}
                        </td>

                        <td class="text-nowrap">
                          {{ file.humanReadableSize }}
                        </td>

                        <td class="fit text-end">
                          <div class="d-flex align-items-center">
                            <v-button
                              class="shadow-none p-4"
                              variant="icon"
                              @click="downloadMedia(file.id)"
                            >
                              <v-icon icon="download"/>
                            </v-button>

                            <v-button
                              class="shadow-none p-4"
                              variant="icon"
                              @click="removeMedia(file)"
                            >
                              <v-icon icon="trash"/>
                            </v-button>
                          </div>
                        </td>
                      </tr>
                    </template>

                    <template v-else>
                      <tr>
                        <td class="rounded-xs py-16" colspan="2222">
                          {{ $t('clientTour.invoice.noInvoicesReceived') }}
                        </td>
                      </tr>
                    </template>
                  </v-table-body>
                </v-table>
              </div>

              <div class="item-cards d-block d-md-none d-lg-block d-xl-none">
                <div v-if="!invoicesLoaded" class="position-relative py-96">
                  <v-loader/>
                </div>

                <template v-else-if="!!invoices && invoices.length > 0">
                  <div v-for="file in invoices" :key="file.id" class="item-card border rounded-xs p-8 mb-12">
                    <the-case-setup-invoices-update-name
                      :agency="agency"
                      :client-tour="clientTour"
                      :file="file"
                      :tour="tour.id"
                      @reload-tour="reloadTour"
                    />

                    <div>
                      <span class="fw-medium">{{ $t('general.shared.date') }}: </span>

                      <span>{{ file.createdAt }}</span>
                    </div>

                    <div>
                      <span class="fw-medium">{{ $t('general.shared.size') }}: </span>
                      <span>{{ file.humanReadableSize }}</span>
                    </div>

                    <div class="d-flex justify-content-end align-items-center">
                      <v-button
                        class="shadow-none p-4"
                        variant="icon"
                        @click="downloadMedia(file.id)"
                      >
                        <v-icon icon="download"/>
                      </v-button>

                      <v-button
                        class="shadow-none p-4"
                        variant="icon"
                        @click="removeMedia(file)"
                      >
                        <v-icon icon="trash"/>
                      </v-button>
                    </div>
                  </div>
                </template>

                <div v-else>
                  {{ $t('clientTour.invoice.noInvoicesReceived') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VUploader from '@/components/vendor/upload/VUploader.vue';
import Media from '@/api/objects/Media';
import Tour from '@/api/objects/Tour';
import { useUserStore } from '@/stores/user';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import TheCaseSetupInvoicesUpdateName from '@/components/case/parts/TheCaseSetupInvoicesUpdateName.vue';
import { mapActions } from 'pinia';

export default defineComponent({
  name: 'TheCaseSetupInvoices',
  components: {
    TheCaseSetupInvoicesUpdateName,
    VLoader,
    VTableBody,
    VTableHeader,
    VTable,
    VIcon,
    VButton,
    VUploader,
    VPicture,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
    clientTour: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      media: [] as any,
      disableButton: false as boolean,
      invoices: [] as Media[],
      invoicesLoaded: false,
    };
  },
  computed: {
    mediaUuids() {
      return this.media.map((media: any) => ({
        uuid: media.attributes.uuid,
        name: media.attributes.name,
      }));
    },
  },
  async created() {
    await this.loadTourInvoices();
  },
  methods: {
    ...mapActions(useUserStore, ['setCurrentTour']),
    onChange() {
      const { uploader }: any = this.$refs;

      if (!!uploader) {
        this.media = uploader.mediaLibrary.state.media;
      }
    },
    async loadTourInvoices() {
      this.invoicesLoaded = false;

      this.invoices = this.tour.invoicesReceived;

      this.invoicesLoaded = true;
    },
    async downloadMedia(fileId: number) {
      try {
        const response = await api.media.download('drive', fileId);

        window.open(response, '_blank');
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
    async removeMedia(file: Media) {
      await this.$modal.delete({
        title: this.$t('clientTour.invoice.removeTitle'),
        text: this.$t('clientTour.invoice.removeText', { name: file.name }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doRemoveMedia(file.id),
      });
    },
    async doRemoveMedia(fileId: string | number) {
      try {
        await api.media.delete('drive', fileId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.reloadTour();
        await this.loadTourInvoices();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async confirmMedia() {
      try {
        this.disableButton = true;

        const data = {
          media: this.mediaUuids,
          model_type: 'tour',
          model_id: this.tour.id,
          collection: 'invoices_received',
        };

        await api.media.confirm('drive', data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.mediaUuids = [];

        const { uploader }: any = this.$refs;

        if (!!uploader) {
          uploader.mediaLibrary.state.media = [];
        }

        await this.reloadTour();
        await this.loadTourInvoices();

        this.disableButton = false;
      } catch (e: any) {
        this.disableButton = false;

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
    async reloadTour() {
      const tourData = {
        id: this.tour.id,
        agency: this.agency,
        clientTourId: this.clientTour,
      };

      const params = {
        expand: ['client', 'paxs', 'featuredImage', 'quotes', 'invoicesReceived', 'clientTour'],
      };

      await this.setCurrentTour(tourData, params);
    },
  },
});
</script>

<style lang="scss" scoped>
.media-container {
  th {
    font-size: 1rem;
  }

  .invoice-icon {
    font-size: 3rem;
  }

  .image-container {
    margin-right: .5rem;

    :deep(img) {
      height: 100%;
    }
  }
}
</style>
