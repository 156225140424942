<template>
  <media-library-collection
    ref="mediaLibraryCollection"
    :afterUpload="afterUpload"
    :initial-value="media"
    :route-prefix="routePrefix"
    :translations="translations"
    :max-items="maxItems"
    :multiple="multiple"
    :upload-domain="domain"
    :validation-rules="validationRules"
    :with-credentials="true"
    :headers="headers"
    name="downloads"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { MediaLibraryCollection } from '@spatie/media-library-pro-vue3-collection';
import { getHummanSize } from '@/helpers/General';

export default defineComponent({
  name: 'VUploader',
  props: {
    headers: {
      required: false,
      type: Object,
    },
    name: {
      required: false,
      type: String,
    },
    initialValue: {
      required: false,
      type: [Array, Object],
    },
    validationErrors: {
      default: () => ({}),
      type: [Object, Array],
    },
    routePrefix: {
      required: false,
      type: String,
      default: 'api',
    },
    validationRules: {
      required: false,
      type: Object,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    sortable: {
      default: true,
      type: Boolean,
    },
    maxItems: {
      required: false,
      type: Number,
    },
    maxSizeForPreviewInBytes: {
      required: false,
      type: Number,
    },
    vapor: {
      required: false,
      type: Boolean,
    },
    vaporSignedStorageUrl: {
      required: false,
      type: String,
    },
    uploadDomain: {
      required: false,
      type: String,
    },
    withCredentials: {
      required: false,
      type: Boolean,
    },
    fileTypeHelpText: {
      required: false,
      type: String,
    },
    beforeUpload: {
      type: Function,
    },
    afterUpload: {
      type: Function,
    },
  },
  emits: [
    'removed',
    'change',
    'is-ready-to-submit-change',
    'has-uploads-in-progress-change',
    'isReadyToSubmitChange',
    'hasUploadsInProgressChange',
  ],
  components: {
    MediaLibraryCollection,
  },
  data() {
    return {
      mediaLibrary: null,
      media: this.initialValue,
    };
  },
  computed: {
    domain() {
      return import.meta.env.VITE_APP_API_URL;
    },
    translations() {
      return {
        fileTypeNotAllowed: this.$t('uploader.fileTypeNotAllowed'),
        tooLarge: this.$t('uploader.tooLarge'),
        tooSmall: this.$t('uploader.tooSmall'),
        tryAgain: this.$t('uploader.tryAgain'),
        somethingWentWrong: this.$t('uploader.somethingWentWrong'),
        selectOrDrag: this.$t('uploader.selectOrDrag'),
        selectOrDragMax: this.$t('uploader.selectOrDrag'),
        file: {
          singular: this.$t('uploader.file.singular'),
          plural: this.$t('uploader.file.plural'),
        },
        anyImage: this.$t('uploader.anyImage'),
        anyVideo: this.$t('uploader.anyVideo'),
        goBack: this.$t('uploader.goBack'),
        dropFile: this.$t('uploader.dropFile'),
        dragHere: this.$t('uploader.dragHere'),
        remove: this.$t('uploader.remove'),
        download: this.$t('uploader.download'),
      };
    },
  },
  watch: {
    initialValue: {
      handler(value) {
        this.media = value;
      },
      deep: true,
    },
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup() {
      this.mediaLibrary = this.$refs.mediaLibraryCollection.mediaLibrary;

      const iconButton = document.querySelector('.media-library-button-info');

      iconButton.innerHTML = '<span class="icon icon-export"/>';
    },
    getHummanSize,
    remove(object) {
      this.mediaLibrary.removeMedia(object.uuid);

      this.$emit('removed', null);
    },
    handleSortEnd(event) {
      this.$refs.mediaLibraryCollection.setOrder(
        Array.from(event.from.children || [])
          .map((element) => element.getAttribute('data-media-library-uuid')),
        false,
      );
    },
  },
});
</script>
