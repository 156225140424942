<template>
  <div id="page-resume-contact">
    <h3 class="h5 mb-12">{{ $t('travelBook.contact') }}</h3>

    <div class="contact-container bg-white w-100 p-28">
      <div v-if="tour.contactText === null">
        {{ $t('travelBook.noContacts') }}
      </div>
      <div v-else v-html="tour.contactText"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Tour from '@/api/objects/Tour';

export default defineComponent({
  name: 'TheTravelBookResumeContact',
  props: {
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}

.contact-container {
  border-radius: .5rem;
  box-shadow: 0 1px .25rem var(--uq-gray-300);
}
</style>
