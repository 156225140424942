<template>
  <component :is="as" class="modal-title fw-semi">
    <slot/>
  </component>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalTitle',
  props: {
    as: {
      type: String,
      required: false,
      default: 'h5',
    },
  },
});
</script>
