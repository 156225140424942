<template>
  <div v-if="!agency.deletedAt" class="detail">
    <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
      <div class="fw-medium">{{ $t('admin.collaborator.users') }}</div>

      <v-button
        class="btn-edit p-4"
        variant="icon"
        @click="openModal('the-admin-collaborator-add-users-modal')">
        <v-icon icon="circle-plus"/>
      </v-button>
    </div>

    <div v-if="!!agency" class="detail-content">
      <div class="users-container card-item-list">
        <div v-if="!agency.agencyUsers || agency.agencyUsers.length === 0" class="text-sm">
          {{ $t('admin.collaborator.noUsers') }}
        </div>

        <div
          v-for="agencyUser in agency.agencyUsers"
          :key="agencyUser.user.id"
          class="agency item-card border rounded-xs d-flex justify-content-between align-items-center
          p-8 p-lg-12 mb-8"
        >
          <div class="d-flex align-items-center w-100 me-8">
            <div class="image-container rounded-sm bg-tertiary me-12">
              {{ agencyUser.user.name?.charAt(0) }}
            </div>

            <div
              class="w-100 d-flex flex-column text-sm">
              <div class="fw-medium">{{ agencyUser.user.name }}</div>
              <div class="text-sm fw-light text-gray-500">
                {{ agencyUser.user.email }}
              </div>
            </div>
          </div>

          <div class="d-flex">
            <v-button
              class="shadow-none p-2"
              variant="icon"
              @click="showUserDetail(agencyUser.user)"
            >
              <v-icon icon="eye"/>
            </v-button>

            <v-button
              :disabled="disableUnassignAgencyUserBtn"
              class="shadow-none p-2"
              variant="icon"
              @click="unassignAgencyUser(agencyUser.user)"
            >
              <v-icon icon="trash"/>
            </v-button>
          </div>
        </div>
      </div>
    </div>

    <the-admin-collaborator-add-users-modal
      v-if="modalToShow === 'the-admin-collaborator-add-users-modal'"
      :agency="agency"
      @closed="closeModal"
      @emit-reload-agency="reloadAgency"
    />

    <the-admin-collaborator-user-details-modal
      v-if="modalToShow === 'the-admin-collaborator-user-details-modal'"
      :user="currentUser"
      @closed="closeModal(); currentUser = null"
      @reload-item="reloadAgency"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useModalUtils from '@/helpers/ModalUtils';
import Agency from '@/api/objects/Agency';
import User from '@/api/objects/User';
import api from '@/api';
import TheAdminCollaboratorAddUsersModal from '@/components/admin/collaborator/TheAdminCollaboratorAddUsersModal.vue';
import TheAdminCollaboratorUserDetailsModal from '@/components/admin/agency/parts/TheAdminAgencyUserDetailsModal.vue';

export default defineComponent({
  name: 'TheAdminAgencyDetailsUsers',
  components: {
    TheAdminCollaboratorUserDetailsModal,
    TheAdminCollaboratorAddUsersModal,
    VIcon,
    VButton,
  },
  emits: [
    'closed',
    'reloadAgency',
  ],
  props: {
    agency: {
      type: [Agency, Object],
      required: false,
    },
  },
  setup() {
    return {
      ...useModalUtils(),
    };
  },
  data() {
    return {
      currentUser: null as null | User,
      disableUnassignAgencyUserBtn: false as boolean,
    };
  },
  watch: {
    agency(value) {
      if (!!this.currentUser && !!value) {
        this.currentUser = value.agencyUsers.find(
          (agencyUser: any) => agencyUser.user.id === this.currentUser?.id)?.user;
      }
    },
  },
  methods: {
    async showUserDetail(user: User) {
      this.currentUser = user;

      if (this.modalToShow !== 'the-admin-collaborator-user-details-modal') {
        this.openModal('the-admin-collaborator-user-details-modal');
      }
    },
    async unassignAgencyUser(user: User) {
      await this.$modal.delete({
        title: this.$t('admin.collaborator.removeCollaboratorUserTitle'),
        text: this.$t('admin.collaborator.removeCollaboratorUserText', {
          agency: this.agency?.name,
          user: user.name,
          email: user.email,
        }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doUnassignAgencyUser(user.id),
      });
    },
    async doUnassignAgencyUser(userId: string | number) {
      try {
        this.disableUnassignAgencyUserBtn = true;

        await api.admin.agency.attachUser(this.agency?.id, userId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.reloadAgency();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableUnassignAgencyUserBtn = false;
      }
    },
    reloadAgency() {
      this.$emit('reloadAgency');
    },
  },
});
</script>
