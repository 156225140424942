<template>
  <header id="top" ref="headerTop" class="fixed-top">
    <the-impersonate-stop/>

    <the-admin-header/>

    <div class="header-container user-header">
      <div class="container-xl px-0">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid justify-content-start">
            <div class="uq-header-logo">
              <router-link
                id="header-logo"
                :to="logoRoute"
                aria-label="Logo"
                href="#"
                itemprop="url"
                rel="home"
              >
                <v-picture class="d-none d-lg-block">
                  <img
                    :alt="$t('general.shared.appLogoAlt')"
                    src="@/../resources/img/waw-logo.png"
                    srcset="@/../resources/img/waw-logo.png 2x,
                       @/../resources/img/waw-logo.png 1x"/>
                </v-picture>

                <v-picture class="d-block d-lg-none">
                  <img
                    :alt="$t('general.shared.appLogoAlt')"
                    src="@/../resources/img/waw-logo-mobile.png"
                    srcset="@/../resources/img/waw-logo-mobile.png 2x,
                       @/../resources/img/waw-logo-mobile.png 1x"/>
                </v-picture>
              </router-link>
            </div>

            <button
              id="btn-header-hamburger"
              ref="btnHeaderHamburger"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              class="navbar-toggler hamburger hamburger--slider shadow-none py-0 ms-auto"
              data-bs-target="#navbarToggleExternalContent"
              data-bs-toggle="collapse"
              type="button"
              @click="openCloseHamburger"
            >
              <span class="hamburger-box">
                <v-icon icon="hamburger" size="xxl"/>
              </span>
            </button>

            <div
              id="navbarToggleExternalContent"
              class="collapse navbar-collapse ms-auto justify-content-lg-end">
              <the-menu/>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import TheMenu from '@/components/header/TheMenu.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import TheImpersonateStop from '@/components/header/TheImpersonateStop.vue';
import TheAdminHeader from '@/components/header/TheAdminHeader.vue';

export default defineComponent({
  name: 'TheHeader',
  components: {
    TheAdminHeader,
    TheImpersonateStop,
    VIcon,
    TheMenu,
    VPicture,
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    ...mapState(useAuthStore, ['agency']),
    logoRoute() {
    if (this.agency.isCollaborator) {
      return { name: 'collaboratorDashboard' };
    } else {
      return { name: 'case.index' };
    }
  }
  },
  methods: {
    openCloseHamburger() {
      const buttonClose: any = this.$refs.btnHeaderHamburger;
      const main: any = document.getElementById('main');

      if (!!buttonClose) {
        if (!buttonClose.classList.contains('is-active')) {
          buttonClose.classList.add('is-active');

          if (window.innerWidth < 992) {
            main.classList.add('menu-open');
          }
        } else {
          buttonClose.classList.remove('is-active');
          main.classList.remove('menu-open');
        }
      }
    },
  },
});
</script>
