<template>
  <v-alert
    class="d-flex"
    icon-class="text-danger"
    icon-variant="circle-actions-alert-info"
    role="alert"
    variant="danger">
    <div class="fw-semi text-black mb-8">{{ $t('general.shared.cannotUndo') }}</div>
    <div class="text-black">
      <slot/>
    </div>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VAlert from '@/components/vendor/alert/VAlert.vue';

export default defineComponent({
  name: 'AlertDivLayout',
  components: {
    VAlert,
  },
});
</script>
