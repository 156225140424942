<template>
  <ul id="main-menu" class="navbar-nav navbar-nav-list flex-row" v-if="!agency?.isCollaborator">
    <li
      v-if="can('user.index')"
      class="menu-item nav-item text-sm me-16 me-md-32 py-0">
      <router-link
        id="admin-users"
        :aria-label="$t('general.adminMenu.users')"
        :to="{ name: 'user.index' }"
        class="nav-link"
        @click="closeMenu"
      >
        {{ $t('general.adminMenu.users') }}
      </router-link>
    </li>

    <li
      v-if="can('agency.index')"
      class="menu-item nav-item text-sm py-0 me-16 me-md-32 py-0">
      <router-link
        id="admin-agencies"
        :aria-label="$t('general.adminMenu.agencies')"
        :to="{ name: 'agency.index' }"
        class="nav-link"
        @click="closeMenu"
      >
        {{ $t('general.adminMenu.agencies') }}
      </router-link>
    </li>
    <li
      class="menu-item nav-item text-sm py-0">
      <router-link
        v-if="can('agency.index')"
        id="admin-collaborators"
        :aria-label="this.$t('general.adminMenu.collaborators')"
        :to="{ name: 'collaborators' }"
        class="nav-link"
        @click="closeMenu"
      >
        {{ $t('general.adminMenu.collaborators') }}
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import useCapabilities from '@/helpers/capabilities';

export default defineComponent({
  name: 'TheAdminMenu',
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    ...mapState(useAuthStore, ['agency']),
  },
  methods: {
    closeMenu() {
      const main: any = document.getElementById('main');
      const buttonClose: any = document.getElementById('btn-header-hamburger');

      if (!!buttonClose && main.classList.contains('menu-open')) {
        buttonClose.click();

        buttonClose.classList.remove('is-active');
        main.classList.remove('menu-open');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-item {
  font-size: 1rem;
  font-weight: 300;
}
</style>
