<template>
  <div class="template-setup-container">
    <div class="wrapper grid">
      <div class="grid left">
        <div class="accordion">
          <div class="template-details accordion-item">
            <h2 id="templateHeadingDetails" class="accordion-header">
              <button
                aria-controls="collapseDetails"
                aria-expanded="true"
                class="accordion-button"
                data-bs-target="#collapseDetails"
                data-bs-toggle="collapse"
                type="button">
                <div class="mb-0 d-flex">
                  <div class="bg-secondary rounded-xs h-fit-content me-8">
                    <v-icon icon="pencil" size="xxl" space="p-12"/>
                  </div>

                  <div>
                    <div class="h4 ff-secondary mb-0">{{ $t('template.details') }}</div>
                    <div class="fw-light accordion-subtitle">{{ $t('template.detailsSubtitle') }}</div>
                  </div>
                </div>
              </button>
            </h2>

            <div
              id="collapseDetails"
              aria-labelledby="templateHeadingDetails"
              class="accordion-collapse collapse show"
              data-bs-parent="#accordionExample">
              <div class="accordion-body pb-32 pb-xl-48">
                <p class="text-sm fw-medium" v-if="template.isCollaborator">{{ $t('template.templateBelongsToCollaborator') }}</p>
                <form
                  id="template-update-form"
                  class="d-flex flex-column justify-content-between h-100"
                >
                  <fieldset>
                    <legend class="visually-hidden">{{ $t('template.basicInformation') }}</legend>

                    <v-form-input
                      id="template-update-name"
                      v-model="name"
                      :label="`${$t('general.shared.name')}*`"
                      :yup-errors-variable="errors.name"
                      autocomplete="off"
                      form-type="outline"
                    />

                    <v-form
                      id="template-update-travel-type-select"
                      :label="`${$t('template.travelType')}*`"
                      :yup-errors-variable="errors.travelType"
                      form-type="outline"
                    >
                      <v-select
                        id="template-update-travel-type-select"
                        v-model="travelType"
                        :clearable="false"
                        :options="travelTypes"
                        :reduce="returnValue => returnValue.key"
                        class="mb-8"
                      >
                        <template v-slot:no-options>
                          <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                        </template>
                      </v-select>
                    </v-form>

                    <v-input-address
                      id="template-update-origin-search"
                      ref="template-update-origin-search"
                      :get-address="true"
                      :get-radius-bounds="true"
                      :label="`${$t('general.shared.origin')}*`"
                      :radius-bounds="20000"
                      :value="searchLocation(origin)"
                      class="col-12"
                      @address="createLocation($event, true)"
                      @input="checkLocationInput($event, true)"
                    />

                    <v-input-address
                      id="template-update-destination-search"
                      ref="template-update-destination-search-ref"
                      :get-address="true"
                      :get-radius-bounds="true"
                      :label="`${$t('general.shared.destination')}*`"
                      :radius-bounds="20000"
                      :value="searchLocation(destination)"
                      class="col-12"
                      @address="createLocation($event, false)"
                      @input="checkLocationInput($event, false)"
                    />
                  </fieldset>

                  <v-button
                    :disabled="!meta.valid || disableButton || !meta.dirty"
                    :is-loading="accepted"
                    class="btn-icon w-fit align-self-end mt-32 mt-lg-48"
                    size="sm"
                    variant="primary"
                    @click="updateTemplate"
                  >
                    {{ $t('general.button.save') }}
                    <v-icon icon="arrow-right" size="sm" space="ms-12"/>
                  </v-button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <the-template-setup-tour-terms
          :agency="agency.slug"
          :template="template"
          @reload-template="$emit('reloadTemplate')"
        />

        <the-template-before-tour
          :agency="agency.slug"
          :template="template"
          @reload-template="$emit('reloadTemplate')"
        />

        <div class="accordion">
          <div class="template-details accordion-item">
            <h2 id="headingArchive" class="accordion-header">
              <button
                aria-controls="collapseArchive"
                aria-expanded="false"
                class="accordion-button collapsed"
                data-bs-target="#collapseArchive"
                data-bs-toggle="collapse"
                type="button">
                <div class="mb-0 d-flex">
                  <div class="bg-secondary rounded-xs h-fit-content me-8">
                    <v-icon icon="import" size="xxl" space="p-12"/>
                  </div>

                  <div>
                    <div class="h4 ff-secondary mb-0">{{ $t('template.archiveTitle') }}</div>
                    <div class="fw-light accordion-subtitle">{{ $t('template.archiveSubtitle') }}</div>
                  </div>
                </div>
              </button>
            </h2>

            <div
              id="collapseArchive"
              aria-labelledby="headingArchive"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample">
              <div class="accordion-body d-flex flex-column pb-32 pb-xl-48">
                <div class="alert alert-warning d-flex align-items-center" role="alert">
                  <v-icon icon="info" size="lg" space="me-16"/>

                  <div class="text-sm">
                    {{ $t('template.archiveText') }}
                  </div>
                </div>

                <v-button
                  v-if="!template.deletedAt"
                  id="btn-archive-template"
                  :disabled="disableArchiveBtn"
                  class="btn-icon mb-8 align-self-end mt-32 mt-lg-48"
                  size="sm"
                  variant="danger"
                  @click="archiveTemplate"
                >
                  <v-icon icon="import" space="me-12"/>
                  <template v-if="disableArchiveBtn">{{ $t('general.button.archiving') }}</template>
                  <template v-else>{{ $t('general.button.archive') }}</template>
                </v-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid right">
        <the-template-setup-agencies
          v-if="can('template.all')"
          :agency="agency.slug"
          :template="template"
          @reload-template="$emit('reloadTemplate')"
        />

        <the-template-setup-contacts
          :agency="agency.slug"
          :template="template"
          @reload-template="$emit('reloadTemplate')"
        />

        <the-template-after-tour 
          :agency="agency.slug"
          :template="template"
          @reload-template="$emit('reloadTemplate')"
        />

        <the-template-setup-featured-image
          :agency="agency.slug"
          :template="template"
          @reload-template="$emit('reloadTemplate')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { mapState } from 'pinia';
import vSelect from 'vue-select';
import { VForm, VFormInput } from '@uniqoders/form';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useFormValidation from '@/helpers/form';
import { useAuthStore } from '@/stores/auth';
import { mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import Template from '@/api/objects/Template';
import api from '@/api';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import Location from '@/api/objects/Location';
import TheTemplateSetupAgencies from '@/components/template/TheTemplateSetupAgencies.vue';
import TheTemplateSetupContacts from '@/components/template/parts/TheTemplateSetupContacts.vue';
import useCapabilities from '@/helpers/capabilities';
import TheTemplateSetupTourTerms from '@/components/template/TheTemplateSetupTourTerms.vue';
import TheTemplateSetupFeaturedImage from '@/components/template/TheTemplateSetupFeaturedImage.vue';
import TheTemplateBeforeTour from '@/components/template/TheTemplateBeforeTour.vue';
import TheTemplateAfterTour from '@/components/template/TheTemplateAfterTour.vue';

export default defineComponent({
  name: 'TheTemplateSetup',
  components: {
    TheTemplateSetupTourTerms,
    TheTemplateSetupFeaturedImage,
    TheTemplateSetupContacts,
    TheTemplateSetupAgencies,
    TheTemplateBeforeTour,
    TheTemplateAfterTour,
    VIcon,
    VButton,
    vSelect,
    VFormInput,
    VForm,
    VInputAddress,
  },
  props: {
    templateId: {
      type: String,
      required: true,
    },
    template: {
      type: Template,
      required: true,
    },
  },
  emits: ['reloadTemplate'],
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      travelType: yup.string()
        .required()
        .label(t('template.travelType')),
      origin: yup.mixed()
        .required()
        .nullable()
        .label(t('general.shared.origin')),
      originSelected: yup.bool()
        .oneOf([true], t('general.shared.selectOriginMessage')),
      destination: yup.mixed()
        .required()
        .nullable()
        .label(t('general.shared.destination')),
      destinationSelected: yup.bool()
        .oneOf([true], t('general.shared.selectDestinationMessage')),
    });

    const initialValues: Record<string, any> = {
      name: props.template.name,
      travelType: props.template.travelType,
      origin: props.template.originId,
      destination: props.template.destinationId,
    };

    if (!!props.template.originId) {
      initialValues.originSelected = true;
    }

    if (!!props.template.destinationId) {
      initialValues.destinationSelected = true;
    }

    const form = useFormValidation(rules, initialValues);

    const { value: name } = useField('name');
    const { value: travelType } = useField('travelType');
    const { value: origin } = useField('origin');
    const { value: destination } = useField('destination');
    const { value: originSelected } = useField('originSelected');
    const { value: destinationSelected } = useField('destinationSelected');

    return {
      ...useCapabilities(),
      ...useConcepts(),
      ...form,
      name,
      travelType,
      origin,
      destination,
      originSelected,
      destinationSelected,
    };
  },
  data() {
    return {
      locations: [] as Location[],
      highlightedDates: [] as Date[],
      disableButton: false as boolean,
      disableArchiveBtn: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    travelTypes(): any {
      if (!!this.concepts && this.concepts['tour.travel_type']) {
        return mapConcepts(this.concepts['tour.travel_type']);
      }

      return [];
    },
  },
  async created() {
    await this.loadLocations();
  },
  methods: {
    checkLocationInput(value: string, isOrigin = false) {
      if (isOrigin) {
        this.originSelected = false;
      } else {
        this.destinationSelected = false;
      }
    },
    async loadLocations() {
      this.locations = await api.location.all();
    },
    searchLocation(locationId: number): string {
      const locationName = this.locations.find((location) => location.id === locationId);

      if (!!locationName) {
        return locationName.name;
      }

      return '';
    },
    async createLocation(address: Record<string, any>, isOrigin = true) {
      const location: Location = await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });

      await this.loadLocations();

      if (isOrigin) {
        this.origin = location.id;
        this.originSelected = true;
      } else {
        this.destination = location.id;
        this.destinationSelected = true;
      }
    },
    async updateTemplate() {
      try {
        this.toggleAccepted();
        this.disableButton = true;

        const data = {
          name: this.name,
          travel_type: this.travelType,
          origin: this.origin,
          destination: this.destination,
          contact_text: this.template.contactText,
          terms_conditions_text: this.template.termsConditionsText,
        };

        if (!!this.agency) {
          await api.template.update(this.agency.slug, this.templateId, data);

          this.$toast.success(this.$t('general.shared.savedChanges'));
        }

        this.$emit('reloadTemplate');

        this.disableButton = false;
        this.toggleAccepted();
      } catch (e: any) {
        this.disableButton = false;
        this.toggleAccepted();

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
    async archiveTemplate() {
      await this.$modal.delete({
        title: this.$t('template.archiveTitle'),
        text: this.$t('template.archiveAlertText', { product: this.template.name }),
        deleteButtonText: this.$t('general.button.archive'),
        deleteButtonLoadingText: this.$t('general.button.archiving'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doArchiveTemplate,
      });
    },
    async doArchiveTemplate() {
      try {
        if (!!this.agency) {
          this.disableArchiveBtn = true;

          await api.template.archive(this.agency.slug, this.templateId);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          await this.$router.replace({
            name: 'template.index',
          });

          this.disableArchiveBtn = false;
        }
      } catch (e: any) {
        this.disableArchiveBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: .75rem 2rem;
}

.wrapper {
  grid-template-columns: 1fr;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
}

.grid.left {
  grid-column: 1 / span 1;
  grid-auto-rows: min-content;
}

.grid.right {
  grid-column: span 1 / -1;
  grid-auto-rows: min-content;
}
</style>
