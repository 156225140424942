<template>
  <div class="md-dropdown dropdown">
    <v-button
      :id="id"
      class="btn dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      :class="[buttonClasses, classes]"
      :data-bs-auto-close="preventClose ? 'outside' : null"
      :aria-expanded="defaultOpen"
      :data-active="active"
      @click="$emit('click')"
    >
      <slot name="button"/>

      <v-icon
        :icon="icon"
        size="xs"
        class="dropdown-arrow ms-auto"
        v-if="!disableIcon"
      />
    </v-button>

    <ul
      class="dropdown-menu"
      ref="dropdown"
      :aria-labelledby="id"
      :class="[dropdownClasses, collapse]"
      :style="styles"
    >
      <slot name="content"/>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'VDropdown',
  components: {
    VButton,
    VIcon,
  },
  data() {
    return {
      dropdown: null as any,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    defaultOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonClasses: {
      type: [String, Object],
      required: false,
      default: '',
    },
    dropdownClasses: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      default: 'chevron-down',
    },
    disableIcon: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    flat: Boolean,
    active: Boolean,
    preventClose: Boolean,
  },
  computed: {
    classes() {
      return {
        'pe-32': !this.disableIcon,
      };
    },
    collapse() {
      return {
        'px-0 py-8': this.flat,
        show: this.defaultOpen,
      };
    },
    styles() {
      return {
        width: !!this.width ? `${this.width}px` : '',
        'min-width': !!this.width ? `${this.width}px` : '',
        height: !!this.height ? `${this.height}px` : '',
      };
    },
  },
});
</script>

<style lang="scss">
.md-dropdown {
  .dropdown-toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border: none;
    background-color: #EFF0F6;

    .dropdown-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: .75rem;
      transition: transform .3s ease-out;
    }

    &[aria-expanded="true"] {
      .dropdown-arrow {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    border: none;
    background-color: var(--uq-light);
    width: 100%;

    &.dropdown-user {
      min-width: 12rem;
    }

    .btn {
      border-radius: 0;

      &:hover {
        background-color: var(--uq-gray-300);
      }
    }
  }

  .dropdown-menu-notifications {
    max-height: 700px !important;
  }
}
</style>
