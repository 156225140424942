<template>
  <div class="dropdown">
    <v-button
      :id="id"
      :aria-expanded="false"
      class="btn-link dropdown-toggle fw-normal text-dark align-items-center"
      data-bs-toggle="dropdown">
      {{ $t('general.shared.tableShowNumber', { number: selected }) }}
      <span class="icon icon-chevron-down text-xs ms-8"/>
    </v-button>

    <ul :aria-labelledby="ariaLabelledBy" class="dropdown-menu p-8">
      <li v-for="(option, key) in options" :key="key">
        <button :disabled="option === selected" class="dropdown-item" type="button" @click="chooseNumber(option)">
          {{ option }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'VRowsPerPage',
  components: {
    VButton,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    ariaLabelledBy: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: false,
      default: () => [5, 15, 30],
    },
    selected: {
      type: Number,
      required: true,
      default: 15,
    },
  },
  methods: {
    async chooseNumber(number: any) {
      this.$emit('changed', parseInt(number, 10));
    },
  },
});
</script>
