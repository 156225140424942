<template>
  <div class="offcanvas-header">
    <slot/>
<div>
  <slot name="other-button"/>
    <v-button
      v-if="!disableClose"
      :aria-label="this.$t('general.button.close')"
      :title="this.$t('general.button.close')"
      class="fw-medium p-4"
      data-bs-dismiss="offcanvas"
      variant="icon"
    >
      <v-icon icon="close" :size="closeSize"/>
    </v-button>
  </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';

export default defineComponent({
  name: 'OffCanvasHeader',
  components: {
    VIcon,
    VButton,
  },
  props: {
    disableClose: {
      type: String,
      required: false,
      default: null,
    },
    closeSize: {
      type: String,
      required: false,
      default: null,
      validator: (val: string) => ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(val),
    },
  },
});
</script>
