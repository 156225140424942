import { AbstractService } from '@uniqoders/sdk';

export default class UserService extends AbstractService {
  /**
   * Retrieves all User Permissions
   * @protected
   */
  public allPermissions(): Promise<any[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/me/permissions'),
    });
  }
}
