import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import Agency from '@/api/objects/Agency';
import User from '@/api/objects/User';

export interface AgencyUserContract extends IBaseObject {
  agencyId: number;
  userId: number;
  agency: null | Agency;
  user: null | User;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export default class AgencyUser extends BaseObject implements AgencyUserContract {
  public declare agencyId: number;
  public declare userId: number;
  public declare agency: null | Agency;
  public declare user: null | User;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;

  constructor(data: AgencyUserContract) {
    super(data);
  }
}
