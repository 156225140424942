<template>
  <div id="page-resume-contact" class="w-100 w-lg-25">
    <h3 class="h5 mb-12">
      {{ tour.clientTour.assignedTo.agency.name }}
    </h3>

    <div class="agency-container bg-white w-100 text-sm p-28">
      <div v-if="!!tour.clientTour.assignedTo.agency.nif" class="mb-8">
        {{ tour.clientTour.assignedTo.agency.nif }}
      </div>

      <div v-if="!!tour.clientTour.assignedTo.agency.billingAddress1">
        {{ tour.clientTour.assignedTo.agency.billingAddress1 }}
      </div>

      <div
        v-if="!!tour.clientTour.assignedTo.agency.billingPostalCode
          || tour.clientTour.assignedTo.agency.billingCity"
      >
        <span class="me-4">
          {{ tour.clientTour.assignedTo.agency.billingPostalCode }}
        </span>

        <span>{{ tour.clientTour.assignedTo.agency.billingCity }}</span>
      </div>

      <div v-if="!!tour.clientTour.assignedTo.agency.billingCountry" class="mb-8">
        {{ tour.clientTour.assignedTo.agency.billingCountry }}
      </div>

      <div class="mb-8">
        <span class="text-uppercase me-4">{{ $t('general.shared.caseManager') }}: </span>
        <span>{{ tour.clientTour?.assignedTo?.user?.name }}</span>
      </div>

      <div class="mb-8">
        <span class="text-uppercase me-4">{{ $t('general.shared.offers') }}: </span>
        <span>{{ offer }}</span>
      </div>

      <div>
        <span class="text-uppercase me-4">{{ $t('general.shared.date') }}: </span>
        {{ dateNow }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { DateTime } from 'luxon';
import { mapState } from 'pinia';
import Tour from '@/api/objects/Tour';
import { useAppStore } from '@/stores/app';

export default defineComponent({
  name: 'TheTravelBookAgencyBilling',
  props: {
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
    dateNow() {
      return this.$str.formatDateTime(DateTime.local(), this.settings.formatDate);
    },
    offer() {
      return this.tour.name.split(' ')[0];
    },
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}

.agency-container {
  border-radius: .5rem;
  box-shadow: 0 1px .25rem var(--uq-gray-300);
}
</style>
