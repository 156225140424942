export default {
  user: {
    users: 'Usuarios',
    noUsers: 'No hay usuarios',
    createNewUser: 'Crear nuevo usuario',
    userDetails: 'Detalles de usuario',
    basicInformation: 'Información básica',
    password: 'Contraseña',
    passwordChangeText: 'La contraseña no puede ser cambiada, pero puedes enviar un correo electrónico con una nueva contraseña al usuario.',
    sendEmail: 'Enviar correo electrónico',
    archivedUsers: 'Usuarios archivados',
    activeUsers: 'Usuarios activos',
    agencies: 'Agencias',
    userEditAgenciesText: 'Editar las agencias a las que el usuario pertenece.',
    noAgencies: 'No hay agencias asociadas',
    addAgencies: 'Agregar agencias',
    removeAgencyUserTitle: 'Eliminar usuario de la agencia',
    removeAgencyUserText: '¿Estás seguro que quieres eliminar a \'{user} ({email})\' de la agencia \'{agency}\'?',
    addExistingAgency: 'Agregar agencia existente',
    addAgencyUserTitle: 'Agregar usuario a la agencia',
    addAgencyUserText: '¿Estás seguro que quieres agregar a \'{user}\' a la agencia \'{agency}\'?',
    archiveUserTitle: 'Archivar usuario',
    archiveUserText: '¿Estás seguro que quieres archivar al usuario \'{user}\'? Si lo archivas, no aparecerá en búsquedas.',
    deleteUserTitle: 'Eliminar usuario',
    deleteUserText: '¿Estás seguro que quieres eliminar al usuario \'{user}\'? Si lo eliminas, no podrás recuperarlo.',
    denyUserTitle: 'Denegar acceso al usuario',
    denyUserText: '¿Estás seguro de que quieres negar el acceso al usuario \'{user}\'? Si lo haces, este usuario ya no podrá acceder a la plataforma. Si en algún momento es necesario volver a dar acceso al usuario, solo tienes que habilitar de nuevo el acceso del usuario y este podrá acceder a la plataforma.',
    forgiveUserTitle: 'Permitir acceso',
    forgiveUserText: '¿Estás seguro de que quieres permitir el acceso al usuario \'{user}\'? Si lo haces, el usuario recuperará el acceso a la plataforma.',
    loginAs: 'Iniciar sesión como',
    impersonateDescription: 'Puedes iniciar sesión como este usuario y utilizar la aplicación como este usuario. Puedes volver a tu perfil en cualquier momento.',
    impersonateText: 'Iniciarás sesión como el usuario \'{user}\'. Podrás ver y hacer todo lo que el usuario hace y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?',
    impersonateErrorTitle: 'Error al iniciar sesión',
    impersonateErrorText: 'Error al intentar iniciar sesión como otro usuario',
    access: 'Acceso',
    userCanAccessText: 'Se permite el acceso del usuario. Si quieres negar el acceso, haz clic en el botón \'Negar acceso\' y el usuario ya no podrá acceder a la plataforma. Después de negar el acceso, puedes permitir el acceso de nuevo en cualquier momento.',
    userCannotAccessText: 'El usuario no tiene acceso permitido. Si quieres permitir el acceso, haz clic en el botón \'Permitir acceso\' y el usuario podrá acceder a la plataforma.',
    denyAccess: 'Negar acceso',
    denyingAccess: 'Denegando acceso...',
    provideAccess: 'Permitir acceso',
    providingAccess: 'Permitiendo acceso...',
    archiveText: 'Puedes archivar a un usuario, si lo haces, el usuario no aparecerá en las búsquedas de la aplicación. Podrás verlo en la lista de usuarios si filtras por \'archivado\'. Una vez que visualices los detalles de un usuario archivado, puedes eliminarlo definitivamente o puedes desarchivarlo.',
    archivedText: 'Este usuario está archivado, si quieres, puedes desarchivarlo haciendo clic en el botón \'Restaurar\'.',
    deleteText: 'Este usuario está archivado, si quieres eliminarlo de forma permanente haz clic en el botón \'Eliminar\'. Ten en cuenta que si lo eliminas, este usuario ya no existirá y no se podrá recuperar.',
    restoreUserTitle: 'Restaurar usuario',
    restoreUserText: '¿Estás seguro de que quieres restaurar al usuario \'{user}\'? Si lo restauras, ya no estará archivado y aparecerá en las búsquedas de la aplicación.',
    userStatus: 'Estado del usuario',
    agency: 'Agencia',
  },
  agency: {
    agencies: 'Agencias',
    noAgencies: 'No hay agencias',
    createNewAgency: 'Crear nueva agencia',
    agencyDetails: 'Detalles de la agencia',
    basicInformation: 'Información básica',
    archivedAgencies: 'Agencias archivadas',
    activeAgencies: 'Agencias activas',
    users: 'Usuarios',
    editUsersBelongAgencyText: 'Editar usuarios que pertenecen a la agencia',
    noUsers: 'No hay usuarios asociados',
    usersEditBelongAgencyText: 'Editar usuarios que pertenecen a la agencia',
    addUsers: 'Agregar usuarios',
    removeAgencyUserTitle: 'Eliminar usuario de la agencia',
    removeAgencyUserText: '¿Estás seguro de que deseas eliminar a \'{user} ({email})\' de la agencia \'{agency}\'?',
    addExistingUser: 'Agregar usuario existente',
    addAgencyUserTitle: 'Agregar usuario a la agencia',
    addAgencyUserText: '¿Estás seguro de que deseas agregar a {user} a la agencia {agency}?',
    archiveAgencyTitle: 'Archivar agencia',
    archiveAgencyText: '¿Estás seguro de que deseas archivar la agencia \'{agency}\'? Si la archivas, no aparecerá en las búsquedas.',
    deleteAgencyTitle: 'Eliminar agencia',
    deleteAgencyText: '¿Estás seguro de que deseas eliminar la agencia \'{agency}\'? Si la eliminas, no podrás recuperarla.',
    archiveText: 'Puedes archivar una agencia, si lo haces, la agencia no aparecerá en las búsquedas de la aplicación. Podrás verla en la lista de agencias si filtras por "archivadas". Una vez que veas los detalles de una agencia archivada, podrás eliminarla definitivamente o desarchivarla.',
    archivedText: 'Esta agencia está archivada, si quieres, puedes desarchivarla haciendo clic en el botón "Restaurar".',
    deleteText: 'Esta agencia está archivada, si quieres eliminarla permanentemente, haz clic en el botón "Eliminar". Ten en cuenta que si la eliminas, esta agencia ya no existirá y no se podrá recuperar.',
    restoreAgencyTitle: 'Recuperar agencia',
    restoreAgencyText: '¿Estás seguro de que deseas recuperar la agencia \'{agency}\'? Si la recuperas, ya no estará archivada y aparecerá en las búsquedas de la aplicación.',
    agencyStatus: 'Estado de la agencia',
    contacts: 'Contactos',
    noContacts: 'No hay contactos asociados',
    editContactsBelongAgencyText: 'Editar contactos pertenecientes a la agencia',
    addContacts: 'Agregar contactos',
    removeContactTitle: 'Eliminar contacto de la agencia',
    removeContactText: '¿Estás seguro de que deseas eliminar {contact} como contacto de la agencia {agency}?',
    template: {
      title: 'Actividades',
      subtitle: 'Actividades asociados con esta agencia',
      noTemplates: 'Esta agencia no tiene ningúna actividad asociada',
      addExistingTemplate: 'Agregar actividad existente',
      editTemplatesBelongsTo: 'Editar las actividades a las que pertenece esta agencia',
      addTemplate: 'Agregar actividad',
      addTemplates: 'Agregar actividades',
      addTemplateToAgencyTitle: 'Agregar actividad a la agencia',
      addTemplateToAgencyTitleText: '¿Estás seguro de que deseas asociar la actividad \'{ template }\' con la agencia \'{ agency }\'?',
      detachTemplateFromAgencyTitle: 'Desasociar actividad de la agencia',
      detachTemplateFromAgencyTitleText: '¿Estás seguro de que deseas desasociar la actividad \'{ template }\' de la agencia \'{ agency }\'?',
    },
    user: 'Usuario',
  },
  collaborator: {
    collaborators: 'Colaboradores',
    noCollaborators: 'No hay colaboradores',
    createNewCollaborator: 'Crear nuevo colaborador',
    collaboratorDetails: 'Detalles de la empresa colaboradora',
    basicInformation: 'Información básica',
    archivedCollaborators: 'Colaboradores archivados',
    activeCollaborators: 'Colaboradores activos',
    users: 'Usuarios colaboradores',
    editUsersBelongCollaboratorText: 'Editar usuarios pertenecientes al colaborador',
    noUsers: 'No hay usuarios asociados',
    usersEditBelongCollaboratorText: 'Editar usuarios pertenecientes al colaborador',
    addUsers: 'Agregar usuarios',
    removeCollaboratorUserTitle: 'Eliminar usuario del colaborador',
    removeCollaboratorUserText: '¿Estás seguro de que quieres eliminar al usuario \'{user} ({email})\' del colaborador \'{agency}\'?',
    addExistingUser: 'Agregar usuario existente',
    addCollaboratorUserTitle: 'Agregar usuario al colaborador',
    addCollaboratorUserText: '¿Estás seguro de que quieres agregar a {user} al colaborador {collaborator}?',
    archiveCollaboratorTitle: 'Archivar colaborador',
    archiveCollaboratorText: '¿Estás seguro de que quieres archivar el colaborador \'{agency}\'? Si lo archivas, no aparecerá en las búsquedas.',
    deleteCollaboratorTitle: 'Eliminar colaborador',
    deleteCollaboratorText: '¿Estás seguro de que quieres eliminar el colaborador \'{agency}\'? Si lo eliminas, no podrás recuperarlo.',
    archiveText: 'Puedes archivar un colaborador, si lo haces, el colaborador no aparecerá en las búsquedas de la aplicación. Podrás verlo en la lista de colaboradores si filtras por \'archivados\'. Una vez que veas los detalles de un colaborador archivado, puedes eliminarlo definitivamente o puedes restaurarlo.',
    archivedText: 'Este colaborador está archivado, si lo deseas puedes restaurarlo haciendo clic en el botón \'Restaurar\'.',
    deleteText: 'Este colaborador está archivado, si deseas eliminarlo permanentemente haz clic en el botón \'Eliminar\'. Ten en cuenta que si lo eliminas, este colaborador dejará de existir y no se podrá recuperar.',
    restoreCollaboratorTitle: 'Recuperar colaborador',
    restoreCollaboratorText: '¿Estás seguro de que quieres recuperar el colaborador \'{agency}\'? Si lo recuperas, ya no estará archivado y aparecerá en las búsquedas de la aplicación.',
    collaboratorStatus: 'Estado del colaborador',
    contacts: 'Contactar personas',
    noContacts: 'No hay contactos asociados',
    editContactsBelongCollaboratorText: 'Editar contactos pertenecientes al colaborador',
    addContacts: 'Agregar contactos',
    removeContactTitle: 'Eliminar contacto del colaborador',
    removeContactText: '¿Estás seguro de que quieres eliminar a {contact} como contacto del colaborador {collaborator}?',
    template: {
      title: 'Activities', // No change
      subtitle: 'Actividades asociadas con este colaborador',
      noTemplates: 'Este colaborador no tiene ninguna actividad asociada',
      addExistingTemplate: 'Agregar actividad existente',
      editTemplatesBelongsTo: 'Edita las actividades a las que pertenece este colaborador',
      addTemplate: 'Agregar actividad',
      addTemplates: 'Agregar actividades',
      addTemplateToCollaboratorTitle: 'Agregar actividad al colaborador',
      addTemplateToCollaboratorTitleText: '¿Estás seguro de que quieres asociar la actividad \'{ template }\' con el colaborador \'{ agency }\'?',
      detachTemplateFromCollaboratorTitle: 'Desasignar actividad del colaborador',
      detachTemplateFromCollaboratorTitleText: '¿Estás seguro de que quieres desasignar la actividad \'{ template }\' del colaborador \'{ agency }\'?',
    },
    user: 'Usuario',
  },
};
