<template>
  <v-off-canvas
    id="supplier-details-offcanvas"
    :backdrop-static="false"
    :offcanvas-class="['item-details offcanvas-end', { 'double-offcanvas': isDoubleOffCanvas }]"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <div class="d-flex align-items-center">
        <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

        <h5 id="supplier-details-offcanvas-label" class="offcanvas-title">
          {{ $t('supplier.supplierDetails') }}
        </h5>
      </div>
      </off-canvas-header>

      <off-canvas-body class="justify-content-between d-flex flex-column">
      <v-loader v-if="status === 'loading'"/>

      <div v-else class="item-details">
        <the-supplier-details-basic-information
          :supplier="supplier"
          @reload-supplier="loadSupplier"
          @emit-reload-suppliers="$emit('reloadSuppliers')"
        />

        <the-supplier-details-media
          v-if="!supplier.deletedAt"
          :supplier="supplier"
          @reload-supplier="loadSupplier"
        />

        <the-supplier-details-contacts
          v-if="!supplier.deletedAt"
          :supplier="supplier"
          @reload-supplier="loadSupplier"
        />

        <the-supplier-details-resources
          v-if="!supplier.deletedAt"
          :supplier="supplier"
          @reload-supplier="loadSupplier(false)"
        />

        <div class="detail pb-0 mb-0">
          <div class="detail-title fw-medium mb-20">
            <template v-if="!supplier.deletedAt">{{ $t('general.shared.archive') }}</template>
            <template v-else>{{ $t('general.shared.restore') }}</template>
          </div>

          <div class="detail-content">
            <p v-if="!supplier.deletedAt" class="text-sm">{{ $t('supplier.archiveText') }}</p>
            <p v-else class="text-sm">{{ $t('supplier.archivedText') }}</p>

            <v-button
              v-if="!supplier.deletedAt"
              id="btn-archive-supplier"
              :disabled="disableArchiveBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="archiveSupplier"
            >
              <v-icon icon="import" space="me-12"/>
              <template v-if="disableArchiveBtn">{{ $t('general.button.archiving') }}</template>
              <template v-else>{{ $t('general.button.archive') }}</template>
            </v-button>

            <v-button
              v-else
              id="btn-restore-supplier"
              :disabled="disableRestoreBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="primary"
              @click="restoreSupplier"
            >
              <v-icon icon="unarchive" space="me-12"/>
              <template v-if="disableRestoreBtn">{{ $t('general.button.restoring') }}</template>
              <template v-else>{{ $t('general.button.restore') }}</template>
            </v-button>
          </div>
        </div>

        <div v-if="!!supplier.deletedAt" class="detail">
          <div class="detail-title fw-medium mb-20">
            {{ $t('general.shared.delete') }}
          </div>

          <div class="detail-content">
            <p class="text-sm">{{ $t('supplier.deleteText') }}</p>

            <v-button
              id="btn-delete-supplier"
              :disabled="disableDeleteBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="deleteSupplier"
            >
              <v-icon icon="trash" space="me-12"/>
              <template v-if="disableDeleteBtn">{{ $t('general.button.deleting') }}</template>
              <template v-else>{{ $t('general.button.delete') }}</template>
            </v-button>
          </div>
        </div>
      </div>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import useModalUtils from '@/helpers/ModalUtils';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useConcepts from '@/helpers/Concepts';
import TheSupplierDetailsBasicInformation from '@/components/supplier/parts/TheSupplierDetailsBasicInformation.vue';
import TheSupplierDetailsResources from '@/components/supplier/parts/TheSupplierDetailsResources.vue';
import TheSupplierDetailsContacts from '@/components/supplier/parts/TheSupplierDetailsContacts.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import Supplier from '@/api/objects/Supplier';
import TheSupplierDetailsMedia from '@/components/supplier/parts/TheSupplierDetailsMedia.vue';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheSupplierDetailsOffcanvas',
  components: {
    TheSupplierDetailsMedia,
    VLoader,
    TheSupplierDetailsContacts,
    TheSupplierDetailsResources,
    TheSupplierDetailsBasicInformation,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
  },
  emits: ['closed', 'reloadSuppliers'],
  props: {
    supplierData: {
      type: Supplier,
      required: true,
    },
    isArchived: {
      type: Boolean,
      required: true,
    },
    isDoubleOffCanvas: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      ...useConcepts(),
      ...useModalUtils(),
    };
  },
  data() {
    return {
      supplier: {} as Supplier,
      status: 'loading' as string,
      editBasicInformation: false as boolean,
      disableArchiveBtn: false as boolean,
      disableDeleteBtn: false as boolean,
      disableDenyForgiveBtn: false as boolean,
      disableImpersonateBtn: false as boolean,
      disableRestoreBtn: false as boolean,
    };
  },
  watch: {
    async supplierData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.isArchived) {
          await this.loadSupplier();
        } else {
          this.supplier = this.supplierData;

          this.status = 'loaded';
        }
      }
    },
  },
  async created() {
    if (!this.isArchived) {
      await this.loadSupplier();
    } else {
      this.supplier = this.supplierData;

      this.status = 'loaded';
    }
  },
  methods: {
    async loadSupplier(loading = true) {
      if (loading) {
        this.status = 'loading';
      }

      this.supplier = await api.supplier.retrieve(this.supplierData.id);      

      if (loading) {
        this.status = 'loaded';
      }
    },
    async archiveSupplier() {
      await this.$modal.delete({
        title: this.$t('supplier.archiveSupplierTitle'),
        text: this.$t('supplier.archiveSupplierText', { supplier: this.supplier?.name }),
        deleteButtonText: this.$t('general.button.archive'),
        deleteButtonLoadingText: this.$t('general.button.archiving'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doArchiveSupplier,
      });
    },
    async doArchiveSupplier() {
      try {
        this.disableArchiveBtn = true;

        await api.supplier.archive(this.supplier.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.disableArchiveBtn = false;

        this.$emit('reloadSuppliers');
        this.$emit('closed');
      } catch (e: any) {
        this.disableArchiveBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async restoreSupplier() {
      await this.$modal.confirm({
        title: this.$t('supplier.restoreSupplierTitle'),
        text: this.$t('supplier.restoreSupplierText', { supplier: this.supplier?.name }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doRestoreSupplier,
      });
    },
    async doRestoreSupplier() {
      try {
        this.disableRestoreBtn = true;

        await api.supplier.restore(this.supplier.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.disableRestoreBtn = false;

        this.$emit('reloadSuppliers');
        this.$emit('closed');
      } catch (e: any) {
        this.disableRestoreBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async deleteSupplier() {
      await this.$modal.delete({
        title: this.$t('supplier.deleteSupplierTitle'),
        text: this.$t('supplier.deleteSupplierText', { supplier: this.supplier?.name }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doDeleteSupplier,
      });
    },
    async doDeleteSupplier() {
      try {
        this.disableDeleteBtn = true;

        await api.supplier.delete(this.supplier.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.disableDeleteBtn = false;

        this.$emit('reloadSuppliers');
        this.$emit('closed');
      } catch (e: any) {
        this.disableDeleteBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
