export default {
  pricing: 'Fijación de precios',
  profit: 'Beneficio',
  netPrice: 'Precio de coste',
  percentageProfit: '% Beneficio',
  grossPrice: 'Precio de venta',
  dates: 'Fechas',
  supplierTypeTotal: '{supplierType} total',
  updatePriceTitle: 'Actualizar precio total',
  updatePriceText: 'El total \'{ newTotal }\' no coincide con su antiguo valor \'{ initialValue }\'. Si cambias este total, ten en cuenta que los precios de los recursos cambiarán para coincidir. ¿Seguro que quieres hacerlo?',
  updateTotalPriceText: 'El total \'{ newTotal }\' no coincide con su antiguo valor \'{ initialValue }\'. Si modifica este total, tenga en cuenta que el precio del recorrido cambiará pero los precios de los productos seguirán siendo los mismos. Si modifica el precio de algún producto, el total se recalculará en función de los precios de los productos. ¿Está seguro de que desea hacerlo?',
  total: 'Total',
  newInvoice: 'Nueva factura',
  invoice: {
    noResourcesSelectedMessage: 'Seleccione un producto para crear una factura',
    createNew: 'Crear nueva factura emitida',
    basicInformation: 'Información básica',
    number: 'Número de factura',
    amount: 'Importe',
    address: 'Dirección',
    resource: 'Recurso',
    resources: 'Recursos',
    status: 'Estado',
    idIdentity: 'NIF',
    vat: 'Número de IVA',
    type: 'Tipo',
    currency: 'Moneda',
  },
  nights: 'Noches',
  dayNumber: 'Número de día',
  dayNumberShort: 'Nº día',
  quantity: 'Cantidad',
  quantityShort: 'Cant.',
  splitPayment: 'Pago dividido',
};
