<template>
  <div>
    <fieldset v-if="language === currentLanguage">
      <legend class="visually-hidden">{{ $t('resource.basicInformation') }}</legend>
        <v-form-input
          id="resource-create-title"
          v-model="titleLocal"
          :disabled="disabledFields"
          :label="`${$t('general.shared.title')}*`"
          :yup-errors-variable="errors.titleLocal"
          autocomplete="off"
          form-type="outline"
          @input="$emit('update:title', titleLocal)"
        />
      <template v-if="!isStage && !isGeneral">
        <v-form
          v-if="!isInformation"
          id="resource-types-select"
          :disabled="disabledFields"
          :label="$t('general.shared.types')"
          :yup-errors-variable="errors.typesLocal"
          form-type="outline"
        >
          <v-select
            v-model="typesLocal"
            :class="{ 'is-invalid': !!errors.typesLocal }"
            :disabled="disabledFields"
            :options="resourceTypes"
            :reduce="(returnValue: any) => returnValue.key"
            multiple
            @option:selected="$emit('update:types', typesLocal)"
            @option:deselected="$emit('update:types', typesLocal)"
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
            </template>
          </v-select>
        </v-form>
      </template>
      <template v-if="isStage && updateSection === 'updateResource'">
        <v-input-address
          id="tour-day-item-resource-create-origin"
          :get-address="true"
          :get-radius-bounds="true"
          :label="`${$t('general.address.city')}*`"
          :radius-bounds="20000"
          :value="city"
          label-class="text-sm"
          :disabled-input="true"
        />
        <v-input-address
          id="tour-day-item-resource-create-origin"
          :get-address="true"
          :get-radius-bounds="true"
          :label="`${$t('general.shared.destination')}*`"
          :radius-bounds="20000"
          :value="destination"
          label-class="text-sm"
          :disabled-input="true"
        />
        <v-form-input
          id="tour-day-item-resource-create-origin"
          :label="`${$t('general.shared.distanceKm')}*`"
          v-model="distanceLocal"
          label-class="text-sm"
          autocomplete="off"
          form-type="outline"
          @input="$emit('update:distance', distanceLocal)"
        />
        <v-form
          id="resource-types-select"
          :label="$t('general.shared.types')"
          :yup-errors-variable="errors.typesLocal"
          form-type="outline"
          disabled
        >
          <v-select
            v-model="typesLocal"
            :class="{ 'is-invalid': !!errors.typesLocal }"
            :options="travelTypes"
            :reduce="(returnValue: any) => returnValue.key"
            @option:selected="$emit('update:types', typesLocal)"
            @option:deselected="$emit('update:types', typesLocal)"
            disabled
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
            </template>
          </v-select>
        </v-form>
      </template>

      <div class="form-outline">
        <label :class="{ disabled: disabledFields }" class="label">
          <span>{{ $t('general.shared.description') }}</span>
        </label>

        <v-html-editor
          id="resource-create-description"
          ref="resource-create-description-ref"
          :disabled="disabledFields"
          :value="descriptionLocal"
          @input="descriptionLocal = $event; $emit('update:description', descriptionLocal)"
          @media-uploaded="$emit('mediaUploaded', $event)"
        />
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VForm, VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import vSelect from 'vue-select';
import useFormValidation from '@/helpers/form';
import useConcepts from '@/helpers/Concepts';
import { mapConcepts } from '@/helpers/ConceptHelper';
import VHtmlEditor from '@/components/vendor/htmlEditor/VHtmlEditor.vue';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';

export default defineComponent({
  name: 'TheResourceCreateBasicInformationFieldset',
  components: {
    VFormInput,
    VForm,
    vSelect,
    VHtmlEditor,
    VInputAddress
  },
  emits: [
    'closed',
    'reloadResources',
    'update:title',
    'update:types',
    'update:description',
    'update:city',
    'update:destination',
    'update:distance',
    'isValid',
    'mediaUploaded',
    'updateIsStage',
    'typesChecking',
  ],
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    types: {
      type: Array,
      required: false,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    isStage: {
      type: Boolean,
      required: false,
      default: false,
    },
    isGeneral: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    language: {
      type: String,
      required: true,
      default: '',
    },
    currentLanguage: {
      type: String,
      required: true,
      default: '',
    },
    clear: {
      type: Boolean,
      default: false,
    },
    isInformation: {
      type: Boolean,
      required: false,
      default: false,
    },
    updateSection: {
      type: String,
    },
    destination: {
      type: String,
    },
    city: {
      type: String,
    },
    distance: {
      type: String,
    }
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      titleLocal: yup.string()
        .required()
        .label(t('general.shared.title')),
      descriptionLocal: yup.string()
        .nullable()
        .label(t('general.shared.description')),
      typesLocal: yup.array()
        .nullable()
        .label(t('general.shared.types')),
    });

    const initialValues = {
      titleLocal: props.title,
      descriptionLocal: props.description,
      typesLocal: props.types,
      destination: props.destination,
      city: props.city,
      distanceLocal: props.distance
    };

    const form = useFormValidation(rules, initialValues);

    const { value: titleLocal } = useField('titleLocal');
    const { value: descriptionLocal } = useField('descriptionLocal');
    const { value: typesLocal } = useField('typesLocal');
    const { value: destinationLocal } = useField('destination');
    const { value: cityLocal } = useField('city');
    const { value: distanceLocal } = useField('distanceLocal');

    return {
      ...useConcepts(),
      ...form,
      titleLocal,
      descriptionLocal,
      typesLocal,
      destinationLocal,
      cityLocal,
      distanceLocal
    };
  },
  data() {
    return {
      travelType: '' as string,
    }
  },
  computed: {
    isValid() {
      return this.meta.valid && this.meta.dirty;
    },
    resourceTypes(): any {
      if (!!this.concepts && this.concepts['resource.types']) {
        return mapConcepts(this.concepts['resource.types']);
      }

      return [];
    },
    travelTypes(): any {
      if (!!this.concepts && this.concepts['tour.travel_type']) {
        return mapConcepts(this.concepts['tour.travel_type']);
      }

      return [];
    },
    computedTitle() {
      return `${this.city}-${this.destination}-${this.typesLocal}`;
    },
    // computedCreatedTitle() {        
    //   return `${this.cityLocal}-${this.destinationLocal}-${this.typesLocal}`
    // },
  },
  watch: {
    title(value) {      
      this.titleLocal = value;
    },
    types(value) {
      this.typesLocal = value;
      this.$emit('typesChecking');
    },
    description(value){
      this.descriptionLocal = value;
    },
    city(value) {
      this.cityLocal = value;
    },
    destination(value) {
      this.destinationLocal = value;
    },
    clear() {
      this.resetForm();
    },
    distance(value) {
      this.distanceLocal = value;
    },
    isValid(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('isValid', newValue);
      }
    },
  },
});
</script>
