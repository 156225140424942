export default {
  statuses: "Statussen",
  bookingStatus: "Boekingsstatus",
  paymentStatus: "Betaalstatus",
  splitPayment: "de kosten delen",
  invoiceStatus: "Factuurstatus",
  status: "status",
  dates: "Data",
  noBookings: "Middelen zijn nog niet toegevoegd aan de Tour, dus Tour-statussen kunnen niet worden bijgewerkt.",
  selectResource: "Selecteer bron",
  sendNotification: "Kennisgeving verzenden",
  notification: "Melding",
  notificationChannel: "Kanaal",
  notificationEmail: "E-mailadres",
  noResourcesSelectedMessage: "Selecteer bronnen om de meldingen te verzenden",
  supplierNoEmail: "Geen e-mail",
  emailModal: {
    title: "E-mail verzenden naar leveranciers",
    text: "Weet je zeker dat je de e-mail naar leveranciers wilt sturen?",
  },
  teamName: "Teamnaam",
  roomType: "Kamertype",
  quantity: "Aantal",
  pax: "Pax",
  noBookingsFound: "Geen boekingen gevonden",
  caseName:  "Case naam",
  clientName:  "Klantnaam",
  journeyRoute: "Reisroute",
  product: "Product",
};
