<template>
  <div class="detail">
    <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
      <div class="fw-medium">{{ $t('admin.user.agencies') }}</div>

      <v-button
        class="btn-edit p-4"
        variant="icon"
        @click="openModal('the-admin-user-agencies-modal')">
        <v-icon icon="circle-plus"/>
      </v-button>
    </div>

    <div class="detail-content">
      <div class="agencies-container card-item-list">
        <div v-if="!userDetails.agencyUsers || userDetails.agencyUsers.length === 0" class="text-sm">
          {{ $t('admin.user.noAgencies') }}
        </div>

        <template v-else>
          <div
            v-for="agencyUser in userDetails.agencyUsers"
            :key="agencyUser.agency.id"
            class="agency item-card border rounded-xs d-flex justify-content-between align-items-center
          p-8 p-lg-12 mb-8"
          >
            <div class="d-flex align-items-center w-100 me-8">
              <div class="image-container rounded-sm bg-tertiary me-12">
                {{ agencyUser.agency.name?.charAt(0) }}
              </div>

              <div
                class="w-100 d-flex flex-column text-sm">
                <div class="fw-medium">{{ agencyUser.agency.name }}</div>
                <div class="text-sm fw-light text-gray-500">
                  <template v-if="!!agencyUser.agency.location">{{ agencyUser.agency.location }}</template>
                  <template v-else>{{ $t('general.shared.noLocation') }}</template>
                </div>
              </div>
            </div>

            <div class="d-flex">
              <v-button
                class="shadow-none p-2"
                variant="icon"
                @click="showAgencyDetail(agencyUser.agency)"
              >
                <v-icon icon="eye"/>
              </v-button>

              <v-button
                :disabled="disableUnassignAgencyUserBtn || !canRemoveAgencyUser()"
                class="shadow-none p-2"
                variant="icon"
                @click="unassignAgencyUser(agencyUser.agency)"
              >
                <v-icon icon="trash"/>
              </v-button>
            </div>
          </div>
        </template>
      </div>
    </div>

    <the-admin-user-add-agencies-modal
      v-if="modalToShow === 'the-admin-user-agencies-modal'"
      :user="userDetails"
      @closed="closeModal"
      @emit-reload-user="reloadUser"
    />

    <the-admin-user-agency-details-modal
      v-if="modalToShow === 'the-admin-user-agency-details-modal'"
      :agency="currentAgency!"
      @closed="closeModal(); currentAgency = null"
      @reload-item="reloadUser"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useModalUtils from '@/helpers/ModalUtils';
import api from '@/api';
import User from '@/api/objects/User';
import Agency from '@/api/objects/Agency';
import TheAdminUserAgencyDetailsModal from '@/components/admin/user/parts/TheAdminUserAgencyDetailsModal.vue';
import TheAdminUserAddAgenciesModal from '@/components/admin/user/TheAdminUserAddAgenciesModal.vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'TheAdminUserDetailsAgencies',
  components: {
    TheAdminUserAddAgenciesModal,
    TheAdminUserAgencyDetailsModal,
    VIcon,
    VButton,
  },
  emits: [
    'closed',
    'reloadUser',
  ],
  props: {
    userDetails: {
      type: [User, Object],
      required: true,
    },
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
      ...useModalUtils(),
    };
  },
  data() {
    return {
      currentAgency: null as null | Agency,
      disableUnassignAgencyUserBtn: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    ...mapState(useAuthStore, ['agency']),
  },
  watch: {
    userDetails(value) {
      if (!!this.currentAgency && !!value) {
        this.currentAgency = value.agencyUsers.find(
          (agencyUser: any) => agencyUser.agency.id === this.currentAgency?.id)?.agency;
      }
    },
  },
  methods: {
    canRemoveAgencyUser() {
      let canRemove = true;

      if (this.user!.id === this.userDetails.id && !!this.userDetails.agencyUsers
        && this.userDetails.agencyUsers.length === 1) {
        canRemove = false;
      }

      return canRemove;
    },
    async showAgencyDetail(agency: Agency) {
      this.currentAgency = agency;

      if (this.modalToShow !== 'the-admin-user-agency-details-modal') {
        this.openModal('the-admin-user-agency-details-modal');
      }
    },
    async unassignAgencyUser(agency: Agency) {
      await this.$modal.delete({
        title: this.$t('admin.user.removeAgencyUserTitle'),
        text: this.$t('admin.user.removeAgencyUserText', {
          user: this.userDetails?.name,
          agency: agency.name,
          email: this.userDetails?.email,
        }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doUnassignAgencyUser(agency.id),
      });
    },
    async doUnassignAgencyUser(agencyId: string | number) {
      try {
        this.disableUnassignAgencyUserBtn = true;

        await api.admin.agency.attachUser(agencyId, this.userDetails.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        if (this.userDetails.id === this.user!.id) {
          const reload = this.agency!.id === agencyId;

          await this.authStore.setAuthUser(reload);
        }

        await this.reloadUser();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableUnassignAgencyUserBtn = false;
      }
    },
    reloadUser() {
      this.$emit('reloadUser');
    },
  },
});
</script>
