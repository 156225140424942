export default {
  headerTile: 'Alles wat je moet weten over jouw reis',
  contact: 'Contact',
  noContacts: 'Er zijn geen contacten',
  tourResume: 'Overzicht',
  stageInformation:"Informatie over de etappe",
  extraField: 'extra\'s',
  day: 'Dag',
  tour: 'Jouw reis',
  flight: 'Vlucht',
  transfer: 'Transfer',
  transportLuggage: 'Transport van uw bagage',
  stageNumOriginDestination: 'Etappe: {origin} - {destination}',
  distanceNumberKm: 'Afstand: {number} Km',
  notSelectTransfer: 'U heeft nog geen transfer geselecteerd',
  notSelectTransport: 'U heeft nog geen transport geselecteerd',
  accommodation: 'Accommodatie',
  information: 'Informatie',
  quantityNights: 'Geen nachten | Eén nacht | {quantity} nachten',
  totalPrice: 'Totaalprijs',
  whatToDoLocation: 'Wat te doen in {location}',
  urlToLocationMap: 'URL naar kaart van {location}',
  urlToLocationWeb: 'URL naar website van {location}',
  website: 'Website',
  map: 'Kaart',
  linkAboutDay: 'Link over de dag',
  guidebookLocation: 'Tips: {location}',
  viewWebsite: 'Meer informatie',
  viewGoogleMaps: 'Bekijken in Google Maps',
  dayInformation: 'Beschrijving',
  guidebookOfLocation: 'Tips van {location}',
  linkGoogleMapsResource: 'Link naar Google Maps van {resource}',
  linkResourceWebsite: 'Link naar {resource} website',
  address: 'Adres',
  noResourcesLocation: 'Er zijn geen bronnen toegevoegd aan deze locatie',
  viewWebsiteOfSupplier: 'Bekijk de website van {supplier}',
  urlGoogleMapsOfSupplier: 'URL naar Google Maps van {supplier}',
  travelInformation: 'Uw reisinformatie',
  pricePerPerson: 'Prijs per persoon',
  downloadErrorText: 'Er is een fout opgetreden tijdens het downloaden',
  pricePerUnit: '€/unit',
};
