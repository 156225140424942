import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import { App } from 'vue';
import router from '@/router';

function initSentry(app: App) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_API_BASE_URL, /^https:\/\/api-waw.uniqoderslab\.com\/api/, /^https:\/\/api.waw\.travel\/api/],
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    trackComponents: true,
    timeout: 500,
    hooks: ['activate', 'mount', 'update', 'destroy'],
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACE_SAMPLE,
    beforeSend(event, hint) {
      if (hint.originalException instanceof axios.Cancel) {
        return null;
      }

      return event;
    },
  });
}

export default initSentry;
