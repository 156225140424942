<template>
  <v-modal
    id="template-day-item-create"
    class-name="modal-fullscreen-sm-down"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header class="justify-content-end pt-0"/>

    <modal-body>
      <div class="item-types-container w-100 row gx-16">
        <div v-for="type in itemTypes" :key="type.key" class="item-type-container mb-16 col-6">
          <a
            class="text-decoration-none d-flex flex-row align-items-center"
            href="#"
            @click.prevent="addItem(type.key)"
          >
            <div class="item-type-square bg-secondary me-16">
              <v-icon
                :icon="type.icon"
                class="text-xxl text-black"/>
            </div>

            <div class="item-type-info fw-medium mb-4">{{ type.label }}</div>
          </a>
        </div>
      </div>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TYPE } from 'vue-toastification';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import useConcepts from '@/helpers/Concepts';
import { mapConcepts } from '@/helpers/ConceptHelper';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';

export default defineComponent({
  name: 'TheTemplateDayItemCreateModal',
  components: {
    ModalBody,
    ModalHeader,
    VModal,
    VIcon,
  },
  emits: [
    'closed',
    'emitReloadTour',
    'openDetails',
  ],
  props: {
    agency: {
      type: String,
      required: true,
    },
    templateId: {
      type: Number,
      required: true,
    },
    tourDayId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      ...useConcepts(),
    };
  },
  data() {
    return {
      disableUnassignAgencyUserBtn: false as boolean,
    };
  },
  computed: {
    itemTypes(): any {
      if (!!this.concepts && this.concepts['supplier.type']) {
        return mapConcepts(this.concepts['supplier.type']);
      }

      return [];
    },
  },
  methods: {
    getItemClass(type: string) {
      let item: Record<string, string> = {};

      switch (type.toLowerCase()) {
        case 'hotel':
          item = {
            icon: 'bed',
            background: 'bg-primary',
            iconColor: 'text-white',
          };
          break;
        case 'transfer':
          item = {
            icon: 'airplane',
            background: 'bg-secondary',
            iconColor: 'text-dark',
          };
          break;
        case 'transport':
          item = {
            icon: 'car',
            background: 'bg-tertiary',
            iconColor: 'text-white',
          };
          break;
        default:
          item = {
            icon: 'bed',
            background: 'bg-primary',
            iconColor: 'text-white',
          };
          break;
      }

      return item;
    },
    async addItem(type: string) {
      const loadingToast = this.$toast.loading(this.$t('general.button.adding'), 'toast-add-template-day-item');

      try {
        const response = await api.templateDayItem.create(
          this.agency,
          this.templateId,
          this.tourDayId,
          {
            type,
          },
        );

        this.$emit('closed');
        this.$emit('openDetails', {
          id: response.id,
          tourDayId: response.tourDayId,
        });
        this.$emit('emitReloadTour');

        loadingToast.update('toast-add-template-day-item', {
          content: this.$t('tourBuilder.dayItemCreated'),
          options: {
            type: TYPE.SUCCESS,
            icon: 'icon icon-check-circle-fill',
            timeout: 5000,
          },
        });
      } catch (e: any) {
        loadingToast.update('toast-add-template-day-item', {
          content: this.$t('tourBuilder.dayITemCreateError'),
          options: {
            type: TYPE.ERROR,
            icon: 'icon icon-exclamation-triangle-fill',
            timeout: 5000,
          },
        });

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.item-types-container {
  .item-type-square {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .25rem;
    width: 3.5rem;
    height: 3.5rem;
  }
}
</style>
