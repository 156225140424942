export default {
  resources: 'Resources',
  noResources: 'There are no resources',
  createNewResource: 'Create new resource',
  createNewInformation: 'Create new information',
  archivedResources: 'Archived resources',
  activeResources: 'Active resources',
  basicInformation: 'Basic information',
  resourceDetails: 'Resource details',
  archiveText: 'You can archive a resource, if you do this the resource will not appear in the application searches. You will be able to see it in the list of resources if you filter by \'archived\'. Once you view the details of an archived resource, you can delete it definitively or you can unarchive it.',
  media: 'Media',
  noMedia: 'There are no associated media',
  archivedText: 'This resource is archived, if you want you can unarchive it by clicking on the \'Restore\' button.',
  archiveResourceTitle: 'Archive resource',
  archiveResourceText: 'Are you sure you want to archive the resource \'{resource}\'? If you archive it, it will not appear in searches.',
  deleteText: 'This resource is archived, if you want to delete it permanently click the \'Delete\' button. Note that if you delete it, this resource will no longer exist and cannot be recovered.',
  deleteResourceTitle: 'Delete resource',
  deleteResourceText: 'Are you sure you want to delete the resource \'{resource}\'? If you delete it, you will not be able to recover it.',
  restoreResourceText: 'Are you sure you want to recover the resource \'{resource}\'? If you recover it, it will no longer be archived and will appear in the application searches.',
  resourcesStatus: 'Resources Status',
  resourceSearch: 'Resource search',
  canSearchResource: 'You can search for the resource',
  orUpdateItManually: 'or update it manually',
  removeMediaTitle: 'Delete media',
  removeMediaText: 'Are you sure you want to delete the archive? If you delete it, you will not be able to recover it.',
  informationDetails: 'Details of the information',
};
