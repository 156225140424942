import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface CasesCountInterface extends IBaseObject {
  total_cases: number,
  completed_cases: number
  pending_cases: number
}

export default class CasesCount extends BaseObject implements CasesCountInterface {
  public declare total_cases: number;
  public declare completed_cases: number;
  public declare pending_cases: number;

  constructor(data: CasesCount) {
    super(data);
  }
}
