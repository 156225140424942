import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import Tour from '@/api/objects/Tour';
import TourDayItem from '@/api/objects/TourDayItem';
import Location from '@/api/objects/Location';
import TourDayTranslation from '@/api/objects/TourDayTranslation';
import Media from '@/api/objects/Media';

export interface TourDayContract extends IBaseObject {
  date: string;
  description: string;
  destinationId: number;
  isBlocked: boolean;
  pax: number;
  originId: number;
  stageLength: any;
  tourId: number;
  type: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  items: TourDayItem[] | [];
  origin: Location;
  destination: Location;
  media: Media[];
  tour: Tour;
  translations: TourDayTranslation[];
  disabledItemResources: any[];
  optionalItemResources: any[];
}

export default class TourDay extends BaseObject implements TourDayContract {
  public declare date: string;
  public declare description: string;
  public declare destinationId: number;
  public declare isBlocked: boolean;
  public declare pax: number;
  public declare originId: number;
  public declare stageLength: any;
  public declare tourId: number;
  public declare type: string;
  public declare url: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare items: TourDayItem[] | [];
  public declare origin: Location;
  public declare destination: Location;
  public declare media: Media[];
  public declare tour: Tour;
  public declare translations: TourDayTranslation[];
  public declare disabledItemResources: any[];
  public declare optionalItemResources: any[];

  constructor(data: TourDayContract) {
    super(data);
  }
}
