<template>
  <div id="resources-list" class="container item-list pb-64">
    <v-list-header
      :title="this.$t('resource.resources')"
      icon="send-love"
      @open-filters-offcanvas="openOffCanvas('the-resource-filters-offcanvas')"
      @emit-close-off-canvas="closeOffCanvas"
      @reload-resources="loadResources"
    >
      <template #input-search>
        <v-input-text-search
          id="resource-search"
          v-model="title"
          :placeholder="this.$t('general.shared.search')"
          autocomplete="off"
          class="w-100 me-24"
          input-class="h-auto py-8"
          input-group-text-class="py-8 px-16"
          type="text"
          @update:modelValue="title = $event; search()"
        />
      </template>
    </v-list-header>

    <div class="resources-content">
      <div class="filters-container mb-24">
        <v-filters
          v-if="!!filterOptions"
          v-model="filterOptions"
          @remove-filter="removeFilter"
        />
      </div>

      <div class="table-responsive d-none d-md-flex">
        <v-table>
          <v-table-header>
            <tr>
              <th>{{ $t('general.shared.title') }}</th>
              <th>{{ $t('general.shared.type') }}</th>
              <th>{{ $t('general.shared.originAndDestination') }}</th>
              <th>{{ $t('general.shared.types') }}</th>

              <th>
                <v-button
                  :class="{ 'sort-active': order.key === 'created_at' }"
                  class="btn-order text-md fw-light shadow-none p-0 pb-4"
                  variant="icon"
                  @click="toggleOrder('created_at')"
                >
                  {{ $t('general.shared.created') }}
                  <v-icon
                    :class="{ 'sort-icon-transition': order.key === 'created_at' && order.order === 'DESC' }"
                    class="ms-8"
                    icon="chevron-down"
                    size="xs"
                  />
                </v-button>
              </th>
              <th class="fit"/>
            </tr>
          </v-table-header>

          <v-table-body class="fw-medium">
            <tr v-if="!loaded">
              <td class="position-relative border-0 py-96" colspan="2222">
                <v-loader/>
              </td>
            </tr>

            <template v-else-if="!!resources && resources.length > 0">
              <tr v-for="resource in resources" :key="resource.id">
                <td class="d-flex">
                  <div class="image-container rounded-sm bg-tertiary me-12">
                    {{ resource.title?.charAt(0) }}
                  </div>

                  <a
                    class="py-12"
                    href="#"
                    @click.prevent="showResourceDetails(resource)"
                  >
                    {{ resource.title }}
                  </a>
                </td>

                <!-- <td>{{ findConceptByKey('resource.type', resource.type).name }}</td> -->
                <td>{{ $t(`general.shared.${resource.type}`) }}</td>

                <td>
                  <template v-if="!!resource.location && !!resource.location.name">
                    {{ resource.location.name }}
                     <v-icon v-if="!!resource.destination" icon="arrow-right" size="xs" space="px-8"/>
                    {{ resource.destination }}
                  </template>

                  <template v-else>-</template>
                </td>

                <td>
                  <template v-if="!!resource.types">
                    {{ getAllTypes(resource.types) }}
                  </template>

                  <template v-else>-</template>
                </td>

                <td>{{ $str.formatDateTime(resource.createdAt, 'dd MMM yyyy') }}</td>

                <td class="fit text-end">
                  <v-button
                    class="btn-view rounded-pill bg-secondary"
                    size="xs"
                    variant="icon"
                    @click="showResourceDetails(resource)"
                  >
                    <v-icon icon="arrow-right"/>
                  </v-button>
                </td>
              </tr>
            </template>

            <template v-else>
              <tr>
                <td class="rounded-xs py-16" colspan="2222">
                  {{ $t('resource.noResources') }}
                </td>
              </tr>
            </template>
          </v-table-body>
        </v-table>
      </div>

      <div class="item-cards d-block d-md-none">
        <div v-if="!loaded" class="position-relative py-96">
          <v-loader/>
        </div>

        <template v-else-if="!!resources && resources.length > 0">
          <div v-for="resource in resources" :key="resource.id" class="item-card border rounded-xs p-8 mb-12 text-sm">
            <div class="d-flex align-items-center mb-4">
              <div class="image-container rounded-sm bg-tertiary me-12">
                <template v-if="!!resource.title">
                  {{ resource.title?.charAt(0) }}
                </template>

                <template v-else>
                  {{ resource.location?.name?.charAt(0) }}
                </template>
              </div>

              <a
                v-if="!!resource.title"
                class="fw-medium mb-4"
                href="#"
                @click.prevent="showResourceDetails(resource)"
              >
                {{ resource.title }}
              </a>

              <a
                v-else-if="!!resource.location && !!resource.location.name"
                class="fw-medium mb-4"
                href="#"
                @click.prevent="showResourceDetails(resource)"
              >
                {{ resource.location.name }}
              </a>

              <div v-else>-</div>
            </div>

            <div class="text-xs">{{ findConceptByKey('resource.type', resource.type).name }}</div>

            <div v-if="!!resource.location && !!resource.location.name" class="text-xs">
              <span class="me-4">{{ $t('general.shared.location') }}: </span>

              <template v-if="!!resource.types">
                {{ resource.location.name }}
              </template>

              <template v-else>-</template>
            </div>

            <div class="text-xs mb-8">
              <span class="me-4">{{ $t('general.shared.types') }}: </span>

              <template v-if="!!resource.types">
                {{ getAllTypes(resource.types) }}
              </template>

              <template v-else>-</template>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div class="text-xs">{{ $str.formatDateTime(resource.createdAt, 'dd MMM yyyy') }}</div>

              <v-button
                class="btn-view rounded-pill bg-secondary"
                size="xs"
                variant="icon"
                @click="showResourceDetails(resource)"
              >
                <v-icon class="fw-semi" icon="arrow-right" size="xs"/>
              </v-button>
            </div>
          </div>
        </template>

        <div v-else>
          {{ $t('resource.noResources') }}
        </div>
      </div>

      <v-pagination
        v-if="!!resources && resources.length > 0"
        :meta="pagination.meta"
        :pagination-links="pagination.links"
        class="mt-32"
        @page-changed="changePage($event); handlePageChangedEvent($event)"
        @per-page-changed="perPage = $event; doSearch()"
      />
    </div>

    <the-resource-filters-offcanvas
      v-if="offCanvasToShow === 'the-resource-filters-offcanvas'"
      v-model:location="location"
      v-model:status="status"
      v-model:type="type"
      v-model:types="types"
      @closed="closeOffCanvas"
      @selected="doSearch"
    />

    <the-resource-details-offcanvas
      v-if="offCanvasToShow === 'the-resource-details-offcanvas'"
      :is-archived="status === 'archived'"
      :resource-data="resource"
      @closed="closeOffCanvas(); cleanCurrentResource()"
      @reload-resources="loadResources"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VInputTextSearch } from '@uniqoders/form';
import qs from 'qs';
import querystring from 'query-string';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import api from '@/api';
import Resource from '@/api/objects/Resource';
import withTimeout from '@/helpers/timeOut';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import VListHeader from '@/components/shared/VListHeader.vue';
import VPagination from '@/components/vendor/basic/table/VPagination.vue';
import usePagination from '@/helpers/pagination';
import VFilters from '@/components/vendor/filters/VFilters.vue';
import TheResourceFiltersOffcanvas from '@/components/resource/TheResourceFiltersOffcanvas.vue';
import TheResourceDetailsOffcanvas from '@/components/resource/TheResourceDetailsOffcanvas.vue';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';

export default defineComponent({
  name: 'TheResources',
  components: {
    TheResourceDetailsOffcanvas,
    TheResourceFiltersOffcanvas,
    VFilters,
    VPagination,
    VListHeader,
    VLoader,
    VTableBody,
    VTableHeader,
    VTable,
    VButton,
    VInputTextSearch,
    VIcon,
  },
  setup() {
    return {
      findConceptByKey,
      ...useOffCanvasUtils(),
      ...usePagination(),
      ...useConcepts(),
    };
  },
  data() {
    return {
      resources: [] as Resource[],
      resource: null as null | Resource,
      status: 'active' as string,
      title: '' as string,
      type: '' as string,
      types: [] as string[],
      location: '' as string,
      filters: [
        {
          variable: 'status',
          key: 'status',
          labelAux: 'general.shared.filterStatus',
          conceptName: 'app.states',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'title',
          key: 'title',
          labelAux: 'general.shared.filterSearch',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'location',
          key: 'location',
          labelAux: 'general.shared.filterLocation',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'type',
          key: 'type',
          labelAux: 'general.shared.filterType',
          conceptName: 'resource.type',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'types',
          key: 'types',
          labelAux: 'general.shared.filterTypes',
          conceptName: 'resource.types',
          multiple: true,
          isConcept: true,
        },
      ] as Record<string, string | boolean>[],
      order: {
        key: '' as string,
        order: '' as string,
      } as any,
      perPage: 15 as number,
      page: 1 as number,
      timers: {
        title: null as any,
      },
      loaded: false as boolean,
      filterOptions: [] as Record<string, any>[],
    };
  },
  async created() {
    await this.setQueryParametersAsFilters();
    await this.doSearch();
  },
  methods: {
    search() {
      clearTimeout(this.timers.title);

      this.timers.title = withTimeout(async () => {
        await this.doSearch();
      }, this.timers.title, 1000);
    },
    /**
     * Detects any query parameter in the url and loads it in the filters (if available)
     *
     * @returns {Promise<void>}
     */
    async setQueryParametersAsFilters() {
      const { query }: any = this.$route;

      const {
        filters,
        order,
        perPage,
        status,
        page,
      }: any = qs.parse(query);

      if (!!filters) {
        this.filters.forEach((filter: Record<string, any>) => {
          if (!!filters[filter.key]) {
            this[filter.variable] = filters[filter.key];
          }
        });
      }

      if (!!order) {
        this.order = order;
      }

      const pageNumber = parseInt(page, 10);

      if (!Number.isNaN(pageNumber)) {
        this.page = pageNumber;
      }

      const perPageNumber = parseInt(perPage, 10);

      if (!Number.isNaN(perPageNumber)) {
        this.perPage = perPageNumber;
      }

      if (!!status) {
        this.status = status;
      }
    },
    /**
     * Sets the local filters as url query parameters.
     */
    async setFiltersAsQueryParameters() {
      const filters: any = {};

      this.filters.forEach((filter: Record<string, any>) => {
        if (!!this[filter.variable]) {
          filters[filter.key] = this[filter.variable];
        }
      });

      const queryObject: any = {
        filters,
        perPage: this.perPage,
        status: this.status,
        page: this.page,
      };

      if (!!this.order && !!this.order.key) {
        queryObject.order = this.order;
      }

      await this.setFilters();

      let query: any = qs.stringify(queryObject, { encode: false });
      query = querystring.parse(query);

      this.$router.push({
        name: 'resource.index',
        query,
      });
    },
    async doSearch() {
      await this.setFiltersAsQueryParameters();
      await this.loadResources();
    },
    async loadResources(page?: number) {
      try {
        this.loaded = false;

        const filters: any = {};

        this.filters.forEach((filter: Record<string, any>) => {
          if (!!this[filter.variable]) {
            filters[filter.key] = this[filter.variable];
          }
        });

        const query: any = {
          filters,
          perPage: this.perPage,
          status: this.status,
          page: !!page ? page : this.page,
        };

        if (!!this.order && !!this.order.key) {
          query.order = this.order;
        }

        const response = await api.resource.allPaginated(query);

        this.setResponse(response);

        this.resources = response.data;
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.loaded = true;
      }
    },
    async showResourceDetails(resource: Resource) {
      this.resource = resource;

      if (this.offCanvasToShow !== 'the-resource-details-offcanvas') {
        this.openOffCanvas('the-resource-details-offcanvas');
      }
    },
    cleanCurrentResource() {
      this.resource = null;
    },
    async handlePageChangedEvent(page: number) {
      await this.loadResources(page);
    },
    toggleOrder(field: string) {
      const { order } = this.order;

      if (!order) {
        this.order.order = 'ASC';
      } else {
        this.order.order = order === 'ASC' ? 'DESC' : 'ASC';
      }

      this.order.key = field;

      this.search();
    },
    setFilters() {
      this.filterOptions = this.filters.flatMap((filter: Record<string, any>) => {
        const {
          key,
          multiple,
          isConcept,
          labelAux,
          conceptName,
          variable,
        } = filter;

        const options = multiple ? this[variable] : [this[variable]];

        return options.map((option: any) => {
          const value = isConcept ? findConceptByKey(`${conceptName}`, option) : option;
          const label = this.$t(`${labelAux}`, { value: !!conceptName ? value.name : value });

          return { key, value: !!conceptName ? value.key : value, label };
        });
      });
    },
    removeFilter(filter: Record<string, string>) {
      this.filters.forEach((filterAux: Record<string, string | boolean>) => {
        if (filterAux.key === filter.key) {
          if (!filterAux.multiple) {
            this[filterAux.variable] = '';
          } else {
            this[filterAux.variable] = this[filterAux.variable].filter((option: string) => option !== filter.value);
          }
        }
      });

      if (filter.key === 'status') {
        this.status = 'active';
      }

      this.doSearch();
    },
    getAllTypes(types: any): string {
      const typesParsed = JSON.parse(types);

      return typesParsed
        .map((type) => {
          const typeObject = this.findConceptByKey('resource.types', type);

          return typeObject?.name;
        })
        .filter(Boolean) // Filter out undefined values
        .join(', ');
    },
  },
});
</script>
