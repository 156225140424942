import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface ResourceTranslationContract extends IBaseObject {
  description: string;
  locale: string;
  resourceId: number;
  title: string;
}

export default class ResourceTranslation extends BaseObject implements ResourceTranslationContract {
  public declare description: string;
  public declare locale: string;
  public declare resourceId: number;
  public declare title: string;

  constructor(data: ResourceTranslationContract) {
    super(data);
  }
}
