<template>
  <div ref="map" class="map w-100 h-100"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { mapState } from 'pinia';
import { useGoogleStore } from '@/stores/google';

export default defineComponent({
  name: 'VMap',
  props: {
    latitude: {
      type: Number,
      required: false,
      default: 0,
    },
    longitude: {
      type: Number,
      required: false,
      default: 0,
    },
    zoom: {
      type: Number,
      required: false,
      default: 14,
    },
    disableDefaultUI: {
      type: Boolean,
      required: false,
      default: false,
    },
    streetViewControl: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: false,
    },
    locations: {
      type: Array,
      required: false,
    },
  },
  setup() {
    const googleStore = useGoogleStore();

    return {
      googleStore,
    };
  },
  data() {
    return {
      markers: [] as any[],
    };
  },
  computed: {
    ...mapState(useGoogleStore, ['loader']),
  },
  async created() {
    await this.loadMap();
  },
  methods: {
    async loadMap() {
      if (!this.loader) {
        const locale = localStorage.getItem('language');

        const loader = new Loader({
          apiKey: import.meta.env.VITE_GMAPS_API_KEY.toString(),
          language: !!locale ? locale : import.meta.env.VITE_APP_DEFAULT_LOCALE,
          version: 'weekly',
          libraries: ['places'],
        });

        this.googleStore.setLoader(loader);
      }

      const mapOptions = {
        styles: [{
          featureType: 'all',
          // featureType: 'poi',
          elementType: 'geometry.fill',
          stylers: [
            {
              weight: '2.00',
              visibility: 'off',
            },
          ],
        }, {
          featureType: 'all',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#9c9c9c' }],
        }, {
          featureType: 'all',
          elementType: 'labels.text',
          stylers: [{ visibility: 'on' }],
        }, {
          featureType: 'landscape',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ffffff' }],
        }, {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ffffff' }],
        }, {
          featureType: 'poi',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        }, {
          featureType: 'road',
          elementType: 'all',
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        }, {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [{ color: '#eeeeee' }],
        }, {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#7b7b7b' }],
        }, {
          featureType: 'road',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#ffffff' }],
        }, {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [{ visibility: 'simplified' }],
        }, {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        }, {
          featureType: 'transit',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        }, {
          featureType: 'water',
          elementType: 'all',
          stylers: [{ color: '#46bcec' }, { visibility: 'on' }],
        }, {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [{ color: '#c8d7d4' }],
        }, {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#070707' }],
        }, { featureType: 'water', elementType: 'labels.text.stroke', stylers: [{ color: '#ffffff' }] }],
        center: {
          lat: this.latitude,
          lng: this.longitude,
        },
        zoom: this.zoom,
        streetViewControl: this.streetViewControl,
        disableDefaultUI: this.disableDefaultUI,
      };

      const google = await this.loader.load();

      const map = new google.maps.Map(this.$refs.map, mapOptions);

      const markerBounds = new google.maps.LatLngBounds();

      // Item Marks
      if (!!this.items && this.items.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.items) {
          if (!!item.lat && !!item.lng) {
            const position = new google.maps.LatLng(item.lat, item.lng);

            // eslint-disable-next-line no-await-in-loop
            const marker = await new google.maps.Marker({
              position,
              map,
            });

            marker.id = item.id;

            this.markers.push(marker);

            // eslint-disable-next-line no-await-in-loop
            await markerBounds.extend(position);

            // Marker Info Windows
            const contentString = '<div id="content" class="p-8">'
              + `<p class="text-md fw-bold mb-0">${item.name}</p>`
              + '</div>';

            const infowindow = new google.maps.InfoWindow({
              content: contentString,
            });

            marker.addListener('click', () => {
              infowindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
              });
            });

            google.maps.event.addListener(map, 'click', () => {
              infowindow.close();
            });
          }
        }
      }

      await map.fitBounds(markerBounds);
    },
    findLocation(locationId: number): any {
      return this.locations?.find((location: any) => location.id === locationId);
    },
    showOneMarkerHideOthers(markerId: number | null) {
      this.markers.forEach((marker: any) => {
        if (!markerId) {
          marker.setVisible(true);
        } else if (marker.id !== markerId) {
          marker.setVisible(false);
        } else {
          marker.setVisible(true);
        }
      });
    },
    defaultMarker(color: string, strokeColor: string, scale: number) {
      return {
        path: 'M17 40.5014L5.33267 28.8341C3.02513 26.5265 1.45368 23.5865 0.817036 20.3858C0.180396 17.1851 0.507163 13.8675 1.75601 10.8525C3.00487 7.83758 5.11971 5.26064 7.83312 3.44761C10.5465 1.63457 13.7366 0.66687 17 0.66687C20.2634 0.66687 23.4535 1.63457 26.1669 3.44761C28.8803 5.26064 30.9951 7.83758 32.244 10.8525C33.4929 13.8675 33.8196 17.1851 33.183 20.3858C32.5463 23.5865 30.9749 26.5265 28.6673 28.8341L17 40.5014ZM26.075 26.2417C27.8697 24.4469 29.0918 22.1602 29.5868 19.6708C30.0819 17.1814 29.8277 14.6012 28.8563 12.2562C27.885 9.91134 26.2401 7.90712 24.1297 6.49704C22.0193 5.08695 19.5381 4.33432 17 4.33432C14.4619 4.33432 11.9807 5.08695 9.87033 6.49704C7.75992 7.90712 6.11504 9.91134 5.14368 12.2562C4.17232 14.6012 3.9181 17.1814 4.41317 19.6708C4.90824 22.1602 6.13036 24.4469 7.92501 26.2417L17 35.3167L26.075 26.2417ZM17 20.8334C16.0275 20.8334 15.0949 20.4471 14.4073 19.7595C13.7196 19.0718 13.3333 18.1392 13.3333 17.1667C13.3333 16.1943 13.7196 15.2616 14.4073 14.574C15.0949 13.8864 16.0275 13.5001 17 13.5001C17.9725 13.5001 18.9051 13.8864 19.5927 14.574C20.2804 15.2616 20.6667 16.1943 20.6667 17.1667C20.6667 18.1392 20.2804 19.0718 19.5927 19.7595C18.9051 20.4471 17.9725 20.8334 17 20.8334Z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor,
        strokeWeight: 1,
        scale,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.map {
  height: calc(100vh - 14rem);
}
</style>
