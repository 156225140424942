<template>
    <v-modal id="show-email-warning-modal" class-name="modal-fullscreen-sm-down" size="lg" @closed="$emit('closed')">
      <modal-header>
        <modal-title>{{ $t("emailTemplate.email_warning") }}</modal-title>
      </modal-header>
  
      <modal-body>
        <div v-if="priceWarning">
            {{ $t("emailTemplate.price_warning") }}
        </div>
        <div v-else-if="freeTextWarning">
            {{ $t("emailTemplate.free_text_warning") }}
        </div>
      </modal-body>
      <modal-footer>
        <v-button
          class="btn-sm btn-success align-self-end cursor-pointer"
          size="sm"
          variant="success"
          @click="$emit('closed')"
        >
          {{ $t("general.button.ok") }}
        </v-button>
      </modal-footer>
    </v-modal>
  </template>
  
  <script>
  import VModal from "@/components/vendor/basic/modal/VModal.vue";
  import ModalHeader from "@/components/vendor/basic/modal/ModalHeader.vue";
  import ModalTitle from "@/components/vendor/basic/modal/ModalTitle.vue";
  import ModalBody from "@/components/vendor/basic/modal/ModalBody.vue";
  import ModalFooter from "@/components/vendor/basic/modal/ModalFooter.vue";
  import VButton from "@/components/vendor/basic/button/VButton.vue";
  
  export default {
    name: "TheEmailWarningModal",
    components: {
      VModal,
      ModalHeader,
      ModalTitle,
      ModalBody,
      ModalFooter,
      VButton,
    },
    emits: ["closed"],
    props: {
        priceWarning: {
            type: Boolean,
            default: false
        },
        freeTextWarning: {
            type: Boolean,
            default: false
        }
    }
  };
  </script>
  