<template>
  <div class="form-outline">
    <label class="label">
      <span>{{ $t('admin.user.addExistingAgency') }}</span>
    </label>

    <v-select
      v-model="agency"
      :clearable="false"
      :filterable="false"
      :loading="assigningAgencyUser"
      :options="agencies"
      :selectable="(option) => !!selectedOptions
        ? !selectedOptions.some((selectedOption) => selectedOption.id === option.id) : null"
      class="select-search"
      label="name"
      @search="onSearch"
      @option:selecting="assignAgencyUser"
    >
      <template v-slot:no-options>
        {{ $t('general.shared.writeSearchAgencies') }}
      </template>

      <template v-slot:option="option">
        <div class="d-center">
          <span>{{ option?.name }}</span>

          <span v-if="!!option?.location" class="text-sm fw-light ms-4">
            ({{ option.location }})
          </span>
        </div>
      </template>

      <template v-slot:selected-option="option">
        <div class="selected d-center py-8">
          <span>{{ option?.name }}</span>

          <span v-if="!!option?.location" class="text-sm fw-light ms-4">
            ({{ option.location }})
          </span>
        </div>
      </template>

      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <v-icon icon="search" size="sm"/>
        </span>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import vSelect from 'vue-select';
import { mapState } from 'pinia';
import Agency from '@/api/objects/Agency';
import api from '@/api';
import User from '@/api/objects/User';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'VSelectAgencySearch',
  components: {
    VIcon,
    vSelect,
  },
  props: {
    userAux: {
      type: User,
      required: true,
    },
    selectedOptions: {
      type: Array as PropType<Agency[]>,
      required: false,
    },
  },
  emits: ['agencyAttached'],
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
    };
  },
  data() {
    return {
      agency: null as Agency | null,
      agencies: [] as Agency[],
      assigningAgencyUser: false as boolean,
      location: '' as string,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
  },
  methods: {
    onSearch(search: string, loading: any) {
      if (search.length) {
        loading(true);
        this.loadAgencies(loading, search);
      }
    },
    async loadAgencies(loading: any, search: string) {
      loading(true);

      try {
        const query = { filters: { name: search } };

        this.agencies = await api.admin.agency.all(query);
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        loading(false);
      }
    },
    async assignAgencyUser(agency: Agency) {
      await this.$modal.confirm({
        title: this.$t('admin.user.addAgencyUserTitle'),
        text: this.$t('admin.user.addAgencyUserText', { user: this.userAux.name, agency: agency.name }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.add'),
        confirmButtonCallback: this.doAssignAgencyUser,
      });
    },
    async doAssignAgencyUser() {
      try {
        this.assigningAgencyUser = true;

        if (!!this.agency) {
          await api.admin.agency.attachUser(this.agency.id, this.userAux.id);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.$emit('agencyAttached');

          if (this.userAux.id === this.user?.id) {
            await this.authStore.setAuthUser();
          }

          this.agency = null;
        }
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.assigningAgencyUser = false;
      }
    },
  },
});
</script>
