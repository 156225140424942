import { IPricingItemSupplierResource } from "@/views/case/parts/TheTourPricing.vue";
import TourDayItemResource from "@/api/objects/TourDayItemResource";

export const calculateAllNetValidated = (data: IPricingItemSupplierResource[] | TourDayItemResource[]): 'mixed' | boolean => {
  const allNetValidated = data.every(
    (item: IPricingItemSupplierResource | TourDayItemResource) => item.netValidated);
  const someNetValidated = data.some((item) => item.netValidated);

  return allNetValidated ? true : someNetValidated ? 'mixed' : false;
};


export const calculateAllPaymentStatus = (data: IPricingItemSupplierResource[] | TourDayItemResource[], status: string): 'mixed' | boolean => {
  const allPaymentStatus = data.every(
    (item: IPricingItemSupplierResource | TourDayItemResource) => item.paymentStatus === status);
  const somePaymentStatus = data.some((item) => item.paymentStatus === status);

  return allPaymentStatus ? true : somePaymentStatus ? 'mixed' : false;
};

// Calculate amount paid and amount pending of group
export const calculatePriceGroup = (data: IPricingItemSupplierResource[] | TourDayItemResource[]): {
  netPrice: number,
  grossPrice: number,
  amountPaid: number,
  amountPending: number
} => {
  // Calculate net price of group
  let netPrice = data.reduce(
    (sum: number, item: TourDayItemResource | IPricingItemSupplierResource) => sum + (Number(item.netPrice) || 0), 0);

  if (netPrice % 1 !== 0) {
    netPrice = Number(netPrice.toFixed(2));
  }

  // Calculate gross price of group
  let grossPrice = data.reduce(
    (sum: number, item: TourDayItemResource | IPricingItemSupplierResource) => sum + (Number(item.grossPrice) || 0), 0);

  if (grossPrice % 1 !== 0) {
    grossPrice = Number(grossPrice.toFixed(2));
  }

  let amountPaid = data.reduce(
    (sum: number, item: TourDayItemResource | IPricingItemSupplierResource) => sum + (Number(item.amountPaid) || 0), 0);

  if (amountPaid % 1 !== 0) {
    amountPaid = Number(amountPaid.toFixed(2));
  }

  const amountPending = netPrice - amountPaid;

  return {
    netPrice,
    grossPrice,
    amountPaid,
    amountPending,
  }
};
