export default {
  traveler: 'Traveler',
  travelerData: 'Traveler data',
  withoutOrigin: 'Without origin',
  withoutDestination: 'Without destination',
  withoutLocation: 'Without location',
  noStartDate: 'No start date',
  noEndDate: 'No end date',
  basicInformation: 'Basic information',
  contactInformation: 'Contact information',
  addressInformation: 'Address information',
  moreInformation: 'More information',
  haveAllergies: 'Do you have any allergies?',
  haveAllergiesText: 'Could you please specify it/them?',
  allergies: 'Allergies',
  yes: 'Yes',
  no: 'No',
  spokenLanguage: 'Spoken language',
  followingDiet: 'Are you following a specific diet?',
  vegetarian: 'Vegetarian',
  vegan: 'Vegan',
  other: 'Other',
  diet: 'Diet',
  bikeText: 'As you are doing your Camino by bike?',
  bikeYesText: 'As you are doing your Camino by bike, please provide us with your height, weight and head size.',
  bike: 'Bike',
  height: 'Height',
  weight: 'Weight',
  bikeType: 'Bike type',
  arrival: 'Arrival',
  arrivalType: 'Arrival type',
  arrivalNumber: 'Flight/Train/Boat/Bus Number',
  arrivalDate: 'Date of arrival',
  arrivalHour: 'Hour of arrival',
  arrivalLocation: 'Your station of arrival',
  departure: 'Departure',
  departureType: 'Departure type',
  departureNumber: 'Flight/Train/Boat/Bus Number',
  departureDate: 'Date of departure',
  departureHour: 'Hour of departure',
  departureLocation: 'Your station of departure',
};
