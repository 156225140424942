<template>
  <v-modal id="show-media-modal" class-name="modal-fullscreen-sm-down" size="lg" @closed="$emit('closed')">
    <modal-header>
      <modal-title>{{ $t("general.shared.confirmationOfPricing") }}</modal-title>
    </modal-header>

    <modal-body>
      <div>
        {{ $t("general.shared.confirmPriceInfo") }}
      </div>
    </modal-body>
    <modal-footer>
      <v-button
        class="btn-sm btn-success align-self-end cursor-pointer"
        size="sm"
        variant="success"
        @click="$emit('closed')"
      >
        {{ $t("general.button.ok") }}
      </v-button>
    </modal-footer>
  </v-modal>
</template>

<script>
import VModal from "@/components/vendor/basic/modal/VModal.vue";
import ModalHeader from "@/components/vendor/basic/modal/ModalHeader.vue";
import ModalTitle from "@/components/vendor/basic/modal/ModalTitle.vue";
import ModalBody from "@/components/vendor/basic/modal/ModalBody.vue";
import ModalFooter from "@/components/vendor/basic/modal/ModalFooter.vue";
import VButton from "@/components/vendor/basic/button/VButton.vue";

export default {
  name: "TheEditableInfoModal",
  components: {
    VModal,
    ModalHeader,
    ModalTitle,
    ModalBody,
    ModalFooter,
    VButton,
  },
  emits: ["closed"],
};
</script>
