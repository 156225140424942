export default {
  suppliers: 'Proveedores',
  noSuppliers: 'No hay proveedores',
  createNewSupplier: 'Crear nuevo proveedor',
  archivedSuppliers: 'Proveedores archivados',
  activeSuppliers: 'Proveedores activos',
  basicInformation: 'Información básica',
  supplierDetails: 'Detalles del proveedor',
  archiveText:
    "Puedes archivar un proveedor, si lo haces el proveedor no aparecerá en las búsquedas de la aplicación. Podrá verlo en la lista de proveedores si filtra por 'archivado'. Una vez que veas los detalles de un proveedor archivado, puedes eliminarlo definitivamente o puedes desarchivarlo",
  media: 'Media',
  noMedia: 'No hay medios asociados',
  archivedText: "Este proveedor está archivado, si lo desea puede desarchivarlo pulsando el botón 'Restaurar'.",
  archiveSupplierTitle: 'Proveedor archivado',
  archiveSupplierText:
    "¿Está seguro de que desea archivar el proveedor '{supplier}'? Si lo archiva, no aparecerá en las búsquedas",
  deleteText:
    "Este proveedor está archivado, si quieres eliminarlo definitivamente pulsa el botón 'Eliminar'. Tenga en cuenta que si lo elimina, este proveedor dejará de existir y no se podrá recuperar.",
  deleteSupplierTitle: 'Eliminar proveedor',
  deleteSupplierText:
    "¿Está seguro de que desea eliminar el proveedor '{supplier}'? Si lo borras, no podrás recuperarlo",
  restoreSupplierTitle: 'Recuperar proveedor',
  restoreSupplierText:
    "¿Está seguro de que desea recuperar el proveedor '{supplier}'? Si lo recuperas, dejará de estar archivado y aparecerá en las búsquedas de la aplicación.",
  supplierStatus: 'Estado del proveedor',
  products: 'Productos',
  noProducts: 'No hay productos asociados',
  editProductsBelongSupplierText: 'Editar productos pertenecientes al proveedor',
  contacts: 'Contactos',
  noContacts: 'No hay contactos asociados',
  editContactsBelongSupplierText: 'Editar contactos pertenecientes al proveedor',
  addContacts: 'Añadir contactos',
  removeContactTitle: 'Eliminar contacto del proveedor',
  removeContactText: "¿Está seguro de que desea eliminar '{contact}' como contacto del proveedor '{supplier}'?",
  supplierSearch: 'Búsqueda de proveedor',
  removeMediaTitle: 'Eliminar medios',
  removeMediaText: '¿Está seguro de que desea eliminar el archivo? Si lo borras, no podrás recuperarlo',
  singleRoom: 'Individual AD',
  doubleRoom: 'Doble AD',
  doubleTwinRoom: 'Doble Twin AD',
  tripleRoom: 'Triple AD',
  bunkbedHBRoom: 'Litera HB',
  bunkbedFBRoom: 'Litera FB',
  singleRoomFullName: 'Habitación Individual AD',
  doubleRoomFullName: 'Habitación doble 1 cama AD',
  doubleTwinRoomFullName: 'Habitación doble 2 camas AD',
  tripleRoomFullName: 'Habitación triple AD',
  bunkbedHBRoomFullName: 'Litera en habitación compartida Media Pensión',
  bunkbedFBRoomFullName: 'Litera habitación compartida pensión completa',
  luggageTransport: 'Transporte de equipaje',
  busTicket: 'Billete de Autobus',
  boatTicket: 'Billete de Barco',
  trainTicket: 'Billete de Tren',
  transfer: 'Transfer',
  exportErrorToast: "No tienes permisos para exportar proyectos. Por favor, contacta con tu administrador",
  resource: {
    createNew: 'Crear nuevo producto',
    basicInformation: 'Información básica',
    title: 'Detalles del producto',
    archiveTitle: 'Archivar producto del proveedor',
    archiveText:
      "¿Está seguro de que desea archivar el producto '{resource}' de '{supplier}'? Si lo archiva, no aparecerá en las búsquedas",
    media: 'Media',
    noMedia: 'No hay medios asociados',
    removeMediaTitle: 'Eliminar medios',
    removeMediaText: '¿Estás seguro de que quieres borrar el archivo? Si lo borras, no podrás recuperarlo',
  },
  rates: 'Tarifas',
  rate: {
    description: 'Añade aquí las formas en que se puede vender este producto',
    descriptionOnlyView: 'Las formas en que se puede vender este producto',
    create: 'Crear tarifa',
    update: 'Actualizar tarifa',
    delete: 'Borrar tarifa',
    duplicate: 'Tasa duplicada',
    duplicateBlock: 'Duplicada',
    basicInformation: 'Información básica',
    typeNoOptions: 'No hay tipos disponibles',
    categoryNoOptions: 'No hay categorías disponibles',
    product: 'Producto',
    type: 'Tipo',
    category: 'Categoría',
    initDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    price: 'Precio',
    removeTitle: 'Eliminar tarifa',
    singleRate: 'Duplicarás la siguiente tarifa',
    blockRate: 'Duplicarás el siguiente bloque',
    removeText: "¿Seguro que quieres borrar la tarifa del producto '{product}'? Si la borras, no podrás recuperarla",
  },
};
