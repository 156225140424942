import { AbstractService } from '@uniqoders/sdk';
import TourDayItemResource from '@/api/objects/TourDayItemResource';

export default class TourBookingService extends AbstractService {
  /**
   * Retrieves all Tour Bookings
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param params
   */
  public all(
    agency: string,
    clientTour: number | string,
    tour: number | string,
    params?: any): Promise<TourDayItemResource[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/booking', {
        agency,
        clientTour,
        tour,
      }),
      params,
    });
  }

  public update(
    agency: string,
    clientTour: number,
    tour: number,
    data: Record<string, any>): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/resource/status/update', {
        agency,
        clientTour,
        tour,
      }),
      data,
    });
  }
}
