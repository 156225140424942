<template>
  <div id="accordionStatuses" class="accordion">
    <div class="case-details accordion-item">
      <h2 id="headingStatuses" class="accordion-header">
        <button
          aria-controls="collapseStatuses"
          aria-expanded="true"
          class="accordion-button collapsed"
          data-bs-target="#collapseStatuses"
          data-bs-toggle="collapse"
          type="button">
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="info" size="xxl" space="p-12"/>
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t('clientTour.statuses') }}</div>
              <div class="fw-light accordion-subtitle">{{ $t('clientTour.tourStatuses') }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseStatuses"
        aria-labelledby="headingStatuses"
        class="accordion-collapse collapse show"
        data-bs-parent="#accordionStatuses">
        <div class="accordion-body pb-32 pb-xl-48">
          <div class="text-sm mb-4">
            <span class="fw-medium me-8">{{ $t('clientTour.reservations') }}:</span>
            <span>{{ findConceptByKey('tour.reservation_status_type', tour.reservationStatus).name }}</span>
          </div>

          <div class="text-sm mb-4">
            <span class="fw-medium me-8">{{ $t('clientTour.payments') }}:</span>
            <span>{{ findConceptByKey('tour.payment_status_type', tour.paymentsStatus).name }}</span>
          </div>

          <div class="text-sm mb-4">
            <span class="fw-medium me-8">{{ $t('clientTour.invoices') }}:</span>
            <span>{{ findConceptByKey('tour.invoice_status_type', tour.invoicesStatus).name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import Tour from '@/api/objects/Tour';
import { findConceptByKey } from '@/helpers/ConceptHelper';

export default defineComponent({
  name: 'TheCaseSetupStatuses',
  components: {
    VIcon,
  },
  props: {
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
  },
  setup() {
    return {
      findConceptByKey,
    };
  },
});
</script>
