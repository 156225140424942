import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface InvoiceLineContract extends IBaseObject {
  amount: string;
  currency: string;
  description: string;
  invoiceId: number;
  quantity: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export default class InvoiceLine extends BaseObject implements InvoiceLineContract {
  public declare amount: string;
  public declare currency: string;
  public declare description: string;
  public declare invoiceId: number;
  public declare quantity: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;

  constructor(data: InvoiceLineContract) {
    super(data);
  }
}
