import { BaseObject, HttpClient, HttpClientOptions, HttpParseResponse } from '@uniqoders/sdk';

// Service Imports
import AuthService from '@/api/services/AuthService';
import AdminUserService from '@/api/services/AdminUserService';
import AdminAgencyService from '@/api/services/AdminAgencyService';
import AdminImpersonateService from '@/api/services/AdminImpersonateService';
import ClientService from '@/api/services/ClientService';
import CaseService from '@/api/services/CaseService';
import ConceptService from '@/api/services/ConceptService';
import ContactService from '@/api/services/ContactService';
import LocationService from '@/api/services/LocationService';
import MediaService from '@/api/services/MediaService';
import ResourceService from '@/api/services/ResourceService';
import SupplierService from '@/api/services/SupplierService';
import SupplierResourceService from '@/api/services/SupplierResourceService';
import SupplierResourcePriceService from '@/api/services/SupplierResourcePriceService';
import TourService from '@/api/services/TourService';
import UserService from '@/api/services/UserService';
import TourDayItemService from '@/api/services/TourDayItemService';
import TourDayService from '@/api/services/TourDayService';
import TourDayItemResourceService from '@/api/services/TourDayItemResourceService';
import TourPricingService from '@/api/services/TourPricingService';
import TourBookingService from '@/api/services/TourBookingService';
import PaxService from '@/api/services/PaxService';
import TemplateService from '@/api/services/TemplateService';
import TemplateDayService from '@/api/services/TemplateDayService';
import TemplateDayItemService from '@/api/services/TemplateDayItemService';
import TemplateDayItemResourceService from '@/api/services/TemplateDayItemResourceService';
import TravelBookService from '@/api/services/TravelBookService';
import AgencyUserService from '@/api/services/AgencyUserService';
import InvoiceService from '@/api/services/InvoiceService';
import AdminTemplateService from '@/api/services/AdminTemplateService';
import NotificationService from '@/api/services/NotificationService';
import MandrillService from '@/api/services/MandrillService';
import EmailAuthLessService from "@/api/services/EmailAuthLessService";


// Object Imports
import Agency from '@/api/objects/Agency';
import AgencyUser from '@/api/objects/AgencyUser';
import User from '@/api/objects/User';
import Client from '@/api/objects/Client';
import ClientTour from '@/api/objects/ClientTour';
import Concept from '@/api/objects/Concept';
import Contact from '@/api/objects/Contact';
import Location from '@/api/objects/Location';
import Media from '@/api/objects/Media';
import Resource from '@/api/objects/Resource';
import Supplier from '@/api/objects/Supplier';
import SupplierResource from '@/api/objects/SupplierResource';
import Tour from '@/api/objects/Tour';
import TourDay from '@/api/objects/TourDay';
import TourDayItem from '@/api/objects/TourDayItem';
import TourDayItemResource from '@/api/objects/TourDayItemResource';
import Template from '@/api/objects/Template';
import TourDayItemResourcePayment from '@/api/objects/TourDayItemResourcePayment';
import SupplierResourcePrice from '@/api/objects/SupplierResourcePrice';
import Pax from '@/api/objects/Pax';
import ResourceTranslation from '@/api/objects/ResourceTranslation';
import SupplierTranslation from '@/api/objects/SupplierTranslation';
import SupplierResourceTranslation from '@/api/objects/SupplierResourceTranslation';
import Invoice from '@/api/objects/Invoice';
import InvoiceLine from '@/api/objects/InvoiceLine';
import TourDayTranslation from '@/api/objects/TourDayTranslation';
import Role from './objects/Role';
import Permission from './objects/Permission';
import Notification from '@/api/objects/Notification';
import CollaboratorService from './services/CollaboratorService';
import CasesCount from './objects/CasesCount';
import caseNotifications from './objects/CaseNotifications';

class ApiClient extends HttpClient {
  public constructor(options: HttpClientOptions) {
    super(options);

    this.httpParseResponse = new HttpParseResponse();
    this.httpParseResponse.objects = this.objects();
  }
  public get auth(): AuthService {
    return new AuthService(this);
  }

  public get admin() {
    return {
      user: new AdminUserService(this),
      agency: new AdminAgencyService(this),
      impersonate: new AdminImpersonateService(this),
      template: new AdminTemplateService(this),
    };
  }

  public get agencyUser(): AgencyUserService {
    return new AgencyUserService(this);
  }

  public get client(): ClientService {
    return new ClientService(this);
  }

  public get case(): CaseService {
    return new CaseService(this);
  }

  public get concept(): ConceptService {
    return new ConceptService(this);
  }

  public get contact(): ContactService {
    return new ContactService(this);
  }

  public get invoice(): InvoiceService {
    return new InvoiceService(this);
  }

  public get location(): LocationService {
    return new LocationService(this);
  }

  public get media(): MediaService {
    return new MediaService(this);
  }

  public get pax(): PaxService {
    return new PaxService(this);
  }

  public get resource(): ResourceService {
    return new ResourceService(this);
  }

  public get supplier(): SupplierService {
    return new SupplierService(this);
  }

  public get supplierResource(): SupplierResourceService {
    return new SupplierResourceService(this);
  }

  public get supplierResourcePrice(): SupplierResourcePriceService {
    return new SupplierResourcePriceService(this);
  }

  public get template(): TemplateService {
    return new TemplateService(this);
  }

  public get templateDay(): TemplateDayService {
    return new TemplateDayService(this);
  }

  public get templateDayItem(): TemplateDayItemService {
    return new TemplateDayItemService(this);
  }

  public get templateDayItemResource(): TemplateDayItemResourceService {
    return new TemplateDayItemResourceService(this);
  }

  public get tour(): TourService {
    return new TourService(this);
  }

  public get tourBooking(): TourBookingService {
    return new TourBookingService(this);
  }

  public get tourDay(): TourDayService {
    return new TourDayService(this);
  }

  public get tourDayItem(): TourDayItemService {
    return new TourDayItemService(this);
  }

  public get tourDayItemResource(): TourDayItemResourceService {
    return new TourDayItemResourceService(this);
  }

  public get tourPricing(): TourPricingService {
    return new TourPricingService(this);
  }

  public get travelBook(): TravelBookService {
    return new TravelBookService(this);
  }

  public get user(): UserService {
    return new UserService(this);
  }

  public get notification(): NotificationService {
    return new NotificationService(this);
  }

  public get mandrill(): MandrillService {
    return new MandrillService(this);
  }

  public get dashboardCases(): CollaboratorService {
    return new CollaboratorService(this);
  }

  public get emailAuthLess(): EmailAuthLessService {
    return new EmailAuthLessService(this);
  }

  public get CaseNotifications(): NotificationService {
    return new NotificationService(this);
  }

  protected objects(): Record<string, (attrs: any) => BaseObject> {
    return {
      agency: (attrs: any) => new Agency(attrs),
      agency_user: (attrs: any) => new AgencyUser(attrs),
      client: (attrs: any) => new Client(attrs),
      client_tour: (attrs: any) => new ClientTour(attrs),
      concept: (attrs: any) => new Concept(attrs),
      contact: (attrs: any) => new Contact(attrs),
      invoice: (attrs: any) => new Invoice(attrs),
      invoice_lines: (attrs: any) => new InvoiceLine(attrs),
      location: (attrs: any) => new Location(attrs),
      media: (attrs: any) => new Media(attrs),
      notification: (attrs: any) => new Notification(attrs),
      pax: (attrs: any) => new Pax(attrs),
      permission: (attrs: any) => new Permission(attrs),
      resource: (attrs: any) => new Resource(attrs),
      role: (attrs: any) => new Role(attrs),
      supplier: (attrs: any) => new Supplier(attrs),
      supplier_resource: (attrs: any) => new SupplierResource(attrs),
      supplier_resource_price: (attrs: any) => new SupplierResourcePrice(attrs),
      template: (attrs: any) => new Template(attrs),
      tour: (attrs: any) => new Tour(attrs),
      tour_day: (attrs: any) => new TourDay(attrs),
      tour_day_translation: (attrs: any) => new TourDayTranslation(attrs),
      tour_day_item: (attrs: any) => new TourDayItem(attrs),
      tour_day_item_resource: (attrs: any) => new TourDayItemResource(attrs),
      tour_day_item_resource_payment: (attrs: any) => new TourDayItemResourcePayment(attrs),
      user: (attrs: any) => new User(attrs),
      resource_translation: (attrs: any) => new ResourceTranslation(attrs),
      supplier_translation: (attrs: any) => new SupplierTranslation(attrs),
      supplier_resource_translation: (attrs: any) => new SupplierResourceTranslation(attrs),
      dashboard_cases: (attrs: any) => new CasesCount(attrs),
      cases_notifications: (attr: any) => new caseNotifications(attr),
    };
  }
}

export default ApiClient;
