<template>
  <div class="form-outline">
    <label class="label">
      <span>{{ $t('admin.user.addExistingAgency') }}</span>
    </label>

    <v-select
      v-model="agency"
      :clearable="false"
      :filterable="false"
      :options="agencies"
      :selectable="(option) => !!selectedOptions
        ? !selectedOptions.some((selectedOption) => selectedOption.id === option.id) : null"
      class="select-search"
      label="name"
      @search="onSearch"
      @option:selecting="$emit('selected', $event)"
    >
      <template v-slot:no-options>
        {{ $t('general.shared.writeSearchAgencies') }}
      </template>

      <template v-slot:option="option">
        <div class="d-center">
          <span>{{ option?.name }}</span>

          <span v-if="!!option?.location" class="text-sm fw-light ms-4">
            ({{ option.location }})
          </span>
        </div>
      </template>

      <template v-slot:selected-option="option">
        <div class="selected d-center py-8">
          <span>{{ option?.name }}</span>

          <span v-if="!!option?.location" class="text-sm fw-light ms-4">
            ({{ option.location }})
          </span>
        </div>
      </template>

      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"><v-icon icon="search" size="sm"/></span>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import vSelect from 'vue-select';
import Agency from '@/api/objects/Agency';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';

export default defineComponent({
  name: 'VSelectTemplateAgencySearch',
  components: {
    VIcon,
    vSelect,
  },
  props: {
    selectedOptions: {
      type: Array as PropType<Agency[]>,
      required: false,
    },
  },
  emits: ['selected'],
  data() {
    return {
      agency: null as Agency | null,
      agencies: [] as Agency[],
      location: '' as string,
    };
  },
  methods: {
    onSearch(search: string, loading: any) {
      if (search.length) {
        loading(true);
        this.loadAgencies(loading, search);
      }
    },
    async loadAgencies(loading: any, search: string) {
      loading(true);

      try {
        const query = { filters: { name: search } };

        this.agencies = await api.admin.agency.all(query);
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        loading(false);
      }
    },
  },
});
</script>
