<template>
  <div id="template-tour" class="container pb-32 pb-lg-64">
    <v-loader v-if="status === 'loading'"/>

    <template v-else>
      <div class="pt-40 pb-32 pb-lg-48">
        <div class="d-flex flex-column flex-md-row justify-content-between">
          <div class="d-flex justify-content-between align-items-center w-100 mb-24 mb-md-0">
            <div class="h4 ff-secondary mb-0 d-flex align-items-center">
              <div class="bg-secondary rounded-xs me-8">
                <v-icon icon="compass" size="xxl" space="p-12"/>
              </div>

              {{ $t(getTitle()) }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!!tourIdAux"
        class="tour-steps-container d-flex align-items-center justify-content-center mb-48 flex-wrap"
      >
        <div
          v-for="step in tourSteps"
          :key="step.key"
          class="tour-step"
        >
          <router-link
            :id="step.id"
            :aria-label="$t(step.translation)"
            :class="{ 'router-link-active': step.url === $route.name }"
            :to="{ name: step.url, params: getParams(step.url) }"
            class="btn btn-outline-primary"
            @click="actualStep = step.key"
          >
            {{ $t(step.translation) }}
          </router-link>
        </div>
      </div>

      <div class="d-flex justify-content-end mb-28">
        <v-button
          class="btn-icon w-fit align-self-end me-16"
          size="sm"
          :disabled="disableDuplicateBtn"
          variant="primary"
          @click="duplicateTemplate"
        >
          {{ $t('template.duplicateActivity') }}
          <span class="mi mi-copy text-sm ms-12"/>
        </v-button>

        <v-button
          :disabled="caseCreateOpened || currentTemplate?.isCollaborator"
          class="btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="openOffCanvas('the-case-create-offcanvas'); caseCreateOpened = true"
        >
          {{ $t('template.createCaseWithTemplate') }}
          <v-icon icon="plus" size="sm" space="ms-12"/>
        </v-button>
      </div>

      <router-view v-if="!!currentTemplate" :template="currentTemplate" @reload-template="loadTemplate"/>
    </template>

    <div v-if="false" class="fixed-bottom bg-body">
      <div class="container-xl">
        <div class="tour-list-container">
          <v-button
            :aria-label="$t('tourClient.createTour')"
            class="btn-icon"
            size="sm"
            variant="outline-tertiary"
            @click="createTour"
          >
            <v-icon icon="plus" size="xs" space="me-8"/>

            <span>{{ $t('tourClient.createTour') }}</span>
          </v-button>

          <router-link
            v-for="tour in tours"
            :key="tour.id"
            :aria-label="$t('tourClient.tour')"
            :class="{ active: tourIdAux === tour.id }"
            :to="{ name: 'tour.builder.show', params: { templateId: tour.id } }"
            class="btn btn-outline-tertiary btn-sm shadow-none"
          >
            <div class="d-flex">
              {{ tour.id }}

              <v-dropdown
                :id="`tour-dropdown-${tour.id}`"
                button-classes="text-sm fw-medium bg-transparent shadow-none btn-menu"
                class="ms-8"
                dropdown-classes="dropdown-tour dropdown-menu-end"
              >
                <template #content>
                  <li class="dropdown-list p-0">
                    <a
                      :aria-label="$t('tourClient.archiveTemplate')"
                      class="dropdown-item px-8 py-16"
                      href="#"
                      @click.prevent="archiveTemplate(tour.id)"
                    >
                      <span>{{ $t('tourClient.archiveTemplate') }}</span>
                    </a>
                  </li>
                </template>
              </v-dropdown>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <the-case-create-offcanvas
      v-if="offCanvasToShow === 'the-case-create-offcanvas'"
      :template-data="currentTemplate"
      @closed="closeOffCanvas(); caseCreateOpened = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { useAuthStore } from '@/stores/auth';
import Tour from '@/api/objects/Tour';
import VDropdown from '@/components/vendor/basic/dropdown/VDropdown.vue';
import { useUserStore } from '@/stores/user';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import TheCaseCreateOffcanvas from '@/components/case/TheCaseCreateOffcanvas.vue';

export default defineComponent({
  name: 'TheTemplate',
  components: {
    TheCaseCreateOffcanvas,
    VLoader,
    VDropdown,
    VIcon,
    VButton,
  },
  props: {
    templateId: {
      type: String,
      required: false,
    },
  },
  setup() {
    const userStore = useUserStore();

    return {
      ...useOffCanvasUtils(),
      userStore,
    };
  },
  data() {
    return {
      caseCreateOpened: false as boolean,
      disableDuplicateBtn: false,
      tourIdAux: null as null | number,
      tours: [] as Tour[],
      status: 'loading' as string,
      actualStep: 'setup',
      tourSteps: [
        {
          key: 'setup',
          label: 'Setup',
          url: 'template.setup.show',
          id: 'template-case-setup',
          translation: 'tourClient.setup',
        },
        {
          key: 'builder',
          label: 'Builder',
          url: 'template.builder.show',
          id: 'template-builder',
          translation: 'tourClient.builder',
        },
      ] as Record<string, any>[],
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    ...mapState(useUserStore, ['currentTemplate']),
  },
  watch: {
    async templateId(newValue) {
      this.status = 'loading';

      this.tourIdAux = Number(newValue);

      await this.loadTemplate();

      this.status = 'loaded';
    },
  },
  async created() {
    if (!!this.$router.currentRoute.value.name) {
      const stepAux = this.tourSteps.find(
        (step: Record<string, string>) => step.url === this.$router.currentRoute.value.name);

      if (!!stepAux) {
        this.actualStep = stepAux.key;

        await this.$router.replace({
          name: this.$router.currentRoute.value.name,
          params: { templateId: this.templateId },
        });
      } else {
        await this.$router.replace({
          name: 'template.setup.show',
          params: { templateId: this.templateId },
        });
      }
    }

    await this.loadTemplate();

    this.status = 'loaded';
  },
  methods: {
    async duplicateTemplate() {
      if (!!this.agency && !!this.templateId) {
        this.disableDuplicateBtn = true;

        try {
          const response = await api.template.duplicate(this.agency.slug, this.templateId);

          this.$toast.success(this.$t('template.duplicatedActivitySuccess'));

          this.$toast.success(this.$t('template.redirectingNewActivity', { timeout: 10000 }));

          await this.$router.push({
            name: 'template.setup.show',
            params: { templateId: response.id },
          });
        } catch (e: any) {
          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        } finally {
          this.disableDuplicateBtn = false;
        }
      }
    },
    async loadTemplate() {
      if (!!this.agency) {
        try {
          const tourData = {
            id: this.templateId,
            agency: this.agency.slug,
          };

          await this.userStore.setCurrentTemplate(tourData);

          this.tourIdAux = Number(this.templateId);
        } catch (e: any) {
          await this.$router.replace({ name: 'template.index' });
        }
      }
    },
    async createTour() {
      await this.$modal.confirm({
        title: this.$t('tourClient.createTour'),
        text: this.$t('tourClient.createTourText'),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doCreateTour,
      });
    },
    async doCreateTour() {
      // if (!!this.agency) {
      //   const tour: Tour = await api.tour.create(this.agency.slug, Number(this.caseId), { status: 'initializing' });
      //
      //   this.$toast.success(tour.message);
      //
      //   await this.loadTemplate();
      //
      //   await this.$router.replace({
      //     name: 'tour.builder.show',
      //     params: { caseId: Number(this.caseId), templateId: tour.id },
      //   });
      // }
    },
    async archiveTemplate(templateId: number) {
      await this.$modal.delete({
        title: this.$t('template.archiveTitle'),
        text: this.$t('template.archiveText'),
        deleteButtonText: this.$t('general.button.archive'),
        deleteButtonLoadingText: this.$t('general.button.archiving'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doArchiveTemplate(templateId),
      });
    },
    async doArchiveTemplate(templateId: number) {
      if (!!this.agency) {
        await api.template.archive(this.agency.slug, templateId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.loadTemplate();

        // TODO: Que te lleve a otro tour
        //
        // await this.$router.replace({
        //   name: 'tour.builder.show',
        //   params: { caseId: Number(this.caseId), templateId: tour.id },
        // });
      }
    },
    getTitle() {
      let title = '';

      switch (this.actualStep) {
        case 'setup':
          title = 'template.setup';
          break;
        case 'builder':
          title = 'template.productBuilder';
          break;
        default:
          title = 'template.setup';
          break;
      }

      return title;
    },
    getParams(url: string) {
      if (url === 'template.index') {
        return {};
      }

      return { templateId: this.templateId };
    },
  },
  unmounted() {
    this.userStore.setCurrentTour(null);
  },
});
</script>

<style lang="scss" scoped>
.tour-steps-container {
  gap: 1rem;
}
</style>
