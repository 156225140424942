<template>
  <div v-if="!!tourDayItemResource">
    <div class="text-sm mb-8">{{ $t("tourBuilder.booking") }}</div>

    <div class="d-flex align-items-end gap-16">
      <div class="form-outline w-100 mb-0">
        <label class="label">
          <span>{{ $t("tourBuilder.bookingStatus") }}</span>
        </label>

        <v-select
          :id="`tour-day-item-resource${tourDayItemResource.id}-update-reservation-status`"
          v-model="reservationStatus"
          :clearable="false"
          :options="itemResourceReservationStatusTypes"
          :reduce="(returnValue: any) => returnValue.key"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
          </template>
        </v-select>
      </div>

      <v-button
        :disabled="!meta.valid"
        class="btn-submit-form btn-icon w-fit align-self-end"
        size="sm"
        variant="primary"
        @click="updateTourDayItemResourceReservationStatus"
      >
        {{ $t("general.button.save") }}
        <v-icon icon="arrow-right" size="sm" space="ms-12" />
      </v-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { useField } from "vee-validate";
import vSelect from "vue-select";
import { useUserStore } from "@/stores/user";
import useConcepts from "@/helpers/Concepts";
import useFormValidation from "@/helpers/form";
import api from "@/api";
import { mapConcepts } from "@/helpers/ConceptHelper";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";

export default defineComponent({
  name: "TheTourDayItemDetailsResourceReservationForm",
  components: {
    vSelect,
    VButton,
    VIcon,
  },
  emits: ["closed", "emitReloadTour"],
  props: {
    resource: {
      type: Object,
      required: true,
    },
    agency: {
      type: String,
      required: true,
    },
    clientTourId: {
      type: Number,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    tourDayId: {
      type: Number,
      required: true,
    },
    tourDayItemId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const { t } = useI18n();

    const rules = yup.object({
      reservationStatus: yup.string().required().label(t("tourBuilder.bookingStatus")),
    });

    const initialValues: Record<string, any> = {
      reservationStatus: props.resource.reservationStatus,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: reservationStatus } = useField("reservationStatus");

    return {
      userStore,
      ...useConcepts(),
      ...form,
      reservationStatus,
    };
  },
  data() {
    return {
      tourDayItemResource: this.resource,
    };
  },
  computed: {
    itemResourceReservationStatusTypes(): any {
      if (!!this.concepts && this.concepts["tour.reservation_status_type"]) {
        return mapConcepts(this.concepts["tour.reservation_status_type"]);
      }

      return [];
    },
  },
  watch: {
    async resource(value: any) {
      this.tourDayItemResource = value;
    },
  },
  methods: {
    async updateTourDayItemResourceReservationStatus() {
      try {
        const status = {
          reservation_status: this.reservationStatus,
        };

        const payload = {
          status: this.reservationStatus,
          status_type: "reservation",
          resources_id: this.tourDayItemResource.id,
        };

        if (this.tourDayItemResource.id.length > 1) {
          await api.tourDayItemResource.updateStatusBulk(this.agency, this.clientTourId, this.tourId, payload);
        } else {
          await api.tourDayItemResource.updateStatus(
            this.agency,
            this.clientTourId,
            this.tourId,
            this.tourDayId,
            this.tourDayItemId,
            this.tourDayItemResource.id,
            status
          );
        }

        this.$emit("emitReloadTour");

        const data = {
          agency: this.agency,
          clientTourId: this.clientTourId,
          tourId: this.tourId,
          tourDayId: this.tourDayId,
          tourDayItemId: this.tourDayItemId,
        };

        await this.userStore.setCurrentTourDayItem(data);

        this.$toast.success(this.$t("general.shared.savedChanges"));
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
