import { AbstractService } from '@uniqoders/sdk';
import Client from '@/api/objects/Client';

export default class ClientService extends AbstractService {
  /**
   * Retrieves all Clients
   * @protected
   * @param agency
   * @param params
   */
  public all(agency: string, params: any): Promise<Client[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/clients', { agency }),
      params,
    });
  }

  /**
   * Retrieves all Clients paginated
   * @protected
   * @param agency
   * @param params
   * @param paginate
   */
  public allPaginated(agency: string, params: any, paginate = true): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/clients', { agency }),
      params,
      options: {
        paginate,
      },
    });
  }

  /**
   * Retrieves a specific Client
   * @param agency
   * @param client
   */
  public retrieve(agency: string, client: string | number): Promise<Client> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/client/{client}', { agency, client }),
    });
  }

  /**
   * Creates a single Client
   * @protected
   * @param agency
   * @param data
   */
  public create(agency: string, data: Record<string, any>): Promise<Client> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/client', { agency }),
      data,
    });
  }

  /**
   * Updates the specified Client
   * @param agency
   * @param client
   * @param data
   * @protected
   */
  public update(agency: string, client: string | number, data: Record<string, any>): Promise<Client> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/client/{client}', { agency, client }),
      data,
    });
  }

  /**
   * Archives the specified Client
   * @protected
   * @param agency
   * @param client
   */
  public archive(agency: string, client: string | number): Promise<Client> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/client/{client}', { agency, client }),
    });
  }

  /**
   * Restore the specified Client
   * @protected
   * @param agency
   * @param client
   */
  public restore(agency: string, client: string | number): Promise<Client> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/client/{client}/restore', { agency, client }),
    });
  }

  /**
   * Deletes the specified Client
   * @protected
   * @param agency
   * @param client
   */
  public delete(agency: string, client: string | number): Promise<Client> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/client/{client}/force', { agency, client }),
    });
  }
}
