import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface PermissionContract extends IBaseObject {
  name: string;
}

export default class Permission extends BaseObject implements PermissionContract {
  public declare name: string;

  constructor(data: PermissionContract) {
    super(data);
  }
}
