<template>
  <v-off-canvas
    id="client-tour-archived-details-offcanvas"
    :backdrop-static="false"
    offcanvas-class="item-details offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <div class="d-flex align-items-center">
        <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

        <h5 id="client-details-offcanvas-label" class="offcanvas-title">
          {{ $t('clientTour.caseDetails') }}
        </h5>
      </div>
      </off-canvas-header>

      <off-canvas-body class="justify-content-between d-flex flex-column">
      <v-loader v-if="status === 'loading'"/>

      <div v-else class="item-details">
        <div class="detail">
          <div class="detail-title d-flex justify-content-between mb-20">
            <div class="fw-medium">{{ $t('clientTour.basicInformation') }}</div>
          </div>

          <div class="detail-content">
            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium">{{ $t('general.shared.name') }}: </span>
              <span>{{ clientTour.name }}</span>
            </div>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium">{{ $t('general.shared.dates') }}: </span>
              <span>
                {{ $str.formatDateTime(clientTour.initDate, settings.formatDate) }}
                -
                {{ $str.formatDateTime(clientTour.endDate, settings.formatDate) }}
              </span>
            </div>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium">{{ $t('general.shared.pax') }}: </span>
              <span>{{ clientTour.paxNumber }}</span>
            </div>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium">{{ $t('clientTour.client') }}: </span>
              <span>{{ clientTour.client?.name }} {{ clientTour.client?.surname }}</span>
            </div>
          </div>
        </div>

        <div class="detail">
          <div class="detail-title fw-medium mb-20">
            {{ $t('general.shared.restore') }}
          </div>

          <div class="detail-content">
            <p class="text-sm">{{ $t('clientTour.archivedText') }}</p>

            <v-button
              id="btn-restore-client-tour"
              :disabled="disableRestoreBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="primary"
              @click="restoreClientTour"
            >
              <v-icon icon="unarchive" space="me-12"/>
              <template v-if="disableRestoreBtn">{{ $t('general.button.restoring') }}</template>
              <template v-else>{{ $t('general.button.restore') }}</template>
            </v-button>
          </div>
        </div>

        <div v-if="!!clientTour.deletedAt" class="detail">
          <div class="detail-title fw-medium mb-20">
            {{ $t('general.shared.delete') }}
          </div>

          <div class="detail-content">
            <p class="text-sm">{{ $t('clientTour.deleteText') }}</p>

            <v-button
              id="btn-delete-client-tour"
              :disabled="disableDeleteBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="deleteClientTour"
            >
              <v-icon icon="trash" space="me-12"/>
              <template v-if="disableDeleteBtn">{{ $t('general.button.deleting') }}</template>
              <template v-else>{{ $t('general.button.delete') }}</template>
            </v-button>
          </div>
        </div>
      </div>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import ClientTour from '@/api/objects/ClientTour';
import { useAuthStore } from '@/stores/auth';
import { useAppStore } from '@/stores/app';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheCaseArchivedOffcanvas',
  components: {
    VLoader,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
  },
  emits: [
    'closed',
    'reloadClientTours',
  ],
  props: {
    clientTour: {
      type: ClientTour,
      required: true,
    },
  },
  data() {
    return {
      status: 'loading' as string,
      disableDeleteBtn: false as boolean,
      disableRestoreBtn: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    ...mapState(useAppStore, ['settings']),
  },
  async created() {
    this.status = 'loaded';
  },
  methods: {
    async restoreClientTour() {
      await this.$modal.confirm({
        title: this.$t('clientTour.restoreClientTourTitle'),
        text: this.$t('clientTour.restoreClientTourText', { clientTour: this.clientTour.name }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doRestoreClientTour,
      });
    },
    async doRestoreClientTour() {
      if (!!this.agency) {
        try {
          this.disableRestoreBtn = true;

          await api.case.restore(this.agency.slug, this.clientTour.id);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.$emit('reloadClientTours');
          this.$emit('closed');

          this.disableRestoreBtn = false;
        } catch (e: any) {
          this.disableRestoreBtn = false;

          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    async deleteClientTour() {
      await this.$modal.delete({
        title: this.$t('clientTour.deleteClientTourTitle'),
        text: this.$t('clientTour.deleteClientTourText', { clientTour: this.clientTour.name }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doDeleteClientTour,
      });
    },
    async doDeleteClientTour() {
      if (!!this.agency) {
        try {
          this.disableDeleteBtn = true;

          await api.case.delete(this.agency.slug, this.clientTour.id);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.$emit('reloadClientTours');
          this.$emit('closed');

          this.disableDeleteBtn = false;
        } catch (e: any) {
          this.disableDeleteBtn = false;

          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
  },
});
</script>
