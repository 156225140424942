import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import Supplier from '@/api/objects/Supplier';
import Media from '@/api/objects/Media';
import SupplierResourcePrice from '@/api/objects/SupplierResourcePrice';
import SupplierResourceTranslation from '@/api/objects/SupplierResourceTranslation';

export interface SupplierResourceContract extends IBaseObject {
  amenities: string;
  name: string;
  description: string;
  type: string;
  cancellationPolicy: string;
  supplierId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  media: Media[];
  translations: SupplierResourceTranslation[];
  prices: SupplierResourcePrice[];
  supplier: Supplier;
  tourDayItemResources: Record<string, any>[];
}

export default class SupplierResource extends BaseObject implements SupplierResourceContract {
  public declare amenities: string;
  public declare name: string;
  public declare description: string;
  public declare type: string;
  public declare cancellationPolicy: string;
  public declare supplierId: number;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare media: Media[];
  public declare translations: SupplierResourceTranslation[];
  public declare prices: SupplierResourcePrice[];
  public declare supplier: Supplier;
  public declare tourDayItemResources: Record<string, any>[];

  constructor(data: SupplierResourceContract) {
    super(data);
  }
}
