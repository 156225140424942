import { createI18n } from 'vue-i18n';
import es from './es';
import en from './en';
import nl from './nl';

// const locale = navigator.language.replace(/-/g, '_');
const locale = localStorage.getItem('language');

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: locale || import.meta.env.VITE_APP_I18N_LOCALE,
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE,
  messages: {
    es,
    en,
    nl,
  },
});

export default i18n;
