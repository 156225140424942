<template>
  <div class="detail">
    <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
      <div class="fw-medium">{{ $t('admin.user.basicInformation') }}</div>

      <v-button
        v-if="!userDetails.deletedAt"
        class="btn-edit p-4"
        variant="icon"
        @click="editBasicInformation = !editBasicInformation">
        <v-icon icon="pencil"/>
      </v-button>
    </div>

    <div class="detail-content">
      <Transition mode="out-in" name="fade-in">
        <div v-if="!editBasicInformation">
          <div class="text-sm d-flex py-4">
            <span class="info-title fw-medium">{{ $t('general.shared.name') }}: </span>
            <span>{{ userDetails.name }}</span>
          </div>

          <div class="text-sm d-flex">
            <span class="info-title fw-medium">{{ $t('general.shared.email') }}: </span>
            <span>{{ email }}</span>
          </div>
        </div>

        <form
          v-else
          id="user-update"
          class="d-flex flex-column justify-content-between h-100"
        >
          <fieldset class="mb-16">
            <legend class="visually-hidden">{{ $t('admin.user.basicInformation') }}</legend>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium">{{ $t('general.shared.name') }}: </span>
              <span>{{ userDetails.name }}</span>
            </div>

            <v-form-input
              id="user-update-email"
              v-model="email"
              :label="`${this.$t('general.shared.email')}*`"
              :type="'email'"
              :yup-errors-variable="errors.email"
              autocomplete="off"
              form-type="outline"
            />
          </fieldset>

          <v-button
            :disabled="!meta.valid || accepted"
            :is-loading="accepted"
            class="btn-submit-form btn-icon w-fit align-self-end"
            size="sm"
            variant="primary"
            @click="saveUser"
          >
            {{ $t('general.button.save') }}
            <v-icon icon="arrow-right" size="sm" space="ms-12"/>
          </v-button>
        </form>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import useModalUtils from '@/helpers/ModalUtils';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import User from '@/api/objects/User';
import useCapabilities from '@/helpers/capabilities';

export default defineComponent({
  name: 'TheAdminUserDetailsBasicInformation',
  components: {
    VIcon,
    VButton,
    VFormInput,
  },
  emits: [
    'closed',
    'reloadUser',
  ],
  props: {
    userDetails: {
      type: [User, Object],
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      email: yup.string()
        .email()
        .required()
        .label(t('general.shared.email')),
    });

    const form = useFormValidation(rules);

    const { value: email } = useField('email');

    return {
      ...useCapabilities(),
      ...useModalUtils(),
      ...form,
      email,
    };
  },
  data() {
    return {
      editBasicInformation: false as boolean,
    };
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      if (!!this.userDetails) {
        this.email = this.userDetails.email;
      }
    },
    async saveUser() {
      try {
        this.toggleAccepted();

        const data: any = {
          email: this.email,
        };

        await api.admin.user.update(this.userDetails.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadUser');

        this.editBasicInformation = !this.editBasicInformation;
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
      }
    },
  },
});
</script>
