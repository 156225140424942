export default {
  clients: 'Klanten',
  noClients: 'Er zijn geen klanten',
  createNewClient: 'Maak nieuwe klant aan',
  archivedClients: 'Gearchiveerde klanten',
  activeClients: 'Actieve klanten',
  basicInformation: 'Basisinformatie',
  clientDetails: 'Klantgegevens',
  archiveText: 'Je kunt een klant archiveren, als je dat doet zal de klant niet verschijnen in de applicatie zoekopdrachten. Je kunt het zien in de lijst met klanten als je filtert op "gearchiveerd". Nadat je de details van een gearchiveerde klant hebt bekeken, kun je deze definitief verwijderen of ongedaan maken.',
  media: 'Media',
  noMedia: 'Er zijn geen gekoppelde media',
  archivedText: 'Deze klant is gearchiveerd, als je wilt, kun je deze terugzetten door op de knop \'Herstellen\' te klikken.',
  archiveClientTitle: 'Klant archiveren',
  archiveClientText: 'Weet je zeker dat je de klant \'{client}\' wilt archiveren? Als je het archiveert, zal het niet verschijnen in zoekopdrachten.',
  deleteText: 'Deze klant is gearchiveerd. Als je deze permanent wilt verwijderen, klik dan op de \'Verwijderen\' knop. Let op: als je het verwijdert, bestaat deze klant niet meer en kan deze niet meer worden hersteld.',
  deleteClientTitle: 'Klant verwijderen',
  deleteClientText: 'Weet je zeker dat je de klant \'{client}\' wilt verwijderen? Als je het verwijdert, kun je het niet meer herstellen.',
  restoreClientTitle: 'Klant herstellen',
  restoreClientText: 'Weet je zeker dat je de klant \'{client}\' wilt herstellen? Als je het herstelt, zal het niet langer gearchiveerd zijn en verschijnt het in de applicatie zoekopdrachten.',
  clientStatus: 'Klantstatus',
  contacts: 'Contacten',
  noContacts: 'Er zijn geen gekoppelde contacten',
  editContactsBelongClientText: 'Bewerk contacten die bij de klant horen',
  addContacts: 'Contacten toevoegen',
  removeContactTitle: 'Verwijder klantcontact',
  removeContactText: 'Weet je zeker dat je {contact} wilt verwijderen als contact voor de klant {client}?',
  cases: 'Zaken',
  casesText: 'Lijst van zaken die bij de klant horen',
  seeCases: 'Bekijk zaken',
};
