<template>
  <div class="form-outline">
    <label v-if="!!label" :class="[labelClass, { disabled: disabledInput }]" :for="id" class="label">
      <span :class="labelSpanClass">{{ label }}</span>
    </label>

    <input
      :id="id"
      ref="autocomplete"
      v-model="valueLocal"
      :class="[computedClasses, inputClass]"
      :disabled="disabledInput"
      :placeholder="placeholder"
      autocomplete="off"
      class="form-control"
      type="text"
      @input="$emit('input', valueLocal)"
    />

    <slot name="errors"/>

    <span v-if="!!yupErrorsVariable && !$slots.errors" class="invalid-feedback">
      {{ yupErrorsVariable }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { mapState } from 'pinia';
import { useGoogleStore } from '@/stores/google';

export default defineComponent({
  name: 'VInputAddress',
  props: {
    id: {
      type: String,
      required: true,
    },
    disabledInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    labelClass: {
      type: String,
      required: false,
    },
    labelSpanClass: {
      type: String,
      required: false,
    },
    inputGroupTextClass: {
      type: String,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    yupErrorsVariable: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Object,
      required: false,
    },
    getRadiusBounds: {
      type: Boolean,
      required: false,
      default: false,
    },
    getRadiusBoundsWithAddress: {
      type: Boolean,
      required: false,
      default: false,
    },
    radiusBounds: {
      type: Number,
      required: false,
      default: 10000,
    },
    getAddress: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['selected', 'selectedPlace', 'radiusBounds', 'address', 'input'],
  setup() {
    const googleStore = useGoogleStore();

    return {
      googleStore,
    };
  },
  data() {
    return {
      valueLocal: '' as string,
    };
  },
  computed: {
    ...mapState(useGoogleStore, ['loader']),
    computedClasses() {
      return {
        'is-invalid': !!this.yupErrorsVariable,
      };
    },
  },
  watch: {
    options: {
      async handler(newValue, oldValue) {
        if (!!newValue.componentRestrictions && !!oldValue.componentRestrictions) {
          if (!!newValue.componentRestrictions.country && !!oldValue.componentRestrictions.country) {
            if (newValue.componentRestrictions.country !== oldValue.componentRestrictions.country) {
              await this.loadAutocomplete();
            }
          }
        }

        if (!!newValue.bounds && !!oldValue.bounds) {
          if (newValue.bounds !== oldValue.bounds) {
            await this.loadAutocomplete();
          }
        }
      },
      deep: true,
    },
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valueLocal = this.value;
      }
    },
  },
  async mounted() {
    this.valueLocal = this.value;

    await this.loadAutocomplete();
  },
  methods: {
    async loadAutocomplete() {
      if (!this.loader) {
        const locale = localStorage.getItem('language');

        const loader = new Loader({
          apiKey: import.meta.env.VITE_GMAPS_API_KEY.toString(),
          language: !!locale ? locale : import.meta.env.VITE_APP_DEFAULT_LOCALE,
          version: 'weekly',
          libraries: ['places'],
        });

        this.googleStore.setLoader(loader);
      }

      const google = await this.loader.load();

      let options = {};

      if (!!this.options) {
        options = this.options;
      }

      if (!!this.$refs.autocomplete) {
        const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete, options);

        autocomplete.addListener('place_changed', () => {
          this.$emit('selected');

          const place = autocomplete.getPlace();

          this.$emit('selectedPlace', place);

          if (!!this.$refs.autocomplete) {
            if (!!this.options && !!this.options.types && !!this.options.types.includes('(cities)')) {
              this.valueLocal = place.formatted_address;
            } else {
              this.valueLocal = place.name;
            }
          }

          let circle: any = null;

          if (this.getRadiusBounds || this.getRadiusBoundsWithAddress) {
            const defaultBounds = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
            circle = new google.maps.Circle({
              center: defaultBounds,
              radius: this.radiusBounds,
            });
          }

          if (this.getAddress) {
            const address: Record<string, any> = {
              streetNumber: {},
              route: {},
              postalCode: {},
              postalCodeSuffix: {},
              locality: {},
              stateCommunity: {},
              regionProvince: {},
              country: {},
              formattedAddress: '',
              name: '',
              vicinity: '',
              placeId: '',
              lat: null,
              lng: null,
              bounds: null,
            };

            address.formattedAddress = place.formatted_address;
            address.name = place.name;
            address.vicinity = place.vicinity;
            address.placeId = place.place_id;
            address.lat = place.geometry?.location.lat();
            address.lng = place.geometry?.location.lng();

            // eslint-disable-next-line no-restricted-syntax,no-undef
            for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
              const componentType = component.types[0];

              // eslint-disable-next-line default-case
              switch (componentType) {
                case 'street_number': {
                  address.streetNumber = {
                    shortName: component.short_name,
                    longName: component.long_name,
                  };
                  break;
                }

                case 'route': {
                  address.route = {
                    shortName: component.short_name,
                    longName: component.long_name,
                  };
                  break;
                }

                case 'postal_code': {
                  address.postalCode = {
                    shortName: component.short_name,
                    longName: component.long_name,
                  };
                  break;
                }

                case 'postal_code_suffix': {
                  address.postalCodeSuffix = {
                    shortName: component.short_name,
                    longName: component.long_name,
                  };
                  break;
                }

                case 'locality':
                  address.locality = {
                    shortName: component.short_name,
                    longName: component.long_name,
                  };
                  break;

                case 'administrative_area_level_1': {
                  address.stateCommunity = {
                    shortName: component.short_name,
                    longName: component.long_name,
                  };
                  break;
                }

                case 'administrative_area_level_2': {
                  address.regionProvince = {
                    shortName: component.short_name,
                    longName: component.long_name,
                  };
                  break;
                }

                case 'country':
                  address.country = {
                    shortName: component.short_name,
                    longName: component.long_name,
                  };
                  break;
              }
            }

            if (this.getRadiusBoundsWithAddress) {
              address.bounds = circle?.getBounds();
            }

            this.$emit('address', address);
          }

          if (this.getRadiusBounds) {
            this.$emit('radiusBounds', circle?.getBounds());
          }
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.input-group {
  .border-end {
    border-right: 1px solid #c0ccd8 !important;
  }

  .w-max {
    width: calc(100% - 2.7rem);
  }

  .w-max-border {
    width: calc(100% - 2.77rem);
  }

  @media (min-width: 992px) {
    .w-max-border {
      width: calc(100% - 2.75rem);
    }
  }
}
</style>

<style lang="scss">
.pac-container {
  z-index: 1070;
}

.input-group {
  .input-group-text {
    border-bottom-left-radius: .25rem;
    border-top-left-radius: .25rem;
    border: 1px solid #C0CCD8;
    border-right: none;
    color: var(--uq-gray-600);
  }

  //.form-floating {
  //  width: 55%;
  //  border-top: 1px solid #c0ccd8;
  //  border-bottom: 1px solid #c0ccd8;
  //
  //  .form-control {
  //    border: none;
  //    border-radius: 0;
  //  }
  //
  //  label {
  //    border: none;
  //    color: var(--uq-gray-600);
  //  }
  //}
}
</style>
