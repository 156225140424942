import admin from '@/lang/nl/admin';
import auth from '@/lang/nl/auth';
import client from '@/lang/nl/client';
import clientTour from '@/lang/nl/clientTour';
import contact from '@/lang/nl/contact';
import dashboard from '@/lang/nl/dashboard';
import general from '@/lang/nl/general';
import resource from '@/lang/nl/resource';
import supplier from '@/lang/nl/supplier';
import tourBuilder from '@/lang/nl/tourBuilder';
import tourClient from '@/lang/nl/tourClient';
import tourPricing from '@/lang/nl/tourPricing';
import tourBooking from '@/lang/nl/tourBooking';
import template from '@/lang/nl/template';
import traveler from '@/lang/nl/traveler';
import travelBook from '@/lang/nl/travelBook';
import user from '@/lang/nl/user';
import uploader from '@/lang/nl/uploader';
import emailTemplate from '@/lang/nl/emailTemplate';

const nl = {
  admin,
  auth,
  client,
  clientTour,
  contact,
  dashboard,
  general,
  resource,
  supplier,
  template,
  tourBuilder,
  tourClient,
  tourPricing,
  tourBooking,
  traveler,
  travelBook,
  user,
  uploader,
  emailTemplate,
};

export default nl;
