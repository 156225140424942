export default {
  resources: 'Información',
  noResources: 'No hay recursos',
  createNewResource: 'Crear nuevo recurso',
  createNewInformation: 'Crear nueva información',
  archivedResources: 'Recursos archivados',
  activeResources: 'Recursos activos',
  basicInformation: 'Información básica',
  resourceDetails: 'Detalles del recurso',
  archiveText: 'Puedes archivar un recurso, si haces esto el recurso no aparecerá en las búsquedas de la aplicación. Podrá verlo en la lista de recursos si filtra por \'archived\'. Una vez que veas los detalles de un recurso archivado, puedes borrarlo definitivamente o puedes desarchivarlo',
  media: 'Media',
  noMedia: 'No hay medios asociados',
  archivedText: 'Este recurso está archivado, si lo desea puede desarchivarlo pulsando el botón \'Restaurar\'.',
  archiveResourceTitle: 'Recurso archivado',
  archiveResourceText: '¿Está seguro de que desea archivar el recurso \'{resource}\'? Si lo archivas, no aparecerá en las búsquedas',
  deleteText: 'Este recurso está archivado, si quieres eliminarlo permanentemente pulsa el botón \'Eliminar\'. Ten en cuenta que si lo eliminas, este recurso dejará de existir y no se podrá recuperar.',
  deleteResourceTitle: 'Eliminar recurso',
  deleteResourceText: '¿Está seguro de que desea eliminar el recurso \'{resource}\'? Si lo eliminas, no podrás recuperarlo',
  restoreResourceText: '¿Está seguro de que desea recuperar el recurso \'{resource}\'? Si lo recuperas, dejará de estar archivado y aparecerá en las búsquedas de la aplicación.',
  resourcesStatus: 'Estado de los recursos',
  resourceSearch: 'Búsqueda de recursos',
  canSearchResource: 'Puedes buscar el recurso',
  orUpdateItManually: 'O actualizarlo manualmente',
  removeMediaTitle: 'Eliminar recurso',
  removeMediaText: '¿Estás seguro de que quieres borrar el archivo? Si lo borras, no podrás recuperarlo',
  informationDetails: 'Detalles de la información',
};
