<template>
  <v-off-canvas
    id="template-archived-details-offcanvas"
    :backdrop-static="false"
    offcanvas-class="item-details offcanvas-end"
    @closed="$emit('closed')"
  >
   <resizable-container>
    <off-canvas-header>
      <div class="d-flex align-items-center">
        <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

        <h5 id="template-details-offcanvas-label" class="offcanvas-title">
          {{ $t('template.details') }}
        </h5>
      </div>
    </off-canvas-header>

    <off-canvas-body class="justify-content-between d-flex flex-column">
      <v-loader v-if="status === 'loading'"/>

      <div v-else class="item-details">
        <div class="detail">
          <div class="detail-title d-flex justify-content-between mb-20">
            <div class="fw-medium">{{ $t('template.basicInformation') }}</div>
          </div>

          <div class="detail-content">
            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium">{{ $t('general.shared.name') }}: </span>
              <span>{{ template.name }}</span>
            </div>
          </div>
        </div>

        <div class="detail" v-if="!agency?.isCollaborator">
          <div class="detail-title fw-medium mb-20">
            {{ $t('general.shared.restore') }}
          </div>

          <div class="detail-content">
            <p class="text-sm">{{ $t('template.archivedText') }}</p>

            <v-button
              id="btn-restore-template"
              :disabled="disableRestoreBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="primary"
              @click="restoreTemplate"
            >
              <v-icon icon="unarchive" space="me-12"/>
              <template v-if="disableRestoreBtn">{{ $t('general.button.restoring') }}</template>
              <template v-else>{{ $t('general.button.restore') }}</template>
            </v-button>
          </div>
        </div>

        <div v-if="!!template.deletedAt" class="detail">
          <div class="detail-title fw-medium mb-20">
            {{ $t('general.shared.delete') }}
          </div>

          <div class="detail-content">
            <p class="text-sm">{{ $t('template.deleteText') }}</p>

            <v-button
              id="btn-delete-template"
              :disabled="disableDeleteBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="deleteTemplate"
            >
              <v-icon icon="trash" space="me-12"/>
              <template v-if="disableDeleteBtn">{{ $t('general.button.deleting') }}</template>
              <template v-else>{{ $t('general.button.delete') }}</template>
            </v-button>
          </div>
        </div>
      </div>
    </off-canvas-body>
  </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import { useAuthStore } from '@/stores/auth';
import Template from '@/api/objects/Template';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue'

export default defineComponent({
  name: 'TheTemplateArchivedOffcanvas',
  components: {
    VLoader,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer
  },
  emits: ['closed', 'reloadTemplates'],
  props: {
    template: {
      type: Template,
      required: true,
    },
  },
  data() {
    return {
      status: 'loading' as string,
      disableDeleteBtn: false as boolean,
      disableRestoreBtn: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
  },
  async created() {
    this.status = 'loaded';
  },
  methods: {
    async restoreTemplate() {
      await this.$modal.confirm({
        title: this.$t('template.restoreTitle'),
        text: this.$t('template.restoreText', { product: this.template.name }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doRestoreTemplate,
      });
    },
    async doRestoreTemplate() {
      if (!!this.agency) {
        try {
          this.disableRestoreBtn = true;

          await api.template.restore(this.agency.slug, this.template.id);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.$emit('reloadTemplates');
          this.$emit('closed');

          this.disableRestoreBtn = false;
        } catch (e: any) {
          this.disableRestoreBtn = false;

          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    async deleteTemplate() {
      await this.$modal.delete({
        title: this.$t('template.deleteTitle'),
        text: this.$t('template.deleteTextAlert', { product: this.template.name }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doDeleteTemplate,
      });
    },
    async doDeleteTemplate() {
      if (!!this.agency) {
        try {
          this.disableDeleteBtn = true;

          await api.template.delete(this.agency.slug, this.template.id);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.$emit('reloadTemplates');
          this.$emit('closed');

          this.disableDeleteBtn = false;
        } catch (e: any) {
          this.disableDeleteBtn = false;

          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
  },
});
</script>
