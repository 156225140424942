<template>
  <div id="supplier-list" class="container item-list pb-64" :class="{ 'loading-cursor': isLoading }">
    <v-list-header :title="this.$t('supplier.suppliers')" icon="group"
      @open-filters-offcanvas="openOffCanvas('the-supplier-filters-offcanvas')" @emit-close-off-canvas="closeOffCanvas"
      @reload-suppliers="loadSuppliers">
      <template #input-search>
        <v-input-text-search id="supplier-search" v-model="mix" :placeholder="this.$t('general.shared.search')"
          autocomplete="off" class="w-100 me-24" input-class="h-auto py-8" input-group-text-class="py-8 px-16"
          type="text" @update:modelValue="mix = $event; search()" />
      </template>
    </v-list-header>
    <div class="items-content">
      <div class="filters-container d-flex mb-24">
        <v-filters v-if="!!filterOptions" v-model="filterOptions" @remove-filter="removeFilter" />
      </div>
      <div class="filters-container d-md-flex">
        <div class="datepickers-container">
          <div class="form-outline">
            <label class="label">
              <span>{{ $t('general.shared.startDate') }}</span>
            </label>
            <Datepicker :format="'dd/MM/yyyy'" v-model="startDate" :enable-time-picker="false"
              :month-change-on-scroll="false" auto-apply calendar-cell-class-name="init-date-cell"
              class="form-datepicker datepicker-for-supplier" @update:modelValue="updateDateRange" />
          </div>

          <div class="form-outline">
            <label class="label">
              <span>{{ $t('general.shared.endDate') }}</span>
            </label>
            <Datepicker :format="'dd/MM/yyyy'" v-model="endDate" :enable-time-picker="false"
              :month-change-on-scroll="false" auto-apply calendar-cell-class-name="init-date-cell"
              class="form-datepicker datepicker-for-supplier" :min-date="startDate"
              @update:modelValue="updateDateRange" />
          </div>

          <div class="datepickers-container list-button-holder">
            <div class="form-outline supplier-list-view">
              <label class="label">
                <span>{{ $t('general.shared.supplierList') }}</span>
              </label>
              <v-select ref="supplierSelect" id="supplier-list-supplier-search" class="supplier-list-select-initial"
                :class="{ 'supplier-list-select': supplierIds.length >= 2 }" v-model="supplierIds"
                :options="suppliersList" label="name" :reduce="returnValue => returnValue.id" multiple
                @option:selected="scrollToEnd">
                <template v-slot:selected-option="option">
                  <span :title="option.name" class="supplier-name-truncated">
                    {{ truncateText(option.name) }}
                  </span>
                </template>
                <template v-slot:no-options>
                  <div v-if="suppliersList.length < 1" class="loader">
                    Loading...
                  </div>
                  <div v-else>
                    {{ $t('general.vueSelect.noOptions') }}
                  </div>
                </template>
              </v-select>
            </div>
            <div>
              <v-button class="btn-icon mb-7" size="sm" variant="primary" @click="exportBookingsToGoogleSheet('')"
                :disabled="disableExportButton" :is-loading="disableExportButton">
                <v-icon icon="export" space="me-12" />
                {{ $t('general.button.exportBookings') }}
              </v-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive d-none d-md-flex">
        <v-table>
          <v-table-header class="table-header">
            <tr>
              <th class="name-of-supplier">
                <v-button :class="{ 'sort-active': order.key === 'name' }"
                  class="btn-order text-md fw-light shadow-none p-0 pb-4" variant="icon" @click="toggleOrder('name')">
                  {{ $t('general.shared.name') }}
                  <v-icon :class="{ 'sort-icon-transition': order.key === 'name' && order.order === 'DESC' }"
                    class="ms-8" icon="chevron-down" size="xs" />
                </v-button>
              </th>

              <th>
                <v-button :class="{ 'sort-active': order.key === 'type' }"
                  class="btn-order text-md fw-light shadow-none p-0 pb-4" variant="icon" @click="toggleOrder('type')">
                  {{ $t('general.shared.type') }}
                  <v-icon :class="{ 'sort-icon-transition': order.key === 'type' && order.order === 'DESC' }"
                    class="ms-8" icon="chevron-down" size="xs" />
                </v-button>
              </th>

              <th>{{ $t('general.shared.amenities') }}</th>
              <th>{{ $t('general.shared.location') }}</th>
              <th class="fit" />
            </tr>

          </v-table-header>

          <v-table-body class="fw-medium">
            <tr v-if="!loaded">
              <td class="position-relative border-0 py-96" colspan="2222">
                <v-loader />
              </td>
            </tr>
            <template v-else-if="!!suppliers && suppliers.length > 0">
              <template v-for="supplier in suppliers" :key="supplier.id">
                <v-table class="suppliersTable">
                  <tr class="suppliersTableSection">
                    <td>
                  <tr>
                    <td class="table-borderless" style="width:50%">
                      <div class="cell-content">
                        <div class="image-container rounded-sm bg-tertiary me-12">
                          {{ supplier.name.charAt(0) }}
                        </div>
                        <a class="py-12" href="#" @click.prevent="showSupplierDetails(supplier)">
                          {{ supplier.name }}
                        </a>
                        <br>
                      </div>
                    </td>
                    <td class="table-borderless">{{ findConceptByKey('supplier.type', supplier.type).name }}</td>
                    <td class="table-borderless">
                      <template v-if="!!supplier.amenities">
                        {{ getAllAmenities(supplier.amenities) }}
                      </template>
                      <template v-else>-</template>
                    </td>

                    <td class="table-borderless">
                      <template v-if="!!supplier.locationName">{{ supplier.locationName }}</template>
                      <template v-else>-</template>
                    </td>

                    <td class="table-borderless fit text-end common-icon-padding">
                      <v-button v-if="selectedSupplierId == supplier.id && showBookings"
                        class="btn-view rounded-pill bg-primary r-btn" size="xs" variant="primary"
                        @click="exportBookingsToGoogleSheet(supplier.id)" :disabled="disableExportButton"
                        :is-loading="disableExportButton">
                        <div class="others-type-tooltip-description" :data-title="$t('general.toast.exportTooltip')">
                          <v-icon icon="export" />
                          <div class="tooltip-right-arrow"></div>
                        </div>
                      </v-button>

                      <v-button v-if="selectedSupplierId !== supplier.id"
                        class="btn-view rounded-pill bg-warning r-btn ms-8" size="xs" variant="icon"
                        @click="showSupplierBookings(supplier, $event)">
                        <div class="others-type-tooltip-description"
                          :data-title="$t('general.toast.showSupplierBookings')">
                          <v-icon icon="bookmark" />
                          <div class="tooltip-right-arrow"></div>
                        </div>
                      </v-button>

                      <v-button class="btn-view rounded-pill bg-tertiary r-btn me-8 ms-8" size="xs" variant="icon"
                        @click="handleButtonClick(supplier)">
                        <v-icon icon="euro" />
                      </v-button>

                      <v-button class="btn-view rounded-pill bg-secondary r-btn" size="xs" variant="icon"
                        @click="showSupplierDetails(supplier)">
                        <v-icon icon="arrow-right" />
                      </v-button>

                    </td>

                  </tr>
                  <div v-if="selectedSupplierId == supplier.id && showBookings" class="show-bookings-table-carousal">
                    <Carousel class="carousel-container" :settings="getCarouselSettings()"
                      :breakpoints="carouselBreakpoints">
                      <Slide v-for="(data, index) in dateRange" :key="index">
                        <div class="card" :class="{ 'highlighted': index === selectedCardIndex }"
                          @click="selectDate(data.date.toString(), index)">
                          <div style="font-weight: 600;">
                            {{ formatDate(data.date) }}
                          </div>
                          <div v-if="data.count == 0">No Bookings</div>
                          <div class="countBooking" v-else>
                            {{ data.count }} {{ data.count > 1 ? 'Bookings' : 'Booking' }}
                          </div>
                        </div>
                      </Slide>
                      <template #addons>
                        <Navigation />
                      </template>
                    </Carousel>
                  </div>
                  <template v-if="isLoading && selectedSupplierId == supplier.id">
                    <v-table>
                      <tr>
                        <td class="position-relative border-0 py-96" colspan="2222">
                          <v-loader />
                        </td>
                      </tr>
                    </v-table>
                  </template>

                  <template v-if="selectedSupplierId == supplier.id && showBookings">
                    <div class="table-wrapper">
                      <table class="bookingTable">
                        <thead>
                          <tr class="bookingDetails">
                            <th class="bookingDetails bookings-header">{{ $t('tourBooking.caseName') }}</th>
                            <th class="bookingDetails bookings-header">{{ $t('tourBooking.teamName') }}</th>
                            <th class="bookingDetails bookings-header" v-if="supplier.type == 'hotel'">{{
                              $t('tourBooking.roomType') }}
                            </th>
                            <th class="bookingDetails bookings-header"
                              v-if="supplier.type == 'transport' || supplier.type == 'transfer' || supplier.type == 'bus'">
                              {{ $t('tourBooking.journeyRoute') }}
                            </th>
                            <th class="bookingDetails bookings-header" v-else-if="supplier.type !== 'hotel'">{{
                              $t('tourBooking.product')
                              }}
                            </th>
                            <th class="bookingDetails bookings-header-end">{{
                              $t('tourBooking.quantity') }}
                            </th>
                            <th class="bookingDetails bookings-header-end">{{ $t('tourBooking.pax') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <template v-if="bookingData[selectedDate]?.length > 0">
                            <tr v-for="booking in bookingData[selectedDate]" :key="booking.id">
                              <td class="left-align table-borderless bookings-header">{{ booking.caseName }}</td>
                              <td class="left-align table-borderless bookings-header">{{ booking.collaboratorName }}
                              </td>
                              <td class="left-align table-borderless bookings-header" v-if="supplier.type == 'hotel'">
                                {{ booking.roomType }}</td>
                              <td class="left-align table-borderless bookings-header" v-else>
                                {{ booking.product }}
                              </td>
                              <td class="left-align table-borderless bookings-header-adjust">
                                {{ booking.quantity }}
                              </td>
                              <td class="left-align table-borderless bookings-header-end-value">{{ booking.pax }}</td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr>
                              <td class="center-align table-borderless" colspan="8">
                                {{ $t('tourBooking.noBookingsFound') }}
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </template>
                  </td>
                  </tr>
                </v-table>
              </template>
            </template>

            <template v-else>
              <tr>
                <td class="rounded-xs py-16" colspan="2222">
                  {{ $t('supplier.noSuppliers') }}
                </td>
              </tr>
            </template>
          </v-table-body>
        </v-table>
      </div>

      <div class="item-cards d-block d-md-none">
        <div v-if="!loaded" class="position-relative py-96">
          <v-loader />
        </div>

        <template v-else-if="!!suppliers && suppliers.length > 0">
          <div v-for="supplier in suppliers" :key="supplier.id" class="item-card border rounded-xs p-8 mb-12 text-sm">
            <div class="d-flex">
              <div class="image-container rounded-sm bg-tertiary me-12">
                {{ supplier.name.charAt(0) }}
              </div>
              <div class="w-100">
                <div class="fw-medium mb-4">{{ supplier.name }}</div>
                <div class="d-flex justify-content-between mb-4 fw-light subtext gap-16 text-xs">
                  <div>{{ findConceptByKey('supplier.type', supplier.type).name }}</div>
                  <div v-if="!!supplier.locationName">{{ supplier.locationName }}</div>
                </div>
              </div>
            </div>
            <div class="text-xs">
              <span>{{ $t('general.shared.amenities') }}: </span>

              <template v-if="!!supplier.amenities">
                {{ getAllAmenities(supplier.amenities) }}
              </template>
              <template v-else>-</template>
            </div>

            <div class="d-flex justify-content-end">
              <v-button class="btn-view rounded-pill bg-secondary" size="xs" variant="icon"
                @click="showSupplierDetails(supplier)">
                <v-icon class="fw-semi" icon="arrow-right" size="xs" />
              </v-button>
            </div>
          </div>
        </template>

        <div v-else>
          {{ $t('supplier.noSuppliers') }}
        </div>
      </div>

      <v-pagination v-if="!!suppliers && suppliers.length > 0" :meta="pagination.meta"
        :pagination-links="pagination.links" class="mt-32"
        @page-changed="changePage($event); handlePageChangedEvent($event)"
        @per-page-changed="perPage = $event; doSearch()" />
    </div>
    <the-supplier-filters-offcanvas v-if="offCanvasToShow === 'the-supplier-filters-offcanvas'"
      v-model:amenities="amenities" v-model:location="location" v-model:name="name" v-model:status="status"
      v-model:type="type" @closed="closeOffCanvas" @selected="doSearch" />
    <the-supplier-details-offcanvas
      v-if="offCanvasToShow === 'the-supplier-details-offcanvas' || offCanvasToShow === 'the-supplier-details-rates-off-canvas'"
      :is-archived="status === 'archived'" :supplier-data="supplier" @closed="closeOffCanvas(); cleanCurrentSupplier()"
      @reload-suppliers="loadSuppliers" />
    <the-supplier-details-rates-off-canvas v-if="offCanvasToShow === 'the-supplier-details-rates-off-canvas'"
      :supplier-id="supplier.id" @closed="closeRatesOffCanvas" @reload-supplier="reloadSupplier" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import { VInputTextSearch } from '@uniqoders/form';
import qs from 'qs';
import querystring from 'query-string';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import api from '@/api';
import withTimeout from '@/helpers/timeOut';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import VListHeader from '@/components/shared/VListHeader.vue';
import VPagination from '@/components/vendor/basic/table/VPagination.vue';
import usePagination from '@/helpers/pagination';
import VFilters from '@/components/vendor/filters/VFilters.vue';
import TheSupplierFiltersOffcanvas from '@/components/supplier/TheSupplierFiltersOffcanvas.vue';
import TheSupplierDetailsOffcanvas from '@/components/supplier/TheSupplierDetailsOffcanvas.vue';
import TheSupplierDetailsRatesOffCanvas from '@/components/supplier/parts/TheSupplierDetailsRatesOffCanvas.vue';
import Supplier from '@/api/objects/Supplier';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import Datepicker from '@vuepic/vue-datepicker';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { useToast } from "vue-toastification";
import { useI18n } from 'vue-i18n';
import vSelect from 'vue-select';

export default defineComponent({
  name: 'TheSuppliers',
  components: {
    TheSupplierDetailsOffcanvas,
    TheSupplierFiltersOffcanvas,
    TheSupplierDetailsRatesOffCanvas,
    VFilters,
    VPagination,
    VListHeader,
    VLoader,
    VTableBody,
    VTableHeader,
    VTable,
    VButton,
    VInputTextSearch,
    VIcon,
    Datepicker,
    Carousel,
    Slide,
    vSelect,
    Navigation,
  },
  emits: ['reloadSupplier'],
  setup() {
    const startDate = ref<Date | null>(null);
    const endDate = ref<Date | null>(null);
    const dateRange: Ref<{ date: Date; count: number; }[]> = ref([]);
    const showBookings = ref(false);
    const selectedSupplierId = ref('');
    const selectedCardIndex = ref(0);
    const selectedDate = ref('');
    const disableExportButton = ref(false);
    const toast = useToast();
    const { t } = useI18n();
    const supplierIds = ref<number[]>([]);
    const bookingData: Record<string, {
      id: number;
      clientName: string;
      caseName: string;
      collaboratorName: string;
      roomType: string;
      product: string;
      quantity: string | number;
      pax: string | number;
      bookingCount: number;
    }[]> = {};

    // Function to update the date range
    const updateDateRange = () => {

      let start, end;
      if (startDate.value && endDate.value) {
        start = new Date(startDate.value);
        end = new Date(endDate.value);
      } else {
        start = new Date();
        end = new Date();
        end.setDate(end.getDate() + 30);
      }
      const range = [];
      const currentDate = new Date(start);

      while (currentDate <= end) {
        range.push({
          date: new Date(currentDate),
          count: bookingData[updateDateFormat(currentDate)]?.length !== undefined ? bookingData[updateDateFormat(currentDate)][0].bookingCount : 0,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      dateRange.value = range; // Update the dateRange reactive ref
      selectDate(start.toISOString(), 0);
      showBookings.value = false;
    };

    const exportBookingsToGoogleSheet = async (supplierId: string | number = '') => {
      disableExportButton.value = true; // Assuming this is a ref or reactive variable
      showBookings.value = false;
      const today = new Date();
      const next30Days = new Date();
      let supplierIdsArray: number[] = [];
      next30Days.setDate(next30Days.getDate() + 30);
      if (supplierId !== '') {
        supplierIdsArray = typeof supplierId === 'number' ? [supplierId] : [parseInt(supplierId)];
      } else {
        supplierIdsArray = supplierIds.value;
      }
      const params = {
        startDate: startDate.value ? updateDateFormat(startDate.value) : today,
        endDate: endDate.value ? updateDateFormat(endDate.value) : next30Days,
        supplierIds: supplierIdsArray
      };
      try {
        const response = await api.supplier.exportToGoogleSheet(params);

        // Check if the response contains the spreadsheetId
        if (response.spreadsheetId) {
          const redirectUrl = 'https://docs.google.com/spreadsheets/d/' + response.spreadsheetId;
          // Redirect to the external URL in a new tab
          window.open(redirectUrl, '_blank');
        } else {
          console.error("No spreadsheetId found in the response:", response);
        }
      } catch (error) {
        if (error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error(t("general.shared.exportErrorToast"))
        }
      } finally {
        disableExportButton.value = false; // Re-enable the export button regardless of success or failure
      }
    };

    const formatDate = (date:
      { getDay: () => string | number; getMonth: () => string | number; getDate: () => any; }) => {
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const dayOfWeek = daysOfWeek[date.getDay()];
      const month = monthsOfYear[date.getMonth()];
      const dayOfMonth = date.getDate();
      return `${dayOfWeek}, ${month} ${dayOfMonth}`;
    };
    const selectDate = (dateString: string, index: number) => {
      const date = new Date(dateString);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      selectedDate.value = formattedDate;
      selectedCardIndex.value = index;
    };
    const loadHotelBookingData = async (supplierId: string) => {
      try {
        const today = new Date();
        const next30Days = new Date();
        next30Days.setDate(next30Days.getDate() + 30);
        showBookings.value = false;

        // Clear bookingData before loading new data
        for (const key in bookingData) {
          if (bookingData.hasOwnProperty(key)) {
            delete bookingData[key];
          }
        }

        const params = {
          startDate: startDate.value ? updateDateFormat(startDate.value) : today,
          endDate: endDate.value ? updateDateFormat(endDate.value) : next30Days
        };
        const response = await api.supplier.getHotelBookingData(supplierId, params);

        for (const date in response) {
          const items = response[date].items;
          if (!bookingData[date]) {
            bookingData[date] = []; // Initialize the array if it doesn't exist
          }
          items.forEach((item) => {
            bookingData[date].push({
              id: item.id,
              clientName: item.client_name || 'N/A',
              caseName: item.case_name || 'N/A',
              collaboratorName: item.collaborator_name || 'N/A',
              roomType: item.room_type,
              product: item.service || `${item.origin || 'N/A'} - ${item.destination || 'N/A'}` || item.description,
              quantity: item.quantity || '-',
              pax: item.pax,
              bookingCount: response[date].booking_count
            });
          });
        }

        showBookings.value = true;
        updateDateRange();

      } catch (e) {
        console.error(e);
      }
    };

    const updateDateFormat = (dateString: string | Date) => {
      const date = new Date(dateString);
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };

    return {
      startDate,
      endDate,
      dateRange,
      showBookings,
      supplierIds,
      updateDateRange,
      exportBookingsToGoogleSheet,
      formatDate,
      selectedCardIndex,
      selectedDate,
      selectDate,
      selectedSupplierId,
      findConceptByKey,
      ...useOffCanvasUtils(),
      ...usePagination(),
      ...useConcepts(),
      bookingData,
      loadHotelBookingData,
      disableExportButton
    };
  },
  data() {
    return {
      carouselBreakpoints: {
        700: { itemsToShow: 5, snapAlign: 'start' },
        992: { itemsToShow: 5, snapAlign: 'start' },
        1200: { itemsToShow: 8, snapAlign: 'start' },
        1600: { itemsToShow: 8, snapAlign: 'start' }
      },
      suppliers: [] as Supplier[],
      supplier: null as null | Supplier,
      mix: '' as string,
      name: '' as string,
      type: '' as string,
      amenities: [] as string[],
      location: '' as string,
      status: 'active' as string,
      isLoading: false as boolean,
      suppliersList: [] as Supplier[],
      filters: [
        {
          variable: 'status',
          key: 'status',
          labelAux: 'general.shared.filterStatus',
          conceptName: 'app.states',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'mix',
          key: 'mix',
          labelAux: 'general.shared.filterSearch',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'name',
          key: 'name',
          labelAux: 'general.shared.filterName',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'type',
          key: 'type',
          labelAux: 'general.shared.filterType',
          conceptName: 'supplier.type',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'amenities',
          key: 'amenities',
          labelAux: 'general.shared.filterAmenity',
          conceptName: 'supplier.amenities',
          multiple: true,
          isConcept: true,
        },
        {
          variable: 'location',
          key: 'location',
          labelAux: 'general.shared.filterLocation',
          multiple: false,
          isConcept: false,
        },
      ] as Record<string, string | boolean>[],
      order: {
        key: '' as string,
        order: '' as string,
      } as any,
      perPage: 15 as number,
      page: 1 as number,
      timers: {
        name: null as any,
      },
      loaded: false as boolean,
      filterOptions: [] as Record<string, any>[],
    };
  },
  async created() {
    await this.setQueryParametersAsFilters();
    await this.doSearch();
    await this.getAllSuppliers();
  },

  watch: {
    supplierIds: {
      handler() {
        this.scrollToEnd();
      },
      deep: true
    }
  },

  methods: {

    async getAllSuppliers() {
      const response = await api.supplier.getAllSuppliers();
      this.suppliersList = response;
    },
    getCarouselSettings() {
      const itemsToShow = 10;
      return {
        itemsToShow,
        snapAlign: 'center'
      };
    },

    truncateText(text) {
      const limit = 13;
      if (text.length > limit) {
        return text.substring(0, limit) + '...';
      }
      return text;
    },

    scrollToEnd() {
      this.$nextTick(() => {
        const selectEl = this.$refs.supplierSelect.$el
        const selectedItems = selectEl.querySelectorAll('.vs__selected')
        if (selectedItems.length > 0) {
          selectedItems[selectedItems.length - 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      })
    },

    search() {
      clearTimeout(this.timers.name);
      this.timers.name = withTimeout(async () => {
        await this.doSearch();
      }, this.timers.name, 1000);
    },
    /**
     * Detects any query parameter in the url and loads it in the filters (if available)
     *
     * @returns {Promise<void>}
     */
    async setQueryParametersAsFilters() {
      const { query }: any = this.$route;

      const {
        filters,
        order,
        perPage,
        status,
        page,
      }: any = qs.parse(query);

      if (!!filters) {
        this.filters.forEach((filter: Record<string, any>) => {
          if (!!filters[filter.key]) {
            this[filter.variable] = filters[filter.key];
          }
        });
      }

      if (!!order) {
        this.order = order;
      }

      const pageNumber = parseInt(page, 10);

      if (!Number.isNaN(pageNumber)) {
        this.page = pageNumber;
      }

      const perPageNumber = parseInt(perPage, 10);

      if (!Number.isNaN(perPageNumber)) {
        this.perPage = perPageNumber;
      }

      if (!!status) {
        this.status = status;
      }
    },
    /**
     * Sets the local filters as url query parameters.
     */
    async setFiltersAsQueryParameters() {
      const filters: any = {};

      this.filters.forEach((filter: Record<string, any>) => {
        if (!!this[filter.variable]) {
          filters[filter.key] = this[filter.variable];
        }
      });

      const queryObject: any = {
        filters,
        perPage: this.perPage,
        status: this.status,
        page: this.page,
      };

      if (!!this.order && !!this.order.key) {
        queryObject.order = this.order;
      }

      await this.setFilters();

      let query: any = qs.stringify(queryObject, { encode: false });
      query = querystring.parse(query);

      this.$router.push({
        name: 'supplier.index',
        query,
      });
    },
    async doSearch() {
      await this.setFiltersAsQueryParameters();
      await this.loadSuppliers();
    },

    async loadSuppliers(page?: number) {
      try {
        this.loaded = false;

        const filters: any = {};

        this.filters.forEach((filter: Record<string, any>) => {
          if (!!this[filter.variable]) {
            filters[filter.key] = this[filter.variable];
          }
        });

        const query: any = {
          filters,
          perPage: this.perPage,
          status: this.status,
          page: !!page ? page : this.page,
        };

        if (!!this.order && !!this.order.key) {
          query.order = this.order;
        }

        const response = await api.supplier.allPaginated(query);
        this.setResponse(response);
        this.suppliers = response.data;
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.loaded = true;
      }
    },
    async showSupplierDetails(supplier: Supplier) {
      this.showBookings = false;
      this.supplier = supplier;
      if (this.offCanvasToShow !== 'the-supplier-details-offcanvas') {
        this.openOffCanvas('the-supplier-details-offcanvas');
      }
    },

    async showSupplierBookings(supplier: Supplier, event?: Event) {
      // Blur the button element
      if (event?.target) {
        (event.target as HTMLElement).blur();
      }
      
      this.isLoading = true;
      if (this.selectedSupplierId === String(supplier.id) && this.showBookings) {
        this.showBookings = !this.showBookings;
        this.isLoading = false;
        return
      }
      this.selectedSupplierId = String(supplier.id);
      await this.loadHotelBookingData(this.selectedSupplierId)
      this.showBookings = true;
      this.isLoading = false;
    },
    cleanCurrentSupplier() {
      this.supplier = null;
    },
    async handlePageChangedEvent(page: number) {
      await this.loadSuppliers(page);
    },
    toggleOrder(field: string) {
      const { order } = this.order;

      if (!order) {
        this.order.order = 'ASC';
      } else {
        this.order.order = order === 'ASC' ? 'DESC' : 'ASC';
      }

      this.order.key = field;

      this.search();
    },
    setFilters() {
      this.filterOptions = this.filters.flatMap((filter: Record<string, any>) => {
        const {
          key,
          multiple,
          isConcept,
          labelAux,
          conceptName,
          variable,
        } = filter;

        const options = multiple ? this[variable] : [this[variable]];

        return options.map((option: any) => {
          const value = isConcept ? findConceptByKey(`${conceptName}`, option) : option;
          const label = this.$t(`${labelAux}`, { value: !!conceptName ? value.name : value });

          return { key, value: !!conceptName ? value.key : value, label };
        });
      });
    },
    removeFilter(filter: Record<string, string>) {
      this.filters.forEach((filterAux: Record<string, string | boolean>) => {
        if (filterAux.key === filter.key) {
          if (!filterAux.multiple) {
            this[filterAux.variable] = '';
          } else {
            this[filterAux.variable] = this[filterAux.variable].filter((option: string) => option !== filter.value);
          }
        }
      });

      if (filter.key === 'status') {
        this.status = 'active';
      }

      this.doSearch();
    },
    getAllAmenities(amenities: string): string {
      const amenitiesParsed: string[] = JSON.parse(amenities);

      return amenitiesParsed
        .map((amenity: string) => {
          const typeObject = this.findConceptByKey('supplier.amenities', amenity);

          return typeObject?.name;
        })
        .filter(Boolean) // Filter out undefined values
        .join(', ');
    },
    reloadSupplier() {
      this.$emit('reloadSupplier');
    },
    closeRatesOffCanvas() {
      this.offCanvasToShow = 'the-supplier-details-offcanvas';
    },
    async handleButtonClick(supplier: Supplier) {
      this.$nextTick(async () => {
        await this.showSupplierDetails(supplier);
        await this.openOffCanvas('the-supplier-details-rates-off-canvas');
      });
    }
  },
});
</script>
<style lang="scss" scoped>
.common-icon-padding {
  padding-right: 5px !important;
  min-width: 120px !important;
}

.datepickers-container {
  align-items: end;
  margin-left: auto;
  display: flex;
}

.loading-cursor {
  cursor: progress;
}

.datepickers-container .form-outline {
  margin-right: 20px;
}


.card {
  border-left: 1px solid #ccc;
  padding: 7px;
  display: inline-block;
  width: 150px;
  margin: 0 auto;
}

.others-type-tooltip-description {
  cursor: pointer;
  align-items: center;
  position: relative;
}

.others-type-tooltip-description[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.others-type-tooltip-description[data-title]:after {
  content: attr(data-title);
  background-color: #EE2A7B;
  color: #FCFCFC;
  font-size: 13px;
  position: absolute;
  padding: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #EE2A7B;
  opacity: 0;
  border: 1px solid #EE2A7B;
  z-index: 99999;
  visibility: hidden;
  border-radius: 5px;
  top: -6px;
  right: 34px;
  height: 30px;
  width: fit-content;
  line-height: 20px;
  font-family: 'Poppins', sans-serif;
}

.others-type-tooltip-description[data-title] {
  position: relative;
}

.tooltip-right-arrow {
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  width: 10px;
  height: 10px;
  background: #EE2A7B;
  position: absolute;
  right: 26px;
  top: 5px;
  visibility: hidden;
  opacity: 0;
}

.others-type-tooltip-description[data-title]:hover .tooltip-right-arrow {
  visibility: visible;
  opacity: 1;
  transition: all 0.1s ease 0.5s;
}

.carousel-container {
  margin: 0;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  padding-left: 55px;
  padding-right: 50px;
  margin-bottom: 1px solid #ccc;
}

.carousel__slide {
  margin: 0px;
  flex-shrink: 1;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
}

.table-borderless {
  border: none;
  padding: 0px;
}


.table-header {
  display: inherit;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  margin-bottom: -15px;
}

.table-header th {
  flex: 1;
}

.table-header .fit {
  flex: 0 1 auto;
}

.bg-tertiary {
  background-color: #65AE90 !important;
}

.cell-content {
  display: flex;
  align-items: center;
}

.bookingTable {
  padding-top: 0px;
  width: 100%;
}

.bookingTable thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.bookingTable th {
  padding: 12px 3px;
  white-space: nowrap;
  font-weight: 300;
  text-align: left;
  background-color: white;
  position: relative;
}

.bookingTable thead::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ccc;
  z-index: 2;
}

.bookingTable th::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ccc;
  z-index: 2;
}

.table tbody {
  border-top-width: 0 !important;
}

.left-align {
  text-align: left;
  padding: 6px;
}

.center-align {
  text-align: center;
  padding-top: 10px;
}

th,
td {
  width: 12%;
  padding: 8px 15px;
}

td {
  @media screen and (max-width: 1400px) {
    max-width: 240px;
  }
}

.full-width-container {
  width: 100%;
}

.show-bookings-table-carousal {
  max-width: 1394px;
}

.table-responsive {
  overflow: hidden !important;
}

.suppliersTable {
  overflow: hidden;
  border-spacing: 0rem;
}

.suppliersTableSection {
  border: 1px solid;
}

.highlighted {
  border-bottom: 4px solid#c93e79;
  color: #c93e79;
  font-weight: bold !important;
}

.card:hover {
  cursor: pointer;
}

.countBooking {
  color: green;
}

.r-btn {
  width: 30px;
  height: 30px;
}

.table-wrapper {
  position: relative;
  max-height: 235px;
  overflow-y: auto;
  padding-top: 0px;
  padding-left: 80px;
  right: 30px;
}

.table-wrapper::-webkit-scrollbar {
  width: 6px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.bookingsDetails {
  width: auto;
}

.bookings-header {
  width: auto;
}

.bookings-header-adjust {
  width: 100px;
  padding-left: 30px;
}

.bookings-header-end {
  width: 80px;
}

.bookings-header-end-value {
  padding-left: 12px;
}

.bookingDetails th:last-child {
  padding-left: 80px;
}

.bookingTable tr td:last-child {
  padding-left: 90px;
}

.supplier-list-select-initial .list-button-holder {
  gap: 105px;
}

.supplier-name-truncated:hover {
  cursor: pointer;
}

.name-of-supplier {
  width: 51.5%;
}

@media screen and (max-width: 1400px) {
  .name-of-supplier {
    width: 48.5%;
  }
}

.btn-view:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>

<style>
.datepicker-for-supplier .dp__pointer {
  height: 50px;
}

.supplier-list-select .vs__dropdown-toggle {
  overflow-y: auto;
}

.supplier-list-select .vs__selected {
  max-height: 2.065rem !important;
}

.supplier-list-select .vs__dropdown-toggle::-webkit-scrollbar {
  width: 2px;
}

.supplier-list-select .vs__dropdown-toggle::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.supplier-list-select .vs__dropdown-toggle::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.supplier-list-select .vs__open-indicator {
  display: none;
}

.supplier-list-select-initial .vs__dropdown-toggle {
  max-height: 50px;
  width: 340px;
}

.supplier-list-select-initial .vs__open-indicator {
  display: none;
}
</style>
