import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface CaseNotificationsInterface extends IBaseObject {
    type: string;
    id: number;
    attributes: {
        tour_id: number;
        client_tour_id: number;
        sender_agency_id: number;
        receiver_agency_id: number;
        app_notification_message: string;
        notification_date_time: string;
        is_read: boolean;
        created_at: string;
        updated_at: string;
    };
}

export default class caseNotifications extends BaseObject implements CaseNotificationsInterface {
  public type: string;
  public id: number;
  public attributes: {
        tour_id: number;
        client_tour_id: number;
        sender_agency_id: number;
        receiver_agency_id: number;
        app_notification_message: string;
        notification_date_time: string;
        is_read: boolean;
        created_at: string;
        updated_at: string;
  };

  constructor(data: caseNotifications) {
    super(data);
  }
}
