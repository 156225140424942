export default {
  greeting: "Hola",
  hope_you_are_fine: "¡Espero que estés bien!",
  email_subject: "Asunto:",
  client: "Cliente:",
  case: "Caso:",
  room: "Habitación:",
  quantity: "Cantidad:",
  check_in_date: "Fecha entrada (Check-in):",
  check_out_date: "Fecha salida (Check-out):",
  nights: "Noches:",
  number_of_people: "Nº personas:",
  observations: "Observaciones:",
  understanding: "Gracias por su colaboración.",
  request: "Queremos *Solicitar* esta reserva en su alojamiento:",
  mofication: "Queremos *Modificar* esta reserva en su alojamiento:",
  reminder: "Queremos *Cancelar* esta reserva en su alojamiento:",
  invoice_request: "Queremos *Solicitar* una facturación para esta reserva en su alojamiento:",
  payment_information: "Queremos *Solicitar* información de pago para esta reserva en su alojamiento:",
  email_warning: "Advertencia",
  price_warning: "El valor del precio no se puede establecer en 0 o negativo.",
  free_text_warning: "El texto libre no puede estar vacío.",
};
