import admin from '@/lang/en/admin';
import auth from '@/lang/en/auth';
import client from '@/lang/en/client';
import clientTour from '@/lang/en/clientTour';
import contact from '@/lang/en/contact';
import dashboard from '@/lang/en/dashboard';
import general from '@/lang/en/general';
import resource from '@/lang/en/resource';
import supplier from '@/lang/en/supplier';
import tourBuilder from '@/lang/en/tourBuilder';
import tourClient from '@/lang/en/tourClient';
import tourPricing from '@/lang/en/tourPricing';
import tourBooking from '@/lang/en/tourBooking';
import template from '@/lang/en/template';
import traveler from '@/lang/en/traveler';
import travelBook from '@/lang/en/travelBook';
import user from '@/lang/en/user';
import uploader from '@/lang/en/uploader';
import emailTemplate from '@/lang/en/emailTemplate';

const en = {
  admin,
  auth,
  client,
  clientTour,
  contact,
  dashboard,
  general,
  resource,
  supplier,
  template,
  tourBuilder,
  tourClient,
  tourPricing,
  tourBooking,
  traveler,
  travelBook,
  user,
  uploader,
  emailTemplate
};

export default en;
