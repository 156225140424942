<template>
  <div class="admin-header">
    <div class="container-xl px-0">
      <div class="navbar navbar-expand-lg py-0">
        <div class="container-fluid gap-16 flex-nowrap">
          <v-select
            id="header-user-agency-select"
            v-model="agencySelected"
            :clearable="false"
            :options="agencyOptions"
            class="mb-0 text-sm agency-selector"
            @option:selected="selectAgency"
          >
            <template v-slot:no-options>
              {{ $t('general.vueSelect.noOptions') }}
            </template>

            <template v-slot:option="option">
              <span>{{ option.name }}</span>
            </template>

            <template v-slot:selected-option="option">
              <span>{{ option.name }}</span>
            </template>
          </v-select>

          <div class="d-flex align-items-center justify-content-between">
            <the-admin-menu/>

            <the-header-notification-dropdown v-if="!agency?.isCollaborator" />
            <the-header-user-dropdown/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import { mapState } from 'pinia';
import TheHeaderUserDropdown from '@/components/header/TheHeaderUserDropdown.vue';
import TheHeaderNotificationDropdown from '@/components/header/TheHeaderNotificationDropDown.vue';
import { useAuthStore } from '@/stores/auth';
import useModalUtils from '@/helpers/ModalUtils';
import TheAdminMenu from '@/components/header/TheAdminMenu.vue';

export default defineComponent({
  name: 'TheAdminHeader',
  components: {
    TheAdminMenu,
    TheHeaderUserDropdown,
    TheHeaderNotificationDropdown,
    vSelect,
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
      ...useModalUtils(),
    };
  },
  data() {
    return {
      agencySelected: null as null | any,
      disableButton: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    ...mapState(useAuthStore, ['agency']),
    agencyOptions(): any[] {
      if (!!this.user && !!this.user.agencies && this.user.agencies.length > 0) {
        return this.user.agencies.map((agency: any) => ({
          ...agency,
          label: agency.name,
          slug: agency.slug,
        }));
      }

      return [];
    },
  },
  watch: {
    agency() {
      this.agencySelected = this.agency;
    },
  },
  mounted() {
    if (!!this.user) {
      const header: any = document.getElementById('top');
      const main: any = document.getElementById('main');

      if (!!header && !!main) {
        const height = header.offsetHeight;

        main.style.paddingTop = `${height}px`;
      }
    }

    this.agencySelected = this.agency;
  },
  methods: {
    async selectAgency() {
      this.disableButton = true;

      if (this.agencySelected.slug !== localStorage.getItem('agency')) {
        localStorage.setItem('agency', this.agencySelected.slug);
        this.authStore.setAgency(this.agencySelected);
        
        if (this.agencySelected.isCollaborator) {
          localStorage.setItem('isCollaborator', this.agencySelected.isCollaborator);
          this.$router.push({ name: "collaboratorDashboard" });
          return;
        }
      }
      window.location.replace(import.meta.env.VITE_APP_URL);

      this.disableButton = false;
    },
  },
});
</script>

<style lang="scss">
.admin-header {
  .agency-selector {
    width: 100%;
    padding-block: .25rem;
    margin-right: 0;

    &.vs--open {
      .vs__search {
        padding-inline: .5rem !important;
      }
    }

    .vs__search {
      padding-inline: 0 !important;
    }

    .vs__dropdown-menu {
      width: auto;
    }

    .vs__dropdown-option--highlight {
      width: fit-content;
    }

    @media (min-width: 576px) {
      width: auto;
      min-width: 18rem;

      .vs__dropdown-menu {
        width: 100%;
      }

      .vs__dropdown-option--highlight {
        width: 100%;
      }
    }

    .vs__search {
      height: 1.75rem;
      min-height: 0 !important;
    }

    .vs__dropdown-toggle {
      height: 1.75rem;

      .vs__actions {
        padding: 0 .5rem 0 0 !important;
      }
    }

    .vs__selected {
      padding: 0 !important;
      min-height: 0 !important;
      height: 1.75rem !important;
      padding-block: 0 !important;
      padding-inline: .5rem !important;

      span {
        overflow: hidden;
        width: 110px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        @media (min-width: 576px) {
          width: 236px;
        }
      }

      .vs__search {
        height: 28px !important;
      }
    }
  }
}
</style>
