/**
 * Redirects to our website with the provided url.
 * If 'entities/1', it redirects to 'domain:8000/entities/1'.
 *
 * @param url
 * @param params
 */

export const getHummanSize = (size: any): string => {
  // eslint-disable-next-line radix
  size = parseInt(size);
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (size === 0) {
    return `0 ${units[1]}`;
  }

  let i = 0;

  // eslint-disable-next-line no-plusplus
  for (i; size > 1024; i++) {
    size /= 1024;
  }

  return `${size.toFixed(2)} ${units[i]}`;
};

export const camelToSnakeCase = (str: string): string => {
  if (/[A-Z]/.test(str)) {
    str = str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).substr(1, 999);
  } else {
    str = str.toLowerCase();
  }

  return str;
};
