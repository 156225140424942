<template>
  <div id="page-price">
    <div class="page-inner container">
      <div
        class="tour-price-container d-flex flex-column flex-sm-row justify-content-between align-items-sm-center
      text-xxl fw-semi"
      >
        <div v-if="priceType !== 'ppp'">{{ $t('travelBook.totalPrice') }}</div>
        <div v-else>{{ $t('travelBook.pricePerPerson') }}</div>

        <div class="total-price">
          {{ tourTotalPrice }} €
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'TheTravelBookPrice',
  props: {
    tourTotalPrice: {
      type: String,
      required: true,
    },
    priceType: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}
</style>
