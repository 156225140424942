import { AbstractService } from '@uniqoders/sdk';
import Tour from '@/api/objects/Tour';
import Resource from '@/api/objects/Resource';

export default class TravelBookService extends AbstractService {
  /**
   * Retrieves Tour Simple data
   * @protected
   * @param clientTour
   * @param tour
   * @param params
   */
  public simple(clientTour: number, tour: number, params: Record<string, any>): Promise<Tour> {
    return this.request({
      method: 'get',
      url: this.buildPath('/case/{clientTour}/tour/{tour}/simple', {
        clientTour,
        tour,
      }),
      params,
    });
  }

  /**
   * Retrieves Tour Locations
   * @protected
   * @param clientTour
   * @param tour
   * @param params
   */
  public locations(clientTour: number, tour: number, params: Record<string, any>): Promise<Location[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/case/{clientTour}/tour/{tour}/locations', {
        clientTour,
        tour,
      }),
      params,
    });
  }

  /**
   * Retrieves Tour Data for Preview
   * @protected
   * @param clientTour
   * @param tour
   * @param params
   */
  public dataForPreview(clientTour: number, tour: number, params: Record<string, any>): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/case/{clientTour}/tour/{tour}/preview', {
        clientTour,
        tour,
      }),
      params,
    });
  }

  /**
   * Retrieves Tour Data for quote
   * @protected
   * @param clientTour
   * @param tour
   * @param params
   */
  public dataForQuote(
    clientTour: number,
    tour: number,
    params: Record<string, any>): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/case/{clientTour}/tour/{tour}/quote', {
        clientTour,
        tour,
      }),
      params,
    });
  }

  /**
   * Retrieves optional Tour Data
   * @protected
   * @param clientTour
   * @param tour
   * @param params
   */
  public optional(
    clientTour: number,
    tour: number,
    params: Record<string, any>): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/case/{clientTour}/tour/{tour}/quote/optionals', {
        clientTour,
        tour,
      }),
      params,
    });
  }

  /**
   * Retrieves Tour Resources
   * @protected
   * @param params
   */
  public resources(params?: any): Promise<Resource[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/book/resources'),
      params,
    });
  }
}
