<template>
  <div class="form-outline">
    <label class="label">
      <span v-if="!!label">{{ label }}</span>
      <span v-else>{{ $t('general.shared.caseManager') }}</span>
    </label>

    <v-select
      v-if="status === 'loaded'"
      v-model="localValue"
      :options="users"
      :reduce="returnValue => returnValue.id"
      class="select-search"
      label="name"
      @update:model-value="$emit('update:modelValue', localValue)"
    >
      <template v-slot:no-options>
        <div class="my-4">{{ $t('general.shared.writeSearchUsers') }}</div>
      </template>

      <template v-slot:option="option">
        <div class="d-center">
          <span>{{ option.name }}</span>

          <span class="text-sm fw-light ms-4">({{ option.email }})</span>
        </div>
      </template>

      <template v-slot:selected-option="option">
        <div class="selected d-center py-8">
          <span>{{ option.name }}</span>

          <span class="text-sm fw-light ms-4">({{ option.email }})</span>
        </div>
      </template>

      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <v-icon icon="search" size="sm"/>
        </span>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useModalUtils from '@/helpers/ModalUtils';
import AgencyUser from '@/api/objects/AgencyUser';

export default defineComponent({
  name: 'VSelectUserCaseSearch',
  components: {
    VIcon,
    vSelect,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    userId: {
      type: Number,
      required: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    agency: {
      type: String,
      required: true,
    },
    
  },
  setup() {
    return {
      ...useModalUtils(),
    };
  },
  data() {
    return {
      localValue: this.modelValue,
      users: [] as Record<string, string | number>[],
      status: 'loading' as string,
    };
  },
  async created() {
    this.status = 'loading';

    await this.loadUsers();

    this.status = 'loaded';
  },
  methods: {
    async loadUsers() {
      try {
        const agencyUsers: AgencyUser[] = await api.agencyUser.all(this.agency);

        this.users = agencyUsers.map((agencyUser: AgencyUser) => ({
          id: agencyUser.id,
          userId: agencyUser.user.id,
          name: agencyUser.user.name,
          email: agencyUser.user.email,
        }));

        if (!!this.userId && !!this.users && this.users.length > 0) {
          const userFound = this.users.find((user: any) => user.id === this.userId);

          if (!!userFound) {
            this.localValue = userFound.id;
          }
        }
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
