import { AbstractService } from '@uniqoders/sdk';
import Notification from '@/api/objects/Notification';
import caseNotifications from '../objects/CaseNotifications';

export default class NotificationService extends AbstractService {
  /**
   * Retrieves all Tours Notifications
   * @param agency
   * @param clientTour
   * @param tour
   * @param type
   */
  public all(agency: string, clientTour: number, tour: number, type = 'client'): Promise<Notification[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/notifications/{type}/log', {
        agency,
        clientTour,
        tour,
        type
      }),
    });
  }

  /**
   * Send Supplier Notification
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   * @protected
   */
  public supplier(
    agency: string,
    clientTour: number,
    tour: number,
    data: Record<string, any>): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/notification/supplier', {
        agency,
        clientTour,
        tour
      }),
      data,
    });
  }

  /**
   * Send Client Notification
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   * @protected
   */
  public client(
    agency: string,
    clientTour: number,
    tour: number,
    data: Record<string, any>): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/notification/client', { agency, clientTour, tour }),
      data,
    });
  }

    /**
   * Retrieves all case Notifications
   * @param agency
   */
    public getCaseNotifications(agency: string): Promise<caseNotifications[]> {
      return this.request({
        method: 'get',
        url: this.buildPath('/{agency}/app-notifications', {
          agency
        }),
      });
    }
     /**
   * update case Notifications
   * @param agency
   */
     public updateCaseNotifications(agency: string, id: number, isRead: boolean): Promise<any[]> {
      return this.request({
        method: 'patch',
        url: this.buildPath('/{agency}/app-notification/{id}?is_read={isRead}', {
          agency,
          id,
          isRead
        }),
      });
    }
}
