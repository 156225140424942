<template>
  <v-off-canvas
    id="pricing-invoice-create-off-canvas"
    :backdrop-static="true"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <h5 class="offcanvas-title">{{ $t('tourPricing.invoice.createNew') }}</h5>
      </off-canvas-header>

      <off-canvas-body>
      <form
        id="tour-invoice-create-form"
        class="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <fieldset>
            <legend class="visually-hidden">{{ $t('tourPricing.invoice.amount') }}</legend>
            <div class="row gx-12">
              <div class="col-12">
                <div class="form-outline">
                  <label class="label">
                    <span>{{ $t('tourPricing.invoice.amount') }}*</span>
                  </label>

                  <v-form-input
                    id="tour-invoice-create-amount"
                    v-model="amount"
                    :yup-errors-variable="errors.amount"
                    autocomplete="off"
                    form-type="input-group"
                    onwheel="return false;"
                    right-addon="€"
                    step=".01"
                    type="number"
                  />
                </div>
              </div>

              <v-form-input
                id="tour-invoice-create-id-identity"
                v-model="idIdentity"
                :label="$t('tourPricing.invoice.idIdentity')"
                :yup-errors-variable="errors.idIdentity"
                autocomplete="off"
                class="col-12"
                form-type="outline"
              />

              <v-form-input
                id="tour-invoice-create-vat"
                v-model="vat"
                :label="$t('tourPricing.invoice.vat')"
                :yup-errors-variable="errors.vat"
                autocomplete="off"
                class="col-12"
                form-type="outline"
              />
            </div>
          </fieldset>

          <div class="search-separator-container pb-32">
            <div class="separator-text-container">
              <span class="separator-text text-sm">{{ $t('tourPricing.invoice.address') }}</span>
            </div>
          </div>

          <fieldset>
            <legend class="visually-hidden">{{ $t('general.address.address') }}</legend>

            <div class="row gx-12">
              <v-input-address
                id="tour-invoice-create-address-search"
                ref="tour-invoice-create-address-search-ref"
                :form-floating="false"
                :get-address="true"
                :label="$t('general.address.streetAddress')"
                :value="address"
                class="col-12"
                @address="setAddress"
              />

              <v-form-input
                id="tour-invoice-create-second-address"
                v-model="addressTwo"
                :label="$t('general.address.aptSuit')"
                :yup-errors-variable="errors.addressTwo"
                autocomplete="off"
                class="col-12"
                form-type="outline"
              />

              <v-form-input
                id="tour-invoice-create-country"
                v-model="countryLabel"
                :label="$t('general.address.country')"
                :yup-errors-variable="errors.countryLabel"
                autocomplete="off"
                class="col-12"
                form-type="outline"
              />

              <v-form-input
                id="tour-invoice-create-postal-code"
                v-model="postalCode"
                :label="$t('general.address.postalCode')"
                :yup-errors-variable="errors.postalCode"
                autocomplete="off"
                class="col-12"
                form-type="outline"
              />

              <v-form-input
                id="tour-invoice-create-city"
                v-model="city"
                :label="$t('general.address.city')"
                :yup-errors-variable="errors.city"
                autocomplete="off"
                class="col-12"
                form-type="outline"
              />

              <v-form-input
                id="tour-invoice-create-region"
                v-model="region"
                :label="$t('general.address.provinceStateRegion')"
                :yup-errors-variable="errors.region"
                autocomplete="off"
                class="col-12"
                form-type="outline"
              />
            </div>
          </fieldset>

          <div class="search-separator-container mb-32 pb-32">
            <div class="separator-text-container">
              <span class="separator-text text-sm">{{ $t('tourPricing.invoice.resources') }}</span>
            </div>
          </div>

          <fieldset>
            <legend class="visually-hidden">{{ $t('tourPricing.invoice.resource') }}</legend>

            <div v-for="type in items.types" :key="`supplier-type-${type}`" class="resource-type text-sm">
              <div class="fw-medium">
                {{ findConceptByKey('supplier.type', type.name).name }}
              </div>

              <div
                v-for="supplier in type.suppliers"
                :key="`supplier-${supplier.id}`"
                class="resource-supplier ms-16"
              >
                <div class="fw-medium">{{ supplier.name }}</div>

                <ul class="list-group-with-dot list-group resources">
                  <li
                    v-for="itemResource in supplier.itemResources"
                    :key="`resource-${itemResource.id}`"
                    class="list-group-item"
                  >
                    <div>
                      {{ itemResource.name }}
                      <span class="text-sm fw-normal">(x{{ itemResource.resources.length }})</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </fieldset>
        </div>

        <v-button
          :disabled="!meta.valid || accepted"
          :is-loading="accepted"
          class="btn-submit-form btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="createInvoice"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </form>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useConcepts from '@/helpers/Concepts';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import { IItemsTypes } from "@/views/case/parts/TheTourPricing.vue";
import api from "@/api";
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'ThePricingInvoiceCreateOffCanvas',
  components: {
    VInputAddress,
    VIcon,
    VButton,
    VFormInput,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
  },
  emits: ['closed', 'reloadResources'],
  props: {
    agency: {
      type: String,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    items: {
      type: Object as PropType<IItemsTypes>,
      required: true,
    },
    totalInvoice: {
      type: Number,
      required: true,
    },
    
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      currency: yup.string()
        .nullable()
        .label(t('tourPricing.invoice.currency')),
      amount: yup.number()
        .required()
        .nullable()
        .min(0)
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('tourPricing.invoice.number')),
      invoiceStatus: yup.string()
        .nullable()
        .label(t('general.shared.invoiceStatus')),
      paymentStatus: yup.string()
        .nullable()
        .label(t('general.shared.paymentStatus')),
      address: yup.string()
        .nullable()
        .label(t('general.address.streetAddress')),
      addressTwo: yup.string()
        .nullable()
        .label(t('general.address.aptSuit')),
      city: yup.string()
        .nullable()
        .label(t('general.address.city')),
      countryLabel: yup.string()
        .nullable()
        .label(t('general.address.country')),
      region: yup.string()
        .nullable()
        .label(t('general.address.provinceStateRegion')),
      postalCode: yup.string()
        .nullable()
        .label(t('general.address.postalCode')),
      idIdentity: yup.string()
        .nullable()
        .label(t('tourPricing.invoice.idIdentity')),
      vat: yup.string()
        .nullable()
        .label(t('tourPricing.invoice.vat')),
    });

    const initialValues = {
      amount: props.totalInvoice,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: currency } = useField('currency');
    const { value: amount } = useField('amount');
    const { value: invoiceStatus } = useField('invoiceStatus');
    const { value: paymentStatus } = useField('paymentStatus');

    const { value: address } = useField('address');
    const { value: addressTwo } = useField('addressTwo');
    const { value: city } = useField('city');
    const { value: countryLabel } = useField('countryLabel');
    const { value: region } = useField('region');
    const { value: postalCode } = useField('postalCode');
    const { value: idIdentity } = useField('idIdentity');
    const { value: vat } = useField('vat');

    return {
      ...useConcepts(),
      ...form,
      findConceptByKey,
      currency,
      amount,
      invoiceStatus,
      paymentStatus,
      address,
      addressTwo,
      city,
      countryLabel,
      region,
      postalCode,
      idIdentity,
      vat,
    };
  },
  data() {
    return {
      resourcesArray: [] as number[],
    };
  },
  methods: {
    async setAddress(address: any) {
      this.address = address.name;
      this.countryLabel = address.country.longName;
      this.city = address.locality.longName;
      this.region = address.regionProvince.longName;
      this.postalCode = address.postalCode.longName;
    },
    async createInvoice() {
      try {
        this.toggleAccepted();

        const data = {
          amount: this.amount,
          currency: 'EUR',
          line1: this.address,
          line2: this.addressTwo,
          postal_code: this.postalCode,
          country: this.countryLabel,
          city: this.city,
          state: this.region,
          id_identity: this.idIdentity,
          vat: this.vat,
          type: 'emitted',
          resources: this.items.resourceIds,
        };

        await api.invoice.create(this.agency, this.caseId, this.tourId, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('closed');

        this.toggleAccepted();
      } catch (e: any) {
        this.toggleAccepted();

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.input-group) {
  .form-control {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

.resource-type {
  border: 1px solid var(--uq-gray-400);
  border-radius: .25rem;
  padding: .5rem;
  margin-bottom: 1rem;

  .resource-supplier {
    margin-left: 1rem;
    position: relative;

    &:not(:last-child) {
      margin-bottom: .5rem;
      padding-bottom: .5rem;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--uq-gray-300);
    }
  }
}
</style>
