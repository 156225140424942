import { AbstractService } from "@uniqoders/sdk";
import TourDayItemResource from "@/api/objects/TourDayItemResource";

export default class TourDayItemResourceService extends AbstractService {
  /**
   * Retrieves a specific Tour Day Item Resource
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @param tourDayItemResource
   * @protected
   */
  public retrieve(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: number,
    tourDayItem: number,
    tourDayItemResource: number
  ): Promise<TourDayItemResource> {
    return this.request({
      method: "get",
      url: this.buildPath(
        "/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}/resource/{tourDayItemResource}",
        {
          agency,
          clientTour,
          tour,
          tourDay,
          tourDayItem,
          tourDayItemResource,
        }
      ),
    });
  }

  /**
   * Creates a single Tour Day Item Resource
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @param data
   */
  public create(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: string | number,
    tourDayItem: string | number,
    data: Record<string, any>
  ): Promise<TourDayItemResource> {
    return this.request({
      method: "post",
      url: this.buildPath("/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}/resource", {
        agency,
        clientTour,
        tour,
        tourDay,
        tourDayItem,
      }),
      data,
    });
  }

  /**
   * Creates a single Tour Day Item Resource
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @param data
   */
  public createbulk(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: string | number,
    tourDayItem: string | number,
    data: Record<string, any>
  ): Promise<TourDayItemResource> {
    return this.request({
      method: "patch",
      url: this.buildPath(
        "/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}/resource/bulk-store",
        {
          agency,
          clientTour,
          tour,
          tourDay,
          tourDayItem,
        }
      ),
      data,
    });
  }

  /**
   * Update the specified Tour Day Item Resource
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @param tourDayItemResource
   * @param data
   * @protected
   */
  public update(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: string | number,
    tourDayItem: string | number,
    tourDayItemResource: string | number,
    data: Record<string, any>
  ): Promise<TourDayItemResource> {
    return this.request({
      method: "patch",
      url: this.buildPath(
        "/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}/resource/{tourDayItemResource}",
        {
          agency,
          clientTour,
          tour,
          tourDay,
          tourDayItem,
          tourDayItemResource,
        }
      ),
      data,
    });
  }

  /**
   * Update the specified Tour Day Item Resource
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @param tourDayItemResource
   * @param data
   * @protected
   */
  public bulkupdate(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: string | number,
    tourDayItem: string | number,
    data: Record<string, any>
  ): Promise<TourDayItemResource> {
    return this.request({
      method: "patch",
      url: this.buildPath(
        "/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}/resource/bulk-update",
        {
          agency,
          clientTour,
          tour,
          tourDay,
          tourDayItem,
        }
      ),
      data,
    });
  }

  /**
   * Delete the specified Tour Day Item Resource
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @param tourDayItemResource
   */
  public delete(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: string | number,
    tourDayItem: string | number,
    tourDayItemResource: string | number
  ): Promise<TourDayItemResource> {
    return this.request({
      method: "delete",
      url: this.buildPath(
        "/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}/resource/{tourDayItemResource}",
        {
          agency,
          clientTour,
          tour,
          tourDay,
          tourDayItem,
          tourDayItemResource,
        }
      ),
    });
  }

    /**
   * Update the specified Tour Day Item Resource
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @param tourDayItemResource
   * @param data
   * @protected
   */
    public updateStatus(
      agency: string,
      clientTour: number,
      tour: number,
      tourDay: string | number,
      tourDayItem: string | number,
      tourDayItemResource: string | number,
      data: Record<string, any>): Promise<TourDayItemResource> {
      return this.request({
        method: 'post',
        url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}/resource/{tourDayItemResource}/status', {
          agency,
          clientTour,
          tour,
          tourDay,
          tourDayItem,
          tourDayItemResource,
        }),
        data,
      });
    }

  /**
   * Update the specified Tour Day Item Resource
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @param tourDayItemResource
   * @param data
   * @protected
   */
  public updateStatusBulk(
    agency: string,
    clientTour: number,
    tour: number,
    data: Record<string, any>): Promise<TourDayItemResource> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/resource/status/update', {
        agency,
        clientTour,
        tour,
      }),
      data,
    });
  }
}
