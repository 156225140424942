import {
  App,
  Plugin,
  shallowRef,
} from 'vue';
import AlertDivLayout from '@/components/vendor/alert/AlertDivLayout.vue';
import {
  GeneralModalType,
  useGeneralModalsStore,
} from '@/stores/genenalModals';
import i18n from '../lang';

class Modal {
  protected $t;

  protected generalModalsStore;

  public constructor() {
    this.$t = i18n.global.t;
    this.generalModalsStore = useGeneralModalsStore();
  }

  /**
   * Shows a modal with the 'info' format.
   *
   * @param title
   * @param text
   * @param acceptButtonText
   * @param acceptButtonCallback
   * @param backdropStatic
   * @param rightButtonClasses
   * @param isDoubleModal
   * @returns {Promise<void>}
   */
  async info(
    {
      title = this.$t('general.button.confirm'),
      text = this.$t('general.button.confirm'),
      acceptButtonText = this.$t('general.button.confirm'),
      acceptButtonCallback = null,
      backdropStatic = false,
      rightButtonClasses = 'btn-primary',
      isDoubleModal = true,
    }) {
    const generalModalsStore = useGeneralModalsStore();

    await generalModalsStore.showModal({
      title,
      text,
      textLayout: null,
      showCloseX: false,
      backdropStatic,
      leftButtonText: null,
      leftButtonClasses: null,
      leftButtonLoadingText: null,
      leftButtonCallback: null,
      rightButtonText: acceptButtonText,
      rightButtonClasses,
      rightButtonLoadingText: acceptButtonText,
      rightButtonCallback: acceptButtonCallback,
      isDoubleModal,
    });
  }

  /**
   * Shows a modal with the 'delete' format. If your callback,
   * when failing, returns a { error: 'error' }, the modal
   * toasts the error and is not closed.
   *
   * @param title
   * @param text
   * @param subtitle
   * @param deleteButtonText
   * @param deleteButtonLoadingText
   * @param deleteButtonCallback
   * @param backdropStatic
   * @param isDoubleModal
   * @param leftButtonClasses
   * @param rightButtonClasses
   * @returns {Promise<void>}
   */
  async delete(
    {
      title = this.$t('general.button.delete'),
      text = this.$t('general.button.delete'),
      subtitle = '',
      deleteButtonText = this.$t('general.button.delete'),
      deleteButtonLoadingText = this.$t('general.button.deleting'),
      deleteButtonCallback = null as any,
      backdropStatic = false,
      isDoubleModal = true,
      leftButtonClasses = 'btn-outline-danger',
      rightButtonClasses = 'btn-primary',
    }) {
    const generalModalsStore = useGeneralModalsStore();

    await generalModalsStore.showModal({
      title,
      text,
      subtitle,
      textLayout: shallowRef(AlertDivLayout),
      showCloseX: false,
      backdropStatic,
      leftButtonText: deleteButtonText,
      leftButtonClasses,
      leftButtonLoadingText: deleteButtonLoadingText,
      leftButtonCallback: deleteButtonCallback,
      rightButtonText: this.$t('general.button.cancel'),
      rightButtonClasses,
      rightButtonLoadingText: this.$t('general.button.cancel'),
      rightButtonCallback: null,
      isDoubleModal,
    });
  }

  /**
   * Shows a modal with the 'confirm' format. If your callback,
   * when failing, returns a { error: 'error' }, the modal
   * toasts the error and is not closed.
   *
   * @param title
   * @param text
   * @param confirmButtonText
   * @param confirmButtonLoadingText
   * @param confirmButtonCallback
   * @param backdropStatic
   * @returns {Promise<void>}
   */
  async confirm(
    {
      title = this.$t('general.button.confirm'),
      text = this.$t('general.button.confirm'),
      confirmButtonText = this.$t('general.button.confirm'),
      confirmButtonLoadingText = this.$t('general.button.processing'),
      confirmButtonCallback = null,
      showCloseX = false,
      backdrop = false,
      switchButtons = false,
      leftButtonText = this.$t('general.button.cancel'),
      leftButtonClasses = 'btn-tertiary',
      rightButtonClasses = 'btn-primary',
      isDoubleModal = true,
    }: Partial<GeneralModalType & {
      confirmButtonText: string
      confirmButtonLoadingText: string
      confirmButtonCallback: any
      showCloseX: boolean
      switchButtons: boolean,
      leftButtonText: string
      leftButtonClasses: string
      rightButtonClasses: string
      isDoubleModal: boolean,
    }>) {
    const generalModalsStore = useGeneralModalsStore();

    await generalModalsStore.showModal({
      title,
      text,
      textLayout: null,
      showCloseX,
      backdrop,
      leftButtonText,
      switchButtons,
      leftButtonClasses,
      leftButtonLoadingText: this.$t('general.button.cancel'),
      leftButtonCallback: null,
      rightButtonText: confirmButtonText,
      rightButtonClasses,
      rightButtonLoadingText: confirmButtonLoadingText,
      rightButtonCallback: confirmButtonCallback,
      isDoubleModal,
    });
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $modal: Modal;
  }
}

const createModal: Plugin = {
  install(app: App) {
    app.config.globalProperties.$modal = new Modal();
  },
};

export default createModal;
