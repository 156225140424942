<template>
  <v-off-canvas
    id="agency-details-offcanvas"
    :backdrop-static="false"
    offcanvas-class="item-details offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <div class="d-flex align-items-center">
        <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

        <h5 id="agency-details-offcanvas-label" class="offcanvas-title">
          {{ $t('admin.agency.agencyDetails') }}
        </h5>
      </div>
      </off-canvas-header>

      <off-canvas-body class="justify-content-between d-flex flex-column">
      <v-loader v-show="status === 'loading'"/>

      <div v-show="status !== 'loading'" class="item-details">
        <the-admin-agency-details-basic-information
          v-if="status !== 'loading'"
          :agency="agency"
          @reload-agency="loadAgency"
          @emit-reload-agencies="$emit('reloadAgencies')"
        />

        <the-admin-agency-details-users
          :agency="agency"
          @reload-agency="loadAgency"
        />

        <the-admin-agency-details-contacts
          :agency="agency"
          @reload-agency="loadAgency"
        />

        <the-admin-agency-details-templates
          :agency="agency"
          @reload-agency="loadAgency"
        />

        <div v-if="status !== 'loading'" class="detail mb-0 pb-0">
          <div class="detail-title fw-medium mb-20">
            {{ $t('general.shared.archive') }}
          </div>

          <div class="detail-content">
            <p v-if="!agency.deletedAt" class="text-sm">{{ $t('admin.agency.archiveText') }}</p>
            <p v-else class="text-sm">{{ $t('admin.agency.archivedText') }}</p>

            <v-button
              v-if="!agency.deletedAt"
              id="btn-archive-agency"
              :disabled="disableArchiveBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="archiveAgency"
            >
              <v-icon icon="import" space="me-12"/>
              <template v-if="disableArchiveBtn">{{ $t('general.button.archiving') }}</template>
              <template v-else>{{ $t('general.button.archive') }}</template>
            </v-button>

            <v-button
              v-else
              id="btn-restore-agency"
              :disabled="disableRestoreBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="primary"
              @click="restoreAgency"
            >
              <v-icon icon="unarchive" space="me-12"/>
              <template v-if="disableRestoreBtn">{{ $t('general.button.restoring') }}</template>
              <template v-else>{{ $t('general.button.restore') }}</template>
            </v-button>
          </div>
        </div>

        <div v-if="!!agency.deletedAt && status !== 'loading'" class="detail">
          <div class="detail-title fw-medium mb-20">
            {{ $t('general.shared.delete') }}
          </div>

          <div class="detail-content">
            <p class="text-sm">{{ $t('admin.agency.deleteText') }}</p>

            <v-button
              id="btn-delete-agency"
              :disabled="disableDeleteBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="deleteAgency"
            >
              <v-icon icon="trash" space="me-12"/>
              <template v-if="disableDeleteBtn">{{ $t('general.button.deleting') }}</template>
              <template v-else>{{ $t('general.button.delete') }}</template>
            </v-button>
          </div>
        </div>
      </div>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import TheAdminAgencyDetailsBasicInformation
  from '@/components/admin/agency/parts/TheAdminAgencyDetailsBasicInformation.vue';
import TheAdminAgencyDetailsUsers from '@/components/admin/agency/parts/TheAdminAgencyDetailsUsers.vue';
import TheAdminAgencyDetailsContacts from '@/components/admin/agency/parts/TheAdminAgencyDetailsContacts.vue';
import Agency from '@/api/objects/Agency';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import TheAdminAgencyDetailsTemplates from '@/components/admin/agency/parts/TheAdminAgencyDetailsTemplates.vue';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheAdminAgencyDetailsOffcanvas',
  components: {
    TheAdminAgencyDetailsTemplates,
    VLoader,
    TheAdminAgencyDetailsContacts,
    TheAdminAgencyDetailsUsers,
    TheAdminAgencyDetailsBasicInformation,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
  },
  emits: ['closed', 'reloadAgencies'],
  props: {
    agencyData: {
      type: Agency,
      required: true,
    },
    isArchived: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      agency: {} as Agency,
      status: 'loading' as string,
      disableArchiveBtn: false as boolean,
      disableDeleteBtn: false as boolean,
      disableRestoreBtn: false as boolean,
    };
  },
  watch: {
    async agencyData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.isArchived) {
          await this.loadAgency();
        } else {
          this.agency = this.agencyData;

          this.status = 'loaded';
        }
      }
    },
  },
  async created() {
    if (!this.isArchived) {
      await this.loadAgency();
    } else {
      this.agency = this.agencyData;

      this.status = 'loaded';
    }
  },
  methods: {
    async loadAgency() {
      this.status = 'loading';

      this.agency = await api.admin.agency.retrieve(this.agencyData.id);

      this.status = 'loaded';
    },
    async archiveAgency() {
      await this.$modal.delete({
        title: this.$t('admin.agency.archiveAgencyTitle'),
        text: this.$t('admin.agency.archiveAgencyText', { agency: this.agency?.name }),
        deleteButtonText: this.$t('general.button.archive'),
        deleteButtonLoadingText: this.$t('general.button.archiving'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doArchiveAgency,
      });
    },
    async doArchiveAgency() {
      try {
        this.disableArchiveBtn = true;

        await api.admin.agency.archive(this.agency.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadAgencies');
        this.$emit('closed');

        this.disableArchiveBtn = false;
      } catch (e: any) {
        this.disableArchiveBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async restoreAgency() {
      await this.$modal.confirm({
        title: this.$t('admin.agency.restoreAgencyTitle'),
        text: this.$t('admin.agency.restoreAgencyText', { agency: this.agency?.name }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doRestoreAgency,
      });
    },
    async doRestoreAgency() {
      try {
        this.disableRestoreBtn = true;

        await api.admin.agency.restore(this.agency.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadAgencies');
        this.$emit('closed');

        this.disableRestoreBtn = false;
      } catch (e: any) {
        this.disableRestoreBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async deleteAgency() {
      await this.$modal.delete({
        title: this.$t('admin.agency.deleteAgencyTitle'),
        text: this.$t('admin.agency.deleteAgencyText', { agency: this.agency?.name }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doDeleteAgency,
      });
    },
    async doDeleteAgency() {
      try {
        this.disableDeleteBtn = true;

        await api.admin.agency.delete(this.agency.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadAgencies');
        this.$emit('closed');

        this.disableDeleteBtn = false;
      } catch (e: any) {
        this.disableDeleteBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
