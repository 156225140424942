export default {
  user: {
    users: 'Gebruikers',
    noUsers: 'Er zijn geen gebruikers',
    createNewUser: 'Nieuwe gebruiker aanmaken',
    userDetails: 'Gebruikersgegevens',
    basicInformation: 'Basisinformatie',
    password: 'Wachtwoord',
    passwordChangeText: 'Het wachtwoord kan niet worden gewijzigd, maar u kunt een e-mail sturen met een nieuw wachtwoord naar de gebruiker.',
    sendEmail: 'E-mail versturen',
    archivedUsers: 'Gearchiveerde gebruikers',
    activeUsers: 'Actieve gebruikers',
    agencies: 'Instanties',
    userEditAgenciesText: 'Bewerk de instanties waartoe de gebruiker behoort.',
    noAgencies: 'Er zijn geen gerelateerde instanties',
    addAgencies: 'Instanties toevoegen',
    removeAgencyUserTitle: 'Gebruiker uit instantie verwijderen',
    removeAgencyUserText: 'Weet u zeker dat u \'{user} ({email})\' wilt verwijderen uit de instantie \'{agency}\'?',
    addExistingAgency: 'Bestaande instantie toevoegen',
    addAgencyUserTitle: 'Gebruiker toevoegen aan instantie',
    addAgencyUserText: 'Weet u zeker dat u \'{user}\' wilt toevoegen aan de instantie \'{agency}\'?',
    archiveUserTitle: 'Gebruiker archiveren',
    archiveUserText: 'Weet u zeker dat u de gebruiker \'{user}\' wilt archiveren? Als u dit doet, zal deze niet meer verschijnen in zoekopdrachten.',
    deleteUserTitle: 'Gebruiker verwijderen',
    deleteUserText: 'Weet u zeker dat u de gebruiker \'{user}\' wilt verwijderen? Als u dit doet, kunt u deze niet meer herstellen.',
    denyUserTitle: 'Toegang gebruiker weigeren',
    denyUserText: 'Weet u zeker dat u de toegang van de gebruiker \'{user}\' wilt weigeren? Als u dit doet, zal deze gebruiker geen toegang meer hebben tot het platform. Als het op enig moment nodig is om de gebruiker weer toegang te geven, schakelt u gewoon de toegang van de gebruiker weer in en kan de gebruiker het platform weer gebruiken.',
    forgiveUserTitle: 'Toegang verlenen',
    forgiveUserText: 'Weet u zeker dat u de toegang van de gebruiker \'{user}\' wilt verlenen? Als u dit doet, krijgt de gebruiker weer toegang tot het platform.',
    loginAs: 'Inloggen als',
    impersonateDescription: 'Je kunt inloggen als deze gebruiker en de applicatie gebruiken als deze gebruiker. Je kunt op elk moment terugkeren naar je eigen profiel.',
    impersonateText: 'Je wordt ingelogd als de gebruiker \'{user}\'. Je ziet en kunt alles doen wat de gebruiker doet en je kunt op elk moment terugkeren naar je administrator profiel. Wil je doorgaan?',
    impersonateErrorTitle: 'Fout bij het inloggen',
    impersonateErrorText: 'Fout bij het inloggen als een andere gebruiker',
    access: 'Toegang',
    userCanAccessText: 'De gebruiker heeft toegang. Als je de toegang wilt weigeren, klik dan op de knop "Toegang weigeren" en de gebruiker kan niet langer toegang krijgen tot de applicatie. Na het weigeren van de toegang kun je de toegang op elk moment opnieuw verlenen.',
    userCannotAccessText: 'De gebruiker heeft geen toegang. Als je toegang wilt verlenen, klik dan op de knop "Toegang verlenen" en de gebruiker kan toegang krijgen tot de applicatie.',
    denyAccess: 'Toegang weigeren',
    denyingAccess: 'Toegang wordt geweigerd...',
    provideAccess: 'Toegang verlenen',
    providingAccess: 'Toegang wordt verleend...',
    archiveText: 'Je kunt een gebruiker archiveren, als je dit doet zal de gebruiker niet verschijnen in de applicatie-zoekopdrachten. Je kunt het bekijken in de lijst van gebruikers als je filtert op \'gearchiveerd\'. Zodra je de details van een gearchiveerde gebruiker bekijkt, kun je deze definitief verwijderen of je kunt deze ongedaan maken.',
    archivedText: 'Deze gebruiker is gearchiveerd, als je wilt kun je deze ongedaan maken door op de knop \'Herstellen\' te klikken.',
    deleteText: 'Deze gebruiker is gearchiveerd, als je deze permanent wilt verwijderen klik dan op de \'Verwijderen\' knop. Let op dat als je deze verwijdert, deze gebruiker niet langer bestaat en niet kan worden hersteld.',
    restoreUserTitle: 'Gebruiker herstellen',
    restoreUserText: 'Weet je zeker dat je de gebruiker \'{user}\' wilt herstellen? Als je deze herstelt, is deze niet langer gearchiveerd en zal deze weer verschijnen in de applicatie-zoekopdrachten.',
    userStatus: 'Gebruikersstatus',
    agency: 'Agentschap',
  },
  agency: {
    agencies: 'Agentschappen',
    noAgencies: 'Er zijn geen agentschappen',
    createNewAgency: 'Nieuw agentschap aanmaken',
    agencyDetails: 'Agentuurgegevens',
    basicInformation: 'Basisinformatie',
    archivedAgencies: 'Gearchiveerde agentschappen',
    activeAgencies: 'Actieve agentschappen',
    users: 'Gebruikers',
    editUsersBelongAgencyText: 'Bewerk gebruikers die bij het agentschap horen',
    noUsers: 'Er zijn geen bijbehorende gebruikers',
    usersEditBelongAgencyText: 'Bewerk gebruikers die bij het agentschap horen',
    addUsers: 'Gebruikers toevoegen',
    removeAgencyUserTitle: 'Gebruiker uit het agentschap verwijderen',
    removeAgencyUserText: 'Weet je zeker dat je \'{user} ({email})\' wilt verwijderen uit het \'{agency}\' agentschap?',
    addExistingUser: 'Bestaande gebruiker toevoegen',
    addAgencyUserTitle: 'Gebruiker aan het agentschap toevoegen',
    addAgencyUserText: 'Weet je zeker dat je {user} wilt toevoegen aan het {agency} agentschap?',
    archiveAgencyTitle: 'Agentschap archiveren',
    archiveAgencyText: 'Weet je zeker dat je het agentschap \'{agency}\' wilt archiveren? Als je het archiveert, zal het niet meer verschijnen in de zoekresultaten.',
    deleteAgencyTitle: 'Agentschap verwijderen',
    deleteAgencyText: 'Weet u zeker dat u het agentschap \'{agency}\' wilt verwijderen? Als u dit doet, kunt u het niet meer herstellen.',
    archiveText: 'U kunt een agentschap archiveren. Als u dit doet, zal het agentschap niet meer verschijnen in de applicatie zoekopdrachten. U kunt het nog steeds zien in de lijst met gearchiveerde agentschappen als u filtert op \'gearchiveerd\'. Nadat u de details van een gearchiveerd agentschap bekijkt, kunt u het definitief verwijderen of het archief opheffen.',
    archivedText: 'Dit agentschap is gearchiveerd. Als u wilt, kunt u het archief opheffen door op de knop \'Herstellen\' te klikken.',
    deleteText: 'Dit agentschap is gearchiveerd. Als u het permanent wilt verwijderen, klikt u op de knop \'Verwijderen\'. Houd er rekening mee dat als u het verwijdert, het agentschap niet langer bestaat en niet kan worden hersteld.',
    restoreAgencyTitle: 'Agentschap herstellen',
    restoreAgencyText: 'Weet u zeker dat u het agentschap \'{agency}\' wilt herstellen? Als u het herstelt, zal het niet langer gearchiveerd zijn en verschijnt het weer in de applicatie zoekopdrachten.',
    agencyStatus: 'Agentschap status',
    contacts: 'Contacten',
    noContacts: 'Er zijn geen bijbehorende contacten',
    editContactsBelongAgencyText: 'Bewerk contacten die bij het agentschap horen',
    addContacts: 'Contacten toevoegen',
    removeContactTitle: 'Verwijder contact van agentschap',
    removeContactText: 'Weet u zeker dat u {contact} als contactpersoon voor het agentschap {agency} wilt verwijderen?',
    template: {
      title: 'Activiteiten',
      subtitle: 'Activiteiten in verband met dit agentschap',
      noTemplates: 'Dit agentschap heeft geen bijbehorende activiteiten',
      addExistingTemplate: 'Bestaande activiteit toevoegen',
      editTemplatesBelongsTo: 'Bewerkt de activiteiten waartoe dit agentschap behoort',
      addTemplate: 'Activiteit toevoegen',
      addTemplates: 'Activiteiten toevoegen',
      addTemplateToAgencyTitle: 'Activiteit toevoegen aan agentschap',
      addTemplateToAgencyTitleText: 'Weet u zeker dat u de activiteit \'{ sjabloon }\' wilt koppelen aan het agentschap \'{ agentschap }\'?',
      detachTemplateFromAgencyTitle: 'Ontkoppel activiteit van agentschap',
      detachTemplateFromAgencyTitleText: 'Weet u zeker dat u de activiteit \'{ sjabloon }\' wilt loskoppelen van het agentschap \'{ agentschap }\'?',
    },
    user: 'Gebruiker',
  },
  collaborator: {
    collaborators: 'Medewerkers',
    noCollaborators: 'Geen medewerkers',
    createNewCollaborator: 'Nieuwe medewerker aanmaken',
    collaboratorDetails: 'Bedrijfsgegevens van de medewerker',
    basicInformation: 'Basisinformatie',
    archivedCollaborators: 'Gearchiveerde medewerkers',
    activeCollaborators: 'Actieve medewerkers',
    users: 'samenwerkende gebruikers',
    editUsersBelongCollaboratorText: 'Gebruikers bewerken die tot de medewerker behoren',
    noUsers: 'Geen gebruikers gekoppeld',
    usersEditBelongCollaboratorText: 'Gebruikers bewerken die tot de medewerker behoren',
    addUsers: 'Gebruikers toevoegen',
    removeCollaboratorUserTitle: 'Gebruiker van medewerker verwijderen',
    removeCollaboratorUserText: 'Weet u zeker dat u de gebruiker \'{user} ({email})\' wilt verwijderen van de medewerker \'{agency}\'?',
    addExistingUser: 'Bestaande gebruiker toevoegen',
    addCollaboratorUserTitle: 'Gebruiker toevoegen aan medewerker',
    addCollaboratorUserText: 'Weet u zeker dat u {user} wilt toevoegen aan de medewerker {collaborator}?',
    archiveCollaboratorTitle: 'Medewerker archiveren',
    archiveCollaboratorText: 'Weet u zeker dat u de medewerker \'{agency}\' wilt archiveren? Als u dit doet, wordt deze niet meer weergegeven in de zoekresultaten.',
    deleteCollaboratorTitle: 'Medewerker verwijderen',
    deleteCollaboratorText: 'Weet u zeker dat u de medewerker \'{agency}\' wilt verwijderen? Als u deze verwijdert, kunt u deze niet meer herstellen.',
    archiveText: 'U kunt een medewerker archiveren. Als u dit doet, wordt de medewerker niet meer weergegeven in de zoekresultaten van de app. U kunt deze bekijken in de lijst met medewerkers als u filtert op \'gearchiveerd\'. Nadat u de details van een gearchiveerde medewerker hebt bekeken, kunt u deze definitief verwijderen of herstellen.',
    archivedText: 'Deze medewerker is gearchiveerd. Als u wilt, kunt u deze herstellen door op de knop \'Herstellen\' te klikken.',
    deleteText: 'Deze medewerker is gearchiveerd. Als u deze definitief wilt verwijderen, klikt u op de knop \'Verwijderen\'. Houd er rekening mee dat als u deze verwijdert, deze medewerker niet meer bestaat en niet kan worden hersteld.',
    restoreCollaboratorTitle: 'Medewerker herstellen',
    restoreCollaboratorText: 'Weet u zeker dat u de medewerker \'{agency}\' wilt herstellen? Als u deze herstelt, wordt deze niet meer gearchiveerd en wordt deze weergegeven in de zoekresultaten van de app.',
    collaboratorStatus: 'Medewerkerstatus',
    contacts: 'Neem contact op met mensen',
    noContacts: 'Geen contacten gekoppeld',
    editContactsBelongCollaboratorText: 'Contactpersonen bewerken die tot de medewerker behoren',
    addContacts: 'Contacten toevoegen',
    removeContactTitle: 'Contactpersoon van medewerker verwijderen',
    removeContactText: 'Weet u zeker dat u {contact} wilt verwijderen als contactpersoon van de medewerker {collaborator}?',
    template: {
      title: 'Activiteiten', // No change
      subtitle: 'Activiteiten gekoppeld aan deze medewerker',
      noTemplates: 'Deze medewerker heeft geen gekoppelde activiteiten',
      addExistingTemplate: 'Bestaande activiteit toevoegen',
      editTemplatesBelongsTo: 'Bewerk de activiteiten waartoe deze medewerker behoort',
      addTemplate: 'Activiteit toevoegen',
      addTemplates: 'Activiteiten toevoegen',
      addTemplateToCollaboratorTitle: 'Activiteit toevoegen aan medewerker',
      addTemplateToCollaboratorTitleText: 'Weet u zeker dat u de activiteit \'{ template }\' wilt koppelen aan de medewerker \'{ agency }\'?',
      detachTemplateFromCollaboratorTitle: 'Activiteit van medewerker ontkoppelen',
      detachTemplateFromCollaboratorTitleText: 'Weet u zeker dat u de activiteit \'{ template }\' wilt ontkoppelen van de medewerker \'{ agency }\'?',
    },
    user: 'Gebruiker',
  }
};
