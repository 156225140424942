export default {
  shared: {
    password: 'Wachtwoord',
    email: 'E-mail',
  },
  login: {
    logInGoogle: 'Inloggen met Google',
    logInApple: 'Inloggen met Apple',
    logInWithEmail: 'of inloggen met uw e-mail',
    logIn: 'Inloggen',
    forgot: 'Ik ben mijn wachtwoord vergeten',
    redirect: 'Doorverwijzen...',
    loginData: 'Inloggegevens',
    userNotAllowed: 'Er is geen gebruiker met dat e-mailadres in de applicatie',
  },
  forgot: {
    recover: 'Wachtwoord opnieuw instellen',
    backLogin: 'Terug naar inloggen',
    receiveEmail: 'U ontvangt een e-mail op dit adres om uw wachtwoord opnieuw in te stellen.',
    receiveEmailSpam: 'Als u het niet ontvangt, kan het in uw spam-map staan.',
    emailSent: 'We hebben u zojuist de e-mail opnieuw gestuurd, controleer alstublieft uw spam-map.',
    resend: 'Opnieuw verzenden',
    email: 'Voer uw e-mailadres in',
  },
  reset: {
    addNewPassword: 'Voeg je nieuwe wachtwoord toe',
    newPassword: 'Nieuw wachtwoord',
    confirmNewPassword: 'Bevestig nieuw wachtwoord',
    congratulations: 'Gefeliciteerd!',
    successfulRecover: 'Je wachtwoord is succesvol gereset. Je kunt nu opnieuw inloggen en je nieuwe inloggegevens invoeren.',
    passwords: 'Wachtwoorden',
  },
};
