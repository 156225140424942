export default {
  traveler: 'Viajero',
  travelerData: 'Datos del viajero',
  withoutOrigin: 'Sin origen',
  withoutDestination: 'Sin destino',
  withoutLocation: 'Sin ubicación',
  noStartDate: 'Sin fecha de inicio',
  noEndDate: 'Sin fecha de finalización',
  basicInformation: 'Información básica',
  contactInformation: 'Información de contacto',
  addressInformation: 'Dirección',
  moreInformation: 'Más información',
  haveAllergies: '¿Tienes alguna alergia?',
  haveAllergiesText: '¿Podría especificarlas?',
  allergies: 'Alergias',
  yes: 'Sí',
  no: 'No',
  spokenLanguage: 'Idioma',
  followingDiet: '¿Sigues alguna dieta específica?',
  vegetarian: 'Vegetariano',
  vegan: 'Vegano',
  other: 'Otro',
  diet: 'Dieta',
  bikeText: '¿Vas a hacer tu Camino en bici?',
  bikeYesText: 'Como vas a hacer el Camino en bici, indícanos tu altura, peso y talla de cabeza.',
  bike: 'Bici',
  height: 'Altura',
  weight: 'Peso',
  bikeType: 'Tipo de bici',
  arrival: 'Llegada',
  arrivalType: 'Tipo de llegada',
  arrivalNumber: 'Número de vuelo/tren/barco/autobús',
  arrivalDate: 'Fecha de llegada',
  arrivalHour: 'Hora de llegada',
  arrivalLocation: 'Estación de llegada',
  departure: 'Salida',
  departureType: 'Tipo de salida',
  departureNumber: 'Número de vuelo/tren/barco/autobús',
  departureDate: 'Fecha de salida',
  departureHour: 'Hora de salida',
  departureLocation: 'Estación de salida',
};
