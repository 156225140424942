import general from '@/lang/es/general';
import auth from '@/lang/es/auth';
import dashboard from '@/lang/es/dashboard';
import admin from '@/lang/es/admin';
import client from '@/lang/es/client';
import clientTour from '@/lang/es/clientTour';
import contact from '@/lang/es/contact';
import resource from '@/lang/es/resource';
import supplier from '@/lang/es/supplier';
import template from '@/lang/es/template';
import tourBuilder from '@/lang/es/tourBuilder';
import tourClient from '@/lang/es/tourClient';
import tourPricing from '@/lang/es/tourPricing';
import tourBooking from '@/lang/es/tourBooking';
import traveler from '@/lang/es/traveler';
import travelBook from '@/lang/es/travelBook';
import user from '@/lang/es/user';
import uploader from '@/lang/es/uploader';
import emailTemplate from '@/lang/es/emailTemplate';

const es = {
  admin,
  auth,
  client,
  clientTour,
  contact,
  dashboard,
  general,
  resource,
  supplier,
  template,
  tourBuilder,
  tourClient,
  tourPricing,
  tourBooking,
  traveler,
  travelBook,
  user,
  uploader,
  emailTemplate,
};

export default es;
