import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import Client from '@/api/objects/Client';
import ClientTour from '@/api/objects/ClientTour';
import TourDay from '@/api/objects/TourDay';
import Pax from '@/api/objects/Pax';
import Media from '@/api/objects/Media';

export interface TourContract extends IBaseObject {
  clientId: number;
  clientTourId: number;
  contactText: string;
  destinationId: number;
  endDate: string;
  featuredImage: Media;
  grossPrice: string;
  initDate: string;
  invoicesStatus: string;
  markup: string;
  markupType: string;
  name: string;
  netPrice: string;
  originId: number;
  paymentsStatus: string;
  reservationStatus: string;
  status: string;
  token: string;
  travelType: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  client: Client;
  clientTour: ClientTour;
  tourDays: TourDay[];
  paxs: Pax[];
  origin: Location;
  destination: Location;
  invoicesReceived: Media[];
  quotes: Media[];
}

export default class Tour extends BaseObject implements TourContract {
  public declare clientId: number;
  public declare clientTourId: number;
  public declare contactText: string;
  public declare destinationId: number;
  public declare endDate: string;
  public declare featuredImage: Media;
  public declare grossPrice: string;
  public declare initDate: string;
  public declare invoicesStatus: string;
  public declare markup: string;
  public declare markupType: string;
  public declare name: string;
  public declare netPrice: string;
  public declare originId: number;
  public declare paymentsStatus: string;
  public declare reservationStatus: string;
  public declare status: string;
  public declare token: string;
  public declare travelType: string;
  public declare type: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare client: Client;
  public declare clientTour: ClientTour;
  public declare tourDays: TourDay[];
  public declare paxs: Pax[];
  public declare origin: Location;
  public declare destination: Location;
  public declare invoicesReceived: Media[];
  public declare quotes: Media[];

  constructor(data: TourContract) {
    super(data);
  }
}
