export default {
  tourBuilder: "Tour Builder",
  stage: "Stage",
  item: "Item",
  itemDetails: "Item details",
  paxErrorMessage: "Pax is a required field",
  deleteTourDayTitle: "Delete day",
  deleteTourDayText:
    "Are you sure you want to delete the day {dayNumber} ({date})? If you delete it, all the progress you have made on this day will be lost.",
  deleteTourDayItemTitle: "Delete day item",
  deleteTourDayItemText:
    "Are you sure you want to delete this '{dayItem}'? If you delete it, all the progress you have made will be lost.",
  dayUpdated: "Day successfully updated",
  dayUpdateError: "An error occurred while updating the day",
  dayCreated: "Day successfully created",
  dayCreateError: "An error occurred while creating the day",
  dayDeleted: "Day successfully deleted",
  dayDeleteError: "An error occurred while deleting the day",
  dayItemCreated: "Item successfully created",
  dayITemCreateError: "An error occurred while creating the item",
  dayItemDeleted: "Item successfully deleted",
  dayItemDeleteError: "An error occurred while deleting the item",
  resources: "Resources",
  reservation: "Reservation",
  payment: "Payment",
  invoice: "Invoice",
  exportAsProduct: "Export as activity",
  searchTourDayItemResource: "Search resources",
  tourDayItemResourceDates: "Resources dates",
  supplierNoOptions: "There are no suppliers",
  writeSearchSuppliers: "Write to search for suppliers",
  writeSearchResources: "Write to search for resources",
  price: "Prices",
  payments: "Payments",
  deleteTourDayItemResourceTitle: "Delete product",
  deleteTourDayItemResourceText:
    "Are you sure you want to delete all '{resource}' products on {date}? If you delete them all the data you have added will be lost.",
  booking: "Booking",
  bookingStatus: "Booking status",
  lengthErrorMessage: "Write a distance in Kms or write zero",
  KilometersShorted: "Km",
  exportTourAsTemplateTitle: "Export tour as an activity",
  exportTourAsTemplateText: "Are you sure you want to create an activity based on this case?",
  editStageInformation: "Edit stage information",
  stageInformation: "Stage information",
  removeMediaTitle: "Delete media",
  removeMediaText: "Are you sure you want to delete the archive? If you delete it, you will not be able to recover it.",
  advancedOptions: "Advanced options",
  addDayTitle: "Add day to the Tour",
  addDayText:
    "Are you sure you want to add a new day to this Tour? Please note that when adding a new day all the hotels, transfers, etc. that have been added and are affected by this new day will change date, whether confirmed or not.",
  hotelRoomTypeUnavailable: "Hotel room type unavailable",
  roomTypeMissingWarning: "Remove this and add appropriate room type",
  transferNotSelected: "Transfer not selected",
  transportNotSelected: "Transport not selected",
};
