import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import Contact from '@/api/objects/Contact';
import SupplierResource from '@/api/objects/SupplierResource';
import Media from '@/api/objects/Media';
import TourDayItemResource from '@/api/objects/TourDayItemResource';
import SupplierTranslation from '@/api/objects/SupplierTranslation';

export interface SupplierContract extends IBaseObject {
  account: string;
  amenities: string;
  contactAddress1: string;
  contactAddress2: string;
  contactCity: string;
  contactCountry: string;
  contactPostalCode: string;
  contactState: string;
  description: string;
  distance: string;
  email: string;
  lat: string;
  lng: string;
  locationId: number;
  locationName: string;
  mapUrl: string;
  name: string;
  notes: string;
  paymentMethod: string;
  phone: string;
  preferredLanguage: string;
  priceRange: number;
  siteUrl: string;
  type: string;
  typeEmail: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  contacts: Contact[] | null;
  location: Location;
  media: Media[];
  resources: SupplierResource[];
  tourDayItemResources: TourDayItemResource[];
  resource: Record<string, any>[];
  translations: SupplierTranslation[];
  invoiceChecked: boolean;
  itemResourcesGrouped: any;
}

export default class Supplier extends BaseObject implements SupplierContract {
  public declare account: string;
  public declare amenities: string;
  public declare contactAddress1: string;
  public declare contactAddress2: string;
  public declare contactCity: string;
  public declare contactCountry: string;
  public declare contactPostalCode: string;
  public declare contactState: string;
  public declare description: string;
  public declare distance: string;
  public declare email: string;
  public declare lat: string;
  public declare lng: string;
  public declare locationId: number;
  public declare locationName: string;
  public declare mapUrl: string;
  public declare name: string;
  public declare notes: string;
  public declare paymentMethod: string;
  public declare phone: string;
  public declare preferredLanguage: string;
  public declare priceRange: number;
  public declare siteUrl: string;
  public declare type: string;
  public declare typeEmail: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare contacts: Contact[] | null;
  public declare location: Location;
  public declare media: Media[];
  public declare resources: SupplierResource[];
  public declare tourDayItemResources: TourDayItemResource[];
  public declare resource: Record<string, any>[];
  public declare translations: SupplierTranslation[];
  public declare invoiceChecked: boolean;
  public declare itemResourcesGrouped: any;

  constructor(data: SupplierContract) {
    super(data);
  }
}
