import { AbstractService } from '@uniqoders/sdk';
import Template from '@/api/objects/Template';

export default class AdminTemplateService extends AbstractService {
  /**
   * Retrieves all Templates
   * @protected
   * @param params
   */
  public all(params: any): Promise<Template[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/templates'),
      params,
    });
  }
}
