<template>
  <v-off-canvas
    id="client-details-offcanvas"
    :backdrop-static="false"
    offcanvas-class="item-details offcanvas-end"
    @closed="$emit('closed')"
  >
   <resizable-container>
    <off-canvas-header>
      <div class="d-flex align-items-center">
        <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

        <h5 id="client-details-offcanvas-label" class="offcanvas-title">
          {{ $t('client.clientDetails') }}
        </h5>
      </div>
    </off-canvas-header>

    <off-canvas-body class="justify-content-between d-flex flex-column">
      <v-loader v-if="status === 'loading'"/>

      <div v-else class="item-details">
        <the-client-details-basic-information
          :client="client"
          :agency="agency.slug"
          @reload-client="loadClient"
          @emit-reload-clients="$emit('reloadClients')"
        />

        <the-client-details-contacts
          v-if="!client.deletedAt"
          :client="client"
          @reload-client="loadClient"
        />

        <div v-if="!client.deletedAt" class="detail">
          <div class="detail-title fw-medium mb-20">{{ $t('client.cases') }}</div>

          <div class="detail-content">
            <p class="text-sm">{{ $t('client.casesText') }}</p>

            <router-link
              id="cases"
              :aria-label="$t('general.menu.cases')"
              :to="{ name: 'case.index', query: { 'filters[client_id]': client.id } }"
              class="btn btn-primary btn-icon btn-sm mb-8"
            >
              {{ $t('client.seeCases') }}
              <v-icon icon="arrow-right" space="ms-12"/>
            </router-link>
          </div>
        </div>

        <div class="detail pb-0 mb-0">
          <div class="detail-title fw-medium mb-20">
            <template v-if="!client.deletedAt">{{ $t('general.shared.archive') }}</template>
            <template v-else>{{ $t('general.shared.restore') }}</template>
          </div>

          <div class="detail-content">
            <p v-if="!client.deletedAt" class="text-sm">{{ $t('client.archiveText') }}</p>
            <p v-else class="text-sm">{{ $t('client.archivedText') }}</p>

            <v-button
              v-if="!client.deletedAt"
              id="btn-archive-client"
              :disabled="disableArchiveBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="archiveClient"
            >
              <v-icon icon="import" space="me-12"/>
              <template v-if="disableArchiveBtn">{{ $t('general.button.archiving') }}</template>
              <template v-else>{{ $t('general.button.archive') }}</template>
            </v-button>

            <v-button
              v-else
              id="btn-restore-client"
              :disabled="disableRestoreBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="primary"
              @click="restoreClient"
            >
              <v-icon icon="unarchive" space="me-12"/>
              <template v-if="disableRestoreBtn">{{ $t('general.button.restoring') }}</template>
              <template v-else>{{ $t('general.button.restore') }}</template>
            </v-button>
          </div>
        </div>

        <div v-if="!!client.deletedAt" class="detail">
          <div class="detail-title fw-medium mb-20">
            {{ $t('general.shared.delete') }}
          </div>

          <div class="detail-content">
            <p class="text-sm">{{ $t('client.deleteText') }}</p>

            <v-button
              id="btn-delete-client"
              :disabled="disableDeleteBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="deleteClient"
            >
              <v-icon icon="trash" space="me-12"/>
              <template v-if="disableDeleteBtn">{{ $t('general.button.deleting') }}</template>
              <template v-else>{{ $t('general.button.delete') }}</template>
            </v-button>
          </div>
        </div>
      </div>
    </off-canvas-body>
   </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import TheClientDetailsBasicInformation from '@/components/client/parts/TheClientDetailsBasicInformation.vue';
import TheClientDetailsContacts from '@/components/client/parts/TheClientDetailsContacts.vue';
import Client from '@/api/objects/Client';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import { useAuthStore } from '@/stores/auth';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheClientDetailsOffcanvas',
  components: {
    VLoader,
    TheClientDetailsContacts,
    TheClientDetailsBasicInformation,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
  },
  emits: [
    'closed',
    'reloadClients',
  ],
  props: {
    clientData: {
      type: Client,
      required: true,
    },
    isArchived: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      client: {} as Client,
      status: 'loading' as string,
      disableArchiveBtn: false as boolean,
      disableDeleteBtn: false as boolean,
      disableRestoreBtn: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
  },
  watch: {
    async clientData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.isArchived) {
          await this.loadClient();
        } else {
          this.client = this.clientData;

          this.status = 'loaded';
        }
      }
    },
  },
  async created() {
    if (!this.isArchived) {
      await this.loadClient();
    } else {
      this.client = this.clientData;

      this.status = 'loaded';
    }
  },
  methods: {
    async loadClient() {
      this.status = 'loading';

      if (!!this.agency) {
        this.client = await api.client.retrieve(this.agency.slug, this.clientData.id);
      }

      this.status = 'loaded';
    },
    async archiveClient() {
      await this.$modal.delete({
        title: this.$t('client.archiveClientTitle'),
        text: this.$t('client.archiveClientText', { client: `${this.client?.name} ${this.client?.surname}` }),
        deleteButtonText: this.$t('general.button.archive'),
        deleteButtonLoadingText: this.$t('general.button.archiving'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doArchiveClient,
      });
    },
    async doArchiveClient() {
      if (!!this.client) {
        try {
          this.disableArchiveBtn = true;

          if (!!this.agency) {
            await api.client.archive(this.agency.slug, this.client.id);

            this.$toast.success(this.$t('general.shared.savedChanges'));

            this.$emit('reloadClients');
            this.$emit('closed');
          }
        } catch (e: any) {
          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        } finally {
          this.disableArchiveBtn = false;
        }
      }
    },
    async restoreClient() {
      await this.$modal.confirm({
        title: this.$t('client.restoreClientTitle'),
        text: this.$t('client.restoreClientText', { client: `${this.client?.name} ${this.client?.surname}` }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doRestoreClient,
      });
    },
    async doRestoreClient() {
      if (!!this.client) {
        try {
          this.disableRestoreBtn = true;

          if (!!this.agency) {
            await api.client.restore(this.agency.slug, this.client.id);

            this.$toast.success(this.$t('general.shared.savedChanges'));

            this.$emit('reloadClients');
            this.$emit('closed');
          }
        } catch (e: any) {
          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        } finally {
          this.disableRestoreBtn = false;
        }
      }
    },
    async deleteClient() {
      await this.$modal.delete({
        title: this.$t('client.deleteClientTitle'),
        text: this.$t('client.deleteClientText', { client: `${this.client?.name} ${this.client?.surname}` }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doDeleteClient,
      });
    },
    async doDeleteClient() {
      if (!!this.client) {
        try {
          this.disableDeleteBtn = true;

          if (!!this.agency) {
            await api.client.delete(this.agency.slug, this.client.id);

            this.$toast.success(this.$t('general.shared.savedChanges'));

            this.$emit('reloadClients');
            this.$emit('closed');
          }
        } catch (e: any) {
          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        } finally {
          this.disableDeleteBtn = false;
        }
      }
    },
  },
});
</script>
