import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import TourDayItemResource from '@/api/objects/TourDayItemResource';

export interface NotificationContract extends IBaseObject {
  clientId: number | null;
  createdAt: string;
  mandrillId: string;
  sentAt: string;
  status: string;
  tourDayItemResourceId: number | null;
  tourId: number;
  type: string;
  tourDayItemResource: TourDayItemResource;
}

export default class Notification extends BaseObject implements NotificationContract {
  public declare clientId: number | null;
  public declare createdAt: string;
  public declare mandrillId: string;
  public declare sentAt: string;
  public declare status: string;
  public declare tourDayItemResourceId: number | null;
  public declare tourId: number;
  public declare type: string;
  public declare tourDayItemResource: TourDayItemResource;

  constructor(data: NotificationContract) {
    super(data);
  }
}
