import { AbstractService } from '@uniqoders/sdk';

export default class AdminImpersonateService extends AbstractService {
  /**
   * Start impersonate
   * @param user
   * @protected
   */
  public async start(user: number) {
    await this.request({
      method: 'post',
      url: this.buildPath('/impersonate/{user}/start', { user }),
    });
  }

  /**
   * Stop impersonate
   * @protected
   */
  public async stop() {
    await this.request({
      method: 'get',
      url: this.buildPath('/impersonate/stop'),
    });
  }
}
