<template>
  <router-view v-slot="{ Component }">
    <transition name="fade-in" appear>
      <component :is="Component" />
    </transition>
  </router-view>

  <the-language-switcher v-if="!!languages && languages.length > 0" />

  <the-general-components />
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted, onUnmounted } from 'vue';
import TheGeneralComponents from '@/components/vendor/generalModals/TheGeneralComponents.vue';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import useConcepts from '@/helpers/Concepts';
import TheLanguageSwitcher from '@/components/layout/parts/TheLanguageSwitcher.vue';
import { mapConcepts } from '@/helpers/ConceptHelper';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: {
    TheLanguageSwitcher,
    TheGeneralComponents,
  },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const router = useRouter();
    const route = useRoute();
    appStore.init();

    const state = reactive({
      idleTimeout: 2 * 60 * 60 * 1000, // 2 hours in milliseconds
      idleTimer: 0,
    });

    // Reset the idle timer
    const resetIdleTimer = () => {
      clearTimeout(state.idleTimer);
      state.idleTimer = setTimeout(logoutUser, state.idleTimeout);
    };

    // Logout the user after idle timeout
    const logoutUser = async () => {
      if (route.name === 'login') {
        return; // Skip authentication check on the login page
      } else {
        await authStore.logout();

        await router.replace({ name: 'login' });
      }
    };

    // Event listeners to reset timer on user activity
    const setupEventListeners = () => {
      const events = ['mousemove', 'mousedown', 'keydown', 'touchstart'];
      events.forEach(event => {
        window.addEventListener(event, resetIdleTimer);
      });
    };

    // Remove event listeners when component unmounts
    onMounted(() => {
      setupEventListeners();
      resetIdleTimer();
    });

    onUnmounted(() => {
      clearTimeout(state.idleTimer);
    });

    return {
      ...useConcepts(),
      ...toRefs(state),
    };
  },
  computed: {
    languages(): any[] {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
  },
  created() {
    document.title = import.meta.env.VITE_APP_TITLE;
  },
});
</script>

<style lang="scss" scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
