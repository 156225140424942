<template>
  <v-off-canvas
    ref="myOffCanvas"
    id="tour-day-information-off-canvas"
    :backdrop-static="true"
    offcanvas-class="item-details offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
        <div class="d-flex align-items-center">
          <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

          <h5 id="tour-day-information-off-canvas-label" class="offcanvas-title">
            {{ $t('tourBuilder.stageInformation') }}
          </h5>
        </div>
      </off-canvas-header>

      <off-canvas-body>
        <v-loader v-if="!loaded"/>

        <div v-else class="stage-information-container">
          <div class="stage-data text-sm mb-24">
            <div class="fw-medium mb-4">
              {{ $t('general.shared.date') }}:

              <span class="fw-normal">
                {{ $str.formatDateTime(tourDay.date, settings.formatDate) }}
              </span>
            </div>

            <template v-if="tourDay.type === 'stage'">
              <div class="fw-medium mb-4">
                {{ $t('general.shared.origin') }}:

                <span class="fw-normal">
                  {{ !!tourDay.origin ? tourDay.origin.name : '-' }}
                </span>
              </div>

              <div class="fw-medium mb-4">
                {{ $t('general.shared.destination') }}:

                <span class="fw-normal">
                  {{ !!tourDay.destination ? tourDay.destination.name : '-' }}
                </span>
              </div>
            </template>

            <div v-else class="fw-medium mb-4">
              {{ $t('general.shared.location') }}:

              <span class="fw-normal">
                {{ !!tourDay.origin ? tourDay.origin.name : '-' }}
              </span>
            </div>
          </div>

          <div class="detail-title d-flex justify-content-between mb-8 align-items-center">
            <div class="fw-medium">{{ $t('general.shared.basicInformation') }}</div>

            <v-button
              class="btn-edit p-4"
              variant="icon"
              @click="editBasicInformation = !editBasicInformation"
            >
              <v-icon icon="pencil"/>
            </v-button>
          </div>

          <div class="detail-content">
            <Transition mode="out-in" name="fade-in">
              <div v-if="!editBasicInformation">
                <div v-if="tourDay.type === 'stage'" class="text-sm d-flex py-4">
                  <span class="info-title fw-medium me-4">{{ $t('general.shared.distanceKm') }}: </span>
                  <span>{{ tourDay.stageLength }}</span>
                </div>

                <div class="text-sm d-flex py-4">
                  <span class="info-title fw-medium me-4">{{ $t('general.shared.description') }}: </span>
                  <div v-html="tourDay.description"/>
                </div>

                <div class="text-sm d-flex py-4">
                  <span class="info-title fw-medium me-4">{{ $t('general.shared.url') }}: </span>
                  <a
                    :aria-label="$t('general.shared.url')"
                    :href="tourDay.url"
                    class="btn-link p-0"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {{ tourDay.url }}
                  </a>
                </div>
              </div>

              <form
                v-else
                id="tour-day-update-information"
                class="d-flex flex-column justify-content-between h-100"
              >
                <fieldset class="mb-16">
                  <legend class="visually-hidden">{{ $t('general.shared.basicInformation') }}</legend>

                  <v-form-input
                    v-if="tourDay.type === 'stage'"
                    :id="`tour-day-update-length`"
                    v-model="stageLength"
                    :disabled="tourDay.isBlocked"
                    :label="$t('general.shared.distanceKm')"
                    :yup-errors-variable="errors.stageLength"
                    autocomplete="off"
                    class="mb-16"
                    form-type="outline"
                    onwheel="return false;"
                    type="number"
                  />

                  <div class="fieldset-translatable">
                    <nav class="translations-tab">
                      <div id="nav-tab" class="nav nav-tabs" role="tablist">
                        <v-button
                          v-for="(language, index) in languages"
                          :id="`nav-tour-day-update-basic-information-${language.key}`"
                          :key="index"
                          :aria-controls="`tour-day-update-basic-information-${language.key}`"
                          :class="{ active: language.shortName === currentLanguage }"
                          :data-bs-target="`#tour-day-update-basic-information-${language.key}`"
                          aria-selected="true"
                          class="nav-tab-link py-8 px-16 me-8"
                          data-bs-toggle="tab"
                          role="tab"
                          size="sm"
                          variant="outline-primary"
                          @click="currentLanguage = language.shortName"
                        >
                          {{ language.label }}
                        </v-button>
                      </div>
                    </nav>

                    <transition
                      mode="out-in"
                      name="fade"
                      appear
                    >
                      <div
                        id="tour-day-create-basic-information-tabs"
                        class="tab-content"
                      >
                        <div
                          v-for="(language, index) in languages"
                          :id="`tour-day-update-basic-information-${language.key}`"
                          :key="index"
                          :aria-labelledby="`nav-tour-day-update-basic-information-${language.key}`"
                          :class="{ 'active show': language.shortName === currentLanguage }"
                          class="tour-day-update-basic-information-tab tab-pane fade"
                          role="tabpanel">
                          <the-tour-day-update-basic-information-fieldset
                            v-model:description="translations[language.shortName].description"
                            :clear="clear"
                            :current-language="currentLanguage"
                            :language="language.shortName"
                            @media-uploaded="editorMedia.push($event)"
                            @is-valid="isTranslationsValid.find((translation) => translation.locale === language.shortName).valid = $event"
                          />
                        </div>
                      </div>
                    </transition>
                  </div>

                  <v-form-input
                    :id="`tour-day-update-url`"
                    v-model="url"
                    :disabled="tourDay.isBlocked"
                    :label="$t('general.shared.url')"
                    :yup-errors-variable="errors.url"
                    autocomplete="off"
                    form-type="outline"
                  />
                </fieldset>

                <v-button
                  :disabled="!meta.valid || accepted || disableSaveButton
                    || !isTranslationsValid.every(translation => translation.valid)"
                  :is-loading="accepted"
                  class="btn-submit-form btn-icon w-fit align-self-end"
                  size="sm"
                  variant="primary"
                  @click="saveTourDay"
                >
                  {{ $t('general.button.save') }}
                  <v-icon icon="arrow-right" size="sm" space="ms-12"/>
                </v-button>
              </form>
            </Transition>
          </div>

          <div class="search-separator-container">
            <div class="separator-text-container mb-16"/>
          </div>

          <fieldset>
            <legend class="visually-hidden">{{ $t('general.shared.media') }}</legend>

            <div class="tour-day-media-container mb-32">
              <div class="media-title text-lg fw-semi mb-16">{{ $t('general.shared.media') }}</div>

              <v-uploader
                ref="uploader"
                :after-upload="onChange"
                :headers="{ 'X-case': clientTourId }"
                :initial-value="photosUuid"
                route-prefix="api/s3"
                @removed="onChange"
              />

              <div class="media-container mt-16">
                <div class="row gx-8 gy-8">
                  <div
                    v-for="media in tourDay.media"
                    :key="media.id"
                    class="col-4 col-lg-3"
                  >
                    <div class="media position-relative">
                      <v-picture v-if="!!media.storageUrl">
                        <img
                          :alt="media.fileName"
                          :src="media.storageUrl"
                          class="rounded-sm"/>
                      </v-picture>

                      <div v-else>
                        <div>{{ media.fileName }}</div>
                      </div>

                      <div v-if="!tourDay.deletedAt" class="media-actions position-absolute top-0 end-0">
                        <v-button
                          :disabled="disableRemoveButton"
                          class="btn-trash btn-primary fw-normal me-4"
                          variant="icon"
                          @click="removeMedia(media.id)"
                        >
                          <v-icon icon="trash" size="md"/>
                        </v-button>

                        <v-button
                          :disabled="disableRemoveButton"
                          class="btn-trash btn-primary fw-normal"
                          variant="icon"
                          @click="showImage(media)"
                        >
                          <v-icon icon="search" size="md"/>
                        </v-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <v-button
                :disabled="mediaUuids.length === 0 || accepted || disableSaveButton"
                :is-loading="accepted"
                class="btn-submit-form btn-icon w-fit"
                size="sm"
                variant="primary"
                @click="saveTourDay"
              >
                {{ $t('general.button.save') }}
                <v-icon icon="arrow-right" size="sm" space="ms-12"/>
              </v-button>
            </div>
          </fieldset>
        </div>
      </off-canvas-body>

      <v-show-media-modal
        v-if="modalToShow === 'v-show-media-modal' && !!currentMedia"
        :media="currentMedia"
        @closed="closeModal(); currentMedia = null"
      />
    </resizable-container>

  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { useUserStore } from '@/stores/user';
import useFormValidation from '@/helpers/form';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import api from '@/api';
import TourDay from '@/api/objects/TourDay';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useConcepts from '@/helpers/Concepts';
import { mapConcepts } from '@/helpers/ConceptHelper';
import TheTourDayUpdateBasicInformationFieldset from '@/components/tour/TheTourDayUpdateBasicInformationFieldset.vue';
import VUploader from '@/components/vendor/upload/VUploader.vue';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import VShowMediaModal from '@/components/shared/VShowMediaModal.vue';
import useModalUtils from '@/helpers/ModalUtils';
import Media from '@/api/objects/Media';
import { useAppStore } from '@/stores/app';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue'

export default defineComponent({
  name: 'TheTourDayInformationOffCanvas',
  components: {
    VShowMediaModal,
    TheTourDayUpdateBasicInformationFieldset,
    VLoader,
    VIcon,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VFormInput,
    VButton,
    VUploader,
    VPicture,
    ResizableContainer
  },
  emits: [
    'closed',
    'emitReloadTour',
  ],
  props: {
    agency: {
      type: String,
      required: true,
    },
    clientTourId: {
      type: Number,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    tourDayId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      stageLength: yup.number()
        .min(0)
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.distanceKm')),
      url: yup.string()
        .nullable()
        .label(t('general.shared.url')),
    });

    const form = useFormValidation(rules);

    const { value: stageLength } = useField('stageLength');
    const { value: url } = useField('url');

    return {
      ...useModalUtils(),
      ...useConcepts(),
      ...form,
      stageLength,
      url,
    };
  },
  data() {
    return {
      currentMedia: null as Media | null,
      loaded: false as boolean,
      disableSaveButton: false as boolean,
      tourDay: {} as TourDay,
      media: [] as any,
      translations: {} as Record<string, any>,
      currentLanguage: '',
      clear: false,
      isTranslationsValid: [] as Record<string, any>[],
      photosUuid: {} as Record<string, any>,
      disableRemoveButton: false as boolean,
      editBasicInformation: false as boolean,
      editorMedia: [] as any[],
    };
  },
  computed: {
    ...mapState(useUserStore, ['currentTourDayItem']),
    ...mapState(useAppStore, ['settings']),
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    languages(): any {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
    mediaUuids() {
      return this.media.map((media: any) => (media.attributes.uuid));
    },
  },
  watch: {
    async tourDayId(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.init();
      }
    },
  },
  async created() {
    this.loaded = false;
    this.currentLanguage = this.defaultLocale;

    await this.init();

    this.loaded = true;
  },
  methods: {
    showImage(media: Media) {
      this.currentMedia = media;

      this.openModal('v-show-media-modal');
    },
    async init() {
      await this.loadTourDay();
      await this.mapTranslations();
      await this.setDefaultValues();
    },
    async loadTourDay() {
      try {
        this.tourDay = await api.tourDay.retrieve(this.agency, this.clientTourId, this.tourId, this.tourDayId);
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
    onChange() {
      const { uploader }: any = this.$refs;

      if (!!uploader) {
        this.media = uploader.mediaLibrary.state.media;
      }
    },
    setDefaultValues() {
      this.setValues({
        stageLength: this.tourDay.stageLength,
        url: this.tourDay.url,
      });

      this.tourDay.translations.forEach((translation: any) => {
        this.translations[translation.locale].description = translation.description;

        const translationValid = this.isTranslationsValid.find(
          (translations) => translations.locale === translation.locale);

        if (!!translationValid) {
          translationValid.valid = true;
        }
      });
    },
    mapTranslations() {
      this.isTranslationsValid = [];

      this.languages.forEach((language: Record<string, any>) => {
        this.isTranslationsValid.push({
          locale: language.key,
          valid: true,
        });

        this.translations[language.key] = {
          description: '',
        };
      });
    },
    async removeMedia(mediaId: string | number) {
      await this.$modal.delete({
        title: this.$t('resource.removeMediaTitle'),
        text: this.$t('resource.removeMediaText'),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doRemoveMedia(mediaId),
      });
    },
    async doRemoveMedia(contactId: string | number) {
      try {
        this.disableRemoveButton = true;

        await api.media.delete('s3', contactId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.disableRemoveButton = false;

        await this.init();
      } catch (e: any) {
        this.disableRemoveButton = false;

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
    async saveTourDay() {
      try {
        this.toggleAccepted();
        this.disableSaveButton = true;

        const data = {
          pax: this.tourDay.pax,
          date: this.tourDay.date,
          type: this.tourDay.type,
          origin: this.tourDay.originId,
          destination: this.tourDay.destinationId,
          stage_length: this.stageLength,
          url: this.url,
          images: this.mediaUuids,
          translations: this.translations,
        };

        const response = await api.tourDay.update(this.agency, this.clientTourId, this.tourId, this.tourDayId, data);

        if (!!this.editorMedia && this.editorMedia.length > 0) {
          const editorMedia = {
            media: this.editorMedia,
            model_type: 'tour_day',
            collection: 'tinymce',
            model_id: this.tourDayId,
          };

          await api.media.confirm('s3', editorMedia);
        }

        this.tourDay = response;

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.editBasicInformation = false;

        this.photosUuid = [];

        const { uploader }: any = this.$refs;

        if (!!uploader) {
          uploader.mediaLibrary.state.media = [];
        }

        await this.init();

        this.$emit('emitReloadTour');
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
        this.disableSaveButton = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.accordion-item {
  background-color: transparent;
}

.accordion .accordion-item .accordion-button {
  font-size: 1rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: transparent;
}
.info-popup {
  position: fixed;
  top: auto;
  right: 0;
  height: calc(100% - 6.125rem);
  background-color: var(--uq-light);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: auto;
  min-width: 480px;
}
.popup-content {
  overflow: auto;
}

.resize-handle {
  position: absolute;
  top: 0;
  left: -5px;
  width: 8px;
  height: 100%;
  cursor: ew-resize;
  background: #ccc;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: ew-resize;
  z-index: 999; /* Ensure overlay is above other elements */
}
</style>
