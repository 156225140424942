<template>
  <draggable
    v-if="!!modelValue"
    :list="modelValue"
    class="tour"
    draggable=".tour-day-container:not(.not-draggable)"
    item-key="id"
    v-bind="dragOptions"
    @change="change"
  >
    <template #item="{ element, index }">
      <div
        :key="index"
        :class="{
          'not-draggable ghost-item': updatingTour,
          'not-draggable blocked-item': element.isBlocked,
        }"
        class="tour-day-container">
        <div key="tour-day">
          <v-button
            v-if="index !== 0"
            :disabled="updatingTour"
            class="btn-icon btn-day p-4 mb-12"
            size="sm"
            variant="outline-secondary"
            @click="addDay(element.date)"
          >
            <v-icon icon="plus"/>
          </v-button>

          <div
            :class="{ 'mt-36': index === 0 }"
            class="tour-day d-flex flex-row row gx-0"
          >
            <div class="col-4">
              <div class="tour-day-info p-20 pe-16">
                <div class="d-flex align-items-center">
                  <div class="tour-day-info-left me-32">
                    <div class="day-circle d-flex align-items-center justify-content-center mb-8">
                      <div>{{ index + 1 }}</div>
                    </div>
                  </div>

                  <div class="tour-day-info-right w-100">
                    <div class="d-flex align-items-center mb-12">
                      <v-checkbox
                        :id="`template-day-${element.id}-stage`"
                        :is-disabled="isTourDayUpdating"
                        :label="$t('template.stage')"
                        :left="false"
                        :modelValue="setIsStage(element.type)"
                        :right="true"
                        class="form-check-inline me-32"
                        for-key="isAllDayActivated"
                        @update:modelValue="updateTourDayType($event, element)"
                      />
                    </div>

                    <template v-if="element.type === 'stage'">
                      <v-input-address
                        id="template-day-origin-search"
                        ref="template-day-origin-search-ref"
                        :disabled-input="isTourDayUpdating"
                        :get-address="true"
                        :get-radius-bounds="true"
                        :label="$t('general.shared.origin')"
                        :radius-bounds="20000"
                        :value="searchLocation(element.originId)"
                        class="col-12 mb-20"
                        @address="createLocation(element, $event, true, index)"
                        @input="updateTourDayOrigin($event, element, index)"
                      />

                      <v-input-address
                        id="template-day-destination-search"
                        ref="template-day-destination-search-ref"
                        :disabled-input="isTourDayUpdating"
                        :get-address="true"
                        :get-radius-bounds="true"
                        :label="$t('general.shared.destination')"
                        :radius-bounds="20000"
                        :value="searchLocation(element.destinationId)"
                        class="col-12 mb-20"
                        @address="createLocation(element, $event, false, index)"
                        @input="updateTourDayDestination($event, element)"
                      />

                      <div class="text-sm mb-20">
                        {{ $t('general.shared.distanceKm') }}: {{ element.stageLength }}
                      </div>
                    </template>

                    <template v-else>
                      <v-input-address
                        id="template-day-location-search"
                        ref="template-day-location-search-ref"
                        :disabled-input="isTourDayUpdating"
                        :get-address="true"
                        :get-radius-bounds="true"
                        :label="$t('general.shared.location')"
                        :radius-bounds="20000"
                        :value="searchLocation(element.originId)"
                        class="col-12 mb-20"
                        @address="createLocation(element, $event, true, index)"
                        @input="updateTourDayOrigin($event, element)"
                      />
                    </template>

                    <v-button
                      :class="{ active: currentTourDay === element.id }"
                      class="btn-icon"
                      size="xs"
                      variant="outline-default"
                      @click="$emit('openDayInformationOffCanvas', element.id, (index + 1));"
                    >
                      <v-icon icon="pencil" space="me-12"/>
                      {{ $t('tourBuilder.editStageInformation') }}
                    </v-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-8">
              <div class="d-flex justify-content-between h-100 align-items-center p-20 ps-16">
                <the-template-day-item-draggable
                  :agency="agency"
                  :can-add-item="(element.type !== 'stage' && !!element.originId)
                    || (element.type === 'stage' && !!element.originId && !!element.destinationId)"
                  :list="element.items"
                  :template-day-id="element.id"
                  :template-id="templateId"
                  :tour-mapped="tourMapped"
                  :updating-tour="updatingTour"
                  type="tour-day-item"
                  @emit-reload-tour="$emit('reloadTour')"
                  @open-item-details-off-canvas="$emit('openItemDetailsOffCanvas', $event)"
                  @move-item-to-another-day="$emit('moveItemToAnotherDay', $event)"
                />

                <div class="d-flex flex-column">
                  <v-button
                    :disabled="updatingTour"
                    class="btn-day p-4 mb-12"
                    size="sm"
                    variant="icon"
                    @click="$emit('reloadTour')"
                  >
                    <span class="icon icon-refresh text-lg text-primary"/>
                  </v-button>

                  <v-button
                    :disabled="updatingTour"
                    class="btn-day p-4 mb-12"
                    size="sm"
                    variant="icon"
                    @click="removeDay(element, index)"
                  >
                    <v-icon icon="trash" size="lg"/>
                  </v-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </template>
  </draggable>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Vuedraggable from 'vuedraggable';
import { VCheckbox } from '@uniqoders/form';
import { TYPE } from 'vue-toastification';
import { DateTime } from 'luxon';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import Location from '@/api/objects/Location';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import TheTemplateDayItemDraggable from '@/components/template/TheTemplateDayItemDraggable.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';

export default defineComponent({
  name: 'TheTemplateDayDraggable',
  components: {
    TheTemplateDayItemDraggable,
    VInputAddress,
    draggable: Vuedraggable,
    VCheckbox,
    VButton,
    VIcon,
  },
  emits: [
    'dayMoved',
    'emitAddDay',
    'reloadTour',
    'openItemDetailsOffCanvas',
    'moveItemToAnotherDay',
    'openDayInformationOffCanvas',
  ],
  props: {
    modelValue: {
      type: Array as PropType<Record<string, unknown>[]>,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    templateId: {
      type: Number,
      required: true,
    },
    agency: {
      type: String,
      required: true,
    },
    updatingTour: {
      type: Boolean,
      required: false,
      default: false,
    },
    initDate: {
      type: String,
      required: true,
      default: '',
    },
    endDate: {
      type: String,
      required: true,
      default: '',
    },
    currentTourDay: {
      type: [String, Number],
      required: false,
      default: '',
    },
    tourMapped: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      findConceptByKey,
      ...useOffCanvasUtils(),
    };
  },
  data() {
    return {
      isStage: false as boolean,
      locations: [] as Location[],
      isTourDayUpdating: false as boolean,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'tour-day',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  async created() {
    await this.loadLocations();
  },
  methods: {
    async loadLocations() {
      this.locations = await api.location.all();
    },
    searchLocation(locationId: number): string {
      const locationName = this.locations.find((location) => location.id === locationId);

      if (!!locationName) {
        return locationName.name;
      }

      return '';
    },
    getDate(index: number) {
      const initDate = DateTime.fromISO(this.initDate);
      const endDate = DateTime.fromISO(this.endDate);

      let date = initDate;

      const dates: string[] = [];

      while (date <= endDate) {
        dates.push(date.toFormat('yyyy-MM-dd'));
        date = date.plus({ days: 1 });
      }

      return dates[index];
    },
    change(event: any) {
      const tourDay = event.moved.element;
      const newDate = this.getDate(event.moved.newIndex);

      this.$emit('dayMoved', tourDay, newDate);
    },
    async addDay(tourDayDate: string) {
      this.$emit('emitAddDay', tourDayDate);
    },
    async removeAllItems(tourDay: Record<string, any>) {
      tourDay.items.forEach((item: Record<string, any>) => {
        api.templateDayItem.delete(
          this.agency,
          this.templateId,
          tourDay.id,
          item.id,
        );
      });
    },
    async removeDay(tourDay: Record<string, any>, dayNumber: number) {
      await this.$modal.delete({
        title: this.$t('template.deleteDayTitle'),
        text: this.$t('template.deleteDayText', { dayNumber: dayNumber + 1 }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doRemoveDay(tourDay),
      });
    },
    async doRemoveDay(tourDay: Record<string, any>) {
      const loadingToast = this.$toast.loading(this.$t('general.button.deleting'), 'toast-remove-tour-day');

      try {
        await this.removeAllItems(tourDay);

        await api.templateDay.delete(
          this.agency,
          this.templateId,
          tourDay.id,
        );

        this.$emit('reloadTour');

        loadingToast.update('toast-remove-tour-day', {
          content: this.$t('template.dayDeleted'),
          options: {
            type: TYPE.SUCCESS,
            icon: 'icon icon-check-circle-fill',
            timeout: 5000,
          },
        });
      } catch (e: any) {
        loadingToast.update('toast-remove-tour-day', {
          content: this.$t('template.dayDeleteError'),
          options: {
            type: TYPE.ERROR,
            icon: 'icon icon-exclamation-triangle-fill',
            timeout: 5000,
          },
        });

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async updateTourDayLength(tourDay: Record<string, any>) {
      if ((!tourDay.stageLength && tourDay.stageLength === 0)
        || (!!tourDay.stageLength && tourDay.stageLength >= 0)) {
        await this.updateTourDay(tourDay);
      }
    },
    async createLocation(tourDay: Record<string, any>, address: Record<string, any>, isOrigin = true, position = null) {
      const location = await api.location.create({
        name: !!address.name ? address.name : '-',
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });

      if (isOrigin) {
        tourDay.originId = location.id;
      } else {
        tourDay.destinationId = location.id;
      }
      let positionCode = undefined;
      if (position !== null && (position + 1) === this.tourMapped.tourDays.length) {
        positionCode = 1;
      } else if (position !== null && position == 0) {
        positionCode = position;
      }
      
      await this.updateTourDay(tourDay, positionCode);
    },
    setIsStage(tourDayType: string) {
      return tourDayType === 'stage';
    },
    async updateTourDayType(checkboxValue: boolean, tourDay: Record<string, any>) {
      if (!this.isTourDayUpdating) {
        tourDay.type = checkboxValue ? 'stage' : 'rest';

        await this.updateTourDay(tourDay);
      }
    },
    async updateTourDayOrigin(value: string, tourDay: Record<string, any>, position = 0) {
      if (!value) {
        tourDay.originId = null;
        
        await this.updateTourDay(tourDay, position);
      }
    },
    async updateTourDayDestination(value: string, tourDay: Record<string, any>) {
      if (!value) {
        tourDay.destinationId = null;

        await this.updateTourDay(tourDay);
      }
    },
    async updateTourDay(tourDay: Record<string, any>, position: any = null) {
      let positionCode = 0;
      if (position !== null && position == 0) {
        positionCode = 1; // origin
      } else if (position !== null && position == 1) {
        positionCode = 2; // destination
      }
      if (!this.isTourDayUpdating) {
        try {
          this.isTourDayUpdating = true;

          await api.templateDay.update(
            this.agency,
            this.templateId,
            tourDay.id,
            {
              date: tourDay.date,
              type: tourDay.type,
              origin: tourDay.originId,
              destination: tourDay.destinationId,
              stage_length: tourDay.stageLength,
              position: positionCode, // 1 = origin, 2 = destination
            },
          );

          this.isTourDayUpdating = false;
        } catch (e: any) {
          this.isTourDayUpdating = false;

          console.error(e);

          this.$toast.error(e.response.data.message);
        }
      }
    },
  },
});
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.list-group {
  min-height: 20px;
}

.list-enter-from {
  opacity: 0;
  transform: scaleY(0.25);
}

.list-enter-active,
.list-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.list-move {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-active {
  position: relative;
}

.list-group-item {
  display: block !important;
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  width: 100% !important;
}
</style>
