<template>
  <div id="accordionInvoicesEmitted" class="accordion">
    <div class="case-details accordion-item">
      <h2 id="headingInvoicesEmitted" class="accordion-header">
        <button aria-controls="collapseInvoicesEmitted" aria-expanded="true" class="accordion-button collapsed"
          data-bs-target="#collapseInvoicesEmitted" data-bs-toggle="collapse" type="button">
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="description" size="xxl" space="p-12" />
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t('clientTour.invoicesEmitted') }}</div>
              <div class="fw-light accordion-subtitle">{{ $t('clientTour.invoicesEmittedSubtitle') }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div id="collapseInvoicesEmitted" aria-labelledby="headingInvoicesEmitted"
        class="accordion-collapse collapse show" data-bs-parent="#accordionInvoicesEmitted">
        <div class="accordion-body pb-32 pb-xl-48">
          <div class="tour-invoices-emitted-container item-list text-sm">
            <div class="table-responsive d-none d-md-flex d-lg-none d-xl-flex">
              <v-table class="mb-0">
                <v-table-header>
                  <tr>
                    <th>{{ $t('general.shared.number') }}</th>
                    <th>{{ $t('general.shared.amount') }}</th>
                    <th class="fit" />
                  </tr>
                </v-table-header>

                <v-table-body class="fw-medium">
                  <tr v-if="!invoicesLoaded">
                    <td class="position-relative border-0 py-96" colspan="2222">
                      <v-loader />
                    </td>
                  </tr>

                  <template v-else-if="!!invoices && invoices.length > 0">
                    <tr v-for="invoice in invoices" :key="invoice.id">
                      <td class="d-flex align-items-center py-20">
                        <span class="px-8">{{ invoice.number }}</span>
                      </td>

                      <td>
                        <span class="me-2">{{ $t('general.shared.euroShort') }}</span>
                        {{ invoice.amount }}
                      </td>

                      <td class="fit text-end">
                        <div class="d-flex align-items-center">
                          <v-button class="btn-action" variant="icon" @click="showInvoiceDetails(invoice)">
                            <v-icon icon="eye" />
                          </v-button>

                          <v-button :disabled="disableDownloadButton" class="btn-action" variant="icon"
                            @click="downloadInvoice(invoice)">
                            <v-icon icon="download" />
                          </v-button>

                          <v-button :disabled="disableArchiveButton" class="btn-action" variant="icon"
                            @click="archiveInvoice(invoice)">
                            <v-icon icon="trash" />
                          </v-button>
                        </div>
                      </td>
                    </tr>
                  </template>

                  <template v-else>
                    <tr>
                      <td class="rounded-xs py-16" colspan="2222">
                        {{ $t('clientTour.invoice.noInvoicesEmitted') }}
                      </td>
                    </tr>
                  </template>
                </v-table-body>
              </v-table>
            </div>

            <div class="item-cards d-block d-md-none d-lg-block d-xl-none">
              <div v-if="!invoicesLoaded" class="position-relative py-96">
                <v-loader />
              </div>

              <template v-else-if="!!invoices && invoices.length > 0">
                <div v-for="invoice in invoices" :key="invoice.id" class="item-card border rounded-xs p-8 mb-12">
                  <div class="fw-bold mb-4">{{ invoice.number }}</div>

                  <div>
                    <span class="fw-medium">{{ $t('general.shared.amount') }}: </span>
                    <span>{{ $t('general.shared.euroShort') }} {{ invoice.amount }}</span>
                  </div>

                  <div class="d-flex justify-content-end align-items-center">
                    <v-button class="btn-action p-4" size="xs" variant="icon" @click="showInvoiceDetails(invoice)">
                      <v-icon class="text-normal" icon="eye" />
                    </v-button>

                    <v-button :disabled="disableDownloadButton" class="btn-action p-4" size="xs" variant="icon"
                      @click="downloadInvoice(invoice)">
                      <v-icon class="text-normal" icon="download" />
                    </v-button>

                    <v-button :disabled="disableArchiveButton" class="btn-action" size="xs" variant="icon"
                      @click="archiveInvoice(invoice)">
                      <v-icon icon="trash" />
                    </v-button>
                  </div>
                </div>
              </template>

              <div v-else>
                {{ $t('clientTour.invoice.noInvoicesEmitted') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <the-case-invoice-emitted-details-modal v-if="modalToShow === 'the-case-invoice-emitted-details-modal'"
      :agency="agency" :client-tour="clientTour" :contactable-type="'agency'" :invoice-id="currentInvoice.id"
      :tour="tour.id" @closed="closeModal(); currentInvoice = null" @reload-invoices="loadTourInvoices" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { DateTime } from 'luxon';
import axios from 'axios';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import Tour from '@/api/objects/Tour';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import Invoice from '@/api/objects/Invoice';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useModalUtils from '@/helpers/ModalUtils';
import TheCaseInvoiceEmittedDetailsModal from '@/components/case/parts/TheCaseInvoiceEmittedDetailsModal.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';

export default defineComponent({
  name: 'TheCaseSetupInvoicesEmitted',
  components: {
    VLoader,
    TheCaseInvoiceEmittedDetailsModal,
    VTableBody,
    VTableHeader,
    VTable,
    VIcon,
    VButton,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
    clientTour: {
      type: Number,
      required: true,
    },
    
  },
  setup() {
    return {
      ...useModalUtils(),
      DateTime,
    };
  },
  data() {
    return {
      disableButton: false as boolean,
      invoices: [] as Invoice[],
      currentInvoice: null as Invoice | null,
      invoicesLoaded: false as boolean,
      disableArchiveButton: false as boolean,
      disableDownloadButton: false as boolean,
    };
  },
  computed: {
    domain() {
      return import.meta.env.VITE_APP_API_URL;
    },
  },
  async created() {
    await this.loadTourInvoices();
  },
  methods: {
    async loadTourInvoices() {
      this.invoicesLoaded = false;

      this.invoices = await api.invoice.all(this.agency,
        this.clientTour, this.tour.id);

      this.invoicesLoaded = true;
    },
    showInvoiceDetails(invoice: Invoice) {
      this.currentInvoice = invoice;

      if (this.modalToShow !== 'the-case-invoice-emitted-details-modal') {
        this.openModal('the-case-invoice-emitted-details-modal');
      }
    },
    async archiveInvoice(invoice: Invoice) {
      await this.$modal.delete({
        title: this.$t('clientTour.invoice.deleteTitle'),
        text: this.$t('clientTour.invoice.deleteText', {
          invoice: invoice.number,
        }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doArchiveInvoice(invoice.id),
      });
    },
    async doArchiveInvoice(invoiceId: string | number) {
      try {
        this.disableArchiveButton = true;

        await api.invoice.archive(this.agency,
          this.clientTour, this.tour.id, invoiceId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.loadTourInvoices();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableArchiveButton = false;
      }
    },
    async downloadInvoice(invoice: Invoice) {
      try {
        this.disableDownloadButton = true;

        const slug = this.agency;

        const response = await axios({
          url: `/api/${slug}/case/${this.clientTour}/tour/${this.tour.id}/invoice/${invoice.id}/download`,
          baseURL: this.domain,
          responseType: 'blob',
          withCredentials: true,
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoice.number}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableDownloadButton = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.tour-invoices-emitted-container {
  .btn-action {
    padding: .125rem;
  }

  th {
    font-size: 1rem;
  }

  .invoice-icon {
    font-size: 3rem;
  }

  .image-container {
    margin-right: .5rem;

    :deep(img) {
      height: 100%;
    }
  }
}
</style>
