import Concept from '@/api/objects/Concept';
import { useAppStore } from '@/stores/app';

const mapConcepts = (concepts: Concept[], sort = false) => {
  let tmp = Object.keys(concepts).length > 0 ? concepts.map((concept: Concept) => ({
    key: concept.key,
    label: concept.name,
    permission: concept.permission || null,
    value: concept.value || null,
    order: concept.order,
    icon: concept.icon,
    color: concept.color,
    status: concept.status,
    shortName: concept.shortName,
  })) : [];

  if (sort) {
    tmp = tmp.sort((a: any, b: any) => a.key - b.key);
  }

  return tmp;
};

const sortConcepts = (concepts: Concept[], field = 'order') => concepts.sort((a: any, b: any) => (+a[field] - +b[field]));

const findConceptByKey = (conceptName: string, conceptKey: string): Concept | string => {
  const appStore = useAppStore();

  if (!!appStore.concepts && !!appStore.concepts[conceptName]) {
    const conceptFound = appStore.concepts[conceptName].find((typeConcept: Concept) => typeConcept.key === conceptKey);

    if (!!conceptFound) {
      return conceptFound;
    }
  }

  return '';
};

export default {
  mapConcepts,
  sortConcepts,
  findConceptByKey,
};

export { mapConcepts, sortConcepts, findConceptByKey };
