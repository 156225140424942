<template>
  <div>
    <fieldset v-if="language === currentLanguage">
      <legend class="visually-hidden">{{ $t("supplier.basicInformation") }}</legend>

      <v-form-input
        id="supplier-create-name"
        v-model="nameLocal"
        :disabled="disabledFields"
        :label="`${$t('general.shared.name')}*`"
        :yup-errors-variable="errors.nameLocal"
        autocomplete="off"
        form-type="outline"
        @input="$emit('update:name', nameLocal)"
      />

      <v-form
        id="supplier-create-type-select"
        :disabled="disabledFields"
        :label="`${$t('general.shared.type')}*`"
        :yup-errors-variable="errors.typeLocal"
        form-type="outline"
      >
        <v-select
          v-model="typeLocal"
          :class="{ 'is-invalid': !!errors.typeLocal }"
          :disabled="disabledFields"
          :options="supplierTypes"
          :reduce="(returnValue) => returnValue.key"
          @option:selected="$emit('update:type', typeLocal)"
          @option:deselected="$emit('update:type', typeLocal)"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
          </template>
        </v-select>
      </v-form>
      <div class="form-outline">
        <label :class="{ disabled: disabledFields }" class="label">
          <span>Service</span>
        </label>
        <div class="form-control">
          <div class="form-outline room-section" v-if="typeLocal === 'hotel'">
            <div class="w-100 pt-8 pb-8 d-flex justify-content-start">
              <v-button class="btn-icon p-1 rounded-circle" size="sm" variant="gradient" @click="toggleCheckboxes">
                <template v-if="showCheckboxes">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    width="256"
                    height="256"
                    viewBox="0 0 256 256"
                    xml:space="preserve"
                  >
                    <defs></defs>
                    <g
                      style="
                        stroke: none;
                        stroke-width: 0;
                        stroke-dasharray: none;
                        stroke-linecap: butt;
                        stroke-linejoin: miter;
                        stroke-miterlimit: 10;
                        fill: none;
                        fill-rule: nonzero;
                        opacity: 1;
                      "
                      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                    >
                      <path
                        d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                        style="
                          stroke: none;
                          stroke-width: 1;
                          stroke-dasharray: none;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          fill: rgb(29, 29, 27);
                          fill-rule: nonzero;
                          opacity: 1;
                        "
                        transform=" matrix(1 0 0 1 0 0) "
                        stroke-linecap="round"
                      />
                      <path
                        d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                        style="
                          stroke: none;
                          stroke-width: 1;
                          stroke-dasharray: none;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          fill: rgb(29, 29, 27);
                          fill-rule: nonzero;
                          opacity: 1;
                        "
                        transform=" matrix(1 0 0 1 0 0) "
                        stroke-linecap="round"
                      />
                    </g>
                  </svg>
                </template>
                <template v-else>
                  <v-icon icon="plus" />
                </template>
              </v-button>
            </div>
            <template v-if="showCheckboxes">
              <div
                class="d-flex align-items-center justify-content-between create-supplier"
                v-for="roomType in roomTypes"
                :key="roomType.id"
              >
                <v-checkbox
                  :id="roomType.id"
                  class="flex-grow-1"
                  :label="$t(roomType.label)"
                  :v-model="isSelected(roomType)"
                  :modelValue="isSelected(roomType)"
                  :isDisabled="isDisabled(roomType)"
                  @change="selectedRoom(roomType)"
                />
                <template v-if="!isDisabled(roomType)">
                  <v-form-input
                    id="supplier-room-text"
                    :v-model="
                      roomType.freeText
                    "
                    :modelValue="
                      roomType.freeText
                    "
                    placeholder="text"
                    autocomplete="off"
                    form-type="outline"
                    class="text-start"
                    @change="handleChange(roomType, $event.target.value, 'freeText')"
                  />
                  <v-form-input
                    id="supplier-room-price"
                    class="text-start"
                    type="number"
                    :v-model="isSelectedPrice(roomType) !== undefined ? isSelectedPrice(roomType) : roomType.price"
                    :modelValue="isSelectedPrice(roomType) !== undefined ? isSelectedPrice(roomType) : roomType.price"
                    autocomplete="off"
                    placeholder="€"
                    onwheel="return false;"
                    @change="handleChange(roomType, $event.target.value, 'price')"
                  />
                </template>
              </div>
            </template>
          </div>
          <div class="form-outline room-section" v-if="typeLocal === 'refuge'">
            <div class="w-100 pt-8 pb-8 d-flex justify-content-start">
              <v-button class="btn-icon p-1 rounded-circle" size="sm" variant="gradient" @click="toggleCheckboxes">
                <template v-if="showCheckboxes">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    width="256"
                    height="256"
                    viewBox="0 0 256 256"
                    xml:space="preserve"
                  >
                    <defs></defs>
                    <g
                      style="
                        stroke: none;
                        stroke-width: 0;
                        stroke-dasharray: none;
                        stroke-linecap: butt;
                        stroke-linejoin: miter;
                        stroke-miterlimit: 10;
                        fill: none;
                        fill-rule: nonzero;
                        opacity: 1;
                      "
                      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                    >
                      <path
                        d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                        style="
                          stroke: none;
                          stroke-width: 1;
                          stroke-dasharray: none;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          fill: rgb(29, 29, 27);
                          fill-rule: nonzero;
                          opacity: 1;
                        "
                        transform=" matrix(1 0 0 1 0 0) "
                        stroke-linecap="round"
                      />
                      <path
                        d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                        style="
                          stroke: none;
                          stroke-width: 1;
                          stroke-dasharray: none;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          fill: rgb(29, 29, 27);
                          fill-rule: nonzero;
                          opacity: 1;
                        "
                        transform=" matrix(1 0 0 1 0 0) "
                        stroke-linecap="round"
                      />
                    </g>
                  </svg>
                </template>
                <template v-else>
                  <v-icon icon="plus" />
                </template>
              </v-button>
            </div>
            <template v-if="showCheckboxes">
              <div
                class="d-flex align-items-center justify-content-between create-supplier"
                v-for="roomType in refugeRoomType"
                :key="roomType.id"
              >
                <v-checkbox
                  :id="roomType.id"
                  class="flex-grow-1"
                  :label="$t(roomType.label)"
                  :v-model="isSelected(roomType)"
                  :modelValue="isSelected(roomType)"
                  :isDisabled="isDisabled(roomType)"
                  @change="selectedRoom(roomType)"
                />
                <template v-if="!isDisabled(roomType)">
                  <v-form-input
                    id="supplier-room-text"
                    :v-model="
                      roomType.freeText
                    "
                    :modelValue="
                      roomType.freeText
                    "
                    placeholder="text"
                    autocomplete="off"
                    form-type="outline"
                    class="text-start"
                    @change="handleChange(roomType, $event.target.value, 'freeText')"
                  />
                  <v-form-input
                    id="supplier-room-price"
                    class="text-start"
                    type="number"
                    :v-model="isSelectedPrice(roomType) !== undefined ? isSelectedPrice(roomType) : roomType.price"
                    :modelValue="isSelectedPrice(roomType) !== undefined ? isSelectedPrice(roomType) : roomType.price"
                    autocomplete="off"
                    placeholder="€"
                    onwheel="return false;"
                    @change="handleChange(roomType, $event.target.value, 'price')"
                  />
                </template>
              </div>
            </template>
          </div>
          <div
            class="form-outline room-section"
            v-if="
              typeLocal === 'transfer' ||
              typeLocal === 'transport' ||
              typeLocal === 'train' ||
              typeLocal === 'bus' ||
              typeLocal === 'boat'
            "
          >
            <div
              class="d-flex align-items-center justify-content-between create-supplier"
              v-for="roomType in updateModule === 'update' ? getRoomTypes(typeLocal).slice(1) : getRoomTypes(typeLocal)"
              :key="roomType.id"
            >
              <v-checkbox
                :id="roomType.id"
                class="flex-grow-1"
                :label="$t(roomType.label)"
                :v-model="isTransportSelected(roomType)"
                :modelValue="isTransportSelected(roomType)"
                @change="selectedTransport(roomType)"
              />
              <v-form-input
                id="supplier-room-text"
                v-model="roomType.freeText"
                placeholder="text"
                autocomplete="off"
                form-type="outline"
                class="text-start"
                @change="handleTransportChange(roomType)"
              />
              <v-form-input
                id="supplier-room-price"
                v-model="roomType.price"
                placeholder="€"
                autocomplete="off"
                form-type="outline"
                class="text-start"
                type="number"
                onwheel="return false;"
                @change="handleTransportChange(roomType)"
              />
            </div>
            <div class="w-100 d-flex justify-content-start">
              <v-button
                class="btn-icon p-1 rounded-circle"
                size="sm"
                variant="gradient"
                @click="duplicateFields(typeLocal)"
              >
                <v-icon icon="plus" />
              </v-button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-outline">
        <label :class="{ disabled: disabledFields }" class="label">
          <span>{{ $t("general.shared.description") }}</span>
        </label>

        <v-html-editor
          id="supplier-create-description"
          ref="supplier-create-description-ref"
          :disabled="disabledFields"
          :value="descriptionLocal"
          @input="
            descriptionLocal = $event;
            $emit('update:description', descriptionLocal);
          "
          @media-uploaded="$emit('mediaUploaded', $event)"
        />
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as yup from "yup";
import { useField } from "vee-validate";
import { VForm, VFormInput, VCheckbox } from "@uniqoders/form";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import { useI18n } from "vue-i18n";
import vSelect from "vue-select";
import useFormValidation from "@/helpers/form";
import useConcepts from "@/helpers/Concepts";
import { mapConcepts } from "@/helpers/ConceptHelper";
import VHtmlEditor from "@/components/vendor/htmlEditor/VHtmlEditor.vue";
import { v4 as uuidv4 } from "uuid";

export type RoomDataType = {
  id?: string;
  label?: string;
  value?: string;
  roomName?: string;
  freeText?: string | number;
  price?: string | number;
};

export default defineComponent({
  name: "TheSupplierCreateBasicInformationFieldset",
  components: {
    VFormInput,
    VForm,
    vSelect,
    VHtmlEditor,
    VCheckbox,
    VButton,
    VIcon,
  },      
  emits: ["closed", "reloadResources", "checkButtonUpdate", "update:name", "update:type", "update:description", "isValid", "mediaUploaded"],
  props: {
    name: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    disabledFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    language: {
      type: String,
      required: true,
      default: "",
    },
    currentLanguage: {
      type: String,
      required: true,
      default: "",
    },
    clear: {
      type: Boolean,
      default: false,
    },
    selectedDefaultResources: {
      type: Array,
      default: () => [],
      required: false,
    },
    selectedResourcesData: {
      type: Array,
      default: () => [],
      required: false,
    },
    updateModule: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      nameLocal: yup.string().required().label(t("general.shared.name")),
      typeLocal: yup.string().required().nullable().label(t("general.shared.type")),
      descriptionLocal: yup.string().nullable().label(t("general.shared.description")),
    });

    const initialValues = {
      descriptionLocal: props.description,
      nameLocal: props.name,
      typeLocal: props.type,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: descriptionLocal } = useField("descriptionLocal");
    const { value: nameLocal } = useField("nameLocal");
    const { value: typeLocal } = useField("typeLocal");

    const roomTypes = [
      {
        id: "singleRoom",
        label: "supplier.singleRoom",
        value: "single_room",
        roomName: "supplier.singleRoomFullName",
        freeText: "",
        price: "",
      },
      {
        id: "doubleRoom",
        label: "supplier.doubleRoom",
        value: "double_room",
        roomName: "supplier.doubleRoomFullName",
        freeText: "",
        price: "",
      },
      {
        id: "doubleTwinRoom",
        label: "supplier.doubleTwinRoom",
        value: "double_twin_room",
        roomName: "supplier.doubleTwinRoomFullName",
        freeText: "",
        price: "",
      },
      {
        id: "tripleRoom",
        label: "supplier.tripleRoom",
        value: "triple_room",
        roomName: "supplier.tripleRoomFullName",
        freeText: "",
        price: "",
      },
    ];

    const refugeRoomType = [
      {
        id: "bunkbedHBRoom",
        label: "supplier.bunkbedHBRoom",
        value: "bunkbed_hb_room",
        roomName: "supplier.bunkbedHBRoomFullName",
        freeText: "",
        price: "",
      },
      {
        id: "bunkbedFBRoom",
        label: "supplier.bunkbedFBRoom",
        value: "bunkbed_fb_room",
        roomName: "supplier.bunkbedFBRoomFullName",
        freeText: "",
        price: "",
      },
    ];

    const transferType = [
      {
        id: uuidv4(),
        label: "supplier.transfer",
        value: "transfer",
        roomName: "supplier.transfer",
        freeText: "",
        price: "",
      },
    ];

    const transportType = [
      {
        id: uuidv4(),
        label: "supplier.luggageTransport",
        value: "luggage_transport",
        roomName: "supplier.luggageTransport",
        freeText: "",
        price: "",
      },
    ];

    const busType = [
      {
        id: uuidv4(),
        label: "supplier.busTicket",
        value: "bus_ticket",
        roomName: "supplier.busTicket",
        freeText: "",
        price: "",
      },
    ];

    const boatType = [
      {
        id: uuidv4(),
        label: "supplier.boatTicket",
        value: "boat_ticket",
        roomName: "supplier.boatTicket",
        freeText: "",
        price: "",
      },
    ];

    const trainType = [
      {
        id: uuidv4(),
        label: "supplier.trainTicket",
        value: "train_ticket",
        roomName: "supplier.trainTicket",
        freeText: "",
        price: "",
      },
    ];

    return {
      ...useConcepts(),
      ...form,
      descriptionLocal,
      nameLocal,
      typeLocal,
      roomTypes,
      refugeRoomType,
      transportType,
      busType,
      boatType,
      trainType,
      transferType,
      initialValues
    };
  },
  data() {
    return {
      showCheckboxes: false as boolean,
    };
  },
  computed: {
    isValid() {
      return this.meta.valid && this.meta.dirty;
    },
    supplierTypes(): any {
      if (!!this.concepts && this.concepts["supplier.type"]) {
        return mapConcepts(this.concepts["supplier.type"]);
      }

      return [];
    },
  },
  watch: {
    name(value) {
      if (value !== this.initialValues.nameLocal) {
        this.$emit('checkButtonUpdate', true);
      } else {
        this.$emit('checkButtonUpdate', false);
      }   
      this.nameLocal = value;
    },
    type(value) {
      if (this.selectedDefaultResources.length > 0) {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedDefaultResources.pop();
      }
      if (value !== this.initialValues.typeLocal) {
        this.$emit('checkButtonUpdate', true);
      } else {
        this.$emit('checkButtonUpdate', false);
      }   
      this.typeLocal = value;
    },
    description(value) {
      const data = value === '' ? null : value;            
      if (data !== this.initialValues.descriptionLocal) {
        this.$emit('checkButtonUpdate', true);
      } else {
        this.$emit('checkButtonUpdate', false);
      }
      this.descriptionLocal = value;
    },
    clear() {
      this.resetForm();
    },
    isValid(value) {
      this.$emit("isValid", value);
    },
  },
  methods: {
    toggleCheckboxes() {
      this.showCheckboxes = !this.showCheckboxes;
    },
    getRoomTypes(type: string) {
      switch (type) {
        case "transfer":
          return this.transferType;
        case "transport":
          return this.transportType;
        case "train":
          return this.trainType;
        case "bus":
          return this.busType;
        case "boat":
          return this.boatType;
        default:
          return [];
      }
    },
    isDisabled(roomType: RoomDataType) {
      return this.selectedResourcesData?.length !== 0
        ? this.selectedResourcesData?.some((e) => e.name.includes(this.$t(roomType.roomName)))
        : false;
    },
    isSelected(roomData: RoomDataType) {
      const isSelected = this.selectedResourcesData?.some((e) => e.name.includes(this.$t(roomData.roomName)));

      const createTimeRoomData = this.selectedDefaultResources?.some((e) => e.room.includes(roomData.value));

      const finalData =
        this.selectedResourcesData?.length !== 0 ? (isSelected ? isSelected : createTimeRoomData) : createTimeRoomData;
      return finalData;
    },
    isSelectedFreeText(roomType: RoomDataType) {
      const data = this.selectedDefaultResources.find((e) => e.room === roomType.value && e.freeText);
      const result = data?.freeText;
      return result;
    },
    isSelectedDefaultPrice(roomData: RoomDataType) {
      const isSelectedPriceData = this.selectedResourcesData?.filter((e) =>
        e.name.includes(this.$t(roomData.roomName))
      );

      const roomPrice = isSelectedPriceData && isSelectedPriceData[0] && isSelectedPriceData[0][0];
      // this will use when update the price for existing product
      // roomData.id = roomPrice?.id

      const selectedRoomPrice = roomPrice?.price || null;

      return selectedRoomPrice;
    },
    isSelectedPrice(roomType: RoomDataType) {
      const data = this.selectedDefaultResources.find((e) => e.room === roomType.value && e.price);
      const result = data?.price;
      const defaultPrice = this.isSelectedDefaultPrice(roomType);

      return defaultPrice !== null ? defaultPrice : result;
    },
    selectedRoom(roomType: RoomDataType) {
      const index = this.selectedDefaultResources.findIndex((item: any) => item.room === roomType.value);
      if (index === -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedDefaultResources.push({
          room: roomType.value,
          freeText: roomType.freeText,
          price: roomType.price,
        });
        this.$emit('checkButtonUpdate', true);
      } else {
        roomType.freeText = "";
        roomType.price = "";
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedDefaultResources.splice(index, 1);
        this.$emit('checkButtonUpdate', false);
      }
    },
    handleChange(roomType: RoomDataType, newValue?: string | number, inputType?: string) {
      const index = this.selectedDefaultResources.findIndex((item) => item.room === roomType.value);
      if (inputType === "freeText") {
        roomType.freeText = newValue;
      } else if (inputType === "price") {
        roomType.price = newValue;
      }

      if (index === -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedDefaultResources.push({
          // this will use when update the price for existing product
          // price_id: roomType.id,
          room: roomType.value,
          freeText: roomType.freeText,
          price: roomType.price,
        });
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedDefaultResources[index] = {
          // this will use when update the price for existing product
          // price_id: roomType.id,
          room: roomType.value,
          freeText: roomType.freeText,
          price: roomType.price,
        };
      }
      this.$emit('checkButtonUpdate', true);
    },
    isTransportSelected(roomType: RoomDataType) {
      const isSelected = this.selectedResourcesData?.some((e) => e.id === roomType?.id);

      const createTimeRoomData = this.selectedDefaultResources?.some((e) => e.id === roomType?.id);
      const finalData =
        this.selectedResourcesData?.length !== 0 ? (isSelected ? isSelected : createTimeRoomData) : createTimeRoomData;
      this.$emit('checkButtonUpdate', true);
        return finalData;
    },
    handleTransportChange(roomType: RoomDataType) {
      const index = this.selectedDefaultResources.findIndex((element) => element?.id === roomType.id);
      if (index === -1) {
        const newTransportData = {
          id: roomType.id,
          room: roomType.value,
          freeText: `${this.$t(roomType.label)} ${roomType.freeText}`,
          price: roomType.price,
        };

        // eslint-disable-next-line vue/no-mutating-props
        this.selectedDefaultResources.push(newTransportData);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedDefaultResources[index] = {
          id: roomType.id,
          room: roomType.value,
          freeText: `${this.$t(roomType.label)} ${roomType.freeText}`,
          price: roomType.price,
        };
      }
      this.$emit('checkButtonUpdate', true);
    },
    selectedTransport(data: RoomDataType) {
      const index = this.selectedDefaultResources.findIndex((element) => element?.id === data?.id);

      if (index === -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedDefaultResources.push({
          id: data?.id,
          room: data?.value,
          freeText: `${this.$t(data?.label)} ${data?.freeText}`,
          price: data?.price,
        });
        this.$emit('checkButtonUpdate', true);
      } else {
        data.freeText = "";
        data.price = "";
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedDefaultResources.splice(index, 1);
        this.$emit('checkButtonUpdate', false);
      }
    },
    duplicateFields(type: string) {
      const currentType = this.getRoomTypes(type);

      if (currentType.length > 0) {
        const duplicatedRoomType = JSON.parse(JSON.stringify(currentType[0]));

        duplicatedRoomType.id = uuidv4();
        duplicatedRoomType.freeText = "";
        duplicatedRoomType.price = "";

        currentType.push(duplicatedRoomType);

        this.$forceUpdate();
      }
    },
  },
});
</script>

<style>
.form-outline.room-section .checkbox input[type="checkbox"]:disabled + .checkbox-label:before {
  background-color: #d7df23 !important;
  opacity: 1 !important;
}

.form-outline.room-section .checkbox .checkbox-label:after {
  opacity: 1 !important;
}

.form-outline.room-section #supplier-room-text {
  padding: 0.5rem;
  min-height: 0;
  width: 10rem;
  margin-right: 1rem;
  height: 2rem;
}

.form-outline.room-section #supplier-room-price {
  padding: 0.5rem;
  min-height: 0;
  width: 4rem;
  height: 2rem;
}

.create-supplier {
  margin-bottom: 1rem;
}

.form-outline.room-section .rounded-circle {
  height: 1.1rem;
  width: 1.1rem;
}

.form-outline.room-section .icon-plus {
  font-size: 0.6rem;
  margin-top: 1.1px;
}

.form-outline.room-section #supplier-room-price input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form-outline.room-section #supplier-room-price input[type="number"] {
  -moz-appearance: textfield;
}
</style>import { emit } from "process";

