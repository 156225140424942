<template>
  <v-modal
    id="supplier-media-edit"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('supplier.media') }}
      </modal-title>
    </modal-header>

    <modal-body>
      <div class="supplier-media-container">
        <div class="mb-16">
          <v-uploader
            ref="uploader"
            :afterUpload="onChange"
            route-prefix="api/s3"
            @removed="onChange"
          />
        </div>

        <div v-if="media.length > 0" class="d-flex justify-content-end">
          <v-button
            class="btn-icon w-fit"
            size="sm"
            variant="primary"
            @click="saveSupplierMedia"
          >
            {{ $t('general.button.save') }}
            <v-icon icon="arrow-right" size="sm" space="ms-12"/>
          </v-button>
        </div>
      </div>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import useConcepts from '@/helpers/Concepts';
import useModalUtils from '@/helpers/ModalUtils';
import api from '@/api';
import VUploader from '@/components/vendor/upload/VUploader.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import Supplier from '@/api/objects/Supplier';

export default defineComponent({
  name: 'TheSupplierDetailsMediaModal',
  components: {
    VUploader,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    VIcon,
  },
  emits: ['closed', 'emitReloadSupplier'],
  props: {
    supplier: {
      type: Supplier,
      required: true,
    },
  },
  setup() {
    return {
      ...useConcepts(),
      ...useModalUtils(),
    };
  },
  data() {
    return {
      media: [] as any,
    };
  },
  computed: {
    mediaUuids() {
      return this.media.map((media: any) => ({
        uuid: media.attributes.uuid,
        name: media.attributes.name,
      }));
    },
  },
  methods: {
    onChange() {
      const { uploader }: any = this.$refs;
      if (!!uploader) {
        this.media = uploader.mediaLibrary.state.media;
      }
    },
    async saveSupplierMedia() {
      try {
        const data: any = {
          name: this.supplier.name,
          type: this.supplier.type,
          amenities: JSON.parse(this.supplier.amenities),
          phone: this.supplier.phone,
          email: this.supplier.email,
          type_email: this.supplier.typeEmail,
          payment_method: this.supplier.paymentMethod,
          account: this.supplier.account,
          notes: this.supplier.notes,
          location_id: this.supplier.locationId,
          contact_address_1: this.supplier.contactAddress1,
          contact_address_2: this.supplier.contactAddress2,
          contact_postal_code: this.supplier.contactPostalCode,
          contact_country: this.supplier.contactCountry,
          contact_city: this.supplier.contactCity,
          contact_state: this.supplier.contactState,
          lat: this.supplier.lat,
          lng: this.supplier.lng,
          price_range: this.supplier.priceRange,
          translations: this.supplier.translations,
          map_url: this.supplier.mapUrl,
          site_url: this.supplier.siteUrl,
          preferred_language: this.supplier.preferredLanguage,
          images: this.mediaUuids,
        };

        await api.supplier.update(this.supplier.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('emitReloadSupplier');
        this.$emit('closed');

        this.media = [];

        const { uploader }: any = this.$refs;

        if (!!uploader) {
          uploader.mediaLibrary.state.media = [];
        }
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
