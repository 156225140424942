<template>
  <v-modal
    id="client-create-modal"
    class-name="modal-fullscreen-sm-down"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('client.createNewClient') }}
      </modal-title>
    </modal-header>

    <modal-body>
      <form
        id="case-client-create-form"
        class="d-flex flex-column justify-content-between h-100"
      >
        <fieldset>
          <legend class="visually-hidden">{{ $t('client.basicInformation') }}</legend>

          <v-form-input
            id="case-client-create-name"
            v-model="name"
            :label="`${$t('general.shared.name')}*`"
            :yup-errors-variable="errors.name"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
          />

          <v-form-input
            id="case-client-create-surname"
            v-model="surname"
            :label="$t('general.shared.surname')"
            :yup-errors-variable="errors.surname"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
          />

          <v-form-input
            id="case-client-create-email"
            v-model="email"
            :label="`${$t('general.shared.email')}*`"
            :yup-errors-variable="errors.email"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
            type="email"
          />

          <v-form
            id="client-create-country-code"
            :label="$t('general.shared.countryCode')"
            :yup-errors-variable="errors.countryCode"
            class="mb-8"
            form-type="outline"
          >
            <v-select
              key="value"
              v-model="countryCode"
              :class="{ 'is-invalid': !!errors.countryCode }"
              :clearable="true"
              :options="countryCodes"
              :placeholder="$t('general.shared.countryCodePlaceholder')"
              :reduce="prefix => prefix.value"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>

          <v-form-input
            id="client-create-contact-phone"
            v-model="phone"
            :label="$t('general.shared.phone')"
            :yup-errors-variable="errors.phone"
            autocomplete="off"
            class="mb-8"
            form-type="outline"
          />

          <v-form
            id="client-create-preferred-language"
            :label="$t('general.shared.preferredLanguage')"
            :yup-errors-variable="errors.preferredLanguage"
            class="mb-8"
            form-type="outline"
          >
            <v-select
              v-model="preferredLanguage"
              :class="{ 'is-invalid': !!errors.preferredLanguage }"
              :options="languages"
              :reduce="returnValue => returnValue.key"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>

          <v-form
            id="case-client-create-type"
            :label="`${$t('general.shared.type')}*`"
            :yup-errors-variable="errors.source"
            class="mb-8"
            form-type="outline"
          >
            <v-select
              v-model="source"
              :clearable="false"
              :options="sourceTypes"
              :reduce="returnValue => returnValue.key"
              class="mb-8"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
              </template>
            </v-select>
          </v-form>
        </fieldset>

        <v-contact-billing-form
          id="case-client-create-address-form"
          ref="case-client-create-address-form-ref"
          :data="addressData"
          id-prefix="client-create"
          @updated-data="addressData = $event"
          @is-valid="addressValid = $event"
        />

        <v-button
          :disabled="!meta.valid || !addressValid || accepted"
          :is-loading="accepted"
          class="btn-submit-form btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="createClient"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </form>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { VForm, VFormInput } from '@uniqoders/form';
import vSelect from 'vue-select';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import useFormValidation from '@/helpers/form';
import useConcepts from '@/helpers/Concepts';
import api from '@/api';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { mapConcepts, sortConcepts } from '@/helpers/ConceptHelper';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';

export default defineComponent({
  name: 'VClientCreateModal',
  components: {
    VContactBillingForm,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VFormInput,
    vSelect,
    VButton,
    VIcon,
    VForm,
  },
  emits: [
    'closed',
    'client',
  ],
  props: {
    agency: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      surname: yup.string()
        .nullable()
        .label(t('general.shared.surname')),
      email: yup.string()
        .email()
        .required()
        .label(t('general.shared.email')),
      countryCode: yup.string()
        .nullable()
        .label(t('general.shared.countryCode')),
      phone: yup.string()
        .nullable()
        .label(t('general.shared.phone')),
      preferredLanguage: yup.string()
        .nullable()
        .label(t('general.shared.preferredLanguage')),
      source: yup.string()
        .nullable()
        .label(t('general.shared.type')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: surname } = useField('surname');
    const { value: email } = useField('email');
    const { value: countryCode } = useField('countryCode');
    const { value: phone } = useField('phone');
    const { value: preferredLanguage } = useField('preferredLanguage');
    const { value: source } = useField('source');

    return {
      ...useConcepts(),
      ...form,
      name,
      surname,
      email,
      countryCode,
      phone,
      preferredLanguage,
      source,
    };
  },
  data() {
    return {
      addressData: {} as any,
      addressValid: false as boolean,
    };
  },
  computed: {
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    languages(): any {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
    sourceTypes(): any {
      if (!!this.concepts && this.concepts['client.source_type']) {
        return mapConcepts(this.concepts['client.source_type']);
      }

      return [];
    },
    countryCodes(): any {
      if (!!this.concepts && this.concepts['app.countryPhones']) {
        return mapConcepts(sortConcepts(this.concepts['app.countryPhones']));
      }

      return [];
    },
  },
  methods: {
    async createClient() {
      try {
        this.toggleAccepted();

        let phoneTmp = '';

        if (!!this.countryCode && !!this.phone) {
          phoneTmp = `${this.countryCode} ${this.phone.toString().replace(/\s/g, '')}`;
        }

        const data: any = {
          name: this.name,
          surname: this.surname,
          email: this.email,
          source: this.source,
          phone: phoneTmp,
          location_id: this.addressData.locationId,
          contact_address_1: this.addressData.contactAddress1,
          contact_address_2: this.addressData.contactAddress2,
          contact_postal_code: this.addressData.contactPostalCode,
          contact_country: this.addressData.contactCountry,
          contact_city: this.addressData.contactCity,
          contact_state: this.addressData.contactState,
          billing_address_1: this.addressData.billingAddress1,
          billing_address_2: this.addressData.billingAddress2,
          billing_postal_code: this.addressData.billingPostalCode,
          billing_country: this.addressData.billingCountry,
          billing_city: this.addressData.billingCity,
          billing_state: this.addressData.billingState,
          preferred_language: !!this.preferredLanguage ? this.preferredLanguage : this.defaultLocale,
        };

        const response = await api.client.create(this.agency, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('client', response);
        this.$emit('closed');

        this.resetForm();

        (this.$refs['case-client-create-address-form-ref'] as any).clearForm();

        this.toggleAccepted();
      } catch (e: any) {
        this.toggleAccepted();

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
