import { ref } from 'vue';

const useOffCanvasUtils = () => {
  const offCanvasToShow = ref('');

  const closeOffCanvas = () => {
    offCanvasToShow.value = '';
  };

  const openOffCanvas = (modal: string) => {
    offCanvasToShow.value = modal;
  };

  return {
    offCanvasToShow,
    closeOffCanvas,
    openOffCanvas,
  };
};

export default useOffCanvasUtils;
