<template>
  <ul id="main-menu" class="navbar-nav navbar-nav-list">
    <li
      class="menu-item nav-item"
      v-if="agency?.isCollaborator"
      >
      <router-link
        id="collaboratorDashboard"
        :aria-label="this.$t('general.menu.resources')"
        :to="{ name: 'collaboratorDashboard' }"
        class="nav-link"
        @click="closeMenu"
      >
        <v-icon class="text-secondary d-block d-lg-none" icon="send-love" size="xl" space="me-8"/>
        {{ $t('dashboard.dashboard') }}
      </router-link>
    </li>
    <li
      class="menu-item nav-item">
      <router-link
        id="cases"
        :aria-label="$t('general.menu.cases')"
        :to="{ name: 'case.index' }"
        class="nav-link"
        @click="closeMenu"
      >
        <v-icon class="text-secondary d-block d-lg-none" icon="compass" size="xl" space="me-8"/>
        {{ $t('general.menu.cases') }}
      </router-link>
    </li>

    <li
      v-if="!agency?.isCollaborator"
      class="menu-item nav-item">
      <router-link
        id="products"
        :aria-label="$t('general.menu.products')"
        :to="{ name: 'template.index' }"
        class="nav-link"
        @click="closeMenu"
      >
        <v-icon class="text-secondary d-block d-lg-none" icon="map" size="xl" space="me-8"/>
        {{ $t('general.menu.products') }}
      </router-link>
    </li>

    <li
      class="menu-item nav-item">
      <router-link
        id="clients"
        :aria-label="$t('general.menu.clients')"
        :to="{ name: 'client.index' }"
        class="nav-link"
        @click="closeMenu"
      >
        <v-icon class="text-secondary d-block d-lg-none" icon="group" size="xl" space="me-8"/>
        {{ $t('general.menu.clients') }}
      </router-link>
    </li>

    <li
      v-if="!agency?.isCollaborator"
      class="menu-item nav-item">
      <router-link
        id="suppliers"
        :aria-label="$t('general.menu.suppliers')"
        :to="{ name: 'supplier.index' }"
        class="nav-link"
        @click="closeMenu"
      >
        <v-icon class="text-secondary d-block d-lg-none" icon="briefcase" size="xl" space="me-8"/>
        {{ $t('general.menu.suppliers') }}
      </router-link>
    </li>

    <li
      v-if="!agency?.isCollaborator"
      class="menu-item nav-item">
      <router-link
        id="resources"
        :aria-label="$t('general.menu.resources')"
        :to="{ name: 'resource.index' }"
        class="nav-link"
        @click="closeMenu"
      >
        <v-icon class="text-secondary d-block d-lg-none" icon="send-love" size="xl" space="me-8"/>
        {{ $t('general.menu.resources') }}
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'TheMenu',
  components: {
    VIcon,
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    ...mapState(useAuthStore, ['agency']),
  },
  methods: {
    closeMenu() {
      const main: any = document.getElementById('main');
      const buttonClose: any = document.getElementById('btn-header-hamburger');

      if (!!buttonClose) {
        buttonClose.click();

        buttonClose.classList.remove('is-active');
        main.classList.remove('menu-open');
      }
    },
  },
});
</script>
