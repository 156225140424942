import { useRouter } from 'vue-router';
import { reactive, watch } from 'vue';

const usePagination = () => {
  const {
    currentRoute,
    replace,
  } = useRouter();
  const { query } = currentRoute.value;
  const { page } = query;

  const pagination = reactive({
    currentPage: !!page ? parseInt(query.toString(), 10) : 1,
    links: [],
    meta: {},
    data: [],
  });

  /**
   * Remove "page" from query string when
   * page is 1
   *
   * @param params
   * @returns {Promise<void>}
   */
  const replaceParams = async (params: any) => {
    if (!!params.page && parseInt(params.page, 10) === 1) {
      const tmp = { ...params };
      delete tmp.page;

      await replace({ query: tmp });
    }
  };

  replaceParams(query);
  watch(() => currentRoute.value.query, replaceParams);

  const setResponse = (response: any) => {
    pagination.links = response.links;
    pagination.meta = response.meta;
    pagination.data = response.data;
  };

  const changePage = async (newPage: any) => {
    await replace({
      query: {
        ...currentRoute.value.query,
        page: newPage,
      },
    });

    pagination.currentPage = parseInt(newPage, 10);
  };

  return {
    pagination,
    setResponse,
    changePage,
  };
};

export default usePagination;
