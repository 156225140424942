import { AbstractService } from '@uniqoders/sdk';
import CasesCount from '../objects/CasesCount';

export default class CollaboratorService extends AbstractService {
  /**
   * Retrieves cases info
   * @protected
   */
  public getCasesCount(collaborator: string): Promise<CasesCount> {
    return this.request({
      method: 'get',
      url: this.buildPath(`/${collaborator}/collaborator/dashboard`),
    });
  }
}