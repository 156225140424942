import { AbstractService } from '@uniqoders/sdk';
import Tour from '@/api/objects/Tour';
import TourDayItemResource from '@/api/objects/TourDayItemResource';

export default class TourPricingService extends AbstractService {
  /**
   * Retrieves all Tours Pricings
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param params
   */
  public all(
    agency: string,
    clientTour: number | string,
    tour: number | string,
    params?: any,
  ): Promise<TourDayItemResource[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/pricing', { agency, clientTour, tour }),
      params,
    });
  }

  /**
   * Updates the specified Tour Item Resource Group Pricing
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   * @protected
   */
  public updatePriceByGroup(
    agency: string,
    clientTour: number,
    tour: number,
    data: Record<string, any>,
  ): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/resource/pricing/update', {
        agency,
        clientTour,
        tour
      }),
      data,
    });
  }

  /**
   * Updates the specified Tour Pricing by Type
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   * @protected
   */
  public updateAllMarkupByType(
    agency: string,
    clientTour: number,
    tour: number,
    data: Record<string, any>,
  ): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/pricing/update/type', { agency, clientTour, tour }),
      data,
    });
  }

  /**
   * Updates the specified Tour Pricing
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   * @protected
   */
  public updateTourTotalPrices(
    agency: string,
    clientTour: number,
    tour: number,
    data: Record<string, any>,
  ): Promise<Tour> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/pricing/update', {
        agency,
        clientTour,
        tour,
      }),
      data,
    });
  }

  /**
   * Validates the specified Tour net price
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   * @protected
   */
  public validateNetPrice(
    agency: string,
    clientTour: number,
    tour: number,
    data: Record<string, any>,
  ): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/resource/validate/net', {
        agency,
        clientTour,
        tour,
      }),
      data,
    });
  }

  /**
   * API for the generate xml file
   * @param agency
   * @param clientTour
   * @param tour
   * @param resourceId
   * @protected
   */
  public generateXML(
    agency: string,
    clientTour: number,
    tour: number,
    resourceId: object,
  ): Promise<TourDayItemResource[]> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/generate-xml', {
        agency,
        clientTour,
        tour
      }),
      data: resourceId,
    });
  }


  /**
   *
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   * @protected
   */
  public updateAmountPaid(
    agency: string,
    clientTour: number,
    tour: number,
    data: Record<string, any>,
  ): Promise<any> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/resource/paid/update', {
        agency,
        clientTour,
        tour,
      }),
      data,
    });
  }
}
