<template>
  <div v-if="!!tour" class="case-setup-container">
    <div class="wrapper grid">
      <div class="grid left">
        <div id="accordionDetails" class="accordion">
          <div class="case-details accordion-item">
            <h2 id="headingDetails" class="accordion-header">
              <button aria-controls="collapseDetails" aria-expanded="true" class="accordion-button"
                data-bs-target="#collapseDetails" data-bs-toggle="collapse" type="button">
                <div class="mb-0 d-flex">
                  <div class="bg-secondary rounded-xs h-fit-content me-8">
                    <v-icon icon="pencil" size="xxl" space="p-12" />
                  </div>
                  <div>
                    <div class="h4 ff-secondary mb-0">{{ $t('clientTour.details') }}</div>
                    <div class="fw-light accordion-subtitle">{{ $t('clientTour.detailsSubtitle') }}</div>
                  </div>
                </div>
              </button>
            </h2>

            <div id="collapseDetails" aria-labelledby="headingDetails" class="accordion-collapse collapse show"
              data-bs-parent="#accordionDetails">
              <div class="accordion-body pb-32 pb-xl-48">
                <form id="case-update-form" class="d-flex flex-column justify-content-between h-100">
                  <fieldset>
                    <legend class="visually-hidden">{{ $t('tourClient.basicInformation') }}</legend>

                    <div class="text-sm mb-16">
                      <span class="fw-medium me-8">{{ $t('general.shared.client') }}:</span>
                      <button class="btn btn-link shadow-none text-sm p-0" type="button"
                        @click="openOffCanvas('the-client-details-offcanvas')">
                        {{ clientName }}
                      </button>
                    </div>

                    <v-select-user-case-search id="case-update-user-search" v-model="userId"  v-if="!clientTour.assignedTo.agency?.isCollaborator"
                      :agency="agency.slug" @update:model-value="assignUserToCase" />
                    <div class="text-sm mb-16" v-else>
                      <span class="fw-medium me-8">{{ $t('general.shared.caseManager') }}:</span>
                        <b>{{ clientTour.assignedTo.user?.name }} ({{ clientTour.assignedTo.agency?.name }})</b>
                    </div>

                    <div class="row">
                      <div class="col-12 col-xl-6">
                        <v-form id="case-update-init-date" :label="`${$t('general.shared.initDate')}*`"
                          :yup-errors-variable="errors.initDate" form-type="outline">
                          <Datepicker v-model="initDate" :day-class="getEndDayClass" :enable-time-picker="false"
                            :format="settings.formatDate" :highlight="highlightedDates"
                            :input-class-name="`form-control ${!!errors.initDate ? 'is-invalid' : ''}`"
                            :month-change-on-scroll="false" auto-apply calendar-cell-class-name="init-date-cell"
                            class="form-datepicker" @update:model-value="setHighlightedDates" />

                          <template #errors>
                            <span v-if="!!errors.initDate" class="invalid-feedback d-block">
                              {{ errors.initDate }}
                            </span>
                          </template>
                        </v-form>
                      </div>

                      <div class="col-12 col-xl-6">
                        <v-form id="case-update-end-date" :label="`${$t('general.shared.endDate')}*`"
                          :yup-errors-variable="errors.endDate" form-type="outline">
                          <Datepicker v-model="endDate" :day-class="getInitDayClass" :enable-time-picker="false"
                            :format="settings.formatDate" :highlight="highlightedDates"
                            :input-class-name="`form-control ${!!errors.endDate ? 'is-invalid' : ''}`"
                            :min-date="!!initDate ? initDate : null" :month-change-on-scroll="false"
                            :start-date="!!initDate ? initDate : null" auto-apply
                            calendar-cell-class-name="end-date-cell" class="form-datepicker" disabled
                            @update:model-value="setHighlightedDates" />

                          <template #errors>
                            <span v-if="!!errors.endDate" class="invalid-feedback d-block">
                              {{ errors.endDate }}
                            </span>
                          </template>
                        </v-form>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-xl-12">
                        <v-form id="case-update-alternate-start-date"
                          :label="`${$t('general.shared.alternativeStartDate')}`"
                          :yup-errors-variable="errors.alternateStartDate" form-type="outline">
                          <Datepicker v-model="alternateStartDate" :enable-time-picker="false"
                            :format="settings.formatDate"
                            :input-class-name="`form-control ${!!errors.alternateStartDate ? 'is-invalid' : ''}`"
                            :month-change-on-scroll="false" auto-apply :disabled="true"
                            calendar-cell-class-name="alternate-start-date-cell" class="form-datepicker" />

                          <template #errors>
                            <span v-if="!!errors.alternateStartDate" class="invalid-feedback d-block">
                              {{ errors.alternateStartDate }}
                            </span>
                          </template>
                        </v-form>
                      </div>
                    </div>
                    
                  </fieldset>

                  <v-button :disabled="!meta.valid || disableButton || !meta.dirty" :is-loading="accepted"
                    class="btn-icon w-fit align-self-end mt-24" size="sm" variant="primary"
                    @click="updateInitialTourDate">
                    {{ $t('general.button.save') }}
                    <v-icon icon="arrow-right" size="sm" space="ms-12" />
                  </v-button>
                </form>
                <div  class="search-separator-container pb-36 mb-16" v-if="clientTour.additionalRequirements">
                  <div class="separator-text-container">
                    <span class="separator-text text-sm">{{ $t('clientTour.additionalInfo') }}</span>
                  </div>
                </div>
                <fieldset v-if="clientTour.additionalRequirements">
                  <legend class="visually-hidden">{{ $t('clientTour.additionalRequirements') }}</legend>
                  <div class="form-outline">
                    <label class="label">
                      <span>{{ $t('clientTour.additionalRequirements') }}</span>
                    </label>

                    <div id="case-tour-other-room-text" class="html-editor-display" v-html="clientTour.additionalRequirements"></div>

                  </div>
                </fieldset>
                <fieldset v-if="clientTour.otherRoomRequirements">
                  <legend class="visually-hidden">{{ $t('clientTour.otherRoomRequirements') }}</legend>

                  <div class="form-outline">
                    <label class="label">
                      <span>{{ $t('clientTour.otherRoomRequirements') }}</span>
                    </label>

                    <div id="case-tour-other-room-text" class="html-editor-display" v-html="clientTour.otherRoomRequirements"></div>
                  </div>
                </fieldset>

                <div class="search-separator-container pb-36 mb-16">
                  <div class="separator-text-container">
                    <span class="separator-text text-sm">{{ $t('clientTour.tourBasicInformation') }}</span>
                  </div>
                </div>

                <the-case-setup-tour-details :agency="agency.slug" :client-tour-id="Number(clientTour.id)" :tour="tour"
                  @reload-client-tour="$emit('reloadClientTour')" />
              </div>
            </div>
          </div>
        </div>

        <the-case-setup-tour-contacts :agency="agency.slug" :case-id="Number(caseId)" :tour="tour"
          @reload-client-tour="$emit('reloadClientTour')" />

        <the-case-setup-tour-terms :agency="agency.slug" :case-id="Number(caseId)" :client-tour="clientTour"
          @reload-client-tour="$emit('reloadClientTour')" />

        <the-case-setup-tour-complementary-text :agency="agency.slug" :case-id="Number(caseId)"
          :client-tour="clientTour" @reload-client-tour="$emit('reloadClientTour')" />

        <the-case-setup-before-tour :agency="agency.slug" :case-id="Number(caseId)" :client-tour="clientTour"
          @reload-client-tour="$emit('reloadClientTour')" />

        <the-case-setup-featured-image :agency="agency.slug" :client-tour="clientTour.id" :tour="currentTour"
          @reload-client-tour="$emit('reloadClientTour')" />

        <div id="accordionArchive" class="accordion">
          <div class="case-details accordion-item">
            <h2 id="headingArchive" class="accordion-header">
              <button aria-controls="collapseArchive" aria-expanded="false" class="accordion-button collapsed"
                data-bs-target="#collapseArchive" data-bs-toggle="collapse" type="button">
                <div class="mb-0 d-flex">
                  <div class="bg-secondary rounded-xs h-fit-content me-8">
                    <v-icon icon="import" size="xxl" space="p-12" />
                  </div>

                  <div>
                    <div class="h4 ff-secondary mb-0">{{ $t('clientTour.archiveTitle') }}</div>
                    <div class="fw-light accordion-subtitle">{{ $t('clientTour.archiveSubtitle') }}</div>
                  </div>
                </div>
              </button>
            </h2>

            <div id="collapseArchive" aria-labelledby="headingArchive" class="accordion-collapse collapse"
              data-bs-parent="#accordionArchive">
              <div class="accordion-body d-flex flex-column pb-32 pb-xl-48">
                <div class="alert alert-warning d-flex align-items-center" role="alert">
                  <v-icon icon="info" size="lg" space="me-16" />

                  <div class="text-sm">
                    {{ $t('clientTour.archiveText') }}
                  </div>
                </div>

                <v-button v-if="!clientTour.deletedAt" id="btn-archive-client-tour" :disabled="disableArchiveBtn"
                  class="btn-icon mb-8 align-self-end mt-32 mt-lg-48" size="sm" variant="danger"
                  @click="archiveClientTour">
                  <v-icon icon="import" space="me-12" />
                  <template v-if="disableArchiveBtn">{{ $t('general.button.archiving') }}</template>
                  <template v-else>{{ $t('general.button.archive') }}</template>
                </v-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid right">
        <the-case-setup-statuses :tour="tour" />

        <the-case-setup-notifications  :agency="agency.slug" :client-tour="Number(clientTour.id)"
          :tour="Number(tourId)" />

        <the-case-setup-notification-log  :agency="agency.slug"
          :client-tour="Number(clientTour.id)" :tour="Number(tourId)" />

        <the-case-setup-pax  :agency="agency.slug" :client-tour="clientTour" :tour="Number(tourId)"
          @reload-client-tour="$emit('reloadClientTour')" />

        <the-case-setup-quotes  :agency="agency.slug" :client-tour="Number(clientTour.id)"
          :tour="currentTour" />

        <the-case-setup-invoices-emitted  :agency="agency.slug"
          :client-tour="Number(clientTour.id)" :tour="currentTour" />

        <the-case-setup-invoices  :agency="agency.slug" :client-tour="Number(clientTour.id)"
          :tour="currentTour" />

        <the-case-setup-after-tour  :agency="agency.slug" :case-id="Number(caseId)"
          :client-tour="clientTour" @reload-client-tour="$emit('reloadClientTour')" />
      </div>
    </div>

    <the-client-details-offcanvas v-if="offCanvasToShow === 'the-client-details-offcanvas'" 
      :client-data="clientTour.client" :is-archived="!!clientTour.client.deletedAt" @closed="closeOffCanvas"
      @reload-clients="$emit('reloadClientTour')" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';

import { addDays, isEqual, set } from 'date-fns';
import { mapState } from 'pinia';
import { VForm } from '@uniqoders/form';
import ClientTour from '@/api/objects/ClientTour';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useFormValidation from '@/helpers/form';
import api from '@/api';
import { useAuthStore } from '@/stores/auth';
import TheCaseSetupPax from '@/components/case/TheCaseSetupPax.vue';
import useConcepts from '@/helpers/Concepts';
import Tour from '@/api/objects/Tour';
import TheCaseSetupTourDetails from '@/components/case/TheCaseSetupTourDetails.vue';
import TheCaseSetupInvoices from '@/components/case/TheCaseSetupInvoices.vue';
import { useUserStore } from '@/stores/user';
import VSelectUserCaseSearch from '@/components/vendor/selectSearch/VSelectUserCaseSearch.vue';
import TheCaseSetupInvoicesEmitted from '@/components/case/TheCaseSetupInvoicesEmitted.vue';
import TheCaseSetupStatuses from '@/components/case/TheCaseSetupStatuses.vue';
import TheCaseSetupTourContacts from '@/components/case/TheCaseSetupTourContacts.vue';
import TheCaseSetupNotifications from '@/components/case/TheCaseSetupNotifications.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import TheClientDetailsOffcanvas from '@/components/client/TheClientDetailsOffcanvas.vue';
import TheCaseSetupTourTerms from '@/components/case/TheCaseSetupTourTerms.vue';
import TheCaseSetupTourComplementaryText from '@/components/case/TheCaseSetupTourComplementaryText.vue';
import TheCaseSetupQuotes from '@/components/case/TheCaseSetupQuotes.vue';
import TheCaseSetupFeaturedImage from '@/components/case/TheCaseSetupFeaturedImage.vue';
import { useAppStore } from '@/stores/app';
import TheCaseSetupNotificationLog from '@/components/case/TheCaseSetupNotificationLog.vue';
import TheCaseSetupAfterTour from '@/components/case/TheCaseSetupAfterTour.vue';
import TheCaseSetupBeforeTour from '@/components/case/TheCaseSetupBeforeTour.vue';

export default defineComponent({
  name: 'TheCaseSetup',
  components: {
    TheCaseSetupNotificationLog,
    TheCaseSetupFeaturedImage,
    TheCaseSetupQuotes,
    TheCaseSetupTourTerms,
    TheCaseSetupTourComplementaryText,
    TheClientDetailsOffcanvas,
    TheCaseSetupNotifications,
    TheCaseSetupTourContacts,
    TheCaseSetupStatuses,
    TheCaseSetupInvoicesEmitted,
    VSelectUserCaseSearch,
    TheCaseSetupInvoices,
    TheCaseSetupTourDetails,
    TheCaseSetupPax,
    VIcon,
    VButton,
    Datepicker,
    VForm,
    TheCaseSetupAfterTour,
    TheCaseSetupBeforeTour,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    tourId: {
      type: String,
      required: true,
    },
    clientTour: {
      type: Object as PropType<ClientTour>,
      required: true,
    },
  },
  emits: ['reloadClientTour'],
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      initDate: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.initDate')),
      endDate: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.endDate')),
      alternateStartDate: yup.string()
        .nullable()
        .label(t('general.shared.alternativeStartDate')),
      clientId: yup.number()
        .required()
        .nullable()
        .label(t('general.shared.client')),
    });

    const initialValues: Record<string, any> = {
      clientId: !!props.clientTour.clientId ? props.clientTour.clientId : 0,
      initDate: props.clientTour.initDate,
      endDate: props.clientTour.endDate,
      alternateStartDate: props.clientTour.alternateStartDate,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: initDate } = useField<Date>('initDate');
    const { value: endDate } = useField<Date>('endDate');
    const { value: alternateStartDate } = useField<Date>('alternateStartDate');
    const { value: clientId } = useField<number | null>('clientId');

    return {
      ...useOffCanvasUtils(),
      ...useConcepts(),
      ...form,
      initDate,
      endDate,
      alternateStartDate,
      clientId,
    };
  },
  data() {
    return {
      highlightedDates: [] as Date[],
      disableButton: false as boolean,
      disableArchiveBtn: false as boolean,
      tour: null as null | undefined | Tour,
      userId: null as null | number | string,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    ...mapState(useUserStore, ['currentTour']),
    ...mapState(useAppStore, ['settings']),
    clientName(): string {
      let clientName = '';

      if (!!this.clientTour && !!this.clientTour.client && !!this.clientTour.client.name) {
        clientName += `(${this.clientTour.client.code}) ${this.clientTour.client.name}`;

        if (!!this.clientTour.client.surname) {
          clientName += ` ${this.clientTour.client.surname}`;
        }
      }

      return clientName;
    },
  },
  created() {
    this.userId = this.clientTour.assignedTo.id;

    this.tour = this.clientTour.tours.find((tour: Tour) => tour.id === Number(this.tourId));

    this.setHighlightedDates();
  },
  watch: {
    clientTour(newValue, oldValue) {
      if (!!newValue && newValue !== oldValue) {
        this.tour = newValue.tours.find((tour: Tour) => tour.id === Number(this.tourId));

        this.setFieldValue('clientId', newValue.clientId);
        this.setFieldValue('initDate', newValue.initDate);
        this.setFieldValue('endDate', newValue.endDate);
      }
    },
  },
  methods: {
    getInitDayClass(date: Date) {
      const initDate: Date = new Date(this.initDate);

      if (isEqual(date, set(initDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }))) {
        return 'init-marked-cell';
      }

      return '';
    },
    getEndDayClass(date: Date) {
      const endDate: Date = new Date(this.endDate);

      if (isEqual(date, set(endDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }))) {
        return 'end-marked-cell';
      }

      return '';
    },
    setHighlightedDates() {
      if (!!this.initDate && !!this.endDate) {
        const initDate: Date = new Date(this.initDate);
        const endDate: Date = new Date(this.endDate);

        this.highlightedDates = [];

        let date = initDate;

        while (date <= endDate) {
          this.highlightedDates.push(date);
          date = addDays(date, 1);
        }
      }
    },
    async updateInitialTourDate() {
      await this.$modal.confirm({
        title: this.$t('clientTour.dateModal.title'),
        text: this.$t('clientTour.dateModal.text'),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doUpdateInitialTourDate,
      });
    },
    async doUpdateInitialTourDate() {
      try {
        this.toggleAccepted();
        this.disableButton = true;

        const data = {
          init_date: this.initDate,
        };

        if (!!this.agency) {
          await api.case.updateInitialDate(
            this.agency.slug,
            this.clientTour.id,
            data);

          this.$toast.success(this.$t('general.shared.savedChanges'));
        }

        this.$emit('reloadClientTour');

        this.disableButton = false;
        this.toggleAccepted();
      } catch (e: any) {
        this.disableButton = false;
        this.toggleAccepted();

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
    async archiveClientTour() {
      await this.$modal.delete({
        title: this.$t('clientTour.archiveTitle'),
        text: this.$t('clientTour.archiveAlertText', { case: this.clientTour.name }),
        deleteButtonText: this.$t('general.button.archive'),
        deleteButtonLoadingText: this.$t('general.button.archiving'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doArchiveClientTour,
      });
    },
    async doArchiveClientTour() {
      try {
        if (!!this.agency) {
          this.disableArchiveBtn = true;

          await api.case.archive(this.agency.slug, this.clientTour.id);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          await this.$router.replace({
            name: 'case.index',
          });

          this.disableArchiveBtn = false;
        }
      } catch (e: any) {
        this.disableArchiveBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async assignUserToCase() {
      try {
        if (!!this.agency) {
          if (!!this.userId) {
            const data = {
              agency_user_id: this.userId,
            };

            await api.case.assignUser(this.agency.slug, this.clientTour.id, data);

            this.$toast.success(this.$t('general.shared.savedChanges'));

            this.$emit('reloadClientTour');
          } else {
            await api.case.unassignUser(this.agency.slug, this.clientTour.id);

            this.$toast.success(this.$t('general.shared.savedChanges'));

            this.$emit('reloadClientTour');
          }
        }
      } catch (e: any) {
        console.error(e);
        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: .75rem 2rem;
}

.wrapper {
  grid-template-columns: 1fr;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
}

.grid.left {
  grid-column: 1 / span 1;
  grid-auto-rows: min-content;
}
.grid.right {
  grid-column: span 1 / -1;
  grid-auto-rows: min-content;
}

.html-editor-display {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
}
</style>
