export default {
  headerTile: 'Todo lo que necesita saber sobre su viaje',
  contact: 'Contacto',
  noContacts: 'No hay contactos',
  tourResume: 'Resumen del viaje',
  extraField: 'Extras',
  day: 'Día',
  tour: 'Tour',
  flight: 'Vuelo',
  transfer: 'Traslado',
  transportLuggage: 'Transporte de su equipaje',
  stageNumOriginDestination: 'Etapa: { origin } - { destination }',
  distanceNumberKm: 'Distancia: { number } Km',
  notSelectTransfer: 'Aún no ha seleccionado un traslado',
  notSelectTransport: 'Aún no has seleccionado un transporte',
  accommodation: 'Alojamiento',
  information: 'Información',
  quantityNights: 'Ninguna noche | Una noche | { quantity } noches',
  totalPrice: 'Precio total',
  whatToDoLocation: 'Qué hacer en { location }',
  urlToLocationMap: 'Url al mapa de { location }',
  urlToLocationWeb: 'Url to { location } website',
  website: 'Sitio web',
  map: 'Mapa',
  linkAboutDay: 'Enlace sobre el día',
  guidebookLocation: 'Guía: { location }',
  viewWebsite: 'Ver sitio web',
  viewGoogleMaps: 'Ver en Google Maps',
  stageInformation: 'Información de la etapa',
  dayInformation: 'Información del día',
  guidebookOfLocation: 'Guía de { location }',
  linkGoogleMapsResource: 'Enlace a Google Maps de { resource }',
  linkResourceWebsite: 'Enlace al sitio web de { resource }',
  address: 'Dirección',
  noResourcesLocation: 'No se han añadido recursos a esta ubicación',
  viewWebsiteOfSupplier: 'Ver el sitio web de { supplier }',
  urlGoogleMapsOfSupplier: 'Url a Google Maps de { supplier }',
  travelInformation: 'Información de tu viaje',
  pricePerPerson: 'Precio por persona',
  downloadErrorText: 'Ha ocurrido un error al descargar',
  pricePerUnit: '€/unidad',
};
