<template>
  <div v-if="!!agency && !!agency.slug" class="tour-pricing">
    <div v-if="updateTourPricing" class="loader-tour-container">
      <v-loader />
    </div>
    <v-loader v-if="status === 'loading'" />

    <template v-else>
      <div class="d-flex justify-content-between w-100 mb-48">
        <v-button class="btn-icon w-fit" size="sm" variant="primary" @click="openCreateInvoiceOffCanvas">
          <v-icon icon="plus" size="sm" space="me-12" />
          {{ $t("tourPricing.newInvoice") }}
        </v-button>
        <v-button
          class="btn-icon w-fit"
          size="sm"
          variant="primary"
          @click="openSendNotificationsModalAll"
        >
          <v-icon icon="sent" size="sm" space="me-12"/>
          {{ $t('tourBooking.sendNotification') }}
        </v-button>
      </div>

      <div v-for="(type, index) in pricing.items.filter((item) => item.name !== 'null')" :key="index"
        class="item-list mb-56">
        <div class="d-flex justify-content-between mb-48">
          <div>
            <v-button class="w-fit align-self-end" size="sm" variant="primary" @click="checkAllResources(type)">
              {{ $t("general.button.selectAll") }}
            </v-button>

            <v-button
              class="w-fit align-self-end ms-12"
              size="sm"
              variant="primary"
              @click="deselectAllResources(type)"
            >
              {{ $t("general.button.unselectAll") }}
            </v-button>
          </div>
        </div>
        <div :aria-controls="`pricing-collapse-${type.name}`" :data-bs-target="`#pricing-collapse-${type.name}`"
          aria-expanded="true" class="resource-header accordion-toggle align-items-center" data-bs-toggle="collapse">
          <div class="mb-24 d-flex align-items-center">
            <div class="bg-secondary rounded-xs me-8">
              <v-icon :icon="getAssociatedIcon(type.name)" size="xxl" space="p-12" />
            </div>

            <h3 class="h4 ff-secondary mb-0">
              {{ findConceptByKey("supplier.type", type.name).name }} - {{ $t("tourPricing.profit") }}
            </h3>
          </div>

          <v-icon class="accordion-icon" icon="chevron-up" size="xl" />
        </div>

        <div :id="`pricing-collapse-${type.name}`" class="resources-container accordion-body collapse show p-0 pb-16">
          <div class="suppliers-body">
            <template v-for="(supplier, index) in type.suppliers" :key="index">
              <div class="supplier-container d-flex w-100">
                <div class="w-auto px-0 ps-4 px-lg-8">
                  <div class="d-flex flex-column align-items-center py-20">
                    <v-checkbox
                      v-model="supplier.invoiceChecked"
                      :for-key="`supplier-${type.name}-check`"
                      :input-id="`supplier-${type.name}-check`"
                      :label="' '"
                      class="form-check-inline d-flex align-items-center m-0"
                      label-class="ms-0 ps-8 ps-md-16 pe-8"
                      @update:model-value="
                        checkAllSupplierItems(
                          supplier, type.name, supplier.invoiceChecked, supplier.id, 'all'
                        )
                        "
                    />
                  </div>
                </div>
                <div class="supplier-data p-8 ps-0 ps-xl-12 p-xl-12 w-100">
                  <div :id="`collapse-header-${type.name}-${index}`" :aria-controls="`collapse-${type.name}-${index}`"
                    :data-bs-target="`#collapse-${type.name}-${index}`" aria-expanded="true"
                    class="d-flex justify-content-between align-items-center accordion-toggle flex-wrap gx-12 w-100"
                    data-bs-toggle="collapse"
                  >
                    <div class="td w-100">
                      <div class="d-flex align-items-center">
                        <div class="image-container rounded-sm bg-tertiary me-12">
                          {{ supplier.name.charAt(0) }}
                        </div>

                        <div class="py-12 fw-medium">
                          {{ supplier.name }}
                        </div>
                      </div>
                      <v-icon class="accordion-icon" icon="chevron-up" size="xl" />
                    </div>
                  </div>
                  <div class="td d-flex w-100">
                    <div class="hidden-conatiner"></div>
                    <div class="td supplier-info align-items-center row">
                      <div v-if="!!supplier.siteUrl" class="text-sm d-flex pt-0 col-12 col-lg-2">
                        <a
                          :aria-label="supplier.siteUrl"
                          :href="supplier.siteUrl"
                          class="btn-link text-nowrap text-sm"
                          data-bs-target
                          data-bs-toggle="collapse"
                          rel="noopener noreferrer"
                          @click.prevent.stop="openWebsite(supplier.siteUrl)"
                        >
                          {{ $t("general.shared.viewWebsite") }}
                        </a>
                      </div>
                      <div v-else class="text-sm d-flex pt-0 empty-section col-12 col-lg-2">{{}}</div>
                      <div class="col-12 col-lg-3">
                        <template v-if="supplier.bookingType === 'Other'">
                          <a
                            :aria-label="$t('general.shared.bookingBy')"
                            :href="supplier.bookingTypeMeta"
                            class="resource-link btn-link text-sm"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop
                          >
                            {{ $t("general.shared.bookingBy") }} : {{ supplier.bookingTypeMeta }}
                          </a>
                        </template>
                        <template v-else-if="supplier.bookingType === 'Email'">
                          <a
                            :aria-label="$t('general.shared.bookingBy')"
                            :href="supplier.bookingTypeMeta"
                            class="resource-link btn-link text-sm"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop
                          >
                            {{ $t("general.shared.bookingBy") }} {{ supplier.bookingType }}
                          </a>
                        </template>
                        <template v-else-if="supplier.bookingType === 'Portal'">
                          <a
                            :aria-label="$t('general.shared.bookingBy')"
                            :href="supplier.bookingTypeMeta"
                            class="resource-link btn-link text-sm"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop="openWebsite(supplier.bookingTypeMeta)"
                          >
                            {{ $t("general.shared.bookingBy") }} {{ supplier.bookingType }}
                          </a>
                        </template>
                        <template v-else-if="supplier.bookingType === 'Promo code'">
                          <a
                            :aria-label="$t('general.shared.promoCode')"
                            :href="supplier.bookingTypeMeta"
                            class="resource-link btn-link text-sm"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop
                          >
                            {{ $t("general.shared.promoCode") }} : {{ supplier.bookingTypeMeta }}
                          </a>
                        </template>
                        <template v-else-if="supplier.bookingType === 'Website'">
                          <a
                            aria-label="website"
                            :href="supplier.bookingTypeMeta"
                            class="resource-link btn-link text-sm"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop
                          >
                            {{ $t("general.shared.bookingBy") }} {{ supplier.bookingType }}
                          </a>
                        </template>
                        <template v-else>
                          <span class="badge btn-danger fw-normal">
                            {{ $t("general.shared.bookingTypeNotFound") }}
                          </span>
                        </template>
                      </div>
                      <div class="ps-lg-0 col-12 col-lg-4">
                        <div v-if="!supplier.email" class="pe-16">
                          <span class="badge bg-danger fw-normal">{{ $t("tourBooking.supplierNoEmail") }}</span>
                        </div>
                        <div v-if="supplier.typeEmail === 'never'" class="pe-16">
                          <span class="badge bg-danger fw-normal">
                            {{ $t("general.shared.notNotificationsSentMail") }}
                          </span>
                        </div>
                        <div v-if="supplier.typeEmail !== 'never' && supplier.email">{{}}</div>
                      </div>
                      <div v-if="!!supplier.paymentMethod" class="col-12 col-lg-3">
                        <div v-if="supplier.paymentMethod === 'bank_transfer' && supplier.account === null">
                          <span class="badge bg-danger fw-normal">
                            {{ $t("general.shared.supplierAccountNotFound") }}
                          </span>
                        </div>
                        <a
                          :aria-label="$t('general.shared.paymentBy')"
                          href=""
                          v-else-if="supplier.paymentMethod !== 'payment_link'"
                          class="resource-link btn-link text-sm"
                          rel="noopener noreferrer"
                          target="_blank"
                          @click.prevent.stop
                        >
                          {{
                            supplier.paymentMethod.charAt(0).toUpperCase() +
                            supplier.paymentMethod.split("_").join(" ").slice(1)
                          }}
                          {{ supplier.paymentDate }}
                        </a>
                        <template v-else>
                          <a
                            v-if="isPaymentLinkAvailable(supplier)"
                            :aria-label="$t('general.shared.paymentBy')"
                            :href="getPaymentLink(supplier)"
                            class="resource-link btn-link"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click.prevent.stop="openWebsite(getPaymentLink(supplier))"
                          >
                            {{
                              supplier.paymentMethod.charAt(0).toUpperCase() +
                              supplier.paymentMethod.split("_").join(" ").slice(1)
                            }}
                            {{ supplier.paymentDate }}
                          </a>
                          <a
                            v-else
                            :aria-label="$t('general.shared.paymentBy')"
                            href=""
                            class="resource-link btn-link text-sm"
                            rel="noopener noreferrer"
                            @click.prevent.stop
                          >
                            {{
                              supplier.paymentMethod.charAt(0).toUpperCase() +
                              supplier.paymentMethod.split("_").join(" ").slice(1)
                            }}
                            {{ supplier.paymentDate }}
                          </a>
                        </template>
                      </div>
                      <div v-else class="col-12 col-lg-3">
                        <span class="badge bg-danger fw-normal">
                          {{ $t("general.shared.noPaymentMethod") }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex send-notification align-self-start justify-content-end mt-8 me-16">
                    <v-button
                      class="btn-icon w-fit align-self-center"
                      size="sm"
                      variant="primary"
                      @click="
                        openSendNotificationsModal();
                        checkAllSupplierItems(
                          supplier, type.name, (supplier.invoiceChecked = true), supplier.id, 'individual'
                        );
                      "
                    >
                      <v-icon icon="sent" size="sm" />
                    </v-button>
                  </div>
                  <div :id="`collapse-${type.name}-${index}`" class="accordion-body collapse show p-0">
                    <div class="d-none d-lg-block">
                      <div class="resources-profit-header">
                        <div class="d-flex w-100 pe-28 text-sm">
                          <div class="w-05 th p-0 p-xxl-8">
                            <span :aria-label="$t('tourBooking.selectResource')" />
                          </div>

                          <div :class="type.name === 'hotel' ? 'w-20' : 'w-25'" class="th">
                            {{ $t("general.shared.name") }}
                          </div>

                          <div v-if="type.name === 'hotel'" class="w-05 th ps-0 ps-xxl-8">
                            {{ $t("tourPricing.nights") }}
                          </div>
                          <div class="w-10 th">{{ $t("tourPricing.dayNumberShort") }}</div>
                          <div class="w-05 th">{{ $t("tourPricing.quantityShort") }}</div>
                          <div class="w-10 th">{{ $t("tourPricing.dates") }}</div>
                          <div class="w-15 fit th">{{ $t("tourPricing.netPrice") }}</div>
                          <div class="w-15 fit th">{{ $t("tourPricing.percentageProfit") }}</div>
                          <div class="w-15 fit th">{{ $t("tourPricing.grossPrice") }}</div>
                        </div>
                      </div>

                      <div class="suppliers-body">
                        <div
                          v-for="(itemResource, itemResourceIndex) in supplier.itemResourcesGrouped"
                          :key="itemResourceIndex"
                          :class="{ 'bg-gray-200': itemResourceIndex % 2 !== 0 }"
                          class="wrapper"
                        >
                          <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="row align-items-center gx-12 text-sm w-100 pe-24">
                              <div
                                class="w-05 td d-flex align-items-center px-0 px-xxl-8"
                                @mouseover="setHoveredElement(itemResource.id)"
                                @mouseleave="setHoveredElement(0)"
                              >
                                <v-tri-state-checkbox
                                  v-model="itemResource.invoiceChecked"
                                  :for-key="`resource-group-${itemResource.id}-check`"
                                  :input-id="`resource-group-${itemResource.id}-check`" :label="' '"
                                  class="form-check-inline d-flex align-items-center m-0" label-class="ms-0"
                                  @update:model-value="checkSupplierResourceGroup(supplier, itemResource)" />
                              </div>

                              <div v-if="itemResource.resources.length > 1"
                                :aria-controls="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                                :class="type.name === 'hotel' ? 'w-20' : 'w-25'"
                                :data-bs-target="`#collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                                aria-expanded="false" class="td accordion-toggle collapsed cusror-pointer"
                                data-bs-toggle="collapse" @mouseover="setHoveredElement(itemResource.id)"
                                @mouseleave="setHoveredElement(0)">
                                <div class="fw-semi py-12">{{ itemResource.name }}</div>
                                <div :class="{ 'icon-container': !(itemResource.id === hoveredId) }">
                                  <div class="d-flex">
                                    <v-button
                                      class="p-4"
                                      size="sm"
                                      variant="icon"
                                      @click="setCurrentItem(itemResource)"
                                    >
                                      <v-icon icon="pencil" />
                                    </v-button>
                                    <v-button class="p-4" size="sm" variant="icon" @click="deleteItem(itemResource)">
                                      <v-icon icon="trash" />
                                    </v-button>
                                  </div>
                                </div>
                              </div>

                              <div v-else :class="type.name === 'hotel' ? 'w-20' : 'w-25'" class="td"
                                @mouseover="setHoveredElement(itemResource.id)" @mouseleave="setHoveredElement(0)">
                                <div class="fw-semi py-12">{{ itemResource.name }}</div>
                                <div :class="{ 'icon-container': !(itemResource.id === hoveredId) }">
                                  <div class="d-flex">
                                    <v-button
                                      class="p-4"
                                      size="sm"
                                      variant="icon"
                                      @click="setCurrentItem(itemResource)"
                                    >
                                      <v-icon icon="pencil" />
                                    </v-button>
                                    <v-button class="p-4" size="sm" variant="icon" @click="deleteItem(itemResource)">
                                      <v-icon icon="trash" />
                                    </v-button>
                                  </div>
                                </div>
                              </div>

                              <div v-if="type.name === 'hotel'" class="w-05 td fw-semi ps-0 ps-xxl-8">
                                <template v-if="itemResource.type === 'hotel'">
                                  {{ calculateNights(itemResource.date, itemResource.endDate) }}
                                </template>

                                <template v-else> - </template>
                              </div>

                              <div class="w-10 td fw-semi">
                                {{ itemResource.stage }}
                              </div>

                              <div class="w-05 td fw-semi">
                                {{ itemResource.quantity }}
                              </div>

                              <div class="w-10 td fw-semi">
                                <div class="py-12">
                                  {{ $str.formatDateTime(itemResource.date, settings.formatDate) }}
                                  <template v-if="itemResource.type === 'hotel'">
                                    -
                                    <template v-if="!!itemResource.endDate">
                                      {{ $str.formatDateTime(itemResource.endDate, settings.formatDate) }}
                                    </template>

                                    <template v-else>
                                      {{ $t("general.shared.noEndDate") }}
                                    </template>
                                  </template>
                                </div>
                              </div>

                              <!-- isGroupIndividual -->
                              <v-pricing-update :id="itemResource.id" v-model:amount-paid="itemResource.amountPaid"
                                v-model:amount-pending="itemResource.amountPending"
                                v-model:gross="itemResource.grossPrice" v-model:mark-to-pay="itemResource.markToPay"
                                v-model:markup="itemResource.markup" v-model:net="itemResource.netPrice"
                                v-model:net-validated="itemResource.netValidated"
                                v-model:payment-complete="itemResource.paymentComplete"
                                v-model:payment-partial="itemResource.paymentPartial"
                                v-model:payment-pending="itemResource.paymentPending"
                                v-model:split-payment="itemResource.splitPayment"
                                v-model:tour-day-item-resources="itemResource.resources"
                                :is-updated-by-supplier="itemResource.isUpdatedBySupplier"
                                :supplier-price="itemResource.supplierPrice"
                                :account="supplier.account"
                                :agency="agency.slug" 
                                :case-id="Number(caseId)"
                                :payment-method="supplier.paymentMethod"
                                :tour-day-id="itemResource.tourDayId"
                                :tour-day-item-resource-ids="itemResource.tourDayItemResourceIds"
                                :tour-id="Number(tourId)" is-group-individual @update-total-pricing="setTotalPricing"
                                @update-group-type="setGroupTypePricing($event, type)"
                                @updated-group-individual-paid="recalculateGroupPaid(itemResource, false)"
                                @update-tour-price-change="updateTourPricingChnage($event)"
                                @load-tour-pricing="loadTourPricing"
                              />
                            </div>

                            <div v-if="itemResource.resources.length > 1"
                              :aria-controls="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                              :data-bs-target="`#collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                              aria-expanded="false"
                              class="accordion-toggle collapsed"
                              data-bs-toggle="collapse"
                            >
                              <v-icon class="accordion-icon" icon="chevron-up" />
                            </div>
                          </div>

                          <div v-if="itemResource.resources.length > 1"
                            :id="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                            class="accordion-body collapse p-0 pt-28"
                          >
                            <div
                              v-for="(tourDayItemResource, index) in itemResource.resources"
                              :key="index"
                              class="row align-items-center gx-12 text-sm pe-36 dashed py-16"
                            >
                              <div class="w-05 td ps-16">
                                <v-checkbox :id="`supplier-resource-${type.name}-check`"
                                  v-model="tourDayItemResource.invoiceChecked"
                                  :for-key="`supplier-resource-${type.name}-check`"
                                  :label="' '"
                                  class="form-check-inline d-flex align-items-center m-0"
                                  label-class="ms-0"
                                  @update:model-value="checkSupplierResource(itemResource, supplier)"
                                />
                              </div>

                              <div :class="type.name === 'hotel' ? 'w-20' : 'w-25'" class="td">
                                <div class="py-12 fw-medium">{{ tourDayItemResource.name }}</div>
                              </div>

                              <div v-if="type.name === 'hotel'" class="w-05 td">
                                {{ calculateNights(tourDayItemResource.date, tourDayItemResource.endDate) }}
                              </div>

                              <div class="w-10 td">
                                {{ itemResource.stage }}
                              </div>

                              <div class="w-05 td">1</div>

                              <div class="w-10 td">
                                <div class="py-12">
                                  {{ $str.formatDateTime(tourDayItemResource.date, settings.formatDate) }}
                                  <template v-if="tourDayItemResource.type === 'hotel'">
                                    -
                                    <template v-if="!!tourDayItemResource.endDate">
                                      {{ $str.formatDateTime(tourDayItemResource.endDate, settings.formatDate) }}
                                    </template>

                                    <template v-else>
                                      {{ $t("general.shared.noEndDate") }}
                                    </template>
                                  </template>
                                </div>
                              </div>

                              <!-- isIndividual -->
                              <v-pricing-update :id="tourDayItemResource.id"
                                v-model:amount-paid="tourDayItemResource.amountPaid"
                                v-model:amount-pending="tourDayItemResource.amountPending"
                                v-model:gross="tourDayItemResource.grossPrice"
                                v-model:mark-to-pay="tourDayItemResource.markToPay"
                                v-model:markup="tourDayItemResource.markup" v-model:net="tourDayItemResource.netPrice"
                                v-model:net-validated="tourDayItemResource.netValidated"
                                v-model:payment-status="tourDayItemResource.paymentStatus"
                                v-model:split-payment="tourDayItemResource.splitPayment"
                                v-model:tour-day-item-resources="itemResource.resources"
                                :is-updated-by-supplier="tourDayItemResource.isUpdatedBySupplier"
                                :supplier-price="tourDayItemResource.supplierPrice"
                                :account="supplier.account"
                                :agency="agency.slug" 
                                :case-id="Number(caseId)"
                                :payment-method="supplier.paymentMethod"
                                :tour-day-id="tourDayItemResource.tourDayId"
                                :tour-day-item="itemResource"
                                :tour-day-item-id="tourDayItemResource.tourDayItemId"
                                :tour-day-item-resource="tourDayItemResource"
                                :tour-day-item-resource-id="tourDayItemResource.id"
                                :tour-day-item-resource-ids="[tourDayItemResource.id]" :tour-id="Number(tourId)"
                                is-individual @update-total-pricing="setTotalPricing"
                                @updated-individual="recalculateGroupIndividualPricing(itemResource)"
                                @update-group-type="setGroupTypePricing($event, type)"
                                @updated-individual-net-validated="recalculateGroupBulk(itemResource, 'netValidated')"
                                @updated-individual-mark-to-pay="recalculateGroupBulk(itemResource, 'markToPay')"
                                @updated-individual-paid="recalculateGroupPaid(itemResource, true)"
                                @updated-individual-split-payment="recalculateGroupPaid(itemResource, false)"
                                @update-tour-price-change="updateTourPricingChnage($event)"
                                @load-tour-pricing="loadTourPricing"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="td d-flex justify-content-end pt-16 pb-24 pe-36">
                          <div class="sub-container">
                            <template class="d-flex flex-column">
                              <div class="d-flex justify-content-between">
                                <span class="fw-bold">
                                  {{ $t('general.shared.payAmount') }} : {{ $t('general.shared.euroShort') }}
                                  {{ totalPayablePriceDayTour(supplier.name, supplier.itemResourcesGrouped)
    .toFixed(2).replace(/\.0+$/, '') }}
                                </span>

                                <span class="fw-bold">
                                  {{ $t('general.shared.pendingAmount') }} : {{ $t('general.shared.euroShort') }}
                                  {{ totalPendingPriceDayTour(supplier.name, supplier.itemResourcesGrouped)
    .toFixed(2).replace(/\.0+$/, '') }}
                                </span>

                                <span class="fw-bold">
                                  {{ $t('general.shared.paidAmount') }} : {{ $t('general.shared.euroShort') }}
                                  {{ totalAmountPaid(supplier.name, supplier.itemResourcesGrouped)
    .toFixed(2).replace(/\.0+$/, '') }}
                                </span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex flex-column d-lg-none suppliers-body p-0">
                      <div class="suppliers-body">
                        <div
                          v-for="(itemResource, itemResourceIndex) in supplier.itemResourcesGrouped"
                          :key="itemResourceIndex"
                          :class="{ 'bg-gray-200': itemResourceIndex % 2 !== 0 }"
                          class="wrapper ps-8"
                        >
                          <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="d-flex align-items-center gx-12 text-sm w-100 pe-32">
                              <v-tri-state-checkbox v-model="itemResource.invoiceChecked"
                                :for-key="`resource-group-${itemResource.id}-check`"
                                :input-id="`resource-group-${itemResource.id}-check`" :label="' '"
                                class="form-check-inline d-flex align-items-center m-0" label-class="ms-0"
                                @update:model-value="checkSupplierResourceGroup(supplier, itemResource)" />

                              <div class="w-100 ps-4">
                                <div :class="{ 'icon-container': !(itemResource.id === hoveredId) }"
                                  @mouseover="setHoveredElement(itemResource.id)" @mouseleave="setHoveredElement(0)">
                                  <div class="d-flex mt-4">
                                    <v-button
                                      class="p-4"
                                      size="sm"
                                      variant="icon"
                                      @click="setCurrentItem(itemResource)"
                                    >
                                      <v-icon icon="pencil" />
                                    </v-button>
                                    <v-button class="p-4" size="sm" variant="icon" @click="deleteItem(itemResource)">
                                      <v-icon icon="trash" />
                                    </v-button>
                                  </div>
                                </div>
                                <div :aria-controls="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                                  :data-bs-target="`#collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                                  aria-expanded="false"
                                  class="accordion-toggle collapsed fw-semi py-4"
                                  data-bs-toggle="collapse"
                                  @mouseover="setHoveredElement(itemResource.id)"
                                  @mouseleave="setHoveredElement(0)"
                                >
                                  <span>{{ $t("general.shared.name") }}: </span>
                                  <span>{{ itemResource.name }}</span>
                                </div>

                                <div
                                  v-if="type.name === 'hotel'"
                                  class="fw-semi py-4"
                                  @mouseover="setHoveredElement(itemResource.id)"
                                  @mouseleave="setHoveredElement(0)"
                                >
                                  <span>{{ $t("tourPricing.nights") }}: </span>

                                  <template v-if="itemResource.type === 'hotel'">
                                    {{ calculateNights(itemResource.date, itemResource.endDate) }}
                                  </template>

                                  <template v-else> - </template>
                                </div>

                                <div class="fw-semi py-4">
                                  <span>{{ $t("tourPricing.dayNumber") }}: </span>
                                  <span>{{ itemResource.stage }}</span>
                                </div>

                                <div class="fw-semi py-4">
                                  <span>{{ $t("tourPricing.quantity") }}: </span>
                                  <span>{{ itemResource.quantity }}</span>
                                </div>

                                <div class="fw-semi py-4">
                                  <span>{{ $t("tourPricing.dates") }}: </span>

                                  <span>
                                    {{ $str.formatDateTime(itemResource.date, settings.formatDate) }}
                                    <template v-if="itemResource.type === 'hotel'">
                                      -
                                      <template v-if="!!itemResource.endDate">
                                        {{ $str.formatDateTime(itemResource.endDate, settings.formatDate) }}
                                      </template>

                                      <template v-else>
                                        {{ $t("general.shared.noEndDate") }}
                                      </template>
                                    </template>
                                  </span>
                                </div>

                                <!-- isGroupIndividual -->
                                <v-pricing-update :id="itemResource.id" v-model:amount-paid="itemResource.amountPaid"
                                  v-model:amount-pending="itemResource.amountPending"
                                  v-model:gross="itemResource.grossPrice" v-model:mark-to-pay="itemResource.markToPay"
                                  v-model:markup="itemResource.markup" v-model:net="itemResource.netPrice"
                                  v-model:net-validated="itemResource.netValidated"
                                  v-model:payment-complete="itemResource.paymentComplete"
                                  v-model:payment-partial="itemResource.paymentPartial"
                                  v-model:payment-pending="itemResource.paymentPending"
                                  v-model:split-payment="itemResource.splitPayment"
                                  v-model:tour-day-item-resources="itemResource.resources"
                                  :is-updated-by-supplier="itemResource.isUpdatedBySupplier"
                                  :supplier-price="itemResource.supplierPrice"
                                  :account="supplier.account"
                                  :agency="agency.slug" 
                                  :case-id="Number(caseId)"
                                  :payment-method="supplier.paymentMethod"
                                  :tour-day-id="itemResource.tourDayId"
                                  :tour-day-item-resource-ids="itemResource.tourDayItemResourceIds"
                                  :tour-id="Number(tourId)" class="w-100 is-mobile" is-group-individual
                                  @update-total-pricing="setTotalPricing"
                                  @update-group-type="setGroupTypePricing($event, type)"
                                  @updated-group-individual-paid="recalculateGroupPaid(itemResource, false)"
                                  @update-tour-price-change="updateTourPricingChnage($event)"
                                  @load-tour-pricing="loadTourPricing"
                                />
                              </div>
                            </div>

                            <div :aria-controls="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                              :data-bs-target="`#collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                              aria-expanded="false"
                              class="accordion-toggle collapsed"
                              data-bs-toggle="collapse"
                            >
                              <v-icon class="accordion-icon" icon="chevron-up" />
                            </div>
                          </div>

                          <div
                            :id="`collapse-tour-day-item-resource-${itemResource.id}-dropdown`"
                            class="accordion-body collapse p-0 pt-28 ps-16"
                          >
                            <div
                              v-for="(tourDayItemResource, index) in itemResource.resources"
                              :key="index"
                              class="d-flex text-sm pe-32 dashed py-16"
                            >
                              <v-checkbox
                                :id="`supplier-resource-${type.name}-check`"
                                v-model="tourDayItemResource.invoiceChecked"
                                :for-key="`supplier-resource-${type.name}-check`"
                                :label="' '"
                                class="form-check-inline d-flex align-items-center m-0"
                                label-class="ms-0"
                                @update:model-value="checkSupplierResource(itemResource, supplier)"
                              />

                              <div class="fw-medium">
                                <div class="py-4">
                                  <span>{{ $t("general.shared.name") }}: </span>
                                  <span>{{ tourDayItemResource.name }}</span>
                                </div>

                                <div v-if="type.name === 'hotel'" class="py-4">
                                  <span>{{ $t("tourPricing.nights") }}: </span>
                                  <span>
                                    {{ calculateNights(tourDayItemResource.date, tourDayItemResource.endDate) }}
                                  </span>
                                </div>

                                <div class="py-4">
                                  <span>{{ $t("tourPricing.dayNumber") }}: </span>
                                  {{ itemResource.stage }}
                                </div>

                                <div class="py-4">
                                  <span>{{ $t("tourPricing.quantity") }}: 1</span>
                                </div>

                                <div class="py-4">
                                  <span>{{ $t("tourPricing.dates") }}: </span>

                                  <span>
                                    {{ $str.formatDateTime(tourDayItemResource.date, settings.formatDate) }}
                                    <template v-if="tourDayItemResource.type === 'hotel'">
                                      -
                                      <template v-if="!!tourDayItemResource.endDate">
                                        {{ $str.formatDateTime(tourDayItemResource.endDate, settings.formatDate) }}
                                      </template>

                                      <template v-else>
                                        {{ $t("general.shared.noEndDate") }}
                                      </template>
                                    </template>
                                  </span>
                                </div>

                                <!-- isIndividual -->
                                <v-pricing-update :id="tourDayItemResource.id"
                                  v-model:amount-paid="tourDayItemResource.amountPaid"
                                  v-model:amount-pending="tourDayItemResource.amountPending"
                                  v-model:gross="tourDayItemResource.grossPrice"
                                  v-model:mark-to-pay="tourDayItemResource.markToPay"
                                  v-model:markup="tourDayItemResource.markup" v-model:net="tourDayItemResource.netPrice"
                                  v-model:net-validated="tourDayItemResource.netValidated"
                                  v-model:payment-status="tourDayItemResource.paymentStatus"
                                  v-model:split-payment="tourDayItemResource.splitPayment"
                                  v-model:tour-day-item-resources="itemResource.resources"
                                  :is-updated-by-supplier="tourDayItemResource.isUpdatedBySupplier"
                                  :supplier-price="tourDayItemResource.supplierPrice"
                                  :account="supplier.account"
                                  :agency="agency.slug" 
                                  :case-id="Number(caseId)"
                                  :payment-method="supplier.paymentMethod"
                                  :tour-day-id="tourDayItemResource.tourDayId"
                                  :tour-day-item="itemResource"
                                  :tour-day-item-id="tourDayItemResource.tourDayItemId"
                                  :tour-day-item-resource="tourDayItemResource"
                                  :tour-day-item-resource-id="tourDayItemResource.id"
                                  :tour-day-item-resource-ids="[tourDayItemResource.id]" :tour-id="Number(tourId)"
                                  class="is-mobile w-100" is-individual @update-total-pricing="setTotalPricing"
                                  @updated-individual="recalculateGroupIndividualPricing(itemResource)"
                                  @update-group-type="setGroupTypePricing($event, type)"
                                  @updated-individual-net-validated="recalculateGroupBulk(itemResource, 'netValidated')"
                                  @updated-individual-mark-to-pay="recalculateGroupBulk(itemResource, 'markToPay')"
                                  @updated-individual-paid="recalculateGroupPaid(itemResource, true)"
                                  @updated-individual-split-payment="recalculateGroupPaid(itemResource, false)"
                                  @update-tour-price-change="updateTourPricingChnage($event)"
                                  @load-tour-pricing="loadTourPricing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="td d-flex flex-column align-items-end align-items-lg-center 
                           flex-lg-column pt-16 pb-24 pe-36"
                        >
                          <span class="fw-bold">
                            {{ $t('general.shared.payAmount') }} : {{ $t('general.shared.euroShort') }}
                            {{ totalPayablePriceDayTour(supplier.name, supplier.itemResourcesGrouped)
    .toFixed(2).replace(/\.0+$/, '') }}
                          </span>

                          <span class="fw-bold">
                            {{ $t('general.shared.pendingAmount') }} : {{ $t('general.shared.euroShort') }}
                            {{ totalPendingPriceDayTour(supplier.name, supplier.itemResourcesGrouped)
    .toFixed(2).replace(/\.0+$/, '') }}
                          </span>

                          <span class="fw-bold">
                            {{ $t('general.shared.paidAmount') }} : {{ $t('general.shared.euroShort') }}
                            {{ totalAmountPaid(supplier.name, supplier.itemResourcesGrouped)
    .toFixed(2).replace(/\.0+$/, '') }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="type-total">
          <div class="d-flex flex-column flex-md-row w-100 align-items-center ps-16 pe-16 ps-md-64">
            <div class="w-05 d-none d-md-block" />
            <div class="w-70 text-xl fw-bold text-uppercase text-center text-md-start">
              {{
                $t("tourPricing.supplierTypeTotal", {
                  supplierType: findConceptByKey("supplier.type", type.name).name,
                })
              }}
            </div>

            <!-- isGroupType -->
            <v-pricing-update :id="type.name" v-model:gross="type.total.grossPrice" v-model:markup="type.total.markup"
              v-model:net="type.total.netPrice" :agency="agency.slug" :case-id="Number(caseId)" 
              :total-type="type.name" :tour-id="Number(tourId)" class="is-mobile" is-group-type
              @updated-total-type-pricing="setTotalTypePricing($event, type)" @update-total-pricing="setTotalPricing"
              @load-tour-pricing="loadTourPricing" />
          </div>

          <div class="d-flex justify-content-end">
            <div class="text-start preview-container">
              <button class="btn-sm btn-primary btn align-self-end" type="button" @click="openModal">
                {{ $t("general.shared.preview") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!!currentTour && !!currentTour.id && statusTotalPricing === 'loaded'"
        class="pricing-total-container item-list"
      >
        <div class="total-header">
          <div class="mb-24 d-flex align-items-center">
            <h3 class="text-xl text-uppercase ff-secondary mb-0">{{ $t("tourPricing.total") }}</h3>
          </div>
        </div>

        <div class="pricing-total">
          <div class="d-flex w-100 align-items-center ps-16">
            <div class="w-05 d-none d-md-block" />
            <div class="w-70 d-none d-md-block" />

            <!-- isTotal -->
            <v-pricing-update :id="`tour-${tourId}`" v-model:gross="pricing.total.grossPrice"
              v-model:markup="pricing.total.markup" v-model:net="pricing.total.netPrice" :agency="agency.slug"
               :case-id="Number(caseId)" :tour-id="Number(tourId)" class="is-mobile" is-total
              @update-total-pricing="setTotalPricing" @load-tour-pricing="loadTourPricing" />
          </div>
        </div>
      </div>
    </template>

    <the-pricing-invoice-create-off-canvas v-if="offCanvasToShow === 'the-pricing-invoice-create-off-canvas'"
     :agency="agency.slug" :case-id="Number(caseId)" :items="items" :total-invoice="totalInvoice"
      :tour-id="Number(tourId)" @closed="closeOffCanvas" />

    <div v-if="showLoader" class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ $t("general.button.loading") }}</span>
    </div>

    <the-hotel-pricing-summary-modal v-if="showmodal" :agency="agency.slug" :case-id="Number(caseId)"
      :summary-data="summaryData" :tour-id="Number(tourId)" 
      @closed="closeModal" @load-tour-pricing="loadTourPricing" />

    <the-tour-day-item-details-off-canvas
      v-if="offCanvasToShow === 'the-tour-day-item-details-off-canvas'"
      :agency="agency.slug" 
      :client-tour-id="Number(caseId)"
      :status="statusTourDayItem"
      :tour-id="Number(tourId)"
      @closed="
        closeOffCanvas();
        clearCurrentTourDayItem();
        loadTourPricing();
      "
      @emit-reload-tour="loadTourPricing"
    />
    <the-tour-statuses-send-notification-off-canvas
      v-if="offCanvasToShow === 'the-tour-statuses-send-notification-off-canvas'"
      :agency="agency.slug"
      :case-id="Number(caseId)"
      :resources="resources"
      :supplier-data-for-email="supplierDataForEmail"
      :tour-id="Number(tourId)"
      @closed="closeOffCanvas()"
      @reload-items="loadTourPricing"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { VCheckbox } from "@uniqoders/form";
import { DateTime } from "luxon";
import api from "@/api";
import { TYPE } from "vue-toastification";
import { useAuthStore } from "@/stores/auth";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import { findConceptByKey, mapConcepts } from "@/helpers/ConceptHelper";
import VLoader from "@/components/vendor/loader/VLoader.vue";
import { useUserStore } from "@/stores/user";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import ThePricingInvoiceCreateOffCanvas from "@/components/pricing/ThePricingInvoiceCreateOffCanvas.vue";
import useOffCanvasUtils from "@/helpers/OffCanvasUtils";
import TourDayItemResource from "@/api/objects/TourDayItemResource";
import Supplier from "@/api/objects/Supplier";
import { useAppStore } from "@/stores/app";
import { usePriceStore } from "@/stores/priceCalculation";
import TheHotelPricingSummaryModal from "@/components/case/parts/TheHotelPricingSummaryModal.vue";
import useConcepts from "@/helpers/Concepts";
import VPricingUpdate from "@/components/pricing/VPricingUpdate.vue";
import VTriStateCheckbox from "@/components/vendor/basic/form/VTriStateCheckbox.vue";
import { calculateAllNetValidated, calculateAllPaymentStatus, calculatePriceGroup } from "@/composables/pricing";
import TheTourDayItemDetailsOffCanvas from "@/components/tour/TheTourDayItemDetailsOffCanvas.vue";
import TheTourStatusesSendNotificationOffCanvas from "@/components/reservation/TheTourStatusesSendNotificationOffCanvas.vue";

export interface IItemsTypes {
  types: any[];
  resourceIds: number[];
}

interface IPrice {
  grossPrice: number;
  markup: number;
  netPrice: number;
}

export interface IPricingItemSupplierResource {
  amountPaid: number;
  amountPending: number;
  date: string;
  endDate: string | Datetime;
  grossPrice: number;
  id: number;
  invoiceChecked: boolean;
  invoiceStatus: string;
  isOptional: false;
  markup: number;
  markToPay: boolean;
  modelId: number;
  modelType: string;
  name: string;
  netPrice: number;
  netValidated: boolean;
  pax: number;
  paymentStatus: string;
  reservationStatus: string;
  splitPayment: boolean;
  stage: number;
  supplierResourceId: string | number;
  tourDayId: number;
  tourDayItemId: number;
  type: string;
}

export interface IPricingItemSupplierResourceGrouped {
  account: string;
  amountPaid: number;
  amountPending: number;
  date: string;
  endDate: string;
  grossPrice: number;
  id: string;
  invoiceChecked: boolean;
  markup: number;
  markToPay: boolean | "mixed";
  name: string;
  netPrice: number;
  netValidated: boolean | "mixed";
  paymentComplete: boolean | "mixed";
  paymentPartial: boolean | "mixed";
  paymentPending: boolean | "mixed";
  paymentCancelled: boolean | "mixed";
  paymentMethod: string;
  paymentDate: string;
  bookingType: string;
  bookingTypeMeta: string;
  quantity: number;
  resources: IPricingItemSupplierResource[];
  splitPayment: boolean | "mixed";
  stage: number;
  tourDayId: number;
  tourDayItemIds: number[];
  tourDayItemResourceIds: number[];
  type: string;
  typeResource: string;
  supplierPrice: string;
}

interface IPricingItemSupplier {
  account: string;
  email: string;
  id: string | number;
  invoiceChecked: boolean | "mixed";
  itemResourcesGrouped: IPricingItemSupplierResourceGrouped[];
  mapUrl: string;
  name: string;
  paymentMethod: string;
  phone: string;
  preferredLanguage: string;
  priceRange: number;
  siteUrl: string;
  type: string;
  typeEmail: string;
}

interface IPricingItems {
  name: string;
  suppliers: IPricingItemSupplier[];
  total: IPrice;
}

interface IPricing {
  items: IPricingItems[];
  total: IPrice;
}

export type HotelDataType = {
  hotelName: string;
  paidAmount?: number;
  pendingAmount?: number;
  payableAmount?: number;
};

export default defineComponent({
  name: "TheTourPricing",
  components: {
    VTriStateCheckbox,
    VPricingUpdate,
    ThePricingInvoiceCreateOffCanvas,
    TheHotelPricingSummaryModal,
    VLoader,
    VIcon,
    VButton,
    VCheckbox,
    TheTourDayItemDetailsOffCanvas,
    TheTourStatusesSendNotificationOffCanvas,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    tourId: {
      type: String,
      required: true,
    },
    
  },
  setup() {
    const userStore = useUserStore();
    return {
      findConceptByKey,
      ...useOffCanvasUtils(),
      ...useConcepts(),
      userStore,
    };
  },
  data() {
    return {
      status: "loading",
      statusTotalPricing: "loading",
      pricing: {} as IPricing,
      resources: [] as IPricingItemSupplierResource[],
      totalNetPrice: 0,
      totalGrossPrice: 0,
      totalMarkup: 0,
      totalInvoice: 0,
      showLoader: false as boolean,
      summaryData: [] as HotelDataType[],
      showmodal: false as boolean,
      requestedUpdatePricing: true,
      test: null as any,
      checkbox: false as boolean | "mixed",
      items: {
        types: [] as any[],
        resourceIds: [] as number[],
      } as any,
      hoveredId: 0 as number,
      statusTourDayItem: "loading" as string,
      supplierDataForEmail: [] as IPricingItemSupplierResourceGrouped[],
      updateTourPricing: false as boolean,
    };
  },
  computed: {
    ...mapState(useAppStore, ["settings"]),
    ...mapState(useAuthStore, ["agency"]),
    ...mapState(useUserStore, ["currentTour"]),
    itemTypes(): any {
      if (!!this.concepts && this.concepts["supplier.type"]) {
        return mapConcepts(this.concepts["supplier.type"]);
      }

      return [];
    },
  },
  async created() {
    await this.loadTourPricing();

    this.status = "loaded";
  },
  methods: {
    updateTourPricingChnage(value: boolean) {
      this.updateTourPricing = value;
    },
    isPaymentLinkAvailable(supplier: any) {
      return (
        supplier?.itemResourcesGrouped?.map((i) => i.paymentLink !== null && i.paymentLink)?.filter(Boolean)[0] !==
        undefined
      );
    },
    getPaymentLink(supplier: any) {
      return supplier?.itemResourcesGrouped?.map((i) => i.paymentLink !== null && i.paymentLink)?.filter(Boolean)[0];
    },
    setTotalTypePricing(response: any, pricingType: IPricingItems) {
      pricingType.suppliers.forEach((supplier: IPricingItemSupplier) => {
        supplier.itemResourcesGrouped.forEach((itemResources: IPricingItemSupplierResourceGrouped) => {
          response.forEach((responseResource: any) => {
            const itemResource = itemResources.resources.find(
              (resource: IPricingItemSupplierResource) => resource.id === responseResource.id
            );

            if (!!itemResource) {
              itemResource.grossPrice = responseResource.gross_value;
              itemResource.markup = responseResource.markup_value;
              itemResource.netPrice = responseResource.net_value;

              let grossPrice = itemResources.resources.reduce(
                (sum: number, item: IPricingItemSupplierResource) => sum + (Number(item.grossPrice) || 0),
                0
              );

              if (grossPrice % 1 !== 0) {
                grossPrice = Number(grossPrice.toFixed(2));
              }

              let netPrice = itemResources.resources.reduce(
                (sum: number, item: IPricingItemSupplierResource) => sum + (Number(item.netPrice) || 0),
                0
              );

              if (netPrice % 1 !== 0) {
                netPrice = Number(netPrice.toFixed(2));
              }

              itemResources.grossPrice = grossPrice;
              itemResources.markup = responseResource.markup_value;
              itemResources.netPrice = netPrice;
            }
          });
        });
      });
    },
    setGroupTypePricing(
      {
        grossPrice,
        markup,
        netPrice,
      }: {
        grossPrice: number;
        markup: number;
        netPrice: number;
      },
      type: IPricingItems
    ) {
      type.total.grossPrice = grossPrice;
      type.total.markup = markup;
      type.total.netPrice = netPrice;
    },
    setTotalPricing({ grossPrice, markup, netPrice }: { grossPrice: number; markup: number; netPrice: number }) {
      this.statusTotalPricing = "loading";

      this.pricing.total.grossPrice = grossPrice;
      this.pricing.total.markup = markup;
      this.pricing.total.netPrice = netPrice;

      this.statusTotalPricing = "loaded";
    },
    recalculateGroupBulk(itemResource: IPricingItemSupplierResourceGrouped, variable: string) {
      let value: boolean | "mixed" = false;

      const allValue = itemResource.resources.every((item: IPricingItemSupplierResource) => item[variable] === true);

      if (allValue) {
        value = true;
      } else {
        const someValue = itemResource.resources.some((item: IPricingItemSupplierResource) => item[variable] === true);

        if (someValue) {
          value = "mixed";
        }
      }

      itemResource[variable] = value;
    },
    recalculateGroupPaid(itemResource: IPricingItemSupplierResourceGrouped, updatePrices = true) {
      if (updatePrices) {
        const { amountPaid, amountPending } = calculatePriceGroup(itemResource.resources);

        itemResource.amountPaid = amountPaid;
        itemResource.amountPending = amountPending;
      }

      const paymentPartial: boolean | "mixed" = calculateAllPaymentStatus(itemResource.resources, "partial");

      itemResource.netValidated = calculateAllNetValidated(itemResource.resources);
      itemResource.paymentComplete = calculateAllPaymentStatus(itemResource.resources, "complete");
      itemResource.paymentPending = calculateAllPaymentStatus(itemResource.resources, "pending");
      itemResource.paymentPartial = paymentPartial;
      itemResource.paymentCancelled = calculateAllPaymentStatus(itemResource.resources, "cancelled");
      itemResource.splitPayment = paymentPartial;
    },
    recalculateGroupIndividualPricing(itemResource: IPricingItemSupplierResourceGrouped) {
      const { netPrice, grossPrice, markup } = this.calculatePrice(itemResource.resources);

      itemResource.netPrice = netPrice;
      itemResource.markup = markup;
      itemResource.grossPrice = grossPrice;
    },
    calculateNights(initDate: string, endDate: string): number {
      if (!initDate || !endDate) {
        return 0;
      }

      const init = DateTime.fromISO(initDate).startOf("day");
      const end = DateTime.fromISO(endDate).startOf("day");

      return end.diff(init, "days").days;
    },
    openWebsite(url: string) {
      window.open(url, "_blank");
    },
    calculatePrice(tourDayItemResources: TourDayItemResource[] | IPricingItemSupplierResource[]): Record<string, any> {
      // 1. Calculate the net, gross and markup for each type.
      const { netPrice, grossPrice } = calculatePriceGroup(tourDayItemResources);

      let markup: number = ((grossPrice - netPrice) * 100) / netPrice;

      if (markup % 1 !== 0) {
        markup = Number(markup.toFixed(2));
      }

      if (Number.isNaN(markup)) {
        markup = 0;
      }

      return {
        netPrice,
        grossPrice,
        markup,
      };
    },
    mapResourcesToNotification(type: string, id: number) {
      this.resources = [];
      this.supplierDataForEmail = [];

      this.pricing.items.forEach((supplierType: any) => {
        if (supplierType.name === type) {
          supplierType?.suppliers?.forEach((newSupplier: any) => {
            if (newSupplier.id === id) {
              this.supplierDataForEmail.push(newSupplier);
              newSupplier.itemResourcesGrouped.forEach((item: any) => {
                item.resources.forEach((resource: any) => {
                  if (resource.invoiceChecked) {
                    this.resources.push(resource.id);
                  }
                });
              });
            }
          });
        }
      });
    },
    async mapResourcesToNotificationAll() {
      this.resources = [];
      const supplierData: any[] = [];
      
     this.pricing.items.forEach((supplierType: any) => {
        supplierType?.suppliers?.forEach((newSupplier: any) => {
          supplierData.push(newSupplier);
          newSupplier.itemResourcesGrouped.forEach((item: any) => {
            item.resources.forEach((resource: any) => {
              if (resource.invoiceChecked) {
                this.resources.push(resource.id);
              }
            });
          });
        });
      });
      this.supplierDataForEmail = supplierData;
    },
    checkAllSupplierItems(supplier: any, type: string, checked: boolean, id: number, checboxType: string) {
      supplier.itemResourcesGrouped.forEach((item: any) => {
        item.invoiceChecked = checboxType === 'all' ? checked : item.invoiceChecked;
        item.resources.forEach((resource: any) => {
          resource.invoiceChecked = checboxType === 'all' ? checked : resource.invoiceChecked;
        });
      });
      this.mapResourcesToNotification(type, id);
    },
    openSendNotificationsModalAll() {
      this.mapResourcesToNotificationAll();

      if (this.resources.length === 0) {
        this.$toast.error(this.$t("tourBooking.noResourcesSelectedMessage"));
      } else {
        this.openOffCanvas("the-tour-statuses-send-notification-off-canvas");
      }
    },
    openSendNotificationsModal() {
      this.openOffCanvas("the-tour-statuses-send-notification-off-canvas");
    },
    setHoveredElement(id: number) {
      this.hoveredId = id;
    },
    checkAllResources(type: any) {
      this.pricing.items.forEach((priceType) => {
        if (priceType.name === type.name) {
          priceType.suppliers.forEach((supplier) => {
            supplier.invoiceChecked = true;

            supplier.itemResourcesGrouped.forEach((item) => {
              item.invoiceChecked = true;
              item.resources.forEach((resource) => {
                resource.invoiceChecked = true;
              });
            });
          });
        }
      });
    },
    deselectAllResources(type: any) {
      this.pricing.items.forEach((priceType) => {
        if (priceType.name === type.name) {
          priceType.suppliers.forEach((supplier) => {
            supplier.invoiceChecked = false;

            supplier.itemResourcesGrouped.forEach((item) => {
              item.invoiceChecked = false;
              item.resources.forEach((resource) => {
                resource.invoiceChecked = false;
              });
            });
          });
        }
      });
    },
    setCurrentItem(item: any) {
      const payload = {
        agency: this.agency.slug,
        clientTourId: Number(this.caseId),
        tourId: Number(this.tourId),
        tourDayId: item?.tourDayId,
        tourDayItemId: item?.tourDayItemIds[0],
      };
      this.openItemDetailsOffCanvas(payload);
    },
    async openItemDetailsOffCanvas(data: any) {
      this.statusTourDayItem = "loading";

      if (this.offCanvasToShow !== "the-tour-day-item-details-off-canvas") {
        this.openOffCanvas("the-tour-day-item-details-off-canvas");
      }

      await this.userStore.setCurrentTourDayItem(data);

      this.statusTourDayItem = "loaded";
    },
    async deleteItem(itemResource: any) {
      await this.$modal.delete({
        title: this.$t("tourBuilder.deleteTourDayItemTitle"),
        text: this.$t("tourBuilder.deleteTourDayItemText", {
          dayItem: itemResource?.name,
        }),
        deleteButtonText: this.$t("general.button.delete"),
        rightButtonClasses: "btn-tertiary",
        deleteButtonCallback: () => this.doDeleteItem(itemResource),
      });
    },
    async doDeleteItem(itemResource: any) {
      this.status = "loading";
      const loadingToast = this.$toast.loading(this.$t("general.button.deleting"), "toast-remove-tour-day-item");
      try {
        await api.tourDayItemResource.delete(
          this.agency.slug,
          Number(this.caseId),
          Number(this.tourId),
          itemResource?.tourDayId,
          itemResource?.tourDayItemIds[0],
          itemResource?.tourDayItemResourceIds[0]
        );

        this.loadTourPricing();

        if (!!this.currentTourDayItem && !!this.currentTourDayItem.id && tourDayItemId === this.currentTourDayItem.id) {
          this.$emit("closeItemDetailsOffCanvas");
        }

        this.loadTourPricing();
        this.status = "loaded";

        loadingToast.update("toast-remove-tour-day-item", {
          content: this.$t("tourBuilder.dayItemDeleted"),
          options: {
            type: TYPE.SUCCESS,
            icon: "icon icon-check-circle-fill",
            timeout: 5000,
          },
        });
      } catch (e: any) {
        loadingToast.update("toast-remove-tour-day-item", {
          content: this.$t("tourBuilder.dayItemDeleteError"),
          options: {
            type: TYPE.ERROR,
            icon: "icon icon-exclamation-triangle-fill",
            timeout: 5000,
          },
        });

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    clearCurrentTourDayItem() {
      this.userStore.setCurrentTourDayItem(null);
    },
    checkItemsInvoiceChecked(items: any) {
      const isAllCheckTrue = items.every((group: any) => group.invoiceChecked === true);

      if (isAllCheckTrue) {
        return true;
      } else {
        const isAllCheckFalse = items.every((group: any) => group.invoiceChecked === false);

        if (isAllCheckFalse) {
          return false;
        } else {
          return "mixed";
        }
      }
    },
    checkSupplier(supplier: any) {
      supplier.invoiceChecked = this.checkItemsInvoiceChecked(supplier.itemResourcesGrouped);
    },
    checkSupplierResource(itemResourcesGrouped: any, supplier: any) {
      itemResourcesGrouped.invoiceChecked = this.checkItemsInvoiceChecked(itemResourcesGrouped.resources);

      this.checkSupplier(supplier);
    },
    checkSupplierResourceGroup(supplier: any, itemResource: any) {
      itemResource.resources.forEach((resource: any) => {
        resource.invoiceChecked = itemResource.invoiceChecked;
      });

      this.checkSupplier(supplier);
    },
    async loadTourPricing(showLoader = false) {
      if (showLoader) {
        this.showLoader = true;
      }

      if (!!this.agency) {
        const pricing: TourDayItemResource[] = await api.tourPricing.all(
          this.agency.slug, this.caseId, this.tourId
        );

        const orderedByType = pricing.group((item: TourDayItemResource) => item.type);

        const pricingItems: IPricingItems[] = Object.keys(orderedByType).map((name: string) => {
          const tourDayItems: TourDayItemResource[] = orderedByType[name];

          // 1. Calculate the net, gross and markup for each type.
          const { netPrice, grossPrice, markup } = this.calculatePrice(tourDayItems);

          // 2. Get all suppliers within each type
          const suppliers = tourDayItems.reduce((values: Supplier[], item: TourDayItemResource) => {
            // 2.1. Search if the given supplier exists...
            const supplierInValues = !!values.find((i: Supplier) => i.id === item.resource.supplier?.id);

            // 2.2. If it doesn't exist, add it.
            if (!supplierInValues && !!item.resource.supplier) {
              values.push(item.resource.supplier);
            }

            return values;
          }, []);

          // 3. For each supplier add your items
          const suppliersMapped = suppliers.map((supplier: Supplier) => {
            const tourDayItemResources: TourDayItemResource[] = tourDayItems.filter(
              (item: TourDayItemResource) => item.resource.supplier?.id === supplier.id
            );

            tourDayItemResources.forEach((tourDayItemResource: any) => {
              tourDayItemResource.resource = {
                amenities: tourDayItemResource.resource.amenities,
                cancellationPolicy: tourDayItemResource.resource.cancellationPolicy,
                createdAt: tourDayItemResource.resource.createdAt,
                deletedAt: tourDayItemResource.resource.deletedAt,
                description: tourDayItemResource.resource.description,
                id: tourDayItemResource.resource.id,
                name: tourDayItemResource.resource.name,
                supplierId: tourDayItemResource.resource.supplierId,
                type: tourDayItemResource.resource.type,
                updatedAt: tourDayItemResource.resource.updatedAt,
                translations: tourDayItemResource.resource.translations,
              };
              // tourDayItemResource.account = supplier.account;
              // tourDayItemResource.paymentMethod = supplier.paymentMethod;
              tourDayItemResource.invoiceChecked = true;
            });

            this.sortTourDayItemResources(tourDayItemResources);

            const tourDayItemsResourcesGroup: {
              [key: string]: TourDayItemResource[];
            } = tourDayItemResources.reduce((acc, itemResource) => {
              const key = `${itemResource.resource.id}-${itemResource.date}-${itemResource.endDate}-${itemResource.netPrice}-${itemResource.markup}`;

              if (!acc[key]) {
                acc[key] = [];
              }

              acc[key].push(itemResource);

              return acc;
            }, {});

            const tourDayItemsResourcesGrouped = Object.keys(tourDayItemsResourcesGroup).map((key: string) => {
              const tourDayItemsResourcesGroupMapped = tourDayItemsResourcesGroup[key].map((item) => {
                const total = Number(item.netPrice);
                const paid = !!item.amountPaid ? Number(item.amountPaid) : 0;
                const amountPending = total - paid;
                
                return {
                  amountPaid: item.amountPaid,
                  amountPending: amountPending,
                  date: item.date,
                  endDate: item.endDate,
                  grossPrice: Number(item.grossPrice),
                  id: item.id,
                  invoiceChecked: true,
                  invoiceStatus: item.invoiceStatus,
                  isOptional: item.isOptional,
                  isUpdatedBySupplier: item.isUpdatedBySupplier,
                  supplierPrice: Number(item.supplierPrice),
                  markup: Number(item.markup),
                  markToPay: false,
                  modelId: item.modelId,
                  modelType: item.modelType,
                  name: item.resource.name,
                  netPrice: Number(item.netPrice),
                  netValidated: item.netValidated,
                  pax: item.pax,
                  paymentStatus: item.paymentStatus,
                  reservationStatus: item.reservationStatus,
                  splitPayment: item.paymentStatus === "partial",
                  stage: item.tourDayItem.stage,
                  supplierResourceId: item.resource.id,
                  tourDayId: item.tourDayItem.tourDayId,
                  tourDayItemId: item.tourDayItemId,
                  type: item.type,
                  paymentLink: item.paymentLink,
                };
              });

              const { netPrice, grossPrice, amountPaid, amountPending } = calculatePriceGroup(
                tourDayItemsResourcesGroup[key]
              );

              // Calculate net validated of group
              const netValidated: boolean | "mixed" = calculateAllNetValidated(tourDayItemsResourcesGroup[key]);

              // Calculate if all payments in the group are complete
              const paymentComplete: boolean | "mixed" = calculateAllPaymentStatus(
                tourDayItemsResourcesGroup[key],
                "complete"
              );
              const paymentPartial: boolean | "mixed" = calculateAllPaymentStatus(
                tourDayItemsResourcesGroup[key],
                "partial"
              );
              const paymentPending: boolean | "mixed" = calculateAllPaymentStatus(
                tourDayItemsResourcesGroup[key],
                "pending"
              );
              const paymentCancelled: boolean | "mixed" = calculateAllPaymentStatus(
                tourDayItemsResourcesGroup[key],
                "cancelled"
              );

              const ids = tourDayItemsResourcesGroup[key].map((itemResource) => itemResource.id);

              return {
                account: tourDayItemsResourcesGroup[key][0].account,
                amountPaid: amountPaid,
                amountPending: amountPending,
                date: tourDayItemsResourcesGroup[key][0].date,
                endDate: DateTime.fromISO(tourDayItemsResourcesGroup[key][0].endDate),
                grossPrice: grossPrice,
                id: ids.join("-"),
                invoiceChecked: true,
                isUpdatedBySupplier: tourDayItemsResourcesGroup[key][0].isUpdatedBySupplier,
                supplierPrice: Number(tourDayItemsResourcesGroup[key][0].supplierPrice),
                markup: Number(tourDayItemsResourcesGroup[key][0].markup),
                markToPay: false,
                name: tourDayItemsResourcesGroup[key][0].resource.name,
                netPrice: netPrice,
                netValidated: netValidated,
                paymentMethod: tourDayItemsResourcesGroup[key][0].paymentMethod,
                paymentLink: tourDayItemsResourcesGroup[key][0].paymentLink,
                paymentDate: tourDayItemsResourcesGroup[key][0].paymentDate,
                bookingType: tourDayItemsResourcesGroup[key][0].bookingType,
                bookingTypeMeta: tourDayItemsResourcesGroup[key][0].bookingTypeMeta,
                paymentComplete: paymentComplete,
                paymentPartial: paymentPartial,
                paymentPending: paymentPending,
                paymentCancelled: paymentCancelled,
                quantity: tourDayItemsResourcesGroup[key].length,
                resources: tourDayItemsResourcesGroupMapped,
                splitPayment: paymentPartial,
                stage: tourDayItemsResourcesGroup[key][0].tourDayItem.stage,
                tourDayId: tourDayItemsResourcesGroup[key][0].tourDayItem.tourDayId,
                tourDayItemIds: tourDayItemsResourcesGroup[key].map((itemResource) => itemResource.tourDayItem.id),
                tourDayItemResourceIds: ids,
                type: tourDayItemsResourcesGroup[key][0].type,
                typeResource: tourDayItemsResourcesGroup[key][0].resource.type,
              };
            });

            // supplier.tourDayItemResources = tourDayItemResources;
            supplier.itemResourcesGrouped = tourDayItemsResourcesGrouped;
            supplier.invoiceChecked = true;

            return {
              account: supplier.account,
              email: supplier.email,
              id: supplier.id,
              invoiceChecked: true,
              itemResourcesGrouped: tourDayItemsResourcesGrouped,
              mapUrl: supplier.mapUrl,
              name: supplier.name,
              paymentMethod: supplier.paymentMethod,
              paymentDate: supplier.paymentDate,
              bookingType: supplier.bookingType,
              bookingTypeMeta: supplier.bookingTypeMeta,
              phone: supplier.phone,
              preferredLanguage: supplier.preferredLanguage,
              priceRange: supplier.priceRange,
              siteUrl: supplier.siteUrl,
              type: supplier.type,
              typeEmail: supplier.typeEmail,
            };
          });
          
          return {
            name,
            suppliers: suppliersMapped,
            total: {
              grossPrice,
              markup,
              netPrice,
            },
          };
        });

        if (!!this.currentTour) {
          this.statusTotalPricing = "loading";
          this.pricing = {
            items: pricingItems,
            total: {
              netPrice: Number(this.currentTour.netPrice),
              grossPrice: Number(this.currentTour.grossPrice),
              markup: Number(this.currentTour.markup),
            },
          };
          this.statusTotalPricing = "loaded";
        }
      }

      if (showLoader) {
        this.showLoader = false;
      }
    },
    getAssociatedIcon(type: string) {
      let defaultIcon = "compass";

      if (!!type) {
        defaultIcon = this.itemTypes.find((item: any) => item.key === type)?.icon;
      }

      return defaultIcon;
    },
    sortTourDayItemResources(items: TourDayItemResource[]): TourDayItemResource[] {
      return items.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        const dateEndA = new Date(a.endDate);
        const dateEndB = new Date(b.endDate);

        if (dateA.getTime() === dateB.getTime()) {
          if (dateEndA.getTime() === dateEndB.getTime()) {
            return a.id - b.id;
          }

          return dateEndA.getTime() - dateEndB.getTime();
        }

        return dateA.getTime() - dateB.getTime();
      });
    },
    openCreateInvoiceOffCanvas() {
      this.totalInvoice = 0;
      this.items.types = [];
      this.items.resourceIds = [];
      const allResources: any = [];

      this.pricing.items.forEach((supplierType: IPricingItems) => {
        supplierType.suppliers.forEach((supplier: IPricingItemSupplier) => {
          const supplierData = {
            id: supplier.id,
            name: supplier.name,
          };

          supplier.itemResourcesGrouped.forEach((itemResource: IPricingItemSupplierResourceGrouped) => {
            itemResource.resources.forEach((resource: IPricingItemSupplierResource) => {
              const resourceData = {
                id: resource.supplierResourceId,
                name: resource.name,
              };

              if (resource.invoiceChecked) {
                allResources.push(resource);
                this.items.resourceIds.push(resource.id);

                const typeFounded = this.items.types.find((type: any) => type.name === supplierType.name);

                if (!typeFounded) {
                  const type = {
                    name: supplierType.name,
                    suppliers: [
                      {
                        ...supplierData,
                        itemResources: [
                          {
                            ...resourceData,
                            resources: [resource.id],
                          },
                        ],
                      },
                    ],
                  };

                  this.items.types.push(type);
                } else {
                  const supplierFounded = typeFounded.suppliers.find(
                    (supplierAux: any) => supplierAux.id === supplier.id
                  );

                  if (!supplierFounded) {
                    typeFounded.suppliers.push({
                      ...supplierData,
                      itemResources: [
                        {
                          ...resourceData,
                          resources: [resource.id],
                        },
                      ],
                    });
                  } else {
                    const itemResourceFounded = supplierFounded.itemResources.find(
                      (itemResourceAux: any) => itemResourceAux.id === resource.supplierResourceId
                    );

                    if (!itemResourceFounded) {
                      supplierFounded.itemResources.push({
                        ...resourceData,
                        resources: [resource.id],
                      });
                    } else {
                      itemResourceFounded.resources.push(resource.id);
                    }
                  }
                }
              }
            });
          });
        });
      });

      let totalInvoice = allResources.reduce(
        (sum: number, item: IPricingItemSupplierResource) => sum + (Number(item.grossPrice) || 0),
        0
      );

      if (totalInvoice % 1 !== 0) {
        totalInvoice = Number(totalInvoice.toFixed(2));
      }

      this.totalInvoice = totalInvoice;

      if (allResources.length === 0) {
        this.$toast.error(this.$t("tourPricing.invoice.noResourcesSelectedMessage"));
      } else {
        this.openOffCanvas("the-pricing-invoice-create-off-canvas");
      }
    },
    totalNetPriceTour(data: IPricingItemSupplierResourceGrouped[]): number {
      return data.reduce((total: number, item: IPricingItemSupplierResourceGrouped) => {
        return total + item.netPrice;
      }, 0);
    },
    totalAmountPaid(hotelName: string, data: IPricingItemSupplierResourceGrouped[]): number {
      const hotelData = this.getHotelData(hotelName);
      return data.reduce((total: number, item: IPricingItemSupplierResourceGrouped) => {
        // Convert the netPrice property to a number before adding to the total
        const amountPaidAsNumber = item.amountPaid;
        hotelData.paidAmount = total + amountPaidAsNumber;
        return total + amountPaidAsNumber;
      }, 0);
    },
    totalPendingPriceDayTour(hotelName: string, data: IPricingItemSupplierResourceGrouped[]): number {
      const priceStore = usePriceStore();
      const totalNetPrice = this.totalNetPriceTour(data);
      const totalAmountPaid = this.totalAmountPaid(hotelName, data);

      const hotelData = this.getHotelData(hotelName);

      const dataIds = data.flatMap((item: IPricingItemSupplierResourceGrouped) => item.tourDayItemResourceIds);
      const matchingPrices = priceStore.price.filter((price) => dataIds.includes(price.id));

      if (matchingPrices.length !== 0) {
        const totalReadyToPrice = matchingPrices.reduce((sum, price) => sum + price.readyToPrice, 0);
        hotelData.pendingAmount = totalNetPrice - totalAmountPaid - totalReadyToPrice;
        return hotelData.pendingAmount;
      }
      hotelData.pendingAmount = totalNetPrice - totalAmountPaid;
      return totalNetPrice - totalAmountPaid;
    },
    totalPayablePriceDayTour(hotelName: string, data: IPricingItemSupplierResourceGrouped[]): number {
      const priceStore = usePriceStore();
      const hotelData = this.getHotelData(hotelName);

      const dataIds = data.flatMap((item: IPricingItemSupplierResourceGrouped) => item.tourDayItemResourceIds);

      const matchingPrices = priceStore.price.filter((price) => dataIds.includes(price.id));

      if (matchingPrices.length !== 0) {
        const totalReadyToPrice = matchingPrices.reduce((sum, price) => sum + price.readyToPrice, 0);
        hotelData.payableAmount = totalReadyToPrice;
        return hotelData.payableAmount;
      }

      hotelData.payableAmount = 0;
      return 0;
    },
    getHotelData(hotelName: string): HotelDataType {
      let hotelData = this.summaryData.find((data) => data.hotelName === hotelName);

      if (!hotelData) {
        hotelData = { hotelName };
        this.summaryData.push(hotelData);
      }

      return hotelData;
    },
    openModal() {
      this.showmodal = true;
    },
    closeModal() {
      this.showmodal = false;
    },
  },
  mounted() {
    const priceStore = usePriceStore();
    priceStore.clearPriceData();
  },
  unmounted() {
    this.userStore.setCurrentTourDayItem(null);
  },
});
</script>

<style lang="scss" scoped>
.preview-container {
  width: 41%;
  margin-right: 2rem;
  margin-top: 0.5rem;

  @media (max-width: 992px) {
    width: 65%;
  }
}

.accordion-toggle {
  cursor: pointer;
}

.status-title {
  height: 49px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.suppliers-header {
  .th {
    padding-left: 2.75rem !important;
  }
}

.suppliers-header,
.resources-profit-header {
  .th {
    padding: 0.5rem 0.375rem;
    font-weight: 500;
    font-size: 0.875rem;

    @media (min-width: 1400px) {
      font-size: 1rem;
    }
  }
}

.suppliers-body {
  .supplier-container {
    border: 1px solid var(--uq-gray-400);
    border-radius: 0.25rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .supplier-data {
    position: relative;

    .td {
      padding-inline: 0.375rem;
      font-weight: 300;
    }
  }

  .supplier-name {
    font-size: 0.875rem;

    @media (min-width: 992px) {
      font-size: 1rem;
    }

    @media (min-width: 1200px) {
      font-size: 1.125rem;
    }
  }

  .row {
    padding: 0.375rem;
    font-size: 0.875rem;

    @media (min-width: 992px) {
      font-size: 1rem;
    }

    .td {
      padding-inline: 0.375rem;
      font-weight: 300;
    }
  }
}

.item-list {
  .resources-container {
    position: relative;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--uq-secondary);
    }
  }

  .type-total,
  .pricing-total {
    padding-block: 1rem;
    border: 1px solid var(--uq-gray-400);
    border-radius: 0.25rem;
  }
}

.spinner-border {
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  z-index: 1050;
}

.suppliers-body {
  .tour-day-item-resource-container,
  .wrapper {
    position: relative;
    padding-top: 0.75rem;

    &:not(:last-child) {
      padding-bottom: 0.75rem;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--uq-gray-300);
    }

    &.bg-gray-200 {
      :deep(.form-outline .label span) {
        background-color: #f7f7fc !important;
      }

      :deep(.input-group .input-group-text) {
        background-color: #f0f0f9;
      }
    }

    :deep(.input-group .input-group-text) {
      background-color: #f7f7fc;
    }
  }

  .mobile-view {
    display: none;
  }

  .sub-container {
    width: 44%;
  }

  .form-check-inline {
    margin-top: 8px;
  }

  @media (max-width: 993px) {
    .normal-view {
      display: none;
    }

    .mobile-view {
      display: flex;
    }

    .sub-container {
      width: 100%;
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }
}

.dashed:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 1px dashed var(--uq-gray-400);
}

.accordion-toggle {
  .accordion-icon {
    right: 0.5rem;
  }
}

.icon-container {
  display: none;
}

.hover-container:hover .icon-container {
  display: flex;
}

.icon-layout {
  margin-left: -3.5rem;
}

.send-notification {
  position: absolute;
  top: 0.4rem;
  right: 3rem;
}

.supplier-info {
  display: flex;
  justify-content: end;
  margin: 0 auto;
  width: 100%;
  margin-top: -4px;
}

.hidden-conatiner {
  width: 2.5rem;
}
</style>
