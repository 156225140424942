<template>
  <v-modal
    id="show-media-modal"
    class-name="modal-fullscreen-sm-down"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title/>
    </modal-header>

    <modal-body class="justify-content-center">
      <v-picture>
        <img
          :alt="!!media.previewUrl ? media.previewUrl : !!media.preview_url ? media.preview_url : media.storageUrl"
          :src="!!media.previewUrl ? media.previewUrl : !!media.preview_url ? media.preview_url : media.storageUrl"
        />
      </v-picture>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import Media from '@/api/objects/Media';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';

export default defineComponent({
  name: 'VShowMediaModal',
  components: {
    VPicture,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  emits: [
    'closed',
  ],
  props: {
    media: {
      type: Media,
      required: true,
    },
  },
});
</script>

<style lang="scss">
#show-media-modal {
  .modal-content {
    height: 100%;

    .modal-body {
      overflow-y: auto;
      display: flex;
    }
  }
}
</style>
