<template>
  <form
    :id="id"
    class="d-flex flex-column justify-content-between h-100"
  >
    <fieldset class="mb-24">
      <legend :class="{ 'visually-hidden': hiddenLegend }">{{ $t('admin.user.basicInformation') }}</legend>

      <div class="row gx-12">
        <v-form-input
          :id="`${idPrefix}-contact-name`"
          v-model="name"
          :label="`${$t('general.shared.name')}*`"
          :yup-errors-variable="errors.name"
          autocomplete="off"
          class="col-12 col-lg-6"
          form-type="outline"
        />

        <v-form-input
          :id="`${idPrefix}-contact-surname`"
          v-model="surname"
          :label="$t('general.shared.surname')"
          :yup-errors-variable="errors.surname"
          autocomplete="off"
          class="col-12 col-lg-6"
          form-type="outline"
        />

        <v-form-input-date
          :id="`${idPrefix}-contact-birthday-date`"
          v-model="dateBirth"
          :label="$t('general.shared.dateBirth')"
          :yup-errors-variable="errors.dateBirth"
          class="col-12 col-lg-4"
          form-type="outline"
        />

        <div class="form-outline col-12 col-lg-4">
          <label class="label">
            <span>{{ $t('general.shared.genre') }}</span>
          </label>

          <v-select
            :id="`${idPrefix}-contact-genre`"
            v-model="genre"
            :clearable="false"
            :options="genres"
            :reduce="returnValue => returnValue.key"
            class="mb-8"
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
            </template>
          </v-select>
        </div>

        <v-form-input
          :id="`${idPrefix}-contact-language`"
          v-model="language"
          :label="$t('general.shared.language')"
          :yup-errors-variable="errors.language"
          autocomplete="off"
          class="col-12 col-lg-4"
          form-type="outline"
        />

        <v-form-input
          :id="`${idPrefix}-contact-note`"
          v-model="note"
          :label="$t('general.shared.note')"
          :yup-errors-variable="errors.note"
          autocomplete="off"
          form-type="outline"
          input-type="textarea"
          rows="5"
        />
      </div>
    </fieldset>

    <fieldset class="mb-24">
      <legend>{{ $t('general.shared.contactInformation') }}</legend>

      <div class="row gx-12">
        <v-form-input
          :id="`${idPrefix}-contact-email`"
          v-model="email"
          :label="$t('general.shared.email')"
          :yup-errors-variable="errors.email"
          autocomplete="off"
          class="col-12"
          form-type="outline"
        />

        <div class="form-outline col-12 col-lg-5">
          <label class="label">
            <span>{{ $t('general.shared.countryCode') }}</span>
          </label>

          <v-select
            :id="`${idPrefix}-contact-country-code`"
            key="value"
            v-model="countryCode"
            :class="{ 'is-invalid': !!errors.countryCode }"
            :clearable="false"
            :options="countryCodes"
            :placeholder="$t('general.shared.countryCodePlaceholder')"
            :reduce="prefix => prefix.value"
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
            </template>
          </v-select>

          <span v-if="!!errors.countryCode" class="invalid-feedback">
            {{ errors.countryCode }}
          </span>
        </div>

        <v-form-input
          :id="`${idPrefix}-contact-phone`"
          v-model="phone"
          :label="$t('general.shared.phone')"
          :yup-errors-variable="errors.phone"
          autocomplete="off"
          class="col-12 col-lg-7"
          form-type="outline"
        />

        <v-form-input
          :id="`${idPrefix}-contact-web`"
          v-model="web"
          :label="$t('general.shared.web')"
          :yup-errors-variable="errors.web"
          autocomplete="off"
          class="col-12"
          form-type="outline"
        />
      </div>
    </fieldset>

    <v-contact-billing-form
      :id="`${idPrefix}-contact-address-form`"
      :ref="`${idPrefix}-contact-address-form-ref`"
      :billing-always-visible="!!contact"
      :contact-and-billing-matches="!contact"
      :data="addressData"
      :hidden-legend="false"
      :id-prefix="`${contactableType}-contact-create`"
      :without-billing-address="true"
      @updated-data="addressData = $event"
      @is-valid="addressValid = $event"
    />

    <v-button
      :disabled="!meta.valid || disableCreateButton"
      :is-loading="disableCreateButton"
      class="btn-submit-form btn-icon w-fit align-self-end"
      variant="primary"
      @click="createContact"
    >
      {{ buttonText }}
      <v-icon icon="arrow-right" size="sm" space="ms-12"/>
    </v-button>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { VFormInput, VFormInputDate } from '@uniqoders/form';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import vSelect from 'vue-select';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import useFormValidation from '@/helpers/form';
import useConcepts from '@/helpers/Concepts';
import { mapConcepts, sortConcepts } from '@/helpers/ConceptHelper';
import Contact from '@/api/objects/Contact';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';

export default defineComponent({
  name: 'VContactForm',
  components: {
    VContactBillingForm,
    VIcon,
    VButton,
    VFormInput,
    VFormInputDate,
    vSelect,
  },
  emits: ['reloadItem', 'closed'],
  props: {
    id: {
      type: String,
      required: false,
      default: 'contact-form',
    },
    contactableType: {
      type: String,
      required: false,
    },
    contactableId: {
      type: Number,
      required: false,
    },
    isCreateContact: {
      type: Boolean,
      required: false,
      default: true,
    },
    idPrefix: {
      type: String,
      required: false,
      default: 'item',
    },
    hiddenLegend: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonText: {
      type: String,
      required: false,
    },
    contact: {
      type: Contact,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      surname: yup.string()
        .nullable()
        .label(t('general.shared.surname')),
      dateBirth: yup.string()
        .nullable()
        .label(t('general.shared.dateBirth')),
      genre: yup.string()
        .nullable()
        .label(t('general.shared.genre')),
      language: yup.string()
        .nullable()
        .label(t('general.shared.language')),
      countryCode: yup.string()
        .nullable()
        .label(t('general.shared.countryCode')),
      phone: yup.string()
        .nullable()
        .label(t('general.shared.phone')),
      email: yup.string()
        .email()
        .nullable()
        .label(t('general.shared.email')),
      web: yup.string()
        .nullable()
        .label(t('general.shared.web')),
      note: yup.string()
        .nullable()
        .label(t('general.shared.note')),
    });

    const initialValues: any = {};

    // eslint-disable-next-line vue/no-setup-props-destructure
    const { contact } = props;
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { isCreateContact } = props;

    if (!isCreateContact && !!contact) {
      initialValues.name = contact.name;
      initialValues.surname = contact.surname;
      initialValues.dateBirth = contact.birthday;
      initialValues.genre = contact.genre;
      initialValues.language = contact.language;
      initialValues.email = contact.email;
      initialValues.web = contact.web;
      initialValues.note = contact.note;

      if (!!contact.phone) {
        const tmpWholePhone: string[] = (contact.phone as string).split(/\s+/);
        if (!!tmpWholePhone) {
          const [tmpCode, tmpNumber] = tmpWholePhone;

          initialValues.countryCode = tmpCode;
          initialValues.phone = tmpNumber;
        }
      }
    }

    const form = useFormValidation(rules, initialValues);

    const { value: name } = useField('name');
    const { value: surname } = useField('surname');
    const { value: dateBirth } = useField('dateBirth');
    const { value: genre } = useField('genre');
    const { value: language } = useField('language');
    const { value: countryCode } = useField('countryCode');
    const { value: phone } = useField('phone');
    const { value: email } = useField('email');
    const { value: web } = useField('web');
    const { value: note } = useField('note');

    return {
      ...useConcepts(),
      ...form,
      name,
      surname,
      dateBirth,
      genre,
      language,
      countryCode,
      phone,
      email,
      web,
      note,
    };
  },
  data() {
    return {
      addressData: {} as any,
      addressValid: false as boolean,
      disableCreateButton: false as boolean,
    };
  },
  computed: {
    contactTypes(): any {
      if (!!this.concepts && this.concepts['contact.type']) {
        return mapConcepts(this.concepts['contact.type']);
      }

      return [];
    },
    countryCodes(): any {
      if (!!this.concepts && this.concepts['app.countryPhones']) {
        return mapConcepts(sortConcepts(this.concepts['app.countryPhones']));
      }

      return [];
    },
    genres(): any {
      if (!!this.concepts && this.concepts['app.genres']) {
        return mapConcepts(this.concepts['app.genres']);
      }

      return [];
    },
  },
  created() {
    if (!this.isCreateContact) {
      this.setData();
    }
  },
  methods: {
    setData() {
      if (!!this.contact) {
        this.addressData.contactAddress1 = this.contact.contactAddress1;
        this.addressData.contactAddress2 = this.contact.contactAddress2;
        this.addressData.contactPostalCode = this.contact.contactPostalCode;
        this.addressData.contactCountry = this.contact.contactCountry;
        this.addressData.contactCity = this.contact.contactCity;
        this.addressData.contactState = this.contact.contactState;
      }
    },
    async createContact() {
      try {
        this.disableCreateButton = true;

        let phoneTmp = '';
        if (!!this.countryCode && !!this.phone) {
          phoneTmp = `${this.countryCode} ${this.phone.toString().replace(/\s/g, '')}`;
        }

        const data: any = {
          name: this.name,
          surname: this.surname,
          birthday: this.dateBirth,
          genre: this.genre,
          language: this.language,
          phone: phoneTmp,
          email: this.email,
          web: this.web,
          note: this.note,
          location_id: this.addressData.locationId,
          contact_address_1: this.addressData.contactAddress1,
          contact_address_2: this.addressData.contactAddress2,
          contact_postal_code: this.addressData.contactPostalCode,
          contact_country: this.addressData.contactCountry,
          contact_city: this.addressData.contactCity,
          contact_state: this.addressData.contactState,
          billing_address_1: this.addressData.billingAddress1,
          billing_address_2: this.addressData.billingAddress2,
          billing_postal_code: this.addressData.billingPostalCode,
          billing_country: this.addressData.billingCountry,
          billing_city: this.addressData.billingCity,
          billing_state: this.addressData.billingState,
        };

        if (this.isCreateContact) {
          data.type = this.contactableType;
          data.contactable_type = this.contactableType;
          data.contactable_id = this.contactableId;

          await api.contact.create(data);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.$emit('closed');
        } else if (!!this.contact) {
          await api.contact.update(this.contact.id, data);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.$emit('closed');
        }

        this.$emit('reloadItem');
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableCreateButton = false;
      }
    },
  },
});
</script>
