<template>
  <v-off-canvas
    id="client-create-offcanvas"
    :backdrop-static="true"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
    <off-canvas-header>
      <h5 class="offcanvas-title">{{ $t('client.createNewClient') }}</h5>
    </off-canvas-header>

    <off-canvas-body>
      <form
        id="client-create-form"
        class="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <fieldset>
            <legend class="visually-hidden">{{ $t('client.basicInformation') }}</legend>

            <v-form-input
              id="client-create-name"
              v-model="name"
              :label="`${$t('general.shared.name')}*`"
              :yup-errors-variable="errors.name"
              autocomplete="off"
              class="mb-8"
              form-type="outline"
            />

            <v-form-input
              id="client-create-surname"
              v-model="surname"
              :label="$t('general.shared.surname')"
              :yup-errors-variable="errors.surname"
              autocomplete="off"
              class="mb-8"
              form-type="outline"
            />

            <v-form-input
              id="client-create-email"
              v-model="email"
              :label="`${$t('general.shared.email')}*`"
              :yup-errors-variable="errors.email"
              autocomplete="off"
              class="mb-8"
              form-type="outline"
              type="email"
            />

            <v-form
              id="client-create-country-code"
              :label="$t('general.shared.countryCode')"
              :yup-errors-variable="errors.countryCode"
              class="mb-8"
              form-type="outline"
            >
              <v-select
                key="value"
                v-model="countryCode"
                :class="{ 'is-invalid': !!errors.countryCode }"
                :clearable="true"
                :options="countryCodes"
                :placeholder="$t('general.shared.countryCodePlaceholder')"
                :reduce="prefix => prefix.value"
              >
                <template v-slot:no-options>
                  <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                </template>
              </v-select>
            </v-form>

            <v-form-input
              id="client-create-contact-phone"
              v-model="phone"
              :label="$t('general.shared.phone')"
              :yup-errors-variable="errors.phone"
              autocomplete="off"
              class="mb-8"
              form-type="outline"
            />

            <v-form
              id="client-create-preferred-language"
              :label="$t('general.shared.preferredLanguage')"
              :yup-errors-variable="errors.preferredLanguage"
              class="mb-8"
              form-type="outline"
            >
              <v-select
                v-model="preferredLanguage"
                :class="{ 'is-invalid': !!errors.preferredLanguage }"
                :options="languages"
                :reduce="returnValue => returnValue.key"
              >
                <template v-slot:no-options>
                  <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                </template>
              </v-select>
            </v-form>

            <div class="form-outline mb-20">
              <label class="label">
                <span>{{ $t('general.shared.type') }}</span>
              </label>

              <v-select
                id="client-create-source-type-select"
                v-model="source"
                :clearable="false"
                :options="sourceTypes"
                :reduce="returnValue => returnValue.key"
                class="mb-16"
              >
                <template v-slot:no-options>
                  <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                </template>
              </v-select>
            </div>
          </fieldset>

          <v-contact-billing-form
            id="client-create-address-form"
            ref="client-create-address-form-ref"
            :data="addressData"
            id-prefix="client-create"
            @updated-data="addressData = $event"
            @is-valid="addressValid = $event"
          />
        </div>

        <v-button
          :disabled="!meta.valid || !addressValid || accepted"
          :is-loading="accepted"
          class="btn-submit-form btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="createClient"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </form>
    </off-canvas-body>
    </resizable-container> 
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import vSelect from 'vue-select';
import { VForm, VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import { mapState } from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import { mapConcepts, sortConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';
import { useAuthStore } from '@/stores/auth';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheClientCreateOffcanvas',
  components: {
    VContactBillingForm,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VFormInput,
    vSelect,
    VForm,
    ResizableContainer,
  },
  emits: [
    'closed',
    'reloadClients',
    'openDetails',
  ],
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
      surname: yup.string()
        .nullable()
        .label(t('general.shared.surname')),
      email: yup.string()
        .email()
        .required()
        .label(t('general.shared.email')),
      countryCode: yup.string()
        .nullable()
        .label(t('general.shared.countryCode')),
      phone: yup.string()
        .nullable()
        .label(t('general.shared.phone')),
      preferredLanguage: yup.string()
        .nullable()
        .label(t('general.shared.preferredLanguage')),
      source: yup.string()
        .nullable()
        .label(t('general.shared.type')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: surname } = useField('surname');
    const { value: email } = useField('email');
    const { value: countryCode } = useField('countryCode');
    const { value: phone } = useField('phone');
    const { value: preferredLanguage } = useField('preferredLanguage');
    const { value: source } = useField('source');

    return {
      ...useConcepts(),
      ...form,
      name,
      surname,
      email,
      countryCode,
      phone,
      preferredLanguage,
      source,
    };
  },
  data() {
    return {
      addressData: {} as any,
      addressValid: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    languages(): any {
      if (!!this.concepts && this.concepts['app.languages']) {
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
    sourceTypes(): any {
      if (!!this.concepts && this.concepts['client.source_type']) {
        return mapConcepts(this.concepts['client.source_type']);
      }

      return [];
    },
    countryCodes(): any {
      if (!!this.concepts && this.concepts['app.countryPhones']) {
        return mapConcepts(sortConcepts(this.concepts['app.countryPhones']));
      }

      return [];
    },
  },
  methods: {
    async createClient() {
      try {
        this.toggleAccepted();

        let phoneTmp = '';

        if (!!this.countryCode && !!this.phone) {
          phoneTmp = `${this.countryCode} ${this.phone.toString().replace(/\s/g, '')}`;
        }

        const data: any = {
          name: this.name,
          surname: this.surname,
          email: this.email,
          source: this.source,
          phone: phoneTmp,
          location_id: this.addressData.locationId,
          contact_address_1: this.addressData.contactAddress1,
          contact_address_2: this.addressData.contactAddress2,
          contact_postal_code: this.addressData.contactPostalCode,
          contact_country: this.addressData.contactCountry,
          contact_city: this.addressData.contactCity,
          contact_state: this.addressData.contactState,
          billing_address_1: this.addressData.billingAddress1,
          billing_address_2: this.addressData.billingAddress2,
          billing_postal_code: this.addressData.billingPostalCode,
          billing_country: this.addressData.billingCountry,
          billing_city: this.addressData.billingCity,
          billing_state: this.addressData.billingState,
          preferred_language: !!this.preferredLanguage ? this.preferredLanguage : this.defaultLocale,
        };

        if (!!this.agency) {
          const response = await api.client.create(this.agency.slug, data);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.resetForm();

          (this.$refs['client-create-address-form-ref'] as any).clearForm();

          this.$emit('openDetails', response);
          this.$emit('reloadClients');
        }
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
      }
    },
  },
});
</script>
