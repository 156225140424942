<template>
    <div class="reservation-confirm-container">
        <main class="bg-tertiary-faded">
            <div class="container">
                <div class="pb-48 pt-sm-120 pt-lg-96 d-flex align-items-center">
                    <div class="w-100 bg-light rounded-xs border-0 py-32 px-24 p-lg-56">
                        <Transition mode="out-in" name="fade-in">
                            <div v-if="status === 'loading'" class="position-relative">
                                <h1 class="h4 text-center mb-32 mb-lg-56">{{ $t("general.pages.confirmingReservation")
                                    }}</h1>

                                <div class="position-relative py-48">
                                    <v-loader />
                                </div>
                            </div>

                            <h1 v-else class="h4 text-center">
                                <p>{{ $t("general.pages.reservationConfirmed") }}</p>
                                <p>{{ $t("general.pages.confirmEmailStatus") }}</p>
                            </h1>
                        </Transition>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import VLoader from "@/components/vendor/loader/VLoader.vue";

export default defineComponent({
    name: "TheConfirmPriceReservation",
    components: {
        VLoader,
    },
    props: {
        status: {
            type: String,
        }
    } 
});
</script>