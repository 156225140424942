<template>
  <div id="page-optional-resources">
    <div class="page-inner container">
      <h3 class="h5 mb-12">{{ $t('clientTour.optionals') }}</h3>

      <div class="optional-container">
        <div
          v-for="(tourDay) in tourDays"
          :key="tourDay.id"
          class=""
        >
          <div class="">
            <h4 class="h6 mb-12 fw-semi">
              {{ $t('travelBook.day') }} {{ tourDay.day }}
              <span class="fw-medium text-normal text-sm">
                ({{ $str.formatDateTime(tourDay.date, 'dd MMMM yyyy') }})
              </span>
            </h4>

            <div
              v-for="dayItem in mapTourDayItems(tourDay.items)"
              :key="`tour-optionals-${dayItem.type}`"
              class="day-data p-16 p-lg-24"
            >
              <div class="data-container-info text-sm break-avoid">
                <div class="fw-semi d-flex align-items-center text-uppercase mb-16">
                  <v-icon :icon="findConceptByKey('supplier.type', dayItem.type).icon" size="xl" space="me-12"/>

                  <p class="mb-0">
                    {{ getDayItemType(dayItem.type) }}
                  </p>
                </div>

                <div
                  v-for="resource in mapItemResources(dayItem.items)"
                  :key="resource.name"
                  class="fw-semi d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-16
                  ps-16 ps-md-36 gap-md-48"
                >
                  <p
                    class="resource-name mb-0"
                    :title="resource.count > 1 ? `${resource.name} x${resource.count}` : `${resource.name}`"
                  >
                    {{ `${resource.name}` }}
                    <span v-if="resource.count > 1">{{ ` x${resource.count}` }}</span>
                  </p>

                  <div class="d-flex justify-content-between gap-md-32">
                    <p class="mb-0">
                      {{ resource.priceFormatted }}
                    </p>

                    <p class="mb-0">
                      ({{ resource.totalPrice.toFixed(2).toString().replace('.', ',') }}€)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import TourDay from '@/api/objects/TourDay';
import {findConceptByKey} from '@/helpers/ConceptHelper';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import TourDayItem from "@/api/objects/TourDayItem";

export default defineComponent({
  name: 'TheTravelBookOptionalResources',
  components: {
    VIcon,
  },
  props: {
    tourDays: {
      type: Array as PropType<TourDay[]>,
      required: true,
    },
  },
  setup() {
    return {
      findConceptByKey,
    };
  },
  methods: {
    getDayItemType(type: string): string {
      if (type === 'hotel') {
        return this.$t('travelBook.accommodation');
      }

      return findConceptByKey('supplier.type', type).name;
    },
    mapTourDayItems(tourDayItems: TourDayItem[]): any[] {
      const itemsGroupByType = tourDayItems.group((item: TourDayItem) => item.type);

      return Object.keys(itemsGroupByType)
        .map((type: string) => {
          const tourDayItems = itemsGroupByType[type];

          let items: any[] = [];

          tourDayItems.forEach((tourDayItem: TourDayItem) => {
            items = items.concat(tourDayItem.resources);
          })

          return {
            type,
            items,
          }
        });
    },
    mapItemResources(itemResources: any): any {
      return itemResources.reduce((acc: any, resource: any) => {
        const clave = resource.resource.name;
        const price = parseFloat(resource.grossPrice);

        const itemFound = acc.find((item: any) => item.name === clave && item.price === price);

        if (itemFound) {
          itemFound.count++;
          itemFound.totalPrice += parseFloat(resource.grossPrice);
        } else {
          acc.push({
            name: resource.resource.name,
            count: 1,
            price: parseFloat(resource.grossPrice),
            totalPrice: parseFloat(resource.grossPrice),
            priceFormatted: `${resource.grossPrice.replace('.', ',')}${this.$t('travelBook.pricePerUnit')}`,
          });
        }

        return acc;
      }, []);
    },
  },
});
</script>

<style lang="scss" scoped>
.hide-print {
  @media print {
    display: none;
  }
}

.resource-name {
  @media only screen {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (min-width: 768px) {
      -webkit-line-clamp: 1;
    }
  }
}

.day-data {
  break-inside: avoid;
  margin-bottom: 1.75rem;
  background-color: var(--uq-white);
  border-radius: .5rem;
  box-shadow: 0 1px .25rem var(--uq-gray-300);

  .data-container-info:not(:last-child) {
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--uq-gray-400);
    }
  }
}
</style>
