<template>
  <div v-if="canShow">
    <div v-if="updatingTour" class="loader-tour-container">
      <v-loader />
    </div>

    <div v-if="status === 'loaded'" :class="{ 'opacity-50': updatingTour }">
      <div class="mb-16">{{ templateName }}</div>

      <div class="w-100 d-flex justify-content-center">
        <v-button :disabled="updatingTour" class="btn-icon p-4 rounded-circle" size="sm" variant="gradient"
          @click="addTemplateDayAtFirst()">
          <v-icon icon="plus" />
        </v-button>
      </div>

      <div class="tour-container">
        <the-template-day-draggable v-model="tourDays" :agency="agency.slug" :current-tour-day="currentTemplateDayId"
          :end-date="currentTemplate.endDate" :init-date="currentTemplate.initDate" :template-id="tourMapped.id"
          :tour-mapped="tourMappedOriginal" :updating-tour="updatingTour" class="mb-32" type="tour-day"
          @day-moved="updateTemplateDay" @emit-add-day="addTemplateDay" @reload-tour="refreshCurrentTemplate"
          @open-item-details-off-canvas="openItemDetailsOffCanvas"
          @open-day-information-off-canvas="openDayInformationOffCanvas"
          @move-item-to-another-day="moveItemToAnotherDay" />
      </div>

      <div class="w-100 d-flex justify-content-center">
        <v-button :disabled="updatingTour" class="btn-icon p-4 rounded-circle" size="sm" variant="gradient"
          @click="addTemplateDayAtEnd()">
          <v-icon icon="plus" />
        </v-button>
      </div>
    </div>

    <v-loader v-else />

    <the-template-day-item-details-off-canvas v-if="offCanvasToShow === 'the-template-day-item-details-off-canvas'"
      :agency="agency.slug" :status="statusTourDayItem" :template-id="Number(template.id)"
      @closed="closeOffCanvas(); clearCurrentTourDayItem()" @emit-reload-tour="refreshCurrentTemplate" />

    <the-template-day-information-off-canvas v-if="offCanvasToShow === 'the-template-day-information-off-canvas' && !!currentTemplateDayId
      && !!currentTemplateDayStage" :agency="agency.slug" :template-day-id="currentTemplateDayId"
      :template-day-stage="currentTemplateDayStage" :template-id="template.id"
      @closed="closeOffCanvas(); currentTemplateDayId = null; currentTemplateDayStage = null"
      @emit-reload-template="refreshCurrentTemplate" />
  </div>

  <the-screen-size-verify @can-show="canShow = $event" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { mapState } from 'pinia';
import { TYPE } from 'vue-toastification';
import api from '@/api';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { useAuthStore } from '@/stores/auth';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import { useUserStore } from '@/stores/user';
import Template from '@/api/objects/Template';
import TheTemplateDayDraggable from '@/components/template/TheTemplateDayDraggable.vue';
import TheTemplateDayItemDetailsOffCanvas from '@/components/template/TheTemplateDayItemDetailsOffCanvas.vue';
import TourDayItemResource from '@/api/objects/TourDayItemResource';
import TheTemplateDayInformationOffCanvas from '@/components/template/TheTemplateDayInformationOffCanvas.vue';
import TheScreenSizeVerify from '@/components/vendor/TheScreenSizeVerify.vue';

export default defineComponent({
  name: 'TheTemplateBuilder',
  components: {
    TheScreenSizeVerify,
    TheTemplateDayInformationOffCanvas,
    TheTemplateDayItemDetailsOffCanvas,
    TheTemplateDayDraggable,
    VLoader,
    VIcon,
    VButton,
  },
  props: {
    template: {
      type: Template,
      required: true,
    },
  },
  emits: ['reloadTemplate'],
  setup() {
    const userStore = useUserStore();

    return {
      ...useOffCanvasUtils(),
      userStore,
    };
  },
  data() {
    return {
      canShow: false,
      statusTourDayItem: 'loading' as string,
      tourMapped: {} as Record<string, any>,
      tourMappedOriginal: {} as Record<string, any>,
      tourDays: [] as Record<string, any>[],
      status: 'loading' as string,
      updatingTour: false as boolean,
      templateName: '' as string,
      templateDates: [] as string[],
      currentTemplateDayId: null as null | number,
      currentTemplateDayStage: null as null | number,
      isCollaborator: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    ...mapState(useUserStore, ['currentTourDayItem', 'currentTemplate']),
  },
  watch: {
    async templateId(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.loadTour();
      }
    },
  },
  async created() {
    await this.loadTour();
  },
  methods: {
    async loadTour() {
      this.updatingTour = true;
      this.templateDates = [];
      this.tourMapped = {};
      this.tourMappedOriginal = {};

      if (!!this.currentTemplate) {
        this.isCollaborator = this.currentTemplate.isCollaborator;
        this.currentTemplate.tourDays.forEach((tourDay: Record<string, any>) => {
          this.templateDates.push(tourDay.date);
        });

        this.templateName = this.currentTemplate.name;

        await this.mapTour(this.currentTemplate);
      }

      this.updatingTour = false;
    },
    mapTour(tour: Record<string, any>) {
      const tourDaysItemsHotelCache: Record<string, any>[] = [];

      const tourDays: Record<string, any>[] = tour.tourDays.map((tourDay: Record<string, any>) => {
        const tourDayItems = tourDay.items.map((tourDayItem: Record<string, any>) => {
          let supplierName = '';

          if (tourDayItem.resources.length > 0) {
            const defaultSupplierName = `(${this.$t('general.shared.supplierNotFound')})`;

            const resourceName = tourDayItem.resources[0]?.resource?.name;
            supplierName = tourDayItem.supplierName || resourceName || defaultSupplierName;
          }

          const tourDayItemMapped: Record<string, any> = {
            id: tourDayItem.id,
            ...tourDayItem,
            disabled: false,
            supplier: supplierName,
          };

          if (tourDayItem.type.toLowerCase() === 'hotel' && !tourDayItem.disabled) {
            const endDates: DateTime[] = tourDayItem.resources.map(
              (a: TourDayItemResource) => DateTime.fromISO(a.endDate));

            let firstDay = DateTime.fromISO(tourDay.date).plus({ days: 1 });
            const lastDay = DateTime.max(...endDates);

            while (firstDay < lastDay) {
              tourDaysItemsHotelCache.push({
                ...tourDayItemMapped,
                dateCheckout: firstDay.toFormat('yyyy-MM-dd'),
                disabled: true,
              });

              firstDay = firstDay.plus({ days: 1 });
            }
          }

          return tourDayItemMapped;
        })
          .sort((itemA: Record<string, any>, itemB: Record<string, any>) => itemA.position - itemB.position);

        const tourDayIsBlocked = tourDay.items.some((tourDayItem: Record<string, any>) => !!tourDayItem.isBlocked);

        if (!tourDay.stageLength) {
          tourDay.stageLength = 0;
        }

        return {
          id: tourDay.id,
          ...tourDay,
          isBlocked: tourDayIsBlocked,
          items: tourDayItems,
        };
      });

      tourDaysItemsHotelCache.forEach((tourDayItemHotel: Record<string, any>) => {
        const tourDayCheckOut = tourDays.find(
          (tourDayAux: Record<string, any>) => DateTime.fromISO(tourDayAux.date).toFormat('yyyy-MM-dd') === tourDayItemHotel.dateCheckout);

        if (!!tourDayCheckOut) {
          tourDayItemHotel.disabled = true;

          tourDayCheckOut.items.unshift(tourDayItemHotel);
        }
      });

      this.tourMapped = {
        id: tour.id,
        ...tour,
        tourDays,
        client: tour.client,
      };

      this.tourDays = this.tourMapped.tourDays;

      this.tourMappedOriginal = JSON.parse(JSON.stringify(this.tourMapped));

      this.status = 'loaded';
    },
    async moveItemToAnotherDay(item: Record<string, any>) {
      const newTourDay = this.tourDays.find((tourDay: Record<string, any>) => tourDay.items.find(
        (tourDayItem: Record<string, any>) => tourDayItem.id === item.element.id));

      if (!!this.agency && !!newTourDay) {
        try {
          await api.templateDayItem.update(
            this.agency.slug,
            this.tourMapped.id,
            item.element.tourDayId,
            item.element.id,
            newTourDay.id,
          );

          const data = {
            position: item.newPosition,
          };

          await api.templateDayItem.movePosition(
            this.agency.slug,
            this.tourMapped.id,
            newTourDay.id,
            item.element.id,
            data,
          );

          this.$toast.success(this.$t('general.shared.savedChanges'));

          await this.refreshCurrentTemplate();
        } catch (e: any) {
          this.$toast.error(e.response.data.message);

          await this.refreshCurrentTemplate();
        }
      }
    },
    async updateTemplateDay(tourDay: Record<string, any>, newDayDate: string) {
      if (!!this.agency) {
        await api.templateDay.update(
          this.agency.slug,
          this.tourMapped.id,
          tourDay.id,
          {
            pax: tourDay.pax,
            date: newDayDate,
            type: tourDay.type,
            origin: tourDay.originId,
            destination: tourDay.destinationId,
            stage_length: tourDay.stageLength,
          },
        );
      }

      await this.refreshCurrentTemplate();
    },
    addTemplateDayAtFirst() {
      const firstTourDayDate = DateTime.fromISO(this.tourMapped.initDate);
      const newFirstDayDate = firstTourDayDate.minus({ days: 1 }).toFormat('yyyy-MM-dd');

      this.addTemplateDay(newFirstDayDate);
    },
    addTemplateDayAtEnd() {
      const lastTourDayDate = DateTime.fromISO(this.tourMapped.endDate);

      const newLastDayDate = lastTourDayDate.plus({ days: 1 }).toFormat('yyyy-MM-dd');

      this.addTemplateDay(newLastDayDate);
    },
    async addTemplateDay(date: string) {
      await this.$modal.confirm({
        title: this.$t('tourBuilder.addDayTitle'),
        text: this.$t('tourBuilder.addDayText'),
        leftButtonText: this.$t('general.button.cancel'),
        switchButtons: true,
        confirmButtonCallback: async () => {
          if (!!this.agency) {
            const loadingToast = this.$toast.loading(this.$t('general.button.adding'), 'toast-add-template-day');

            try {
              await api.templateDay.create(
                this.agency.slug,
                this.tourMapped.id,
                {
                  date,
                  type: 'rest',
                  pax: 1,
                },
              );

              await this.refreshCurrentTemplate();

              loadingToast.update('toast-add-template-day', {
                content: this.$t('tourBuilder.dayCreated'),
                options: {
                  type: TYPE.SUCCESS,
                  icon: 'icon icon-check-circle-fill',
                  timeout: 5000,
                },
              });
            } catch (e: any) {
              console.error(e.response.data);

              loadingToast.update('toast-add-template-day', {
                content: this.$t('tourBuilder.dayCreateError'),
                options: {
                  type: TYPE.ERROR,
                  icon: 'icon icon-exclamation-triangle-fill',
                  timeout: 5000,
                },
              });
            }
          }
        },
        confirmButtonLoadingText: this.$t('general.button.adding'),
        confirmButtonText: this.$t('general.button.add'),
      });
    },
    async openItemDetailsOffCanvas(data: Record<string, any>) {
      this.statusTourDayItem = 'loading';

      if (this.offCanvasToShow !== 'the-template-day-item-details-off-canvas') {
        this.openOffCanvas('the-template-day-item-details-off-canvas');
      }

      await this.userStore.setCurrentTemplateDayItem(data);

      this.statusTourDayItem = 'loaded';
    },
    async openDayInformationOffCanvas(templateDayId: number, templateDayStage: number) {
      this.currentTemplateDayId = templateDayId;
      this.currentTemplateDayStage = templateDayStage;

      if (this.offCanvasToShow !== 'the-template-day-information-off-canvas') {
        this.openOffCanvas('the-template-day-information-off-canvas');
      }
    },
    clearCurrentTourDayItem() {
      this.userStore.setCurrentTourDayItem(null);
    },
    async refreshCurrentTemplate() {
      this.updatingTour = true;

      const tourData = {
        id: this.currentTemplate?.id,
        agency: this.agency?.slug,
      };

      await this.userStore.setCurrentTemplate(tourData);
      await this.loadTour();

      this.updatingTour = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.tour-steps-container {
  gap: 1rem;
}

.list-enter-from {
  opacity: 0;
  transform: scaleY(0.25);
}

.list-enter-active,
.list-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.list-move {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-active {
  position: absolute;
}

.list-group-item {
  display: block;
  padding: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  width: 100%;
}
</style>
