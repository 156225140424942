import { defineStore } from 'pinia';

export const usePriceStore = defineStore('myStore', {
  state: () => ({
    price: [] as { id: number; readyToPrice: number }[],
    updatingAmount: false as boolean,
  }),
  actions: {
    setPrice(id: number, readyToPrice: number) {
      // Check if an item with the given id already exists in the array
      const existingItemIndex = this.price.findIndex((item) => item.id === id);

      if (existingItemIndex !== -1) {
        // If the item exists, update the readyToPrice value
        this.price[existingItemIndex].readyToPrice = readyToPrice;
      } else {
        // If the item doesn't exist, push a new item to the array
        this.price.push({ id, readyToPrice });
      }
    },
    setCheckUpdate(value: boolean) {
      // call when update the net price
      this.updatingAmount = value;
    },
    clearPriceData() {
      // clear the price data
      this.price = [];
    },
  },
});
export default usePriceStore;
