<template>
  <div class="d-flex align-items-start">
    <v-checkbox
      :id="`reservations-supplier-resource-${bookingType}-check`"
      v-model="tourDayItemResourceLocal.invoiceChecked"
      :for-key="`reservations-supplier-resource-${bookingType}-check`"
      :label="' '"
      class="mt-4 form-check-inline d-flex align-items-center me-4"
      label-class="ms-0"
      @update:model-value="$emit('updateItemResourceCheck', tourDayItemResourceLocal.invoiceChecked)"
    />

    <div class="w-100">
      <div
        class="mb-8 d-flex"
        @mouseover="setHoveredElement(tourDayItemResource.id)"
        @mouseleave="setHoveredElement(0)"
        :class="{'fw-bold': isGroup(tourDayItemResource.id)}"
      >
        <span class="fw-medium">{{ $t("general.shared.name") }}: </span>
        {{ tourDayItemResource.name }}
        <div :class="{ 'icon-container': !(tourDayItemResource.id === hoveredId) }">
          <div class="d-flex">
            <v-button class="p-4" size="sm" variant="icon" @click="$emit('setCurrentItem')">
              <v-icon icon="pencil" />
            </v-button>
            <v-button class="p-4" size="sm" variant="icon" @click="$emit('deleteItem')">
              <v-icon icon="trash" />
            </v-button>
          </div>
        </div>
      </div>

      <div class="mb-8 d-flex" :class="{'fw-bold': isGroup(tourDayItemResource.id)}">
        <div class="fw-medium me-4">{{ $t("tourBooking.dates") }}:</div>

        <div>
          {{ $str.formatDateTime(tourDayItemResource.date, settings.formatDate) }}
          -
          <template v-if="!!tourDayItemResource.endDate">
            {{ $str.formatDateTime(tourDayItemResource.endDate, settings.formatDate) }}
          </template>

          <template v-else>
            {{ $t("general.shared.noEndDate") }}
          </template>
        </div>
      </div>

      <div class="mb-8 d-flex flex-column flex-md-row">
        <span class="col-3 white-space-nowrap fw-medium me-8">{{ $t("tourBooking.bookingStatus") }}: </span>

        <v-form
          id="tour-resource-update-reservation-status"
          :yup-errors-variable="errors.status"
          class="w-100"
          form-type="outline"
        >
          <v-select
            id="tour-resource-update-reservation-status"
            v-model="status"
            :class="[
              { 'is-invalid': !!errors.status },
              `bg-status-${findConceptByKey('tour.reservation_status_type', status).color}`,
            ]"
            :clearable="false"
            :options="tourReservationStatusTypes"
            :reduce="(returnValue) => returnValue.key"
            @option:selected="updateItemResourceReservationStatus('reservation_status')"
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
            </template>
          </v-select>
        </v-form>
      </div>

      <div class="mb-8 d-flex flex-column flex-md-row">
        <span class="col-3 white-space-nowrap fw-medium me-8">{{ $t("tourBooking.paymentStatus") }}: </span>

        <div class="w-100">
          <v-form
            id="tour-resource-update-payment-status"
            :yup-errors-variable="errors.paymentStatus"
            class="w-100"
            form-type="outline"
          >
            <v-select
              v-model="paymentStatus"
              :class="[
                { 'is-invalid': !!errors.paymentStatus },
                `bg-status-${findConceptByKey('tour.payment_status_type', paymentStatus).color}`,
              ]"
              :clearable="false"
              :options="tourPaymentStatusTypes"
              :reduce="(returnValue) => returnValue.key"
              @option:selected="updateItemResourceReservationStatus('payment_status')"
            >
              <template v-slot:no-options>
                <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
              </template>
            </v-select>
          </v-form>
        </div>
      </div>

      <div class="mb-8 d-flex flex-column flex-md-row">
        <span class="col-3 white-space-nowrap fw-medium me-8">{{ $t("tourBooking.invoiceStatus") }}: </span>
        <v-form
          id="tour-resource-update-invoice-status"
          :yup-errors-variable="errors.invoiceStatus"
          class="w-100"
          form-type="outline"
        >
          <v-select
            v-model="invoiceStatus"
            :class="[
              { 'is-invalid': !!errors.invoiceStatus },
              `bg-status-${findConceptByKey('tour.invoice_status_type', invoiceStatus).color}`,
            ]"
            :clearable="false"
            :options="tourInvoiceStatusTypes"
            :reduce="(returnValue) => returnValue.key"
            @option:selected="updateItemResourceReservationStatus('invoice_status')"
          >
            <template v-slot:no-options>
              <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
            </template>
          </v-select>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { useField } from "vee-validate";
import vSelect from "vue-select";
import { VCheckbox, VForm } from "@uniqoders/form";
import { mapState } from "pinia";
import useFormValidation from "@/helpers/form";
import { findConceptByKey, mapConcepts } from "@/helpers/ConceptHelper";
import useConcepts from "@/helpers/Concepts";
import api from "@/api";
import { useUserStore } from "@/stores/user";
import TourDayItemResource from "@/api/objects/TourDayItemResource";
import { useAppStore } from "@/stores/app";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";

export default defineComponent({
  name: "TheTourResourceReservationsMobile",
  components: {
    vSelect,
    VForm,
    VCheckbox,
    VButton,
    VIcon,
  },
  emits: [
    "updateItemResourceReservationStatus",
    "updateItemResourcePaymentStatus",
    "updateItemResourceInvoiceStatus",
    "updateItemResourceCheck",
    "updateItemResourceAmountPaid",
    "setCurrentItem",
    "deleteItem",
    "emitLoadTour",
  ],
  props: {
    isGroupIndividual: {
      type: Boolean,
      required: false,
      default: false,
    },
    tourDayItemResource: {
      type: TourDayItemResource,
      required: true,
    },
    agency: {
      type: String,
      required: true,
    },
    bookingType: {
      type: String,
      required: true,
    },
    clientTourId: {
      type: Number,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    tourDayId: {
      type: Number,
      required: true,
    },
    tourDayItemId: {
      type: Number,
      required: true,
    },
    tourDayItemResourceId: {
      type: Number,
      required: true,
    },
    
  },
  setup(props) {
    const userStore = useUserStore();
    const { t } = useI18n();

    const rules = yup.object({
      status: yup.string().required().label(t("tourBooking.bookingStatus")),
      paymentStatus: yup.string().required().label(t("tourBooking.paymentStatus")),
      invoiceStatus: yup.string().required().label(t("tourBooking.invoiceStatus")),
      amountPaid: yup
        .number()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t("general.shared.paid")),
      amountPending: yup
        .number()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t("general.shared.paid")),
    });

    const initialValues: Record<string, any> = {
      status: props.tourDayItemResource.reservationStatus,
      paymentStatus: props.tourDayItemResource.paymentStatus,
      invoiceStatus: props.tourDayItemResource.invoiceStatus,
      amountPaid: props.tourDayItemResource.amountPaid,
    };

    if (!!props.tourDayItemResource.netPrice) {
      const total = Number(props.tourDayItemResource.netPrice);
      const paid = !!props.tourDayItemResource.amountPaid ? Number(props.tourDayItemResource.amountPaid) : 0;

      initialValues.amountPending = total - paid;
    }

    const form = useFormValidation(rules, initialValues);

    const { value: status } = useField<string>("status");
    const { value: paymentStatus } = useField<string>("paymentStatus");
    const { value: invoiceStatus } = useField<string>("invoiceStatus");
    const { value: amountPaid } = useField("amountPaid");
    const { value: amountPending } = useField("amountPending");

    return {
      findConceptByKey,
      userStore,
      ...useConcepts(),
      ...form,
      status,
      paymentStatus,
      invoiceStatus,
      amountPaid,
      amountPending,
    };
  },
  computed: {
    ...mapState(useAppStore, ["settings"]),
    tourReservationStatusTypes(): any {
      if (!!this.concepts && this.concepts["tour.reservation_status_type"]) {
        return mapConcepts(this.concepts["tour.reservation_status_type"]);
      }

      return [];
    },
    tourPaymentStatusTypes(): any {
      if (!!this.concepts && this.concepts["tour.payment_status_type"]) {
        return mapConcepts(this.concepts["tour.payment_status_type"]);
      }

      return [];
    },
    tourInvoiceStatusTypes(): any {
      if (!!this.concepts && this.concepts["tour.invoice_status_type"]) {
        return mapConcepts(this.concepts["tour.invoice_status_type"]);
      }

      return [];
    },
  },
  data() {
    return {
      tourDayItemResourceLocal: this.tourDayItemResource,
      hoveredId: 0 as number,
    };
  },
  methods: {
    isGroup(id: string | number) {
      return id.toString().includes('-');
    },
    setHoveredElement(id: number) {
      this.hoveredId = id;
    },
    async updateItemResourceReservationStatus(type: string) {
      try {
        this.toggleAccepted();

        let status = "";

        if (type === "reservation_status") {
          status = this.status;
        } else if (type === "payment_status") {
          status = this.paymentStatus;
        } else {
          status = this.invoiceStatus;
        }

        const data = {
          status: status,
          status_type: type.split("_")[0],
          resources_id: this.tourDayItemResourceId,
        };

        const response = await api.tourDayItemResource.updateStatusBulk(
          this.agency,
          this.clientTourId,
          this.tourId,
          data
        );

        // TODO: in future uncomment when needed
        // if (type === 'payment_status') {
        //   await this.updateItemResource();
        // }

        this.$toast.success(this.$t("general.shared.savedChanges"));

        this.resetForm({
          values: {
            status: response.reservationStatus,
            paymentStatus: response.paymentStatus,
            invoiceStatus: response.invoiceStatus,
          },
        });

        this.$emit("updateItemResourceReservationStatus", response.reservationStatus);
        this.$emit("updateItemResourcePaymentStatus", response.paymentStatus);
        this.$emit("updateItemResourceInvoiceStatus", response.invoiceStatus);
        this.$emit("emitLoadTour");
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.success(this.$t("general.shared.savedChanges"));
      } finally {
        this.toggleAccepted();
      }
    },
    async updateItemResource(event?: any) {
      if (!!event) {
        event.target.blur();
      } else {
        try {
          this.toggleAccepted();

          const resource = {
            pax: this.tourDayItemResource.pax,
            amount_paid: this.paymentStatus === "partial" ? this.amountPaid : 0,
          };

          const response = await api.tourDayItemResource.update(
            this.agency,
            this.clientTourId,
            this.tourId,
            this.tourDayId,
            this.tourDayItemId,
            this.tourDayItemResourceId,
            resource
          );

          this.$emit("updateItemResourceAmountPaid", response.amountPaid);

          if (this.paymentStatus === "partial") {
            const total = Number(this.tourDayItemResource.netPrice);
            const paid = Number(response.amountPaid);

            this.setFieldValue("amountPending", total - paid);
          }
        } catch (e: any) {
          console.error(e);

          this.$toast.error(e.response.data.message);
        } finally {
          this.toggleAccepted();
        }
      }
    },
  },
});
</script>
