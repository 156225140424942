<template>
  <div id="accordionSetupFeaturedImage" class="accordion">
    <div class="case-details accordion-item">
      <h2 id="headingSetupFeaturedImage" class="accordion-header">
        <button
          aria-controls="collapseSetupFeaturedImage"
          aria-expanded="true"
          class="accordion-button collapsed"
          data-bs-target="#collapseSetupFeaturedImage"
          data-bs-toggle="collapse"
          type="button">
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="description" size="xxl" space="p-12"/>
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t('clientTour.featuredImage') }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseSetupFeaturedImage"
        aria-labelledby="headingSetupFeaturedImage"
        class="accordion-collapse collapse show"
        data-bs-parent="#accordionSetupFeaturedImage">
        <div class="accordion-body pb-32 pb-xl-48">
          <div class="d-flex flex-column">
            <v-picture v-if="!!featuredImage" class="d-block image-container mb-16">
              <img
                :alt="$t('clientTour.featuredImage')"
                :src="featuredImage.storageUrl"
              />
            </v-picture>

            <v-uploader
              ref="uploader"
              :multiple="false"
              :max-items="1"
              :after-upload="onChange"
              route-prefix="api/s3"
              @removed="onChange"
            />

            <v-button
              :disabled="disableButton || mediaUuids.length === 0"
              class="mt-16 align-self-end"
              size="sm"
              variant="primary"
              @click="confirmMedia">
              {{ $t('general.button.save') }}
              <v-icon icon="arrow-right" size="sm" space="ms-12"/>
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VUploader from '@/components/vendor/upload/VUploader.vue';
import Media from '@/api/objects/Media';
import { useUserStore } from '@/stores/user';
import { getHummanSize } from '@/helpers/General';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import Template from '@/api/objects/Template';

export default defineComponent({
  name: 'TheCaseSetupFeaturedImage',
  components: {
    VIcon,
    VButton,
    VUploader,
    VPicture,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    template: {
      type: Template,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
      getHummanSize,
      DateTime,
    };
  },
  data() {
    return {
      media: [] as any,
      disableButton: false as boolean,
      featuredImage: {} as Media,
    };
  },
  computed: {
    mediaUuids() {
      return this.media.map((media: any) => ({
        uuid: media.attributes.uuid,
        name: media.attributes.name,
        collection: 'featured_image',
      }));
    },
  },
  async created() {
    this.featuredImage = this.template.featuredImage;
  },
  methods: {
    onChange() {
      const { uploader }: any = this.$refs;

      if (!!uploader) {
        this.media = uploader.mediaLibrary.state.media;
      }
    },
    async confirmMedia() {
      try {
        this.disableButton = true;

        if (!!this.featuredImage && !!this.featuredImage.id) {
          await api.media.delete('s3', this.featuredImage.id as number);
        }

        const data = {
          media: this.mediaUuids,
          model_type: 'template',
          collection: 'featured_image',
          model_id: this.template.id,
        };

        const media = await api.media.confirm('s3', data);

        this.$toast.success(media.message);

        this.mediaUuids = [];

        const { uploader }: any = this.$refs;

        if (!!uploader) {
          uploader.mediaLibrary.state.media = [];
        }

        this.featuredImage = this.template.featuredImage;

        this.disableButton = false;
      } catch (e: any) {
        this.disableButton = false;

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.image-container {
  :deep(img) {
    height: 100%;
    max-height: 14rem;
  }
}
</style>
