<template>
  <div v-if="!!currentTourDayItem" class="h-100">
    <v-loader v-if="tourDayItemResourcesStatus === 'loading'" />

    <form v-else id="tour-day-item-resource-update" class="d-flex flex-column justify-content-between h-100">
      <div>
        <template
          v-if="currentTourDayItem.type !== 'general_information' && currentTourDayItem.type !== 'stage_information'">
          <fieldset>
            <legend class="visually-hidden">{{ $t('tourBuilder.searchTourDayItemResource') }}</legend>

            <div class="d-flex gap-16 mb-16">
              <div class="fw-medium">{{ $t('general.shared.supplier') }}:</div>
              <div>{{ supplierName }}</div>
            </div>

            <div class="resources-container form-outline">
              <div class="d-flex justify-content-between">
                <label class="label">
                  <span>{{ $t('general.shared.products') }}</span>
                </label>

                <label class="me-4 label">
                  <v-button class="btn-rates p-0 mx-4" variant="icon" v-if="supplier.type !== 'hotel'"
                    @click="openOffCanvas('the-supplier-details-rates-off-canvas')">
                    <v-icon class="px-0" icon="euro" />
                  </v-button>

                  <v-button class="btn-rates p-0 mx-4" variant="icon"
                    @click="openOffCanvas('the-supplier-details-offcanvas')">
                    <v-icon class="px-0" icon="pencil" />
                  </v-button>

                  <v-button class="btn-rates p-0 mx-4" variant="icon" @click="loadSupplierSelected" v-if="supplier.type !== 'hotel'">
                    <v-icon class="px-0" icon="refresh" />
                  </v-button>
                </label>
              </div>

              <div class="form-control" v-if="supplier.type !== 'hotel'">
                <div v-for="resource in resources" :key="resource.id"
                  class="resource-item d-flex justify-content-between align-items-center py-4">
                  <v-checkbox :id="`tour-day-item-create-resource-${resource.id}`" v-model="resource.check"
                    :for-key="`tour-day-item-create-resource-${resource.id}`" :label="resource.name"
                    class="form-check-inline me-32" label-class="ms-0" @click.prevent="setResource(resource)" />

                  <div v-if="!!checkExistResource(resource.id)" class="d-flex">
                    <div class="mx-8">{{ checkExistResource(resource.id).quantity }}</div>

                    <v-button class="p-0" size="sm" variant="icon" @click="addResource(resource)">
                      <v-icon icon="circle-plus" size="sm" />
                    </v-button>
                  </div>
                </div>
              </div>
              <div class="form-control" v-else>
                <div v-for="resource in resources" :key="resource.id"
                  class="resource-item d-flex justify-content-between align-items-center py-4">
                  <div :id="`tour-day-item-create-resource-${resource.id}`" class="form-check-inline me-32">
                    {{ resource.name }}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div class="search-separator-container mb-24 pb-24">
              <div class="separator-text-container mb-16" />
            </div>

            <div v-if="resourcesSelected.length > 0">
              <template v-for="(resource, index) in resourcesSelected" :key="index">
                <div :id="`accordionSupplierResource${resource.data.id}`" class="accordion border mb-16">
                  <div class="supplier-resource-details accordion-item mb-0">
                    <div :id="`headingSupplierResource${resource.data.id}`" class="accordion-header">
                      <button :aria-controls="`collapseSupplierResource${resource.data.id}`"
                        :data-bs-target="`#collapseSupplierResource${resource.data.id}`" aria-expanded="false"
                        class="accordion-button collapsed p-8" data-bs-toggle="collapse" type="button">
                        <span class="d-flex flex-column">
                          <span class="fw-medium pe-80">
                            {{ resource.data.name }}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div :id="`collapseSupplierResource${resource.data.id}`"
                      :aria-labelledby="`headingSupplierResource${resource.data.id}`"
                      :data-bs-parent="`#accordionSupplierResource${resource.data.id}`"
                      class="accordion-collapse collapse">
                      <div class="accordion-body p-8">
                        <div v-for="(tourDayItemResource, indexItemResources) in resource.tourDayItemResources"
                          :key="`template-day-item-resource-${tourDayItemResource.id}`"
                          class="border rounded-xs p-12 gx-8 mb-8">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="fw-medium text-sm">
                              {{ resource.data.name }}
                              <span v-if="resource.tourDayItemResources.length > 1" class="fw-normal text-xs">
                                (#{{ indexItemResources + 1 }})
                              </span>
                            </div>

                            <v-button size="sm" variant="icon"
                              @click="removeTourDayItemResource(tourDayItemResource.id)">
                              <v-icon icon="trash" size="sm" />
                            </v-button>
                          </div>

                          <the-template-day-item-details-resource-form v-if="!!tourDayItemResource" :agency="agency"
                            :data="tourDayItemResource" :index="indexItemResources" :status="status"
                            :template-id="templateId" :tour-day-id="currentTourDayItem.tourDayId"
                            :tour-day-item-id="currentTourDayItem.id" :type="currentTourDayItem.type"
                            :update-view="true" @refresh-resource-data="tourDayItemResource = $event"
                            @emit-reload-tour="$emit('emitReloadTour');" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </fieldset>
        </template>
        <template v-else>
          <fieldset>
            <div class="selected d-center py-8">
              <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
                <div class="fw-medium">{{ $t('resource.basicInformation') }}</div>

                <v-button class="btn-edit p-4" variant="icon" @click="openOffCanvas('the-resource-details-offcanvas')">
                  <v-icon icon="pencil" />
                </v-button>
              </div>
              <template v-for="(lang, index) in stageData?.translations" :key="index">
                <div class="text-sm d-flex py-4" v-if="currentLanguage === lang?.locale">
                  <span class="me-16 fw-medium">{{ $t('general.shared.title') }}: </span>
                  <span>{{ lang?.title }}</span>
                </div>
              </template>
              <div class="text-sm d-flex py-4">
                <span class="me-16 fw-medium">{{ $t('general.shared.location') }}: </span>
                <span>
                  {{ stageData?.city }}
                  <v-icon v-if="!!stageData?.destination" icon="arrow-right" size="xs" space="px-8" />
                  {{ stageData?.destination }}
                </span>
              </div>
              <div class="text-sm d-flex py-4" v-if="!!stageData?.types">
                <span class="me-16 fw-medium">{{ $t('general.shared.types') }}: </span>
                <span>{{ JSON.parse(stageData?.types)[0] }}</span>
              </div>
              <template v-for="(lang, index) in stageData?.translations" :key="index">
                <div class="text-sm d-flex py-4" v-if="currentLanguage === lang?.locale">
                  <span class="me-16 fw-medium">{{ $t('general.shared.description') }}: </span>
                  <span v-html="lang?.description" />
                </div>
              </template>
              <div class="search-separator-container mb-8">
                <div class="separator-text-container">
                  <span class="separator-text text-sm">{{ $t('general.shared.links') }}</span>
                </div>
              </div>
              <div v-if="stageData?.links" class="text-sm d-flex flex-column py-4">
                <span class="me-16 fw-medium">{{ $t('general.shared.links') }}: </span>
                <template v-for="url in JSON.parse(stageData?.links)" :key="url">
                  <a :href="url" class="resource-link btn-link" rel="noopener noreferrer" target="_blank">
                    {{ url }}
                  </a>
                </template>
              </div>
            </div>
          </fieldset>
        </template>
      </div>
    </form>

    <the-supplier-details-rates-off-canvas v-if="offCanvasToShow === 'the-supplier-details-rates-off-canvas'"
      :supplier-id="supplier.id" @closed="closeOffCanvas" />

    <the-supplier-create-offcanvas v-if="offCanvasToShow === 'the-supplier-create-offcanvas'" @closed="closeOffCanvas"
      @reload-suppliers="closeOffCanvas" @open-details="supplier = $event" />

    <the-supplier-details-offcanvas v-if="offCanvasToShow === 'the-supplier-details-offcanvas'" :is-archived="false"
      :is-double-off-canvas="false" :supplier-data="supplier" @closed="closeOffCanvas(); loadSupplierSelected()" />

    <the-resource-details-offcanvas v-if="offCanvasToShow === 'the-resource-details-offcanvas'" :is-archived="false"
      :resource-data="stageData" tour-builder="tourBuilder" @closed="closeOffCanvas();"
      @reload-resources="closeOffCanvas" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VCheckbox } from '@uniqoders/form';
import { mapState } from 'pinia';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import Supplier from '@/api/objects/Supplier';
import { useUserStore } from '@/stores/user';
import api from '@/api';
import SupplierResource from '@/api/objects/SupplierResource';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import TheSupplierDetailsRatesOffCanvas from '@/components/supplier/parts/TheSupplierDetailsRatesOffCanvas.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import TheTemplateDayItemDetailsResourceForm from '@/components/template/TheTemplateDayItemDetailsResourceForm.vue';
import TourDayItemResource from '@/api/objects/TourDayItemResource';
import TheSupplierDetailsOffcanvas from '@/components/supplier/TheSupplierDetailsOffcanvas.vue';
import TheSupplierCreateOffcanvas from '@/components/supplier/TheSupplierCreateOffcanvas.vue';
import TheResourceDetailsOffcanvas from '@/components/resource/TheResourceDetailsOffcanvas.vue';
import { useAppStore } from '@/stores/app';

export default defineComponent({
  name: 'TheTemplateDayItemDetailsUpdateForm',
  components: {
    TheSupplierCreateOffcanvas,
    TheSupplierDetailsOffcanvas,
    TheTemplateDayItemDetailsResourceForm,
    TheSupplierDetailsRatesOffCanvas,
    TheResourceDetailsOffcanvas,
    VLoader,
    VIcon,
    VButton,
    VCheckbox,
  },
  emits: ['closed', 'emitReloadTour', 'deletedItemResource'],
  props: {
    agency: {
      type: String,
      required: true,
    },
    templateId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
      ...useOffCanvasUtils(),
    };
  },
  data() {
    return {
      resourceKey: 0 as number,
      supplier: {} as Supplier,
      supplierName: '' as string,
      resources: [] as SupplierResource[],
      resourcesMapped: [] as SupplierResource[],
      suppliersStatus: 'loading' as string,
      tourDayItemResourcesStatus: 'loading' as string,
      name: '' as string,
      resourcesSelected: [] as Record<string, any>[],
      resourcesNextStep: false as boolean,
      isDirty: false as boolean,
      filters: {
        name: '' as string,
        type: '' as string,
      } as Record<string, any>,
      timers: {
        name: null as any,
      } as Record<string, any>,
      currentLanguage: localStorage.getItem('language'),
    };
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
    ...mapState(useUserStore, ['currentTourDayItem']),
    stageData() {
      return this.currentTourDayItem?.resources[0]?.resource;
    },
  },
  async created() {
    this.setTourDayItemData();
    await this.loadSupplierSelected();
  },
  methods: {
    setTourDayItemData() {
      if (!!this.currentTourDayItem) {
        this.resourcesSelected = [];

        this.currentTourDayItem.resources.forEach((resource) => {
          const resourceExist = this.checkExistResource(resource.modelId);

          if (!!resourceExist) {
            resourceExist.tourDayItemResources.push(resource);

            resourceExist.quantity += 1;
          } else {
            this.resourcesSelected.push({
              data: {
                id: resource.resource.id,
                name: resource.resource.name,
              },
              quantity: 1,
              tourDayItemResources: [
                resource,
              ],
            });
          }
        });

        this.tourDayItemResourcesStatus = 'loaded';
      }
    },
    mapResources() {
      this.resources = this.supplier.resources.map((resourceAux) => {
        const resourceExist = this.checkExistResource(resourceAux.id);

        resourceAux.check = !!resourceExist;

        return resourceAux;
      });
    },
    async loadSupplierSelected() {
      if (!!this.currentTourDayItem) {
        try {
          const resource = this.currentTourDayItem.resources[0];
          let supplierId = 0;
          if (resource.modelType === 'supplier_resource') {
            supplierId = resource.resource.supplierId;
          } else if (resource.modelType === 'supplier') {
            supplierId = resource.modelId;
          }
          if (supplierId !== 0) {
            this.supplier = await api.supplier.retrieve(supplierId);
          }

          this.mapResources();

          this.supplierName = !!this.supplier.name ? this.supplier.name : '-';
        } catch (e: any) {
          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    setResource(resource: SupplierResource | any) {
      if (!resource.check) {
        this.addResource(resource);
        resource.check = true;
      } else {
        this.deleteModalTourDayItemResource(resource);
      }
    },
    async addResource(resource: SupplierResource | any) {
      if (!!this.currentTourDayItem) {
        try {
          const data: Record<string, any> = {
            model_type: 'supplier_resource',
            model_id: resource.id,
          };

          const response: TourDayItemResource = await api.templateDayItemResource.create(
            this.agency,
            this.templateId,
            this.currentTourDayItem.tourDay.id,
            this.currentTourDayItem.id,
            data,
          );

          this.$toast.success(this.$t('general.shared.savedChanges'));

          const item = {
            agency: this.agency,
            templateId: this.templateId,
            templateDayId: this.currentTourDayItem.tourDayId,
            templateDayItemId: this.currentTourDayItem.id,
          };

          await this.userStore.setCurrentTemplateDayItem(item);

          const tourDayItemResource: Record<string, any> = {
            id: response.id,
          };

          const resourceExist = this.checkExistResource(resource.id);

          if (!!resourceExist) {
            resourceExist.tourDayItemResources.push(tourDayItemResource);

            resourceExist.quantity += 1;
          } else {
            this.resourcesSelected.push({
              data: {
                id: resource.id,
                name: resource.name,
              },
              quantity: 1,
              tourDayItemResources: [
                tourDayItemResource,
              ],
            });
          }
        } catch (e: any) {
          console.error(e);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    checkExistResource(supplierResourceId: number | any): Record<string, any> | undefined {
      return this.resourcesSelected.find((resourceAux) => resourceAux.data.id === supplierResourceId);
    },
    async deleteModalTourDayItemResource(resource: any) {
      if (!!this.currentTourDayItem) {
        const resourceExist = this.resourcesSelected.find((resourceAux) => resourceAux.data.id === resource.id);
        await this.$modal.delete({
          title: this.$t('tourBuilder.deleteTourDayItemResourceTitle'),
          text: this.$t('tourBuilder.deleteTourDayItemResourceText', {
            resource: resourceExist?.data.name,
          }),
          deleteButtonText: this.$t('general.button.delete'),
          rightButtonClasses: 'btn-tertiary',
          deleteButtonCallback: () => this.doDeleteAllTourDayItemResources(resourceExist, resource),
        });
      }
    },
    async doDeleteAllTourDayItemResources(resource: any, resourceOriginal: any) {
      if (!!this.currentTourDayItem) {
        // eslint-disable-next-line no-restricted-syntax
        for (const tourDayItemResource of resource.tourDayItemResources) {
          // eslint-disable-next-line no-await-in-loop
          await this.removeTourDayItemResource(tourDayItemResource.id, false);
        }

        resourceOriginal.check = false;

        const item = {
          agency: this.agency,
          templateId: this.templateId,
          templateDayId: this.currentTourDayItem.tourDayId,
          templateDayItemId: this.currentTourDayItem.id,
        };

        this.$emit('deletedItemResource');

        await this.userStore.setCurrentTemplateDayItem(item);

        if (!!this.currentTourDayItem.resources && this.currentTourDayItem.resources.length > 0) {
          this.setTourDayItemData();
        }
      }
    },
    async removeTourDayItemResource(
      tourDayItemResource: number,
      refreshCurrentTourDayItem = true,
    ) {
      if (!!this.currentTourDayItem) {
        try {
          await api.templateDayItemResource.delete(
            this.agency,
            this.templateId,
            this.currentTourDayItem.tourDay.id,
            this.currentTourDayItem.id,
            tourDayItemResource,
          );

          this.$toast.success(this.$t('general.shared.savedChanges'));

          if (refreshCurrentTourDayItem) {
            const item = {
              agency: this.agency,
              templateId: this.templateId,
              templateDayId: this.currentTourDayItem.tourDayId,
              templateDayItemId: this.currentTourDayItem.id,
            };

            this.$emit('deletedItemResource');

            await this.userStore.setCurrentTemplateDayItem(item);

            await this.setTourDayItemData();
            this.mapResources();
          }
        } catch (e: any) {
          console.error(e);

          this.$toast.error(e.response.data.message);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.accordion-item {
  background-color: transparent;
}

.accordion .accordion-item .accordion-button {
  font-size: 1rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: transparent;
}
</style>
