import { defineStore } from 'pinia';
import Resource from '@/api/objects/Resource';
import api from '@/api';
import Client from '@/api/objects/Client';
import Contact from '@/api/objects/Contact';
import Supplier from '@/api/objects/Supplier';
import TourDayItem from '@/api/objects/TourDayItem';
import Tour from '@/api/objects/Tour';
import Template from '@/api/objects/Template';

export interface UserState {
    currentResource: Resource | null,
    currentClient: Client | null,
    currentContact: Contact | null,
    currentSupplier: Supplier | null,
    currentTour: Tour | null,
    currentTemplate: Template | null,
    currentTourDayItem: TourDayItem | null,
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    currentResource: null,
    currentClient: null,
    currentContact: null,
    currentSupplier: null,
    currentTour: null,
    currentTourDayItem: null,
    currentTemplate: null,
  }),
  actions: {
    async setCurrentResource(resource: Resource | number | null) {
      if (!resource) {
        this.currentResource = null;
      } else if (typeof resource === 'number') {
        this.currentResource = await api.resource.retrieve(resource);
      } else {
        this.currentResource = resource;
      }
    },
    async setCurrentTour(tour: Record<string, any> | null, params?: any) {
      if (!tour) {
        this.currentTour = null;
      } else {
        this.currentTour = await api.tour.retrieve(
          tour.agency,
          tour.clientTourId,
          tour.id,
          params,
        );
      }
    },
    async setCurrentTemplate(template: Record<string, any> | null) {
      if (!template) {
        this.currentTemplate = null;
      } else {
        this.currentTemplate = await api.template.retrieve(
          template.agency,
          template.id,
        );
      }
    },
    async setCurrentTourDayItem(item: Record<string, any> | null) {
      if (!item) {
        this.currentTourDayItem = null;
      } else {
        this.currentTourDayItem = await api.tourDayItem.retrieve(
          item.agency,
          item.clientTourId,
          item.tourId,
          item.tourDayId,
          item.tourDayItemId,
        );
      }
    },
    async setCurrentTemplateDayItem(item: Record<string, any> | null) {
      if (!item) {
        this.currentTourDayItem = null;
      } else {
        this.currentTourDayItem = await api.templateDayItem.retrieve(
          item.agency,
          item.templateId,
          item.templateDayId,
          item.templateDayItemId,
        );
      }
    },
  },
});
