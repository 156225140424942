<template>
  <div class="user-profile ms-24 ms-md-36">
    <v-dropdown
      id="user-header-dropdown"
      :disable-icon="true"
      button-classes="text-sm fw-medium bg-transparent shadow-none p-0 pe-0"
      dropdown-classes="dropdown-user dropdown-menu-end w-auto"
    >
      <template #button>
        <v-icon icon="user" size="xxl"/>
      </template>

      <template #content>
        <div class="p-8">
          <div class="fw-medium text-sm">{{ user.name }}</div>
          <div class="text-xs">{{ user.email }}</div>
        </div>

        <div class="dropdown-divider"/>

        <li class="dropdown-list p-0 text-sm">
          <a
            :aria-label="$t('general.menu.myProfile')"
            class="dropdown-item d-flex align-items-center px-16 py-16"
            href="#"
            @click.prevent="logout()"
          >
            <v-icon class="text-secondary" icon="logout" size="xxl" space="me-8"/>
            <span>{{ $t('general.menu.logout') }}</span>
          </a>
        </li>
      </template>
    </v-dropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VDropdown from '@/components/vendor/basic/dropdown/VDropdown.vue';

export default defineComponent({
  name: 'TheHeaderUserDropdown',
  components: {
    VDropdown,
    VIcon,
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
  },
  methods: {
    async logout() {
      await this.authStore.logout();

      await this.$router.replace({ name: 'login' });
    },
  },
});
</script>
