<script lang="ts" setup>
import { computed } from 'vue';
import VRowsPerPage from '@/components/vendor/basic/table/VRowsPerPage.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

interface DataTablePaginationProps {
  meta: {
    currentPage: number,
    lastPage: number,
    perPage: number,
    from: number,
    to: number,
    total: number,
    path: string,
  };
  paginationLinks: Array<{
    label: string;
    url: string | null;
    active: boolean;
  }>;
}

const props = defineProps<DataTablePaginationProps>();
const emit = defineEmits(['updatePage', 'perPageChanged', 'pageChanged']);

const maxPageLinks = 3;

const pageNumbers = computed(() => {
  const pages = [];
  let startPage, endPage;
  const currentPage = props.meta.currentPage;
  const lastPage = props.meta.lastPage;

  if (lastPage <= maxPageLinks) {
    startPage = 1;
    endPage = lastPage;
  } else {
    const maxPagesBeforeCurrentPage = Math.floor(maxPageLinks / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPageLinks / 2) - 1;

    if (currentPage <= maxPagesBeforeCurrentPage) {
      startPage = 1;
      endPage = maxPageLinks;
    } else if (currentPage + maxPagesAfterCurrentPage >= lastPage) {
      startPage = lastPage - maxPageLinks + 1;
      endPage = lastPage;
    } else {
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return pages;
});

const handlePageChange = (url: number | null) => {
  if (url) {
    emit('pageChanged', url);
  }
}

async function setPerPage(perPage: any) {
  if (props.meta.perPage !== perPage) {
    emit('perPageChanged', perPage);
  }
}
</script>

<template>
  <div class="d-flex flex-column flex-sm-row align-items-center justify-content-between gap-16">
    <v-rows-per-page
      id="show-number"
      class="align-self-start"
      :selected="meta?.perPage"
      @changed="setPerPage"
    />

    <div class="pagination justify-content-end">
      <div class="page-item">
        <v-button
          v-if="pageNumbers[0] > 1"
          class="page-link page text-sm"
          variant="outline"
          @click="handlePageChange(1)"
        >
          1
        </v-button>
      </div>
      <span v-if="pageNumbers[0] > 2" class="me-8">...</span>

      <div
        v-for="pageNum in pageNumbers"
        :key="pageNum"
        :class="{ active: meta.currentPage === pageNum }"
        class="page-item"
      >
        <v-button
          :class="{ active: meta.currentPage === pageNum }"
          class="page-link page text-sm"
          variant="outline"
          @click="handlePageChange(pageNum)"
        >
          {{ pageNum }}
        </v-button>
      </div>

      <span v-if="pageNumbers[pageNumbers.length - 1] < meta.lastPage - 1"
            class="me-8"
      >...</span>

      <div class="page-item">
        <v-button
          v-if="pageNumbers[pageNumbers.length - 1] < meta.lastPage"
          class="page-link page text-sm"
          variant="outline"
          @click="handlePageChange(meta.lastPage)"
        >
          {{ meta.lastPage }}
        </v-button>
      </div>
    </div>
  </div>
</template>
