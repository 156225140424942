<template>
    <div id="admin-agencies-list" class="container item-list pb-64">
      <v-list-header
        :title="this.$t('admin.collaborator.collaborators')"
        icon="user"
        @open-filters-offcanvas="openOffCanvas('the-admin-agency-filters-offcanvas')"
        @emit-close-off-canvas="closeOffCanvas"
        @reload-agencies="loadAgencies"
      >
        <template #input-search>
          <v-input-text-search
            id="agency-search"
            v-model="name"
            :placeholder="this.$t('general.shared.search')"
            autocomplete="off"
            class="w-100 me-24"
            input-class="h-auto py-8"
            input-group-text-class="py-8 px-16"
            type="text"
            @update:modelValue="name = $event; search()"
          />
        </template>
      </v-list-header>
  
      <div class="items-content">
        <div class="filters-container mb-24">
          <v-filters
            v-if="!!filterOptions"
            v-model="filterOptions"
            @remove-filter="removeFilter"
          />
        </div>
  
        <div class="table-responsive d-none d-md-flex">
          <v-table>
            <v-table-header>
              <tr>
                <th>
                  {{ $t('general.shared.name') }}
                  <v-button
                    id="btn-agency-order-name"
                    :class="{ 'sort-active': order.key === 'name' }"
                    class="p-0"
                    size="xs"
                    variant="icon"
                    @click="toggleOrder('name')"
                  >
                    <v-icon
                      :class="{ 'sort-icon-transition': order.key === 'name' && order.order === 'DESC' }"
                      icon="chevron-down"
                      size="xs"/>
                  </v-button>
                </th>
  
                <th>{{ $t('general.shared.location') }}</th>
                <th class="fit"/>
              </tr>
            </v-table-header>
  
            <v-table-body class="fw-medium">
              <tr v-if="!loaded">
                <td class="position-relative border-0 py-96" colspan="2222">
                  <v-loader/>
                </td>
              </tr>
  
              <template v-else-if="!!agencies && agencies.length > 0">
                <tr v-for="agency in agencies" :key="agency.id">
                  <td class="d-flex">
                    <div class="image-container rounded-sm bg-tertiary me-12">
                      {{ agency.name.charAt(0) }}
                    </div>
  
                    <a
                      class="py-12"
                      href="#"
                      @click.prevent="showAgencyDetails(agency)"
                    >
                      {{ agency.name }}
                    </a>
                  </td>
  
                  <td>{{ !! agency.contactCity ?  agency.contactCity : '-' }}</td>
  
                  <td class="fit text-end">
                    <v-button
                      class="btn-view rounded-pill bg-secondary"
                      size="xs"
                      variant="icon"
                      @click="showAgencyDetails(agency)"
                    >
                      <v-icon icon="arrow-right"/>
                    </v-button>
                  </td>
                </tr>
              </template>
  
              <template v-else>
                <tr>
                  <td class="rounded-xs py-16" colspan="2222">
                    {{ $t('admin.collaborator.noCollaborators') }}
                  </td>
                </tr>
              </template>
            </v-table-body>
          </v-table>
        </div>
  
        <div class="item-cards d-block d-md-none">
          <div v-if="!loaded" class="position-relative py-96">
            <v-loader/>
          </div>
  
          <template v-else-if="!!agencies && agencies.length > 0">
            <div v-for="agency in agencies" :key="agency.id" class="item-card border rounded-xs p-8 mb-12 text-sm">
              <div class="d-flex align-items-center">
                <div class="image-container rounded-sm bg-tertiary me-12">
                  {{ agency.name.charAt(0) }}
                </div>
  
                <div>
                  <div class="fw-medium">{{ agency.name }}</div>
                  <div class="fw-light text-email text-xs">{{  agency.contactCity }}</div>
                </div>
              </div>
  
              <div class="d-flex justify-content-end">
                <v-button
                  class="btn-view rounded-pill bg-secondary"
                  size="xs"
                  variant="icon"
                  @click="showAgencyDetails(agency)"
                >
                  <v-icon class="fw-semi" icon="arrow-right" size="xs"/>
                </v-button>
              </div>
            </div>
          </template>
  
          <div v-else>
            {{ $t('admin.collaborator.noCollaborators') }}
          </div>
        </div>
  
        <v-pagination
        v-if="!!agencies && agencies.length > 0"
        :meta="pagination.meta"
        :pagination-links="pagination.links"
        class="mt-32"
        @page-changed="changePage($event); handlePageChangedEvent($event)"
        @per-page-changed="perPage = $event; doSearch()"
      />
      </div>
  
      <the-admin-agency-filters-offcanvas
        v-if="offCanvasToShow === 'the-admin-agency-filters-offcanvas'"
        v-model:location="location"
        v-model:status="status"
        @closed="closeOffCanvas"
        @selected="doSearch"
      />
  
      <the-admin-collaborator-details-offcanvas
        v-if="offCanvasToShow === 'the-admin-agency-details-offcanvas'"
        :agency-data="agency"
        :is-archived="status === 'archived'"
        @closed="closeOffCanvas(); cleanCurrentAgency()"
        @reload-agencies="loadAgencies"
      />
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import { VInputTextSearch } from '@uniqoders/form';
  import qs from 'qs';
  import querystring from 'query-string';
  import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
  import VButton from '@/components/vendor/basic/button/VButton.vue';
  import VTable from '@/components/vendor/basic/table/VTable.vue';
  import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
  import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
  import VLoader from '@/components/vendor/loader/VLoader.vue';
  import api from '@/api';
  import withTimeout from '@/helpers/timeOut';
  import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
  import Agency from '@/api/objects/Agency';
  import TheAdminCollaboratorDetailsOffcanvas from '@/components/admin/collaborator/TheAdminCollaboratorDetailsOffcanvas.vue';
  import VListHeader from '@/components/shared/VListHeader.vue';
  import VPagination from '@/components/vendor/basic/table/VPagination.vue';
  import usePagination from '@/helpers/pagination';
  import TheAdminAgencyFiltersOffcanvas from '@/components/admin/agency/TheAdminAgencyFiltersOffcanvas.vue';
  import VFilters from '@/components/vendor/filters/VFilters.vue';
  import { findConceptByKey } from '@/helpers/ConceptHelper';
  
  export default defineComponent({
    name: 'TheAdminCollaborators',
    components: {
      VFilters,
      TheAdminAgencyFiltersOffcanvas,
      VPagination,
      VListHeader,
      TheAdminCollaboratorDetailsOffcanvas,
      VLoader,
      VTableBody,
      VTableHeader,
      VTable,
      VButton,
      VInputTextSearch,
      VIcon,
    },
    setup() {
      return {
        findConceptByKey,
        ...useOffCanvasUtils(),
        ...usePagination(),
      };
    },
    data() {
      return {
        agencies: [] as Agency[],
        agency: null as null | Agency,
        name: '' as string,
        location: '' as string,
        status: 'active' as string,
        filters: [
          {
            variable: 'status',
            key: 'status',
            labelAux: 'general.shared.filterStatus',
            conceptName: 'app.states',
            multiple: false,
            isConcept: true,
          },
          {
            variable: 'name',
            key: 'name',
            labelAux: 'general.shared.filterSearch',
            multiple: false,
            isConcept: false,
          },
          {
            variable: 'location',
            key: 'location',
            labelAux: 'general.shared.filterLocation',
            multiple: false,
            isConcept: false,
          },
          {
            variable: 'is_collaborator',
            key: 'is_collaborator',
            labelAux: 'general.shared.filterLocation',
            multiple: false,
            isConcept: false, //// should fix here
          },
        ] as Record<string, string | boolean>[],
        order: {
          key: '' as string,
          order: '' as string,
        } as any,
        perPage: 15 as number,
        page: 1 as number,
        timers: {
          name: null as any,
        },
        loaded: false as boolean,
        filterOptions: [] as Record<string, any>[],
      };
    },
    async created() {
      await this.setQueryParametersAsFilters();
      await this.doSearch();
    },
    methods: {
      search() {
        clearTimeout(this.timers.name);
  
        this.timers.name = withTimeout(async () => {
          await this.doSearch();
        }, this.timers.name, 1000);
      },
      /**
       * Detects any query parameter in the url and loads it in the filters (if available)
       *
       * @returns {Promise<void>}
       */
      async setQueryParametersAsFilters() {
        const { query }: any = this.$route;
  
        const {
          filters,
          order,
          perPage,
          status,
          page,
        }: any = qs.parse(query);
  
        if (!!filters) {
          this.filters.forEach((filter: Record<string, any>) => {
            if (!!filters[filter.key]) {
              this[filter.variable] = filters[filter.key];
              this['is_collaborator'] = true;
            }
          });
        }
  
        if (!!order) {
          this.order = order;
        }
  
        const pageNumber = parseInt(page, 10);
  
        if (!Number.isNaN(pageNumber)) {
          this.page = pageNumber;
        }
  
        const perPageNumber = parseInt(perPage, 10);
  
        if (!Number.isNaN(perPageNumber)) {
          this.perPage = perPageNumber;
        }
  
        if (!!status) {
          this.status = status;
        }
      },
      /**
       * Sets the local filters as url query parameters.
       */
      async setFiltersAsQueryParameters() {
        const filters: any = {};
  
        this.filters.forEach((filter: Record<string, any>) => {
          if (!!this[filter.variable]) {
            filters[filter.key] = this[filter.variable];
          }
        });
  
        const queryObject: any = {
          filters,
          perPage: this.perPage,
          status: this.status,
          page: this.page,
        };
  
        if (!!this.order && !!this.order.key) {
          queryObject.order = this.order;
        }
  
        await this.setFilters();
  
        let query: any = qs.stringify(queryObject, { encode: false });
        query = querystring.parse(query);
  
        this.$router.push({
          name: 'collaborators',
          query,
        });
      },
      async doSearch() {
        await this.setFiltersAsQueryParameters();
        await this.loadAgencies();
      },
      async loadAgencies(page?: number) {
        try {
          this.loaded = false;
  
          const filters: any = {};
  
          this.filters.forEach((filter: Record<string, any>) => {
            if (!!this[filter.variable]) {
              filters[filter.key] = this[filter.variable];
              this['is_collaborator'] = true;
            }
          });
  
          const query: any = {
            filters,
            order: this.order,
            perPage: this.perPage,
            status: this.status,
            page: !!page ? page : this.page
          };
  
          const response = await api.admin.agency.allPaginated(query);
  
          this.setResponse(response);
  
          this.agencies = response.data;
        } catch (e: any) {
          console.error(e);
  
          this.$toast.error(e.response.data.message);
        } finally {
          this.loaded = true;
        }
      },
      async showAgencyDetails(agency: Agency) {
        this.agency = agency;
  
        if (this.offCanvasToShow !== 'the-admin-agency-details-offcanvas') {
          this.openOffCanvas('the-admin-agency-details-offcanvas');
        }
      },
      cleanCurrentAgency() {
        this.agency = null;
      },
      async handlePageChangedEvent(page: number) {
        await this.loadAgencies(page);
      },
      toggleOrder(field: string) {
        const { order } = this.order;
  
        if (!order) {
          this.order.order = 'ASC';
        } else {
          this.order.order = order === 'ASC' ? 'DESC' : 'ASC';
        }
  
        this.order.key = field;
  
        this.search();
      },
      setFilters() {
        this.filterOptions = this.filters.flatMap((filter: Record<string, any>) => {
          const {
            key,
            multiple,
            isConcept,
            labelAux,
            conceptName,
            variable,
          } = filter;
  
          const options = multiple ? this[variable] : [this[variable]];
  
          return options.map((option: any) => {
            const value = isConcept ? findConceptByKey(`${conceptName}`, option) : option;
            const label = this.$t(`${labelAux}`, { value: !!conceptName ? value.name : value });
            return { key, value: !!conceptName ? value.key : value, label };
          });
        });
      },
      removeFilter(filter: Record<string, string>) {
        this.filters.forEach((filterAux: Record<string, string | boolean>) => {
          if (filterAux.key === filter.key) {
            if (!filterAux.multiple) {
              this[filterAux.variable] = '';
            } else {
              this[filterAux.variable] = this[filterAux.variable].filter((option: string) => option !== filter.value);
            }
          }
        });
  
        if (filter.key === 'status') {
          this.status = 'active';
        }
  
        this.doSearch();
      },
    },
  });
  </script>
  