<template>
  <div>
    <the-email-header />
    <div class="mt-32">
      <the-user-final-price />
    </div>
  </div>
</template>

<script>
import TheUserFinalPrice from "@/components/email/TheUserFinalPrice.vue";
import TheEmailHeader from "@/components/email/TheEmailHeader.vue";

export default {
  name: "TheEmailTemplate",
  components: {
    TheUserFinalPrice,
    TheEmailHeader,
  },
  created() {
    const {  lang } = this.$route.query;
    localStorage.setItem("language", lang);
  },
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
