import { AbstractService } from '@uniqoders/sdk';
import Location from '@/api/objects/Location';

export default class LocationService extends AbstractService {
  /**
   * Retrieves all Locations
   * @protected
   * @param params
   */
  public all(params?: any): Promise<Location[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/locations'),
      params,
    });
  }

  /**
   * Creates a single Location
   * @protected
   * @param data
   */
  public create(data: Record<string, any>): Promise<Location> {
    return this.request({
      method: 'post',
      url: this.buildPath('/location'),
      data,
    });
  }
}
