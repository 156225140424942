import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import Media from '@/api/objects/Media';
import ResourceTranslation from '@/api/objects/ResourceTranslation';

export interface ResourceContract extends IBaseObject {
  title: string;
  description: string;
  type: string;
  types: [];
  links: string;
  mapUrl: string;
  siteUrl: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  postalCode: string;
  phone: string;
  state: string;
  locationId: number;
  lat: string;
  lng: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  location: Location;
  media: Media[];
  translations: ResourceTranslation[];
}

export default class Resource extends BaseObject implements ResourceContract {
  public declare title: string;
  public declare description: string;
  public declare type: string;
  public declare types: [];
  public declare links: string;
  public declare mapUrl: string;
  public declare siteUrl: string;
  public declare address1: string;
  public declare address2: string;
  public declare city: string;
  public declare country: string;
  public declare postalCode: string;
  public declare phone: string;
  public declare state: string;
  public declare locationId: number;
  public declare lat: string;
  public declare lng: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare location: Location;
  public declare media: Media[];
  public declare translations: ResourceTranslation[];

  constructor(data: ResourceContract) {
    super(data);
  }
}
