export default {
  user: {
    users: 'Users',
    noUsers: 'There are no users',
    createNewUser: 'Create new user',
    userDetails: 'User details',
    basicInformation: 'Basic information',
    password: 'Password',
    passwordChangeText: 'The password cannot be changed, but you can send an email with a new password to the user.',
    sendEmail: 'Send email',
    archivedUsers: 'Archived users',
    activeUsers: 'Active users',
    agencies: 'Agencies',
    userEditAgenciesText: 'Edit the agencies to which the user belongs.',
    noAgencies: 'There are no associated agencies',
    addAgencies: 'Add agencies',
    removeAgencyUserTitle: 'Remove user from the agency',
    removeAgencyUserText: 'Are you sure you want to remove \'{user} ({email})\' from the \'{agency}\' agency?',
    addExistingAgency: 'Add existing agency',
    addAgencyUserTitle: 'Add user to agency',
    addAgencyUserText: 'Are you sure you want to add \'{user}\' to the \'{agency}\' agency?',
    archiveUserTitle: 'Archive user',
    archiveUserText: 'Are you sure you want to archive the user \'{user}\'? If you archive it, it will not appear in searches.',
    deleteUserTitle: 'Delete user',
    deleteUserText: 'Are you sure you want to delete the user \'{user}\'? If you delete it, you will not be able to recover it.',
    denyUserTitle: 'Deny user access',
    denyUserText: 'Are you sure you want to deny access to the user \'{user}\'? If you do so, this user will no longer be able to access the platform. If at any time it is necessary to give the user access again, just re-enable user access and the user will be able to access the platform.',
    forgiveUserTitle: 'Provide access',
    forgiveUserText: 'Are you sure you want to provide access to the user \'{user}\'? If you do so, the user will regain access to the platform.',
    loginAs: 'Login as',
    impersonateDescription: 'You can log in as this user and use the application as this user. You can return to your profile at any time.',
    impersonateText: 'You will be logged in as the user \'{user}\'. You will be able to see and do everything the user does and return to your administrator profile whenever you want. Do you want to continue?',
    impersonateErrorTitle: 'Error logging in',
    impersonateErrorText: 'Error trying to log in as another user',
    access: 'Access',
    userCanAccessText: 'The user is allowed access. If you want to deny access, click the \'Deny access\' button and the user will no longer be able to access the platform. After denying access, you can allow access again at any time.',
    userCannotAccessText: 'The user is not allowed access. If you want to allow access, click on the \'Provide access\' button and the user will be able to access the platform.',
    denyAccess: 'Deny access',
    denyingAccess: 'Denying access...',
    provideAccess: 'Provide access',
    providingAccess: 'Providing access...',
    archiveText: 'You can archive a user, if you do this the user will not appear in the application searches. You will be able to see it in the list of users if you filter by \'archived\'. Once you view the details of an archived user, you can delete it definitively or you can unarchive it.',
    archivedText: 'This user is archived, if you want you can unarchive it by clicking on the \'Restore\' button.',
    deleteText: 'This user is archived, if you want to delete it permanently click the \'Delete\' button. Note that if you delete it, this user will no longer exist and cannot be recovered.',
    restoreUserTitle: 'Recover user',
    restoreUserText: 'Are you sure you want to recover the user \'{user}\'? If you recover it, it will no longer be archived and will appear in the application searches.',
    userStatus: 'User status',
    agency: 'Agency',
  },
  agency: {
    agencies: 'Agencies',
    noAgencies: 'There are no agencies',
    createNewAgency: 'Create new agency',
    agencyDetails: 'Agency details',
    basicInformation: 'Basic information',
    archivedAgencies: 'Archived agencies',
    activeAgencies: 'Active agencies',
    users: 'Users',
    editUsersBelongAgencyText: 'Edit users belonging to the agency',
    noUsers: 'There are no associated users',
    usersEditBelongAgencyText: 'Edit users belonging to the agency',
    addUsers: 'Add users',
    removeAgencyUserTitle: 'Remove user from the agency',
    removeAgencyUserText: 'Are you sure you want to remove \'{user} ({email})\' from the \'{agency}\' agency?',
    addExistingUser: 'Add existing user',
    addAgencyUserTitle: 'Add user to agency',
    addAgencyUserText: 'Are you sure you want to add {user} to the {agency} agency?',
    archiveAgencyTitle: 'Archive agency',
    archiveAgencyText: 'Are you sure you want to archive the agency \'{agency}\'? If you archive it, it will not appear in searches.',
    deleteAgencyTitle: 'Delete agency',
    deleteAgencyText: 'Are you sure you want to delete the agency \'{agency}\'? If you delete it, you will not be able to recover it.',
    archiveText: 'You can archive a agency, if you do this the agency will not appear in the application searches. You will be able to see it in the list of agencies if you filter by \'archived\'. Once you view the details of an archived agency, you can delete it definitively or you can unarchive it.',
    archivedText: 'This agency is archived, if you want you can unarchive it by clicking on the \'Restore\' button.',
    deleteText: 'This agency is archived, if you want to delete it permanently click the \'Delete\' button. Note that if you delete it, this agency will no longer exist and cannot be recovered.',
    restoreAgencyTitle: 'Recover agency',
    restoreAgencyText: 'Are you sure you want to recover the agency \'{agency}\'? If you recover it, it will no longer be archived and will appear in the application searches.',
    agencyStatus: 'Agency status',
    contacts: 'Contacts',
    noContacts: 'There are no associated contacts',
    editContactsBelongAgencyText: 'Edit contacts belonging to the agency',
    addContacts: 'Add contacts',
    removeContactTitle: 'Remove agency contact',
    removeContactText: 'Are you sure you want to remove {contact} as a contact for the agency {agency}?',
    template: {
      title: 'Activities',
      subtitle: 'Activities associated with this agency',
      noTemplates: 'This agency does not have any associated activities',
      addExistingTemplate: 'Add existing activity',
      editTemplatesBelongsTo: 'Edits the activities to which this agency belongs',
      addTemplate: 'Add activity',
      addTemplates: 'Add activities',
      addTemplateToAgencyTitle: 'Add activity to agency',
      addTemplateToAgencyTitleText: 'Are you sure you want to associate the activity \'{ template }\' with the agency \'{ agency }\'?',
      detachTemplateFromAgencyTitle: 'Unassign activity from agency',
      detachTemplateFromAgencyTitleText: 'Are you sure you want to unassign the activity \'{ template }\' from the agency \'{ agency }\'?',
    },
    user: 'User',
  },
  collaborator: {
    collaborators: 'Collaborators',
    noCollaborators: 'There are no collaborators',
    createNewCollaborator: 'Create new collaborator',
    collaboratorDetails: 'Collaborator company details',
    basicInformation: 'Basic information',
    archivedCollaborators: 'Archived collaborators',
    activeCollaborators: 'Active collaborators',
    users: 'Collaborator Users',
    editUsersBelongCollaboratorText: 'Edit users belonging to the collaborator',
    noUsers: 'There are no associated users',
    usersEditBelongCollaboratorText: 'Edit users belonging to the collaborator',
    addUsers: 'Add users',
    removeCollaboratorUserTitle: 'Remove user from the collaborator',
    removeCollaboratorUserText: 'Are you sure you want to remove \'{user} ({email})\' from the \'{agency}\' collaborator?',
    addExistingUser: 'Add existing user',
    addCollaboratorUserTitle: 'Add user to collaborator',
    addCollaboratorUserText: 'Are you sure you want to add {user} to the {collaborator} collaborator?',
    archiveCollaboratorTitle: 'Archive collaborator',
    archiveCollaboratorText: 'Are you sure you want to archive the collaborator \'{agency}\'? If you archive it, it will not appear in searches.',
    deleteCollaboratorTitle: 'Delete collaborator',
    deleteCollaboratorText: 'Are you sure you want to delete the collaborator \'{agency}\'? If you delete it, you will not be able to recover it.',
    archiveText: 'You can archive a collaborator, if you do this the collaborator will not appear in the application searches. You will be able to see it in the list of collaborators if you filter by \'archived\'. Once you view the details of an archived collaborator, you can delete it definitively or you can unarchive it.',
    archivedText: 'This collaborator is archived, if you want you can unarchive it by clicking on the \'Restore\' button.',
    deleteText: 'This collaborator is archived, if you want to delete it permanently click the \'Delete\' button. Note that if you delete it, this collaborator will no longer exist and cannot be recovered.',
    restoreCollaboratorTitle: 'Recover collaborator',
    restoreCollaboratorText: 'Are you sure you want to recover the collaborator \'{agency}\'? If you recover it, it will no longer be archived and will appear in the application searches.',
    collaboratorStatus: 'Collaborator status',
    contacts: 'Contact people',
    noContacts: 'There are no associated contacts',
    editContactsBelongCollaboratorText: 'Edit contacts belonging to the collaborator',
    addContacts: 'Add contacts',
    removeContactTitle: 'Remove collaborator contact',
    removeContactText: 'Are you sure you want to remove {contact} as a contact for the collaborator {collaborator}?',
    template: {
      title: 'Activities',
      subtitle: 'Activities associated with this collaborator',
      noTemplates: 'This collaborator does not have any associated activities',
      addExistingTemplate: 'Add existing activity',
      editTemplatesBelongsTo: 'Edits the activities to which this collaborator belongs',
      addTemplate: 'Add activity',
      addTemplates: 'Add activities',
      addTemplateToCollaboratorTitle: 'Add activity to collaborator',
      addTemplateToCollaboratorTitleText: 'Are you sure you want to associate the activity \'{ template }\' with the collaborator \'{ agency }\'?',
      detachTemplateFromCollaboratorTitle: 'Unassign activity from collaborator',
      detachTemplateFromCollaboratorTitleText: 'Are you sure you want to unassign the activity \'{ template }\' from the collaborator \'{ agency }\'?',
    },
    user: 'User',
  },

};
