<template>
  <div id="accordionTourContacts" class="accordion">
    <div class="case-details accordion-item">
      <h2 id="headingTourContacts" class="accordion-header">
        <button
          aria-controls="collapseTourContacts"
          aria-expanded="true"
          class="accordion-button collapsed"
          data-bs-target="#collapseTourContacts"
          data-bs-toggle="collapse"
          type="button">
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="contact" size="xxl" space="p-12"/>
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t('clientTour.contact.contacts') }}</div>
              <div class="fw-light accordion-subtitle">{{ $t('clientTour.contact.contactsSubtitle') }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseTourContacts"
        aria-labelledby="headingTourContacts"
        class="accordion-collapse collapse show"
        data-bs-parent="#accordionTourContacts">
        <div class="accordion-body pb-32 pb-xl-48">
          <form
            id="case-tour-update-contact-text-form"
            class="d-flex flex-column justify-content-between h-100"
          >
            <fieldset>
              <legend class="visually-hidden">{{ $t('clientTour.contact.contactInformation') }}</legend>

              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('clientTour.contact.contactInformation') }}</span>
                </label>

                <v-html-editor
                  id="case-tour-update-contact-text"
                  ref="case-tour-update-contact-text-ref"
                  :value="contactInformationLocal"
                  @input="contactInformationLocal = $event"
                  @media-uploaded="editorMedia.push($event)"
                />
              </div>
            </fieldset>

            <v-button
              :disabled="!meta.valid || disableButton || !meta.dirty"
              :is-loading="accepted"
              class="btn-icon w-fit align-self-end mt-24"
              size="sm"
              variant="primary"
              @click="updateTour"
            >
              {{ $t('general.button.save') }}
              <v-icon icon="arrow-right" size="sm" space="ms-12"/>
            </v-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import Tour from '@/api/objects/Tour';
import VHtmlEditor from '@/components/vendor/htmlEditor/VHtmlEditor.vue';
import api from '@/api';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'TheCaseSetupTourContacts',
  components: {
    VHtmlEditor,
    VIcon,
    VButton,
  },
  emits: ['reloadClientTour'],
  props: {
    agency: {
      type: String,
      required: true,
    },
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      contactInformationLocal: yup.string()
        .nullable()
        .label(t('clientTour.contact.contactInformation')),
    });

    const initialValues = {
      contactInformationLocal: props.tour.contactText,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: contactInformationLocal } = useField<string>('contactInformationLocal');

    return {
      ...form,
      contactInformationLocal,
    };
  },
  data() {
    return {
      disableButton: false as boolean,
      editorMedia: [] as any[],
    };
  },
  methods: {
    async updateTour() {
      try {
        this.toggleAccepted();
        this.disableButton = true;

        const data = {
          name: this.tour.name,
          travel_type: this.tour.travelType,
          origin: this.tour.originId,
          destination: this.tour.destinationId,
          contact_text: this.contactInformationLocal,
        };

        await api.tour.update(this.agency, this.caseId, this.tour.id, data);

        if (!!this.editorMedia && this.editorMedia.length > 0) {
          const editorMedia = {
            media: this.editorMedia,
            model_type: 'tour',
            collection: 'tinymce',
            model_id: this.tour.id,
          };

          await api.media.confirm('s3', editorMedia);
        }

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadClientTour');

        this.disableButton = false;
        this.toggleAccepted();
      } catch (e: any) {
        this.disableButton = false;
        this.toggleAccepted();

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
