import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface ConceptContact extends IBaseObject {
  type: string;
  name: string;
  key: string;
  value: string | null;
  order: number;
  shortName: string;
  description: string;
  class: string;
  color: string;
  icon: string;
  status: string;
  permission: string;
}

export default class Concept extends BaseObject implements ConceptContact {
  public declare type: string;
  public declare name: string;
  public declare key: string;
  public declare value: string | null;
  public declare order: number;
  public declare shortName: string;
  public declare description: string;
  public declare class: string;
  public declare color: string;
  public declare icon: string;
  public declare status: string;
  public declare permission: string;

  constructor(data: ConceptContact) {
    super(data);
  }
}
