import { ShallowRef } from 'vue';
import { defineStore } from 'pinia';
import i18n from '@/lang';

const $t = i18n.global.t;

export type GeneralModalType = {
  show: boolean,
  title: string | any,
  text: string,
  subtitle: string,
  textLayout: string | null | ShallowRef,
  showCloseX: boolean,
  backdrop: boolean,
  backdropStatic: boolean,
  rightButtonText: string,
  rightButtonClasses: string,
  rightButtonLoadingText: string,
  rightButtonDisabled: boolean,
  rightButtonCallback: any,
  leftButtonText: string,
  leftButtonClasses: string,
  leftButtonLoadingText: string,
  leftButtonCallback: any,
  leftButtonDisabled: boolean,
  isDoubleModal: boolean,
  switchButtons: boolean,
};

export interface generalModalsState {
  modal: {
    show: boolean,
    title: string,
    text: string,
    subtitle: string,
    textLayout: string | null,
    showCloseX: boolean,
    backdrop: boolean,
    rightButtonText: string,
    rightButtonClasses: string,
    rightButtonLoadingText: string,
    rightButtonDisabled: boolean,
    rightButtonCallback: any,
    leftButtonText: string,
    leftButtonClasses: string,
    leftButtonLoadingText: string,
    leftButtonCallback: any,
    leftButtonDisabled: boolean,
    isDoubleModal: boolean,
    switchButtons: boolean,
  },
}

export const useGeneralModalsStore = defineStore('generalModals', {
  state: (): generalModalsState => ({
    modal: {
      show: false,
      title: '',
      text: '',
      subtitle: '',
      textLayout: null,
      showCloseX: true,
      backdrop: false,
      rightButtonText: $t('general.button.confirm'),
      rightButtonClasses: 'btn-primary',
      rightButtonLoadingText: $t('general.button.processing'),
      rightButtonDisabled: false,
      rightButtonCallback: null,
      leftButtonText: $t('general.button.close'),
      leftButtonClasses: 'btn-secondary',
      leftButtonLoadingText: $t('general.button.processing'),
      leftButtonCallback: null,
      leftButtonDisabled: false,
      isDoubleModal: true,
      switchButtons: false,
    },
  }),
  actions: {
    showModal(payload: any) {
      this.modal = {
        ...this.modal,
        ...payload,
        leftButtonDisabled: false,
        rightButtonDisabled: false,
        backdrop: false,
        show: true,
      };
    },
    enableModalLeftButton() {
      this.modal.leftButtonDisabled = false;
    },
    disableModalLeftButton() {
      this.modal.leftButtonDisabled = true;
    },
    enableModalRightButton() {
      this.modal.rightButtonDisabled = false;
    },
    disableModalRightButton() {
      this.modal.rightButtonDisabled = true;
    },
    hideModal() {
      this.modal.show = false;
    },
  },
});
