<template>
  <div v-if="!!currentTourDayItem" class="h-100">
    <v-loader v-if="status === 'loading' && suppliersStatus === 'loading'"/>

    <form v-else id="template-day-item-resource-select" class="d-flex flex-column justify-content-between h-100">
      <div>
        <fieldset>
          <legend class="visually-hidden">{{ $t('tourBuilder.searchTourDayItemResource') }}</legend>

          <div class="form-outline">
            <label class="label">
              <span
                v-if="currentTourDayItem.type !== undefined &&
                  currentTourDayItem.type === 'stage_information'
                  || currentTourDayItem.type === 'general_information'">
                {{ $t('general.shared.selected') }}
              </span>
              <span v-else>
                {{ $t('general.shared.searchByNameOrLocation') }}
              </span>
            </label>

            <v-select v-if="currentTourDayItem.type !== undefined &&
              currentTourDayItem.type === 'stage_information' || currentTourDayItem.type === 'general_information'"
                id="tour-day-item-supplier-select"
                v-model="supplier"
                :filterable="false"
                :options="stageResouce"
                class="select-search"
                label="name"
                @search="onSearch"
                @option:selected="loadResouceSelected"
              >
                <template v-slot:no-options>
                  {{ $t('tourBuilder.writeSearchResources') }}
                </template>

                <template v-slot:option="option">
                  <div class="d-center">
                    <span>{{ option?.title }}</span>
                  </div>
                </template>

                <template v-slot:selected-option="option">
                  <div class="selected d-center py-8">
                    <span>{{ option?.title }}</span>
                  </div>
                </template>

                <template #list-footer>
                  <li ref="load" class="create-client">
                    <v-button
                      class="btn-icon h-fit fw-normal shadow-none py-0 px-16"
                      size="sm"
                      variant="link"
                      @click="openOffCanvas('the-resource-create-offcanvas')"
                    >
                      <v-icon icon="plus" size="xs" space="me-8" />
                      {{ $t('clientTour.createResource') }}
                    </v-button>
                  </li>
                </template>
              </v-select>
              <v-select
                v-else id="tour-day-item-supplier-select"
                v-model="supplier"
                :filterable="false"
                :options="suppliers"
                class="select-search"
                label="name"
                @search="onSearch"
                @option:selected="loadSupplierSelected"
              >
                <template v-slot:no-options>
                  {{ $t('tourBuilder.writeSearchSuppliers') }}
                </template>

                <template v-slot:option="option">
                  <div class="d-center">
                    <span class="text-sm fw-light ms-4">
                      ({{ $str.roundedToFixed($str.milesToKilometers(Number(option?.distance)), 1) }}
                      {{ $t('tourBuilder.KilometersShorted') }})
                    </span>

                    <span>{{ option?.name }}</span>

                    <span v-if="!!option?.locationName" class="text-sm fw-light ms-4">
                      ({{ option?.locationName }})
                    </span>
                  </div>
                </template>

                <template v-slot:selected-option="option">
                  <div class="selected d-center py-8">
                    <span>{{ option?.name }}</span>

                    <span v-if="!!option?.distance" class="text-sm fw-light ms-4">
                      ({{ $str.roundedToFixed($str.milesToKilometers(Number(option.distance)), 1) }}
                      {{ $t('tourBuilder.KilometersShorted') }})
                    </span>

                    <span v-if="!!option?.locationName" class="text-sm fw-light ms-4">
                      ({{ option?.locationName }})
                    </span>
                  </div>
                </template>

                <template #list-footer>
                  <li ref="load" class="create-client">
                    <v-button class="btn-icon h-fit fw-normal shadow-none py-0 px-16" size="sm" variant="link"
                      @click="openOffCanvas('the-supplier-create-offcanvas')">
                      <v-icon icon="plus" size="xs" space="me-8" />
                      {{ $t('clientTour.createSupplier') }}
                    </v-button>
                  </li>
                </template>
              </v-select>
          </div>

          <!-- eslint-disable-next-line max-len -->
          <div v-if="!!supplier && showProducts && !!supplier.id && stageResouce.length === 0" class="resources-container form-outline">
            <div class="d-flex justify-content-between">
              <label class="label">
                <span>{{ $t('general.shared.products') }}</span>
              </label>

              <label class="label me-20 bg-light">
                <v-button
                  class="btn-rates p-0 mx-4"
                  variant="icon"
                  @click="openOffCanvas('the-supplier-details-rates-off-canvas')">
                  <v-icon class="px-0" icon="euro"/>
                </v-button>

                <v-button
                  class="btn-rates p-0 mx-4"
                  variant="icon"
                  @click="openOffCanvas('the-supplier-details-offcanvas')">
                  <v-icon class="px-0" icon="pencil"/>
                </v-button>

                <v-button
                  class="btn-rates p-0 mx-4"
                  variant="icon"
                  @click="loadSupplierSelected">
                  <v-icon class="px-0" icon="refresh"/>
                </v-button>
              </label>
            </div>

            <div v-if="!!resources && resources.length > 0" class="form-control">
              <div
                v-for="resource in resources"
                :key="resource.id"
                class="resource-item d-flex justify-content-between align-items-center py-4"
              >
                <v-checkbox
                  :id="`template-day-item-create-resource-${resource.id}`"
                  :for-key="`template-day-item-create-resource-${resource.id}`"
                  :label="resource.name"
                  class="form-check-inline me-32"
                  label-class="ms-0"
                  @update:modelValue="setResource($event, resource)"
                />

                <div v-if="!!checkExistResource(resource)" class="d-flex">
                  <v-button
                    v-if="checkExistResource(resource).quantity > 1"
                    class="p-0"
                    size="sm"
                    variant="icon"
                    @click="removeResource(resource)"
                  >
                    <v-icon icon="remove" size="sm"/>
                  </v-button>

                  <div class="mx-8">{{ checkExistResource(resource).quantity }}</div>

                  <v-button
                    class="p-0"
                    size="sm"
                    variant="icon"
                    @click="addResource(resource)"
                  >
                    <v-icon icon="circle-plus" size="sm"/>
                  </v-button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset v-if="!!resourcesSelected && resourcesSelected.length > 0">
          <div class="search-separator-container mb-24 pb-24">
            <div class="separator-text-container mb-16"/>
          </div>

          <legend class="visually-hidden">{{ $t('tourBuilder.tourDayItemResourceDates') }}</legend>

          <div>
            <template v-for="(resource, index) in resourcesSelected" :key="index">
              <div :id="`accordionSupplierResource${resource.data.id}`" class="accordion border mb-16">
                <div class="supplier-resource-details accordion-item mb-0">
                  <div :id="`headingSupplierResource${resource.data.id}`" class="accordion-header">
                    <button
                      :aria-controls="`collapseSupplierResource${resource.data.id}`"
                      :data-bs-target="`#collapseSupplierResource${resource.data.id}`"
                      aria-expanded="true"
                      class="accordion-button collapsed p-8"
                      data-bs-toggle="collapse"
                      type="button">
                      <span class="fw-medium">
                        {{ resource.data.name }}
                      </span>
                    </button>
                  </div>

                  <div
                    :id="`collapseSupplierResource${resource.data.id}`"
                    :aria-labelledby="`headingSupplierResource${resource.data.id}`"
                    :data-bs-parent="`#accordionSupplierResource${resource.data.id}`"
                    class="accordion-collapse collapse show">
                    <div class="accordion-body p-8">
                      <div
                        v-for="(tourDayItemResource, indexItemResources) in resource.tourDayItemResources"
                        :key="indexItemResources"
                        class="border rounded-xs p-12 gx-8 mb-8"
                      >
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="fw-medium text-sm">
                            {{ resource.data.name }}
                            <span v-if="resource.tourDayItemResources.length > 1" class="fw-normal text-xs">
                              (#{{ indexItemResources + 1 }})
                            </span>
                          </div>

                          <v-button
                            size="sm"
                            variant="icon"
                            @click="removeTourDayItemResource(resource.data.id, tourDayItemResource)"
                          >
                            <v-icon icon="trash" size="sm"/>
                          </v-button>
                        </div>

                        <the-template-day-item-details-resource-form
                          v-if="!!tourDayItemResource"
                          :data="tourDayItemResource"
                          :index="indexItemResources"
                          :type="currentTourDayItem.type"
                          @refresh-resource-data="setItemResourceData($event, index, indexItemResources)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </fieldset>
        <fieldset v-if="!!selectedResouce && !!supplier && !!supplier.id">
            <div class="selected d-center py-8">
              <div class="d-flex justify-content-center">
                <label class="label">
                  <span>{{ $t('general.shared.basicInformation') }}</span>
                </label>
              </div>
              <div class="text-sm d-flex py-4">
                  <span class="me-16 fw-medium">{{ $t('general.shared.title') }}: </span>
                  <span>{{ selectedResouce?.title }}</span>
              </div>
              <div class="text-sm d-flex py-4">
                <span class="me-16 fw-medium">{{ $t('general.shared.location') }}: </span>
                <span>
                  {{ selectedResouce?.location?.name }}
                  <v-icon v-if="!!selectedResouce?.destination" icon="arrow-right" size="xs" space="px-8" />
                  {{ selectedResouce?.destination }}
                </span>
              </div>
              <div class="text-sm d-flex py-4">
                <span class="me-16 fw-medium">{{ $t('general.shared.types') }}: </span>
                <span>{{ selectedResouce?.destination }}</span>
              </div>
              <div class="text-sm d-flex py-4">
                <span class="me-16 fw-medium">{{ $t('general.shared.description') }}: </span>
                <span v-html="selectedResouce?.description" />
              </div>
              <div class="search-separator-container mb-8">
                <div class="separator-text-container">
                  <span class="separator-text text-sm">{{ $t('general.shared.links') }}</span>
                </div>
              </div>
              <div v-if="selectedResouce?.links" class="text-sm d-flex flex-column py-4">
                <span class="me-16 fw-medium">Links: </span>
                <template v-for="url in JSON.parse(selectedResouce?.links)" :key="url">
                  <a :href="url" class="resource-link btn-link" rel="noopener noreferrer" target="_blank">
                      {{ url }}
                  </a>
                </template>
              </div>
            </div>
          </fieldset>
      </div>
      <v-button
          v-if="currentTourDayItem.type === 'stage_information' || currentTourDayItem.type === 'general_information'"
          :disabled="!selectedResouce || stageResouce.length === 0"
          class="btn-submit-form btn-icon w-fit align-self-end mt-8" size="sm" variant="primary"
          @click="createTourDayItemResource"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12" />
        </v-button>
        <v-button
          v-else
          :disabled="!supplier.id"
          class="btn-submit-form btn-icon w-fit align-self-end mt-8" size="sm" variant="primary"
          @click="createTourDayItemResource"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12" />
        </v-button>
      <!-- <v-button
        :disabled="!resourcesSelected || resourcesSelected.length === 0"
        class="btn-submit-form btn-icon w-fit align-self-end"
        size="sm"
        variant="primary"
        @click="createTourDayItemResource"
      >
        {{ $t('general.button.create') }}
        <v-icon icon="arrow-right" size="sm" space="ms-12"/>
      </v-button> -->
    </form>

    <the-supplier-details-rates-off-canvas
      v-if="offCanvasToShow === 'the-supplier-details-rates-off-canvas'"
      :supplier-id="supplier.id"
      @closed="closeOffCanvas"
    />

    <the-supplier-create-offcanvas
      v-if="offCanvasToShow === 'the-supplier-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-suppliers="closeOffCanvas"
      @open-details="supplier = $event"
    />

    <the-supplier-details-offcanvas
      v-if="offCanvasToShow === 'the-supplier-details-offcanvas'"
      :is-archived="false"
      :is-double-off-canvas="false"
      :supplier-data="supplier"
      @closed="closeOffCanvas(); loadSupplierSelected()"
    />

    <the-resource-create-offcanvas
      v-if="offCanvasToShow === 'the-resource-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-resources="closeOffCanvas"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import { VCheckbox } from '@uniqoders/form';
import { mapState } from 'pinia';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import Supplier from '@/api/objects/Supplier';
import { useUserStore } from '@/stores/user';
import api from '@/api';
import SupplierResource from '@/api/objects/SupplierResource';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import TheSupplierDetailsRatesOffCanvas from '@/components/supplier/parts/TheSupplierDetailsRatesOffCanvas.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import TheTemplateDayItemDetailsResourceForm from '@/components/template/TheTemplateDayItemDetailsResourceForm.vue';
import TheSupplierCreateOffcanvas from '@/components/supplier/TheSupplierCreateOffcanvas.vue';
import TheSupplierDetailsOffcanvas from '@/components/supplier/TheSupplierDetailsOffcanvas.vue';
import TheResourceCreateOffcanvas from '@/components/resource/TheResourceCreateOffcanvas.vue';
import Resource from '@/api/objects/Resource';

export default defineComponent({
  name: 'TheTemplateDayItemDetailsCreateForm',
  components: {
    TheSupplierDetailsOffcanvas,
    TheSupplierCreateOffcanvas,
    TheTemplateDayItemDetailsResourceForm,
    TheSupplierDetailsRatesOffCanvas,
    TheResourceCreateOffcanvas,
    VLoader,
    VIcon,
    vSelect,
    VButton,
    VCheckbox,
  },
  emits: [
    'closed',
    'emitReloadTour',
  ],
  props: {
    agency: {
      type: String,
      required: true,
    },
    templateId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
      ...useOffCanvasUtils(),
    };
  },
  data() {
    return {
      resourceKey: 0 as number,
      suppliers: [] as Supplier[],
      stageResouce: [] as Resource[],
      supplier: {} as Supplier,
      resources: [] as SupplierResource[],
      resourcesMapped: [] as SupplierResource[],
      suppliersStatus: 'loading' as string,
      resourceStatus: 'loading' as string,
      mix: '' as string,
      resourcesSelected: [] as Record<string, any>[],
      resourcesNextStep: false as boolean,
      filters: {
        mix: '' as string,
        type: this.currentTourDayItem?.type as string,
      } as Record<string, any>,
      timers: {
        mix: null as any,
      } as Record<string, any>,
      media: null as any,
      selectedResouce: null as any,
      showProducts: true as boolean,
    };
  },
  computed: {
    ...mapState(useUserStore, ['currentTourDayItem', 'currentTemplate']),
  },
  methods: {
    setItemResourceData(value: any, resourceIndex: number, indexItemResources: any) {
      this.resourcesSelected[resourceIndex].tourDayItemResources[indexItemResources].observations = value.observations;
      // eslint-disable-next-line max-len
      this.resourcesSelected[resourceIndex].tourDayItemResources[indexItemResources].client_observations = value.client_observations;
      this.resourcesSelected[resourceIndex].tourDayItemResources[indexItemResources].nReserve = value.nReserve;
      this.resourcesSelected[resourceIndex].tourDayItemResources[indexItemResources].origin = value.origin;
      this.resourcesSelected[resourceIndex].tourDayItemResources[indexItemResources].destination = value.destination;
      this.resourcesSelected[resourceIndex].tourDayItemResources[indexItemResources].initHour = value.initHour;
      this.resourcesSelected[resourceIndex].tourDayItemResources[indexItemResources].endHour = value.endHour;
      this.resourcesSelected[resourceIndex].tourDayItemResources[indexItemResources].isOptional = value.isOptional;
      this.media = value.media;
    },
    onSearch(search: string, loading: any) {
      if (search.length) {
        loading(true);
        const type = this.currentTourDayItem?.type !== undefined &&
          this.currentTourDayItem?.type === 'stage_information' || this.currentTourDayItem?.type === 'general_information'
        type ? this.loadResources(loading, search) : this.loadSuppliers(loading, search);
      }
    },
    async loadSuppliers(loading: any, search: string) {
      if (!!this.currentTourDayItem) {
        loading(true);

        try {
          const query = {
            filters: {
              type: this.currentTourDayItem.type,
              mix: search,
            },
            location_id: (this.currentTourDayItem.tourDay.type === 'stage')
              ? this.currentTourDayItem.tourDay.destinationId
              : this.currentTourDayItem.tourDay.originId,
          };

          this.suppliers = await api.supplier.all(query);
        } catch (e: any) {
          console.error(e);
        } finally {
          this.suppliersStatus = 'loaded';

          loading(false);
        }
      }
    },
    async loadSupplierSelected() {
      if (this.supplier?.type === 'hotel') {
        this.showProducts = false;
      } else {
        this.showProducts = true;
      }
      if (!!this.currentTourDayItem && !!this.supplier) {
        try {
          this.resourceStatus = 'loading';

          const supplier = await api.supplier.retrieve(this.supplier.id);

          this.resources = supplier.resources;
        } catch (e: any) {
          console.error(e);

          this.$toast.error(e.response.data.message);
        } finally {
          this.resourceStatus = 'loaded';
        }
      }
    },
    async loadResources(loading: any, search: string) {
      if (!!this.currentTourDayItem) {
        loading(true);

        try {
          const query = {
            filters: {
              type: this.currentTourDayItem.type,
              title: search,
            },
          };

          this.stageResouce = await api.resource.all(query);
        } catch (e: any) {
          console.error(e.response.data);
        } finally {
          this.suppliersStatus = 'loaded';

          loading(false);
        }
      }
    },
    async loadResouceSelected() {
      if (!!this.currentTourDayItem && !!this.supplier) {
        try {
          this.resourceStatus = 'loading';

          const resource = await api.resource.retrieve(this.supplier.id);

          this.selectedResouce = resource;
        } catch (e: any) {
          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        } finally {
          this.resourceStatus = 'loaded';
        }
      }
    },
    async createTourDayItemResource() {
      if (!!this.currentTourDayItem) {
        try {
          this.resourceStatus = 'loading';
          if (this.currentTourDayItem.type === 'stage_information' || this.currentTourDayItem.type === 'general_information') {
            const data = {
              "model_type": "resource",
              "model_id": this.selectedResouce.id,
            }

            await api.templateDayItemResource.create(
              this.agency,
              this.templateId,
              this.currentTourDayItem.tourDay.id,
              this.currentTourDayItem.id,
              data,
            );
          } else if(this.currentTourDayItem.type === 'hotel') {
            const reqParam: Record<string, any> = {
                model_type: 'supplier',
                model_id: this.supplier.id
              };

              // eslint-disable-next-line no-await-in-loop
              await api.templateDayItemResource.create(
                this.agency,
                this.templateId,
                this.currentTourDayItem.tourDay.id,
                this.currentTourDayItem.id,
                reqParam,
              );
              this.$toast.success(this.$t('general.shared.savedChanges'));
          } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const resource of this.resourcesSelected) {
            // eslint-disable-next-line no-restricted-syntax
            for (const tourDayItemResource of resource.tourDayItemResources) {
              const data: Record<string, any> = {
                model_type: 'supplier_resource',
                model_id: resource.data.id,
                observations: tourDayItemResource.observations,
                client_observations: tourDayItemResource.client_observations,
                n_reserve: tourDayItemResource.nReserve,
                origin: tourDayItemResource.origin,
                destination: tourDayItemResource.destination,
                init_hour: tourDayItemResource.initHour,
                end_hour: tourDayItemResource.endHour,
                is_optional: tourDayItemResource.isOptional,
              };

              // eslint-disable-next-line no-await-in-loop
              const response = await api.templateDayItemResource.create(
                this.agency,
                this.templateId,
                this.currentTourDayItem.tourDay.id,
                this.currentTourDayItem.id,
                data,
              );

              if (!!this.media && this.media.length > 0) {
                const media = {
                  media: this.media,
                  model_type: 'tour_day_item_resource',
                  collection: 'item_resource',
                  model_id: response.id,
                };

                // eslint-disable-next-line no-await-in-loop
                await api.media.confirm('s3', media);
              }

              this.$toast.success(this.$t('general.shared.savedChanges'));
            }
          }
        }

          this.$emit('emitReloadTour');

          const data = {
            agency: this.agency,
            templateId: this.templateId,
            templateDayId: this.currentTourDayItem.tourDayId,
            templateDayItemId: this.currentTourDayItem.id,
          };

          await this.userStore.setCurrentTemplateDayItem(data);

          this.resourceStatus = 'loaded';
        } catch (e: any) {
          this.resourceStatus = 'loaded';

          console.error(e.response.data);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    setResource(checkBoxValue: any, resource: SupplierResource | any) {
      if (checkBoxValue) {
        this.addResource(resource);
      } else {
        this.removeResource(resource, true);
      }
    },
    addResource(resource: SupplierResource | any) {
      if (!!this.currentTourDayItem) {
        const resourceExist = this.checkExistResource(resource);

        this.resourceKey += 1;

        const tourDayItemResource: Record<string, any> = {
          id: this.resourceKey,
        };

        if (!!resourceExist) {
          resourceExist.tourDayItemResources.push(tourDayItemResource);

          resourceExist.quantity += 1;
        } else {
          this.resourcesSelected.push({
            data: resource,
            quantity: 1,
            tourDayItemResources: [
              tourDayItemResource,
            ],
          });
        }
      }
    },
    removeResource(resource: SupplierResource, IsRemoveAllResource = false) {
      const resourceExist = this.checkExistResource(resource);

      if (!!resourceExist) {
        if (resourceExist.quantity <= 1 || IsRemoveAllResource) {
          this.resourcesSelected = this.resourcesSelected.filter((el) => el.data.id !== resource.id);
        } else {
          resourceExist.quantity -= 1;
        }
      }

      if (this.resourcesSelected.length === 0) {
        this.resourcesNextStep = false;
      }
    },
    checkExistResource(resource: SupplierResource | Record<string, any> | any): Record<string, any> | undefined {
      return this.resourcesSelected.find((resourceAux) => resourceAux.data.id === resource.id);
    },
    removeResourceFromAll(resource: SupplierResource) {
      this.removeResource(resource);
    },
    removeTourDayItemResource(
      resourceId: number,
      tourDayItemResource: Record<string, any>,
      IsRemoveAllResource = false,
    ) {
      const resourceExist = this.resourcesSelected.find((resourceAux) => resourceAux.data.id === resourceId);

      if (!!resourceExist) {
        if (resourceExist.quantity <= 1 || IsRemoveAllResource) {
          this.resourcesSelected = this.resourcesSelected.filter((el) => el.data.id !== resourceId);
        } else {
          resourceExist.quantity -= 1;

          // eslint-disable-next-line max-len
          resourceExist.tourDayItemResources = resourceExist.tourDayItemResources.filter((el: Record<string, any>) => el.id !== tourDayItemResource.id);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.accordion-item {
  background-color: transparent;
}

.accordion .accordion-item .accordion-button {
  font-size: 1rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: transparent;
}
</style>
