export default {
  title: 'Activities',
  noProducts: 'There are no activities',
  createNew: 'Create new Activity',
  setup: 'Setup',
  builder: 'Builder',
  productBuilder: 'Activity Builder',
  product: 'Activity',
  travelType: 'Travel type',
  show: 'Show Activity',
  archived: 'Archived activities',
  active: 'Active activities',
  basicInformation: 'Basic information',
  details: 'Activity details',
  detailsSubtitle: 'General activity information lives here',
  media: 'Media',
  noMedia: 'There are no associated media',
  archiveSubtitle: 'You can archive the activity here',
  archivedText: 'This activity is archived, if you want you can unarchive it by clicking on the \'Restore\' button.',
  archiveTitle: 'Archive activity',
  archiveAlertText: 'Are you sure you want to archive the activity \'{product}\'? If you archive it, it will not appear in searches.',
  archiveText: 'You can archive a Activity, if you do this the Activity will not appear in the application searches. You will be able to see it in the list of activities if you filter by \'archived\'. Once you view the details of an archived activity, you can delete it definitively or you can unarchive it.',
  archiveTextAlert: 'Are you sure you want to archive the activity \'{product}\'? If you archive it, it will not appear in searches.',
  deleteText: 'This activity is archived, if you want to delete it permanently click the \'Delete\' button. Note that if you delete it, this activity will no longer exist and cannot be recovered.',
  deleteTitle: 'Delete activity',
  deleteTextAlert: 'Are you sure you want to delete the activity \'{product}\'? If you delete it, you will not be able to recover it.',
  restoreTitle: 'Restore activity',
  restoreText: 'Are you sure you want to recover the activity \'{product}\'? If you recover it, it will no longer be archived and will appear in the application searches.',
  status: 'Activity status',
  search: 'Activity search',
  canSearch: 'You can search for the activity',
  orUpdateItManually: 'or update it manually',
  removeMediaTitle: 'Delete media',
  removeMediaText: 'Are you sure you want to delete the archive? If you delete it, you will not be able to recover it.',
  tourType: 'Tour type',
  noArchived: 'No archived activities',
  useProduct: 'Use activity',
  productSaved: 'Activity saved',
  createCaseWithTemplate: 'Create case with this activity',
  deleteDayTitle: 'Delete day',
  deleteDayText: 'Are you sure you want to delete the day {dayNumber}? If you delete it, all the progress you have made on this day will be lost.',
  dayDeleted: 'Day successfully deleted',
  dayDeleteError: 'An error occurred while deleting the day',
  lengthErrorMessage: 'Write a distance in Kms or write zero',
  dayUpdated: 'Day successfully updated',
  dayUpdateError: 'An error occurred while updating the day',
  stage: 'Stage',
  duplicateActivity: 'Duplicate Activity',
  duplicatedActivitySuccess: 'Successfully duplicated activity',
  redirectingNewActivity: 'Redirecting to the new activity...',
  templateBelongsToCollaborator: 'This activity is for collaborators',
  agency: {
    title: 'Agencies',
    subtitle: 'Agencies associated with this activity',
    noAgencies: 'This activity is not associated with any agency',
    editAgenciesBelongsTo: 'Edit the agencies this activity belongs to',
    addAgency: 'Add agency',
    addAgencyToTemplateTitle: 'Add agency to activity',
    addAgencyToTemplateTitleText: 'Are you sure you want to associate the agency \'{ agency }\' with the activity \'{ template }\'?',
    detachAgencyToTemplateTitle: 'Unassign agency from activity',
    detachAgencyToTemplateTitleText: 'Are you sure you want to unassign the agency \'{ agency }\' from the activity \'{ template }\'?',
  },
  contact: {
    contacts: 'Contacts',
    contactsSubtitle: 'Here you can add the default contact information for the preview and quotation of cases using this activity',
    contactInformation: 'Contact information',
  },
  pax: {
    title: 'Pax',
    subtitle: 'Pax of the activity',
  },
  addDayTitle: 'Add day to activity',
  addDayText: 'Are you sure you want to add a new day to this activity?',
};
