import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface TourDayItemResourcePaymentContract extends IBaseObject {
  amountPaid: string;
  paymentMethod: string;
  paymentStatus: string;
  invoiceStatus: string;
  tourDayItemResourceId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export default class TourDayItemResourcePayment extends BaseObject implements TourDayItemResourcePaymentContract {
  public declare amountPaid: string;
  public declare paymentMethod: string;
  public declare paymentStatus: string;
  public declare invoiceStatus: string;
  public declare tourDayItemResourceId: number;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;

  constructor(data: TourDayItemResourcePaymentContract) {
    super(data);
  }
}
