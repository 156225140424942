import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import NProgress from 'nprogress';
import api from '@/api';
import VLayout from '@/components/layout/VLayout.vue';
import TheLogin from '@/views/auth/TheLogin.vue';
import TheAdminUsers from '@/views/admin/user/TheAdminUsers.vue';
import TheAdminAgencies from '@/views/admin/agency/TheAdminAgencies.vue';
import TheAdminCollaborators from '@/views/admin/collaborator/TheAdminCollaborator.vue';
import TheClients from '@/views/client/TheClients.vue';
import TheResources from '@/views/resource/TheResources.vue';
import TheSuppliers from '@/views/supplier/TheSuppliers.vue';
import TheCases from '@/views/case/TheCases.vue';
import TheCase from '@/views/case/TheCase.vue';
import TheCaseSetup from '@/views/case/parts/TheCaseSetup.vue';
import TheTourBuilder from '@/views/case/parts/TheTourBuilder.vue';
import TheTourReservations from '@/views/case/parts/TheTourReservations.vue';
import TheTourPricing from '@/views/case/parts/TheTourPricing.vue';
import TheTemplates from '@/views/template/TheTemplates.vue';
import TheTourTravelersForm from '@/views/TheTourTravelersForm.vue';
import TheTemplate from '@/views/template/TheTemplate.vue';
import TheTemplateSetup from '@/views/template/parts/TheTemplateSetup.vue';
import TheTemplateBuilder from '@/views/template/parts/TheTemplateBuilder.vue';
import ThePreviewPdf from '@/views/travel-book/ThePreviewPdf.vue';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import TheQuotePdf from '@/views/travel-book/TheQuotePdf.vue';
import TheReservationConfirm from '@/views/TheReservationConfirm.vue';
import TheCollaboratorDashboard from '@/views/TheCollaboratorDashboard.vue';
import TheEmailTemplate from "@/views/TheEmailTemplate.vue";
import TheEmailConfirmationVue from '@/views/TheEmailConfirmation.vue';

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/login',
    name: 'login',
    component: TheLogin,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/auth/social/:provider/confirm',
    name: 'auth.social.confirm',
    redirect: '/login',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/cases/:caseId/tour/:tourId/preview',
    name: 'tour.travel-book.preview',
    component: ThePreviewPdf,
    props: true,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/cases/:caseId/tour/:tourId/quote',
    name: 'tour.travel-book.quote',
    component: TheQuotePdf,
    props: true,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/reservation-confirm',
    name: 'reservation.confirm',
    component: TheReservationConfirm,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/travelers-form',
    name: 'travelers.form',
    component: TheTourTravelersForm,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/email-confirm',
    name: 'email.confirm',
    component: TheEmailTemplate,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/thankyou',
    name: 'thankyou',
    component: TheEmailConfirmationVue,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: VLayout,
    children: [
      {
        path: 'admin',
        name: 'admin',
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'users',
            name: 'user.index',
            component: TheAdminUsers,
            meta: {
              requiresAuth: true,
              permission: 'user.index',
            },
          },
          {
            path: 'agencies',
            name: 'agency.index',
            component: TheAdminAgencies,
            meta: {
              requiresAuth: true,
              permission: 'agency.index',
            },
          },
          {
            path: 'collaborators',
            name: 'collaborators',
            component: TheAdminCollaborators,
            meta: {
              requiresAuth: false,
              permission: 'agency.index',
            },
          },
        ],
      },
      {
        path: 'cases',
        name: 'case.index',
        component: TheCases,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'resources',
        name: 'resource.index',
        component: TheResources,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'clients',
        name: 'client.index',
        component: TheClients,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'suppliers',
        name: 'supplier.index',
        component: TheSuppliers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'cases',
        name: 'cases',
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ':caseId',
            name: 'case.show',
            component: TheCase,
            props: true,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: 'tour/:tourId',
                redirect: 'tour.default',
                name: 'tour.index',
                props: true,
                meta: {
                  requiresAuth: true,
                },
                children: [
                  {
                    path: '',
                    name: 'tour.default',
                    props: true,
                    meta: {
                      requiresAuth: true,
                    },
                    beforeEnter: (to, from, next) => {
                      next({
                        name: 'case.show',
                        params: {
                          caseId: to.params.caseId,
                          tourId: to.params.tourId,
                        },
                      });
                    },
                  },
                  {
                    path: 'setup',
                    name: 'case.setup.show',
                    component: TheCaseSetup,
                    props: true,
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: 'builder',
                    name: 'tour.builder.show',
                    component: TheTourBuilder,
                    props: true,
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: 'statuses',
                    name: 'tour.bookings.show',
                    component: TheTourReservations,
                    props: true,
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: 'pricing',
                    name: 'tour.pricing.show',
                    component: TheTourPricing,
                    props: true,
                    meta: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'activities',
        name: 'templates',
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'template.index',
            component: TheTemplates,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ':templateId',
            name: 'template.show',
            component: TheTemplate,
            props: true,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: 'setup',
                name: 'template.setup.show',
                component: TheTemplateSetup,
                props: true,
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: 'builder',
                name: 'template.builder.show',
                component: TheTemplateBuilder,
                props: true,
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
        ],
      },
      {
        path:'/dashboard',
        name: 'collaboratorDashboard',
        component: TheCollaboratorDashboard,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const history = createWebHistory();

const router = createRouter({
  history,
  routes,
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  if (to.name === 'home') {
    return next({ name: 'case.index' });
  }

  const isPublic = to.matched.some((record) => record.meta.isPublic);

  // When the user is guest
  if (isPublic) {
    return next();
  }

  let isLogged;

  try {
    isLogged = await api.auth.check();
  } catch (error) {
    isLogged = false;
  }

  const reqAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (!reqAuth && isLogged) {
    return next({ name: 'home' });
  } else if (reqAuth && !isLogged) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath },
    });
  }

  const { redirect } = to.query;

  // Redirect after login
  if (!!redirect && isLogged) {
    return next({ path: redirect });
  }

  // TODO apply permissions for route
  const appStore = useAppStore();
  const authStore = useAuthStore();

  // If we don't have permissions in store, get them
  if (isLogged && appStore.permissions.length === 0) {
    await authStore.getPermissions();
  }

  const { permission } = to.meta;

  // If the route doesn't have a permission, continue
  if (!permission) {
    return next();
  }

  const canPass = appStore.permissions.includes(permission);

  if (!canPass) {
    return next({ name: 'case.index' });
  }

   const isCollaboratorDashboard = to.name === 'collaboratorDashboard';

   // If the route is for the collaborator dashboard and the user is not a collaborator, redirect
   if (isCollaboratorDashboard && !authStore.agency.isCollaborator) {
     return next({ name: 'case.index' });
   }

  return next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
