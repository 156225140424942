<template>
  <div class="accordion" id="accordionPax">
    <div class="case-details accordion-item">
      <h2 id="headingPax" class="accordion-header">
        <button aria-controls="collapsePax" aria-expanded="true" class="accordion-button collapsed"
          data-bs-target="#collapsePax" data-bs-toggle="collapse" type="button">
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="group" size="xxl" space="p-12" />
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t('clientTour.pax') }}</div>
              <div class="fw-light accordion-subtitle">{{ $t('clientTour.paxSubtitle') }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div id="collapsePax" aria-labelledby="headingPax" class="accordion-collapse collapse show"
        data-bs-parent="#accordionPax">
        <div class="accordion-body pb-32 pb-xl-48">
          <div class="search-separator-container">
            <form id="case-update-pax-form" class="d-flex flex-column justify-content-between h-100 mb-16">
              <fieldset class="mb-24">
                <legend class="visually-hidden">{{ $t('tourClient.pax') }}</legend>

                <v-form-input id="case-update-pax" v-model="pax" :label="this.$t('general.shared.pax')"
                  :yup-errors-variable="errors.pax" autocomplete="off" form-type="outline" onwheel="return false;"
                  type="number" />
              </fieldset>

              <v-button :disabled="!meta.valid || disableButton || !meta.dirty" :is-loading="accepted"
                class="btn-icon w-fit align-self-end" size="sm" variant="primary" @click="updateClientTour">
                {{ $t('general.button.save') }}
                <v-icon icon="arrow-right" size="sm" space="ms-12" />
              </v-button>
            </form>

            <div class="separator-text-container">
              <span class="separator-text text-sm">{{ $t('clientTour.sendEmail') }}</span>
            </div>
          </div>

          <div class="search-separator-container">
            <p class="text-sm">{{ $t('clientTour.paxSendEmailText') }}</p>

            <div class="d-flex mb-16">
              <v-button :disabled="sendingEmail" :is-loading="sendingEmail" class="btn-icon w-fit" size="sm"
                variant="primary" @click="sendEmail">
                <template v-if="sendingEmail">{{ $t('general.button.sending') }}</template>
                <template v-else>{{ $t('general.button.sendEmail') }}</template>
                <v-icon icon="mail" size="sm" space="ms-12" />
              </v-button>
            </div>

            <div class="separator-text-container">
              <span class="separator-text text-sm">{{ $t('clientTour.travelers') }}</span>
            </div>
          </div>

          <div class="travelers-container">
            <div v-if="paxes.length === 0" class="text-sm">{{ $t('clientTour.noTravelers') }}</div>

            <template v-else>
              <div v-for="pax in paxes" :key="pax.id" class="traveler d-flex align-items-center">
                <div class="image-container bg-tertiary" v-if="!!pax.name">
                  {{ pax.name.charAt(0) }}
                </div>

                <div class="traveler-data h-100 w-100 d-flex justify-content-between gap-8 align-items-center" v-if="!!pax.name">

                  <div class="fw-medium">{{ pax.name }} {{ pax.surname1 }} {{ pax.surname2 }}
                    <span v-if="!!pax.age">({{ pax.age }} {{ $t('general.shared.yearsOld') }})</span></div>

                  <div class="text-sm fw-light text-gray-500 text-nowrap">
                    <span class="text-uppercase">{{ pax.documentType }}:</span> {{ pax.documentNumber }}
                  </div>

                  <div class="actions-container text-nowrap">
                    <v-button class="p-4" variant="icon" @click="openUpdatePaxModal(pax)">
                      <v-icon icon="pencil" />
                    </v-button>

                    <v-button class="p-4" variant="icon" @click="removePax(pax)">
                      <v-icon icon="trash" />
                    </v-button>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <the-pax-update-modal v-if="modalToShow === 'the-pax-update-modal'" :agency="agency" :client-tour="clientTour.id"
      :pax="currentPax" :tour="tour" @closed="closeModal" @reload-paxs="loadTourPaxes" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import ClientTour from '@/api/objects/ClientTour';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useFormValidation from '@/helpers/form';
import api from '@/api';
import Pax from '@/api/objects/Pax';
import useModalUtils from '@/helpers/ModalUtils';
import ThePaxUpdateModal from '@/components/case/parts/ThePaxUpdateModal.vue';

export default defineComponent({
  name: 'TheCaseSetupPax',
  components: {
    ThePaxUpdateModal,
    VIcon,
    VButton,
    VFormInput,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    tour: {
      type: Number,
      required: true,
    },
    clientTour: {
      type: ClientTour,
      required: true,
    },
    
  },
  emits: ['reloadClientTour'],
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      pax: yup.number()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.pax')),
    });

    const initialValues: Record<string, any> = {
      pax: props.clientTour.paxNumber,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: pax } = useField('pax');

    return {
      ...useModalUtils(),
      ...form,
      pax,
    };
  },
  data() {
    return {
      disableButton: false as boolean,
      paxes: [] as Pax[],
      sendingEmail: false,
      currentPax: null as Pax | null,
    };
  },
  async created() {
    await this.loadTourPaxes();
  },
  methods: {
    async loadTourPaxes() {
      try {
        const paxes: Pax[] = await api.pax.caseAll(this.agency,
          this.clientTour.id);

        this.paxes = paxes.filter((pax: Pax) => pax.tourId === this.tour);
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    openUpdatePaxModal(pax: Pax) {
      this.currentPax = pax;

      if (this.modalToShow !== 'the-pax-update-modal') {
        this.openModal('the-pax-update-modal');
      }
    },
    async removePax(pax: Pax) {
      const paxName = `${pax.name} ${!!pax.surname1 ? pax.surname1 : ''} ${!!pax.surname2 ? pax.surname2 : ''}`.trim();
      await this.$modal.delete({
        title: this.$t('clientTour.traveler.removeTitle'),
        text: this.$t('clientTour.traveler.removeText', { name: paxName }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doRemovePax(pax.id),
      });
    },
    async doRemovePax(paxId: number) {
      try {
        await api.pax.delete(this.agency,
          this.clientTour.id, this.tour, paxId);

        this.currentPax = null;

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.loadTourPaxes();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async sendEmail() {
      try {
        this.sendingEmail = true;

        await api.pax.sendEmail(this.agency,
          this.clientTour.id, {
          tour: this.tour,
        });

        this.$toast.success(this.$t('general.shared.savedChanges'));
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.sendingEmail = false;
      }
    },
    async updateClientTour() {
      try {
        this.toggleAccepted();
        this.disableButton = true;

        const data = {
          client_id: this.clientTour.clientId,
          init_date: this.clientTour.initDate,
          end_date: this.clientTour.endDate,
          pax_number: this.pax,
          type: 'single',
          complementary_text: this.clientTour.complementaryText,
          terms_conditions_text: this.clientTour.termsConditionsText,
        };

        await api.case.update(this.agency, this.clientTour.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadClientTour');

        this.disableButton = false;
        this.toggleAccepted();
      } catch (e: any) {
        this.disableButton = false;
        this.toggleAccepted();

        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.travelers-container .traveler {
  margin-bottom: .5rem;

  .image-container {
    border-bottom-left-radius: .25rem;
    border-top-left-radius: .25rem;
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.5rem;
    color: var(--uq-light);
  }

  .traveler-data {
    padding: .563rem 1.25rem .563rem 2rem;
    border: 1px solid var(--uq-gray-400);
    border-left: none;
    border-top-right-radius-radius: .25rem;
    border-top-right-radius-radius: .25rem;
  }
}
</style>
