<template>
  <v-off-canvas
    id="resource-create-offcanvas"
    :backdrop-static="true"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
        <h5 class="offcanvas-title">{{ $t("resource.createNewResource") }}</h5>
      </off-canvas-header>

      <off-canvas-body>
        <form id="resource-create-form" class="d-flex flex-column justify-content-between h-100">
          <div>
            <fieldset>
              <legend class="visually-hidden">{{ $t("resource.resourceSearch") }}</legend>

              <div class="row gx-12">
                <div class="mb-0 search-separator-container">
                  <div class="separator-text-container">
                    <span class="text-sm separator-text fw-semi">{{ $t("general.shared.writeCity") }}</span>
                  </div>
                </div>

                <v-input-address
                  id="city-search"
                  ref="city-search-ref"
                  :get-address="true"
                  :get-radius-bounds="true"
                  :label="`${$t('general.address.city')}*`"
                  :radius-bounds="20000"
                  class="mb-8 col-12"
                  @address="setCity($event, true)"
                  @input="checkCityValue"
                  @radius-bounds="setCityBounds"
                />
                <v-checkbox
                  id="is-stage"
                  v-model="isStage"
                  :label="`${$t('general.shared.isStage')}`"
                  :is-disabled="!searchRadius || !countryCode"
                  class="mt-8 text-nowrap"
                  @change="handleCheckboxChange('isStage')"
                />
                <v-checkbox
                  id="is-general"
                  v-model="isGeneral"
                  :label="`${$t('general.shared.isGeneral')}`"
                  :is-disabled="!searchRadius || !countryCode"
                  class="mt-8 text-nowrap"
                  @change="handleCheckboxChange('isGeneral')"
                />
                <template v-if="isStage">
                  <v-input-address
                    id="tour-day-item-resource-create-origin"
                    :get-address="true"
                    :get-radius-bounds="true"
                    :label="`${$t('general.shared.destination')}*`"
                    :radius-bounds="20000"
                    :value="destination"
                    label-class="text-sm"
                    @address="setLocation($event)"
                  />
                  <v-form id="resource-types-select" :label="$t('general.shared.types')" form-type="outline">
                    <v-select
                      v-model="types"
                      :options="travelTypes"
                      :reduce="(returnValue: any) => returnValue.key"
                      @option:selected="types"
                      @option:deselected="types"
                    >
                      <template v-slot:no-options>
                        <div class="my-4">{{ $t("general.vueSelect.noOptions") }}</div>
                      </template>
                    </v-select>
                    <v-form-input
                      :id="`template-day-update-length`"
                      v-model="stageLength"
                      :label="$t('general.shared.distanceKm')"
                      autocomplete="off"
                      class="mb-16"
                      form-type="outline"
                      onwheel="return false;"
                      type="number"
                    />
                  </v-form>
                </template>
                <template v-else-if="!isGeneral">
                  <div class="pb-24 search-separator-container">
                    <div class="separator-text-container">
                      <span class="text-sm separator-text fw-semi">{{ $t("general.shared.canSearchResource") }}</span>
                    </div>
                  </div>
                  <v-form-input
                    v-if="!searchRadius || !countryCode"
                    id="search-resource-title"
                    :label="$t('general.shared.resource')"
                    autocomplete="off"
                    disabled
                    form-type="outline"
                  />
                  <v-input-address
                    v-else
                    id="search-resource"
                    ref="search-resource-ref"
                    :form-floating="false"
                    :get-address="true"
                    :label="$t('general.shared.resource')"
                    :options="{
                      types: ['establishment'],
                      componentRestrictions: { country: countryCode },
                      bounds: searchRadius,
                      strictBounds: true,
                    }"
                    class="mb-20 col-12"
                    @address="setCity"
                    @selected-place="setPlace"
                  />
                </template>

                <div class="pb-24 mb-24 search-separator-container">
                  <div class="separator-text-container">
                    <span class="text-sm separator-text fw-semi">{{ $t("general.shared.writeItManually") }}</span>
                  </div>
                </div>
              </div>
            </fieldset>

            <div class="fieldset-translatable">
              <nav class="translations-tab">
                <div id="nav-tab" class="nav nav-tabs" role="tablist">
                  <v-button
                    v-for="(language, index) in languages"
                    :id="`nav-resource-create-basic-information-${language.key}`"
                    :key="index"
                    :aria-controls="`resource-create-basic-information-${language.key}`"
                    :class="{ active: language.key === currentLanguage }"
                    :data-bs-target="`#resource-create-basic-information-${language.key}`"
                    aria-selected="true"
                    class="px-16 py-8 nav-tab-link me-8"
                    data-bs-toggle="tab"
                    role="tab"
                    size="sm"
                    variant="outline-primary"
                    @click="currentLanguage = language.key"
                  >
                    {{ language.label }}
                  </v-button>
                </div>
              </nav>

              <transition mode="out-in" name="fade" appear>
                <div id="resource-create-basic-information-tabs" class="tab-content">
                  <div
                    v-for="(language, index) in languages"
                    :id="`resource-create-basic-information-${language.key}`"
                    :key="index"
                    :aria-labelledby="`nav-resource-create-basic-information-${language.key}`"
                    :class="{ 'active show': language.key === currentLanguage }"
                    class="resource-create-basic-information-tab tab-pane fade"
                    role="tabpanel"
                  >
                    <the-resource-create-basic-information-fieldset
                      v-model:description="translations[language.key].description"
                      v-model:title="translations[language.key].title"
                      v-model:types="types"
                      v-model:is-stage="isStage"
                      v-model:is-general="isGeneral"
                      v-model:city="city"
                      v-model:destination="destination"
                      :clear="clear"
                      :current-language="currentLanguage"
                      :disabled-fields="!searchRadius || !countryCode"
                      :is-information="false"
                      :language="language.shortName"
                      @types-checking="typesChecking()"
                      @media-uploaded="editorMedia.push($event)"
                      @is-valid="
                        isTranslationsValid.find((translation) => translation.locale === language.key).valid = $event
                      "
                    />
                  </div>
                </div>
              </transition>
            </div>

            <fieldset v-if="!isStage && !isGeneral">
              <legend class="visually-hidden">{{ $t("general.shared.basicInformation") }}</legend>
              <v-form-input
                id="resource-create-phone"
                v-model="phone"
                :disabled="!searchRadius || !countryCode"
                :label="$t('general.shared.phone')"
                :yup-errors-variable="errors.phone"
                autocomplete="off"
                form-type="outline"
              />
            </fieldset>

            <div class="pb-24 mb-8 search-separator-container">
              <div class="separator-text-container">
                <span class="text-sm separator-text">{{ $t("general.shared.links") }}</span>
              </div>
            </div>

            <fieldset v-if="!isStage && !isGeneral">
              <legend class="visually-hidden">{{ $t("general.shared.links") }}</legend>

              <v-form-input
                id="resource-create-map"
                v-model="mapUrl"
                :disabled="!searchRadius || !countryCode"
                :label="$t('general.shared.map')"
                :yup-errors-variable="errors.mapUrl"
                autocomplete="off"
                form-type="outline"
              />

              <v-form-input
                id="resource-create-website"
                v-model="website"
                :disabled="!searchRadius || !countryCode"
                :label="$t('general.shared.website')"
                :yup-errors-variable="errors.website"
                autocomplete="off"
                form-type="outline"
              />
            </fieldset>
            <fieldset v-else>
              <legend class="visually-hidden">{{ $t("general.shared.links") }}</legend>

              <div v-for="(input, index) in inputFields" :key="index">
                <v-form-input
                  :id="'resource-create-new-url-' + index"
                  v-model="input.link"
                  label="Link"
                  autocomplete="off"
                  form-type="outline"
                />
                <div class="w-100 d-flex justify-content-start" v-if="index === inputFields.length - 1">
                  <v-button
                    class="p-1 mt-8 btn-icon rounded-circle"
                    size="sm"
                    variant="gradient"
                    @click="addInputField"
                  >
                    <v-icon icon="plus" />
                  </v-button>
                </div>
              </div>
            </fieldset>

            <template v-if="!isStage && !isGeneral">
              <div class="pb-24 mb-8 search-separator-container">
                <div class="separator-text-container">
                  <span class="text-sm separator-text">{{ $t("general.address.address") }}</span>
                </div>
              </div>

              <fieldset>
                <legend class="visually-hidden">{{ $t("general.address.address") }}</legend>

                <div class="row gx-12">
                  <v-input-address
                    id="resource-create-address-search"
                    ref="resource-create-address-search-ref"
                    :disabled-input="!searchRadius || !countryCode"
                    :form-floating="false"
                    :get-address="true"
                    :label="$t('general.address.streetAddress')"
                    :value="address"
                    class="mb-20 col-12"
                    @address="setAddress"
                  />

                  <v-form-input
                    id="resource-create-second-address"
                    v-model="addressTwo"
                    :disabled="!searchRadius || !countryCode"
                    :label="$t('general.address.aptSuit')"
                    :yup-errors-variable="errors.addressTwo"
                    autocomplete="off"
                    class="mb-20 col-12"
                    form-type="outline"
                  />

                  <v-form-input
                    id="resource-create-country"
                    v-model="countryLabel"
                    :disabled="!searchRadius || !countryCode"
                    :label="$t('general.address.country')"
                    :yup-errors-variable="errors.countryLabel"
                    autocomplete="off"
                    class="mb-20 col-12"
                    form-type="outline"
                  />

                  <v-form-input
                    id="resource-create-postal-code"
                    v-model="postalCode"
                    :disabled="!searchRadius || !countryCode"
                    :label="$t('general.address.postalCode')"
                    :yup-errors-variable="errors.postalCode"
                    autocomplete="off"
                    class="mb-20 col-12"
                    form-type="outline"
                  />

                  <v-form-input
                    id="resource-create-city"
                    v-model="city"
                    :disabled="!searchRadius || !countryCode"
                    :label="$t('general.address.city')"
                    :yup-errors-variable="errors.city"
                    autocomplete="off"
                    class="mb-20 col-12"
                    form-type="outline"
                  />

                  <v-form-input
                    id="resource-create-region"
                    v-model="region"
                    :disabled="!searchRadius || !countryCode"
                    :label="$t('general.address.provinceStateRegion')"
                    :yup-errors-variable="errors.region"
                    autocomplete="off"
                    class="mb-20 col-12"
                    form-type="outline"
                  />
                </div>
              </fieldset>
            </template>

            <div class="search-separator-container">
              <div class="mb-16 separator-text-container" />
            </div>

            <fieldset>
              <legend class="visually-hidden">{{ $t("general.shared.media") }}</legend>

              <div class="mb-32 resource-media-container">
                <div class="mb-16 text-lg media-title fw-semi">{{ $t("general.shared.media") }}</div>

                <v-uploader
                  ref="uploader"
                  :after-upload="onChange"
                  :initial-value="photosUuid"
                  route-prefix="api/s3"
                  @removed="onChange"
                />

                <div class="mt-16 media-container">
                  <div class="row gx-8 gy-8">
                    <div v-for="media in photos" :key="media.uuid" class="col-4 col-lg-3">
                      <div class="media position-relative">
                        <v-picture>
                          <img :alt="media.preview_url" :src="media.preview_url" />
                        </v-picture>

                        <div class="top-0 media-actions position-absolute end-0">
                          <v-button
                            :disabled="disableRemoveButton"
                            class="btn-trash btn-primary fw-normal me-4"
                            variant="icon"
                            @click="removePhoto(media.uuid)"
                          >
                            <v-icon icon="trash" size="md" />
                          </v-button>

                          <v-button
                            :disabled="disableRemoveButton"
                            class="btn-trash btn-primary fw-normal"
                            variant="icon"
                            @click="showImage(media)"
                          >
                            <v-icon icon="search" size="md" />
                          </v-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <v-button
            :disabled="!meta.valid || accepted || !isTranslationsValid.every((translation) => translation.valid)"
            :is-loading="accepted"
            class="btn-submit-form btn-icon w-fit align-self-end"
            size="sm"
            variant="primary"
            @click="createResource"
          >
            {{ $t("general.button.create") }}
            <v-icon icon="arrow-right" size="sm" space="ms-12" />
          </v-button>
        </form>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>

  <v-show-media-modal
    v-if="modalToShow === 'v-show-media-modal' && !!currentMedia"
    :media="currentMedia"
    @closed="
      closeModal();
      currentMedia = null;
    "
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as yup from "yup";
import { useField } from "vee-validate";
import { VFormInput, VCheckbox, VForm } from "@uniqoders/form";
import vSelect from "vue-select";
import { useI18n } from "vue-i18n";
import { v4 as uuidv4 } from "uuid";
import VOffCanvas from "@/components/vendor/basic/offcanvas/VOffCanvas.vue";
import OffCanvasHeader from "@/components/vendor/basic/offcanvas/OffCanvasHeader.vue";
import OffCanvasBody from "@/components/vendor/basic/offcanvas/OffCanvasBody.vue";
import useFormValidation from "@/helpers/form";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import api from "@/api";
import useConcepts from "@/helpers/Concepts";
import { mapConcepts } from "@/helpers/ConceptHelper";
import VInputAddress from "@/components/vendor/basic/form/VInputAddress.vue";
import VUploader from "@/components/vendor/upload/VUploader.vue";
import Location from "@/api/objects/Location";
import TheResourceCreateBasicInformationFieldset from "@/components/resource/parts/TheResourceCreateBasicInformationFieldset.vue";
import VPicture from "@/components/vendor/basic/picture/VPicture.vue";
import Media from "@/api/objects/Media";
import useModalUtils from "@/helpers/ModalUtils";
import VShowMediaModal from "@/components/shared/VShowMediaModal.vue";
import ResizableContainer from "@/components/resizablecontainer/ResizableContainer.vue";

export default defineComponent({
  name: "TheResourceCreateOffcanvas",
  components: {
    VShowMediaModal,
    VPicture,
    TheResourceCreateBasicInformationFieldset,
    VUploader,
    VInputAddress,
    VIcon,
    VButton,
    VFormInput,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
    VCheckbox,
    vSelect,
    VForm,
  },
  emits: ["closed", "reloadResources", "openDetails"],
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      mapUrl: yup.string().nullable().label(t("general.shared.map")),
      address: yup.string().nullable().label(t("general.address.streetAddress")),
      addressTwo: yup.string().nullable().label(t("general.address.aptSuit")),
      city: yup.string().nullable().label(t("general.address.city")),
      countryLabel: yup.string().nullable().label(t("general.address.country")),
      region: yup.string().nullable().label(t("general.address.provinceStateRegion")),
      postalCode: yup.string().nullable().label(t("general.address.postalCode")),
      website: yup.string().nullable().label(t("general.shared.website")),
      phone: yup.string().nullable().label(t("general.shared.phone")),
    });

    const form = useFormValidation(rules);

    const { value: mapUrl } = useField("mapUrl");
    const { value: address } = useField("address");
    const { value: addressTwo } = useField("addressTwo");
    const { value: city } = useField("city");
    const { value: countryLabel } = useField("countryLabel");
    const { value: region } = useField("region");
    const { value: postalCode } = useField("postalCode");
    const { value: website } = useField("website");
    const { value: phone } = useField("phone");

    return {
      ...useModalUtils(),
      ...useConcepts(),
      ...form,
      mapUrl,
      address,
      addressTwo,
      city,
      countryLabel,
      region,
      postalCode,
      website,
      phone,
    };
  },
  data() {
    return {
      currentMedia: null as Media | null,
      searchRadius: null as Record<any, any> | null,
      lat: null as null | number,
      lng: null as null | number,
      countryCode: "" as string,
      media: [] as any,
      location: {} as Location,
      placeId: "" as string,
      types: [] as [],
      translations: {} as Record<string, any>,
      currentLanguage: "",
      clear: false,
      isTranslationsValid: [] as Record<string, any>[],
      photos: [] as Media[],
      photosUuid: {} as Record<string, any>,
      disableRemoveButton: false as boolean,
      editorMedia: [] as any[],
      isStage: false as boolean,
      isGeneral: false as boolean,
      destination: "" as string,
      dLat: null as null | number,
      dLng: null as null | number,
      destinationId: 0 as number,
      inputFields: [{ link: "" }] as { link: string }[],
      stageLength: null as null | number,
    };
  },
  computed: {
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : "en";
    },
    languages(): any {
      if (!!this.concepts && this.concepts["app.languages"]) {
        return mapConcepts(this.concepts["app.languages"]);
      }

      return [];
    },
    resourceTypes(): any {
      if (!!this.concepts && this.concepts["resource.types"]) {
        return mapConcepts(this.concepts["resource.types"]);
      }

      return [];
    },
    travelTypes(): any {
      if (!!this.concepts && this.concepts["tour.travel_type"]) {
        return mapConcepts(this.concepts["tour.travel_type"]);
      }

      return [];
    },
    mediaUuids() {
      return this.media.map((media: any) => media.attributes.uuid);
    },
    resourcetype() {
      if (this.isGeneral) {
        return "general_information";
      } else if (this.isStage) {
        return "stage_information";
      } else {
        return "point_of_interest";
      }
    },
  },
  watch: {
    countryCode(newValue, oldValue) {
      if (!!oldValue && newValue !== oldValue) {
        this.clearForm();
      }
    },
  },
  created() {
    this.currentLanguage = this.defaultLocale;

    this.mapTranslations();
  },
  methods: {
    typesChecking() {
      if (this.isStage) {
        const resultString = `${this.city ? this.city : ""}${this.city && this.destination ? "-" : ""}${
          this.destination ? this.destination : ""
        } ${this.types !== null && this.types.length > 0 ? `[${this.types}]` : ""}`;

        Object.keys(this.translations).forEach((language: string) => {
          this.translations[language].title = resultString;
        });
      }
    },
    addInputField() {
      this.inputFields.push({ link: "" });
    },
    getLinksArray() {
      const linksArray = this.inputFields.map((input) => input.link);

      if (linksArray.every((link) => link.trim() === "")) {
        return null;
      }

      return linksArray;
    },
    handleCheckboxChange(checkbox: string) {
      if (checkbox === "isStage" && this.isStage) {
        this.isGeneral = false;
        this.destination = "";
        this.dLat = null;
        this.dLng = null;
        this.destinationId = 0;
        this.types = [];
      } else if (checkbox === "isGeneral" && this.isGeneral) {
        this.isStage = false;
        this.destination = "";
        this.dLat = null;
        this.dLng = null;
        this.destinationId = 0;
        this.types = [];
      }
    },
    async setLocation(address: Record<string, any>) {
      const data = await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });
      this.destination = address.name;
      this.dLat = address.lat;
      this.dLng = address.lng;
      this.destinationId = data.id;

      if (this.isStage) {
        const resultString = `${this.city ? this.city : ""}${this.city && this.destination ? "-" : ""}${
          this.destination ? this.destination : ""
        } ${this.types !== null && this.types.length > 0 ? `[${this.types}]` : ""}`;

        Object.keys(this.translations).forEach((language: string) => {
          this.translations[language].title = resultString;
        });
      }
    },
    showImage(media: Media) {
      this.currentMedia = media;

      this.openModal("v-show-media-modal");
    },
    mapTranslations() {
      this.isTranslationsValid = [];

      this.languages.forEach((language: Record<string, any>) => {
        this.isTranslationsValid.push({
          locale: language.key,
          valid: false,
        });

        this.translations[language.key] = {
          title: "",
          description: "",
        };
      });
    },
    onChange() {
      const { uploader }: any = this.$refs;

      if (!!uploader) {
        this.media = uploader.mediaLibrary.state.media;
      }
    },
    async setCity(address: Record<string, any>, createLocation = false) {
      this.address = address.vicinity;
      this.countryLabel = address.country.longName;
      this.countryCode = address.country.shortName;
      this.city = address.locality.longName;
      this.region = address.regionProvince.longName;
      this.postalCode = address.postalCode.longName;

      if (this.isStage) {
        const resultString = `${this.city ? this.city : ""}${this.city && this.destination ? "-" : ""}${
          this.destination ? this.destination : ""
        } ${this.types !== null && this.types.length > 0 ? `[${this.types}]` : ""}`;

        Object.keys(this.translations).forEach((language: string) => {
          this.translations[language].title = resultString;
        });
      }

      if (createLocation) {
        await this.createLocation(address);
      }
    },
    setCityBounds(bounds: any) {
      this.searchRadius = bounds;
    },
    async setPlace(place: any) {
      if (!!this.placeId && this.placeId !== place.place_id) {
        await this.clearForm(true);
      }

      this.placeId = place.place_id;
      this.mapUrl = place.url;
      this.website = place.website;
      this.lat = place.geometry.location.lat().toString();
      this.lng = place.geometry.location.lng().toString();
      this.phone = place.international_phone_number;

      const types = place.types.filter((type: string) => type !== "establishment" && type !== "point_of_interest");

      this.types = types;

      Object.keys(this.translations).forEach((language: string) => {
        this.translations[language].title = place.name;
      });

      if (!!place.photos) {
        place.photos.forEach(async (photo: any) => {
          const uuid = uuidv4();

          const data = {
            url: photo.getUrl(),
            uuid,
          };

          const media = await api.media.upload("s3", data);

          this.photos.push(media);
        });
      }
    },
    removePhoto(photoUuid: string) {
      this.disableRemoveButton = true;

      this.photos = this.photos.filter((photo) => photo.uuid !== photoUuid);

      this.disableRemoveButton = false;
    },
    checkCityValue(value: string) {
      if (!value) {
        this.clear = !this.clear;
        this.clearForm(true);
        this.isStage = false;
        this.isGeneral = false;
        this.destination = "";
        this.dLat = null;
        this.dLng = null;
        this.destinationId = 0;
        this.types = [];
      }
    },
    async setAddress(address: any) {
      this.address = address.name;
      this.countryLabel = address.country.longName;
      this.city = address.locality.longName;
      this.region = address.regionProvince.longName;
      this.postalCode = address.postalCode.longName;
    },
    async createLocation(address: Record<string, any>) {
      this.location = await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });
    },
    clearForm(clearAll = false) {
      if (!!this.$refs["search-resource-ref"]) {
        (this.$refs["search-resource-ref"] as any).valueLocal = "";
      }

      this.resetForm();

      this.mapTranslations();

      if (clearAll) {
        this.countryCode = "";
        this.searchRadius = {};

        if (!!this.$refs["city-search-ref"]) {
          (this.$refs["city-search-ref"] as any).valueLocal = "";
        }

        this.media = [];
        this.photos = [];
        this.photosUuid = [];

        const { uploader }: any = this.$refs;

        if (!!uploader) {
          uploader.mediaLibrary.state.media = [];
        }
      }
    },
    async createResource() {
      try {
        this.toggleAccepted();

        const photosUuid = this.photos.map((media: any) => media.uuid);

        const media = photosUuid.concat(this.mediaUuids);

        const data = {
          types: this.isStage ? [this.types] : this.isGeneral ? null : this.types,
          map_url: this.mapUrl,
          images: media,
          address_1: this.address,
          address_2: this.addressTwo,
          postal_code: this.postalCode,
          country: this.countryLabel,
          city: this.city,
          state: this.region,
          lat: this.lat,
          lng: this.lng,
          location_id: this.location.id,
          provider_id: this.placeId,
          translations: this.translations,
          site_url: this.website,
          type: this.resourcetype,
          phone: this.phone,
          destination: this.destination,
          d_lat: this.dLat?.toString(),
          d_lng: this.dLng?.toString(),
          destination_id: this.destinationId,
          links: this.isStage || this.isGeneral ? this.getLinksArray() : null,
          stage_length: this.stageLength,
        };

        const response = await api.resource.create(data);

        if (!!this.editorMedia && this.editorMedia.length > 0) {
          const editorMedia = {
            media: this.editorMedia,
            model_type: "resource",
            collection: "tinymce",
            model_id: response.id,
          };

          await api.media.confirm("s3", editorMedia);
        }

        this.$toast.success(this.$t("general.shared.savedChanges"));

        this.clear = !this.clear;

        this.clearForm(true);

        this.$emit("openDetails", response);
        this.$emit("reloadResources");
        this.isStage = false;
        this.isGeneral = false;
        this.destination = "";
        this.dLat = null;
        this.dLng = null;
        this.destinationId = 0;
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
      }
    },
  },
});
</script>
