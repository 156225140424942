<template>
  <div v-if="activities && activities.length > 0">
    <Carousel :settings="getCarouselSettings()" :breakpoints="carouselBreakpoints"
      class="waw-activity-wrapper collaborator-activity-wrapper" :class="{ 'wawWrapperHover': wawCardHovered }">
      <Slide v-for="(item, index) in activities" :key="index">
        <TheActivityCard @card-hovered="cardHovered" :card="item" :index="index" />
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import TheActivityCard from './TheActivityCard.vue';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'TheCarousel',
  components: {
    Carousel,
    Slide,
    Navigation,
    TheActivityCard
  },
  props: {
    activities: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      wawCardHovered: false,
      carouselBreakpoints: {
        700: { itemsToShow: 2, snapAlign: 'start' },
        992: { itemsToShow: 3, snapAlign: 'start' },
        1200: { itemsToShow: 4, snapAlign: 'start' },
        1600: { itemsToShow: 4, snapAlign: 'start' }
      }
    };
  },
  methods: {
    cardHovered(value) {
      this.wawCardHovered = value;
    },
    getCarouselSettings() {
      const itemCount = this.activities.length;
      let itemsToShow = 4; // Default to 4 items

      // Determine the appropriate number of items to show based on the number of activities
      if (itemCount <= 2) {
        itemsToShow = 2;
      } else if (itemCount === 3) {
        itemsToShow = 3;
      } else if (itemCount === 4) {
        itemsToShow = 4;
      } else {
        // For itemCount > 4, maintain itemsToShow as 4
        itemsToShow = 4;
      }

      return {
        itemsToShow,
        snapAlign: 'center'
      };
    }
  }
});
</script>

<style lang="scss">
.wawWrapperHover {
  z-index: 2;
}

.collaborator-activity-wrapper {
  .carousel__viewport {
    padding-left: 10px;
  }
}
</style>
