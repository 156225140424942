export default {
  traveler: 'Reiziger',
  travelerData: 'Gegevens van de reiziger',
  withoutOrigin: 'Zonder oorsprong',
  withoutDestination: 'Zonder bestemming',
  withoutLocation: 'Zonder locatie',
  noStartDate: 'Geen startdatum',
  noEndDate: 'Geen einddatum',
  basicInformation: 'Basisinformatie',
  contactInformation: 'Contactgegevens',
  addressInformation: 'Adresgegevens',
  moreInformation: 'Meer informatie',
  haveAllergies: 'Heb je allergieën?',
  haveAllergiesText: 'Kunt u deze specificeren?',
  allergies: 'Allergieën',
  yes: 'Ja',
  no: 'Geen',
  spokenLanguage: 'Gesproken taal',
  followingDiet: 'Volg je een specifiek dieet?',
  vegetarian: 'Vegetarisch',
  vegan: 'Veganistisch',
  other: 'Andere',
  diet: 'Dieet',
  bikeText: 'Ga je op jouw manier fietsen?',
  bikeYesText: 'Als je de Camino op de fiets doet, geef ons dan je lengte, gewicht en hoofdomtrek door.',
  bike: 'Fiets',
  height: 'Hoogte',
  weight: 'Gewicht',
  bikeType: 'Type fiets',
  arrival: 'Aankomst',
  arrivalType: 'Type aankomst',
  arrivalNumber: 'Vlucht/trein/boot/busnummer',
  arrivalDate: 'Datum van aankomst',
  arrivalHour: 'Uur van aankomst',
  arrivalLocation: 'Uw station van aankomst',
  departure: 'Vertrek',
  departureType: 'Type vertrek',
  departureNumber: 'Vlucht/trein/boot/busnummer',
  departureDate: 'Datum van vertrek',
  departureHour: 'Uur van vertrek',
  departureLocation: 'Uw vertrekstation',
};
