<template>
  <div class="reservation-confirm-container">
    <main class="bg-tertiary-faded">
      <div class="container">
        <div class="pb-48 pt-sm-120 pt-lg-96 d-flex align-items-center">
          <div class="w-100 bg-light rounded-xs border-0 py-32 px-24 p-lg-56">
            <h1 class="h4 text-center">
              <p>{{ $t("general.pages.emailAlreadyUpdated") }}</p>
            </h1>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheEmailAlreadyUpdate",
});
</script>
