<template>
  <div>
    <draggable
      :list="list"
      class="tour-day-item-container"
      draggable=".item-container:not(.not-draggable)"
      item-key="id"
      v-bind="dragOptions"
      @change="onChanged"
    >
      <template #item="{ element }">
        <div
          :class="{
            'not-draggable ghost-item': element.disabled || updatingTour,
            'not-draggable blocked-item': element.isBlocked,
            'item-selected': element.id === currentTourDayItem?.id,
          }"
          class="item-container position-relative">
          <div class="tour-day-item-actions d-flex justify-content-center">
            <v-button
              :disabled="updatingTour"
              class="p-4"
              size="sm"
              variant="icon"
              @click="setCurrentItem(element)"
            >
              <v-icon icon="pencil"/>
            </v-button>

            <v-button
              :disabled="updatingTour"
              class="p-4"
              size="sm"
              variant="icon"
              @click="deleteItem(element, element.supplier)"
            >
              <v-icon icon="trash"/>
            </v-button>
          </div>

           <div class="d-flex justify-content-center">
              <div
                :class="{
                  'not-draggable ghost-hotel-item': element.disabled,
                  'blocked-item': element.isBlocked,
                }"
              class="tour-day-item d-flex align-items-center justify-content-center position-relative"
              >
                <v-icon
                  :icon="searchItem(element.type).icon"
                  class="text-xxl"/>
              </div>
           </div>

          <div class="supplier-name text-xs text-center fw-light mt-8" :title="element.supplier">
            <template v-if="element.type === 'stage_information'">
                {{ element?.resources[0]?.resource?.title }}
              </template>
              <template v-else-if="element.type === 'general_information'">
                {{ element?.resources[0]?.resource?.title }}
              </template>
              <template v-else>
                {{ element.supplier }}
              </template>
          </div>
        </div>
      </template>

      <template #footer>
        <v-button
          :disabled="updatingTour || !canAddItem"
          class="btn-add-item btn-icon p-4"
          size="sm"
          variant="outline-secondary"
          @click="openModal('the-template-day-item-create-modal')"
        >
          <v-icon icon="plus"/>
        </v-button>
      </template>
    </draggable>

    <the-template-day-item-create-modal
      v-if="modalToShow === 'the-template-day-item-create-modal'"
      :agency="agency"
      :template-id="templateId"
      :tour-day-id="templateDayId"
      @closed="closeModal"
      @emit-reload-tour="emitReloadTour"
      @open-details="setCurrentItem"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Vuedraggable from 'vuedraggable';
import { TYPE } from 'vue-toastification';
import { mapState } from 'pinia';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import useModalUtils from '@/helpers/ModalUtils';
import { useUserStore } from '@/stores/user';
import { findConceptByKey, mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import Concept from '@/api/objects/Concept';
import TheTemplateDayItemCreateModal from '@/components/template/TheTemplateDayItemCreateModal.vue';
import api from '@/api';

export default defineComponent({
  name: 'TheTemplateDayItemDraggable',
  components: {
    TheTemplateDayItemCreateModal,
    draggable: Vuedraggable,
    VIcon,
    VButton,
  },
  emits: [
    'emitReloadTour',
    'openItemDetailsOffCanvas',
    'moveItemToAnotherDay',
  ],
  props: {
    list: {
      type: Array as PropType<Record<string, unknown>[]>,
      required: false,
    },
    templateDayId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    templateId: {
      type: Number,
      required: true,
    },
    agency: {
      type: String,
      required: true,
    },
    updatingTour: {
      type: Boolean,
      required: false,
      default: false,
    },
    canAddItem: {
      type: Boolean,
      required: false,
      default: true,
    },
    tourMapped: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      ...useOffCanvasUtils(),
      ...useModalUtils(),
      ...useConcepts(),
      findConceptByKey,
    };
  },
  computed: {
    ...mapState(useUserStore, ['currentTourDayItem']),
    itemTypes(): any {
      if (!!this.concepts && this.concepts['supplier.type']) {
        return mapConcepts(this.concepts['supplier.type']);
      }

      return [];
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'tour-day-item',
        disabled: false,
        ghostClass: 'ghost',
        preventOnFilter: false,
      };
    },
  },
  methods: {
    stgeType(inputString: string) {
      const convertedString = inputString.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
      return convertedString;
    },
    onChanged({ moved, added }: any) {
      if (!!moved) {
        const templateDay = this.tourMapped.tourDays.find((tourDay: any) => tourDay.id === moved.element.tourDayId);

        if (!!templateDay) {
          const itemReplaced = templateDay.items[moved.newIndex];

          this.moveItemPosition(moved.element, itemReplaced.position);
        }
      }

      if (!!added) {
        const dayItemsWithNewItem = this.list?.filter(
          (item: any) => item.disabled || item.id !== added.element.id);

        if (!!dayItemsWithNewItem) {
          const itemReplaced = dayItemsWithNewItem[added.newIndex];

          if (!!itemReplaced) {
            const data = {
              element: added.element,
              newPosition: itemReplaced?.position,
            };

            this.$emit('moveItemToAnotherDay', data);
          } else {
            const lastElement: any = dayItemsWithNewItem.pop();

            if (!!lastElement) {
              const data = {
                element: added.element,
                newPosition: lastElement.position + 1,
              };

              this.$emit('moveItemToAnotherDay', data);
            } else {
              const data = {
                element: added.element,
                newPosition: 1,
              };

              this.$emit('moveItemToAnotherDay', data);
            }
          }
        }
      }
    },
    async moveItemPosition(item: Record<string, any>, newPosition: number) {
      try {
        const data = {
          position: newPosition,
        };

        await api.templateDayItem.movePosition(
          this.agency,
          this.templateId,
          this.templateDayId,
          item.id,
          data,
        );

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.emitReloadTour();
      } catch (e: any) {
        this.$toast.error(e.response.data.message);

        this.emitReloadTour();
      }
    },
    setCurrentItem(item: Record<string, any>) {
      const data = {
        agency: this.agency,
        templateId: this.templateId,
        templateDayId: item.tourDayId,
        templateDayItemId: item.id,
      };
      this.$emit('openItemDetailsOffCanvas', data);
    },
    searchItem(type: string) {
      return this.itemTypes.find((itemType: Concept) => itemType.key === type);
    },
    async deleteItem(templateDayItem: Record<string, any>, name: null | string = null) {
      await this.$modal.delete({
        title: this.$t('tourBuilder.deleteTourDayItemTitle'),
        text: this.$t('tourBuilder.deleteTourDayItemText', {
          dayItem: !!name ? name : this.findConceptByKey('supplier.type', templateDayItem.type).name,
        }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doDeleteItem(templateDayItem.id),
      });
    },
    async doDeleteItem(templateDayItem: number) {
      const loadingToast = this.$toast.loading(this.$t('general.button.deleting'), 'toast-remove-template-day-item');

      try {
        await api.templateDayItem.delete(
          this.agency,
          this.templateId,
          this.templateDayId,
          templateDayItem,
        );

        this.$emit('emitReloadTour');

        loadingToast.update('toast-remove-template-day-item', {
          content: this.$t('tourBuilder.dayItemDeleted'),
          options: {
            type: TYPE.SUCCESS,
            icon: 'icon icon-check-circle-fill',
            timeout: 5000,
          },
        });
      } catch (e: any) {
        loadingToast.update('toast-remove-template-day-item', {
          content: this.$t('tourBuilder.dayItemDeleteError'),
          options: {
            type: TYPE.ERROR,
            icon: 'icon icon-exclamation-triangle-fill',
            timeout: 5000,
          },
        });

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    emitReloadTour() {
      this.$emit('emitReloadTour');
    },
  },
});
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.supplier-name {
  width: 4.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.list-enter-from {
  opacity: 0;
  transform: scaleY(0.25);
}

.list-enter-active,
.list-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.list-move {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-active {
  position: absolute;
}

.list-group-item {
  display: block;
  padding: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  width: 100%;
}
.tour-day-item-container {
  padding-bottom: 65px;
}
</style>
