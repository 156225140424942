<template>
  <v-form-input
    :id="`tour-invoice-received-${file.id}-update-name`"
    v-model="name"
    :title="name"
    autocomplete="off"
    class="invoice-name-input w-100"
    form-type="outline"
    @focusout="show = false"
    @focusin="show = true"
  >
    <template #rightAddon>
      <v-button
        :class="{ 'opacity-0': !show }"
        :disabled="disableButton"
        class="btn-show-details shadow-none p-4 h-fit-content btn-primary ms-8"
        variant="icon"
        @click="updateMediaName(file)"
      >
        <v-icon icon="check"/>
      </v-button>
    </template>
  </v-form-input>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import Media from '@/api/objects/Media';
import { useUserStore } from '@/stores/user';
import useFormValidation from '@/helpers/form';

export default defineComponent({
  name: 'TheCaseSetupInvoicesUpdateName',
  emits: ['reloadTour'],
  components: {
    VIcon,
    VButton,
    VFormInput,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    tour: {
      type: Number,
      required: true,
    },
    clientTour: {
      type: Number,
      required: true,
    },
    file: {
      type: Media,
      required: true,
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const { t } = useI18n();

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('general.shared.name')),
    });

    const initialValues = {
      name: props.file.name.replace(/\.[^/.]+$/, ''),
    };

    const form = useFormValidation(rules, initialValues);

    const { value: name } = useField('name');

    return {
      userStore,
      ...form,
      name,
    };
  },
  data() {
    return {
      show: false as boolean,
      media: [] as any,
      disableButton: false as boolean,
      invoices: [] as Media[],
      invoicesLoaded: false,
    };
  },
  methods: {
    async updateMediaName(media: Media) {
      try {
        this.disableButton = true;
        await api.media.update('drive', media.id, {
          name: this.name,
        });

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadTour');

        this.show = false;
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableButton = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.invoice-name-input {
  display: flex;
  align-items: center;

  :deep(.form-control) {
    font-size: .875rem;
    padding: .75rem;
  }
}
</style>
