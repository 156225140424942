export default {
  contact: 'Contact',
  contacts: 'Contacts',
  noContacts: 'There are no contacts',
  createNewContact: 'Create new contact',
  archivedContacts: 'Archived contacts',
  activeContacts: 'Active contacts',
  basicInformation: 'Basic information',
  contactDetails: 'Contact details',
  archiveText: 'You can archive a contact, if you do this the contact will not appear in the application searches. You will be able to see it in the list of contacts if you filter by \'archived\'. Once you view the details of an archived contact, you can delete it definitively or you can unarchive it.',
  media: 'Media',
  noMedia: 'There are no associated media',
  archivedText: 'This contact is archived, if you want you can unarchive it by clicking on the \'Restore\' button.',
  archiveContactTitle: 'Archive contact',
  archiveContactText: 'Are you sure you want to archive the contact \'{contact}\'? If you archive it, it will not appear in searches.',
  deleteText: 'This contact is archived, if you want to delete it permanently click the \'Delete\' button. Note that if you delete it, this contact will no longer exist and cannot be recovered.',
  deleteContactTitle: 'Delete contact',
  deleteContactText: 'Are you sure you want to delete the contact \'{contact}\'? If you delete it, you will not be able to recover it.',
  restoreContactTitle: 'Recover contact',
  restoreContactText: 'Are you sure you want to recover the contact \'{contact}\'? If you recover it, it will no longer be archived and will appear in the application searches.',
  contactsStatus: 'Contacts Status',
};
