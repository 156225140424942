<!-- eslint-disable max-len -->
<template>
  <div id="collaborator-dashboard container">
    <div v-if="!loaded">
      <v-loader />
    </div>
    <div v-else>
      <div class="dashboard-cards-container mt-48">
        <div class="container">
          <div class="h4 ff-secondary mb-0 d-flex align-items-center mb-28">
            <div class="bg-secondary rounded-xs me-8">
              <span class="icon-compass text-xxl p-12 icon"></span>
            </div>
            {{ $t('dashboard.dashboard') }}
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-4 h-20" v-for="(card, index) in cases" :key="index">
              <div class="card pe-16 py-48 border border-1 rounded mb-20 mb-md-0 mb-lg-0 mb-xl-0"
                @mouseover="setHoveredIndex(index)" @mouseleave="resetHoveredIndex()"
                :class="{ '': hoveredIndex === index }" @click="routeToCase(card.title)">
                <p class="text-center mb-0">{{ card.title }}</p>
                <h4 class="h4 header-title text-center pt-20" :class="{ 'bg-white': index === 0 }">{{ card.text }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-activities-container mt-48">
        <div class="container mt-12">
          <div class="h4 ff-secondary mb-0 d-flex align-items-center">
            <div class="bg-secondary rounded-xs me-8">
              <span class="icon-compass text-xxl p-12 icon"></span>
            </div>
            {{ $t('admin.agency.template.title') }}
          </div>

      <div class="mt-24" v-if="templates.length === 0">
        <p class="text-left">{{ $t('admin.agency.template.noTemplates') }}</p>
      </div>
      <div class="container">
        <the-carousel :activities="templates" />
      </div>
      <div class="d-flex justify-content-end"
      style="margin-top:-150px"
      @click="routeActivities()"
       v-if="templates.length >= 3">
        <v-button
            class="btn-icon w-fit align-self-end"
            size="sm"
            variant="primary"
            @click="routeActivities()"
            style="z-index:1"
           >
            View all
            <v-icon icon="arrow-right" size="sm" space="ms-12"/>
          </v-button>
      </div>
    </div>
  </div>
</div>
      </div>
</template>

<script lang="ts">
import api from '@/api';
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useAuthStore } from '../stores/auth';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import querystring from 'query-string';
import Template from '../api/objects/Template';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import TheCarousel from '@/components/dashboard/TheCarousel.vue';

export default defineComponent({
  name: 'TheCollaboratorDashboard',
  components: {
    VLoader,
    VButton,
    VIcon,
    TheCarousel
  },
  setup() {
    return {

    };
  },
  data() {
    return {
      loaded: false as boolean,
      hoveredIndex: -1 as number,
      cases: [
        { title: this.$t('general.shared.totalCases'), text: '-' },
        { title: this.$t('general.shared.completedCases'), text: '-' },
        { title: this.$t('general.shared.pendingCases'), text: '-' },
      ] as { title: string; text: string }[],

      templates: [] as Template[],
      status: 'active' as string,
      name: '' as string,
      destination: '' as string,
      location: '' as string,
      origin: '' as string,
      travelType: '' as string,
      order: {
        key: '' as string,
        order: '' as string,
      } as Record<string, string>,
      perPage: 15 as number,
      page: 1 as number,
      timers: {
        name: null as any,
      },
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
  },
  async created() {
    if (!!this.agency?.isCollaborator) {
       await this.init();
      } else {
        return this.$router.push({
          name: 'case.index'
        })
      }
  },
  watch: {
    async agency() {
      if (!!this.agency?.isCollaborator) {
        return await this.init()
      }
    },
  },
  methods: {
    async init() {
      this.loaded = false;
      try {
        await this.doSearch();
        const response = await api.dashboardCases.getCasesCount(this.agency?.slug);
        const { data } = response;

        this.cases[0].text = data.total_cases !== undefined ? data.total_cases.toString() : '-';
        this.cases[1].text = data.completed_cases !== undefined ? data.completed_cases.toString() : '-';
        this.cases[2].text = data.pending_cases !== undefined ? data.pending_cases.toString() : '-';
      } catch (error) {
        console.error('Error fetching cases count:', error);
      }
      finally {
        this.loaded = true;
      }

    },
    capitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    setHoveredIndex(index: number) {
      this.hoveredIndex = index;
    },
    resetHoveredIndex() {
      this.hoveredIndex = -1;
    },
    routeToCase(title: string) {
      let query: string;

      if (title === this.$t('general.shared.pendingCases')) {
        query = "?filters[payments_status]=pending&filters[status]=active&perPage=15&status=active";
      } else if (title === this.$t('general.shared.completedCases')) { // Use else if for subsequent conditions
        query = "?filters[payments_status]=complete&filters[status]=active&perPage=15&status=active";
      } else {
        query = '';
      }
      query = querystring.parse(query);

      this.$router.push({
        name: 'case.index',
        query,
      });
    },
    routeActivity(templateId: number) {
      this.$router.push({
        name: 'template.show',
        params: {
          templateId: templateId,
        },
      })
    },
    async doSearch() {
      await this.loadActivities();
    },
    async loadActivities(page?: number) {
      try {
        this.loaded = false;
        const query: any = {
          order: this.order,
          perPage: this.perPage,
          status: this.status,
          page: !!page ? page : this.page,
        };

        if (!!this.agency) {
          // const response = await api.template.allPaginated(this.agency.slug, query);
          // this.templates = response.data;
          const response = await api.template.allPaginated(this.agency.slug, query);
          const slicedData = response.data;
          this.templates = slicedData;
        }
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.loaded = true;
      }
    },
    routeActivities() {
      this.$router.push({
        name: 'template.index'
      })
    }

  },
});
</script>

<style lang="scss" scoped>
.card {
  transition: box-shadow 0.1s ease-in-out;
  cursor: pointer;
}
</style>
