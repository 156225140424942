<template>
  <v-off-canvas
    id="template-filters-offcanvas"
    :backdrop-static="false"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <h5 class="offcanvas-title">{{ $t('general.shared.filters') }}</h5>
      </off-canvas-header>

      <off-canvas-body>
      <v-form
        id="template-filter-status-select"
        :label="this.$t('general.shared.status')"
        form-type="outline"
      >
        <v-select
          v-model="filterStatus"
          :clearable="false"
          :options="statusTypesOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:status', filterStatus); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-form-input
        id="template-filter-origin-select"
        v-model="filterOrigin"
        :label="this.$t('general.shared.origin')"
        autocomplete="off"
        form-type="outline"
        @update:modelValue="$emit('update:origin', filterOrigin); $emit('selected')"
      />

      <v-form-input
        id="template-filter-destination-select"
        v-model="filterDestination"
        :label="this.$t('general.shared.destination')"
        autocomplete="off"
        form-type="outline"
        @update:modelValue="$emit('update:destination', filterDestination); $emit('selected')"
      />

      <v-form-input
        id="template-filter-location-select"
        v-model="filterLocation"
        :label="this.$t('general.shared.location')"
        autocomplete="off"
        form-type="outline"
        @update:modelValue="$emit('update:location', filterLocation); $emit('selected')"
      />

      <v-form
        id="template-filter-travel-type-select"
        :label="this.$t('general.shared.travelType')"
        form-type="outline"
      >
        <v-select
          v-model="filterTravelType"
          :clearable="false"
          :options="travelTypeOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:travelType', filterTravelType); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import { VForm, VFormInput } from '@uniqoders/form';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useConcepts from '@/helpers/Concepts';
import { mapConcepts } from '@/helpers/ConceptHelper';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue'


export default defineComponent({
  name: 'TheTemplateFiltersOffcanvas',
  components: {
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    vSelect,
    VForm,
    VFormInput,
    ResizableContainer
  },
  emits: [
    'closed',
    'selected',
    'update:destination',
    'update:location',
    'update:origin',
    'update:status',
    'update:travelType',
  ],
  props: {
    destination: {
      type: String,
      required: false,
      default: '',
    },
    location: {
      type: String,
      required: false,
      default: '',
    },
    origin: {
      type: String,
      required: false,
      default: '',
    },
    status: {
      type: String,
      required: true,
      default: 'active',
    },
    travelType: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    return {
      ...useConcepts(),
    };
  },
  data() {
    return {
      filterDestination: this.destination,
      filterLocation: this.location,
      filterOrigin: this.origin,
      filterStatus: this.status,
      filterTravelType: this.travelType,
    };
  },
  computed: {
    statusTypesOptions(): any {
      if (!!this.concepts && this.concepts['app.states']) {
        return mapConcepts(this.concepts['app.states']);
      }

      return [];
    },
    travelTypeOptions(): any {
      if (!!this.concepts && this.concepts['tour.travel_type']) {
        const concepts = mapConcepts(this.concepts['tour.travel_type']);

        concepts.unshift({
          key: '',
          label: this.$t('general.shared.all'),
          permission: null,
          value: null,
          order: 0,
          status: 'active',
          icon: '',
          color: '',
        });

        return concepts;
      }

      return [];
    },
  },
  watch: {
    destination(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterDestination = this.destination;
      }
    },
    location(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterLocation = this.location;
      }
    },
    origin(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterOrigin = this.origin;
      }
    },
    status(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterStatus = this.status;
      }
    },
    travelType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterTravelType = this.travelType;
      }
    },
  },
});
</script>
