<template>
  <v-off-canvas
    id="template-duplicate-off-canvas"
    :backdrop-static="false"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <h5 class="offcanvas-title">{{ $t('tourClient.duplicateTour') }}</h5>
      </off-canvas-header>

      <off-canvas-body>
      <form
        v-if="!!agency && !!agency.slug"
        id="case-create-form"
        class="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <fieldset>
            <legend class="visually-hidden">{{ $t('clientTour.basicInformation') }}</legend>
            <v-select-client-search
              id="case-create-client-search"
              v-model="localClientId"
              :agency="agency.slug"
              :client-id="localClientId"
            />
          </fieldset>
        </div>

        <v-button
          :disabled="!meta.valid || disableButton"
          :is-loading="accepted"
          class="btn-icon w-fit align-self-end mt-24"
          size="sm"
          variant="primary"
          @click="duplicateTour"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </form>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { mapState } from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { useAuthStore } from '@/stores/auth';
import VSelectClientSearch from '@/components/vendor/selectSearch/VSelectClientSearch.vue';
import useConcepts from '@/helpers/Concepts';
import api from '@/api';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheCaseDuplicateOffCanvas',
  components: {
    VSelectClientSearch,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
  },
  emits: [
    'closed',
  ],
  props: {
    caseId: {
      type: String,
      required: true,
    },
    tourId: {
      type: String,
      required: false,
    },
    clientId: {
      type: Number,
      required: false,
      default: null,
    },
    clientName: {
      type: String,
      required: false,
      default: '',
    },
    
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      localClientId: yup.number()
        .nullable()
        .label(t('general.shared.client')),
    });

    const initialValues: Record<string, any> = {
      localClientId: !!props.clientId ? props.clientId : null,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: localClientId } = useField('localClientId');

    return {
      ...useConcepts(),
      ...form,
      localClientId,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
  },
  data() {
    return {
      disableButton: false as boolean,
    };
  },
  methods: {
    async duplicateTour() {
      if (!!this.agency) {
        try {
          const response = await api.tour.duplicate(
            this.agency.slug,
            Number(this.caseId),
            Number(this.tourId),
            Number(this.localClientId),
          );

          this.$toast.success(this.$t('tourClient.duplicatedTourSuccess'));

          this.$toast.success(this.$t('tourClient.redirectingNewTour', { timeout: 10000 }));

          await this.$router.push({
            name: 'case.setup.show',
            params: { caseId: response.clientTourId, tourId: response.id },
          });

          this.$emit('closed');
        } catch (e: any) {
          console.error(e);

          this.$toast.error(e.response.data.message);
        }
      }
    },
  },
});
</script>
