import { AbstractService } from '@uniqoders/sdk';
import Tour from '@/api/objects/Tour';

export default class TourService extends AbstractService {
  /**
   * Retrieves all Tours
   * @protected
   * @param agency
   * @param clientTour
   * @param params
   */
  public all(agency: string, clientTour: string | number, params?: any): Promise<Tour[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tours', { agency, clientTour }),
      params,
    });
  }

  /**
   * Retrieves a specific Tour
   * @param agency
   * @param clientTour
   * @param tour
   * @param params
   * @protected
   */
  public retrieve(agency: string, clientTour: string | number, tour: number, params: any): Promise<Tour> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}', { agency, clientTour, tour }),
      params,
    });
  }

  /**
   * Creates a single Tour
   * @protected
   * @param agency
   * @param clientTour
   * @param data
   */
  public create(agency: string, clientTour: string | number, data: Record<string, any>): Promise<Tour> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour', { agency, clientTour }),
      data,
    });
  }

  /**
   * Updates the specified Tour
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   * @protected
   */
  public update(
    agency: string,
    clientTour: string | number,
    tour: string | number,
    data: Record<string, any>): Promise<Tour> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}', { agency, clientTour, tour }),
      data,
    });
  }

  /**
   * Archives the specified Tour
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   */
  public archive(agency: string, clientTour: string | number, tour: string | number): Promise<Tour> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}', { agency, clientTour, tour }),
    });
  }

  /**
   * Restore the specified Tour
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   */
  public restore(agency: string, clientTour: string | number, tour: number): Promise<Tour> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/restore', { agency, clientTour, tour }),
    });
  }

  /**
   * Deletes the specified Tour
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   */
  public delete(agency: string, clientTour: string | number, tour: number): Promise<Tour> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/force', { agency, clientTour, tour }),
    });
  }

  /**
   * Duplicates a Tour
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param client
   */
  public duplicate(agency: string, clientTour: string | number, tour: number, client: number): Promise<Tour> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/duplicate', { agency, clientTour, tour }),
      data: { client },
    });
  }
}
