<template>
  <div class="offcanvas-body">
    <slot/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OffCanvasBody',
});
</script>
<style scoped>
 .offcanvas .offcanvas-body{
    margin-right: 0rem !important;
  }
  @media (max-width: 991px) {
    .offcanvas .offcanvas-body{
      padding-bottom: 6rem;
      padding-right: 6px;
    }
  }
  @media (min-width: 992px) {
      .offcanvas .offcanvas-body{
          margin-right: 0rem !important;
      }
  }
</style>
