export default {
  cases: 'Gevallen',
  noCases: 'Er zijn geen gevallen',
  createNewCase: 'Maak een nieuw geval aan',
  createNewProduct: 'Nieuwe activiteit maken',
  activityCreatedFor: 'Activiteit gemaakt voor',
  archivedCases: 'Gearchiveerde gevallen',
  activeCases: 'Actieve gevallen',
  basicInformation: 'Basisinformatie',
  details: 'Details',
  caseDetails: 'Geval details',
  detailsSubtitle: 'Algemene gevalinformatie bevindt zich hier',
  media: 'Media',
  noMedia: 'Er zijn geen bijbehorende media',
  archivedText: 'Dit geval is gearchiveerd, als je wilt, kun je het dearchiveren door op de knop \'Herstellen\' te klikken.',
  archiveTitle: 'Archiveer geval',
  archiveSubtitle: 'Hier kun je het geval archiveren',
  archiveText: 'Je kunt een geval archiveren, als je dit doet, zal het geval niet verschijnen in de applicatie zoekopdrachten. Je kunt het zien in de lijst met gevallen als je filtert op \'gearchiveerd\'. Zodra je de details van een gearchiveerd geval bekijkt, kun je het definitief verwijderen of je kunt het dearchiveren.',
  archiveAlertText: 'Weet je zeker dat je het geval \'{case}\' wilt archiveren? Als je het archiveert, zal het niet meer in de zoekopdrachten verschijnen.',
  deleteText: 'Dit geval is gearchiveerd, als je het permanent wilt verwijderen, klik dan op de knop \'Verwijderen\'. Houd er rekening mee dat als je het verwijdert, dit geval niet meer bestaat en niet kan worden hersteld.',
  deleteClientTourTitle: 'Verwijder geval',
  deleteClientTourText: 'Weet je zeker dat je het geval \'{clientTour}\' wilt verwijderen? Als je het verwijdert, kun je het niet meer herstellen.',
  restoreClientTourTitle: 'Herstel geval',
  restoreClientTourText: 'Weet je zeker dat je het geval \'{clientTour}\' wilt herstellen? Als je het herstelt, zal het niet meer gearchiveerd zijn en zal het verschijnen in de applicatie zoekopdrachten.',
  caseStatus: 'Geval status',
  client: 'Klant',
  createClient: 'Maak een nieuwe klant aan',
  createSupplier: 'Maak een nieuwe leverancier aan',
  createResource: 'Maak een nieuw geval aan',
  products: 'Activiteiten',
  templateNoOptions: 'Geen activiteiten beschikbaar',
  locationNoOptions: 'Er is geen locatie in deze activiteit',
  chooseTemplate: 'Kies een activiteit',
  orStartScratch: 'of begin vanaf nul',
  howToDoIt: 'Hoe doe je het',
  extraDaysAtStart: 'Extra dagen aan het begin',
  extraDaysAtEnd: 'Extra dagen aan het einde',
  participantsDetails: 'Gegevens deelnemers',
  rooming: 'Kamer',
  howWillYourPath: 'Hoe zal jouw route zijn',
  extraDays: 'Extra dagen',
  pax: 'Passagiers',
  paxSubtitle: 'Passagiers van de zaak',
  choosingProductOptional: 'Het kiezen van een activiteit is optioneel, je kunt de casus helemaal opnieuw maken.',
  caseType: 'Zaak type',
  travelType: 'Reistype',
  paxSendEmailText: 'Stuur een e-mail naar de klant met een link zodat alle reizigers een formulier kunnen invullen met hun gegevens.',
  sendEmail: 'Stuur e-mail',
  tourBasicInformation: 'Tour basisinformatie',
  travelers: 'Reizigers',
  noTravelers: 'Er zijn geen reizigers toegevoegd',
  traveler: {
    update: 'Reiziger bijwerken',
    removeTitle: 'Reiziger verwijderen',
    removeText: 'Weet je zeker dat je \'{name}\' als passagier op deze tour wilt verwijderen?',
  },
  invoicesReceived: 'Ontvangen facturen',
  invoicesReceivedSubtitle: 'Ontvangen facturen van de zaak',
  invoicesEmitted: 'Uitgegeven facturen',
  invoicesEmittedSubtitle: 'Uitgegeven facturen van de zaak',
  invoice: {
    noInvoicesReceived: 'Er zijn geen ontvangen facturen',
    noInvoicesEmitted: 'Er zijn geen uitgegeven facturen',
    removeTitle: 'Factuurbestand verwijderen',
    removeText: 'Weet je zeker dat je de factuur \'{name}\' van deze tour wilt verwijderen?',
    number: 'Nummer',
    amount: 'Bedrag',
    basicInformation: 'Basisinformatie',
    invoiceDetails: 'Factuurgegevens',
    invoiceLines: 'Factuurregels',
    quantity: 'Hoeveelheid',
    nif: 'NIF',
    vat: 'BTW-nummer',
    deleteTitle: 'Factuur verwijderen',
    deleteText: 'Weet je zeker dat je de factuur \'{invoice}\' wilt verwijderen? Als je dit doet, kun je het niet meer herstellen.',
  },
  tourStatuses: 'Statussen van de zaak',
  statuses: 'Statussen',
  reservations: 'Reserveringen',
  payments: 'Betalingen',
  invoices: 'Facturen',
  contact: {
    contacts: 'Contacten',
    contactsSubtitle: 'Hier kunt u de contactinformatie voor de case preview en offerte toevoegen',
    contactInformation: 'Contactinformatie',
  },
  dateModal: {
    title: 'Weet je zeker dat je de begindatum wilt wijzigen?',
    text: 'Deze instelling zal de data van het hele reisschema wijzigen. Controleer of er geen boekingen zijn aangevraagd of bevestigd, want die worden ook beïnvloed door deze wijziging.',
  },
  sendNotification: 'Kennisgeving verzenden',
  notification: 'Melding',
  noResourcesSelectedMessage: 'Selecteer bronnen om de meldingen te verzenden',
  sendNotificationSubtitle: 'E-mail verzenden naar de klant',
  sendClientAndPaxs: 'Kennisgeving sturen naar klant en passagiers',
  sendOnlyClient: 'Waarschuwing alleen naar de klant sturen',
  termsConditions: 'Voorwaarden & bepalingen',
  termsSubtitle: 'Hier kun je de algemene voorwaarden van de zaak toevoegen.',
  complementaryText: 'Extra\'s',
  complementarySubtitle: 'Hier kunt u aanvullende en verklarende details voor het geval toevoegen.',
  afterTour: 'Na je thuiskomst',
  afterTourSubtitle: 'Hier kunt u aanvullende en verklarende details voor het geval toevoegen.',
  beforeTour: 'Voor je vertrek',
  beforeTourSubtitle: 'Hier kunt u aanvullende en verklarende details voor het geval toevoegen.',
  quotes: 'Quotes',
  quoteOptionalText: 'Optionele bronnen toevoegen aan de offerte',
  quotePriceText: 'Toon in de offerte de totaalprijs of de prijs per persoon.',
  quoteTotalPrice: 'Totale prijs',
  quotePricePerPerson: 'Prijs per persoon',
  optionals: 'Opties',
  noQuotes: 'Er zijn geen offertes',
  featuredImage: 'Uitgelichte afbeelding',
  notificationLog: 'Logboek voor leveranciersmeldingen',
  clientNotificationLog: 'Logboek voor klantmeldingen',
  notificationLogSubtitle: 'Hier is de geschiedenis van leveranciersmeldingen.',
  noClientNotifications: 'Geen meldingen aan klanten',
  noSuppliersNotifications: 'Geen kennisgevingen aan leveranciers',
  paxsInformation: 'Informatie voor passagiers',
  tourName: 'Naam Tour',
  emailModal: {
    title: 'Stuur e-mail',
    text: 'Weet je zeker dat je de e-mail naar de klant wilt sturen?',
  },
  additionalInfo: 'Extra info',
  additionalRequirements: 'Extra voorwaarden',
  otherRoomRequirements: 'Andere kamer voorwaarden',
};
