<template>
  <v-off-canvas
    id="supplier-create-offcanvas"
    :backdrop-static="true"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
   <resizable-container>
      <off-canvas-header>
      <h5 class="offcanvas-name">{{ $t('supplier.createNewSupplier') }}</h5>
      </off-canvas-header>

      <off-canvas-body>
      <div class="d-flex flex-column justify-content-between h-100">
        <form id="supplier-create-form">
          <fieldset>
            <legend class="visually-hidden">{{ $t('supplier.supplierSearch') }}</legend>

            <div class="row gx-12">
              <div class="search-separator-container mb-0">
                <div class="separator-text-container">
                  <span class="separator-text fw-semi text-sm">{{ $t('general.shared.writeCity') }}</span>
                </div>
              </div>

              <v-input-address
                id="supplier-create-city-search"
                ref="supplier-create-city-search-ref"
                :get-address="true"
                :get-radius-bounds-with-address="true"
                :label="`${$t('general.address.city')}*`"
                :options="{
                  types: ['(cities)'],
                  fields: ['place_id', 'name', 'vicinity', 'formatted_address', 'geometry', 'address_component'],
                }"
                :radius-bounds="20000"
                class="col-12"
                @address="setCity($event, true)"
                @input="checkCityValue"
              />

              <div class="search-separator-container pb-24">
                <div class="separator-text-container">
                  <span class="separator-text fw-semi text-sm">{{ $t('general.shared.canSearchSupplier') }}</span>
                </div>
              </div>

              <v-form-input
                v-if="!searchRadius || !countryCode"
                id="search-supplier-title"
                :label="$t('supplier.supplierSearch')"
                autocomplete="off"
                disabled
                form-type="outline"
              />

              <v-input-address
                v-else
                id="supplier-create-supplier-search"
                ref="supplier-create-supplier-search-ref"
                :form-floating="false"
                :get-address="true"
                :label="$t('supplier.supplierSearch')"
                :options="{
                  types: ['establishment'],
                  componentRestrictions: { country: countryCode },
                  bounds: searchRadius,
                  strictBounds: true,
                }"
                class="col-12"
                @address="setPlaceAddress"
                @input="checkSupplierValue"
                @selected-place="setPlace"
              />

              <div class="search-separator-container pb-24 mb-24">
                <div class="separator-text-container">
                  <span class="separator-text fw-semi text-sm">{{ $t('general.shared.writeItManually') }}</span>
                </div>
              </div>
            </div>
          </fieldset>

          <div class="fieldset-translatable mb-0">
            <nav class="translations-tab">
              <div id="nav-tab" class="nav nav-tabs" role="tablist">
                <v-button
                  v-for="(language, index) in languages"
                  :id="`nav-supplier-create-basic-information-${language.key}`"
                  :key="index"
                  :aria-controls="`supplier-create-basic-information-${language.key}`"
                  :class="{ active: language.key === currentLanguage }"
                  :data-bs-target="`#supplier-create-basic-information-${language.key}`"
                  aria-selected="true"
                  class="nav-tab-link py-8 px-16 me-8"
                  data-bs-toggle="tab"
                  role="tab"
                  size="sm"
                  variant="outline-primary"
                  @click="currentLanguage = language.key"
                >
                  {{ language.label }}
                </v-button>
              </div>
            </nav>

            <transition
              mode="out-in"
              name="fade"
              appear
            >
              <div
                id="supplier-create-basic-information-tabs"
                class="tab-content"
              >
                <div
                  v-for="(language, index) in languages"
                  :id="`supplier-create-basic-information-${language.key}`"
                  :key="index"
                  :aria-labelledby="`nav-supplier-create-basic-information-${language.key}`"
                  :class="{ 'active show': language.key === currentLanguage }"
                  class="supplier-create-basic-information-tab tab-pane fade"
                  role="tabpanel">
                  <the-supplier-create-basic-information-fieldset
                    v-model:description="translations[language.key].description"
                    v-model:name="name"
                    v-model:type="type"
                    :clear="clear"
                    :selected-default-resources="selectedDefaultResources"
                    :current-language="currentLanguage"
                    :disabled-fields="!searchRadius || !countryCode"
                    :language="language.key"
                    @media-uploaded="editorMedia.push($event)"
                    @is-valid="isTranslationsValid.find((translation) => translation.locale === language.key).valid = $event"
                  />
                </div>
              </div>
            </transition>
          </div>

          <fieldset>
            <legend class="visually-hidden">{{ $t('general.shared.moreData') }}</legend>

            <div class="row gx-12">
              <div class="form-outline">
                <label :class="{ disabled: !searchRadius || !countryCode }" class="label">
                  <span>{{ $t('general.shared.priceRange') }}</span>
                </label>

                <div class="form-control">
                  <div class="star-group justify-content-between">
                    <v-star-rating
                      v-model="rating"
                      :disabled="!searchRadius || !countryCode"
                      icon="euro"
                      icon-size="md"
                    />

                    <div class="text-sm">{{ getPriceRangeLabel(rating) }}</div>
                  </div>
                </div>
              </div>

              <div class="form-outline">
                <label :class="{ disabled: !searchRadius || !countryCode }" class="label">
                  <span>{{ $t('general.shared.amenities') }}</span>
                </label>

                <v-select
                  id="supplier-create-amenities-select"
                  v-model="amenities"
                  :disabled="!searchRadius || !countryCode"
                  :options="supplierAmenities"
                  :reduce="returnValue => returnValue.key"
                  multiple
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>

              <v-form-input
                id="supplier-create-phone"
                v-model="phone"
                :disabled="!searchRadius || !countryCode"
                :label="$t('general.shared.phone')"
                :type="'text'"
                :yup-errors-variable="errors.phone"
                autocomplete="off"
                form-type="outline"
              />

              <v-form-input
                id="supplier-create-email"
                v-model="email"
                :disabled="!searchRadius || !countryCode"
                :label="$t('general.shared.email')"
                :type="'email'"
                :yup-errors-variable="errors.email"
                autocomplete="off"
                form-type="outline"
              />

              <!-- <v-checkbox
                id="supplier-create-email-type"
                v-model="emailType"
                :class="{ 'text-gray-500': !searchRadius || !countryCode }"
                :is-disabled="!searchRadius || !countryCode"
                :label="$t('general.shared.sendEmailNotification')"
                :left="true"
                :right="false"
                class="form-check-inline mb-12"
                for-key="supplierEmailTypeNormal"
              /> -->

              <div class="form-outline">
                <label :class="{ disabled: !searchRadius || !countryCode }" class="label">
                  <span>{{ $t('general.shared.bookingType') }}</span>
                </label>
                <v-select
                  id="supplier-create-preferred-language"
                  v-model="preferredTypesBooking"
                  :disabled="!searchRadius || !countryCode"
                  :options="getTypesBooking"
                  :reduce="returnValue => returnValue.label"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>

              <v-form-input
                v-if="'Portal' === preferredTypesBooking"
                :id="`supplier-booking-types-supplier`"
                v-model="preferredTypesBookingMeta"
                :label="$t('general.shared.supplierPortal')"
                :type="'text'"
                autocomplete="off"
                form-type="outline"
              />

              <v-form-input
                v-if="'Promo code' === preferredTypesBooking"
                :id="`supplier-booking-types-promocode`"
                v-model="preferredTypesBookingMeta"
                :label="$t('general.shared.promoCode')"
                :type="'text'"
                autocomplete="off"
                form-type="outline"
              />

              <!-- <v-form-input
                v-if="'Email' === preferredTypesBooking"
                :id="`supplier-booking-types-email`"
                v-model="preferredTypesBookingMeta"
                :label="$t('general.shared.byEmail')"
                :type="'text'"
                autocomplete="off"
                form-type="outline"
              /> -->

              <v-form-input
                v-if="'Other' === preferredTypesBooking"
                :id="`supplier-booking-types-other`"
                v-model="preferredTypesBookingMeta"
                :label="$t('general.shared.other')"
                :type="'text'"
                autocomplete="off"
                form-type="outline"
              />
              <!-- <template>
                <div>
                  <v-form-input
                    id="supplier-room-text"
                    v-model="roomType.freeText"
                    placeholder="text"
                    autocomplete="off"
                    form-type="outline"
                    class="text-start"
                    @change="handleTransportChange(roomType)"
                  />
                </div>
              </template> -->

              <div class="form-outline">
                <label :class="{ disabled: !searchRadius || !countryCode }" class="label">
                  <span>{{ $t('general.shared.preferredLanguage') }}</span>
                </label>

                <v-select
                  id="supplier-create-preferred-language"
                  v-model="preferredLanguage"
                  :disabled="!searchRadius || !countryCode"
                  :options="languages"
                  :reduce="returnValue => returnValue.key"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>

              <div class="form-outline">
                <label :class="{ disabled: !searchRadius || !countryCode }" class="label">
                  <span>{{ $t('general.shared.paymentMethod') }}</span>
                </label>

                <v-select
                  id="supplier-create-payment-method-type-select"
                  v-model="paymentMethod"
                  :disabled="!searchRadius || !countryCode"
                  :options="supplierPaymentMethodTypes"
                  :reduce="returnValue => returnValue.key"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>

              <v-form-input
                v-if="paymentMethod === 'bank_transfer'"
                id="supplier-create-account-number"
                v-model="account"
                :disabled="!searchRadius || !countryCode"
                :label="$t('general.shared.accountNumber')"
                :yup-errors-variable="errors.account"
                autocomplete="off"
                class="col-12"
                form-type="outline"
              />

              <div class="form-outline">
                <label :class="{ disabled: !searchRadius || !countryCode }" class="label">
                  <span>{{ $t('general.shared.paymentBy') }}</span>
                </label>

                <v-select
                  id="supplier-create-payment-by-select"
                  v-model="payment_date"
                  :disabled="!searchRadius || !countryCode"
                  :options="getPaymentBy"
                  :reduce="returnValue => returnValue.label"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </div>

              <div class="form-outline">
                <label :class="{ disabled: !searchRadius || !countryCode }" class="label">
                  <span>{{ $t('general.shared.notes') }}</span>
                </label>

                <v-html-editor
                  id="supplier-create-notes"
                  ref="supplier-create-notes-ref"
                  :disabled="!searchRadius || !countryCode"
                  :value="notes"
                  @input="notes = $event"
                  @media-uploaded="editorMedia.push($event)"
                />
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend class="visually-hidden">{{ $t('general.shared.links') }}</legend>

            <v-form-input
              id="supplier-create-map"
              v-model="mapUrl"
              :disabled="!searchRadius || !countryCode"
              :label="$t('general.shared.map')"
              :yup-errors-variable="errors.mapUrl"
              autocomplete="off"
              form-type="outline"
            />

            <v-form-input
              id="supplier-create-website"
              v-model="siteUrl"
              :disabled="!searchRadius || !countryCode"
              :label="$t('general.shared.website')"
              :yup-errors-variable="errors.website"
              autocomplete="off"
              form-type="outline"
            />
          </fieldset>

          <div class="search-separator-container pb-32">
            <div class="separator-text-container">
              <span class="separator-text text-sm">{{ $t('general.address.address') }}</span>
            </div>
          </div>

          <v-contact-billing-form
            id="supplier-create-address-form"
            ref="supplier-create-address-form-ref"
            :all-disabled="!searchRadius || !countryCode"
            :data="addressData"
            :hidden-legend="true"
            :without-billing-address="true"
            id-prefix="supplier-create"
            @updated-data="setAddressData"
            @is-valid="addressValid = $event"
          />

          <div class="search-separator-container">
            <div class="separator-text-container mb-16"/>
          </div>

          <fieldset>
            <legend class="visually-hidden">{{ $t('general.shared.media') }}</legend>

            <div class="supplier-media-container mb-32">
              <div class="media-title text-lg fw-semi mb-16">{{ $t('general.shared.media') }}</div>

              <v-uploader
                ref="uploader"
                :after-upload="onChange"
                route-prefix="api/s3"
                @removed="onChange"
              />

              <div class="media-container mt-16">
                <div class="row gx-8 gy-8">
                  <div
                    v-for="media in photos"
                    :key="media.uuid"
                    class="col-4 col-lg-3"
                  >
                    <div class="media position-relative">
                      <v-picture>
                        <img
                          :alt="media.preview_url"
                          :src="media.preview_url"
                        />
                      </v-picture>

                      <div class="media-actions position-absolute top-0 end-0">
                        <v-button
                          :disabled="disableRemoveButton"
                          class="btn-trash btn-primary fw-normal me-4"
                          variant="icon"
                          @click="removePhoto(media.uuid)"
                        >
                          <v-icon icon="trash" size="md"/>
                        </v-button>

                        <v-button
                          :disabled="disableRemoveButton"
                          class="btn-trash btn-primary fw-normal"
                          variant="icon"
                          @click="showImage(media)"
                        >
                          <v-icon icon="search" size="md"/>
                        </v-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>

        <v-button
          :disabled="!meta.valid || !addressValid || accepted || !isTranslationsValid.every(translation => translation.valid)"
          :is-loading="accepted"
          class="btn-submit-form btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="createSupplier"
        >
          {{ $t('general.button.create') }}
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </div>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>

  <v-show-media-modal
    v-if="modalToShow === 'v-show-media-modal' && !!currentMedia"
    :media="currentMedia"
    @closed="closeModal(); currentMedia = null"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import vSelect from 'vue-select';
import { v4 as uuidv4 } from 'uuid';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import useConcepts from '@/helpers/Concepts';
import { mapConcepts } from '@/helpers/ConceptHelper';
import VContactBillingForm from '@/components/shared/VContactBillingForm.vue';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import Location from '@/api/objects/Location';
import VHtmlEditor from '@/components/vendor/htmlEditor/VHtmlEditor.vue';
import VUploader from '@/components/vendor/upload/VUploader.vue';
import VStarRating from '@/components/vendor/VStarRating.vue';
import TheSupplierCreateBasicInformationFieldset
  from '@/components/supplier/parts/TheSupplierCreateBasicInformationFieldset.vue';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import Media from '@/api/objects/Media';
import useModalUtils from '@/helpers/ModalUtils';
import VShowMediaModal from '@/components/shared/VShowMediaModal.vue';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheSupplierCreateOffcanvas',
  components: {
    VShowMediaModal,
    TheSupplierCreateBasicInformationFieldset,
    VStarRating,
    VUploader,
    VHtmlEditor,
    VInputAddress,
    VContactBillingForm,
    VIcon,
    VButton,
    VFormInput,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    vSelect,
    // VCheckbox,
    VPicture,
    ResizableContainer,
  },
  emits: [
    'closed',
    'reloadSuppliers',
    'openDetails',
  ],
  setup() {
    const { t } = useI18n();

    const rules = yup.object({
      amenities: yup.array()
        .nullable()
        .label(t('general.shared.amenities')),
      phone: yup.string()
        .nullable()
        .label(t('general.shared.phone')),
      email: yup.string()
        .email()
        .nullable()
        .label(t('general.shared.email')),
      emailType: yup.bool()
        .oneOf([true, false], t('general.shared.sendEmailNotification')),
      paymentMethod: yup.string()
        .nullable()
        .label(t('general.shared.paymentMethod')),
      account: yup.string()
        .nullable()
        .label(t('general.shared.accountNumber')),
      notes: yup.string()
        .nullable()
        .label(t('general.shared.notes')),
      contactAddress2: yup.string()
        .nullable()
        .label(t('general.address.aptSuit')),
      rating: yup.number()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.priceRange')),
      mapUrl: yup.string()
        .nullable()
        .label(t('general.shared.map')),
      siteUrl: yup.string()
        .nullable()
        .label(t('general.shared.website')),
      preferredLanguage: yup.string()
        .nullable()
        .label(t('general.shared.preferredLanguage')),
      preferredTypesBooking: yup.string()
        .nullable()
        .label(t('general.shared.bookingType')),
      preferredTypesBookingMeta: yup.string()
        .nullable()
        .label(t('general.shared.bookingType')),
    });

    const initialValues: Record<string, any> = {
      rating: 0,
      emailType: true,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: amenities } = useField('amenities');
    const { value: phone } = useField('phone');
    const { value: email } = useField('email');
    const { value: emailType } = useField('emailType');
    const { value: paymentMethod } = useField('paymentMethod');
    const { value: account } = useField('account');
    const { value: notes } = useField('notes');
    const { value: contactAddress2 } = useField('contactAddress2');
    const { value: rating } = useField('rating');
    const { value: mapUrl } = useField('mapUrl');
    const { value: siteUrl } = useField('siteUrl');
    const { value: preferredLanguage } = useField('preferredLanguage'); 
    const { value: preferredTypesBooking } = useField('preferredTypesBooking');
    const { value: preferredTypesBookingMeta } = useField('preferredTypesBookingMeta');

    return {
      ...useModalUtils(),
      ...useConcepts(),
      ...form,
      amenities,
      phone,
      email,
      emailType,
      paymentMethod,
      account,
      notes,
      contactAddress2,
      rating,
      mapUrl,
      siteUrl,
      preferredLanguage,
      preferredTypesBooking,
      preferredTypesBookingMeta,
    };
  },
  data() {
    return {
      currentMedia: null as Media | null,
      addressData: {} as Record<string, any>,
      addressValid: false as boolean,
      searchRadius: null as Record<any, any> | null,
      lat: null as null | number,
      lng: null as null | number,
      countryCode: '' as string,
      countryCodeSupplier: '' as string,
      location: {} as Location,
      placeId: '' as string,
      media: [] as any,
      name: '' as string,
      type: '' as string,
      translations: {} as Record<string, any>,
      currentLanguage: '',
      clear: false,
      isTranslationsValid: [] as Record<string, any>[],
      photos: [] as Media[],
      photosUuid: {} as Record<string, any>,
      disableRemoveButton: false as boolean,
      editorMedia: [] as any[],
      selectedDefaultResources: [] as any[],
      payment_date: "10 days before" as string,
    };
  },
  computed: {
    defaultLocale() {
      return !!import.meta.env.VITE_APP_DEFAULT_LOCALE ? import.meta.env.VITE_APP_DEFAULT_LOCALE : 'en';
    },
    languages(): any {
      if (!!this.concepts && this.concepts['app.languages']) {        
        return mapConcepts(this.concepts['app.languages']);
      }

      return [];
    },
    supplierPaymentMethodTypes(): any {
      if (!!this.concepts && this.concepts['supplier.payment_method_type']) {
        return mapConcepts(this.concepts['supplier.payment_method_type']);
      }

      return [];
    },
    supplierAmenities(): any {
      if (!!this.concepts && this.concepts['supplier.amenities']) {
        return mapConcepts(this.concepts['supplier.amenities']);
      }

      return [];
    },
    getTypesBooking() {
      const bokingData = [
        {
          name: 'website',
          label: 'Website'
        },
        {
          name: 'supplier_portal',
          label: 'Portal'
        },
        {
          name: 'promo_code',
          label: 'Promo code'
        },
        {
          name: 'email',
          label: 'Email'
        },
        {
          name: 'other',
          label: 'Other'
        }
      ];
      const sortedBookingData = bokingData.sort((a, b) => a.label.localeCompare(b.label));

      return sortedBookingData;
    },
    getPaymentBy() {
      const PaymentData = [
        {
          name: '10_days_before',
          label: '10 days before'
        },
        {
          name: 'after_the_trip',
          label: 'After the trip'
        },
      ];
      return PaymentData;
    },
    mediaUuids() {
      return this.media.map((media: any) => (media.attributes.uuid));
    },
  },
  watch: {
    countryCode(newValue, oldValue) {
      if (!!oldValue && newValue !== oldValue) {
        this.clearForm();
      }
    },
    preferredTypesBooking(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.preferredTypesBookingMeta = "";
      }
    }
  },
  created() {
    this.currentLanguage = this.defaultLocale;

    this.mapTranslations();
  },
  methods: {
    showImage(media: Media) {
      this.currentMedia = media;

      this.openModal('v-show-media-modal');
    },
    mapTranslations() {
      this.isTranslationsValid = [];

      this.languages.forEach((language: Record<string, any>) => {
        this.isTranslationsValid.push({
          locale: language.key,
          valid: false,
        });

        this.translations[language.key] = {
          description: '',
        };
      });
    },
    onChange() {
      const { uploader }: any = this.$refs;

      if (!!uploader) {
        this.media = uploader.mediaLibrary.state.media;
      }
    },
    async setAddressData(address: Record<string, any>) {
      this.addressData = address;
      this.lat = address.lat;
      this.lng = address.lng;
    },
    async setCity(address: Record<string, any>, createLocation = false) {
      await this.clearForm();
      this.clear = !this.clear;

      this.countryCode = address.country.shortName;
      this.addressData.contactCity = address.name;
      this.addressData.contactCountry = address.country.longName;
      this.addressData.contactState = address.regionProvince.longName;
      this.addressData.contactCity = address.name;

      this.searchRadius = address.bounds;

      if (createLocation) {
        this.location = await api.location.create({
          name: address.name,
          provider_id: address.placeId,
          lat: address.lat,
          lng: address.lng,
          country: address.country.longName,
          province: address.regionProvince.longName,
          state: address.stateCommunity.longName,
        });
      }
    },
    async setPlaceAddress(address: Record<string, any>) {
      this.countryCodeSupplier = address.country.shortName;

      this.addressData.contactAddress1 = address.vicinity;
      this.addressData.contactAddress2 = !!this.contactAddress2 ? this.contactAddress2 : '';
      this.addressData.contactPostalCode = address.postalCode.longName;
      this.addressData.contactCountry = address.country.longName;
      this.addressData.contactCity = address.locality.longName;
      this.addressData.contactState = address.regionProvince.longName;
    },
    setCityBounds(bounds: any) {
      this.searchRadius = bounds;
    },
    async setPlace(place: any) {
      if (!!this.placeId && this.placeId !== place.place_id) {
        await this.clearForm(false);
      }

      this.placeId = place.place_id;
      this.name = place.name;
      this.mapUrl = place.url;
      this.siteUrl = place.website;
      this.lat = place.geometry.location.lat().toString();
      this.lng = place.geometry.location.lng().toString();
      this.phone = place.international_phone_number;

      if (!!place.photos) {
        place.photos.forEach(async (photo: any) => {
          const uuid = uuidv4();

          const data = {
            url: photo.getUrl(),
            uuid,
          };

          const media = await api.media.upload('s3', data);

          this.photos.push(media);
        });
      }
    },
    removePhoto(photoUuid: string) {
      this.disableRemoveButton = true;

      this.photos = this.photos.filter((photo) => photo.uuid !== photoUuid);

      this.disableRemoveButton = false;
    },
    checkCityValue(value: string) {
      if (!value) {
        this.clear = !this.clear;
        this.clearForm(true);
      }
    },
    checkSupplierValue(value: string) {
      if (!value) {
        this.clear = !this.clear;
        this.clearForm(false);
      }
    },
    clearForm(clearAll = false) {
      if (!!this.$refs['supplier-create-supplier-search-ref']) {
        (this.$refs['supplier-create-supplier-search-ref'] as any).valueLocal = '';
      }

      if (!!this.$refs['supplier-create-notes-ref']) {
        (this.$refs['supplier-create-notes-ref'] as any).clear();
      }

      (this.$refs['supplier-create-address-form-ref'] as any).clearForm();

      this.resetForm();

      this.mapTranslations();

      this.media = [];
      this.photos = [];
      this.photosUuid = [];

      const { uploader }: any = this.$refs;

      if (!!uploader) {
        uploader.mediaLibrary.state.media = [];
      }

      if (clearAll) {
        this.countryCode = '';
        this.searchRadius = {};

        if (!!this.$refs['supplier-create-city-search-ref']) {
          (this.$refs['supplier-create-city-search-ref'] as any).valueLocal = '';
        }
      }
    },
    getPriceRangeLabel(rating: number | unknown) {
      let ratingLabel = '';

      switch (rating) {
        case 1:
          ratingLabel = this.$t('general.shared.veryCheap');
          break;
        case 2:
          ratingLabel = this.$t('general.shared.cheap');
          break;
        case 3:
          ratingLabel = this.$t('general.shared.normal');
          break;
        case 4:
          ratingLabel = this.$t('general.shared.expensive');
          break;
        default:
          ratingLabel = '';
          break;
      }

      return ratingLabel;
    },
    async createSupplier() {
      try {
        this.toggleAccepted();

        const photosUuid = this.photos.map((media: any) => media.uuid);

        const media = photosUuid.concat(this.mediaUuids);

        const data: any = {
          name: this.name,
          type: this.type,
          amenities: this.amenities,
          phone: this.phone,
          email: this.email,
          type_email: this.emailType ? 'normal' : 'never',
          payment_method: this.paymentMethod,
          account: this.account,
          notes: this.notes,
          contact_address_1: this.addressData.contactAddress1,
          contact_address_2: this.addressData.contactAddress2,
          contact_postal_code: this.addressData.contactPostalCode,
          contact_country: this.addressData.contactCountry,
          contact_city: this.addressData.contactCity,
          contact_state: this.addressData.contactState,
          lat: this.lat,
          lng: this.lng,
          location_id: this.location.id,
          provider_id: this.placeId,
          price_range: this.rating,
          images: media,
          translations: this.translations,
          map_url: this.mapUrl,
          site_url: this.siteUrl,
          preferred_language: this.preferredLanguage,
          selectedDefaultResources: this.selectedDefaultResources,
          booking_type: this.preferredTypesBooking,
          booking_type_meta: this.preferredTypesBookingMeta,
          payment_date: this.payment_date,
        };

        const response = await api.supplier.create(data);

        if (!!this.editorMedia && this.editorMedia.length > 0) {
          const editorMedia = {
            media: this.editorMedia,
            model_type: 'supplier',
            collection: 'tinymce',
            model_id: response.id,
          };

          await api.media.confirm('s3', editorMedia);
        }

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.clear = !this.clear;

        this.clearForm(true);

        this.$emit('closed');
        this.$emit('openDetails', response);
        this.$emit('reloadSuppliers');
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.toggleAccepted();
      }
    },
  },
});
</script>