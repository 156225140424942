<template>
  <div class="info-popup" :style="{ width: dynamicPopupWidth }">
    <div class="info-mini-container">
      <slot></slot>
      <div class="resize-handle" @mousedown="startResize"></div>
      <div v-if="isResizing" class="overlay" @mousemove="handleResize" @mouseup="stopResize"></div>
    </div>
  </div>
</template>

<script>
export default {
    name: "ResizableContainer",
    data() {
        return {
            isResizing: false,
            popupWidth: 600,
        };
    },
    created() {
        window.addEventListener("resize", this.handleWindowResize);
    },
    unmounted() {
        window.removeEventListener("resize", this.handleWindowResize);
    },
    computed: {
        dynamicPopupWidth() {
            return window.innerWidth < 992 ? "100%" : this.popupWidth + "px";
        },
    },
    methods: {
        startResize() {
            this.isResizing = true;
        },
        handleResize(e) {
            if (this.isResizing) {
                const newWidth = window.innerWidth - e.pageX;
                this.popupWidth = newWidth;
            }
        },
        stopResize() {
            this.isResizing = false;
        },
        handleWindowResize() {
            if (window.innerWidth < 992) {
                this.popupWidth = 992;
            } else {
                this.popupWidth = 520;
            }
        },
    },
};
</script>

<style scoped>
.info-popup {
  position: fixed;
  top: auto;
  right: 0;
  height: calc(100% - 0.10rem);
  background-color: var(--uq-light);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  min-width: 650px;
  overflow-y: scroll;
}

.info-mini-container {
  position: relative;
}

.resize-handle {
    position: absolute;
    top: 0;
    left: -5px;
    width: 8px;
    cursor: ew-resize;
    background: #ccc;
    z-index: 111;
    overflow: hidden;
    height: 103%;
    min-height: 74vh;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: ew-resize;
    z-index: 999;
}

@media (max-width: 992px) {
    .info-popup {
        min-width: 100%;
        padding: 1.5rem 1rem 0 0;
    }
}
</style>
