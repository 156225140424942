import { AbstractService } from '@uniqoders/sdk';
import Agency from '@/api/objects/Agency';

export default class AdminAgencyService extends AbstractService {
  /**
   * Retrieves all Agencies
   * @protected
   * @param params
   */
  public all(params: any): Promise<Agency[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/agencies'),
      params,
    });
  }

  /**
   * Retrieves all Agencies paginated
   * @protected
   * @param params
   * @param paginate
   */
  public allPaginated(params: any, paginate = true): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/agencies'),
      params,
      options: {
        paginate,
      },
    });
  }

  /**
   * Retrieves the specified Agency
   * @protected
   * @param agency
   */
  public retrieve(agency: string | number): Promise<Agency> {
    return this.request({
      method: 'get',
      url: this.buildPath('/agency/{agency}', { agency }),
    });
  }

  /**
   * Creates a single Agency
   * @protected
   * @param data
   */
  public create(data: Record<string, any>): Promise<Agency> {
    return this.request({
      method: 'post',
      url: this.buildPath('/agency'),
      data,
    });
  }

  /**
   * Updates the specified Agency
   * @param agency
   * @param data
   * @protected
   */
  public update(agency: string | number, data: Record<string, any>): Promise<Agency> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/agency/{agency}', { agency }),
      data,
    });
  }

  /**
   * Archives the specified Agency
   * @protected
   * @param agency
   */
  public archive(agency: string | number): Promise<Agency> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/agency/{agency}', { agency }),
    });
  }

  /**
   * Restore the specified Agency
   * @protected
   * @param agency
   */
  public restore(agency: string | number): Promise<Agency> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/agency/{agency}/restore', { agency }),
    });
  }

  /**
   * Deletes the specified Agency
   * @protected
   * @param agency
   */
  public delete(agency: string | number): Promise<Agency> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/agency/{agency}/force', { agency }),
    });
  }

  /**
   * Assign/unassign User to Agency
   * @protected
   * @param agency
   * @param user
   */
  public attachUser(agency: string | number, user: string | number): Promise<Agency> {
    return this.request({
      method: 'post',
      url: this.buildPath('/agency/{agency}/attach/user/{user}', { agency, user }),
    });
  }

  /**
   * Attach Templates
   * @protected
   * @param agency
   * @param data
   */
  public attachTemplates(agency: string | number, data: Record<string, any>): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/agency/{agency}/attach/templates', { agency }),
      data,
    });
  }

  /**
   * Detach Templates
   * @protected
   * @param agency
   * @param data
   */
  public detachTemplates(agency: number, data: Record<string, any>): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/agency/{agency}/detach/templates', { agency }),
      data,
    });
  }
}
