<template>
  <div class="form-outline">
    <label class="label">
      <span>{{ $t('admin.agency.template.addExistingTemplate') }}</span>
    </label>

    <v-select
      v-model="template"
      :clearable="false"
      :filterable="false"
      :options="templates"
      :selectable="(option) => !!selectedOptions
        ? !selectedOptions.some((selectedOption) => selectedOption.id === option.id) : null"
      class="select-search"
      label="name"
      @search="onSearch"
      @option:selecting="$emit('selected', $event)"
    >
      <template v-slot:no-options>
        {{ $t('general.shared.writeSearchProducts') }}
      </template>

      <template v-slot:option="option">
        <div class="d-center">
          <span>{{ option?.name }}</span>
        </div>
      </template>

      <template v-slot:selected-option="option">
        <div class="selected d-center py-8">
          <span>{{ option?.name }}</span>
        </div>
      </template>

      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <v-icon icon="search" size="sm"/>
        </span>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import vSelect from 'vue-select';
import { mapState } from 'pinia';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import Template from '@/api/objects/Template';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'VSelectAgencyTemplateSearch',
  components: {
    VIcon,
    vSelect,
  },
  props: {
    selectedOptions: {
      type: Array as PropType<Template[]>,
      required: false,
    },
  },
  emits: ['selected'],
  data() {
    return {
      template: null as Template | null,
      templates: [] as Template[],
      location: '' as string,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
  },
  methods: {
    onSearch(search: string, loading: any) {
      if (search.length) {
        loading(true);
        this.loadTemplates(loading, search);
      }
    },
    async loadTemplates(loading: any, search: string) {
      loading(true);

      try {
        const query = { filters: { name: search } };

        this.templates = await api.admin.template.all(query);
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        loading(false);
      }
    },
  },
});
</script>
