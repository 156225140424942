export default {
  cases: 'Casos',
  case: 'Caso',
  tour: 'Tour',
  setup: 'Configuración',
  caseSetup: 'Configuración del Caso',
  builder: 'Constructor',
  pax: 'Pasajeros',
  contacts: 'Contactos',
  bookings: 'Reservas',
  pricing: 'Precios',
  preview: 'Vista previa',
  tourBuilder: 'Constructor del Tour',
  tourPax: 'Tour Pax',
  tourContacts: 'Contactos del Tour',
  tourStatuses: 'Estados del Tour',
  tourPricing: 'Precio del Tour',
  createTour: 'Crear nuevo Tour',
  createTourText: '¿Seguro que quieres crear un nuevo Tour en este caso?',
  archiveTour: 'Archivar recorrido',
  selectClient: 'Seleccionar cliente',
  writeSearchClients: 'Escribir para buscar clientes',
  noCases: 'No hay casos',
  createNewCase: 'Crear nuevo caso',
  archivedCases: 'Casos archivados',
  activeCases: 'Casos activos',
  basicInformation: 'Información básica',
  caseDetails: 'Detalles del caso',
  archiveText: 'Puedes archivar un caso, si lo haces el caso no aparecerá en las búsquedas de la aplicación. Podrás verlo en la lista de casos si filtras por \'archivado\'. Una vez que veas los detalles de un caso archivado, puedes eliminarlo definitivamente o puedes desarchivarlo',
  media: 'Media',
  noMedia: 'No hay medios asociados',
  archivedText: 'Este caso está archivado, si lo desea puede desarchivarlo pulsando el botón \'Restaurar\'.',
  archiveCaseTitle: 'Caso archivado',
  archiveCaseText: '¿Seguro que quiere archivar el caso \'{case}\'? Si lo archivas, no aparecerá en las búsquedas',
  deleteText: 'Este caso está archivado, si quieres borrarlo permanentemente pulsa el botón \'Borrar\'. Ten en cuenta que si lo eliminas, este caso dejará de existir y no se podrá recuperar',
  deleteCaseTitle: 'Eliminar caso',
  deleteCaseText: '¿Está seguro de que desea eliminar el caso \'{case}\'? Si lo borras, no podrás recuperarlo',
  restoreCaseTitle: 'Recuperar caso',
  restoreCaseText: '¿Seguro que quieres recuperar el caso \'{case}\'? Si lo recuperas, dejará de estar archivado y aparecerá en las búsquedas de la aplicación',
  caseStatus: 'Estado del caso',
  noContacts: 'No hay contactos asociados',
  editContactsBelongCaseText: 'Editar contactos pertenecientes al caso',
  addContacts: 'Añadir contactos',
  removeContactTitle: 'Eliminar contacto del caso',
  removeContactText: '¿Está seguro de que desea eliminar {contact} como contacto del caso {case}?',
  quote: 'Quote',
  statuses: 'Estados',
  duplicateTour: 'Duplicar tour',
  duplicatedTourSuccess: 'Todo el tour duplicado con éxito',
  redirectingNewTour: 'Redirigiendo al nuevo tour...',
};
