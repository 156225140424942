import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface TourDayTranslationContract extends IBaseObject {
  description: string;
  locale: string;
  tourDayId: number;
}

export default class TourDayTranslation extends BaseObject implements TourDayTranslationContract {
  public declare description: string;
  public declare locale: string;
  public declare tourDayId: number;

  constructor(data: TourDayTranslationContract) {
    super(data);
  }
}
