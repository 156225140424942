import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import TourDay from '@/api/objects/TourDay';
import TourDayItemResource from '@/api/objects/TourDayItemResource';
import Supplier from '@/api/objects/Supplier';
import { UserContract } from "@/api/objects/User";

export interface TourDayItemContract extends IBaseObject {
  invoiceStatus: string;
  isBlocked: boolean;
  paymentStatus: string;
  position: number;
  order: number;
  reservationStatus: string;
  tourDayId: number;
  type: string;
  supplierName: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  resources: TourDayItemResource[];
  supplier: Supplier;
  stage: number;
  tourDay: TourDay;
  disabled: boolean;
}

export default class TourDayItem extends BaseObject implements TourDayItemContract {
  public declare invoiceStatus: string;
  public declare isBlocked: boolean;
  public declare paymentStatus: string;
  public declare position: number;
  public declare order: number;
  public declare reservationStatus: string;
  public declare tourDayId: number;
  public declare type: string;
  public declare supplierName: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;
  public declare resources: TourDayItemResource[];
  public declare supplier: Supplier;
  public declare stage: number;
  public declare tourDay: TourDay;
  public declare disabled: boolean;

  constructor(data: UserContract) {
    super(data);
  }
}
