import { BaseObject, IBaseObject } from '@uniqoders/sdk';
import Resource from '@/api/objects/Resource';

export interface LocationContract extends IBaseObject {
  name: string;
  state: string;
  providerId: string | null;
  country: string;
  province: string;
  lat: number;
  lng: number;
  createdAt: string;
  updatedAt: string;
  resources: Resource[];
}

export default class Location extends BaseObject implements LocationContract {
  public declare name: string;
  public declare state: string;
  public declare providerId: string | null;
  public declare country: string;
  public declare province: string;
  public declare lat: number;
  public declare lng: number;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare resources: Resource[];

  constructor(data: LocationContract) {
    super(data);
  }
}
