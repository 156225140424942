export default {
  cases: 'Cases',
  case: 'Case',
  tour: 'Tour',
  setup: 'Setup',
  caseSetup: 'Case setup',
  builder: 'Builder',
  pax: 'Pax',
  contacts: 'Contacts',
  bookings: 'Bookings',
  pricing: 'Pricing',
  preview: 'Preview',
  tourBuilder: 'Tour Builder',
  tourPax: 'Tour Pax',
  tourContacts: 'Tour Contacts',
  tourStatuses: 'Tour Statuses',
  tourPricing: 'Tour Pricing',
  createTour: 'Create new Tour',
  createTourText: 'Are you sure you want to create a new Tour in this case?',
  archiveTour: 'Archive Tour',
  selectClient: 'Select client',
  writeSearchClients: 'Write to search for clients',
  noCases: 'There are no cases',
  createNewCase: 'Create new case',
  archivedCases: 'Archived cases',
  activeCases: 'Active cases',
  basicInformation: 'Basic information',
  caseDetails: 'Case details',
  archiveText: 'You can archive a case, if you do this the case will not appear in the application searches. You will be able to see it in the list of cases if you filter by \'archived\'. Once you view the details of an archived case, you can delete it definitively or you can unarchive it.',
  media: 'Media',
  noMedia: 'There are no associated media',
  archivedText: 'This case is archived, if you want you can unarchive it by clicking on the \'Restore\' button.',
  archiveCaseTitle: 'Archive case',
  archiveCaseText: 'Are you sure you want to archive the case \'{case}\'? If you archive it, it will not appear in searches.',
  deleteText: 'This case is archived, if you want to delete it permanently click the \'Delete\' button. Note that if you delete it, this case will no longer exist and cannot be recovered.',
  deleteCaseTitle: 'Delete case',
  deleteCaseText: 'Are you sure you want to delete the case \'{case}\'? If you delete it, you will not be able to recover it.',
  restoreCaseTitle: 'Recover case',
  restoreCaseText: 'Are you sure you want to recover the case \'{case}\'? If you recover it, it will no longer be archived and will appear in the application searches.',
  caseStatus: 'Case Status',
  noContacts: 'There are no associated contacts',
  editContactsBelongCaseText: 'Edit contacts belonging to the case',
  addContacts: 'Add contacts',
  removeContactTitle: 'Remove case contact',
  removeContactText: 'Are you sure you want to remove {contact} as a contact for the case {case}?',
  quote: 'Quote',
  statuses: 'Statuses',
  duplicateTour: 'Duplicate tour',
  duplicatedTourSuccess: 'Whole tour duplicated successfully',
  redirectingNewTour: 'Redirecting to the new tour...',
};
