import { AbstractService } from '@uniqoders/sdk';
import User from '@/api/objects/User';

export default class AdminUserService extends AbstractService {
  /**
   * Retrieves all Users
   * @protected
   * @param params
   */
  public all(params: any): Promise<User[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/users'),
      params,
    });
  }

  /**
   * Retrieves all Users paginated
   * @protected
   * @param params
   * @param paginate
   */
  public async allPaginated(params: any, paginate = true): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/users'),
      params,
      options: {
        paginate,
      },
    });
  }

  /**
   * Retrieves the specified User
   * @protected
   * @param user
   */
  public retrieve(user: string | number): Promise<User> {
    return this.request({
      method: 'get',
      url: this.buildPath('/user/{user}', { user }),
    });
  }

  /**
   * Creates a single User
   * @protected
   * @param data
   */
  public create(data: Record<string, any>): Promise<User> {
    return this.request({
      method: 'post',
      url: this.buildPath('/user'),
      data,
    });
  }

  /**
   * Updates the specified User
   * @param user
   * @param data
   * @protected
   */
  public update(user: string | number, data: Record<string, any>): Promise<User> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/user/{user}', { user }),
      data,
    });
  }

  /**
   * Archives the specified User
   * @protected
   * @param user
   */
  public archive(user: string | number): Promise<User> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/user/{user}', { user }),
    });
  }

  /**
   * Restore the specified User
   * @protected
   * @param user
   */
  public restore(user: string | number): Promise<User> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/user/{user}/restore', { user }),
    });
  }

  /**
   * Deletes the specified User
   * @protected
   * @param user
   */
  public delete(user: string | number): Promise<User> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/user/{user}/force', { user }),
    });
  }

  /**
   * Forgives access to the specified User
   * @protected
   * @param user
   */
  public forgiveAccess(user: string | number): Promise<User> {
    return this.request({
      method: 'post',
      url: this.buildPath('/user/{user}/forgive/access', { user }),
    });
  }
}
