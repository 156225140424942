import { AbstractService } from '@uniqoders/sdk';
import Media from '@/api/objects/Media';

export default class MediaService extends AbstractService {
  /**
   * Upload a temporary Media
   * @protected
   * @param driver
   * @param data
   * @param headers
   */
  public upload(driver: string, data: Record<string, any>, headers = {}): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{driver}/uploads', { driver }),
      data,
      headers,
    });
  }

  /**
   * Confirms uploads files
   * @protected
   * @param driver
   * @param data
   */
  public confirm(driver: string, data: Record<string, any>): Promise<Media> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{driver}/uploads/confirm', { driver }),
      data,
    });
  }

  /**
   * Deletes a specific Media
   * @protected
   * @param driver
   * @param media
   */
  public delete(driver: string, media: string | number): Promise<any> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{driver}/uploads/destroy/{media}', { driver, media }),
    });
  }

  /**
   * Downloads a specific Media
   * @protected
   * @param driver
   * @param media
   */
  public download(driver: string, media: number): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{driver}/uploads/download/{media}', { driver, media }),
    });
  }

  /**
   * Update a specific Media name
   * @protected
   * @param driver
   * @param media
   * @param data
   */
  public update(driver: string, media: string | number, data: Record<string, any>): Promise<any> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{driver}/uploads/rename/{media}', { driver, media }),
      data,
    });
  }
}
