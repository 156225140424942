import { computed, ref } from 'vue';
import Concept from '@/api/objects/Concept';
import { useAppStore } from '@/stores/app';
import useCapabilities from "@/helpers/capabilities";

const useConcepts = () => {
  const appStore = useAppStore();

  const concepts = ref(computed(() => appStore.concepts));

  const { can } = useCapabilities();
  const conceptAllowed = (concept: Concept) => can(concept.permission) || false;

  return {
    concepts,
    conceptAllowed,
  };
};

export default useConcepts;
