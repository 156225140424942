<template>
  <v-modal id="show-media-modal" class-name="modal-fullscreen-sm-down w-auto" size="lg" @closed="$emit('closed')">
    <modal-header>
      <modal-title> {{ $t("general.shared.priceSummary") }} </modal-title>
    </modal-header>
    <modal-body class="justify-content-center">
      <template v-if="summaryData">
        <table class="pricing-table">
          <thead>
            <tr>
              <th>{{ $t("general.shared.hotelName") }}</th>
              <th>{{ $t("general.shared.total") }}</th>
              <th>{{ $t("general.shared.paidAmount") }}</th>
              <th>{{ $t("general.shared.pendingAmount") }}</th>
              <th>{{ $t("general.shared.payAmount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in summaryData" :key="index">
              <td>{{ data.hotelName }}</td>
              <td>{{ (data.payableAmount + data.pendingAmount + data.paidAmount).toFixed(2).replace(/\.0+$/, '') }}</td>
              <td>{{ (data.paidAmount).toFixed(2).replace(/\.0+$/, '') }}</td>
              <td>{{ (data.pendingAmount).toFixed(2).replace(/\.0+$/, '') }}</td>
              <td>{{ (data.payableAmount).toFixed(2).replace(/\.0+$/, '') }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t("general.shared.total") }}</td>
              <td class="total-price">
                {{ $t("general.shared.euroShort") }} {{ calculateTotalColumn().toFixed(2).replace(/\.0+$/, '') }}
              </td>
              <td class="total-price">
                {{ $t("general.shared.euroShort") }} {{ calculateTotal("paidAmount").toFixed(2).replace(/\.0+$/, '') }}
              </td>
              <td>
                {{ $t("general.shared.euroShort") }}
                {{ calculateTotal("pendingAmount").toFixed(2).replace(/\.0+$/, '') }}
              </td>
              <td>
                {{ $t("general.shared.euroShort") }}
                {{ calculateTotal("payableAmount").toFixed(2).replace(/\.0+$/, '') }}
              </td>
            </tr>
          </tfoot>
        </table>
      </template>
      <template v-else>
        <p>{{ $t("general.shared.noDataAvailable") }}</p>
      </template>
    </modal-body>
    <modal-footer>
      <v-button
        class="btn-sm btn-primary align-self-end cursor-pointer"
        size="sm"
        variant="primary"
        :disabled="isButtonDisabled()"
        @click="handleClick"
      >
        {{ $t("general.shared.generateXml") }}
      </v-button>
    </modal-footer>
  </v-modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import { usePriceStore } from "@/stores/priceCalculation";
import { useUserStore } from "@/stores/user";
import VModal from "@/components/vendor/basic/modal/VModal.vue";
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ModalHeader from "@/components/vendor/basic/modal/ModalHeader.vue";
import ModalTitle from "@/components/vendor/basic/modal/ModalTitle.vue";
import ModalBody from "@/components/vendor/basic/modal/ModalBody.vue";
import ModalFooter from "@/components/vendor/basic/modal/ModalFooter.vue";
import api from "@/api";

export default defineComponent({
  name: "TheHotelPricingSummaryModal",
  components: {
    ModalBody,
    ModalTitle,
    ModalHeader,
    ModalFooter,
    VModal,
    VButton
  },
  emits: ["closed", "loadTourPricing"],
  props: {
    summaryData: {
      type: Array as () => Array<{
        hotelName: string;
        paidAmount: number;
        payableAmount: number;
        pendingAmount: number;
      }>,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    agency: {
      type: String,
      required: true,
    },
    
  },
  data() {
    return {
      totalPayable: 0,
      totalPending: 0,
      totalPaid: 0,
    };
  },
  methods: {
    isButtonDisabled() {
      return this.calculateTotal("payableAmount") === 0;
    },
    calculateTotalColumn() {
      // Calculate the total of data.payableAmount + data.pendingAmount + data.paidAmount for all rows
      return this.summaryData.reduce(
        (total, data) => total + data.payableAmount + data.pendingAmount + data.paidAmount,
        0
      );
    },
    calculateTotal(propertyName: string) {
      return this.summaryData.reduce((total, data) => total + data[propertyName], 0);
    },
    cleanXmlData(xmlData: string) {
      // Remove unnecessary escape characters
      const cleanedXmlData = xmlData.replace(/\\/g, "");
      // Remove newline characters
      const finalXmlData = cleanedXmlData.replace(/\n/g, "");
      return finalXmlData;
    },
    async handleClick() {
      const aar: number[] = [];
      const priceStore = usePriceStore();
      priceStore.price.map((e) => aar.push(e.id));
      const payload = { resource_id: aar };
      try {
        const response: any = await api.tourPricing.generateXML(
          this.agency, this.caseId, this.tourId, payload
        );
        if (response.xmlFileData) {
          this.$toast.success(this.$t('general.shared.savedChanges'));
          const cleanedXmlData = this.cleanXmlData(response.xmlFileData);
          this.downloadXmlFile(cleanedXmlData);
          // clear the mark to pay data on success xml generate
          priceStore.clearPriceData();
          this.$emit("closed");
          this.$emit("loadTourPricing");
        }
      } catch (error: any) {
        if (error.response.status === 400) {
          this.$toast.error(error.response.data.message);
        }
      }
    },
    downloadXmlFile(xmlData: BlobPart) {
      const user = useUserStore();
      const blob = new Blob([xmlData], { type: "application/xml" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const xmlFileName = user.currentTour?.clientTour?.name;
      const now = DateTime.now();
      const currentDate = now.toLocaleString(DateTime.DATE_SHORT);
      a.download = `${xmlFileName}_${currentDate}.xml`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
  },
});
</script>
<style lang="scss">
#show-media-modal {
  .modal-content {
    height: 100%;
    .modal-body {
      overflow-y: auto;
      display: flex;
    }
  }
}
</style>
<style scoped>
.pricing-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: auto;
}
.pricing-table th,
.pricing-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.pricing-table th {
  background-color: #f2f2f2;
}
.pricing-table tfoot td {
  font-weight: bold;
}
.total-price {
  min-width: 100px;
}
@media (max-width: 998px) {
  .pricing-table {
    display: block;
    margin-top: 0;
  }
}
</style>
