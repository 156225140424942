import { BaseObject, IBaseObject } from '@uniqoders/sdk';

export interface ContactContract extends IBaseObject {
  type: string;
  name: string;
  surname: string;
  language: string;
  genre: string;
  birthday: string;
  phone: string;
  email: string;
  web: string;
  note: string;
  contactAddress1: string;
  contactAddress2: string;
  contactPostalCode: string;
  contactCountry: string;
  contactCity: string;
  contactState: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export default class Contact extends BaseObject implements ContactContract {
  public declare type: string;
  public declare name: string;
  public declare surname: string;
  public declare language: string;
  public declare genre: string;
  public declare birthday: string;
  public declare phone: string;
  public declare email: string;
  public declare web: string;
  public declare note: string;
  public declare contactAddress1: string;
  public declare contactAddress2: string;
  public declare contactPostalCode: string;
  public declare contactCountry: string;
  public declare contactCity: string;
  public declare contactState: string;
  public declare createdAt: string;
  public declare updatedAt: string;
  public declare deletedAt: string;

  constructor(data: ContactContract) {
    super(data);
  }
}
