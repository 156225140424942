<template>
  <general-modal v-if="modal.show"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import GeneralModal from '@/components/vendor/generalModals/modals/GeneralModal.vue';
import { useGeneralModalsStore } from '@/stores/genenalModals';

export default defineComponent({
  name: 'TheGeneralComponents',
  components: {
    GeneralModal,
  },
  computed: {
    ...mapState(useGeneralModalsStore, ['modal']),
  },
});
</script>
