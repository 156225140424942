<template>
  <div class="page-header">
    <div
      class="container-xl header-inner d-flex flex-row align-items-sm-center justify-content-between text-light
        py-48 py-md-80 px-16">
      <div class="tour-data text-sm">
        <h2 class="h4 header-title">{{ $t('travelBook.headerTile') }}</h2>

        <div class="d-flex flex-wrap align-items-center mb-4">
          <div v-if="!!tour.name" class="basic-information">{{ tour.name }}</div>

          <span class="dot-divider my-8 my-sm-0"/>

          <div class="locations d-flex align-items-center fw-medium">
            <div v-if="!!tour.origin && !!tour.origin.name">{{ tour.origin.name }}</div>
            <div v-else>{{ $t('traveler.withoutOrigin') }}</div>

            <v-icon icon="arrow-right" size="sm" space="px-8"/>

            <div v-if="!!tour.destination && !!tour.destination.name">{{ tour.destination.name }}</div>
            <div v-else>{{ $t('traveler.withoutDestination') }}</div>
          </div>
        </div>

        <div class="d-flex align-items-center basic-information flex-wrap">
          <div class="dates">
            <template v-if="!!tour.initDate">{{ $str.formatDateTime(tour.initDate, settings.formatDate) }}</template>
            <template v-else>{{ $t('traveler.noStartDate') }}</template>
            -
            <template v-if="!!tour.endDate">{{ $str.formatDateTime(tour.endDate, settings.formatDate) }}</template>
            <template v-else>{{ $t('traveler.noEndDate') }}</template>
          </div>

          <span class="dot-divider my-8 my-sm-0"/>

          <div class="travelers">
            {{ tour.clientTour.paxNumber }}
            <v-icon icon="user-check" size="sm"/>
          </div>

          <span class="dot-divider  my-8 my-sm-0"/>

          <div class="travelers">
            {{ (findConceptByKey('tour.travel_type', tour.travelType)).name }}
            <v-icon icon="run" size="sm"/>
          </div>
        </div>
      </div>

      <div class="header-logo">
        <v-picture class="logo">
          <img
            :alt="$t('general.shared.appLogoAlt')"
            src="@/../resources/img/logo-waw-travel-white.png"
            srcset="@/../resources/img/logo-waw-travel-white.png 2x, @/../resources/img/logo-waw-travel-white.png 1x"/>
        </v-picture>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapState } from 'pinia';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import Tour from '@/api/objects/Tour';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import { useAppStore } from '@/stores/app';

export default defineComponent({
  name: 'VTravelBookPageHeader',
  components: {
    VIcon,
    VPicture,
  },
  props: {
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },
  },
  setup() {
    return {
      findConceptByKey,
    };
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
  },
});
</script>

<style lang="scss" scoped>
.basic-information {
  @media only screen {
    font-weight: 500;

    @media (min-width: 1200px) {
      color: var(--uq-white);
      font-weight: 300;
    }
  }

  @media print {
    color: var(--uq-white);
    font-weight: 500;
  }
}

.page-header {
  background: linear-gradient(122.71deg, rgba(var(--uq-primary-rgb), 1) 31%,
    rgba(var(--uq-secondary-rgb), 1) 150%);

  @media print {
    height: 6.375rem;
  }

  .header-inner {
    position: relative;

    .tour-data {
      z-index: 20;

      @media print {
        font-weight: 500;
      }

      .header-title {
        @media print {
          display: none;
        }
      }
    }

    .header-logo {
      position: absolute;
      right: 50%;
      top: 100%;
      transform: translate(50%, -50%);
      opacity: .1;

      :deep(.logo img) {
        width: 70vw;
        max-width: 992px;
      }
    }
  }

  @media print {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .header-inner {
    @media print {
      padding: 1.75rem 1rem;
      max-width: 58.125rem;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.traveler {
  border-radius: .25rem;
  position: relative;
  box-shadow: 0 .5rem 1.75rem rgba(0, 0, 0, .1);
  margin-bottom: 1rem;
}

.dot-divider {
  background-color: var(--uq-white, #f1c933);
  border-radius: 50%;
  display: inline-flex;
  height: .25rem;
  margin-inline: 1rem;
  width: .25rem;
}
</style>
