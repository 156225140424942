<template>
  <v-modal
    id="pax-update-modal"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('clientTour.traveler.update') }}
      </modal-title>
    </modal-header>

    <modal-body>
      <form id="travelers-update-form">
        <fieldset>
          <legend class="visually-hidden">{{ $t('traveler.travelerData') }}</legend>

          <div class="row">
            <div class="col-12">
              <v-form-input
                id="traveler-create-name"
                v-model="nameLocal"
                :label="`${$t('general.shared.name')}*`"
                :yup-errors-variable="errors.nameLocal"
                autocomplete="off"
                form-type="outline"
                @keyup.enter="updatePax"
              />
            </div>

            <div class="col-12">
              <v-form-input
                id="traveler-create-surname"
                v-model="surnameLocal"
                :label="`${this.$t('general.shared.surname')}*`"
                :yup-errors-variable="errors.surnameLocal"
                autocomplete="off"
                form-type="outline"
                @keyup.enter="updatePax"
              />
            </div>

            <div class="col-12">
              <v-form-input
                id="traveler-create-surname-second"
                v-model="surnameSecondLocal"
                :label="this.$t('general.shared.secondSurname')"
                :yup-errors-variable="errors.surnameSecondLocal"
                autocomplete="off"
                form-type="outline"
                @keyup.enter="updatePax"
              />
            </div>

            <div class="col-12">
              <v-form-input
                id="traveler-create-age"
                v-model="ageLocal"
                :label="this.$t('general.shared.age')"
                :yup-errors-variable="errors.ageLocal"
                autocomplete="off"
                form-type="outline"
                @keyup.enter="updatePax"
              />
            </div>

            <div class="col-12 col-lg-5">
              <v-form
                id="traveler-create-document-type"
                :label="`${this.$t('general.shared.documentType')}*`"
                :yup-errors-variable="errors.documentTypeLocal"
                form-type="outline"
              >
                <v-select
                  id="case-client-create-source-type-select"
                  v-model="documentTypeLocal"
                  :class="{ 'is-invalid': !!errors.documentTypeLocal }"
                  :options="documentTypes"
                  :reduce="returnValue => returnValue.key"
                >
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>

                  <template v-slot:option="option">
                    <span class="text-uppercase">{{ option.label }}</span>
                  </template>

                  <template v-slot:selected-option="option">
                    <span class="text-uppercase">{{ option.label }}</span>
                  </template>
                </v-select>
              </v-form>
            </div>

            <div class="col-12 col-lg-7">
              <v-form-input
                id="traveler-create-document-number"
                v-model="documentNumberLocal"
                :label="`${this.$t('general.shared.documentNumber')}*`"
                :yup-errors-variable="errors.documentNumberLocal"
                autocomplete="off"
                form-type="outline"
                @keyup.enter="updatePax"
              />
            </div>
          </div>
        </fieldset>
      </form>
    </modal-body>

    <modal-footer>
      <v-button
        :disabled="!meta.valid || disableButton || !meta.dirty"
        :is-loading="disableButton"
        class="btn-submit-form btn-icon w-fit align-self-end"
        size="sm"
        variant="primary"
        @click="updatePax"
      >
        {{ $t('general.button.confirm') }}
        <v-icon icon="arrow-right" size="sm" space="ms-12"/>
      </v-button>
    </modal-footer>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VForm, VFormInput } from '@uniqoders/form';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import useConcepts from '@/helpers/Concepts';
import api from '@/api';
import Pax from '@/api/objects/Pax';
import useFormValidation from '@/helpers/form';
import { mapConcepts } from '@/helpers/ConceptHelper';
import ModalFooter from '@/components/vendor/basic/modal/ModalFooter.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';

export default defineComponent({
  name: 'ThePaxUpdateModal',
  components: {
    VIcon,
    VButton,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VFormInput,
    VForm,
    vSelect,
  },
  emits: ['closed', 'reloadPaxs'],
  props: {
    agency: {
      type: String,
      required: true,
    },
    clientTour: {
      type: Number,
      required: true,
    },
    tour: {
      type: Number,
      required: true,
    },
    pax: {
      type: Pax,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      nameLocal: yup.string()
        .required()
        .label(t('general.shared.name')),
      surnameLocal: yup.string()
        .label(t('general.shared.surname')),
      surnameSecondLocal: yup.string()
        .label(t('general.shared.name')),
      ageLocal: yup.number()
        .label(t('general.shared.age')),
      documentTypeLocal: yup.string()
        .nullable()
        .label(t('general.shared.documentType')),
      documentNumberLocal: yup.string()
        .label(t('general.shared.documentNumber')),
    });

    const initialValues = {
      nameLocal: props.pax.name,
      surnameLocal: props.pax.surname1,
      surnameSecondLocal: props.pax.surname2,
      documentTypeLocal: props.pax.documentType,
      documentNumberLocal: props.pax.documentNumber,
      ageLocal: props.pax.age,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: nameLocal } = useField('nameLocal');
    const { value: surnameLocal } = useField('surnameLocal');
    const { value: surnameSecondLocal } = useField('surnameSecondLocal');
    const { value: documentTypeLocal } = useField('documentTypeLocal');
    const { value: documentNumberLocal } = useField('documentNumberLocal');
    const { value: ageLocal } = useField('ageLocal');

    return {
      ...useConcepts(),
      ...form,
      nameLocal,
      surnameLocal,
      surnameSecondLocal,
      documentTypeLocal,
      documentNumberLocal,
      ageLocal
    };
  },
  data() {
    return {
      disableButton: false,
    };
  },
  computed: {
    documentTypes(): any {
      if (!!this.concepts && this.concepts['pax.document_type']) {
        return mapConcepts(this.concepts['pax.document_type']);
      }

      return [];
    },
  },
  methods: {
    async updatePax() {
      try {
        this.disableButton = true;

        const data: Record<string, any> = {
          name: this.nameLocal,
          surname_1: this.surnameLocal,
          surname_2: this.surnameSecondLocal,
          document_type: this.documentTypeLocal,
          document_number: this.documentNumberLocal,
          age: this.ageLocal
        };

        await api.pax.update(this.agency, this.clientTour, this.tour, this.pax.id, data);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadPaxs');
        this.$emit('closed');
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableButton = false;
      }
    },
  },
});
</script>
