import { AbstractService } from '@uniqoders/sdk';
import TourDayItem from '@/api/objects/TourDayItem';
import TourDay from '@/api/objects/TourDay';

export default class TourDayItemService extends AbstractService {
  /**
   * Retrieves a specific Tour Day Item
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @protected
   */
  public retrieve(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: number,
    tourDayItem: number): Promise<TourDayItem> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}',
        {
          agency,
          clientTour,
          tour,
          tourDay,
          tourDayItem,
        }),
    });
  }

  /**
   * Creates a single Tour Day Item
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param data
   */
  public create(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: number,
    data: Record<string, any>): Promise<TourDayItem> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item', {
        agency,
        clientTour,
        tour,
        tourDay,
      }),
      data,
    });
  }

  /**
   * Updates the specified Tour Day Item
   * @param agency
   * @param clientTour
   * @param tour
   * @param oldTourDay
   * @param tourDayItem
   * @param newTourDay
   * @protected
   */
  public update(
    agency: string,
    clientTour: number,
    tour: number,
    oldTourDay: number,
    tourDayItem: number,
    newTourDay: number): Promise<TourDayItem> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day/{oldTourDay}/item/{tourDayItem}/move/{newTourDay}', {
        agency,
        clientTour,
        tour,
        oldTourDay,
        tourDayItem,
        newTourDay,
      }),
    });
  }

  /**
   * Deletes the specified Tour Day Item
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   */
  public delete(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: number,
    tourDayItem: number): Promise<TourDayItem> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}', {
        agency,
        clientTour,
        tour,
        tourDay,
        tourDayItem,
      }),
    });
  }

  /**
   * Changes the position of the specified Tour Day Item
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param tourDayItem
   * @param data
   */
  public movePosition(
    agency: string,
    clientTour: number,
    tour: number,
    tourDay: number,
    tourDayItem: number,
    data: Record<string, number>): Promise<TourDay> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}/item/{tourDayItem}/position', {
        agency,
        clientTour,
        tour,
        tourDay,
        tourDayItem,
      }),
      data,
    });
  }
}
