export default {
  statuses: 'Statuses',
  bookingStatus: 'Booking Status',
  paymentStatus: 'Payment Status',
  splitPayment: 'Split Payment',
  invoiceStatus: 'Invoice Status',
  status: 'status',
  dates: 'Dates',
  noBookings: 'Resources have not yet been added to the Tour, so Tour statuses cannot be updated.',
  selectResource: 'Select resource',
  sendNotification: 'Send notification',
  notification: 'Notification',
  notificationChannel: 'Notification Channel',
  notificationEmail: 'Notification Email',
  noResourcesSelectedMessage: 'Select resources to send the notifications',
  supplierNoEmail: 'No email',
  emailModal: {
    title: 'Send email to suppliers',
    text: 'Are you sure you want to send the email to suppliers?',
  },
  teamName: 'Team name',
  roomType: 'Room type',
  quantity: 'Quantity',
  pax: 'Pax',
  noBookingsFound: 'No bookings found',
  caseName:  'Case name',
  clientName:  'Client name',
  journeyRoute:  'Journey route',
  product:  'Product',
};
