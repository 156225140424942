<template>
  <div>
    <fieldset v-if="language === currentLanguage">
      <legend class="visually-hidden">{{ $t('resource.basicInformation') }}</legend>

      <v-form-input
        id="resource-create-title"
        v-model="titleLocal"
        :disabled="disabledFields"
        :label="`${this.$t('general.shared.title')}*`"
        :yup-errors-variable="errors.titleLocal"
        autocomplete="off"
        form-type="outline"
        @input="$emit('update:title', titleLocal)"
      />

      <div class="form-outline">
        <label :class="{ disabled: disabledFields }" class="label">
          <span>{{ $t('general.shared.description') }}</span>
        </label>

        <v-html-editor
          id="resource-create-description"
          ref="resource-create-description-ref"
          :disabled="disabledFields"
          :value="descriptionLocal"
          @input="descriptionLocal = $event; $emit('update:description', descriptionLocal)"
          @media-uploaded="$emit('mediaUploaded', $event)"
        />
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import useFormValidation from '@/helpers/form';
import useConcepts from '@/helpers/Concepts';
import VHtmlEditor from '@/components/vendor/htmlEditor/VHtmlEditor.vue';

export default defineComponent({
  name: 'TheResourceInformationCreateBasicInformationFieldset',
  components: {
    VFormInput,
    VHtmlEditor,
  },
  emits: [
    'closed',
    'reloadResources',
    'update:title',
    'update:description',
    'isValid',
    'mediaUploaded',
  ],
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    disabledFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    language: {
      type: String,
      required: true,
      default: '',
    },
    currentLanguage: {
      type: String,
      required: true,
      default: '',
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      titleLocal: yup.string()
        .required()
        .label(t('general.shared.title')),
      descriptionLocal: yup.string()
        .nullable()
        .label(t('general.shared.description')),
    });

    const initialValues = {
      titleLocal: props.title,
      descriptionLocal: props.description,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: titleLocal } = useField('titleLocal');
    const { value: descriptionLocal } = useField('descriptionLocal');

    return {
      ...useConcepts(),
      ...form,
      titleLocal,
      descriptionLocal,
    };
  },
  computed: {
    isValid() {
      return this.meta.valid && this.meta.dirty;
    },
  },
  watch: {
    title(value) {
      this.titleLocal = value;
    },
    description(value) {
      this.descriptionLocal = value;
    },
    clear() {
      this.resetForm();
    },
    isValid(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('isValid', newValue);
      }
    },
  },
});
</script>
