<template>
  <v-off-canvas
    id="supplier-filters-offcanvas"
    :backdrop-static="false"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
  <resizable-container>
    <off-canvas-header>
      <h5 class="offcanvas-title">{{ $t('general.shared.filters') }}</h5>
    </off-canvas-header>

    <off-canvas-body>
      <v-form
        id="supplier-status-select"
        :label="this.$t('general.shared.status')"
        form-type="outline"
      >
        <v-select
          v-model="filterStatus"
          :clearable="false"
          :options="statusTypesOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:status', filterStatus); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-form
        id="supplier-filter-type-select"
        :label="this.$t('general.shared.type')"
        form-type="outline"
      >
        <v-select
          v-model="filterType"
          :clearable="false"
          :options="supplierTypeOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:type', filterType); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-form
        id="supplier-amenities-select"
        :label="this.$t('general.shared.amenities')"
        form-type="outline"
      >
        <v-select
          v-model="filterAmenities"
          :multiple="true"
          :options="supplierAmenitiesOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:amenities', filterAmenities); $emit('selected')"
          @option:deselected="$emit('update:amenities', filterAmenities); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-form-input
        id="supplier-location-select"
        v-model="filterLocation"
        :label="this.$t('general.shared.location')"
        autocomplete="off"
        form-type="outline"
        @update:modelValue="$emit('update:location', filterLocation); $emit('selected')"
      />
    </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import { VForm, VFormInput } from '@uniqoders/form';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import { mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheSupplierFiltersOffcanvas',
  components: {
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    vSelect,
    VForm,
    VFormInput,
    ResizableContainer,
  },
  emits: [
    'closed',
    'selected',
    'update:amenities',
    'update:location',
    'update:name',
    'update:status',
    'update:type',
  ],
  props: {
    amenities: {
      type: Array,
      required: false,
    },
    location: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    status: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    return {
      ...useConcepts(),
    };
  },
  data() {
    return {
      timer: '' as any,
      filterAmenities: this.amenities,
      filterLocation: this.location,
      filterName: this.name,
      filterStatus: this.status,
      filterType: this.type,
    };
  },
  computed: {
    statusTypesOptions(): any {
      if (!!this.concepts && this.concepts['app.states']) {
        return mapConcepts(this.concepts['app.states']);
      }

      return [];
    },
    supplierTypeOptions(): any {
      if (!!this.concepts && this.concepts['supplier.type']) {
        const supplierTypes = mapConcepts(this.concepts['supplier.type']);

        supplierTypes.unshift({
          key: '',
          label: this.$t('general.shared.all'),
          permission: null,
          value: null,
          order: 0,
          status: 'active',
          icon: '',
          color: '',
        });

        return supplierTypes;
      }

      return [];
    },
    supplierAmenitiesOptions(): any {
      if (!!this.concepts && this.concepts['supplier.amenities']) {
        return mapConcepts(this.concepts['supplier.amenities']);
      }

      return [];
    },
  },
  watch: {
    amenities(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterAmenities = this.amenities;
      }
    },
    location(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterLocation = this.location;
      }
    },
    name(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterName = this.name;
      }
    },
    status(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterStatus = this.status;
      }
    },
    type(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterType = this.type;
      }
    },
  },
});
</script>
