export default {
  headerTile: 'Everything you need to know about your trip',
  contact: 'Contact',
  noContacts: 'There are no contacts',
  tourResume: 'Tour resume',
  extraField: 'Extras',
  day: 'Day',
  tour: 'Tour',
  flight: 'Flight',
  transfer: 'Transfer',
  transportLuggage: 'Transport of your luggage',
  stageNumOriginDestination: 'Stage: { origin } - { destination }',
  distanceNumberKm: 'Distance: { number } Km',
  notSelectTransfer: 'You have not selected a transfer yet',
  notSelectTransport: 'You have not selected a transport yet',
  accommodation: 'Accommodation',
  information: 'Information',
  quantityNights: 'No nights | One night | { quantity } nights',
  totalPrice: 'Total Price',
  whatToDoLocation: 'What to do in { location }',
  urlToLocationMap: 'Url to { location } map',
  urlToLocationWeb: 'Url to { location } website',
  website: 'Website',
  map: 'Map',
  linkAboutDay: 'Link about the day',
  guidebookLocation: 'Guidebook: { location }',
  viewWebsite: 'View website',
  viewGoogleMaps: 'View in Google Maps',
  stageInformation: 'Stage information',
  dayInformation: 'Day information',
  guidebookOfLocation: 'Guidebook of { location }',
  linkGoogleMapsResource: 'Link to Google Maps of { resource }',
  linkResourceWebsite: 'Link to { resource } website',
  address: 'Address',
  noResourcesLocation: 'No resources have been added to this location',
  viewWebsiteOfSupplier: 'View website of { supplier }',
  urlGoogleMapsOfSupplier: 'Url to Google Maps of { supplier }',
  travelInformation: 'Travel information',
  pricePerPerson: 'Price per person',
  downloadErrorText: 'An error occurred while downloading',
  pricePerUnit: '€/unit',
};
