<template>
  <div id="accordionSupplierNotificationLog" class="accordion">
    <div class="case-details accordion-item">
      <h2 id="headingNotificationLog" class="accordion-header">
        <button aria-controls="collapseNotificationLog" aria-expanded="true" class="accordion-button collapsed"
          data-bs-target="#collapseNotificationLog" data-bs-toggle="collapse" type="button">
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="description" size="xxl" space="p-12" />
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t('clientTour.notificationLog') }}</div>
              <div class="fw-light accordion-subtitle">{{ $t('clientTour.notificationLogSubtitle') }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div id="collapseNotificationLog" aria-labelledby="headingNotificationLog"
        class="accordion-collapse collapse show" data-bs-parent="#accordionSupplierNotificationLog">
        <div class="accordion-body pb-32 pb-xl-48">
          <div class="d-flex flex-column text-sm">
            <div class="table-notifications table-responsive d-none d-md-flex d-lg-none d-xl-block pe-8">
              <v-table class="mb-0">
                <v-table-header>
                  <tr class="text-normal">
                    <th>
                      {{ $t('general.shared.type') }}
                    </th>

                    <th>
                      {{ $t('general.shared.date') }}
                    </th>

                    <th>
                      {{ $t('general.shared.status') }}
                    </th>
                  </tr>
                </v-table-header>

                <v-table-body class="fw-medium">
                  <tr v-if="!notificationsLoaded">
                    <td class="position-relative border-0 py-96" colspan="2222">
                      <v-loader />
                    </td>
                  </tr>

                  <template v-else-if="!!notifications && notifications.length > 0">
                    <tr v-for="notification in notifications" :key="notification.id">
                      <td class="text-nowrap">
                        {{ findConceptByKey('supplier.email_notification_types', notification.type).name }}

                        <span v-if="!!notification.tourDayItemResource && !!notification.tourDayItemResource.resource">
                          ({{ notification.tourDayItemResource.resource.supplier.name }})
                        </span>

                        <span v-else>(-)</span>
                      </td>

                      <td class="text-nowrap">
                        {{ $str.formatDateTime(notification.sentAt, settings.formatDatetime) }}
                      </td>

                      <td class="text-nowrap">
                        {{ findConceptByKey('notification.status', notification.status).name }}
                      </td>
                    </tr>
                  </template>

                  <template v-else>
                    <tr>
                      <td class="rounded-xs py-16" colspan="2222">
                        {{ $t('clientTour.noSuppliersNotifications') }}
                      </td>
                    </tr>
                  </template>
                </v-table-body>
              </v-table>
            </div>

            <div class="item-cards d-block d-md-none d-lg-block d-xl-none">
              <div v-if="!notificationsLoaded" class="position-relative py-96">
                <v-loader />
              </div>

              <template v-else-if="!!notifications && notifications.length > 0">
                <div v-for="notification in notifications" :key="notification.id"
                  class="item-card border rounded-xs p-8 mb-12">
                  <div>
                    <span class="fw-medium">{{ $t('general.shared.type') }}: </span>

                    <span>
                      {{ findConceptByKey('supplier.email_notification_types', notification.type).name }}
                    </span>
                  </div>

                  <div>
                    <span class="fw-medium">{{ $t('general.shared.date') }}: </span>
                    <span>{{ $str.formatDateTime(notification.sentAt, settings.formatDatetime) }}</span>
                  </div>

                  <div>
                    <span class="fw-medium">{{ $t('general.shared.status') }}: </span>
                    <span>{{ findConceptByKey('notification.status', notification.status).name }}</span>
                  </div>
                </div>
              </template>

              <div v-else>
                {{ $t('clientTour.noSuppliersNotifications') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import { useAppStore } from '@/stores/app';
import Notification from '@/api/objects/Notification';

export default defineComponent({
  name: 'TheCaseSetupNotificationLog',
  components: {
    VLoader,
    VTableBody,
    VTableHeader,
    VTable,
    VIcon,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    tour: {
      type: Number,
      required: true,
    },
    clientTour: {
      type: Number,
      required: true,
    },
    
  },
  setup() {
    return {
      findConceptByKey,
    };
  },
  data() {
    return {
      notifications: [] as Notification[],
      notificationsLoaded: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
  },
  async created() {
    await this.loadTouNotifications();
  },
  methods: {
    async loadTouNotifications() {
      try {
        this.notifications = await api.notification.all(this.agency,
          this.clientTour, this.tour, 'supplier');
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.notificationsLoaded = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.table-notifications {
  overflow: auto;
  max-height: 23rem;

  thead th {
    background-color: var(--uq-body-bg);
  }
}

.table-notifications thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
