<template>
  <div :class="cVariant" :role="role" class="alert d-flex">
    <span :class="[iconClass, cIconVariant]" class="icon main-icon text-md"/>

    <div class="text">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VAlert',
  props: {
    iconClass: {
      type: String,
      required: false,
      default: '',
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    iconVariant: {
      type: String,
      required: false,
      default: null,
    },
    role: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    // TODO debe ser compatible con purgecss
    cVariant() {
      let ret = null;

      if (!!this.variant) {
        ret = `alert-${this.variant}`;
      }

      return ret;
    },
    cIconVariant() {
      let ret = null;

      if (!!this.iconVariant) {
        ret = `icon-${this.iconVariant}`;
      }

      return ret;
    },
  },
});
</script>
