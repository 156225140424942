<template>
  <div :id="`accordion-tour-day-${id}`" class="day accordion">
    <div :id="`tour-day-head-${id}`" class="day-simple-data cursor-pointer break-avoid">
      <div
        :aria-controls="`collapse-tour-day-${id}`"
        :data-bs-target="`#collapse-tour-day-${id}`"
        aria-expanded="true"
        class="accordion-button bg-transparent p-0 gap-12"
        data-bs-toggle="collapse"
      >
        <div>
          <h4 class="h6 mb-12 fw-semi">
            {{ $t('travelBook.day') }} {{ index + 1 }}
            <span class="fw-medium text-normal text-sm">({{ $str.formatDateTime(day.date, 'dd MMMM yyyy') }})</span>
          </h4>

          <template v-if="day.type === 'stage'">
            <div class="fw-medium text-sm mb-4">
              {{
                $t('travelBook.stageNumOriginDestination', {
                  origin: !!day.origin ? day.origin.name : $t('traveler.withoutOrigin'),
                  destination: !!day.destination ? day.destination.name : $t('traveler.withoutDestination'),
                })
              }}
            </div>

            <!-- <div class="text-sm mb-16">
              {{
                $t('travelBook.distanceNumberKm', {
                  number: !!day.stageLength ? day.stageLength : 0,
                })
              }}
            </div> -->
          </template>
        </div>
      </div>
    </div>

    <div
      :id="`collapse-tour-day-${id}`"
      :aria-labelledby="`tour-day-head-${id}`"
      :data-bs-parent="`#accordion-tour-day-${id}`"
      class="accordion-collapse collapse show">

      <!-- TOUR DAY DESCRIPTION -->
      <!-- <v-travel-book-information
        :id="`tour-day-${id}-information`"
        :destination="day.destination?.name"
        :images="day.media"
        :index="index"
        :is-stage="day.type === 'stage'"
        :location="day.origin?.name"
        :message="day.description"
        :origin="day.origin?.name"
        :tour-day="day"
        :url="day.url"
        title-icon="marker"
      /> -->

      <!-- ITEMS -->
      <template v-if="!!day.items && day.items.length > 0">
        <template v-for="(dayItem, dayItemIndex) in sortedDayItems" :key="dayItem.id">
          <v-travel-book-information
            :id="`tour-day-${id}-item-${dayItem.id}`"
            :day-item="dayItem"
            :index="dayItemIndex"
            :message="getSupplierDescription(dayItem)"
            :parse-images="true"
            :resources="dayItem.resources"
            :show-data-item="true"
            :supplier-name="!!dayItem.supplierName ? dayItem.supplierName : $t('general.shared.withoutSupplier')"
            :title="getDayItemType(dayItem.type)"
            :title-icon="findConceptByKey('supplier.type', dayItem.type).icon"
            :tour-day="day"
          />
        </template>
      </template>

      <!-- LOCATION INFORMATION -->
      <template v-for="(resourceInformation) in getTourDayLocation(day)" :key="resourceInformation.id">
        <v-travel-book-information
          :id="`tour-day-${id}-location-${resourceInformation.id}`"
          :images="resourceInformation.media"
          :index="index"
          :is-location-info="true"
          :location="day.type === 'stage' ? day.destination.name : day.origin.name"
          :location-id="day.type === 'stage' ? day.destination.id : day.origin.id"
          :map-url="resourceInformation.mapUrl"
          :message="resourceInformation.description"
          :site-url="resourceInformation.siteUrl"
          :tour-day="day"
          title-icon="marker"
        />
      </template>
    </div>

    <div class="day-divider-container">
      <span class="day-divider"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TourDay from '@/api/objects/TourDay';
import VTravelBookInformation from '@/components/tour/book/parts/VTravelBookInformation.vue';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import TourDayItem from '@/api/objects/TourDayItem';
import Supplier from '@/api/objects/Supplier';

export default defineComponent({
  name: 'TheTravelBookDay',
  components: {
    VTravelBookInformation,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    day: {
      type: TourDay,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      findConceptByKey,
    };
  },
  data() {
    return {
      supplier: {} as Supplier,
      collapse: false,
    };
  },
  computed: {
    sortedDayItems() {
      return this.day.items.slice().sort((a, b) => a.position - b.position);
    },
  },
  methods: {
    getTourDayLocation(tourDay: TourDay): any[] {
      if (tourDay.type === 'stage') {
        if (!!tourDay.destination && !!tourDay.destination.resources && tourDay.destination.resources.length > 0) {
          return tourDay.destination.resources;
        }
      } else if (!!tourDay.origin && !!tourDay.origin.resources && tourDay.origin.resources.length > 0) {
        return tourDay.origin.resources;
      }

      return [];
    },
    getSupplierDescription(dayItem: TourDayItem): string {
      if (!!dayItem.resources && dayItem.resources.length > 0) {
        const resourceAux = dayItem.resources[0];

        if (!!resourceAux.resource) {
          return resourceAux?.resource?.supplier?.description;
        }
      }

      return '';
    },
    getDayItemType(type: string): string {
      if (type === 'hotel') {
        return this.$t('travelBook.accommodation');
      }
      return findConceptByKey('supplier.type', type).name;
    },
  },
});
</script>

<style lang="scss" scoped>
.break-avoid {
  break-inside: avoid;
}

.hide-print {
  @media print {
    display: none;
  }
}

.hide-screen {
  @media only screen {
    display: none;
  }
}

.accordion-button {
  @media print {
    &:after {
      display: none;
    }
  }
}

.information-container-summary {
  border-radius: .5rem;
  box-shadow: 0 1px .25rem var(--uq-gray-300);

  @media print {
    box-shadow: none !important;
  }
}

.flight-container {
  border-radius: .5rem;
  box-shadow: 0 1px .25rem var(--uq-gray-300);
  background-color: var(--uq-white);
}

.day-divider-container {
  padding-top: 1rem;
  padding-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .day-divider {
    position: relative;
    background-color: var(--uq-secondary);
    border-radius: 50%;
    display: inline-flex;
    height: .4rem;
    width: .4rem;

    &:before {
      position: absolute;
      height: .4rem;
      width: .4rem;
      content: '';
      left: -1.5rem;
      background-color: var(--uq-tertiary);
      border-radius: 50%;
    }

    &:after {
      position: absolute;
      height: .4rem;
      width: .4rem;
      content: '';
      right: -1.5rem;
      background-color: var(--uq-primary);
      border-radius: 50%;
    }
  }
}
</style>
