<template>
  <table class="table">
    <slot/>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VTable',
});
</script>
