export default {
  clients: 'Clients',
  noClients: 'There are no clients',
  createNewClient: 'Create new client',
  archivedClients: 'Archived clients',
  activeClients: 'Active clients',
  basicInformation: 'Basic information',
  clientDetails: 'Client details',
  archiveText: 'You can archive a client, if you do this the client will not appear in the application searches. You will be able to see it in the list of clients if you filter by \'archived\'. Once you view the details of an archived client, you can delete it definitively or you can unarchive it.',
  media: 'Media',
  noMedia: 'There are no associated media',
  archivedText: 'This client is archived, if you want you can unarchive it by clicking on the \'Restore\' button.',
  archiveClientTitle: 'Archive client',
  archiveClientText: 'Are you sure you want to archive the client \'{client}\'? If you archive it, it will not appear in searches.',
  deleteText: 'This client is archived, if you want to delete it permanently click the \'Delete\' button. Note that if you delete it, this client will no longer exist and cannot be recovered.',
  deleteClientTitle: 'Delete client',
  deleteClientText: 'Are you sure you want to delete the client \'{client}\'? If you delete it, you will not be able to recover it.',
  restoreClientTitle: 'Recover client',
  restoreClientText: 'Are you sure you want to recover the client \'{client}\'? If you recover it, it will no longer be archived and will appear in the application searches.',
  clientStatus: 'Client Status',
  contacts: 'Contacts',
  noContacts: 'There are no associated contacts',
  editContactsBelongClientText: 'Edit contacts belonging to the client',
  addContacts: 'Add contacts',
  removeContactTitle: 'Remove client contact',
  removeContactText: 'Are you sure you want to remove {contact} as a contact for the client {client}?',
  cases: 'Cases',
  casesText: 'List of cases that belong to the client',
  seeCases: 'See cases',
};
