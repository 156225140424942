export default {
  suppliers: 'Leveranciers',
  noSuppliers: 'Er zijn geen leveranciers',
  createNewSupplier: 'Nieuwe leverancier aanmaken',
  archivedSuppliers: 'Gearchiveerde leveranciers',
  activeSuppliers: 'Actieve leveranciers',
  basicInformation: 'Basisinformatie',
  supplierDetails: 'Leveranciersgegevens',
  archiveText:
    "U kunt een leverancier archiveren, als u dit doet zal de leverancier niet verschijnen in de zoekresultaten van de applicatie. U kunt het bekijken in de lijst met leveranciers als u filtert op 'gearchiveerd'. Nadat u de details van een gearchiveerde leverancier heeft bekeken, kunt u deze definitief verwijderen of opnieuw archiveren.",
  media: 'Media',
  noMedia: 'Er zijn geen bijbehorende media',
  archivedText:
    "Deze leverancier is gearchiveerd, als u wilt kunt u deze opnieuw activeren door op de knop 'Herstellen' te klikken.",
  archiveSupplierTitle: 'Leverancier archiveren',
  archiveSupplierText:
    "Weet u zeker dat u de leverancier '{supplier}' wilt archiveren? Als u deze archiveert, zal deze niet meer in de zoekresultaten verschijnen.",
  deleteText:
    "Deze leverancier is gearchiveerd, als u deze definitief wilt verwijderen, klikt u op de knop 'Verwijderen'. Let op: als u deze verwijdert, zal deze leverancier niet meer bestaan en kan deze niet meer worden hersteld.",
  deleteSupplierTitle: 'Leverancier verwijderen',
  deleteSupplierText:
    "Weet u zeker dat u de leverancier '{supplier}' wilt verwijderen? Als u deze verwijdert, kunt u deze niet meer herstellen.",
  restoreSupplierTitle: 'Leverancier herstellen',
  restoreSupplierText:
    "Weet u zeker dat u de leverancier '{supplier}' wilt herstellen? Als u deze herstelt, zal deze niet langer gearchiveerd zijn en zal deze weer verschijnen in de zoekresultaten van de applicatie.",
  supplierStatus: 'Leveranciersstatus',
  products: 'Producten',
  noProducts: 'Er zijn geen bijbehorende producten',
  editProductsBelongSupplierText: 'Producten bewerken die bij de leverancier horen',
  contacts: 'Contactpersonen',
  noContacts: 'Er zijn geen bijbehorende contactpersonen',
  editContactsBelongSupplierText: 'Contactpersonen bewerken die bij de leverancier horen',
  addContacts: 'Contactpersonen toevoegen',
  removeContactTitle: 'Verwijder leverancierscontact',
  removeContactText:
    "Weet je zeker dat je '{contact}' wilt verwijderen als contactpersoon voor leverancier '{supplier}'?",
  supplierSearch: 'Leveranciers zoeken',
  removeMediaTitle: 'Media verwijderen',
  removeMediaText:
    'Weet je zeker dat je het archief wilt verwijderen? Als je het verwijdert, kun je het niet meer herstellen.',
  singleRoom: 'Single B&B',
  doubleRoom: 'Double B&B',
  doubleTwinRoom: 'Double Twin B&B',
  tripleRoom: 'Triple B&B',
  bunkbedHBRoom: 'Stapelbed HB',
  bunkbedFBRoom: 'Stapelbed FB',
  singleRoomFullName: 'Eenpersoonskamer B&B',
  doubleRoomFullName: 'Tweepersoonskamer 1 bed B&B',
  doubleTwinRoomFullName: 'Tweepersoonskamer 2 bedden B&B',
  tripleRoomFullName: 'Driepersoonskamer B&B',
  bunkbedHBRoomFullName: 'Stapelbed in gedeelde kamer Halfpension',
  bunkbedFBRoomFullName: 'Stapelbed in gedeelde kamer volpension',
  luggageTransport: 'Bagagevervoer',
  busTicket: 'Bus Ticket',
  boatTicket: 'Boot Ticket',
  trainTicket: 'Trein Ticket',
  transfer: 'Transfer',
  exportErrorToast: 'U heeft geen toestemming om leveranciers te exporteren. Neem contact op met uw beheerder',
  resource: {
    createNew: 'Nieuw product aanmaken',
    basicInformation: 'Basisinformatie',
    title: 'Productdetails',
    archiveTitle: 'Leveranciersproduct archiveren',
    archiveText:
      "Weet je zeker dat je het product '{resource}' van '{supplier}' wilt archiveren? Als je het archiveert, verschijnt het niet meer in zoekopdrachten.",
    media: 'Media',
    noMedia: 'Er zijn geen gerelateerde media',
    removeMediaTitle: 'Media verwijderen',
    removeMediaText:
      'Weet je zeker dat je het archief wilt verwijderen? Als je het verwijdert, kun je het niet meer herstellen.',
  },
  rates: 'Tarieven',
  rate: {
    description: "Voeg hier de manieren toe waarop dit product kan worden verkocht.",
    descriptionOnlyView: "De manieren waarop dit product kan worden verkocht",
    create: "Tarief aanmaken",
    update: "Tarief bijwerken",
    delete: "Tarief verwijderen",
    duplicate: "Dubbel tarief",
    duplicateBlock: "Duplicaat",
    basicInformation: "Basisinformatie",
    typeNoOptions: "Geen beschikbare typen",
    categoryNoOptions: "Geen categorie beschikbaar",
    product: "Product",
    type: "Type",
    category: "Categorie",
    initDate: "Startdatum",
    endDate: "Einddatum",
    price: "Prijs",
    removeTitle: "Tarief verwijderen",
    singleRate: "U dupliceert het volgende tarief",
    blockRate: "Je dupliceert het volgende blok",
    removeText:
      "Weet u zeker dat u het tarief van product '{product}' wilt verwijderen? Als u dit doet, kunt u het niet meer herstellen.",
  },
};
