import { AbstractService } from '@uniqoders/sdk';
import Resource from '@/api/objects/Resource';

export default class ResourceService extends AbstractService {
  /**
   * Retrieves all Resources
   * @protected
   * @param params
   */
  public all(params: any): Promise<Resource[]> {
    return this.request({
      method: 'get',
      url: this.buildPath('/resources'),
      params,
    });
  }

  /**
   * Retrieves all Resources paginated
   * @protected
   * @param params
   * @param paginate
   */
  public allPaginated(params?: any, paginate = true): Promise<any> {
    return this.request({
      method: 'get',
      url: this.buildPath('/resources'),
      params,
      options: {
        paginate,
      }
    });
  }

  /**
   * Retrieves a specific Resource
   * @protected
   */
  public retrieve(resource: string | number): Promise<Resource> {
    return this.request({
      method: 'get',
      url: this.buildPath('/resource/{resource}', { resource }),
    });
  }

  /**
   * Creates a single Resource
   * @protected
   * @param data
   */
  public create(data: Record<string, any>): Promise<Resource> {
    return this.request({
      method: 'post',
      url: this.buildPath('/resource'),
      data,
    });
  }

  /**
   * Updates the specified Resource
   * @param resource
   * @param data
   * @protected
   */
  public update(resource: string | number, data: Record<string, any>): Promise<Resource> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/resource/{resource}', { resource }),
      data,
    });
  }

  /**
   * Archives the specified Resource
   * @protected
   * @param resource
   */
  public archive(resource: string | number): Promise<Resource> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/resource/{resource}', { resource }),
    });
  }

  /**
   * Restore the specified Resource
   * @protected
   * @param resource
   */
  public restore(resource: string | number): Promise<Resource> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/resource/{resource}/restore', { resource }),
    });
  }

  /**
   * Deletes the specified Resource
   * @protected
   * @param resource
   */
  public delete(resource: string | number): Promise<Resource> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/resource/{resource}/force', { resource }),
    });
  }
}
