<template>
  <div class="detail">
    <div class="detail-title d-flex justify-content-between mb-20 align-items-center">
      <div class="fw-medium">{{ $t('supplier.media') }}</div>

      <v-button
        class="btn-edit p-4"
        variant="icon"
        @click="openModal('the-supplier-details-media-modal')">
        <v-icon icon="circle-plus"/>
      </v-button>
    </div>

    <div class="detail-content">
      <div class="supplier-media-container">
        <div v-if="!supplier.media || supplier.media.length === 0">
          {{ $t('supplier.noMedia') }}
        </div>

        <div class="media-container">
          <div class="row gx-8 gy-8">
            <div
              v-for="media in supplier.media"
              :key="media.id"
              class="col-4 col-lg-3"
            >
              <div class="media position-relative">
                <v-picture v-if="!!media.storageUrl">
                  <img
                    :alt="media.fileName"
                    :src="media.storageUrl"
                    class="rounded-sm"/>
                </v-picture>

                <div v-else>
                  <div>{{ media.fileName }}</div>
                </div>

                <div class="media-actions position-absolute top-0 end-0">
                  <v-button
                    :disabled="disableRemoveButton"
                    class="btn-trash btn-primary fw-normal me-4"
                    variant="icon"
                    @click="removeMedia(media.id)"
                  >
                    <v-icon icon="trash" size="md"/>
                  </v-button>

                  <v-button
                    :disabled="disableRemoveButton"
                    class="btn-trash btn-primary fw-normal"
                    variant="icon"
                    @click="showImage(media)"
                  >
                    <v-icon icon="search" size="md"/>
                  </v-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <the-supplier-details-media-modal
      v-if="modalToShow === 'the-supplier-details-media-modal'"
      :supplier="supplier"
      @closed="closeModal"
      @emit-reload-supplier="$emit('reloadSupplier')"
    />

    <v-show-media-modal
      v-if="modalToShow === 'v-show-media-modal' && !!currentMedia"
      :media="currentMedia"
      @closed="closeModal(); currentMedia = null"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useModalUtils from '@/helpers/ModalUtils';
import api from '@/api';
import VPicture from '@/components/vendor/basic/picture/VPicture.vue';
import Supplier from '@/api/objects/Supplier';
import TheSupplierDetailsMediaModal from '@/components/supplier/parts/TheSupplierDetailsMediaModal.vue';
import Media from '@/api/objects/Media';
import VShowMediaModal from '@/components/shared/VShowMediaModal.vue';

export default defineComponent({
  name: 'TheSupplierDetailsMedia',
  components: {
    VShowMediaModal,
    TheSupplierDetailsMediaModal,
    VPicture,
    VIcon,
    VButton,
  },
  emits: ['closed', 'reloadSupplier'],
  props: {
    supplier: {
      type: Supplier,
      required: true,
    },
  },
  setup() {
    return {
      ...useModalUtils(),
    };
  },
  data() {
    return {
      disableArchiveButton: false as boolean,
      disableRemoveButton: false as boolean,
      currentMedia: null as Media | null,
    };
  },
  methods: {
    showImage(media: Media) {
      this.currentMedia = media;

      this.openModal('v-show-media-modal');
    },
    async removeMedia(mediaId: string | number) {
      await this.$modal.delete({
        title: this.$t('supplier.removeMediaTitle'),
        text: this.$t('supplier.removeMediaText'),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doRemoveMedia(mediaId),
      });
    },
    async doRemoveMedia(contactId: string | number) {
      try {
        this.disableRemoveButton = true;

        await api.media.delete('s3', contactId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.disableRemoveButton = false;

        this.$emit('reloadSupplier');
      } catch (e: any) {
        this.disableRemoveButton = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
  },
});
</script>
