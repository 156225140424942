<template>
  <div class="item-list">
    <v-dropdown
      id="add-dropdown"
      :disable-icon="true"
      button-classes="btn-gradient fw-bold justify-content-center py-12 px-28"
      class="me-md-24"
      dropdown-classes="add-dropdown dropdown-menu-end"
    >
      <template #button>
        <v-icon icon="plus" space="me-12"/>
        {{ $t('general.button.new') }}
      </template>

      <template #content>
        <li v-if="can('user.store')" class="dropdown-list p-0">
          <a
            id="create-user"
            :aria-label="this.$t('general.shared.user')"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="$emit('emitCloseOffCanvas'); openOffCanvas('the-admin-user-create-offcanvas')"
          >
            <!-- TODO: Gestionar todos con el can() -->
            <v-icon class="text-secondary" icon="group" size="xxl" space="me-8"/>
            <span>{{ $t('general.shared.user') }}</span>
          </a>
        </li>

        <li v-if="can('agency.store')" class="dropdown-list p-0">
          <a
            id="create-agency"
            :aria-label="this.$t('general.shared.agency')"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="$emit('emitCloseOffCanvas'); openOffCanvas('the-admin-agency-create-offcanvas')"
          >
            <v-icon class="text-secondary" icon="shop" size="xxl" space="me-12"/>
            <span>{{ $t('general.shared.agency') }}</span>
          </a>
        </li>

        <li v-if="can('agency.store')" class="dropdown-list p-0">
          <a
            id="create-collaborator"
            :aria-label="this.$t('general.shared.collaborator')"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="$emit('emitCloseOffCanvas'); openOffCanvas('the-admin-collaborator-create-offcanvas')"
          >
            <v-icon class="text-secondary" icon="user" size="xxl" space="me-12"/>
            <span>{{ $t('general.shared.collaborator') }}</span>
          </a>
        </li>

        <li class="dropdown-list p-0">
          <a
            id="create-client"
            :aria-label="this.$t('general.shared.client')"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="$emit('emitCloseOffCanvas'); openOffCanvas('the-client-create-offcanvas')"
          >
            <v-icon class="text-secondary" icon="group" size="xxl" space="me-12"/>
            <span>{{ $t('general.shared.client') }}</span>
          </a>
        </li>

        <li class="dropdown-list p-0">
          <a
            id="create-resource"
            :aria-label="this.$t('general.shared.resource')"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="$emit('emitCloseOffCanvas'); openOffCanvas('the-resource-create-offcanvas')"
          >
            <v-icon class="text-secondary" icon="send-love" size="xxl" space="me-12"/>
            <span>{{ $t('general.shared.resource') }}</span>
          </a>
        </li>

        <li class="dropdown-list p-0">
          <a
            id="create-resource-information"
            :aria-label="this.$t('general.shared.information')"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="$emit('emitCloseOffCanvas'); openOffCanvas('the-resource-information-create-offcanvas')"
          >
            <v-icon class="text-secondary" icon="marker" size="xxl" space="me-12"/>
            <span>{{ $t('general.shared.information') }}</span>
          </a>
        </li>

        <li class="dropdown-list p-0">
          <a
            id="create-supplier"
            :aria-label="this.$t('general.shared.supplier')"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="$emit('emitCloseOffCanvas'); openOffCanvas('the-supplier-create-offcanvas')"
          >
            <v-icon class="text-secondary" icon="briefcase" size="xxl" space="me-12"/>
            <span>{{ $t('general.shared.supplier') }}</span>
          </a>
        </li>

        <li class="dropdown-list p-0">
          <a
            id="create-case"
            :aria-label="this.$t('general.shared.case')"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="$emit('emitCloseOffCanvas'); openOffCanvas('the-case-create-offcanvas')"
          >
            <v-icon class="text-secondary" icon="compass" size="xxl" space="me-12"/>
            <span>{{ $t('general.shared.case') }}</span>
          </a>
        </li>

        <li class="dropdown-list p-0">
          <a
            id="create-template"
            :aria-label="this.$t('general.shared.product')"
            class="dropdown-item d-flex align-items-center px-28 py-16"
            href="#"
            @click.prevent="$emit('emitCloseOffCanvas'); openOffCanvas('the-template-create-offcanvas')"
          >
            <v-icon class="text-secondary" icon="map" size="xxl" space="me-12"/>
            <span>{{ $t('general.shared.product') }}</span>
          </a>
        </li>
      </template>
    </v-dropdown>

    <the-admin-user-create-offcanvas
      v-if="offCanvasToShow === 'the-admin-user-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-users="$emit('reloadUsers')"
      @open-details="openDetailsOffCanvas('the-admin-user-details-offcanvas', $event)"
    />

    <the-admin-user-details-offcanvas
      v-if="offCanvasToShow === 'the-admin-user-details-offcanvas'"
      :is-archived="false"
      :user-data="currentData"
      @closed="closeOffCanvas(); currentData = null"
      @reload-users="$emit('reloadUsers')"
    />

    <the-admin-agency-create-offcanvas
      v-if="offCanvasToShow === 'the-admin-agency-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-agencies="$emit('reloadAgencies')"
      @open-details="openDetailsOffCanvas('the-admin-agency-details-offcanvas', $event)"
    />

    <the-admin-collaborator-create-offcanvas
      v-if="offCanvasToShow === 'the-admin-collaborator-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-agencies="$emit('reloadAgencies')"
      @open-details="openDetailsOffCanvas('the-admin-collaborator-details-offcanvas', $event)"
    />

    <the-admin-agency-details-offcanvas
      v-if="offCanvasToShow === 'the-admin-agency-details-offcanvas'"
      :agency-data="currentData"
      :is-archived="false"
      @closed="closeOffCanvas(); currentData = null"
      @reload-agencies="$emit('reloadAgencies')"
    />

    <the-client-create-offcanvas
      v-if="offCanvasToShow === 'the-client-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-clients="$emit('reloadClients')"
      @open-details="openDetailsOffCanvas('the-client-details-offcanvas', $event)"
    />

    <the-client-details-offcanvas
      v-if="offCanvasToShow === 'the-client-details-offcanvas'"
      :client-data="currentData"
      :is-archived="false"
      @closed="closeOffCanvas(); currentData = null"
      @reload-clients="$emit('reloadClients')"
    />

    <the-resource-create-offcanvas
      v-if="offCanvasToShow === 'the-resource-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-resources="$emit('reloadResources')"
      @open-details="openDetailsOffCanvas('the-resource-details-offcanvas', $event)"
    />

    <the-resource-information-create-offcanvas
      v-if="offCanvasToShow === 'the-resource-information-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-resources="$emit('reloadResources')"
      @open-details="openDetailsOffCanvas('the-resource-details-offcanvas', $event)"
    />

    <the-resource-details-offcanvas
      v-if="offCanvasToShow === 'the-resource-details-offcanvas'"
      :is-archived="false"
      :resource-data="currentData"
      @closed="closeOffCanvas(); currentData = null"
      @reload-resources="$emit('reloadResources')"
    />

    <the-supplier-create-offcanvas
      v-if="offCanvasToShow === 'the-supplier-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-suppliers="$emit('reloadSuppliers')"
      @open-details="openDetailsOffCanvas('the-supplier-details-offcanvas', $event)"
    />

    <the-supplier-details-offcanvas
      v-if="offCanvasToShow === 'the-supplier-details-offcanvas'"
      :is-archived="false"
      :supplier-data="currentData"
      @closed="closeOffCanvas(); currentData = null"
      @reload-suppliers="$emit('reloadSuppliers')"
    />

    <the-case-create-offcanvas
      v-if="offCanvasToShow === 'the-case-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-client-tours="$emit('reloadClientTours')"
    />

    <the-template-create-offcanvas
      v-if="offCanvasToShow === 'the-template-create-offcanvas'"
      @closed="closeOffCanvas"
      @reload-client-tours="$emit('reloadClientTours')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VDropdown from '@/components/vendor/basic/dropdown/VDropdown.vue';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import TheAdminUserCreateOffcanvas from '@/components/admin/user/TheAdminUserCreateOffcanvas.vue';
import TheAdminAgencyCreateOffcanvas from '@/components/admin/agency/TheAdminAgencyCreateOffcanvas.vue';
import TheAdminCollaboratorCreateOffcanvas from '@/components/admin/collaborator/TheAdminCollaboratorCreateOffcanvas.vue';
import TheClientCreateOffcanvas from '@/components/client/TheClientCreateOffcanvas.vue';
import TheResourceCreateOffcanvas from '@/components/resource/TheResourceCreateOffcanvas.vue';
import TheSupplierCreateOffcanvas from '@/components/supplier/TheSupplierCreateOffcanvas.vue';
import TheCaseCreateOffcanvas from '@/components/case/TheCaseCreateOffcanvas.vue';
import TheResourceInformationCreateOffcanvas from '@/components/resource/TheResourceInformationCreateOffcanvas.vue';
import TheClientDetailsOffcanvas from '@/components/client/TheClientDetailsOffcanvas.vue';
import TheAdminAgencyDetailsOffcanvas from '@/components/admin/agency/TheAdminAgencyDetailsOffcanvas.vue';
import TheAdminUserDetailsOffcanvas from '@/components/admin/user/TheAdminUserDetailsOffcanvas.vue';
import TheResourceDetailsOffcanvas from '@/components/resource/TheResourceDetailsOffcanvas.vue';
import TheSupplierDetailsOffcanvas from '@/components/supplier/TheSupplierDetailsOffcanvas.vue';
import TheTemplateCreateOffcanvas from '@/components/template/TheTemplateCreateOffcanvas.vue';
import useCapabilities from '@/helpers/capabilities';

export default defineComponent({
  name: 'VCreateItemDropdown',
  components: {
    TheTemplateCreateOffcanvas,
    TheSupplierDetailsOffcanvas,
    TheResourceDetailsOffcanvas,
    TheAdminUserDetailsOffcanvas,
    TheAdminAgencyDetailsOffcanvas,
    TheClientDetailsOffcanvas,
    TheResourceInformationCreateOffcanvas,
    TheCaseCreateOffcanvas,
    TheSupplierCreateOffcanvas,
    TheResourceCreateOffcanvas,
    TheClientCreateOffcanvas,
    TheAdminAgencyCreateOffcanvas,
    TheAdminCollaboratorCreateOffcanvas,
    TheAdminUserCreateOffcanvas,
    VDropdown,
    VIcon,
  },
  emits: [
    'openFiltersOffcanvas',
    'emitCloseOffCanvas',
    'reloadUsers',
    'reloadAgencies',
    'reloadClients',
    'reloadResources',
    'reloadSuppliers',
    'reloadClientTours',
  ],
  setup() {
    return {
      ...useOffCanvasUtils(),
      ...useCapabilities(),
    };
  },
  data() {
    return {
      currentData: null as any,
    };
  },
  methods: {
    openDetailsOffCanvas(offCanvasName: string, id: number) {
      this.currentData = id;

      if (this.offCanvasToShow !== offCanvasName) {
        this.openOffCanvas(offCanvasName);
      }
    },
  },
});
</script>
