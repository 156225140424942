<template>
  <v-modal id="show-media-modal" class-name="modal-fullscreen-sm-down w-auto" size="lg" @closed="$emit('closed')">
    <modal-header>
      <modal-title>
        {{ $t("supplier.rate.duplicate") }}
      </modal-title>
    </modal-header>
    <div class="container">
      <span class="badge bg-danger fw-normal">
        {{ duplicateBlockString === "" ? $t("supplier.rate.singleRate") : $t("supplier.rate.blockRate") }}
      </span>
    </div>
    <modal-body class="justify-content-start">
      <div class="container text-start" v-if="duplicateBlockString === ''">
        <div class="row mb-4">
          <div class="col">
            <label class="label fw-bold">
              <span>{{ $t("general.shared.type") }}</span> : {{ duplicatePriceData.type }}
            </label>
          </div>
          <div class="col">
            <label class="label fw-bold">
              <span>{{ $t("general.shared.category") }}</span> : {{ duplicatePriceData.category }}
            </label>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label class="label fw-bold">
              <span>{{ $t("general.shared.initDate") }}</span> : {{ duplicatePriceData.initDate }}
            </label>
          </div>
          <div class="col">
            <label class="label fw-bold">
              <span>{{ $t("general.shared.endDate") }}</span> : {{ duplicatePriceData.endDate }}
            </label>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label class="label fw-bold">
              <span>{{ $t("general.shared.price") }}</span> : {{ duplicatePriceData.price }}
            </label>
          </div>
        </div>
        <hr />
        <div class="row mb-4" v-for="resource in supplierResources" :key="resource.id">
          <template v-if="selectedResouceId !== resource.id">
            <v-checkbox
              :id="resource.id"
              v-model="selectedSupplierCheck[resource.id]"
              :label="resource.name"
              @click.prevent="getResourceId(resource.id)"
            />
          </template>
        </div>
      </div>
      <div class="container text-start" v-else>
        <div class="table-container w-100" role="table">
          <div class="d-flex table-header" role="rowgroup">
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.product") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.type") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.category") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.initDate") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.endDate") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.price") }}</div>
          </div>
          <hr />
          <div class="d-flex table-content-row flex-column w-100" role="rowgroup">
            <div class="d-flex">
              <div class="table-flex-row align-items-center" role="cell">{{ duplicatePriceData.name }}</div>
              <div class="d-flex flex-column table-type w-100">
                <template v-if="!!duplicatePriceData.types && duplicatePriceData.types.length > 0">
                  <template v-for="(type, outerIndex) in duplicatePriceData.types" :key="outerIndex">
                    <div class="d-flex table-row">
                      <div class="table-flex-row align-items-center" role="cell">
                        {{ fnFindConceptByKey("supplier_resource_price.type", type.key).name }}
                      </div>

                      <div class="d-flex flex-column table-category w-100">
                        <template v-for="(category, innerIndex) in type.categories" :key="innerIndex">
                          <div class="d-flex table-row">
                            <div class="table-flex-row" role="cell">
                              {{ fnFindConceptByKey("supplier_resource_price.category", category.key).name }}
                            </div>

                            <div class="d-flex flex-column table-price w-100">
                              <template v-for="(price, priceIndex) in category.prices" :key="priceIndex">
                                <div class="d-flex table-row w-100">
                                  <div class="table-flex-row" role="cell">
                                    {{ price.initDate }}
                                  </div>

                                  <div class="table-flex-row" role="cell">
                                    {{ price.endDate }}
                                  </div>

                                  <div class="table-flex-row" role="cell">{{ price.price }}</div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <!-- Add <hr> if the current row is not the last one in the inner loop -->
                          <hr v-if="innerIndex < type.categories.length - 1" />
                        </template>
                      </div>
                    </div>
                    <!-- Add <hr> if the current row is not the last one in the outer loop -->
                    <hr v-if="outerIndex < duplicatePriceData.types.length - 1" />
                  </template>
                </template>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div class="row mb-4" v-for="resource in supplierResources" :key="resource.id">
          <template v-if="selectedResouceId !== resource.id">
            <v-checkbox
              :id="resource.id"
              v-model="selectedSupplierCheck[resource.id]"
              :label="resource.name"
              @click.prevent="getResourceId(resource.id)"
            />
          </template>
        </div>
      </div>
    </modal-body>
    <modal-footer>
      <v-button
        class="btn-sm btn-primary d-flex align-items-center cursor-pointer"
        size="sm"
        variant="primary"
        @click="duplicateSupplierResourcePrice"
      >
        {{ $t("general.button.save") }}
        <v-icon icon="arrow-right" space="px-8"/>
      </v-button>
    </modal-footer>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VCheckbox } from "@uniqoders/form";
import VButton from "@/components/vendor/basic/button/VButton.vue";

import VModal from "@/components/vendor/basic/modal/VModal.vue";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";
import ModalHeader from "@/components/vendor/basic/modal/ModalHeader.vue";
import ModalTitle from "@/components/vendor/basic/modal/ModalTitle.vue";
import ModalBody from "@/components/vendor/basic/modal/ModalBody.vue";
import ModalFooter from "@/components/vendor/basic/modal/ModalFooter.vue";
import { findConceptByKey } from "@/helpers/ConceptHelper";
import api from "@/api";

export default defineComponent({
  name: 'TheSupplierDuplicatePriceModal',
  components: {
    ModalBody,
    ModalTitle,
    ModalHeader,
    ModalFooter,
    VModal,
    VIcon,
    VCheckbox,
    VButton,
  },
  emits: ['closed', 'emitReloadSupplierRates'],
  props: {
    supplierResources: {
      type: [] as any[],
      required: true,
    },
    selectedResouceId: {
      type: Number,
    },
    duplicateBlockString: {
      type: String,
    },
    duplicatePriceData: {
      type: [] as any[],
      required: true,
    },
  },
  data() {
    return {
      selectedSupplierCheck: {} as { [key: number]: boolean },
      selectedDupicateRateId: [] as number[],
      fnFindConceptByKey: findConceptByKey,
    };
  },
  methods: {
    async duplicateSupplierResourcePrice() {
      try {
        if (this.duplicateBlockString === '') {
          const data: any = {
            price: [
              {
                category: this.duplicatePriceData.category,
                type: this.duplicatePriceData.type,
                init_date: this.duplicatePriceData.initDate,
                end_date: this.duplicatePriceData.endDate,
                price: this.duplicatePriceData.price,
              },
            ],
            destinationSupplierResourceId: this.selectedDupicateRateId,
          };

          const response = await api.supplierResourcePrice.duplicate(data);

          this.$toast.success(response.message);
        } else {
          const arr: any[] = [];
          if (this.duplicatePriceData) {
            this.duplicatePriceData.types.map(
              (newData: any) => newData.categories
                && newData.categories.map(
                  (newcat: any) => newcat.prices
                    && newcat.prices.map((newObje: any) => arr.push({
                      category: newObje.category,
                      type: newObje.type,
                      init_date: newObje.initDate,
                      end_date: newObje.endDate,
                      price: newObje.price,
                    }),
                    ),
                ),
            );
          }

          const duplicateBlockData: any = {
            destinationSupplierResourceId: this.selectedDupicateRateId,
            price: arr,
          };
          const response = await api.supplierResourcePrice.duplicate(duplicateBlockData);

          this.$toast.success(response.message);
        }

        this.$emit('emitReloadSupplierRates');
        this.$emit('closed');
      } catch (e: any) {
        console.error(e);
      }
    },
    getResourceId(id: number) {
      if (this.selectedSupplierCheck[id]) {
        const index = this.selectedDupicateRateId.indexOf(id);
        if (index !== -1) {
          this.selectedDupicateRateId.splice(index, 1);
        }
        this.selectedSupplierCheck[id] = false;
      } else {
        this.selectedDupicateRateId.push(id);
        this.selectedSupplierCheck[id] = true;
      }

      // Trigger a reactive update
      this.$forceUpdate();
    },
  },
});
</script>

<style scoped>
.table-flex-row {
  display: flex;
  flex-direction: row;
  min-width: 6.6rem;
  padding: 0.125rem 0.375rem;
}
</style>
