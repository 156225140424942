<template>
  <div id="clientTours-list" class="container item-list pb-64">
    <v-list-header :title="$t('clientTour.cases')" icon="compass"
      @open-filters-offcanvas="openOffCanvas('the-case-filters-offcanvas')" @emit-close-off-canvas="closeOffCanvas"
      @reload-client-tours="loadClientTours">
      <template #input-search>
        <v-input-text-search id="clientTours-search" v-model="clientName" :placeholder="$t('general.shared.search')"
          autocomplete="off" class="w-100 me-24" input-class="h-auto py-8" input-group-text-class="py-8 px-16"
          type="text" @update:modelValue="clientName = $event; search()" />
      </template>
    </v-list-header>

    <div class="items-content">
      <div class="filters-container mb-24">
        <v-filters v-if="!!filterOptions" v-model="filterOptions" @remove-filter="removeFilter" />
      </div>

      <div class="table-responsive d-none d-lg-flex table-for-cases">
        <v-table>
          <v-table-header>
            <tr>
              <th>{{ $t('general.shared.name') }}</th>
              <th>{{ $t('general.shared.type') }}</th>
              <th>{{ $t('general.shared.originAndDestination') }}</th>
              <th>{{ $t('general.shared.pax') }}</th>

              <th>
                {{ $t('general.shared.initDate') }}
                <v-button :class="{ 'sort-active': order.key === 'init_date' }" class="p-0" size="xs" variant="icon"
                  @click="toggleOrder('init_date')">
                  <v-icon :class="{ 'sort-icon-transition': order.key === 'init_date' && order.order === 'DESC' }"
                    icon="chevron-down" size="xs" />
                </v-button>
              </th>

              <th class="responsive-header">
                {{ $t('general.shared.endDate') }}
                <v-button :class="{ 'sort-active': order.key === 'end_date' }" class="p-0" size="xs" variant="icon"
                  @click="toggleOrder('end_date')">
                  <v-icon :class="{ 'sort-icon-transition': order.key === 'end_date' && order.order === 'DESC' }"
                    icon="chevron-down" size="xs" />
                </v-button>
              </th>

              <th v-if="!agency?.isCollaborator">{{ $t('general.shared.reservations') }}</th>
              <th v-if="!agency?.isCollaborator">{{ $t('general.shared.payments') }}</th>
              <th v-if="!agency?.isCollaborator">{{ $t('general.shared.invoices') }}</th>

              <th class="fit" />
            </tr>
          </v-table-header>

          <v-table-body class="fw-medium">
            <tr v-if="!loaded">
              <td class="position-relative border-0 py-96" colspan="2222">
                <v-loader />
              </td>
            </tr>

            <template v-else-if="!!clientTours && clientTours.length > 0">
              <tr v-for="clientTour in clientTours" :key="clientTour.id">
                <td class="d-flex">
                  <div class="image-container rounded-sm bg-tertiary me-12"
                    :class="{ 'bg-collaborator': clientTour.assignedTo?.agency?.isCollaborator }">
                    <template v-if="!!clientTour.client && !!clientTour.client.name">
                      {{ clientTour.client.name.charAt(0) }}
                    </template>

                    <template v-else>{{ clientTour.id }}</template>
                  </div>
                  <template v-if="agency?.isCollaborator">
                    <a :aria-label="clientTour.name" class="py-12 client-modal-button"  @click="openCaseDetailsModal(clientTour)">
                      {{ clientTour.name }}
                    </a>
                  </template>
                  <template v-else>
                    <router-link v-if="!clientTour.deletedAt" :aria-label="clientTour.name" :to="{
                      name: 'case.show',
                      params: { caseId: clientTour.id }
                    }" class="py-12">
                      {{ clientTour.name }}
                    </router-link>
                    <a v-else :aria-label="clientTour.name" class="py-12" href="#"
                      @click.prevent="setCurrentClientTour(clientTour)">
                      {{ clientTour.name }}
                    </a>
                  </template>
                </td>

                <td>{{ findConceptByKey('tour.travel_type', clientTour.tours[0]?.travelType).name }}</td>

                <td>
                  <template v-if="!!clientTour.tours[0]?.origin || !!clientTour.tours[0]?.destination">
                    {{ !!clientTour.tours[0]?.origin ? clientTour.tours[0]?.origin.name : '-' }}

                    <v-icon icon="arrow-right" size="xs" space="px-8" />

                    {{ !!clientTour.tours[0].destination ? clientTour.tours[0].destination.name : '-' }}
                  </template>

                  <template v-else>-</template>
                </td>

                <td>{{ clientTour.paxNumber }}</td>

                <td>{{ $str.formatDateTime(clientTour.initDate, settings.formatDate, false) }}</td>
                <td>{{ !!clientTour.endDate ? $str.formatDateTime(clientTour.endDate, settings.formatDate, false) : '-' }}</td>

                <td v-if="!agency?.isCollaborator">
                  {{ findConceptByKey('tour.reservation_status_type', clientTour.tours[0]?.reservationStatus).name }}
                </td>

                <td v-if="!agency?.isCollaborator">
                  {{ findConceptByKey('tour.payment_status_type', clientTour.tours[0]?.paymentsStatus).name }}
                </td>
                <td v-if="!agency?.isCollaborator">
                  {{ findConceptByKey('tour.invoice_status_type', clientTour.tours[0]?.invoicesStatus).name }}
                </td>

                <td class="fit text-end" v-if="!agency?.isCollaborator">
                  <router-link v-if="!clientTour.deletedAt" :aria-label="$t('tourClient.tour')"
                    :to="{ name: 'case.show', params: { caseId: clientTour.id } }"
                    class="btn btn-icon btn-view rounded-pill bg-secondary">
                    <v-icon icon="arrow-right" />
                  </router-link>

                  <v-button v-else class="btn-view rounded-pill bg-secondary" size="xs" variant="icon"
                    @click="setCurrentClientTour(clientTour)">
                    <v-icon icon="arrow-right" />
                  </v-button>
                </td>
                <td v-if="agency?.isCollaborator" class="fit text-end">
                  <v-button
                    class="btn-view rounded-pill bg-secondary"
                    size="xs"
                    variant="icon"
                    @click="openCaseDetailsModal(clientTour)">
                    <v-icon icon="arrow-right" />
                  </v-button>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="rounded-xs py-16" colspan="2222">
                  {{ $t('clientTour.noCases') }}
                </td>
              </tr>
            </template>
          </v-table-body>
        </v-table>
      </div>

      <div class="item-cards d-block d-lg-none">
        <div v-if="!loaded" class="position-relative py-96">
          <v-loader />
        </div>

        <template v-else-if="!!clientTours && clientTours.length > 0">
          <div v-for="clientTour in clientTours" :key="clientTour.id"
            class="item-card border rounded-xs p-8 mb-12 text-sm">
            <div class="d-flex">
              <div class="image-container rounded-sm bg-tertiary me-12">
                <template v-if="!!clientTour.client && !!clientTour.client.name">
                  {{ clientTour.client.name.charAt(0) }}
                </template>

                <template v-else>{{ clientTour.id }}</template>
              </div>

              <div class="w-100">
                <router-link :aria-label="clientTour.name"
                  :to="{ name: 'case.show', params: { caseId: clientTour.id } }" class="fw-medium">
                  {{ clientTour.name }}
                </router-link>

                <div class="text-xs fw-light mt-4">
                  {{ $str.formatDateTime(clientTour.initDate, settings.formatDate) }}
                  -
                  {{ !!clientTour.endDate ? $str.formatDateTime(clientTour.endDate, settings.formatDate) : '-' }}
                </div>

                <div class="text-xs fw-light">
                  <template v-if="!!clientTour.tours[0]?.origin || !!clientTour.tours[0]?.destination">
                    {{ !!clientTour.tours[0].origin ? clientTour.tours[0].origin.name : '-' }}

                    <v-icon icon="arrow-right" size="xs" space="px-8" />

                    {{ !!clientTour.tours[0].destination ? clientTour.tours[0].destination.name : '-' }}
                  </template>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between mt-12 align-items-center">
              <div class="fw-light">
                <div v-if="!!clientTour.paxNumber || !!clientTour.tours[0].travelType"
                  class="d-flex flex-wrap align-items-center">
                  <div>{{ $t('general.shared.pax') }}: {{ clientTour.paxNumber }}</div>

                  <span v-if="!!clientTour.tours[0]?.travelType" class="dot-divider mx-12" />

                  <span v-if="!!clientTour.tours[0]?.travelType">
                    {{ findConceptByKey('tour.travel_type', clientTour.tours[0]?.travelType).name }}
                  </span>
                </div>

                <div class="d-flex flex-wrap align-items-center">
                  <span>
                    {{ $t('general.shared.reservations') }}:
                    {{ findConceptByKey('tour.reservation_status_type', clientTour.tours[0]?.reservationStatus).name }}
                  </span>

                  <span class="dot-divider mx-12" />

                  <span>
                    {{ $t('general.shared.payments') }}:
                    {{ findConceptByKey('tour.payment_status_type', clientTour.tours[0]?.paymentsStatus).name }}
                  </span>

                  <span class="dot-divider mx-12" />

                  <span>
                    {{ $t('general.shared.invoices') }}:
                    {{ findConceptByKey('tour.invoice_status_type', clientTour.tours[0]?.invoicesStatus).name }}
                  </span>
                </div>
              </div>

              <router-link v-if="!clientTour.deletedAt" :aria-label="$t('tourClient.tour')"
                :to="{ name: 'case.show', params: { caseId: clientTour.id } }"
                class="btn btn-icon btn-view rounded-pill bg-secondary h-fit-content">
                <v-icon icon="arrow-right" size="xs" />
              </router-link>

              <v-button v-else class="btn-view rounded-pill bg-secondary h-fit-content" size="xs" variant="icon"
                @click="setCurrentClientTour(clientTour)">
                <v-icon icon="arrow-right" />
              </v-button>
            </div>
          </div>
        </template>

        <div v-else>
          {{ $t('clientTour.noCases') }}
        </div>
      </div>

      <v-pagination v-if="!!clientTours && clientTours.length > 0" :meta="pagination.meta"
        :pagination-links="pagination.links" class="mt-32"
        @page-changed="changePage($event); handlePageChangedEvent($event)"
        @per-page-changed="perPage = $event; doSearch()" />
    </div>

    <the-case-filters-offcanvas v-if="offCanvasToShow === 'the-case-filters-offcanvas'" v-model:assignedTo="assignedTo"
      v-model:clientId="clientId" v-model:destination="destination" v-model:endDate="endDate"
      v-model:initDate="initDate" v-model:invoicesStatus="invoicesStatus" v-model:location="location"
      v-model:onlyOnWay="onlyOnWay" v-model:origin="origin" v-model:paxNumber="paxNumber"
      v-model:paymentsStatus="paymentsStatus" v-model:reservationStatus="reservationStatus"
      v-model:startingSoon="startingSoon" v-model:status="status" v-model:travelType="travelType" :agency="agency.slug"
      @closed="closeOffCanvas" @selected="doSearch" />

    <the-case-archived-offcanvas v-if="offCanvasToShow === 'the-case-archived-offcanvas'"
      :client-tour="currentClientTour" @closed="closeOffCanvas(); cleanCurrentClientTour()"
      @reload-client-tours="loadClientTours" />
  </div>
  <v-modal v-if="isSelectedClientItem" @closed="closeClientModal">
    <modal-header class="header-container">
      <h5>{{ caseModalData['caseName'] }}</h5>
      <button type="button" @click="closeClientModal" aria-label="Close" class="btn-icon btn fw-medium p-4"
        data-bs-dismiss="modal" title="Close"><span class="icon-close icon"></span></button>
    </modal-header>
    <modal-body class="modalMessage">
      <table class="modalTable">
        <tbody>
          <tr>
            <th>{{ $t("general.shared.clientName") }}</th>
            <td>{{ caseModalData['clientName'] }}</td>
          </tr>
          <tr>
            <th>{{ $t("general.shared.clientEmail") }}</th>
            <td>{{ caseModalData['clientEmail'] }}</td>
          </tr>
          <tr>
            <th>{{ $t("general.shared.pax") }}</th>
            <td>{{ caseModalData['paxNumber'] }}</td>
          </tr>
          <tr>
            <th>{{ $t("general.shared.initDate") }}</th>
            <td>{{ $str.formatDateTime(caseModalData['initDate'], settings.formatDate, false) }}</td>
          </tr>
          <tr>
            <th>{{ $t("general.shared.endDate") }}</th>
            <td>{{ $str.formatDateTime(caseModalData['endDate'], settings.formatDate, false) }}</td>
          </tr>
          <tr>
            <th>{{ $t("general.shared.origin") }} </th>
            <td>{{ caseModalData['origin'] }}</td>
          </tr>
          <tr>
            <th>{{ $t("general.shared.destination") }} </th>
            <td>{{ caseModalData['destination'] }}</td>
          </tr>
          <tr>
            <th>{{ $t("general.shared.travelType") }}</th>
            <td>{{ capitalizeFirstLetter(caseModalData['travelType']) }}</td>
          </tr>
          <tr v-if="otherRoomRequirements">
            <th>{{ $t('clientTour.otherRoomRequirements') }}</th>
            <td>
              <div id="case-tour-other-room-text" class="html-editor-display" v-html="otherRoomRequirements"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="modalTable" v-if="rooming.length !== 0 && rooming[0]?.pax !== null">
        <tr>
          <th class="modalMainHeadings">Rooming Details</th>
        </tr>
        <tr>
          <th class="headingBoarder">Room Type</th>
          <th class="headingBoarder">{{ $t('general.shared.pax') }}</th>
        </tr>
        <tbody>
          <tr v-for="(room, index) in rooming" :key="index">
            <td>{{ room?.room_type }}</td>
            <td class="tripDetailsHeader">{{ room?.pax }}</td>
          </tr>
        </tbody>
      </table>
      <p>Please contact admin for any further assistance.</p>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VInputTextSearch } from '@uniqoders/form';
import qs from 'qs';
import querystring from 'query-string';
import { mapState } from 'pinia';
import { DateTime } from 'luxon';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import api from '@/api';
import withTimeout from '@/helpers/timeOut';
import useOffCanvasUtils from '@/helpers/OffCanvasUtils';
import VListHeader from '@/components/shared/VListHeader.vue';
import VPagination from '@/components/vendor/basic/table/VPagination.vue';
import usePagination from '@/helpers/pagination';
import VFilters from '@/components/vendor/filters/VFilters.vue';
import ClientTour from '@/api/objects/ClientTour';
import { useAuthStore } from '@/stores/auth';
import TheCaseFiltersOffcanvas from '@/components/case/TheCaseFiltersOffcanvas.vue';
import TheCaseArchivedOffcanvas from '@/components/case/TheCaseArchivedOffcanvas.vue';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import { useAppStore } from '@/stores/app';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
export default defineComponent({
  name: 'TheCases',
  components: {
    TheCaseArchivedOffcanvas,
    TheCaseFiltersOffcanvas,
    VFilters,
    VPagination,
    VListHeader,
    VLoader,
    VTableBody,
    VTableHeader,
    VTable,
    VButton,
    VInputTextSearch,
    VIcon,
    VModal
  },
  setup() {
    return {
      ...useOffCanvasUtils(),
      ...usePagination(),
      findConceptByKey,
    };
  },
  data() {
    return {
      modalMessage: '',
      rooming: [],
      otherRoomRequirements: null,
      isSelectedClientItem: false,
      clientTours: [] as ClientTour[],
      status: 'active' as string,
      clientName: '' as string,
      initDate: '' as string,
      endDate: '' as string,
      travelType: '' as string,
      paxNumber: '' as string,
      reservationStatus: '' as string,
      paymentsStatus: '' as string,
      invoicesStatus: '' as string,
      destination: '' as string,
      location: '' as string,
      origin: '' as string,
      clientId: '' as string,
      assignedTo: '' as string,
      onlyOnWay: false as boolean,
      startingSoon: '' as any,
      caseModalData: {} as any,
      filters: [
        {
          variable: 'status',
          key: 'status',
          labelAux: 'general.shared.filterStatus',
          conceptName: 'app.states',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'clientName',
          key: 'client_name',
          labelAux: 'general.shared.filterSearch',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'initDate',
          key: 'init_date',
          labelAux: 'general.shared.filterInitDate',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'endDate',
          key: 'end_date',
          labelAux: 'general.shared.filterEndDate',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'travelType',
          key: 'travel_type',
          labelAux: 'general.shared.filterTravelType',
          conceptName: 'tour.travel_type',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'paxNumber',
          key: 'pax_number',
          labelAux: 'general.shared.filterPaxNumber',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'reservationStatus',
          key: 'reservation_status',
          labelAux: 'general.shared.filterReservationStatus',
          conceptName: 'tour.reservation_status_type',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'paymentsStatus',
          key: 'payments_status',
          labelAux: 'general.shared.filterPaymentsStatus',
          conceptName: 'tour.payment_status_type',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'invoicesStatus',
          key: 'invoices_status',
          labelAux: 'general.shared.filterInvoicesStatus',
          conceptName: 'tour.invoice_status_type',
          multiple: false,
          isConcept: true,
        },
        {
          variable: 'clientId',
          key: 'client_id',
          labelAux: 'general.shared.filterClient',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'destination',
          key: 'destination',
          labelAux: 'general.shared.filterDestination',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'location',
          key: 'location',
          labelAux: 'general.shared.filterLocation',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'origin',
          key: 'origin',
          labelAux: 'general.shared.filterOrigin',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'assignedTo',
          key: 'assigned_to',
          labelAux: 'general.shared.filterManager',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'onlyOnWay',
          key: 'only_on_way',
          labelAux: 'general.shared.filterOnlyOnWay',
          multiple: false,
          isConcept: false,
        },
        {
          variable: 'startingSoon',
          key: 'starting_soon',
          labelAux: 'general.shared.filterStartingSoon',
          multiple: false,
          isConcept: false,
        },
      ] as Record<string, string | boolean>[],
      order: {
        key: '' as string,
        order: '' as string,
      } as Record<string, string>,
      perPage: 15 as number,
      page: 1 as number,
      timers: {
        name: null as any,
      },
      loaded: false as boolean,
      currentClientTour: null as null | ClientTour,
      filterOptions: [] as Record<string, any>[],
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    ...mapState(useAppStore, ['settings']),
  },
  async created() {
    await this.setQueryParametersAsFilters();
    await this.doSearch();
  },
  methods: {
    capitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    openCaseDetailsModal(clientTour: any) {
      this.isSelectedClientItem = !this.isSelectedClientItem;
      this.caseModalData['caseName'] = clientTour.tours[0].name || '--';
      this.caseModalData['clientName'] = clientTour.client.name || '--';
      this.caseModalData['clientEmail'] = clientTour.client.email || '--';
      this.caseModalData['initDate'] = clientTour.initDate || '--';
      this.caseModalData['endDate'] = clientTour.endDate || '--';
      this.caseModalData['paxNumber'] = clientTour.paxNumber || '--';
      this.caseModalData['origin'] = clientTour.tours[0].origin.name || '--';
      this.caseModalData['destination'] = clientTour.tours[0].destination.name || '--';
      this.caseModalData['travelType'] = clientTour.tours[0].travelType || '--';
      this.rooming = clientTour.rooming || [];
      this.otherRoomRequirements = clientTour.otherRoomRequirements || null;
    },
    closeClientModal() {
      this.isSelectedClientItem = !this.isSelectedClientItem;
    },

    search() {
      clearTimeout(this.timers.name);

      this.timers.name = withTimeout(async () => {
        await this.doSearch();
      }, this.timers.name, 1000);
    },
    /**
     * Detects any query parameter in the url and loads it in the filters (if available)
     *
     * @returns {Promise<void>}
     */
    async setQueryParametersAsFilters() {
      const { query }: any = this.$route;

      const {
        filters,
        order,
        perPage,
        status,
        page,
      }: any = qs.parse(query);

      if (!!filters) {
        this.filters.forEach((filter: Record<string, any>) => {
          if (!!filters[filter.key]) {
            this[filter.variable] = filters[filter.key];
          }
        });
      }

      if (!!order) {
        this.order = order;
      }

      const pageNumber = parseInt(page, 10);

      if (!Number.isNaN(pageNumber)) {
        this.page = pageNumber;
      }

      const perPageNumber = parseInt(perPage, 10);

      if (!Number.isNaN(perPageNumber)) {
        this.perPage = perPageNumber;
      }

      if (!!status) {
        this.status = status;
      }
    },
    /**
     * Sets the local filters as url query parameters.
     */
    async setFiltersAsQueryParameters() {
      const filters: any = {};

      this.filters.forEach((filter: Record<string, any>) => {
        if (!!this[filter.variable]) {
          filters[filter.key] = this[filter.variable];
        }
      });

      const queryObject: any = {
        filters,
        perPage: this.perPage,
        status: this.status,
        page: this.page,
      };

      if (!!this.order && !!this.order.key) {
        queryObject.order = this.order;
      }

      await this.setFilters();

      let query: any = qs.stringify(queryObject, { encode: false });
      query = querystring.parse(query);

      this.$router.push({
        name: 'case.index',
        query,
      });
    },
    async doSearch() {
      await this.setFiltersAsQueryParameters();
      await this.loadClientTours();
    },
    async loadClientTours(page?: number) {
      try {
        this.loaded = false;

        const filters: any = {};

        this.filters.forEach((filter: Record<string, any>) => {
          if (!!this[filter.variable]) {
            if (filter.key === 'init_date' || filter.key === 'end_date') {
              filters[filter.key] = DateTime.fromFormat(this[filter.variable], 'dd-MM-yyyy').toFormat('MM-dd-yyyy');
            } else {
              filters[filter.key] = this[filter.variable];
            }
          }
        });

        const query: any = {
          filters,
          order: this.order,
          perPage: this.perPage,
          status: this.status,
          page: !!page ? page : this.page,
        };

        if (!!this.agency) {
          const response = await api.case.allPaginated(this.agency.slug, query);

          this.setResponse(response);

          this.clientTours = response.data;
        }
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.loaded = true;
      }
    },
    cleanCurrentClientTour() {
      this.currentClientTour = null;
    },
    async handlePageChangedEvent(page: number) {
      await this.loadClientTours(page);
    },
    toggleOrder(field: string) {
      const { order } = this.order;

      if (!order) {
        this.order.order = 'ASC';
      } else {
        this.order.order = order === 'ASC' ? 'DESC' : 'ASC';
      }

      this.order.key = field;

      this.search();
    },
    setFilters() {
      this.filterOptions = this.filters.flatMap((filter: Record<string, any>) => {
        const {
          key,
          multiple,
          isConcept,
          labelAux,
          conceptName,
          variable,
        } = filter;

        const options = multiple ? this[variable] : [this[variable]];

        return options.map((option: any) => {
          const value = isConcept ? findConceptByKey(`${conceptName}`, option) : option;
          const label = this.$t(`${labelAux}`, { value: !!conceptName ? value.name : value });

          return { key, value: !!conceptName ? value.key : value, label };
        });
      });
    },
    removeFilter(filter: Record<string, string>) {
      this.filters.forEach((filterAux: Record<string, string | boolean>) => {
        if (filterAux.key === filter.key) {
          if (!filterAux.multiple) {
            this[filterAux.variable] = '';
          } else {
            this[filterAux.variable] = this[filterAux.variable].filter((option: string) => option !== filter.value);
          }
        }
      });

      if (filter.key === 'status') {
        this.status = 'active';
      }

      this.doSearch();
    },
    setCurrentClientTour(clientTour: ClientTour) {
      this.currentClientTour = clientTour;

      if (this.offCanvasToShow !== 'the-case-archived-offcanvas') {
        this.openOffCanvas('the-case-archived-offcanvas');
      }
    },
  },
  unmounted() {
    this.cleanCurrentClientTour();
  },
});
</script>
<style scoped>
.client-modal-button {
  cursor:pointer;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
}

/* Style for the close button */
.close-button {
  background: none;
  border: none;
  cursor: pointer;

}
.headingBoarder {
  border: 1px solid #ddd;
  padding: 15px !important
}
.modalMessage {
  padding: 15px;

}

.modalTable {
  width: 100%;
  margin-bottom: 12px;
}

.modalTable tr {
  border: 1px solid #ddd;
  padding: 12px;
}

.modalTable td {
  border: 1px solid #ddd;
  padding: 12px;
}

.modalTable th {
  padding: 12px;
}

.bg-collaborator {
  background: #dcbb66;
}

.responsive-header {
  width: 105px;
}
</style>
