<template>
  <v-off-canvas
    id="user-details-offcanvas"
    :backdrop-static="false"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <div class="d-flex align-items-center">
        <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

        <h5 id="user-details-offcanvas-label" class="offcanvas-title">
          {{ $t('admin.user.userDetails') }}
        </h5>
      </div>
      </off-canvas-header>

      <off-canvas-body class="justify-content-between d-flex flex-column">
      <v-loader v-show="status === 'loading'"/>

      <div v-show="status !== 'loading'" class="item-details">
        <the-admin-user-details-basic-information
          v-if="!currentUser.deletedAt"
          :user-details="currentUser"
          @reload-user="loadUser(); $emit('reloadUsers')"
        />

        <the-admin-user-details-agencies
          v-if="!currentUser.deletedAt"
          :user-details="currentUser"
          @reload-user="loadUser"
        />

        <div
          v-if="!currentUser.deletedAt && currentUser.id !== user.id"
          class="detail"
        >
          <div class="detail-title fw-medium mb-20">
            {{ $t('admin.user.loginAs') }}
          </div>

          <div class="detail-content">
            <p class="text-sm">
              {{ $t('admin.user.impersonateDescription') }}
            </p>

            <v-button
              id="btn-impersonate-start"
              :disabled="disableImpersonateBtn"
              class="btn-icon me-12"
              size="sm"
              variant="primary"
              @click="impersonate"
            >
              <v-icon icon="user-settings" space="me-12"/>
              <template v-if="disableImpersonateBtn">{{ $t('general.button.loggingIn') }}</template>
              <template v-else>{{ $t('general.button.login') }}</template>
            </v-button>
          </div>
        </div>

        <div v-if="!currentUser.deletedAt && currentUser.id !== user.id" class="detail">
          <div class="detail-title fw-medium d-flex align-items-center mb-20">
            <v-icon v-if="!user.canAccess" class="text-danger" icon="user-delete" size="lg" space="me-8"/>
            <v-icon v-else class="text-success" icon="user-check" size="lg" space="me-8"/>
            {{ $t('admin.user.access') }}
          </div>

          <div class="detail-content">
            <p v-if="!currentUser.canAccess" class="text-sm">{{ $t('admin.user.userCannotAccessText') }}</p>
            <p v-else class="text-sm">{{ $t('admin.user.userCanAccessText') }}</p>

            <v-button
              v-if="!currentUser.canAccess"
              id="btn-forgive-access-user"
              :disabled="disableDenyForgiveBtn"
              class="btn-icon me-12 mb-8"
              size="sm"
              variant="success"
              @click="forgiveUserAccess"
            >
              <v-icon icon="check" space="me-12"/>
              <template v-if="disableDenyForgiveBtn">{{ $t('admin.user.providingAccess') }}</template>
              <template v-else>{{ $t('admin.user.provideAccess') }}</template>
            </v-button>

            <v-button
              v-else
              id="btn-deny-access-user"
              :disabled="disableDenyForgiveBtn"
              class="btn-icon me-12 mb-8"
              size="sm"
              variant="danger"
              @click="denyUserAccess"
            >
              <v-icon icon="disabled" space="me-12"/>
              <template v-if="disableDenyForgiveBtn">{{ $t('admin.user.denyingAccess') }}</template>
              <template v-else>{{ $t('admin.user.denyAccess') }}</template>
            </v-button>
          </div>
        </div>

        <div v-if="currentUser.id !== user.id" class="detail mb-0 pb-0">
          <div class="detail-title fw-medium mb-20">
            <template v-if="!currentUser.deletedAt">{{ $t('general.shared.archive') }}</template>
            <template v-else>{{ $t('general.shared.restore') }}</template>
          </div>

          <div class="detail-content">
            <p v-if="!currentUser.deletedAt" class="text-sm">{{ $t('admin.user.archiveText') }}</p>
            <p v-else class="text-sm">{{ $t('admin.user.archivedText') }}</p>

            <v-button
              v-if="!currentUser.deletedAt"
              id="btn-archive-user"
              :disabled="disableArchiveBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="archiveUser"
            >
              <v-icon icon="import" space="me-12"/>
              <template v-if="disableArchiveBtn">{{ $t('general.button.archiving') }}</template>
              <template v-else>{{ $t('general.button.archive') }}</template>
            </v-button>

            <v-button
              v-else
              id="btn-restore-user"
              :disabled="disableRestoreBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="primary"
              @click="restoreUser"
            >
              <v-icon icon="unarchive" space="me-12"/>
              <template v-if="disableRestoreBtn">{{ $t('general.button.restoring') }}</template>
              <template v-else>{{ $t('general.button.restore') }}</template>
            </v-button>
          </div>
        </div>

        <div v-if="!!currentUser.deletedAt && currentUser.id !== user.id" class="detail">
          <div class="detail-title fw-medium mb-20">
            {{ $t('general.shared.delete') }}
          </div>

          <div class="detail-content">
            <p class="text-sm">{{ $t('admin.user.deleteText') }}</p>

            <v-button
              id="btn-delete-user"
              :disabled="disableDeleteBtn"
              class="btn-icon mb-8"
              size="sm"
              variant="danger"
              @click="deleteUser"
            >
              <v-icon icon="trash" space="me-12"/>
              <template v-if="disableDeleteBtn">{{ $t('general.button.deleting') }}</template>
              <template v-else>{{ $t('general.button.delete') }}</template>
            </v-button>
          </div>
        </div>
      </div>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { useAuthStore } from '@/stores/auth';
import User from '@/api/objects/User';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import TheAdminUserDetailsAgencies from '@/components/admin/user/parts/TheAdminUserDetailsAgencies.vue';
import TheAdminUserDetailsBasicInformation from '@/components/admin/user/parts/TheAdminUserDetailsBasicInformation.vue';
import useCapabilities from '@/helpers/capabilities';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheAdminUserDetailsOffcanvas',
  components: {
    TheAdminUserDetailsBasicInformation,
    TheAdminUserDetailsAgencies,
    VLoader,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer,
  },
  emits: ['closed', 'reloadUsers'],
  props: {
    userData: {
      type: User,
      required: true,
    },
    isArchived: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const authStore = useAuthStore();

    return {
      ...useCapabilities(),
      authStore,
    };
  },
  data() {
    return {
      currentUser: {} as User,
      status: 'loading' as string,
      disableArchiveBtn: false as boolean,
      disableDeleteBtn: false as boolean,
      disableDenyForgiveBtn: false as boolean,
      disableImpersonateBtn: false as boolean,
      disableRestoreBtn: false as boolean,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
  },
  watch: {
    async userData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.isArchived) {
          await this.loadUser();
        } else {
          this.currentUser = this.userData;

          this.status = 'loaded';
        }
      }
    },
  },
  async created() {
    if (!this.isArchived) {
      await this.loadUser();
    } else {
      this.currentUser = this.userData;

      this.status = 'loaded';
    }
  },
  methods: {
    async loadUser() {
      this.status = 'loading';

      this.currentUser = await api.admin.user.retrieve(this.userData.id);

      this.status = 'loaded';
    },
    async archiveUser() {
      await this.$modal.delete({
        title: this.$t('admin.user.archiveUserTitle'),
        text: this.$t('admin.user.archiveUserText', { user: this.currentUser?.name }),
        deleteButtonText: this.$t('general.button.archive'),
        deleteButtonLoadingText: this.$t('general.button.archiving'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doArchiveUser,
      });
    },
    async doArchiveUser() {
      try {
        this.disableArchiveBtn = true;

        await api.admin.user.archive(this.currentUser.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadUsers');
        this.$emit('closed');

        this.disableArchiveBtn = false;
      } catch (e: any) {
        this.disableArchiveBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async restoreUser() {
      await this.$modal.confirm({
        title: this.$t('admin.user.restoreUserTitle'),
        text: this.$t('admin.user.restoreUserText', { user: this.currentUser?.name }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doRestoreUser,
      });
    },
    async doRestoreUser() {
      try {
        this.disableRestoreBtn = true;

        await api.admin.user.restore(this.currentUser.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadUsers');
        this.$emit('closed');

        this.disableRestoreBtn = false;
      } catch (e: any) {
        this.disableRestoreBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async deleteUser() {
      await this.$modal.delete({
        title: this.$t('admin.user.deleteUserTitle'),
        text: this.$t('admin.user.deleteUserText', { user: this.currentUser?.name }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doDeleteUser,
      });
    },
    async doDeleteUser() {
      try {
        this.disableDeleteBtn = true;

        await api.admin.user.delete(this.currentUser.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.$emit('reloadUsers');
        this.$emit('closed');

        this.disableDeleteBtn = false;
      } catch (e: any) {
        this.disableDeleteBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async denyUserAccess() {
      await this.$modal.delete({
        title: this.$t('admin.user.denyUserTitle'),
        text: this.$t('admin.user.denyUserText', { user: this.currentUser?.name }),
        deleteButtonText: this.$t('admin.user.denyAccess'),
        deleteButtonLoadingText: this.$t('admin.user.denyingAccess'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: this.doDenyForgiveUserAccess,
      });
    },
    async forgiveUserAccess() {
      await this.$modal.confirm({
        title: this.$t('admin.user.forgiveUserTitle'),
        text: this.$t('admin.user.forgiveUserText', { user: this.currentUser?.name }),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('admin.user.provideAccess'),
        confirmButtonLoadingText: this.$t('admin.user.providingAccess'),
        confirmButtonCallback: this.doDenyForgiveUserAccess,
      });
    },
    async doDenyForgiveUserAccess() {
      try {
        this.disableDenyForgiveBtn = true;

        await api.admin.user.forgiveAccess(this.currentUser.id);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.loadUser();

        this.disableDenyForgiveBtn = false;
      } catch (e: any) {
        this.disableDenyForgiveBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async impersonate() {
      await this.$modal.confirm({
        title: this.$t('admin.user.loginAs'),
        text: this.$t('admin.user.impersonateText', { user: this.currentUser?.name }),
        confirmButtonText: this.$t('general.button.confirm'),
        confirmButtonCallback: this.doImpersonate,
      });
    },
    async doImpersonate() {
      try {
        await this.authStore.impersonateStart(this.currentUser.id);
      } catch (e) {
        await this.$modal.info({
          title: this.$t('admin.user.impersonateErrorTitle'),
          text: this.$t('admin.user.impersonateErrorText'),
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
#user-details-offcanvas {
  &.transition-in {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
  }
}
</style>
