import { computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useAppStore } from '@/stores/app';

const useCapabilities = () => {
  const authStore = useAuthStore();
  const appStore = useAppStore();

  const { user } = authStore;
  const permissions = computed(() => appStore.permissions);

  const can = (permission: string) => permissions.value.includes(permission);

  return {
    user,
    can,
  };
};

export default useCapabilities;
