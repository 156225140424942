import { defineStore } from 'pinia';
import api from '@/api';
import User from '@/api/objects/User';
import Agency from '@/api/objects/Agency';
import { useAppStore } from '@/stores/app';
import { POSITION, TYPE, useToast } from 'vue-toastification';

export interface AuthState {
  user: User | null,
  logged: boolean,
  agency: Agency | null,
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
    logged: false,
    agency: null,
  }),
  actions: {
    setLogged(logged: boolean) {
      this.logged = logged;
    },
    setUser(user: User | null) {
      this.user = user;
    },
    async getPermissions() {
      const appStore = useAppStore();

      const permissions = await api.user.allPermissions();

      await appStore.setPermissions(permissions);

      return permissions;
    },
    async login() {
      const appStore = useAppStore();

      const user = await Promise.all([
        api.auth.user(),
        api.user.allPermissions(),
      ]);

      await this.setUser(user[0]);
      await appStore.setPermissions(user[1]);
    },
    async logout() {
      await this.setUser(null);
      await this.setLogged(false);
      await this.setAgency(null);

      await api.auth.logout();
    },
    async setAuthUser(reload = false) {
      try {
        const user: User = await api.auth.user();

        await this.setUser(user);

        if (this.user?.agencies?.length) {
          const agencySlug = localStorage.getItem('agency');
          const { agencies } = this.user;

          const agencyFound: any = agencies.find((agency) => agency.slug === agencySlug);
          const agency = agencyFound || agencies[0];

          this.setAgency(agency);

          localStorage.setItem('agency', agency.slug);
          localStorage.setItem('isCollaborator', agency.isCollaborator);

          if (reload) {
            window.location.replace(import.meta.env.VITE_APP_URL);
          }
        }
      } catch (e) {
        const errorMsg = e?.response?.data?.message || 'login failed'
        const toast = useToast();
        toast.error(errorMsg, {
          type: TYPE.ERROR,
          position: POSITION.BOTTOM_RIGHT
        })
        this.setUser(null);
      }
    },
    async impersonateStart(userId: number) {
      await api.admin.impersonate.start(userId);

      await this.setAuthUser();
      await this.setAgency(null);

      localStorage.setItem('agency', '');

      window.location.replace(import.meta.env.VITE_APP_URL);
    },
    async impersonateStop() {
      await api.admin.impersonate.stop();

      await this.setAuthUser();
      await this.setAgency(null);

      localStorage.setItem('agency', '');

      window.location.replace(import.meta.env.VITE_APP_URL);
    },
    setAgency(agency: Agency | null) {
      this.agency = agency;
    },
  },
});
