<template>
  <v-off-canvas
    id="tour-day-item-details-offcanvas"
    :backdrop-static="false"
    offcanvas-class="item-details offcanvas-end"
    @closed="$emit('closed'); removeCurrentTourDayItem()"
  >
    <resizable-container>
      <off-canvas-header>
        <div class="d-flex align-items-center">
          <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

          <h5 id="tour-day-item-details-offcanvas-label" class="offcanvas-title">
            {{ $t('tourBuilder.itemDetails') }}
          </h5>
        </div>
      </off-canvas-header>

      <off-canvas-body>
        <v-loader v-if="status === 'loading' || !currentTourDayItem"/>

        <template v-else>
          <the-tour-day-item-details-create-form
            v-if="!!currentTourDayItem && !!currentTourDayItem.resources && currentTourDayItem.resources.length === 0"
            
            :agency="agency"
            :client-tour-id="clientTourId"
            :status="status"
            :tour-id="tourId"
            @emit-reload-tour="$emit('emitReloadTour')"
          />

          <the-tour-day-item-details-update-form
            v-else
            
            :agency="agency"
            :client-tour-id="clientTourId"
            :status="status"
            :tour-id="tourId"
            @deleted-item-resource="deletedItemResource = true"
            @emit-reload-tour="$emit('emitReloadTour')"
            @open-item-details-off-canvas="$emit('openItemDetailsOffCanvas')"
          />
        </template>

      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import { useUserStore } from '@/stores/user';
import useConcepts from '@/helpers/Concepts';
import TheTourDayItemDetailsCreateForm from '@/components/tour/TheTourDayItemDetailsCreateForm.vue';
import TheTourDayItemDetailsUpdateForm from '@/components/tour/TheTourDayItemDetailsUpdateForm.vue';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue'

export default defineComponent({
  name: 'TheTourDayItemDetailsOffCanvas',
  components: {
    TheTourDayItemDetailsUpdateForm,
    TheTourDayItemDetailsCreateForm,
    VLoader,
    VIcon,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer
  },
  emits: ['closed', 'emitReloadTour', 'openItemDetailsOffCanvas'],
  props: {
    agency: {
      type: String,
      required: true,
    },
    clientTourId: {
      type: Number,
      required: true,
    },
    tourId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    
  },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
      ...useConcepts(),
    };
  },
  data() {
    return {
      deletedItemResource: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['currentTourDayItem']),
  },
  watch: {
    currentTourDayItem: {
      handler(value) {
        if (this.deletedItemResource && !!value && !!value.resources && value.resources.length === 0) {
          this.$emit('emitReloadTour');
        }
      },
      deep: true,
    },
  },
  methods: {
    removeCurrentTourDayItem() {
      localStorage.removeItem('currentTourDayItem');  
    }
  },
  unmounted() {
    this.userStore.setCurrentTourDayItem(null);
  },
});
</script>

<style lang="scss" scoped>
.accordion-item {
  background-color: transparent;
}

.accordion .accordion-item .accordion-button {
  font-size: 1rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: transparent;
}
</style>
