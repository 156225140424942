export default {
  cases: 'Cases',
  noCases: 'There are no cases',
  createNewCase: 'Create new case',
  createNewProduct: 'Create new activity',
  activityCreatedFor: 'Activity created for',
  archivedCases: 'Archived cases',
  activeCases: 'Active cases',
  basicInformation: 'Basic information',
  details: 'Details',
  caseDetails: 'Case details',
  detailsSubtitle: 'General case information lives here',
  media: 'Media',
  noMedia: 'There are no associated media',
  archivedText: 'This case is archived, if you want you can unarchive it by clicking on the \'Restore\' button.',
  archiveTitle: 'Archive case',
  archiveSubtitle: 'You can archive the case here',
  archiveText: 'You can archive a case, if you do this the case will not appear in the application searches. You will be able to see it in the list of cases if you filter by \'archived\'. Once you view the details of an archived case, you can delete it definitively or you can unarchive it.',
  archiveAlertText: 'Are you sure you want to archive the case \'{case}\'? If you archive it, it will not appear in searches.',
  deleteText: 'This case is archived, if you want to delete it permanently click the \'Delete\' button. Note that if you delete it, this case will no longer exist and cannot be recovered.',
  deleteClientTourTitle: 'Delete case',
  deleteClientTourText: 'Are you sure you want to delete the case \'{clientTour}\'? If you delete it, you will not be able to recover it.',
  restoreClientTourTitle: 'Recover case',
  restoreClientTourText: 'Are you sure you want to recover the case \'{clientTour}\'? If you recover it, it will no longer be archived and will appear in the application searches.',
  caseStatus: 'Case Status',
  client: 'Client',
  createClient: 'Create new client',
  createSupplier: 'Create new supplier',
  createResource: 'Create new resource',
  products: 'Activities',
  templateNoOptions: 'No activities available',
  locationNoOptions: 'There is no location in this activity',
  chooseTemplate: 'Choose an activity',
  orStartScratch: 'or start from scratch',
  participantsDetails: 'Participants Details',
  rooming: 'Rooming',
  howToDoIt: 'How to do it',
  extraDaysAtStart: 'Extra days at the start',
  extraDaysAtEnd: 'Extra days at the end',
  howWillYourPath: 'How will your path be',
  extraDays: 'Extra days',
  pax: 'Pax',
  paxSubtitle: 'Pax of the case',
  choosingProductOptional: 'Choosing a activity is optional, you can create the case from scratch.',
  caseType: 'Case type',
  travelType: 'Travel type',
  paxSendEmailText: 'Send an email to the client with a link for all travelers to fill in a form with their data.',
  sendEmail: 'Send email',
  tourBasicInformation: 'Tour basic information',
  travelers: 'Travelers',
  noTravelers: 'No travelers have been added',
  traveler: {
    update: 'Update traveler',
    removeTitle: 'Delete traveler',
    removeText: 'Are you sure to eliminate \'{ name }\' as a passenger on this tour?',
  },
  invoicesReceived: 'Invoices received',
  invoicesReceivedSubtitle: 'Invoices received of the case',
  invoicesEmitted: 'Invoices emitted',
  invoicesEmittedSubtitle: 'Invoices emitted of the case',
  invoice: {
    noInvoicesReceived: 'There are no invoices received',
    noInvoicesEmitted: 'There are no invoices emitted',
    removeTitle: 'Delete invoice file',
    removeText: 'Are you sure to eliminate the invoice \'{ name }\' from this tour?',
    number: 'Number',
    amount: 'Amount',
    basicInformation: 'Basic information',
    invoiceDetails: 'Invoice details',
    invoiceLines: 'Invoice lines',
    quantity: 'Quantity',
    nif: 'NIF',
    vat: 'VAT number',
    deleteTitle: 'Delete invoice',
    deleteText: 'Are you sure you want to delete the invoice \'{invoice}\'? If you delete it, you will not be able to recover it.',
  },
  tourStatuses: 'Statuses of the case',
  statuses: 'Statuses',
  reservations: 'Reservations',
  payments: 'Payments',
  invoices: 'Invoices',
  contact: {
    contacts: 'Contacts',
    contactsSubtitle: 'Here you can add the contact information for the case preview and quotation',
    contactInformation: 'Contact information',
  },
  dateModal: {
    title: 'Are you sure you want to change the start date?',
    text: 'This setting will change the dates of the entire itinerary. Please check that there are no bookings requested or confirmed as they will also be affected by this change.',
  },
  sendNotification: 'Send notification',
  notification: 'Notification',
  noResourcesSelectedMessage: 'Select resources to send the notifications',
  sendNotificationSubtitle: 'Send mail to the client',
  sendClientAndPaxs: 'Send notification to client and paxes',
  sendOnlyClient: 'Send notification only to the client',
  termsConditions: 'Terms & Conditions',
  termsSubtitle: 'Here you can add the terms and conditions of the case.',
  complementaryText: 'Extras',
  complementarySubtitle: 'Here you can add additional and explanatory details for the case.',
  afterTour: 'After your trip',
  afterTourSubtitle: 'Here you can add additional information after your trip',
  beforeTour: 'Before your trip',
  beforeTourSubtitle: 'Here you can add additional information before your trip',
  quotes: 'Quotes',
  quoteOptionalText: 'Add optional resources to the quote',
  quotePriceText: 'Show in the quotation the total price or the price per person.',
  quoteTotalPrice: 'Total price',
  quotePricePerPerson: 'Price per person',
  optionals: 'Optionals',
  noQuotes: 'There are no quotes',
  featuredImage: 'Featured image',
  notificationLog: 'Supplier Notification Log',
  clientNotificationLog: 'Client Notification Log',
  notificationLogSubtitle: 'Here is the history of supplier notifications.',
  noClientNotifications: 'No notifications to clients',
  noSuppliersNotifications: 'No notifications to suppliers',
  paxsInformation: 'Paxs Information',
  tourName: 'Tour name',
  emailModal: {
    title: 'Send email',
    text: 'Are you sure you want to send the email to the client?',
  },
  additionalInfo: 'Additional information',
  additionalRequirements: 'Additional requirements',
  otherRoomRequirements: 'Other room requirements',
};
