<template>
  <v-off-canvas
    id="case-filters-offcanvas"
    :backdrop-static="false"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
    <off-canvas-header>
      <h5 class="offcanvas-title">{{ $t('general.shared.filters') }}</h5>
    </off-canvas-header>

    <off-canvas-body>
      <v-checkbox
        id="case-filter-only-on-way-select"
        v-model="filterOnlyOnWay"
        :label="this.$t('general.shared.seenOnlyCasesOnWay')"
        :left="true"
        :right="false"
        class="form-check-inline"
        for-key="caseFilterOnlyOnWaySelect"
        @update:modelValue="$emit('update:onlyOnWay', filterOnlyOnWay); $emit('selected')"
      />

      <v-form
        id="case-filter-starting-soon-select"
        :label="this.$t('general.shared.startingSoon')"
        form-type="outline"
      >
        <v-select
          v-model="filterStartingSoon"
          :clearable="false"
          :options="startingSoonOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:startingSoon', filterStartingSoon); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-form
        id="case-filter-status-select"
        :label="this.$t('general.shared.status')"
        form-type="outline"
      >
        <v-select
          v-model="filterStatus"
          :clearable="false"
          :options="statusTypesOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:status', filterStatus); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-select-client-search
        id="case-filter-client-select"
        v-model="filterClientId"
        :agency="agency"
        :can-create="false"
        :clearable="true"
        :client-id="filterClientId"
        :label="this.$t('general.shared.client')"
        @clear="filterClientId = ''; $emit('update:clientId', ''); $emit('selected')"
        @update:modelValue="$emit('update:clientId', filterClientId); $emit('selected')"
      />

      <v-select-user-case-search
        id="case-filter-user-search"
        v-model="filterAssignedTo"
        :agency="agency"
        @update:model-value="$emit('update:assignedTo', filterAssignedTo); $emit('selected')"
      />

      <v-form
        id="case-filter-init-date-select"
        :label="this.$t('general.shared.initDate')"
        form-type="outline"
      >
        <Datepicker
          v-model="filterInitDate"
          :day-class="getEndDayClass"
          :enable-time-picker="false"
          :month-change-on-scroll="false"
          :hide-navigation="['time']"
          :highlight="highlightedDates"
          :max-date="!!endDate ? endDate : null"
          auto-apply
          calendar-cell-class-name="init-date-cell"
          class="form-datepicker"
          :format="settings.formatDate"
          input-class-name="form-control"
          @update:model-value="setHighlightedDates();
                               $emit('update:initDate', !!filterInitDate ? fixDate(filterInitDate) : '');
                               $emit('selected')"
        />
      </v-form>

      <v-form
        id="case-filter-end-date-select"
        :label="this.$t('general.shared.endDate')"
        form-type="outline"
      >
        <Datepicker
          v-model="filterEndDate"
          :day-class="getInitDayClass"
          :enable-time-picker="false"
          :month-change-on-scroll="false"
          :hide-navigation="['time']"
          :highlight="highlightedDates"
          :min-date="!!initDate ? initDate : null"
          auto-apply
          calendar-cell-class-name="end-date-cell"
          class="form-datepicker"
          :format="settings.formatDate"
          input-class-name="form-control"
          @update:model-value="setHighlightedDates();
                               $emit('update:endDate', !!filterEndDate ? fixDate(filterEndDate) : '');
                               $emit('selected')"
        />
      </v-form>

      <v-form-input
        id="case-filter-origin-select"
        v-model="filterOrigin"
        :label="this.$t('general.shared.origin')"
        autocomplete="off"
        form-type="outline"
        @update:modelValue="$emit('update:origin', filterOrigin); $emit('selected')"
      />

      <v-form-input
        id="case-filter-destination-select"
        v-model="filterDestination"
        :label="this.$t('general.shared.destination')"
        autocomplete="off"
        form-type="outline"
        @update:modelValue="$emit('update:destination', filterDestination); $emit('selected')"
      />

      <v-form-input
        id="case-filter-location-select"
        v-model="filterLocation"
        :label="this.$t('general.shared.location')"
        autocomplete="off"
        form-type="outline"
        @update:modelValue="$emit('update:location', filterLocation); $emit('selected')"
      />

      <v-form
        id="case-filter-travel-type-select"
        :label="this.$t('general.shared.travelType')"
        form-type="outline"
      >
        <v-select
          v-model="filterTravelType"
          :clearable="false"
          :options="travelTypeOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:travelType', filterTravelType); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-form
        id="case-filter-reservation-status-select"
        :label="this.$t('general.shared.reservationStatus')"
        form-type="outline"
      >
        <v-select
          v-model="filterReservationStatus"
          :clearable="false"
          :options="reservationStatusOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:reservationStatus', filterReservationStatus); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-form
        id="case-filter-payments-status-select"
        :label="this.$t('general.shared.paymentsStatus')"
        form-type="outline"
      >
        <v-select
          v-model="filterPaymentsStatus"
          :clearable="false"
          :options="paymentsStatusOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:paymentsStatus', filterPaymentsStatus); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-form
        id="case-filter-invoices-status-select"
        :label="this.$t('general.shared.invoicesStatus')"
        form-type="outline"
      >
        <v-select
          v-model="filterInvoicesStatus"
          :clearable="false"
          :options="invoicesStatusOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:invoicesStatus', filterInvoicesStatus); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>
    </off-canvas-body>
    </resizable-container>  
  </v-off-canvas>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import vSelect from 'vue-select';
import {VCheckbox, VForm, VFormInput} from '@uniqoders/form';
import Datepicker from '@vuepic/vue-datepicker';

import {addDays, isEqual, set} from 'date-fns';
import {mapState} from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useConcepts from '@/helpers/Concepts';
import VSelectClientSearch from '@/components/vendor/selectSearch/VSelectClientSearch.vue';
import {mapConcepts} from '@/helpers/ConceptHelper';
import VSelectUserCaseSearch from '@/components/vendor/selectSearch/VSelectUserCaseSearch.vue';
import {useAppStore} from '@/stores/app';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheCaseFiltersOffcanvas',
  components: {
    VSelectUserCaseSearch,
    VSelectClientSearch,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    vSelect,
    VForm,
    Datepicker,
    VFormInput,
    VCheckbox,
    ResizableContainer,
  },
  emits: [
    'closed',
    'selected',
    'update:assignedTo',
    'update:clientId',
    'update:destination',
    'update:endDate',
    'update:initDate',
    'update:invoicesStatus',
    'update:location',
    'update:onlyOnWay',
    'update:origin',
    'update:paxNumber',
    'update:paymentsStatus',
    'update:reservationStatus',
    'update:status',
    'update:travelType',
    'update:startingSoon',
  ],
  props: {
    agency: {
      type: String,
      required: true,
    },
    assignedTo: {
      type: String,
      required: false,
      default: '',
    },
    clientId: {
      type: [String, Number],
      required: false,
      default: '',
    },
    destination: {
      type: String,
      required: false,
      default: '',
    },
    endDate: {
      type: Date,
      required: false,
      default: '',
    },
    initDate: {
      type: Date,
      required: false,
      default: '',
    },
    invoicesStatus: {
      type: String,
      required: false,
      default: '',
    },
    location: {
      type: String,
      required: false,
      default: '',
    },
    onlyOnWay: {
      type: Boolean,
      required: false,
      default: false,
    },
    origin: {
      type: String,
      required: false,
      default: '',
    },
    paxNumber: {
      type: String,
      required: false,
      default: '',
    },
    paymentsStatus: {
      type: String,
      required: false,
      default: '',
    },
    reservationStatus: {
      type: String,
      required: false,
      default: '',
    },
    startingSoon: {
      type: [String, Number],
      required: false,
      default: '',
    },
    status: {
      type: String,
      required: true,
      default: 'active',
    },
    travelType: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    return {
      ...useConcepts(),
    };
  },
  data() {
    return {
      filterAssignedTo: this.assignedTo,
      filterClientId: this.clientId,
      filterDestination: this.destination,
      filterEndDate: this.endDate,
      filterInitDate: this.initDate,
      filterInvoicesStatus: this.invoicesStatus,
      filterOnlyOnWay: this.onlyOnWay,
      filterLocation: this.location,
      filterOrigin: this.origin,
      filterPaxNumber: this.paxNumber,
      filterPaymentsStatus: this.paymentsStatus,
      filterReservationStatus: this.reservationStatus,
      filterStartingSoon: this.startingSoon,
      filterStatus: this.status,
      filterTravelType: this.travelType,
      highlightedDates: [] as Date[],
    };
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
    startingSoonOptions(): any[] {
      return [
        {
          key: '7',
          label: this.$t('general.shared.oneWeek'),
          order: 0,
          shortName: 'one_week',
        },
        {
          key: '14',
          label: this.$t('general.shared.twoWeeks'),
          order: 1,
          shortName: 'two_weeks',
        },
        {
          key: '30',
          label: this.$t('general.shared.oneMonth'),
          order: 2,
          shortName: 'one_month',
        },
      ];
    },
    statusTypesOptions(): any {
      if (!!this.concepts && this.concepts['app.states']) {
        return mapConcepts(this.concepts['app.states']);
      }

      return [];
    },
    travelTypeOptions(): any {
      if (!!this.concepts && this.concepts['tour.travel_type']) {
        const concepts = mapConcepts(this.concepts['tour.travel_type']);

        concepts.unshift({
          key: '',
          label: this.$t('general.shared.all'),
          permission: null,
          value: null,
          order: 0,
          status: 'active',
          icon: '',
          color: '',
          shortName: 'all',
        });

        return concepts;
      }

      return [];
    },
    reservationStatusOptions(): any {
      if (!!this.concepts && this.concepts['tour.reservation_status_type']) {
        const concepts = mapConcepts(this.concepts['tour.reservation_status_type']);

        concepts.unshift({
          key: '',
          label: this.$t('general.shared.all'),
          permission: null,
          value: null,
          order: 0,
          status: 'active',
          icon: '',
          color: '',
          shortName: 'all',
        });

        return concepts;
      }

      return [];
    },
    paymentsStatusOptions(): any {
      if (!!this.concepts && this.concepts['tour.payment_status_type']) {
        const concepts = mapConcepts(this.concepts['tour.payment_status_type']);

        concepts.unshift({
          key: '',
          label: this.$t('general.shared.all'),
          permission: null,
          value: null,
          order: 0,
          status: 'active',
          icon: '',
          color: '',
          shortName: 'all',
        });

        return concepts;
      }

      return [];
    },
    invoicesStatusOptions(): any {
      if (!!this.concepts && this.concepts['tour.invoice_status_type']) {
        const concepts = mapConcepts(this.concepts['tour.invoice_status_type']);

        concepts.unshift({
          key: '',
          label: this.$t('general.shared.all'),
          permission: null,
          value: null,
          order: 0,
          status: 'active',
          icon: '',
          color: '',
          shortName: 'all',
        });

        return concepts;
      }

      return [];
    },
  },
  watch: {
    assignedTo(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterAssignedTo = this.assignedTo;
      }
    },
    clientId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterClientId = this.clientId;
      }
    },
    destination(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterDestination = this.destination;
      }
    },
    endDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterEndDate = this.endDate;
        this.setHighlightedDates();
      }
    },
    initDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterInitDate = this.initDate;
        this.setHighlightedDates();
      }
    },
    invoicesStatus(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterInvoicesStatus = this.invoicesStatus;
      }
    },
    onlyOnWay(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterOnlyOnWay = this.onlyOnWay;
      }
    },
    location(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterLocation = this.location;
      }
    },
    origin(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterOrigin = this.origin;
      }
    },
    paxNumber(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterPaxNumber = this.paxNumber;
      }
    },
    paymentsStatus(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterPaymentsStatus = this.paymentsStatus;
      }
    },
    reservationStatus(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterReservationStatus = this.reservationStatus;
      }
    },
    status(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterStatus = this.status;
      }
    },
    startingSoon(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterStartingSoon = this.startingSoon;
      }
    },
    travelType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterTravelType = this.travelType;
      }
    },
  },
  created() {
    this.setHighlightedDates();
  },
  methods: {
    setHighlightedDates() {
      if (!!this.initDate && !!this.endDate) {
        const initDate: Date = new Date(this.filterInitDate);
        const endDate: Date = new Date(this.filterEndDate);

        this.highlightedDates = [];

        let date = initDate;

        while (date <= endDate) {
          this.highlightedDates.push(date);
          date = addDays(date, 1);
        }
      }
    },
    getInitDayClass(date: Date) {
      const initDate: Date = new Date(this.filterInitDate);

      if (isEqual(date, set(initDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }))) return 'init-marked-cell';

      return '';
    },
    getEndDayClass(date: Date) {
      const endDate: Date = new Date(this.filterEndDate);

      if (isEqual(date, set(endDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }))) return 'end-marked-cell';

      return '';
    },
    fixDate(date: any): string {
      return this.$str.formatDateTime(date.toISOString(), 'dd-MM-yyyy');
    },
  },
});
</script>
