<template>
  <div id="layout">
    <the-header/>

    <main id="main" class="main pb">
      <router-view/>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheHeader from '@/components/layout/parts/TheHeader.vue';

export default defineComponent({
  name: 'VLayout',
  components: {
    TheHeader,
  },
  mounted() {
    const main: any = document.getElementById('main');
    const buttonClose: any = document.getElementById('btn-header-hamburger');

    main.addEventListener('click', () => {
      if (main.classList.contains('menu-open')) {
        buttonClose.click();

        buttonClose.classList.remove('is-active');
        main.classList.remove('menu-open');
      }
    });
  },
});
</script>
