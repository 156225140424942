<template>
  <div class="checkbox-container d-flex align-items-center" @click="onClick($event)">
    <div :class="containerClass" class="d-inline-flex position-relative align-bottom">
      <div class="hidden-accessible border-0 overflow-hidden position-absolute p-0">
        <input
          :id="inputId"
          ref="input"
          :aria-label="ariaLabel"
          :aria-labelledby="ariaLabelledby"
          :checked="modelValue === true"
          :disabled="disabled"
          :tabindex="tabindex"
          type="checkbox"
          v-bind="inputProps"
          @blur="onBlur($event)"
          @click="$emit('click')"
          @focus="onFocus($event)"
          @keydown="onKeyDown($event)"
        />
      </div>

      <div
        ref="box"
        :class="['tri-checkbox-box',
               {
                 highlight: forceVisualTrue || modelValue === true || modelValue === 'mixed',
                 disabled: disabled,
                 focus: focused,
               }]"
        class="d-flex align-items-center justify-content-center"
      >
        <span v-if="forceVisualTrue || modelValue === true" class="mi mi-check text-white"/>
        <span v-else-if="modelValue === 'mixed'" class="mi mi-actions-minus text-white fw-semi"/>
        <span v-else/>
      </div>
    </div>

    <span
      v-if="!!label"
      :class="labelClass"
      class="ps-8"
    >{{ label }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VTriStateCheckbox',
  props: {
    modelValue: {
      type: [Boolean, String],
      default: false,
    },
    inputId: {
      type: String,
      default: null,
    },
    inputProps: {
      type: null,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forceVisualTrue: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    ariaLabelledby: {
      type: String,
      default: null,
    },
    ariaLabel: {
      type: String,
      default: null,
    },
    treeOptions: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: [
    'click',
    'update:modelValue',
    'change',
    'keydown',
    'focus',
    'blur',
  ],
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    containerClass() {
      return [
        'tri-checkbox',
        {
          'tri-checkbox-checked': this.modelValue === true,
          'tri-checkbox-disabled': this.disabled,
          'tri-checkbox-focused': this.focused,
        },
      ];
    },
  },
  methods: {
    updateModel() {
      if (!this.disabled) {
        let newValue;

        if (this.treeOptions) {
          switch (this.modelValue) {
            case true:
              newValue = 'mixed';
              break;
            case 'mixed':
              newValue = false;
              break;
            default:
              newValue = true;
              break;
          }
        } else {
          newValue = !(this.modelValue === 'mixed' || this.modelValue === true);
        }

        this.$emit('update:modelValue', newValue);
      }
    },
    onClick(event: any) {
      if (!this.disabled) {
        this.updateModel();
        this.$emit('click', event);
        this.$emit('change', event);

        (this.$refs.input as any).focus();
      }
    },
    onKeyDown(event: any) {
      if (event.code === 'Enter') {
        this.updateModel();
        this.$emit('change', event);
        this.$emit('keydown', event);
        event.preventDefault();
      }
    },
    onFocus(event: any) {
      this.focused = true;
      this.$emit('focus', event);
    },
    onBlur(event: any) {
      this.focused = false;
      this.$emit('blur', event);
    },
  },
});
</script>

<style lang="scss" scoped>
.checkbox-container {
  cursor: pointer;
}

.tri-checkbox {
  width: 1rem;
  height: 1rem;
  transition: all 0.25s;

  &:hover {
    box-shadow: 0 1px .25rem var(--uq-gray-100);
  }
}

.hidden-accessible {
  clip: rect(0 0 0 0);
  height: .0625em;
  margin: -.0625em;
  width: .0625em;
}

.tri-checkbox-box {
  border: 1px solid var(--uq-gray-400);
  background: var(--uq-light);
  width: 1rem;
  height: 1rem;
  border-radius: .1875rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

  &:hover {
    box-shadow: 0 1px .25rem #D5D5D5;
  }

  .mi {
    font-size: .75rem;

    &.mi-check::before {
      font-weight: 600 !important;
    }
  }

  .icon {
    font-size: .4rem;

    &.icon-minus {
      height: .313rem;
    }
  }
}

.tri-checkbox-box.highlight {
  border-color: #F7F7FC;
  outline: 1px solid #D5D5D5;
  background: var(--uq-secondary);
}

.tri-checkbox-disabled {
  cursor: unset;

  .highlight {
    background: #808082 !important;
    opacity: .5;
  }
}
</style>
