<template>
  <v-off-canvas
    id="template-day-item-details-offcanvas"
    :backdrop-static="false"
    offcanvas-class="item-details offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <div class="d-flex align-items-center">
        <v-icon class="text-primary" icon="info" size="xxl" space="me-8"/>

        <h5 id="template-day-item-details-offcanvas-label" class="offcanvas-title">
          {{ $t('tourBuilder.itemDetails') }}
        </h5>
      </div>
      </off-canvas-header>

      <off-canvas-body>
      <v-loader v-if="status === 'loading' || !currentTourDayItem"/>

      <template v-else>
        <the-template-day-item-details-create-form
          v-if="!!currentTourDayItem && !!currentTourDayItem.resources && currentTourDayItem.resources.length === 0"
          :agency="agency"
          :status="status"
          :template-id="templateId"
          @emit-reload-tour="$emit('emitReloadTour')"
        />

        <the-template-day-item-details-update-form
          v-else
          :agency="agency"
          :status="status"
          :template-id="templateId"
          :is-collaborator="isCollaborator"
          @deleted-item-resource="deletedItemResource = true"
          @emit-reload-tour="$emit('emitReloadTour')"
        />
      </template>

      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import { useUserStore } from '@/stores/user';
import useConcepts from '@/helpers/Concepts';
import TheTemplateDayItemDetailsUpdateForm from '@/components/template/TheTemplateDayItemDetailsUpdateForm.vue';
import TheTemplateDayItemDetailsCreateForm from '@/components/template/TheTemplateDayItemDetailsCreateForm.vue';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue'

export default defineComponent({
  name: 'TheTemplateDayItemDetailsOffCanvas',
  components: {
    TheTemplateDayItemDetailsCreateForm,
    TheTemplateDayItemDetailsUpdateForm,
    VLoader,
    VIcon,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    ResizableContainer
  },
  emits: ['closed', 'emitReloadTour'],
  props: {
    agency: {
      type: String,
      required: true,
    },
    templateId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    isCollaborator: {
      type: Boolean,
    },
  },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
      ...useConcepts(),
    };
  },
  data() {
    return {
      deletedItemResource: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['currentTourDayItem']),
  },
  watch: {
    currentTourDayItem: {
      handler(value) {
        if (this.deletedItemResource && !!value && !!value.resources && value.resources.length === 0) {
          this.$emit('emitReloadTour');
        }
      },
      deep: true,
    },
  },
  unmounted() {
    this.userStore.setCurrentTemplateDayItem(null);
  },
});
</script>

<style lang="scss" scoped>
.accordion-item {
  background-color: transparent;
}

.accordion .accordion-item .accordion-button {
  font-size: 1rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: transparent;
}
</style>
