<template>
  <div id="accordionNotification" class="accordion">
    <div class="case-details accordion-item">
      <h2 id="headingNotification" class="accordion-header">
        <button aria-controls="collapseNotification" aria-expanded="true" class="accordion-button collapsed"
          data-bs-target="#collapseNotification" data-bs-toggle="collapse" type="button">
          <div class="mb-0 d-flex">
            <div class="bg-secondary rounded-xs h-fit-content me-8">
              <v-icon icon="mail" size="xxl" space="p-12" />
            </div>

            <div>
              <div class="h4 ff-secondary mb-0">{{ $t('clientTour.sendNotification') }}</div>
              <div class="fw-light accordion-subtitle">{{ $t('clientTour.sendNotificationSubtitle') }}</div>
            </div>
          </div>
        </button>
      </h2>

      <div id="collapseNotification" aria-labelledby="headingNotification" class="accordion-collapse collapse show"
        data-bs-parent="#accordionNotification">
        <div class="accordion-body pb-32 pb-xl-48">
          <form id="case-update-notification-form" class="d-flex flex-column justify-content-between h-100 mb-16">
            <fieldset class="mb-24">
              <legend class="visually-hidden">{{ $t('clientTour.notification') }}</legend>

              <div class="mb-24">
                <v-form-input-radio v-model="paxs" :formValueOptions="[
                { key: false, label: $t('clientTour.sendOnlyClient') },
                { key: true, label: $t('clientTour.sendClientAndPaxs') },
              ]" form-class="mb-8" />
              </div>

              <v-form id="case-type-notification-select" :label="$t('clientTour.notification')" form-type="outline">
                <v-select v-model="notification" :clearable="false" :options="clientEmailNotificationOptions"
                  :reduce="(returnValue: any) => returnValue.key">
                  <template v-slot:no-options>
                    <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                  </template>
                </v-select>
              </v-form>
            </fieldset>

            <v-button :disabled="disableButton" :is-loading="disableButton" class="btn-icon w-fit align-self-end"
              size="sm" variant="primary" @click="sendNotification">
              {{ $t('general.button.send') }}
              <v-icon icon="arrow-right" size="sm" space="ms-12" />
            </v-button>
          </form>

          <div class="d-flex flex-column text-sm">
            <div class="d-none d-md-flex d-lg-none d-xl-block">
              <p class="text-md fw-semi mb-8">{{ $t('clientTour.clientNotificationLog') }}</p>

              <div class="table-notifications table-responsive pe-8">
                <v-table class="mb-0">
                  <v-table-header>
                    <tr class="text-normal">
                      <th>
                        {{ $t('general.shared.type') }}
                      </th>

                      <th>
                        {{ $t('general.shared.date') }}
                      </th>

                      <th>
                        {{ $t('general.shared.status') }}
                      </th>
                    </tr>
                  </v-table-header>

                  <v-table-body class="fw-medium">
                    <tr v-if="!notificationsLoaded">
                      <td class="position-relative border-0 py-96" colspan="2222">
                        <v-loader />
                      </td>
                    </tr>

                    <template v-else-if="!!notifications && notifications.length > 0">
                      <tr v-for="notification in notifications" :key="notification.id">
                        <td class="text-nowrap">
                          <template v-if="notification.type === 'Paxs Information'">
                            {{ $t('clientTour.paxsInformation') }}
                          </template>

                          <template v-else>
                            {{ findConceptByKey('client.email_notification_types', notification.type).name }}
                          </template>
                        </td>

                        <td class="text-nowrap">
                          {{ $str.formatDateTime(notification.sentAt, settings.formatDatetime) }}
                        </td>

                        <td class="text-nowrap">
                          {{ findConceptByKey('notification.status', notification.status).name }}
                        </td>
                      </tr>
                    </template>

                    <template v-else>
                      <tr>
                        <td class="rounded-xs py-16" colspan="2222">
                          {{ $t('clientTour.noClientNotifications') }}
                        </td>
                      </tr>
                    </template>
                  </v-table-body>
                </v-table>
              </div>
            </div>

            <div class="item-cards d-block d-md-none d-lg-block d-xl-none mt-8">
              <div v-if="!notificationsLoaded" class="position-relative py-96">
                <v-loader />
              </div>

              <template v-else-if="!!notifications && notifications.length > 0">

                <div id="accordionSetupClientNotification" class="accordion">
                  <div class="case-details accordion-item">
                    <div id="headingSetupClientNotification" class="accordion-header">
                      <button aria-controls="collapseSetupClientNotification" aria-expanded="false"
                        class="accordion-button collapsed fw-semi text-normal"
                        data-bs-target="#collapseSetupClientNotification" data-bs-toggle="collapse" type="button">
                        {{ $t('clientTour.clientNotificationLog') }}
                      </button>
                    </div>

                    <div id="collapseSetupClientNotification" aria-labelledby="headingSetupClientNotification"
                      class="accordion-collapse collapse" data-bs-parent="#accordionSetupClientNotification">
                      <div class="accordion-body pb-32 pb-xl-48">
                        <div v-for="notification in notifications" :key="notification.id"
                          class="item-card border rounded-xs p-8 mb-12">
                          <div>
                            <span class="fw-medium">{{ $t('general.shared.type') }}: </span>

                            <span>
                              {{ findConceptByKey('client.email_notification_types', notification.type).name }}
                            </span>
                          </div>

                          <div>
                            <span class="fw-medium">{{ $t('general.shared.date') }}: </span>
                            <span>{{ $str.formatDateTime(notification.sentAt, settings.formatDatetime) }}</span>
                          </div>

                          <div>
                            <span class="fw-medium">{{ $t('general.shared.status') }}: </span>
                            <span>{{ findConceptByKey('notification.status', notification.status).name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div v-else>
                {{ $t('clientTour.noClientNotifications') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import { VForm, VFormInputRadio } from '@uniqoders/form';
import { mapState } from 'pinia';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import { findConceptByKey, mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import Notification from '@/api/objects/Notification';
import { useAppStore } from '@/stores/app';

export default defineComponent({
  name: 'TheCaseSetupNotifications',
  components: {
    VTableHeader,
    VLoader,
    VTable,
    VTableBody,
    vSelect,
    VIcon,
    VButton,
    VFormInputRadio,
    VForm,
  },
  props: {
    agency: {
      type: String,
      required: true,
    },
    tour: {
      type: Number,
      required: true,
    },
    clientTour: {
      type: Number,
      required: true,
    },
    
  },
  setup() {
    return {
      ...useConcepts(),
      findConceptByKey,
    };
  },
  data() {
    return {
      disableButton: false,
      notification: '',
      paxs: false,
      notifications: [] as Notification[],
      notificationsLoaded: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
    clientEmailNotificationOptions(): any[] {
      if (!!this.concepts && this.concepts['client.email_notification_types']) {
        return mapConcepts(this.concepts['client.email_notification_types']);
      }

      return [];
    },
  },
  async created() {
    this.notification = this.clientEmailNotificationOptions[0].key;

    await this.loadTourNotifications();
  },
  methods: {
    sendNotification() {
      this.$modal.confirm({
        title: this.$t('clientTour.emailModal.title'),
        text: this.$t('clientTour.emailModal.text'),
        leftButtonClasses: 'btn-tertiary',
        confirmButtonText: this.$t('general.button.send'),
        confirmButtonCallback: () => this.doSendNotification(),
      });
    },
    async doSendNotification() {
      try {
        this.disableButton = true;

        const data = {
          notification: this.notification,
          paxs: this.paxs,
        };

        await api.notification.client(this.agency,
          this.clientTour, this.tour, data);
        this.$toast.success(this.$t('general.shared.savedChanges'));
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.disableButton = false;

        await this.loadTourNotifications();
      }
    },
    async loadTourNotifications() {
      try {
        this.notifications = await api.notification.all(this.agency,
          this.clientTour, this.tour, 'client');
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      } finally {
        this.notificationsLoaded = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.table-notifications {
  overflow: auto;
  max-height: 23rem;

  thead th {
    background-color: var(--uq-body-bg);
  }
}

.table-notifications thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
