<template>
  <v-modal
    id="admin-collaborator-users-edit"
    class-name="modal-fullscreen-sm-down"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        {{ $t('admin.collaborator.addUsers') }}
      </modal-title>
    </modal-header>

    <modal-body>
      <v-select-collaborator-search
        id="collaborator-users-search"
        :agency="agency"
        :selected-options="selectedUsers"
        @user-attached="reloadAgency"
      />

      <div class="search-separator-container pb-32">
        <div class="separator-text-container">
          <span class="separator-text text-sm">{{ $t('general.shared.orCreateNew') }}</span>
        </div>
      </div>

      <div class="agency-create-container">
        <the-admin-agency-create-user-form
          :agency-id="agency.id"
          @user-created="reloadAgency"
        />
      </div>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import api from '@/api';
import VSelectCollaboratorSearch from '@/components/vendor/selectSearch/VSelectCollaboratorSearch.vue';
import User from '@/api/objects/User';
import AgencyUser from '@/api/objects/AgencyUser';
import Agency from '@/api/objects/Agency';
import TheAdminAgencyCreateUserForm from '@/components/admin/agency/parts/TheAdminAgencyCreateUserForm.vue';

export default defineComponent({
  name: 'TheAdminAgencyAddUsersModal',
  components: {
    TheAdminAgencyCreateUserForm,
    VSelectCollaboratorSearch,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  emits: ['closed', 'emitReloadAgency'],
  props: {
    agency: {
      type: Agency,
      required: true,
    },
  },
  data() {
    return {
      disableUnassignAgencyUserBtn: false as boolean,
    };
  },
  computed: {
    selectedUsers() {
      const agencyUsers = this.agency.agencyUsers?.map((agencyUser: any) => agencyUser);

      if (!!agencyUsers) {
        return agencyUsers.map((agencyUser: AgencyUser) => agencyUser.user);
      }

      return [];
    },
  },
  methods: {
    async unassignAgencyUser(user: User) {
      await this.$modal.delete({
        title: this.$t('admin.collaborator.removeCollaboratorUserTitle'),
        text: this.$t('admin.collaborator.removeCollaboratorUserText', {
          agency: this.agency?.name,
          user: user.name,
          email: user.email,
        }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doUnassignAgencyUser(user.id),
      });
    },
    async doUnassignAgencyUser(userId: string | number) {
      try {
        this.disableUnassignAgencyUserBtn = true;

        await api.admin.agency.attachUser(this.agency.id, userId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.reloadAgency();

        this.disableUnassignAgencyUserBtn = false;
      } catch (e: any) {
        this.disableUnassignAgencyUserBtn = false;

        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    async reloadAgency() {
      this.$emit('emitReloadAgency');
    },
  },
});
</script>
