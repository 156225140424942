<template>
  <v-off-canvas
    id="agency-filters-offcanvas"
    :backdrop-static="false"
    offcanvas-class="offcanvas-end"
    @closed="$emit('closed')"
  >
    <resizable-container>
      <off-canvas-header>
      <h5 class="offcanvas-title">{{ $t('general.shared.filters') }}</h5>
      </off-canvas-header>

      <off-canvas-body>
      <v-form
        id="agency-filter-status-select"
        :label="this.$t('general.shared.status')"
        form-type="outline"
      >
        <v-select
          v-model="filterStatus"
          :clearable="false"
          :options="statusTypesOptions"
          :reduce="returnValue => returnValue.key"
          @option:selected="$emit('update:status', filterStatus); $emit('selected')"
        >
          <template v-slot:no-options>
            <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
          </template>
        </v-select>
      </v-form>

      <v-form-input
        id="agency-filter-location-select"
        v-model="filterLocation"
        :label="this.$t('general.shared.location')"
        autocomplete="off"
        form-type="outline"
        @update:modelValue="$emit('update:location', filterLocation); $emit('selected')"
      />
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import vSelect from 'vue-select';
import { VForm, VFormInput } from '@uniqoders/form';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useConcepts from '@/helpers/Concepts';
import { mapConcepts } from '@/helpers/ConceptHelper';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';

export default defineComponent({
  name: 'TheAdminAgencyFiltersOffcanvas',
  components: {
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    vSelect,
    VForm,
    VFormInput,
    ResizableContainer,
  },
  emits: [
    'closed',
    'selected',
    'update:status',
    'update:location',
  ],
  props: {
    status: {
      type: String,
      required: true,
      default: 'active',
    },
    location: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    return {
      ...useConcepts(),
    };
  },
  data() {
    return {
      filterStatus: this.status,
      filterLocation: this.location,
    };
  },
  computed: {
    statusTypesOptions(): any {
      if (!!this.concepts && this.concepts['app.states']) {
        return mapConcepts(this.concepts['app.states']);
      }

      return [];
    },
  },
  watch: {
    status(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterStatus = this.status;
      }
    },
    location(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterLocation = this.location;
      }
    },
  },
});
</script>
