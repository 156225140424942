import { AbstractService } from '@uniqoders/sdk';
import TourDay from '@/api/objects/TourDay';

export default class TourDayService extends AbstractService {
  /**
   * Retrieves a specific Tour Day
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @protected
   */
  public retrieve(
    agency: string,
    clientTour: string | number,
    tour: string | number,
    tourDay: string | number): Promise<TourDay> {
    return this.request({
      method: 'get',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}', {
        agency,
        clientTour,
        tour,
        tourDay,
      }),
    });
  }

  /**
   * Creates a single Tour Day
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param data
   */
  public create(
    agency: string,
    clientTour: string | number,
    tour: string | number,
    data: Record<string, any>): Promise<TourDay> {
    return this.request({
      method: 'post',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day', {
        agency,
        clientTour,
        tour,
      }),
      data,
    });
  }

  /**
   * Updates the specified Tour Day
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   * @param data
   * @protected
   */
  public update(
    agency: string,
    clientTour: string | number,
    tour: string | number,
    tourDay: string | number,
    data: Record<string, any>): Promise<TourDay> {
    return this.request({
      method: 'patch',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}', {
        agency,
        clientTour,
        tour,
        tourDay,
      }),
      data,
    });
  }

  /**
   * Deletes the specified Tour Day
   * @protected
   * @param agency
   * @param clientTour
   * @param tour
   * @param tourDay
   */
  public delete(
    agency: string,
    clientTour: string | number,
    tour: string | number,
    tourDay: string | number): Promise<TourDay> {
    return this.request({
      method: 'delete',
      url: this.buildPath('/{agency}/case/{clientTour}/tour/{tour}/day/{tourDay}', {
        agency,
        clientTour,
        tour,
        tourDay,
      }),
    });
  }
}
